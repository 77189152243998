import { schema } from 'normalizr';

import course from 'schemas/course';
import assignment from 'schemas/activity';
import spotlightTag from 'schemas/spotlightTag';

const spotlight = new schema.Entity(
  'spotlights',
  {
    course,
    activity: assignment,
    tag: spotlightTag,
  },
  { idAttribute: '_id' }
);

export default spotlight;
