import * as ActivityUtils from '@kritik/utils/activity';
import { Activity, Group, Student, Creation } from '@kritik/types.generated';

export const isGroupEmpty = (group: Group) => {
  return group.members.length === 0;
};

export const isGroupLeader = (student?: Student, group?: Group) => {
  if (!group || !group.leader || !student) {
    return false;
  }
  const studentLeaderId = (group.leader as Student)._id || (group.leader as string);

  return student._id === studentLeaderId;
};

export function isGroupMember(group?: Group, student?: Student) {
  if (!group || !group.members || !student) {
    return false;
  }
  return !!group.members.find((member) => {
    return member === student._id;
  });
}

export function findGroupFromStudentAndActivity(
  groups: Group[],
  student: Student,
  activityId: string
) {
  if (!groups || !groups.length || !student || !activityId) {
    return null;
  }
  const studentGroup = groups
    .filter((g) => {
      return (g.assignments as unknown as string[]).includes(activityId);
    })
    .find((g) => {
      return isGroupMember(g, student);
    });
  return studentGroup || null;
}

export const isNotInGroup = (activity: Activity, creation: Creation, group: Group) => {
  if (
    (ActivityUtils.isGroupAssignment(activity) ||
      ActivityUtils.isGroupPresentationActivity(activity)) &&
    !creation
  ) {
    return true;
  }

  if (ActivityUtils.isWithinGroupActivity(activity) && !group) {
    return true;
  }
  return false;
};

export const getGroupMemberIds = (group) => {
  return group.members.map((m) => {
    return m._id || m;
  });
};
