export const assignmentStatuses = {
  DRAFT: 'Draft',
  CREATE: 'Create',
  PROCESSING1: 'Processing1',
  EVALUATE: 'Evaluate',
  PROCESSING2: 'Processing2',
  FEEDBACK: 'Feedback',
  PROCESSING3: 'Processing3',
  FINALIZED: 'Finalized',
} as const;

export const PRESENT_STATUS = 'Present';

export type AssignmentStatusesValues = (typeof assignmentStatuses)[keyof typeof assignmentStatuses];

export default {
  assignmentStatuses,
};
