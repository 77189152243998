import { studentSchema } from 'schemas';
import { getSubmission } from 'selectors/creation';
import { getCourse, getCourseRecord, selectCurrentCourse } from 'selectors/course';
import { denormalize } from 'normalizr';
import * as _ from 'lodash-es';
import { isCalibrationActivity, isGroupAssignment } from '@kritik/utils/activity';
import { isPastGracePeriodDeadline } from '@kritik/utils/stage';
import { getAssignment } from './activity';
import { getGroupListPerAssignment } from './group';

export const getStudent = (studentId: any, entities: any) => {
  return denormalize(studentId, studentSchema, entities);
};

export const getStudents = (studentIds: any, entities: any) => {
  const studentList = denormalize(studentIds, [studentSchema], entities);
  return studentList.filter((student: any) => {
    return !!student;
  });
};

export const getStudentFromSubmission = (state: any, submissionId: any) => {
  const submission = getSubmission(state, submissionId);
  return submission ? getStudent(submission.student, state.entities) : null;
};

export const getStudentFromUser = (state: any, courseId: any) => {
  const user = state.user.authUser;
  const studentList = state.entities.students;
  if (!user) {
    return null;
  }
  const studentId = Object.keys(studentList).find((id) => {
    return studentList[id].user === user._id && studentList[id].course === courseId;
  });
  return studentList[studentId];
};

export const getStudentList = (state: any) => {
  const studentIds = Object.keys(state.entities.students);
  return denormalize(studentIds, [studentSchema], state.entities);
};

/**
 * Only select the related students in an activity for different activities
 * otherwise we need to handle so many cases in single table
 */
export const getCurrentActivityStudents = (state: any) => {
  let studentIds = [];
  const activity = getAssignment(state, state.selected.assignmentId);
  const course = selectCurrentCourse(state);

  if (isGroupAssignment(activity)) {
    // we can't get all students in group activity since they must be in a group
    const groups = getGroupListPerAssignment(state, activity._id);
    for (const group of groups) {
      for (const memberId of group.members) {
        const studentId = memberId;
        studentIds.push(studentId);
      }
    }
  } else if (isCalibrationActivity(activity)) {
    studentIds = Object.keys(state.creation.studentCreationMap);
  } else if (!isPastGracePeriodDeadline(activity)) {
    // if not pass grade period, students will still allow to submit
    // so we need to select all enrolled students in a course
    studentIds = course.students.map((student: any) => {
      return student._id || student;
    });
  } else {
    // after grace period, we only get students who have creation records
    studentIds = Object.keys(state.creation.studentCreationMap);
  }
  const students = denormalize(studentIds, [studentSchema], state.entities);
  return students.filter((student: any) => {
    return !student.removedOn && student.course === course._id;
  });
};

export const getStudentListFromSelectedCourse = (state: any) => {
  const course = getCourseRecord(state);
  if (!course) {
    return [];
  }
  const studentIds = [...course.students, ...course.removedStudents];
  return getStudents(studentIds, state.entities);
};

export const selectActiveStudentInCurrentCourse = (state: any) => {
  const course = getCourseRecord(state);
  if (!course) {
    return [];
  }
  const studentIds = [...course.students];
  return getStudents(studentIds, state.entities);
};

export const selectStudentScoreHistories = (state: any, studentId: any) => {
  const student = getStudent(studentId, state.entities);
  return student.scoreHistories;
};

export const selectCurrentStudent = (state: any) => {
  const course = getCourse(state);
  if (course.userRole === 'instructor') {
    return getStudent(state.selected.studentId, state.entities);
  }
  return getStudentFromUser(state, course._id);
};

export default {
  getStudent,
  getStudents,
  getStudentFromSubmission,
  getStudentFromUser,
  getStudentList,
  getStudentListFromSelectedCourse,
  selectActiveStudentInCurrentCourse,
  getCurrentActivityStudents,
  selectStudentScoreHistories,
  selectCurrentStudent,
};
