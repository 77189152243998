const TYPES = {
  GET_PAGINATION_RESULTS_REQUEST: 'GET_PAGINATION_RESULTS_REQUEST',
  GET_PAGINATION_RESULTS_SUCCESS: 'GET_PAGINATION_RESULTS_SUCCESS',
  GET_PAGINATION_RESULTS_FAILURE: 'GET_PAGINATION_RESULTS_FAILURE',
  GET_ACTIVITY_PAGINATED_CREATIONS_REQUEST: 'GET_ACTIVITY_PAGINATED_CREATIONS_REQUEST',
  GET_ACTIVITY_PAGINATED_CREATIONS_SUCCESS: 'GET_ACTIVITY_PAGINATED_CREATIONS_SUCCESS',
  GET_ACTIVITY_PAGINATED_CREATIONS_FAILURE: 'GET_ACTIVITY_PAGINATED_CREATIONS_FAILURE',
  SET_STUDENT_FEEDBACK_RECEIVED_MAP: 'SET_STUDENT_FEEDBACK_RECEIVED_MAP',
};

export default TYPES;
