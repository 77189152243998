import { connect } from 'react-redux';
import { Feedback } from 'components/Creation/Feedback';
import { EvaluationView } from 'components/Creation/Evaluate';
import { exactDate } from '@kritik/utils/format';
import StudentContent from 'components/layout/StudentContent';
import { getNewKS } from 'utils/student';
import VerticalList from 'components/layout/VerticalList';
import * as ActivityUtils from '@kritik/utils/activity';
import StudentSelectors from 'selectors/student';
import UserSelectors from 'selectors/user';
import * as EvaluationUtil from '@kritik/utils/creation/evaluation';
import { EvaluationScoreUtil, FeedbackScoreUtil } from '@kritik/utils/grade';
import NoticeBoard from 'components/layout/NoticeBoard';
import { localize } from 'locales/index';
import { LateEvaluationBoard } from './CreationOverview/EvaluationTab/LateEvaluation';

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    entities: state.entities,
    user: state.user,
    myUser: state.user.authUser,
    fofStudent: StudentSelectors.getStudent(
      ownProps.evaluation.feedbackOnFeedback.student,
      state.entities
    ),
    fofUser: UserSelectors.getUser(ownProps.evaluation.feedbackOnFeedback.user, state.entities),
  };
};

function EvaluationWithFeedback(props: any) {
  const { submission, evaluation, assignment } = props;
  const isGroupAssignment = ActivityUtils.isGroupAssignment(assignment);
  const isPresentationActivity = ActivityUtils.isPresentationActivity(assignment);

  const getUserForContent = () => {
    if (isGroupAssignment || isPresentationActivity) {
      return props.fofUser;
    }
    return submission.user;
  };

  const getStudentForContent = () => {
    if (isGroupAssignment || isPresentationActivity) {
      return props.fofStudent;
    }
    return submission.student;
  };

  const renderFOF = () => {
    const isFOFAssigned = getUserForContent() && getStudentForContent();
    const isSelfEvaluation = EvaluationUtil.isSelfEvaluation({
      evaluation,
      userId: submission.user._id,
    });

    // We want to hide the FoF form on a removed evaluation
    if (
      EvaluationScoreUtil.isEvaluationRemoved(evaluation) &&
      !FeedbackScoreUtil.isFOFCompleted(evaluation)
    ) {
      return (
        <NoticeBoard
          title={localize({
            message: 'Creation.Evaluation.Feedback.Notice.NoFeedbackRequired.Title',
          })}
        >
          {localize({ message: 'Creation.Evaluation.Feedback.Notice.NoFeedbackRequired.Content' })}
        </NoticeBoard>
      );
    }

    if (isFOFAssigned && !isSelfEvaluation) {
      return (
        <StudentContent user={getUserForContent()} score={getNewKS(getStudentForContent())}>
          <Feedback assignment={assignment} submission={submission} evaluation={evaluation} />
        </StudentContent>
      );
    }
    return null;
  };

  let studentId;
  if (typeof evaluation.student === 'string') {
    studentId = evaluation.student;
  } else {
    studentId = evaluation.student._id;
  }

  return (
    <VerticalList
      itemList={[
        <StudentContent
          user={
            typeof evaluation.user === 'string'
              ? props.entities.users[evaluation.user]
              : evaluation.user
          }
          score={getNewKS(props.entities.students[studentId])}
        >
          <div className="evaluation-entry__evaluation" data-testid="evaluation-display">
            <LateEvaluationBoard
              evaluation={evaluation}
              activity={assignment}
              evaluatedCreation={submission}
            />
            <div className="evaluation-header">
              <h3 className="header-3">{props.title}</h3>
              <div>{exactDate(evaluation.createdAt)}</div>
            </div>
            <EvaluationView
              assignment={assignment}
              submission={submission}
              userScore={evaluation}
            />
          </div>
        </StudentContent>,
        renderFOF(),
      ]}
    />
  );
}

export default connect(mapStateToProps)(EvaluationWithFeedback);
