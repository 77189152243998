import React from 'react';
import createSvgIcon from '../util/generateIconSVG';

export default createSvgIcon(
  <React.Fragment>
    <circle cx="32" cy="32" r="31.5" fill="#D7EEF4" stroke="#235C6A" />
    <path
      d="M40.1035 47.1798L40.1845 47.2607L40.2915 47.3013L47.2573 49.9443L48.3657 50.3648L47.9451 49.2564L45.3022 42.2906L45.2616 42.1836L45.1806 42.1027L22.2264 19.1484L21.8492 18.7713L21.4721 19.1484L17.1493 23.4712L16.7721 23.8484L17.1493 24.2255L40.1035 47.1798Z"
      fill="#8AC8D7"
      stroke="#235C6A"
      strokeWidth="1.06667"
    />
    <path d="M47.444 49.448L40.4194 46.7463L44.7423 42.4234L47.444 49.448Z" fill="#D7EEF4" />
    <path
      d="M15.8917 14.3228L12.3231 17.8914L10.0491 15.6174C9.63258 15.2008 9.63258 14.5254 10.0491 14.1089L12.1092 12.0488C12.5258 11.6322 13.2012 11.6322 13.6177 12.0488L15.8917 14.3228Z"
      fill="#8AC8D7"
      stroke="#235C6A"
      strokeWidth="1.06667"
    />
    <rect
      x="21.723"
      y="18.6455"
      width="7.18011"
      height="7.18011"
      transform="rotate(135 21.723 18.6455)"
      fill="#5799A9"
      stroke="#235C6A"
      strokeWidth="1.06667"
    />
    <rect
      x="18.8071"
      y="20.8066"
      width="1.52836"
      height="6.11344"
      transform="rotate(135 18.8071 20.8066)"
      fill="#76B6C7"
    />
    <rect
      x="41.502"
      y="45.6636"
      width="1.52836"
      height="32.0956"
      transform="rotate(135 41.502 45.6636)"
      fill="#B3DDE8"
    />
    <rect
      x="44.7446"
      y="42.4214"
      width="1.52836"
      height="32.0956"
      transform="rotate(135 44.7446 42.4214)"
      fill="#76B6C7"
    />
  </React.Fragment>,
  '0 0 64 64'
);
