import { rubricSchema } from 'schemas';
import { denormalize } from 'normalizr';
import * as types from 'types';

export const selectRubrics = (state: any) => {
  const { rubrics } = state.entities;

  if (!rubrics) {
    return [];
  }
  const ids = Object.keys(rubrics);

  const denormalizedRubrics = denormalize(ids, [rubricSchema], state.entities);

  return denormalizedRubrics
    .filter((r: any) => {
      return r.isTemplate;
    })
    .sort((a: any, b: any) => {
      // @ts-expect-error TS(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
};

export const selectMyTemplates = (state: any) => {
  const { rubrics } = state.entities;

  if (!rubrics) {
    return [];
  }
  const ids = Object.keys(rubrics);

  const denormalizedRubrics = denormalize(ids, [rubricSchema], state.entities);

  return denormalizedRubrics
    .filter((r: any) => {
      return r.isTemplate && !r.default;
    })
    .sort((a: any, b: any) => {
      // @ts-expect-error TS(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
};

export const selectRubricAsyncState = (state: any) => {
  return state.rubric.async;
};

export const selectCreateTemplateState = (state: any) => {
  return selectRubricAsyncState(state)[types.CREATE_NEW_TEMPLATE];
};

export default {
  selectRubrics,
  selectMyTemplates,
};
