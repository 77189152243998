export const getActivityPage = (courseId: any, activityId: any) => {
  return `/course/${courseId}/assignment/${activityId}`;
};

export const getSpotlightPage = ({ courseId, activityId, spotlightId }: any) => {
  return `${getActivityPage(courseId, activityId)}/spotlights/${spotlightId}`;
};

export const getSpotlightEditPage = ({ courseId, activityId, spotlightId }: any) => {
  return `${getSpotlightPage({
    courseId,
    activityId,
    spotlightId,
  })}/edit`;
};
