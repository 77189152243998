import { createRestApiClient } from 'utils/createRestApiClient';

const apiEndpoint = import.meta.env.VITE_API_URL;

type ResolveArgs = {
  activityActionItemId: string;
} & action_items.$id.resolve.POST.Request;

type UnresolveArgs = {
  activityActionItemId: string;
};

export default (options = {}) => {
  const client =
    (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint });
  return {
    getCount: ({
      courseId,
      activityId,
    }: any): Promise<DataResponse<courses.$id.instructor_actions_count.GET.Response>> => {
      return client.request({
        method: 'GET',
        url: `courses/${courseId}/action-items-count`,
        params: {
          activityId,
        },
      });
    },
    resolve: ({ activityActionItemId, note, status }: ResolveArgs) => {
      return client.request({
        method: 'POST',
        url: `action-items/${activityActionItemId}/resolve`,
        data: { note, status },
      });
    },
    unresolve: ({ activityActionItemId }: UnresolveArgs) => {
      return client.request({
        method: 'POST',
        url: `action-items/${activityActionItemId}/unresolve`,
      });
    },
  };
};
