import { useEffect, useMemo, useState, useRef } from 'react';
import { Field, useForm } from 'react-final-form';
import { groupService } from 'services';
import MultiSelectionField from 'components/core/form/MultiSelectionField';
import { localize } from 'locales';
import { ALL_STUDENTS, EXCLUDE_CREATORS } from '@kritik/constants/activity';

const GroupField = () => {
  const [groups, setGroups] = useState({});
  const form = useForm();
  const { groupSetId, evaluatorType, creatorGroups } = form.getState().values;
  const prevGroupSetId = useRef(groupSetId);

  useEffect(() => {
    if (prevGroupSetId.current !== groupSetId) {
      // When groupSetId changes, we reset form values
      form.batch(() => {
        form.change('creatorGroups', []);
        form.change('allGroupsSelected', false);
        form.change('evaluatorType', EXCLUDE_CREATORS);
      });
    }

    const getGroupsFromGroupSet = async () => {
      try {
        const response = await groupService().getGroupsFromGroupSet({
          groupSetId,
        });
        if (response.data) {
          setGroups((groups) => ({
            ...groups,
            [groupSetId]: response.data.filter((group) => group.members.length > 0),
          }));
        }
      } catch (ignore) {}
    };

    if (!groups[groupSetId]) {
      void getGroupsFromGroupSet();
    }
    prevGroupSetId.current = groupSetId;
  }, [groupSetId]);

  useEffect(() => {
    if (groupSetId && groups[groupSetId]) {
      const groupSetMembersIds = groups[groupSetId].flatMap((group) => group.members);
      form.change('groupSetMembersIds', groupSetMembersIds);
      form.change('groups', groups[groupSetId]);
    }
  }, [groupSetId, groups]);

  useEffect(() => {
    if (
      evaluatorType === EXCLUDE_CREATORS &&
      creatorGroups?.length === groups[groupSetId]?.length
    ) {
      form.change('evaluatorType', ALL_STUDENTS);
    }
  }, [evaluatorType]);

  const groupsOptions = useMemo(
    () =>
      (groups[groupSetId] || []).map((groupSet: any) => {
        return {
          label: groupSet.name,
          value: groupSet._id,
        };
      }),
    [groups, groupSetId]
  );

  const handleInputChange = (value, onChange) => {
    onChange(value);
    // if the instructor picks ALL groups, we force the “All Student” option
    if (value?.length === groups[groupSetId].length) {
      form.change('evaluatorType', ALL_STUDENTS);
      form.change('allGroupsSelected', true);
    } else {
      form.change('allGroupsSelected', false);
    }
    // if the instructor only picks ONE groups, we force the “All except creator” option
    if (value?.length === 1) {
      form.change('evaluatorType', EXCLUDE_CREATORS);
    }
  };

  return (
    <>
      <div className="group-select-title">
        {localize({ message: 'Activity.EditStudentParticipation.Group.GroupField.Title' })}
      </div>
      <div className="group-select-info">
        {localize({ message: 'Activity.EditStudentParticipation.Group.GroupField.Info' })}
      </div>
      <Field
        name="creatorGroups"
        parse={(values) => (values || []).map((v) => v.value)}
        format={(values) =>
          (values || []).map((v) => groupsOptions.find((group) => group.value === v))
        }
      >
        {({ input }) => {
          return (
            <div className="group-select-container">
              <MultiSelectionField
                value={input.value}
                name={input.name}
                onChange={(value) => handleInputChange(value, input.onChange)}
                placeholder="Groups"
                options={groupsOptions}
                testid="presentation-select-groups"
              />
            </div>
          );
        }}
      </Field>
    </>
  );
};

export default GroupField;
