import { connect } from 'react-redux';
import { getAssignment as getActivity } from 'selectors/activity';
import { getCourse } from 'selectors/course';
import { Field } from 'react-final-form';
import validators from 'components/ActivityEdit/Details/Fields/validators';
import FormFieldTitle from 'components/core/form/Title';
import FormFieldInput from 'components/core/form/FieldInput';
import NumberInput from 'components/core/input/Number';
import { localize } from 'locales/index';
import { TranslatedText } from 'components/TranslatedText';
import { Activity, Course } from '@kritik/types.generated';
import { isEvaluateOrLater } from '@kritik/utils/stage';
import FormFieldInfo from 'components/core/form/FieldInfo';
import {
  MAX_LATE_PENALTY_PERCENTAGE,
  MIN_LATE_PENALTY_PERCENTAGE,
} from 'common/constants/activity';

type Props = {
  course: Course;
  activity: Activity;
  isDuplicatingActivity: boolean;
};
function LateCreationPenaltyPercentage(props: Props) {
  const FIELD_NAME = 'lateCreationPenaltyPercentage';

  return (
    <Field
      name={FIELD_NAME}
      validate={validators.composeValidators(
        validators.isRequired,
        validators.minValue(MIN_LATE_PENALTY_PERCENTAGE),
        validators.maxValue(MAX_LATE_PENALTY_PERCENTAGE)
      )}
    >
      {({ input }) => {
        return (
          <div>
            <FormFieldTitle
              label={localize({ message: 'Activity.LateCreationPenaltyPercentageField.Label' })}
            />
            <FormFieldInput>
              <NumberInput
                className="not-bold"
                name={FIELD_NAME}
                value={input.value}
                onChange={(e: any) => {
                  return input.onChange(Number.parseInt(e.target.value, 10));
                }}
                disabled={isEvaluateOrLater(props.activity) && !props.isDuplicatingActivity}
                min={0}
                max={99}
              />
              <span className="input_unit">%</span>
              <FormFieldInfo
                label={
                  <TranslatedText
                    i18nKey="Activity.LateCreationPenaltyPercentageField.HelpText"
                    values={{ value: input.value }}
                  />
                }
              />
            </FormFieldInput>
          </div>
        );
      }}
    </Field>
  );
}

const mapStateToProps = (state: any) => {
  return {
    activity: getActivity(state, state.selected.assignmentId),
    course: getCourse(state, state.selected.courseId),
  };
};

export default connect(mapStateToProps, {})(LateCreationPenaltyPercentage);
