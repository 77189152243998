import React from 'react';
import { connect } from 'react-redux';
import { localize } from 'locales';
import TextInput from 'components/core/input/Text';
import { Button } from 'components/buttons';
import { useApprovePendingLMSConnection, useResendPendingLMSConnectionCode } from 'hooks/lms';
import { RouterProp, withRouter } from 'utils/withRouter';
import { InlineInformation } from 'components/layout';
import { SignupContainer, SignupContent, SignupMainTitle } from 'components/AuthWrapper';
import FormFieldLabel from 'components/core/form/FieldLabel';
import FormField from 'components/core/form/Field';
import { maskEmail } from '@kritik/utils/general';

function LMSConnectionPendingContainer(props: { email: string; router: RouterProp }) {
  const DEFAULT_ERROR_MESSAGE = localize({
    message: 'Error.SomethingWrongHappened',
  });
  const [code, setCode] = React.useState('');
  const [error, setError] = React.useState('');
  const approvePendingLMSConnectionMutation = useApprovePendingLMSConnection();
  const resendPendingLMSConnectionCodeMutation = useResendPendingLMSConnectionCode();
  React.useEffect(() => {
    resendPendingLMSConnectionCodeMutation.mutate(null, {
      onError: (e: any) => setError(e.response?.data?.errors?.message || DEFAULT_ERROR_MESSAGE),
    });
  }, []);

  const isLoading =
    approvePendingLMSConnectionMutation.isLoading ||
    resendPendingLMSConnectionCodeMutation.isLoading;

  return (
    <div className="login-container lms-connection-pending-container">
      <SignupContainer>
        <SignupContent>
          <SignupMainTitle>{localize({ message: 'LMSConnectionPending.Title' })}</SignupMainTitle>
          <p>
            {localize({ message: 'LMSConnectionPending.Paragraph.1' })}
            <strong>{maskEmail(props.email)}</strong>.&nbsp;
            {localize({ message: 'LMSConnectionPending.Paragraph.2' })}
          </p>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              setError('');
              approvePendingLMSConnectionMutation.mutate(
                {
                  code,
                },
                {
                  onSuccess: () => {
                    props.router.push('/');
                  },
                  onError: (e: any) => {
                    setError(e.response?.data?.errors?.message || DEFAULT_ERROR_MESSAGE);
                  },
                }
              );
            }}
          >
            <div>
              <FormField>
                <FormFieldLabel
                  label={localize({
                    message: 'LMSConnectionPending.Form.SecurityCode',
                  })}
                />
                <div className="security-code-wrapper">
                  <TextInput
                    inputProps={{
                      autoComplete: 'off',
                      minLength: '6',
                      maxLength: '6',
                      pattern: '\\d{6,6}',
                      required: true,
                    }}
                    placeholder="123456"
                    name="code"
                    testid="code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    autoFocus
                    aria-label={localize({
                      message: 'LMSConnectionPending.Form.SecurityCode',
                    })}
                  />
                  <Button
                    testid="send-code"
                    type="secondary"
                    onClick={async () => {
                      setError('');
                      setCode('');
                      resendPendingLMSConnectionCodeMutation.mutate(null, {
                        onError: (e: any) => {
                          setError(e.response?.data?.errors?.message || DEFAULT_ERROR_MESSAGE);
                        },
                      });
                    }}
                    disabled={isLoading}
                  >
                    {localize({
                      message: 'LMSConnectionPending.Form.SendAgain',
                    })}
                  </Button>
                </div>
              </FormField>
            </div>
            {error && (
              <InlineInformation testid="error-info" type="danger">
                {error}
              </InlineInformation>
            )}
            <Button testid="submit" type="primary" inputType="submit" disabled={isLoading}>
              {localize({
                message: 'LMSConnectionPending.Form.Submit',
              })}
            </Button>
          </form>
          <p>{localize({ message: 'LMSConnectionPending.Paragraph.3' })}</p>
        </SignupContent>
      </SignupContainer>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    email: state.user.authUser.email,
  };
};

export default connect(mapStateToProps)(withRouter(LMSConnectionPendingContainer));
