import { useState, useRef, useEffect } from 'react';
import { withRouter } from 'utils/withRouter';
import CourseDetails from 'components/Course/Edit/CourseDetails';
import PageContainer from 'components/layout/PageContainer';
import OnLeaveModal from 'components/modals/OnLeaveModal';
import { updateCourseMutation, useFetchCourse } from 'hooks/course';

function EditCourseDetailsContainer(props: any) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updateError, setUpdateError] = useState('');
  const formRef = useRef();
  const { data: course } = useFetchCourse(props.router.params.courseId);
  const updateCourse = updateCourseMutation();

  useEffect(() => {
    if (updateCourse.isSuccess) {
      handleCancelEdit();
    }
  }, [updateCourse.isSuccess]);

  const sanitizeDetailsValues = (data: any) => {
    const detailsData = { ...data };
    detailsData.section = detailsData.section || '';
    detailsData.code = detailsData.code || '';
    detailsData.description = detailsData.description || '';
    const courseData = { ...course, ...detailsData };
    return courseData;
  };

  const handleCancelEdit = () => {
    props.router.push(`/course/${props.router.params.courseId}`);
  };

  const onDetailsSubmit = (detailsData: any) => {
    const courseData = sanitizeDetailsValues(detailsData);
    setIsSubmitting(true);

    updateCourse.mutate(courseData, {
      onError: (e: any) => setUpdateError(e?.response?.data?.errors?.message),
    });
  };

  return (
    <PageContainer>
      <CourseDetails
        authUser={props.authUser}
        course={course}
        onCancel={handleCancelEdit}
        onSubmit={onDetailsSubmit}
        formRef={formRef}
        isEditing
        updateError={updateError}
      />
      {!isSubmitting && <OnLeaveModal formRef={formRef} />}
    </PageContainer>
  );
}

export default withRouter(EditCourseDetailsContainer);
