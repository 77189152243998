import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Select from 'components/core/input/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import CustomTimePicker from 'components/core/input/DateAndTime/CustomTimePicker';
import * as FormatUtils from '@kritik/utils/format';
import Typography from 'components/core/Typography';
import localUtils from 'components/core/input/DateAndTime/utils';

function DateInput(props: any) {
  const timeOptions = [
    { label: 'End of day', value: '11:59 PM' },
    { label: 'Afternoon', value: '1:00 PM' },
    { label: 'Morning', value: '9:00 AM' },
  ];
  if (localUtils.hasCustomTime(props.customTime)) {
    timeOptions.push({
      label: 'My Custom Time',
      value: localUtils.convertCustomTimeToString(props.customTime),
    });
  }
  timeOptions.push({ label: 'Custom', value: '' });

  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDay, setSelectedDay] = useState(props.value);
  const [isSelectingTime, setIsSelectingTime] = useState(false);
  const [isSelectingCustomTime, setIsSelectingCustomTime] = useState(false);

  useEffect(() => {
    const formattedValue = FormatUtils.readableTimeFormat(props.value);
    const initialTimeSelection = timeOptions.find((option) => {
      return option.value === formattedValue;
    });
    setSelectedTime(initialTimeSelection || { value: formattedValue });
    setSelectedDay(props.value);
  }, [moment(props.value).unix()]);

  function handleDateChange(day: any, time: any) {
    const combinedDate = localUtils.combineDateAndTime(day, time);
    return props.onDateChange(combinedDate);
  }

  function getMinDate(institutionTimeZone) {
    let _minDate;
    // current time should be institution time zone
    const currentDate = FormatUtils.setLocalZone(new Date(), institutionTimeZone);
    if (props.disabled || props.disableMinDate) {
      _minDate = null;
    } else if (props.minDate) {
      if (props.minDate < currentDate) {
        _minDate = currentDate;
      } else {
        _minDate = props.minDate;
      }
    } else {
      _minDate = currentDate;
    }
    return _minDate;
  }

  function handleOptionClick(e: any) {
    const selectedLabel = e.currentTarget.children[0].innerText;
    if (selectedLabel === 'Custom') {
      setIsSelectingTime(true);
      setIsSelectingCustomTime(true);
    } else {
      const selectedOption = timeOptions.find((option) => {
        return option.label === selectedLabel;
      });
      handleDateChange(selectedDay, selectedOption);
      setIsSelectingTime(false);
    }
  }

  function renderTimeOptions() {
    if (!selectedTime) {
      return null;
    }

    const selectedTimeOption = timeOptions.find((option) => {
      return option.value === (selectedTime as any).value;
    });

    return timeOptions.map((option, i) => {
      const isSelected =
        (!selectedTimeOption && option.label === 'Custom') ||
        (selectedTimeOption && selectedTimeOption.label === option.label);

      return (
        <MenuItem
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '250px',
          }}
          key={option.value}
          selected={isSelected}
          value={option.value}
          onClick={handleOptionClick}
          data-testid={`time-option-${i}`}
        >
          <Typography color={isSelected ? 'accent' : 'dark'}>{option.label}</Typography>
          <Typography color={isSelected ? 'accent' : 'light'}>{option.value}</Typography>
        </MenuItem>
      );
    });
  }

  function renderCustomTimePicker() {
    return (
      <CustomTimePicker
        onBack={() => {
          setIsSelectingCustomTime(false);
          setIsSelectingTime(true);
        }}
        onSet={(time: any) => {
          setIsSelectingCustomTime(false);
          setIsSelectingTime(false);
          handleDateChange(selectedDay, {
            value: time,
          });
          if (typeof props.onCustomTimeChange === 'function') {
            props.onCustomTimeChange(time);
          }
        }}
        initialValue={moment(new Date()).format('hh:mm a')}
      />
    );
  }

  function renderTimeSelect() {
    return (
      <div className="datepicker__time-select">
        <label
          htmlFor={`${props.label}-time-pick`}
          className="visually-hidden"
          tabIndex={0}
        >{`${props.label} time`}</label>

        <Select
          menuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          open={isSelectingTime}
          onOpen={() => {
            return setIsSelectingTime(true);
          }}
          onClose={() => {
            setIsSelectingCustomTime(false);
            setIsSelectingTime(false);
          }}
          value={selectedTime}
          renderValue={(option: any) => {
            return option.value;
          }}
          disabled={props.disabled}
          testid={`${props.testid}-time-pick`}
          id={`${props.label}-time-pick`}
          aria-label={`${props.label} time`}
        >
          {isSelectingCustomTime ? renderCustomTimePicker() : renderTimeOptions()}
        </Select>
      </div>
    );
  }

  const classes = classNames('datepicker', {
    'datepicker--disabled': props.disabled,
  });

  return (
    <div className={classes}>
      <Grid container alignItems="center">
        <DatePicker
          className="datepicker__input"
          placeholderText={`Click to select a date for ${props.label}`}
          selected={FormatUtils.setLocalZone(props.value, props.timeZone)}
          minDate={getMinDate(props.timeZone)}
          onChange={(day: any) => {
            handleDateChange(day, selectedTime);
          }}
          disabled={props.disabled}
          dateFormat="EEE, MMM d"
          open={props.isOpen}
          customInput={<input data-testid={props.testid} type="text" />}
          label={`${props.label}`}
        />
        <span className="datepicker__separator">@</span>
        {renderTimeSelect()}
      </Grid>
    </div>
  );
}

DateInput.defaultProps = {
  minDate: new Date(),
  disabled: false,
  customTime: null,
  disableMinDate: false,
};

export default DateInput;
