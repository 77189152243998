import { createRestApiClient } from 'utils/createRestApiClient';
const apiEndpoint = import.meta.env.VITE_API_URL;

export default (options = {}) => {
  const client =
    (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint });
  return {
    addGroup: (data: any) => {
      return client.request({
        method: 'POST',
        url: '/groups',
        data,
      });
    },
    changeName: (data: any) => {
      return client.request({
        method: 'PUT',
        url: `/groups/name`,
        data,
      });
    },
    changeLeader: (data: any) => {
      return client.request({
        method: 'PUT',
        url: `/groups/leader`,
        data,
      });
    },
    moveMember: (data: any) => {
      return client.request({
        method: 'PUT',
        url: `/groups/move-member`,
        data,
      });
    },
    getAllGroupSets: ({ courseId }: any) => {
      return client.request({
        method: 'GET',
        url: `/groups/get-sets/${courseId}`,
      });
    },
    getGroupsFromGroupSet: ({ groupSetId }: any) => {
      return client.request({
        method: 'GET',
        url: `/groups/get-groups-from-set/${groupSetId}`,
      });
    },
    getGroupsByAssignment: ({ courseId, assignmentId }: any) => {
      return client.request({
        method: 'GET',
        url: `/groups/course/${courseId}/assignment/${assignmentId}`,
      });
    },
    generateGroupSet: (data: any) => {
      return client.request({
        method: 'POST',
        url: `/groups/generate-set`,
        data,
      });
    },
    isGroupSetInUse: ({ groupSetId }: any) => {
      return client.request({
        method: 'GET',
        url: `/groups/group-set/${groupSetId}/status`,
      });
    },
    deleteGroupSet: ({ groupSetId }: any) => {
      return client.request({
        method: 'PUT',
        url: `/groups/delete-set/${groupSetId}`,
      });
    },
    deleteEmptyGroup: (data: any) => {
      return client.request({
        method: 'PUT',
        url: `/groups/delete`,
        data,
      });
    },
    generateFromCSV: ({ formData }: any) => {
      return client.request({
        method: 'POST',
        url: '/groups/generate-from-csv',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    getGroupPresentationParticipation: (
      activityId: string
    ): Promise<{ data: activities.$id.creator_groups.GET.Response }> => {
      return client.request({
        method: 'GET',
        url: `/activities/${activityId}/presentation/group-participation`,
      });
    },
    syncGroups: ({ courseId }: any) => {
      return client.request({
        method: 'POST',
        url: `/groups/course/${courseId}/sync`,
      });
    },
  };
};
