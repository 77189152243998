import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SyncRosterPopup from 'components/RosterManager/SyncRosterPopup';
import Popover from '@material-ui/core/Popover';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from 'components/buttons/Button';
import { SYNC_ROSTER_MODAL_ID } from 'components/RosterManager/SyncRosterPopup/SyncRosterModal';
import { useLocation } from 'react-router';

type Props = {
  usingGradeSync: boolean;
  modalStatus: any;
  onRosterSync: () => {};
  user: any;
};

const SyncRosterButton = (props: Props) => {
  const location = useLocation();
  const [popupOpen, setPopupOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);

  useEffect(() => {
    if (popupOpen) {
      setPopupOpen(false);
    }
  }, [location]);

  const { usingGradeSync, modalStatus, onRosterSync } = props;

  function toggleSyncRosterPopup(event: any) {
    setAnchor(event.currentTarget);
    setPopupOpen(!popupOpen);
  }

  function closePopup() {
    if (modalStatus.options?.props?.id === SYNC_ROSTER_MODAL_ID) {
      return;
    }
    setPopupOpen(false);
  }

  const shouldShowInfoIcon = !usingGradeSync;

  return (
    <div>
      <Button
        type="primary"
        onClick={(event: any) => {
          toggleSyncRosterPopup(event);
        }}
        chevron="down"
        info={shouldShowInfoIcon}
        className="student-manager__button-right"
        testid="sync-roster"
      >
        Sync Roster
      </Button>
      <Popover
        open={popupOpen}
        anchorEl={anchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={closePopup}
      >
        <ClickAwayListener onClickAway={closePopup}>
          <SyncRosterPopup usingGradeSync={usingGradeSync} onRosterSync={onRosterSync} />
        </ClickAwayListener>
      </Popover>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    modalStatus: state.modal,
    user: state.user,
  };
};

export default connect(mapStateToProps)(SyncRosterButton);
