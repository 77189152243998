import { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'router';
import { getGroupsByAssignment } from 'actions/groups';
import { getAssignment, getAssignmentSubmissions, listCreationsByActivity } from 'actions/activity';
import { selectAssignment, selectCourse } from 'actions/select';
import { getStudentInCourse } from 'actions/users';
import { listSpotlights, selectSpotlights } from 'redux/spotlight';
import { getCourse } from 'selectors/course';
import { getStudentFromUser } from 'selectors/student';
import { GET_STUDENT } from 'types';
import LoaderOverlay from 'components/Loaders/OverlaySpinner';
import { getAssignment as selectActivity } from 'selectors/activity';
import getPaginatedResults from 'actions/pagination/actions';
import Access from 'components/Access';
import { setCreationTableSearch } from 'redux/creationTable/actions';
import StudentView from './StudentView';
import InstructorView from './InstructorView';
import { RouterProp, withRouter } from 'utils/withRouter';
import { UserRoleInCourse } from 'app-types';
import { withUserRole } from 'utils/withUserRole';
import { isPresentationActivity } from '@kritik/utils/activity';
import { studentParticipatesInActivityFromStudentView } from '@kritik/utils/stage';

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    course: getCourse(state),
    student: getStudentFromUser(state, state.selected.courseId),
    getStudentSuccess: state.async[GET_STUDENT].success,
    getStudentError: state.async[GET_STUDENT].error,
    spotlights: selectSpotlights(state),
    activity: selectActivity(state, state.selected.assignmentId),
  };
};

type MainState = any;

type MainProps = {
  user: any;
  course: any;
  student: any;
  getStudentSuccess: any;
  getStudentError: any;
  spotlights: any;
  activity: any;
  getStudentInCourse: any;
  getAssignment: any;
  getAssignmentSubmissions: any;
  listCreationsByActivity: any;
  selectCourse: any;
  selectAssignment: any;
  push: any;
  getGroupsByAssignment: any;
  listSpotlights: any;
  getPaginatedResults: any;
  setCreationTableSearch: any;
  router: RouterProp;
  userRole: UserRoleInCourse;
};

class Main extends Component<MainProps, MainState> {
  constructor(props) {
    super(props);
    this.state = {
      isDataLoaded: false,
    };
  }

  async componentDidMount() {
    const {
      router,
      userRole: { isStudentInCourse },
    } = this.props;
    const { courseId, assignmentId } = router.params;

    const toFetch = [
      this.props.getAssignment({ id: assignmentId }),
      this.props.selectCourse(courseId),
      this.props.selectAssignment(assignmentId),
      this.props.listSpotlights({ activity: assignmentId }),
    ];
    if (isStudentInCourse) {
      toFetch.push(
        this.props.getStudentInCourse({ courseId }),
        this.props.getGroupsByAssignment({ courseId, assignmentId }),
        this.props.getAssignmentSubmissions({ assignmentId })
      );
    }

    await Promise.all(toFetch);
    this.setState({ isDataLoaded: true });
  }

  componentDidUpdate(prevProps: {}) {
    if (
      this.props.getStudentError &&
      this.props.getStudentError != (prevProps as any).getStudentError
    ) {
      this.props.router.push('/');
    }
    if (
      this.props.getStudentSuccess &&
      this.props.student &&
      this.props.student.removedOn != null
    ) {
      this.props.router.push('/');
    }

    if (
      this.props.activity &&
      this.props.student &&
      isPresentationActivity(this.props.activity) &&
      !studentParticipatesInActivityFromStudentView({
        activity: this.props.activity,
      })
    ) {
      this.props.router.push('/');
    }
  }

  componentWillUnmount() {
    this.props.setCreationTableSearch('');
  }

  render() {
    const isStudent = this.props.userRole.isStudentInCourse;

    const { course, student } = this.props;

    if (!course || !course.user) {
      return null;
    }

    if (isStudent && (!student || student.removedOn != null)) {
      return null;
    }

    if (!isStudent && !(course.userRole === 'instructor')) {
      (this.props as any).router.push('/');
    }

    if (!this.state.isDataLoaded) {
      return <LoaderOverlay isOpen label="Loading Activity..." />;
    }

    return (
      <Access
        instructor={<InstructorView {...this.props} />}
        student={<StudentView {...this.props} />}
      />
    );
  }
}

export default withUserRole(
  withRouter(
    connect(mapStateToProps, {
      getStudentInCourse,
      getAssignment,
      getAssignmentSubmissions,
      listCreationsByActivity,
      selectCourse,
      selectAssignment,
      push,
      getGroupsByAssignment,
      listSpotlights,
      getPaginatedResults,
      setCreationTableSearch,
    })(Main)
  )
);
