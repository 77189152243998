import React, { useState } from 'react';
import { connect } from 'react-redux';
import DateInput from 'components/core/input/DateAndTime';
import FieldLabel from 'components/core/form/FieldLabel';
import { timeDisplayFromMinutes, getMinutesBetweenDates } from '@kritik/utils/format';
import ConfirmModal from 'components/modals/ConfirmModal';
import { selectTimeZone } from 'selectors/user';

const mapStateToProps = (state: any) => {
  return {
    timeZone: selectTimeZone(state),
    modalStatus: state.modal,
  };
};

const CustomGracePeriodModal = ({ isOpen, onCancel, minDate, onSelect, timeZone }: any) => {
  const [selectedDate, setSelectedDate] = useState(minDate);

  const selectGracePeriod = () => {
    onSelect(selectedDate);
  };

  const cancel = () => {
    return onCancel();
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const renderTimeSelected = () => {
    if (!selectedDate || !minDate) {
      return null;
    }
    const minutesToDate = getMinutesBetweenDates(selectedDate, minDate);
    if (minutesToDate === 0) {
      return null;
    }
    const displayTimeSelection = timeDisplayFromMinutes(minutesToDate);
    return (
      <div>
        <strong> Length </strong>
        <span> {displayTimeSelection} </span>
      </div>
    );
  };

  return (
    <ConfirmModal
      className="custom-grace-period-modal"
      isFullWidth
      maxWidth="xs"
      isOpen={Boolean(isOpen)}
      onCancel={() => {
        return cancel();
      }}
      cancelButton="Cancel"
      onConfirm={() => {
        return selectGracePeriod();
      }}
      confirmButton="Set Grace Period"
      title="Select Grace Period End Date"
    >
      <div className="custom-grace-period-modal__date-picker">
        <FieldLabel label="End Date" color="light" />
        <DateInput
          label="End Date"
          value={selectedDate}
          onDateChange={(date: any) => {
            return handleDateChange(date);
          }}
          minDate={minDate}
          disabled={false}
          isOpen
          timeZone={timeZone}
        />
      </div>
      {renderTimeSelected()}
    </ConfirmModal>
  );
};

export default connect(mapStateToProps, {})(CustomGracePeriodModal);
