import React from 'react';
import createSvgIcon from '../util/generateIconSVG';

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M14.3839 6.71429C14.3839 6.41071 14.2589 6.11607 14.0536 5.90179L13.3839 5.23214C13.1696 5.02679 12.875 4.90178 12.5714 4.90178C12.2679 4.90178 11.9732 5.02679 11.7679 5.23214L9.14286 7.85714V1.57143C9.14286 0.946428 8.625 0.428571 8 0.428571H6.85714C6.23214 0.428571 5.71429 0.946428 5.71429 1.57143V7.85714L3.08929 5.23214C2.88393 5.02679 2.58929 4.90178 2.28571 4.90178C1.98214 4.90178 1.6875 5.02679 1.47321 5.23214L0.8125 5.90179C0.598214 6.11607 0.473214 6.41071 0.473214 6.71429C0.473214 7.01786 0.598214 7.3125 0.8125 7.51786L6.625 13.3393C6.83036 13.5446 7.125 13.6696 7.42857 13.6696C7.73214 13.6696 8.02679 13.5446 8.24107 13.3393L14.0536 7.51786C14.2589 7.3125 14.3839 7.01786 14.3839 6.71429Z"
      fill="#4844A3"
    />
  </React.Fragment>,
  '0 0 15 14'
);
