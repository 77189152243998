import { createRestApiClient } from 'utils/createRestApiClient';
const apiEndpoint = import.meta.env.VITE_API_URL;

type FlagCreationParams = {
  creationId: string;
} & creations.$id.flag.POST.Request;

export default (options = {}) => {
  const client =
    (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint });
  return {
    get: (params: { id: string }) => {
      return client.request({
        method: 'GET',
        url: `/submissions/${params.id}`,
        params,
      });
    },
    list: (params: any) => {
      return client.request({
        method: 'GET',
        url: `/submissions`,
        params,
      });
    },
    create: (data: any) => {
      return client.request({
        method: 'POST',
        url: '/submissions',
        data,
      });
    },
    update: (data: any) => {
      return client.request({
        method: 'PATCH',
        url: `/submissions/${data.creationId}/update`,
        data,
      });
    },
    newUpdate: (data: any) => {
      return client.request({
        method: 'PATCH',
        url: `/submissions/${data._id}`,
        data,
      });
    },
    // TODO: we need to merge this into generic update api
    updateScoreView: ({ creationId, data }: any) => {
      return client.request({
        method: 'PATCH',
        url: `/submissions/${creationId}/update`,
        data,
      });
    },
    getCreationsToEvaluate: ({ activityId, courseId, studentId }: any) => {
      return client.request({
        method: 'GET',
        url: `/submissions/to-evaluate/${courseId}/${activityId}/${studentId}`,
      });
    },
    getFlaggedCreationWithMatchedSentences: ({
      creationId,
    }: {
      creationId: string;
    }): Promise<DataResponse<creations.flagged.$id.GET.Response>> => {
      return client.request({
        method: 'GET',
        url: `/creations/flagged/${creationId}`,
      });
    },
    countPlagiarismCreationMatches: ({
      creationId,
    }: {
      creationId: string;
    }): Promise<DataResponse<creations.flagged_count.$id.GET.Response>> => {
      return client.request({
        method: 'GET',
        url: `/creations/flagged-count/${creationId}`,
      });
    },
    flagCreation: ({ creationId, explanation, reason }: FlagCreationParams) => {
      return client.request({
        method: 'POST',
        url: `/creations/${creationId}/flag`,
        data: { explanation, reason },
      });
    },
    getCreationFlags: ({
      creationId,
    }: {
      creationId: string;
    }): Promise<DataResponse<creations.$id.flags.GET.Response>> => {
      return client.request({
        method: 'GET',
        url: `/creations/${creationId}/flags`,
      });
    },
  };
};
