export const ACTIVITY_TEMPLATE = {
  objective:
    '<p>Example Objective: To gain learning curiosity toward a particular topic by creating questions.</p>',
  instructions:
    '<p>Example Instruction: Read section 1.1 through 1.3 of your textbook. Think of a good question that can be answered by reading AND fully comprehending the content of these sections. The question should evoke higher order thinking within the context of the materials. The rubric provided below will be used to assess your score.</p>',
};

export default {
  ACTIVITY_TEMPLATE,
};
