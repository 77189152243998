import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { navigateToActivityList } from 'actions/activity';
import { Button, ButtonContainer } from 'components/buttons';
import LinearProgress from 'components/Loaders/ProgressBar';
import VerticalList from 'components/layout/VerticalList';
import { InlineInformation } from 'components/layout';
import {
  selectSuccessfulImports,
  selectActivityIdsToImport,
  selectIsImporting,
} from 'redux/activityImport/select';
import { abortImportAction } from 'redux/activityImport/actions';

const LoadingModal = (props: any) => {
  const onClose = () => {
    props.navigateToActivityList({ courseId: props.courseId });
  };

  const getProgress = () => {
    return props.successfulImports.length / props.activitiesToImport.length;
  };

  const renderLoadingDialog = () => {
    return (
      <Dialog open maxWidth="xs" fullWidth>
        <DialogTitle>Importing Activities</DialogTitle>
        <DialogContent>
          <DialogContentText>This should only take a moment...</DialogContentText>
        </DialogContent>
        <DialogContent>
          <LinearProgress
            progress={getProgress() * 100}
            progressLabel={`${props.successfulImports.length}/${props.activitiesToImport.length}`}
          />
        </DialogContent>
        <DialogActions className="kritik-confirm__buttons">
          <ButtonContainer>
            <Button
              onClick={() => {
                return props.abortImportAction();
              }}
              type="secondary"
              autoFocus
            >
              Cancel
            </Button>
          </ButtonContainer>
        </DialogActions>
      </Dialog>
    );
  };

  const getSuccessBanner = () => {
    if (props.successfulImports.length) {
      return (
        <InlineInformation
          type="success"
          title={`${props.successfulImports.length} ${
            props.successfulImports.length === 1 ? 'Activity' : 'Activities'
          } imported successfully`}
        />
      );
    }
    return null;
  };

  const getErrorBanner = () => {
    const failedToImport = props.activitiesToImport.length - props.successfulImports.length;
    if (failedToImport > 0) {
      return (
        <InlineInformation
          type="danger"
          title={`${failedToImport} ${
            failedToImport === 1 ? 'Activity' : 'Activities'
          } failed to import`}
        />
      );
    }
    return null;
  };

  const renderResultDialog = () => {
    return (
      <Dialog open maxWidth="xs" fullWidth aria-labelledby="activity-import-complete-title">
        <DialogTitle id="activity-import-complete-title">Activity Import Complete</DialogTitle>
        <DialogContent>
          <VerticalList space="xs" itemList={[getSuccessBanner(), getErrorBanner()]} />
        </DialogContent>
        <DialogActions className="kritik-confirm__buttons">
          <ButtonContainer>
            <Button
              onClick={onClose}
              type="secondary"
              testid="close-import-activity-modal"
              autoFocus
            >
              Close
            </Button>
          </ButtonContainer>
        </DialogActions>
      </Dialog>
    );
  };

  if (!props.isOpen) {
    return null;
  }

  if (props.isImporting) {
    return renderLoadingDialog();
  }

  return renderResultDialog();
};

const mapStateToProps = (state: any) => {
  return {
    courseId: state.selected.courseId,
    successfulImports: selectSuccessfulImports(state),
    activitiesToImport: selectActivityIdsToImport(state),
    isImporting: selectIsImporting(state),
  };
};

export default connect(mapStateToProps, {
  navigateToActivityList,
  abortImportAction,
})(LoadingModal);
