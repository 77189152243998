import * as _ from 'lodash-es';
import { Field, FormSpy } from 'react-final-form';
import FormRadio from 'components/core/form/RadioField';
import FormTitle from 'components/core/form/Title';
import validators from 'components/ActivityEdit/Details/Fields/validators';
import FormFieldInfo from 'components/core/form/FieldInfo';
import { AdvancedOptionField } from 'components/ActivityEdit/AdvancedOptions/Field';
import NoticeBoard from 'components/layout/NoticeBoard';
import { isCreateOrLater } from '@kritik/utils/stage';
import { IS_MULTIPLE_TOPICS, NUMBER_OF_TOPICS } from './constants';
import { Activity } from 'old-common/types.generated';
import NumberField from 'components/core/form/NumberField';
import { FormApi } from 'final-form';
import { useEffect } from 'react';

const letters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

const ActivityTopic = (props: { activity: Activity; values: any; form: FormApi }) => {
  const isMultiTopicsEnabled = Boolean(props.values[IS_MULTIPLE_TOPICS]);
  const numberOfTopics = props.values[NUMBER_OF_TOPICS];
  const topicsToLetters = _.range(numberOfTopics)
    .map((i) => letters[i])
    .join(', ');
  const canUpdate = !isCreateOrLater(props.activity);

  useEffect(() => {
    /**
     * If the activity has no topics and it's a multitopic activity, it means it was created before KR-4808 was released.
     * To make sure the instructor doesn't see "0 Topics" in the UI, we update the number of topics to 2.
     * This can be removed after some time.
     */
    if (props.values[IS_MULTIPLE_TOPICS] && props.values[NUMBER_OF_TOPICS] < 2) {
      setTimeout(() => {
        props.form.change(NUMBER_OF_TOPICS, 2);
      }, 100);
    }
  }, []);

  return (
    <AdvancedOptionField>
      <FormTitle label="Multiple Topics" />
      <Field name={IS_MULTIPLE_TOPICS} validate={validators.isRequired}>
        {({ input }) => {
          return (
            <FormRadio
              options={[
                {
                  value: false,
                  label: 'Disabled',
                  testid: 'activity-multitopics-disable-btn',
                  isDisabled: isMultiTopicsEnabled && !canUpdate,
                },
                {
                  value: true,
                  label: 'Enabled',
                  testid: 'activity-multitopics-enable-btn',
                  isDisabled: !isMultiTopicsEnabled && !canUpdate,
                },
              ]}
              onChange={(e: any) => {
                const shouldEnable = e.target.value === 'true';
                if (shouldEnable && numberOfTopics < 2) {
                  /**
                   * we're updating the activity to use multiple topics.
                   * because the activity was already created without any topics,
                   * we need to update the number of topics to 2
                   */
                  props.form.change(NUMBER_OF_TOPICS, 2);
                }
                return input.onChange(shouldEnable);
              }}
              value={input.value}
              disabled
              type="button"
              info="Multiple Topics allows instructors to cover a wider range of content by having students do
        the same activity with varying instructions."
              legendText="Legend.Text.MultipleTopics"
            />
          );
        }}
      </Field>
      {isMultiTopicsEnabled && (
        <>
          {canUpdate && (
            <Field name={NUMBER_OF_TOPICS} validate={validators.isRequired} defaultValue={2}>
              {({ input }) => (
                <NumberField
                  label="Number of Topics"
                  value={input.value}
                  onChange={(e: any) => {
                    return input.onChange(Number(e.target.value));
                  }}
                  min={2}
                  max={26}
                  testid="number-of-topics"
                />
              )}
            </Field>
          )}
          <div className="activity-edit-topic__info">
            <NoticeBoard title="How it works:">
              <div className="activity-edit-topic__info-section">
                <FormFieldInfo>
                  <FormTitle size="md" label="Create" />
                  Each student is assigned one topic to make a creation for. Students will be
                  distributed proportionally across all topics.
                </FormFieldInfo>
                <FormFieldInfo type="attention">
                  {numberOfTopics} Topics: {topicsToLetters}
                </FormFieldInfo>
              </div>
              <div className="activity-edit-topic__info-section">
                <FormFieldInfo>
                  <FormTitle size="md" label="Evaluate" />
                  Students will evaluate peer Creations on a topic different from their own.
                </FormFieldInfo>
                <FormFieldInfo type="attention">Evaluate Different Topics</FormFieldInfo>
              </div>
              <div className="activity-edit-topic__info-section">
                <FormFieldInfo>
                  <FormTitle size="md" label="Feedback" />
                  Feedback works the same as regular activities.
                </FormFieldInfo>
              </div>
            </NoticeBoard>
          </div>
        </>
      )}
    </AdvancedOptionField>
  );
};

const Spy = (props: any) => {
  return <FormSpy component={ActivityTopic} {...props} />;
};

export default Spy;
