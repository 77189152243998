import { SORT_OPTIONS } from 'components/CreationList/constant';
import { sortByDisputedDate } from './sortByDisputedDate';
import { sortByResolvedDate } from './sortByResolvedDate';
import { sortByStatus } from './sortByStatus';
import { sortByStudentName } from './sortByName';

export const sortItemList = (itemList: any, sortedColumn: any) => {
  const ascending = sortedColumn.sorting.isAscending;
  switch (sortedColumn.header) {
    case SORT_OPTIONS.NAME: {
      return sortByStudentName(itemList, ascending);
    }
    case SORT_OPTIONS.DISPUTED_DATE: {
      return sortByDisputedDate(itemList, ascending);
    }
    case SORT_OPTIONS.RESOLVED_DATE: {
      return sortByResolvedDate(itemList, ascending);
    }
    case SORT_OPTIONS.STATUS: {
      return sortByStatus(itemList, ascending);
    }
    default: {
      return itemList;
    }
  }
};
