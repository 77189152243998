import React from 'react';
import classNames from 'classnames';
import LoadingSpinner from 'components/Loaders/Spinner';

const COLORS = {
  WHITE: 'white',
};

const LoaderOverlay = (props: any) => {
  if (!props.isOpen) {
    return null;
  }

  const classes = classNames(
    'loader-overlay',
    {
      'variant-white': props.color === COLORS.WHITE,
    },
    props.className
  );

  const renderLabel = () => {
    if (!props.label) {
      return null;
    }
    return <div className="loader-overlay__label">{props.label}</div>;
  };

  return (
    <div className={classes}>
      <div className="loader-overlay__container">
        <LoadingSpinner className="loader-overlay__spinner" />
        {renderLabel()}
      </div>
    </div>
  );
};

LoaderOverlay.defaultProps = {
  isOpen: false,
};

export default LoaderOverlay;
