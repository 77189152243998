export const defaultFileRestrictions = {
  allowAudio: false,
  allowExcel: true,
  allowImages: true,
  allowPDF: true,
  allowPowerpoint: true,
  allowText: true,
  allowVideo: false,
  allowWord: true,
  allowZip: true,
};

export const allFilesRestricted = {
  allowAudio: false,
  allowExcel: false,
  allowImages: false,
  allowPDF: false,
  allowPowerpoint: false,
  allowText: false,
  allowVideo: false,
  allowWord: false,
  allowZip: false,
};

export default {
  defaultFileRestrictions,
  allFilesRestricted,
};
