import { asyncActions, RESET_ASYNC } from 'types';

const initialState = asyncActions.call.reduce((a, c) => {
  return {
    ...a,
    [c]: {
      busy: false,
      success: '',
      error: '',
    },
  };
}, {});

function reducer(state = initialState, action = {}) {
  if (asyncActions.call.indexOf((action as any).type) > -1) {
    return {
      ...state,
      [(action as any).type]: { success: '', error: '', busy: true },
    };
  }
  if (asyncActions.success.indexOf((action as any).type) > -1) {
    return {
      ...state,
      [(action as any).type.replace(/_SUCCESS$/, '')]: {
        success: (action as any).success,
        error: '',
        busy: false,
      },
    };
  }
  if (asyncActions.error.indexOf((action as any).type) > -1) {
    return {
      ...state,
      [(action as any).type.replace(/_FAILURE$/, '')]: {
        success: '',
        error: (action as any).error,
        busy: false,
      },
    };
  }
  if ((action as any).type === RESET_ASYNC) {
    return {
      ...state,
      [(action as any).payload]: { success: '', error: '', busy: false },
    };
  }

  return state;
}

export default reducer;
