import React from 'react';
import { connect } from 'react-redux';
import StarRubricMarks from 'components/General/StarRubricMarks';
import Grid from '@material-ui/core/Grid';
import { STAR_RUBRIC_MARKS } from 'components/General/constant';

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    course: state.entities.courses[state.selected.courseId],
  };
};

function EvaluationPendingView(props: any) {
  const { rubric } = props;

  return (
    <React.Fragment>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item xs={3}>
          <h3 className="header-3">Score</h3>
        </Grid>
        <Grid item xs={3}>
          <div style={{ textAlign: 'right' }}>Not scored yet</div>
        </Grid>
      </Grid>
      <StarRubricMarks
        rubric={rubric}
        type="secondary"
        precision={1}
        tableType={STAR_RUBRIC_MARKS.DEFAULT}
      />
      <div>
        <h4 className="header-4">Written Evaluation</h4>
        <p>Evaluator has not completed the written evaluation yet.</p>
      </div>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(EvaluationPendingView);
