import round from 'lodash/round';
import {
  getTotalCriteriaWeight,
  getLevelsInCriteria,
  getCriterion,
  isUseModeRubric,
} from '@kritik/utils/rubric';
import { getMode } from '@kritik/utils/grade/general';
import { Evaluation, Rubric, Creation } from '@kritik/types.generated';

type CalcAvgCreationScoreArgs = {
  creation: Creation & { scores: Evaluation[] };
  rubric: Rubric;
  startingScore?: number;
  lateCreationPenaltyPercentage?: number;
};

type GetAppliedLateCreationPenaltyPercentageArgs = {
  creation: Creation & { scores: Evaluation[] };
  lateCreationPenaltyPercentage?: number;
};

type GetScoreEarnedArgs = {
  marks: number[];
  rubric: Rubric;
  startingScore?: number;
};

export class CreationScoreUtil {
  static calcAvgCreationScore({
    creation,
    rubric,
    startingScore = 0,
    lateCreationPenaltyPercentage = 0,
  }: CalcAvgCreationScoreArgs) {
    const appliedLateCreationPenaltyPercentage = this.getAppliedLateCreationPenaltyPercentage({
      creation,
      lateCreationPenaltyPercentage,
    });

    const weightEarned = this.calcPercentageCreationScore(creation, rubric, startingScore);
    const totalWeight = getTotalCriteriaWeight(rubric);
    const finalScore = (weightEarned / (totalWeight * 100)) * 100;
    return round(finalScore) - appliedLateCreationPenaltyPercentage;
  }

  static calcPercentageCreationScore(
    creation: Creation & { scores: Evaluation[] },
    rubric: Rubric,
    startingScore: number = 0
  ) {
    if (!creation) {
      return 0;
    }
    const rawCreationScore = this.getRawCreationScore(creation, rubric);
    const scoreEarned = this.getScoreEarned({ marks: rawCreationScore, rubric, startingScore });
    return round(scoreEarned, 2);
  }

  static getScoreEarned({ marks, rubric, startingScore = 0 }: GetScoreEarnedArgs) {
    const criterionScoreSum = marks.reduce((acc, mark, i) => {
      const levelsInCriteria = getLevelsInCriteria(rubric, i);
      const criterion = getCriterion(rubric, i);
      const criterionScore = this.scaleMark({
        mark,
        levelsInCriteria,
        startingScore,
      });
      return acc + criterionScore * criterion.weight;
    }, 0);
    return criterionScoreSum;
  }

  static scaleMark({
    mark,
    levelsInCriteria,
    startingScore = 0,
  }: {
    mark: number;
    levelsInCriteria: number;
    startingScore: number;
  }) {
    if (mark === 0) return 0;
    if (!startingScore || startingScore === 0 || levelsInCriteria === 1) {
      return (mark / levelsInCriteria) * 100;
    }
    const scaleFactor = (100 - startingScore) / (levelsInCriteria - 0.5);
    return startingScore + (mark - 0.5) * scaleFactor;
  }

  static calcFractionCreationScore(creation: Creation, rubric: Rubric) {
    if (!creation) {
      return 0;
    }
    const rawCreationScore = this.getRawCreationScore(creation, rubric);
    const scoreEarned = this.getFractionScoreEarned(rawCreationScore, rubric);
    return round(scoreEarned, 2);
  }

  static getFractionScoreEarned(marks: number[], rubric: Rubric) {
    const criterionScoreSum = marks.reduce((acc, mark, i) => {
      const levelsInCriteria = getLevelsInCriteria(rubric, i);
      const criterion = getCriterion(rubric, i);
      const criterionScore = this.calcCriterionScore(mark, levelsInCriteria, criterion);
      return acc + criterionScore;
    }, 0);
    return criterionScoreSum;
  }

  static calcCriterionScore(
    mark: number,
    levelsInCriterion: number,
    criterion: { weight: number }
  ) {
    return (mark / levelsInCriterion) * criterion.weight;
  }

  static calcCriterionScoreInPercent(mark: number, levelsInCriterion: number) {
    return (mark / levelsInCriterion) * 100;
  }

  static getTeacherScore = (creation: Creation) => {
    return creation.teacherScore;
  };

  static getRevisedTeacherScore = (creation: Creation) => {
    return creation.revisedTeacherScore;
  };

  static getRawCreationScore(creation: Creation, rubric: Rubric): number[] {
    if (this.isProfRevised(creation)) {
      return this.getRevisedTeacherScore(creation);
    }
    if (this.isInstructorGraded(creation)) {
      return this.getTeacherScore(creation);
    }
    const _modifiedScore = [...creation.rawWeightedAvgByCriteria];
    if (_modifiedScore.length) {
      const criteriaToModify: number[] = rubric.criteria.reduce((toModify, criterion, idx) => {
        if (criterion.useMode) {
          toModify.push(idx);
        }
        return toModify;
      }, []);
      criteriaToModify.forEach((index) => {
        const modes = getMode(creation.scores.map((score) => score.marks[index]));
        if (modes && modes.length === 1) {
          _modifiedScore[index] = modes[0];
        }
      });
    }
    return _modifiedScore;
  }

  static getModeScoringValidityForAllCriteria(creation: Creation, rubric: Rubric): string[] {
    if (!isUseModeRubric(rubric)) {
      return null;
    }

    if (!this.isProfRevised(creation) && !this.isInstructorGraded(creation)) {
      return rubric.criteria.reduce((toModify, criterion, idx) => {
        if (criterion.useMode) {
          const modes = getMode(creation.scores.map((score) => score.marks[idx]));
          if (!modes || modes.length !== 1) {
            toModify.push('OverruledModeScoring');
          } else if (modes && modes.length == 1) {
            toModify.push('ModeScoring');
          }
        } else {
          toModify.push('AverageScoring');
        }
        return toModify;
      }, []);
    } else {
      return rubric.criteria.reduce((toModify, criterion, idx) => {
        if (criterion.useMode) {
          toModify.push('ModeScoring');
        } else {
          toModify.push('AverageScoring');
        }
        return toModify;
      }, []);
    }
  }

  static isPeerGraded(creation: Creation) {
    if (!creation) {
      return false;
    }
    return creation.scores.length > 0 || creation.rawWeightedAvgByCriteria.length > 0;
  }

  static isInstructorGraded(creation: Creation) {
    if (!creation) {
      return false;
    }
    return creation.teacherScore.length > 0;
  }

  static isProfRevised(creation: Creation) {
    if (!creation) {
      return false;
    }
    return creation.revisedTeacherScore.length > 0;
  }

  static hasCreationScore(creation: Creation) {
    return (
      this.isPeerGraded(creation) ||
      this.isInstructorGraded(creation) ||
      this.isProfRevised(creation)
    );
  }

  static isPassed(creation: Creation) {
    if (this.isInstructorGraded(creation) || this.isProfRevised(creation)) {
      return creation.teacherPassed;
    }
    return creation.passed;
  }

  static getPassFailScore(creation: Creation) {
    return this.isPassed(creation) ? 100 : 0;
  }

  static isCreationGradedByInstructor(creation: Creation) {
    if (!creation) {
      return false;
    }
    return this.isInstructorGraded(creation) || this.isProfRevised(creation);
  }

  static checkRubricPassed({ rubric, passCount }: { rubric: Rubric; passCount: number }) {
    switch (rubric.passCriteriaRule) {
      case 'any':
        return passCount >= 1;
      case 'majority':
        return passCount > rubric.criteria.length / 2;
      case 'all':
      default:
        return passCount === rubric.criteria.length;
    }
  }

  static validatePassFailCriteria(rubric: Rubric, creation: Creation & { scores: Evaluation[] }) {
    if (rubric.passFailCondition === 'MinimumPercentage') {
      const creationScore = CreationScoreUtil.calcAvgCreationScore({ creation, rubric });
      return creationScore >= rubric.minimumPercentage;
    } else {
      const rawCreationScore = CreationScoreUtil.getRawCreationScore(creation, rubric);
      let numCriteriaPassed = 0;
      for (const score of rawCreationScore) {
        if (score >= parseInt(rubric.passLevel)) {
          numCriteriaPassed += 1;
        }
      }
      return CreationScoreUtil.checkRubricPassed({ rubric, passCount: numCriteriaPassed });
    }
  }

  static getAppliedLateCreationPenaltyPercentage({
    creation,
    lateCreationPenaltyPercentage,
  }: GetAppliedLateCreationPenaltyPercentageArgs) {
    let appliedLateCreationPenaltyPercentage = 0;
    if (creation.submissionStatus === 'LATE') {
      appliedLateCreationPenaltyPercentage = lateCreationPenaltyPercentage;
    }
    if (this.isCreationGradedByInstructor(creation)) {
      appliedLateCreationPenaltyPercentage = 0;
    }
    return appliedLateCreationPenaltyPercentage;
  }

  static calcClassAverageCreationScore({ averageGrade, rubric, startingScore }) {
    const scoreEarned = this.getScoreEarned({
      marks: averageGrade,
      rubric,
      startingScore,
    });
    const totalWeight = getTotalCriteriaWeight(rubric);
    const creationScore = (scoreEarned / (totalWeight * 100)) * 100;
    return round(creationScore);
  }
}
