import React from 'react';
import classNames from 'classnames';
import { ProgressBar } from 'components/layout';

const LabeledProgressBar = ({ label, progress, progressLabel, className, testid }: any) => {
  const customClasses = classNames('progress-bar-w-label', className);
  return (
    <div className={customClasses} data-testid={testid} tabIndex={0}>
      <label className="progress-bar-w-label__label">{label}</label>
      <div className="progress-bar-w-label__wrapper">
        <div className="progress-bar-w-label__bar">
          <ProgressBar progress={progress} />
        </div>
        <div className="progress-bar-w-label__value">{progressLabel}</div>
      </div>
    </div>
  );
};

export default LabeledProgressBar;
