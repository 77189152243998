import React, { Component } from 'react';
import Checkbox from 'components/core/input/Checkbox';

type State = any;

export default class extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.checkedHandler = this.checkedHandler.bind(this);
  }

  checkedHandler() {
    (this.props as any).node.setSelected(!(this.props as any).node.isSelected());
    (this.props as any).onSelectionChanged();
    this.setState({});
  }

  render() {
    if ((this.props as any).node.firstChild) {
      return null;
    }
    return (
      <Checkbox
        testid={(this.props as any).testid + '-' + (this.props as any).node.rowIndex}
        type="primary"
        onChange={this.checkedHandler}
        isChecked={(this.props as any).node.isSelected()}
      />
    );
  }
}
