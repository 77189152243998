import { createRestApiClient } from 'utils/createRestApiClient';

const apiEndpoint = import.meta.env.VITE_API_URL;

type ImpersonateUserArgs = {
  email: string;
  password: string;
};
export default (options = {}) => {
  const client =
    (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint });
  return {
    createLateSubmission: (params: any) => {
      return client.request({
        method: 'POST',
        url: '/admin/late-submission',
        data: params,
      });
    },
    getCourses: (params: any) => {
      const url = `admin/courses?email=${encodeURIComponent(params.email)}`;
      return client.request({
        method: 'GET',
        url,
      });
    },
    getCourseData: ({ instructorEmail }: { instructorEmail: string }) => {
      return client.request({
        method: 'GET',
        url: `/admin/course-data?instructorEmail=${encodeURIComponent(instructorEmail)}`,
      });
    },
    getEdlinkConnectionRequests: ({ email }: { email: string }) => {
      return client.request({
        method: 'GET',
        url: `/admin/edlink-connection-requests?email=${encodeURIComponent(email)}`,
      });
    },
    getCoursesForLateSubmission: (params: any) => {
      return client.request({
        method: 'GET',
        url: `admin/get-courses-for-late-submission?email=${encodeURIComponent(params.email)}`,
      });
    },
    getAssignmentsForLateSubmission: (params: any) => {
      return client.request({
        method: 'GET',
        url: `/admin/get-assignments-for-late-submission?courseID=${
          params.courseID
        }&email=${encodeURIComponent(params.email)}`,
      });
    },
    getPotentialReviewersForLateSubmission: (params: any) => {
      return client.request({
        method: 'GET',
        url: `/admin/get-potential-reviewers-for-late-submission?assignmentID=${params.assignmentID}&studentID=${params.studentID}`,
      });
    },
    findCreationForMissedEvaluation: (params: any) => {
      return client.request({
        method: 'GET',
        url: `/admin/find-creation-for-missed-evaluation?assignment=${params.assignment}&student=${params.student}&creationNumber=${params.creationNumber}`,
      });
    },
    findEvaluationForMissedFof: (params: any) => {
      return client.request({
        method: 'GET',
        url: `/admin/find-evaluation-for-missed-fof?activityId=${params.assignment}&studentId=${params.student}&evaluationNumber=${params.evaluationNumber}`,
      });
    },
    createMissedFof: (params: any) => {
      return client.request({
        method: 'POST',
        url: '/admin/create-missed-fof',
        data: params,
      });
    },
    getCourseAndInstitutionInfo: (params: any) => {
      return client.request({
        method: 'GET',
        url: `/admin/get-course-and-institution-info?email=${encodeURIComponent(
          params.email
        )}&courseId=${params.courseId}`,
        data: params,
      });
    },
    changeCoursePriceAndCurrency: (params: any) => {
      return client.request({
        method: 'POST',
        url: '/admin/change-course-price-and-currency',
        data: params,
      });
    },
    addInstitutions: () => {
      return client.request({
        method: 'POST',
        url: '/admin/add-institutions',
      });
    },
    addInstitution: (params: any) => {
      return client.request({
        method: 'POST',
        url: '/admin/add-institution',
        data: params,
      });
    },
    verifyInstitution: (params: any) => {
      return client.request({
        method: 'POST',
        url: '/admin/verify-institution',
        data: params,
      });
    },
    changeUserInstitutions: (params: any) => {
      return client.request({
        method: 'PATCH',
        url: '/admin/change-user-institution',
        data: params,
      });
    },
    sendCouponEmails: (params: any) => {
      return client.request({
        method: 'POST',
        url: '/admin/send-coupon-emails',
        data: params,
      });
    },
    createSingleCoupon: (params: any) => {
      return client.request({
        method: 'POST',
        url: '/admin/create-single-coupon',
        data: params,
      });
    },
    createMultipleCoupons: (params: any) => {
      return client.request({
        method: 'POST',
        url: '/admin/create-multiple-coupons',
        data: params,
      });
    },
    deleteCoupons: (params: any) => {
      return client.request({
        method: 'POST',
        url: '/admin/delete-coupons',
        data: params,
      });
    },
    getCouponData: (params: any) => {
      return client.request({
        method: 'GET',
        url: '/admin/get-coupon-data',
        params,
      });
    },
    getHubspotDealsByEmail: (params: any) => {
      return client.request({
        method: 'GET',
        url: `/admin/get-deals-by-email?email=${encodeURIComponent(params.email)}`,
      });
    },
    updateHubspotDeal: (params: any) => {
      return client.request({
        method: 'PUT',
        url: '/admin/update-deal',
        data: params,
      });
    },
    promoteUserToAdmin: (params: any) => {
      return client.request({
        method: 'PATCH',
        url: 'admin/users/promote-admin',
        data: params,
      });
    },
    changeDefaultCoursePrice: (params: any) => {
      return client.request({
        method: 'POST',
        url: 'admin/institution/course-price',
        data: params,
      });
    },
    fetchInstitutionIntegrations: (
      params: any
    ): Promise<DataResponse<admin.institution_integrations.GET.Response>> => {
      return client.request({
        method: 'GET',
        url: `/admin/institution/integrations?institutionId=${encodeURIComponent(
          params.institutionId
        )}`,
      });
    },
    fetchIntegratedInstitutionList: () => {
      return client.request({
        method: 'GET',
        url: '/admin/institution/integrated-institutions',
      });
    },
    fetchInstructorLmsFeatureControlByInstitution: (params: any) => {
      return client.request({
        method: 'GET',
        url: `/admin/institution/lms-feature-control?institutionId=${encodeURIComponent(
          params.institutionId
        )}`,
      });
    },
    impersonateUser: (data: ImpersonateUserArgs) => {
      return client.request({
        method: 'POST',
        url: `/admin/impersonate`,
        data,
      });
    },
    updateLMSControlGradeSyncEnabled: (params: any) => {
      return client.request({
        method: 'POST',
        url: `/admin/institution/updateGradeSyncEnabled?userId=${encodeURIComponent(
          params.userId
        )}&newStatus=${encodeURIComponent(params.newStatus)}`,
      });
    },
    updateLMSControlRosterSyncEnabled: (params: any) => {
      return client.request({
        method: 'POST',
        url: `/admin/institution/updateRosterSyncEnabled?userId=${encodeURIComponent(
          params.userId
        )}&newStatus=${encodeURIComponent(params.newStatus)}`,
      });
    },
    deleteIntegration: (params: any) => {
      return client.request({
        method: 'DELETE',
        url: '/admin/integration',
        data: params,
      });
    },
    updateIntegration: (
      id: string,
      params: {
        name: string;
        lms: string;
        edlinkIntegrationId: string;
        edlinkIntegrationAccessToken: string;
        institution: string;
        allowNonLmsCollaborators: boolean;
        lmsClientId: string | null;
        lmsSecret: string | null;
        redirectUrl: string | null;
      }
    ) => {
      return client.request({
        method: 'PUT',
        url: `/admin/integration/${id}`,
        data: params,
      });
    },
    createIntegration: (params: {
      name: string;
      lms: string;
      edlinkIntegrationId: string;
      edlinkIntegrationAccessToken: string;
      institution: string;
      allowNonLmsCollaborators: boolean;
      lmsClientId: string | null;
      lmsSecret: string | null;
      redirectUrl: string | null;
    }) => {
      return client.request({
        method: 'POST',
        url: '/admin/create-integration',
        data: params,
      });
    },
    updateUserEmail: (params: { currentEmail: string; newEmail: string }) => {
      return client.request({
        method: 'PATCH',
        url: 'admin/users/update-user-email',
        data: params,
      });
    },
    deleteUser: ({ id }) => {
      return client.request({
        method: 'DELETE',
        url: `admin/users/${id}`,
      });
    },
    createEvaluation: (data: AdminCreateEvaluationArgs) => {
      return client.request({
        method: 'POST',
        url: `/admin/evaluations`,
        data,
      });
    },
    updateEvaluation: (data: AdminUpdateEvaluationArgs) => {
      return client.request({
        method: 'PUT',
        url: `/admin/evaluations/${data.evaluationId}`,
        data,
      });
    },
  };
};
type AdminCreateEvaluationArgs = {
  creationId: string;
  evaluatorUserId: string;
  comment: string;
  files: {
    name?: string;
    url?: string;
    size?: number;
  }[];
  highlightedCriteria: number[];
  marks: number[];
  lateEvaluationReason?: string;
  notifications: {
    instructor: boolean;
    evaluator: boolean;
  };
};

type AdminUpdateEvaluationArgs = Omit<
  AdminCreateEvaluationArgs,
  'creationId' | 'evaluatorUserId' | 'lateEvaluationReason'
> & {
  evaluationId: string;
};
