import { Creation } from '@kritik/types.generated';

export const isGroupCreation = (creation: Creation) => {
  return !!creation.group;
};

export const isUserCreationOwner = (creation: Creation, user: UserWithAuthUser) => {
  return (creation.user as any)._id === user.authUser.id;
};

export const creationHasTeacherComment = (creation: Creation) => {
  const { teacherComment } = creation;
  return teacherComment && teacherComment.text !== '' && teacherComment.text !== '<p>&nbsp;</p>';
};

export const calcProgress = (completed: number, expected: number) => {
  return expected ? (completed / expected) * 100 : 0;
};

export const isCalibrationCreation = (creation: Creation) => {
  if (!creation) {
    return false;
  }
  return creation.calibrationCreation === true;
};
