const GET_ACTIVITY_IMPORT_COURSES_REQUEST = 'GET_ACTIVITY_IMPORT_COURSES_REQUEST';
const GET_ACTIVITY_IMPORT_COURSES_SUCCESS = 'GET_ACTIVITY_IMPORT_COURSES_SUCCESS';
const GET_ACTIVITY_IMPORT_COURSES_FAILURE = 'GET_ACTIVITY_IMPORT_COURSES_FAILURE';

const GET_ACTIVITIES_TO_IMPORT_REQUEST = 'GET_ACTIVITIES_TO_IMPORT_REQUEST';
const GET_ACTIVITIES_TO_IMPORT_SUCCESS = 'GET_ACTIVITIES_TO_IMPORT_SUCCESS';
const GET_ACTIVITIES_TO_IMPORT_FAILURE = 'GET_ACTIVITIES_TO_IMPORT_FAILURE';

const SET_ACTIVITIES_TO_IMPORT = 'SET_ACTIVITIESID_TO_IMPORT';
const RESET_ACTIVITIES_TO_IMPORT = 'RESET_ACTIVITIES_TO_IMPORT';

const ACTIVITY_IMPORT_STREAM_REQUEST = 'ACTIVITY_IMPORT_STREAM_REQUEST';
const ACTIVITY_IMPORT_STREAM_SUCCESS = 'ACTIVITY_IMPORT_STREAM_SUCCESS';
const ACTIVITY_IMPORT_STREAM_FAILURE = 'ACTIVITY_IMPORT_STREAM_FAILURE';

const IMPORT_ACTIVITY_REQUEST = 'IMPORT_ACTIVITY_REQUEST';
const IMPORT_ACTIVITY_SUCCESS = 'IMPORT_ACTIVITY_SUCCESS';
const IMPORT_ACTIVITY_FAILURE = 'IMPORT_ACTIVITY_FAILURE';

const STOP_IMPORT = 'STOP_IMPORT';
const RESET_ACTIVITY_IMPORT = 'RESET_ACTIVITY_IMPORT';

export default {
  ACTIVITY_IMPORT_STREAM_REQUEST,
  ACTIVITY_IMPORT_STREAM_SUCCESS,
  ACTIVITY_IMPORT_STREAM_FAILURE,
  GET_ACTIVITY_IMPORT_COURSES_REQUEST,
  GET_ACTIVITY_IMPORT_COURSES_SUCCESS,
  GET_ACTIVITY_IMPORT_COURSES_FAILURE,
  GET_ACTIVITIES_TO_IMPORT_REQUEST,
  GET_ACTIVITIES_TO_IMPORT_SUCCESS,
  GET_ACTIVITIES_TO_IMPORT_FAILURE,
  SET_ACTIVITIES_TO_IMPORT,
  RESET_ACTIVITIES_TO_IMPORT,
  IMPORT_ACTIVITY_REQUEST,
  IMPORT_ACTIVITY_SUCCESS,
  IMPORT_ACTIVITY_FAILURE,
  STOP_IMPORT,
  RESET_ACTIVITY_IMPORT,
};
