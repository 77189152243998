import { combineReducers } from 'redux';
import { generateAsyncReducer } from 'redux/utils';
import types from './types';

const asyncActions = {
  call: [
    types.GET_SCHEDULING_TEMPLATE,
    types.CREATE_SCHEDULING_TEMPLATE,
    types.UPDATE_SCHEDULING_TEMPLATE,
  ],
  success: [
    types.GET_SCHEDULING_TEMPLATE_SUCCESS,
    types.CREATE_SCHEDULING_TEMPLATE_SUCCESS,
    types.UPDATE_SCHEDULING_TEMPLATE_SUCCESS,
  ],
  error: [
    types.GET_SCHEDULING_TEMPLATE_FAILURE,
    types.CREATE_SCHEDULING_TEMPLATE_FAILURE,
    types.UPDATE_SCHEDULING_TEMPLATE_FAILURE,
  ],
};

function scheduleReducer(state = {}, action: any) {
  switch (action.type) {
    case types.UPDATE_SCHEDULE:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_SCHEDULE:
      return {
        ...action.payload,
      };
    case types.RESET_SCHEDULE:
      return {};
    default:
      return state;
  }
}

const initialPastDateError = {
  pastDate: {},
};

function errorReducer(state = initialPastDateError, action: any) {
  switch (action.type) {
    case types.SET_ERROR_PAST_DATE:
      return {
        ...state,
        pastDate: action.payload,
      };
    case types.RESET_SCHEDULE:
      return initialPastDateError;
    default:
      return state;
  }
}

export default combineReducers({
  scheduling: scheduleReducer,
  async: generateAsyncReducer(asyncActions),
  error: errorReducer,
});
