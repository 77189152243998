import { schema } from 'normalizr';

import user from 'schemas/user';
import student from 'schemas/student';
import group from 'schemas/group';

const creation = new schema.Entity(
  'user_assignments',
  {
    user,
    student,
    group,
    reviewers: [student],
  },
  { idAttribute: '_id' }
);

export default creation;
