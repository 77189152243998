import React from 'react';
import NoticeBoard from 'components/layout/NoticeBoard';

function AutoAssignEvaluatorNotice(props: any) {
  const { numEvaluatorsAssigned, numEvaluatorsExpected } = props;
  const text = {
    notice:
      'Late Creations will automatically be assigned to eligible evaluators if the activity is still in the Evaluation stage. An eligible evaluator is a student who has not yet started any evaluations.',
    title_no_eligible: 'Auto-assign - There were no eligible evaluators found',
    title_one_eligible: `Auto-assign - There was only ${numEvaluatorsAssigned} evaluator available to help with this creation`,
    title_some_eligible: `Auto-assign - There were only ${numEvaluatorsAssigned} evaluators available to help with this creation`,
    title_all_eligible: 'Auto-assign - All the required evaluators were found',
  };
  if (numEvaluatorsAssigned === 0) {
    return (
      <NoticeBoard type="danger" title={text.title_no_eligible}>
        {text.notice}
      </NoticeBoard>
    );
  }
  if (numEvaluatorsAssigned < numEvaluatorsExpected) {
    return (
      <NoticeBoard
        type="caution"
        title={numEvaluatorsAssigned === 1 ? text.title_one_eligible : text.title_some_eligible}
      >
        {text.notice}
      </NoticeBoard>
    );
  }
  return (
    <NoticeBoard type="information" title={text.title_all_eligible}>
      {text.notice}
    </NoticeBoard>
  );
}

export default AutoAssignEvaluatorNotice;
