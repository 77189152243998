import TutorialBanner from 'components/tutorial/Banner';
import Badge from './Icon';

export const COPY = {
  image: <Badge />,
  title: 'How the create stage works',
  description: `The first of a three stage Kritik Process. For the create
    stage, follow the activity instructions and read the provided rubric to
    create your submission.`,
  link: {
    target: 'https://youtu.be/AhqecxXD6E0',
    type: 'video',
    length: '1:09 minutes',
  },
};

const Banner = (props: any) => {
  return (
    <TutorialBanner
      className={props.className}
      image={COPY.image}
      title={COPY.title}
      description={COPY.description}
      link={COPY.link}
    />
  );
};

Banner.defaultProps = {
  className: '',
};

export default Banner;
