import { useEffect, useState } from 'react';
import PageContainer from 'components/layout/PageContainer/index';
import PageHeader from 'components/layout/PageHeader';
import { Form, Field } from 'react-final-form';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/buttons/ButtonContainer';
import CourseCodeField from 'components/Course/Edit/CourseDetails/CourseCodeField';
import CourseSectionField from 'components/Course/Edit/CourseDetails/CourseSectionField';
import CourseNameField from 'components/Course/Edit/CourseDetails/CourseNameField';
import CourseDepartmentField from 'components/Course/Edit/CourseDetails/CourseDepartmentField';
import CourseDescriptionField from 'components/Course/Edit/CourseDetails/CourseDescriptionField';
import AttachmentManager from 'components/layout/AttachmentManager';
import FormField from 'components/core/form/Field';
import FormFieldLabel from 'components/core/form/FieldLabel';
import CourseLength from 'components/Course/Edit/CourseLength';
import FormFieldInfo from 'components/core/form/FieldInfo/index';
import CodeSectionFieldLayout from 'components/Course/Edit/CourseDetails/Layout';
import { withRouter } from 'utils/withRouter';
import { COURSE_FIELD_NAMES } from 'components/Course/Edit/constants';
import CourseIntegrations from 'components/LmsGradeSync/CourseIntegrations';
import { InlineInformation } from 'components/layout';
import CourseStudentPermissions from './CourseStudentPermissions';
import MinimumWordCount from 'components/Course/Edit/CourseDetails/MinimumWordCount';
import { MINIMUM_WORD_COUNT } from '@kritik/constants/course';
import { useFetchCourse } from 'hooks/course';
import { Course } from '@kritik/types.generated';
import { FormApi } from 'final-form';
import { AuthUser, UserRole } from 'app-types';
import { LateSubmissionSettings } from 'components/DefaultActivitySettings/LateSubmissionSettings';
import { CourseTermField } from './CourseTermField';
import { TranslatedText } from 'components/TranslatedText';

type Props = {
  authUser: AuthUser;
  course: Course & { userRole: UserRole };
  form: FormApi;
  values: any;
  onCancel: () => void;
  isEditing: boolean;
  updateError?: string;
};

function CourseDetails({
  authUser,
  course,
  form,
  values,
  onCancel,
  isEditing,
  updateError,
}: Props) {
  const handleCourseLengthChange = (date: any, dateType: any) => {
    const dateTypes = ['startDate', 'endDate'];
    if (!dateTypes.includes(dateType)) {
      return;
    }
    form.mutators.setValue(`${[dateType]}`, date);
  };

  const submitBtnText = isEditing ? 'Update' : 'Next';
  return (
    <PageContainer>
      <PageHeader title={isEditing ? 'Edit Course Details' : 'Course Details'} />
      <div>
        <CourseNameField />
        <CodeSectionFieldLayout className="course-details__code-section-field-layout">
          <CourseCodeField />
          <CourseSectionField />
          <CourseTermField />
        </CodeSectionFieldLayout>
        <CourseDepartmentField />
        <CourseDescriptionField />

        <Field name="files">
          {({ input }) => {
            return (
              <FormField>
                <FormFieldLabel label="Syllabus & Resources" />
                <FormFieldInfo label="Add files to your course as resources for your students." />
                <AttachmentManager
                  onFileChange={input.onChange}
                  fileList={input.value}
                  label="Upload Course Files"
                />
              </FormField>
            );
          }}
        </Field>

        <CourseLength
          startDate={values.startDate}
          endDate={values.endDate}
          handleCourseLengthChange={handleCourseLengthChange}
        />

        <MinimumWordCount
          fieldName={COURSE_FIELD_NAMES.MINIMUM_WORD_COUNT}
          testid="course-edit-num-evals-assigned"
        />
        <LateSubmissionSettings
          showNoLateSubmissionsAllowedNotice={
            values.removeStudentFromActivityAfterMissedCreation === true
          }
          acceptLateCreations={values.acceptLateSubmissions}
          onAcceptLateCreationsChange={(e) => {
            form.change('acceptLateSubmissions', e.target.checked);
            form.change('autoAccept', false);
          }}
          autoAcceptLateCreations={values.autoAccept}
          onAutoAcceptLateCreationsChange={(e) => form.change('autoAccept', e.target.checked)}
          acceptLateEvaluations={values.acceptLateEvaluations}
          onAcceptLateEvaluationsChange={(e) => {
            form.change('acceptLateEvaluations', e.target.checked);
            form.change('autoAcceptLateEvaluations', false);
          }}
          autoAcceptLateEvaluations={values.autoAcceptLateEvaluations}
          onAutoAcceptLateEvaluationsChange={(e) =>
            form.change('autoAcceptLateEvaluations', e.target.checked)
          }
          isEditingCourse
        />
        <CourseStudentPermissions />
        {course && course.userRole === 'instructor' && (
          <CourseIntegrations course={course} authUser={authUser} />
        )}
        {isEditing && (
          <InlineInformation type="information" className="course-details__update-settings-warning">
            <TranslatedText i18nKey="CourseDetails.Edit.Warning" />
          </InlineInformation>
        )}
        <ButtonContainer>
          <Button type="primary" inputType="submit" testid="update-course">
            {submitBtnText}
          </Button>
          <Button type="secondary" onClick={onCancel} testid="back-button">
            {isEditing ? 'Cancel' : 'Back'}
          </Button>
        </ButtonContainer>
        {Boolean(updateError) && (
          <InlineInformation type="danger" className="course-details__no-department-warning">
            {updateError}
          </InlineInformation>
        )}
      </div>
    </PageContainer>
  );
}

CourseDetails.defaultProps = {
  isEditing: false,
};

const defaultValues = {
  [COURSE_FIELD_NAMES.COURSE_NAME]: '',
  [COURSE_FIELD_NAMES.CODE]: '',
  [COURSE_FIELD_NAMES.SECTION]: '',
  [COURSE_FIELD_NAMES.DESCRIPTION]: '',
  [COURSE_FIELD_NAMES.START_DATE]: null,
  [COURSE_FIELD_NAMES.END_DATE]: null,
  [COURSE_FIELD_NAMES.DEPARTMENT]: null,
  [COURSE_FIELD_NAMES.FILES]: [],
  [COURSE_FIELD_NAMES.ACCEPT_LATE_CREATIONS]: true,
  [COURSE_FIELD_NAMES.AUTO_ACCEPT_LATE_CREATIONS]: false,
  [COURSE_FIELD_NAMES.ACCEPT_LATE_EVALUATIONS]: true,
  [COURSE_FIELD_NAMES.AUTO_ACCEPT_LATE_EVALUATIONS]: false,
  [COURSE_FIELD_NAMES.COURSE_SETTINGS]: {
    hideAverageGrade: true,
    minimumWordCountForEvaluations: MINIMUM_WORD_COUNT,
  },
};

function FormWrapper(props: any) {
  const { data: course } = useFetchCourse(props.router.params.courseId);
  const [initialValues, setInitialValues] = useState(defaultValues);

  const getInitialValuesFromCourse = (course: any) => {
    // "Other" value is not allowed as selection option but it is saved as default
    // in simplified course creation, so we have to reset it to make sure that instructor needs to select
    // different value when upating course.
    return {
      [COURSE_FIELD_NAMES.COURSE_NAME]: course.title,
      [COURSE_FIELD_NAMES.CODE]: course.code ?? '',
      [COURSE_FIELD_NAMES.SECTION]: course.section ?? '',
      [COURSE_FIELD_NAMES.DESCRIPTION]: course.description,
      [COURSE_FIELD_NAMES.START_DATE]: course.startDate ? new Date(course.startDate) : null,
      [COURSE_FIELD_NAMES.END_DATE]: course.endDate ? new Date(course.endDate) : null,
      [COURSE_FIELD_NAMES.DEPARTMENT]: course.department,
      [COURSE_FIELD_NAMES.FILES]: course.files,
      [COURSE_FIELD_NAMES.ACCEPT_LATE_CREATIONS]: course.acceptLateSubmissions,
      [COURSE_FIELD_NAMES.AUTO_ACCEPT_LATE_CREATIONS]: course.autoAccept,
      [COURSE_FIELD_NAMES.ACCEPT_LATE_EVALUATIONS]: course.acceptLateEvaluations,
      [COURSE_FIELD_NAMES.AUTO_ACCEPT_LATE_EVALUATIONS]: course.autoAcceptLateEvaluations,
      [COURSE_FIELD_NAMES.COURSE_SETTINGS]: {
        hideAverageGrade: course.courseSettings?.hideAverageGrade,
        minimumWordCountForEvaluations: course.courseSettings?.minimumWordCountForEvaluations,
      },
      [COURSE_FIELD_NAMES.TERM]: course.term,
    };
  };

  useEffect(() => {
    if (course) {
      setInitialValues(getInitialValuesFromCourse(course));
    }
  }, [course]);

  return (
    <Form
      onSubmit={props.onSubmit}
      initialValues={initialValues}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => {
            return value;
          });
        },
      }}
      render={({ handleSubmit, form, values }) => {
        if (props.formRef) {
          props.formRef.current = form;
        }
        return (
          <form onSubmit={handleSubmit}>
            <CourseDetails
              course={course}
              form={form}
              values={values}
              onCancel={props.onCancel}
              isEditing={props.isEditing}
              authUser={props.authUser}
              updateError={props.updateError}
            />
          </form>
        );
      }}
    />
  );
}

FormWrapper.defaultProps = {
  isEditing: false,
};

export default withRouter(FormWrapper);
