import * as _ from 'lodash-es';
import { combineReducers } from 'redux';

import * as types from 'types';

export const SELECT_STUDENT = 'SELECT_STUDENT';

const initialState = {
  courseId: null,
  assignmentId: null,
  submissionId: null,
  studentId: null,
};

export default function reducer(state = initialState, action = {}) {
  switch ((action as any).type) {
    case types.SELECT_COURSE:
      return { ...state, courseId: (action as any).payload };
    case types.SELECT_ASSIGNMENT:
      return { ...state, assignmentId: (action as any).payload };
    case types.SELECT_SUBMISSION:
      return { ...state, submissionId: (action as any).payload };
    case types.SELECT_GROUP_SET:
      return { ...state, groupSetId: (action as any).payload };
    case SELECT_STUDENT:
      return { ...state, studentId: (action as any).payload };
    case types.SELECT_RESET:
      return initialState;
    default:
      return state;
  }
}

export const selectStudent = (studentId: any) => {
  return { type: SELECT_STUDENT, payload: studentId };
};
