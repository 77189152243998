import React, { useState, useContext, useEffect } from 'react';
import ShowInfoMessage from './ShowInfoMessage';
import ShowErrorMessage from './ShowErrorMessage';
import { CreationContext } from '../../../context/CreationContext';
import { NOT_FOUND } from '../AddNewEvaluation';

const ShowMessage = ({ changeShouldAddNewEval }: any) => {
  const { creation, setCreation } = useContext(CreationContext);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!creation) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
    changeShouldAddNewEval(false);
  }, [creation]);

  if (isOpen && creation) {
    if (creation !== NOT_FOUND) {
      const { files } = creation;
      return <ShowInfoMessage files={files} changeShouldAddNewEval={changeShouldAddNewEval} />;
    }
    return <ShowErrorMessage />;
  }
  return null;
};

export default ShowMessage;
