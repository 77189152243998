import { REGION_OPTIONS, getHostname } from '@kritik/utils/region';
import * as LocalStorageUtils from 'utils/localStorage';

/**
 * @deprecated This method should not be used
 */
export function redirectToRegion({ region, path }: any) {
  // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
  const hostname = getHostname({ region, appEnv: App.config.get('appEnv') });
  let protocol = 'https';
  if (hostname.includes('localhost')) {
    protocol = 'http';
  }
  const baseUrl = `${protocol}://${hostname}`;
  let targetUrl = baseUrl;
  if (path) {
    targetUrl = `${baseUrl}${path}`;
  }
  const url = new URL(targetUrl);
  url.searchParams.append('region', region);
  window.location.replace(url.toString());
}

/**
 * @deprecated This method should not be used
 */
export const getRegion = () => {
  const region = LocalStorageUtils.getText('region');
  if (region) {
    return region;
  }

  // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
  if (App.config.get('region') === REGION_OPTIONS.AMERICA) {
    return REGION_OPTIONS.AMERICA;
  }
  return REGION_OPTIONS.CANADA;
};
