import HtmlContent from 'components/General/HtmlContent';
import FOFSlider from 'components/Creation/Feedback/FOFSlider';
import Button from 'components/buttons/Button';

const displayFeedback = ({
  evaluation,
  showBreakdown,
  onEdit,
  isEditable = false,
  showTitle = true,
}: any) => {
  const renderTitle = () => {
    if (showTitle) {
      return <h3 className="header-3">Evaluation Feedback</h3>;
    }
  };

  const renderFeedbackComment = (
    <div className="feedback section" data-testid="feedback-comment">
      <div className="title">Feedback Comment</div>
      <HtmlContent content={evaluation.feedbackOnFeedback.comment || 'No comment'} />
    </div>
  );

  return (
    <div className="fof-view__container">
      <div className="fof-view__submission-container">
        {renderTitle()}
        <div className="submit-fof-container">
          <div className="fof-display section">
            <div className="fof-graph-container">
              <FOFSlider
                critical={evaluation.feedbackOnFeedback.critical}
                motivational={evaluation.feedbackOnFeedback.motivational}
                criticalChange={showBreakdown ? evaluation.feedbackSkillsChange.critical : null}
                motivationalChange={
                  showBreakdown ? evaluation.feedbackSkillsChange.motivational : null
                }
                disabled
                studentId={evaluation.student?._id || evaluation.student}
              />
            </div>
          </div>
          {renderFeedbackComment}
          {isEditable && (
            <Button data-testid="edit-feedback" type="primary" onClick={onEdit}>
              {' '}
              Edit{' '}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default displayFeedback;
