import React from 'react';
import classNames from 'classnames';
import { Divider } from '@material-ui/core';

const PanelDisplay = (props: any) => {
  const panelClasses = classNames('panel-display', props.className);

  const SectionDivider = () => {
    return (
      <div className="panel-display__divider">
        <Divider />
      </div>
    );
  };

  const renderTitle = () => {
    if (!props.title) {
      return null;
    }
    return <div className="panel-display__title">{props.title}</div>;
  };
  const renderSubTitle = () => {
    if (!props.subTitle) {
      return null;
    }
    return <div className="panel-display__subtitle">{props.subTitle}</div>;
  };

  const renderHeader = () => {
    if (!props.title && !props.subTitle) {
      return null;
    }
    return (
      <div className="panel-display__header">
        {renderTitle()}
        {renderSubTitle()}
        {props.headerDivider && <SectionDivider />}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="panel-display__footer">
        <SectionDivider />
        <div className="panel-display__footer-content">{props.footer}</div>
      </div>
    );
  };

  return (
    <div className={panelClasses}>
      {renderHeader()}
      {props.children}
      {props.footer && renderFooter()}
    </div>
  );
};

PanelDisplay.defaultProps = {
  className: '',
  title: '',
  subTitle: '',
  headerDivider: false,
};

export default PanelDisplay;
