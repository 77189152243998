import * as _ from 'lodash-es';
import { getEvaluationScoreByActivity } from 'utils/student';
import { getStudent } from './student';

export function sortByEvaluationScore(itemList: any, ascending: any, activity: any, course: any) {
  return _.orderBy(
    itemList,
    [
      function (item) {
        const student = getStudent(item);
        const evaluationScore = getEvaluationScoreByActivity(student, activity, course);
        return evaluationScore;
      },
    ],
    [ascending ? 'asc' : 'desc']
  );
}
