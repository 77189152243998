import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LoaderOverlay from 'components/Loaders/OverlaySpinner';
import CreationDisplay from 'components/Creation/Create/CreationDisplay';
import ReferenceContentWrapper from 'components/Spotlight/View/ReferenceContentWrapper';
import { getCreation, GET_CREATION } from 'redux/creation';
import { resetAsync } from 'actions/async';
import { getSubmission } from 'selectors/creation';

function Creation(props: any) {
  const { creation, creationId } = props;
  useEffect(() => {
    if (!creation) {
      props.getCreation({
        id: creationId,
      });
    }
    return function cleanup() {
      resetAsync(GET_CREATION);
    };
  }, []);
  if (!creation) {
    return <LoaderOverlay isOpen />;
  }
  return (
    <ReferenceContentWrapper title="Creation">
      <CreationDisplay creation={creation} />
    </ReferenceContentWrapper>
  );
}

const mapStateToProps = (state: any, ownProps: any) => {
  const creation = getSubmission(state, ownProps.creationId);
  return {
    creation,
  };
};

export default connect(mapStateToProps, {
  getCreation,
})(Creation);
