import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'components/buttons';
import FormTitle from 'components/core/form/Title';
import PercentageInput from 'components/core/input/Percentage';
import { ScoreEditCard } from 'components/Creation/GradeView';
import FormSection from 'components/core/form/Section';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import FormContainer from 'components/core/form/Container';
import FormField from 'components/core/form/Field';
import { FeedbackScoreUtil } from '@kritik/utils/grade';
import { updateAssignmentSubmission } from 'actions/activity';

const FeedbackScoreEdit = (props: any) => {
  const getCurrentFeedbackScore = () => {
    return FeedbackScoreUtil.calcScore({
      creation: props.creation,
      activity: props.activity,
      studentId: props.creation.student._id,
      creations: props.creations,
    });
  };

  const [newFeedbackScore, setNewFeedbackScore] = useState(getCurrentFeedbackScore().toString());

  const handleScoreChange = (e: any) => {
    setNewFeedbackScore(e.target.value);
  };

  const scoreCardProps = {
    feedbackScore: {
      title: 'Feedback Score',
      description: 'Participation in completed tasks',
      prevScore: getCurrentFeedbackScore(),
      newScore: (
        <PercentageInput
          name="writtenEvalScore"
          value={newFeedbackScore}
          min={0}
          max={100}
          onChange={(e: any) => {
            return handleScoreChange(e);
          }}
        />
      ),
    },
  };

  const renderScoreEditOptions = () => {
    return (
      <FormField>
        <ScoreEditCard {...scoreCardProps.feedbackScore} />
      </FormField>
    );
  };

  const handleSubmit = () => {
    const data = {};
    (data as any).teacherFeedbackScore = Number(newFeedbackScore);
    (data as any).submissionId = props.creation._id;
    props.updateAssignmentSubmission(data).then(() => {
      props.onCancel();
    });
  };

  return (
    <FormContainer>
      <FormTitle label="Edit Scores" size="xl" />
      <FormSection>{renderScoreEditOptions()}</FormSection>
      <FormSubmitButtons>
        <Button type="primary" onClick={handleSubmit}>
          Submit
        </Button>
        <Button type="secondary" onClick={props.onCancel}>
          Cancel
        </Button>
      </FormSubmitButtons>
    </FormContainer>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

export default connect(mapStateToProps, { updateAssignmentSubmission })(FeedbackScoreEdit);
