import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { localize } from 'locales';

import { isEdlinkLmsSetup } from '@kritik/utils/course';

import CommunityLink from 'components/Navigation/Community';
import NavigationLink from 'components/Navigation/Link';
import TopBar from 'components/Navigation/TopBar';

import ActivitiesTab from 'components/Navigation/Tabs/Activities';
import CourseInfoTab from 'components/Navigation/Tabs/CourseInfo';
import HelpTab from 'components/Navigation/Tabs/Help';
import ProfileTab from 'components/Navigation/Tabs/Profile';
import StudentsTab from 'components/Navigation/Tabs/Students';
import logo from 'images/logo-small.png';
import { withRouter } from 'utils/withRouter';
import { useFetchCourse } from 'hooks/course';
import { Course } from '@kritik/types.generated';
import { UserRole } from 'app-types';
import { formatPartialCourseBreadcrumb } from 'utils/format';

function Navigation(props: any) {
  const { user } = props;
  const { data: course } = useFetchCourse(props.router.params.courseId);

  // For mobile navigation bar open or close
  const [isNavigationBarOpen, setIsNavigationBarOpen] = useState(false);

  const toggleNavigation = () =>
    setIsNavigationBarOpen((isNavigationBarOpen) => !isNavigationBarOpen);

  const location = useLocation();

  useEffect(() => {
    setIsNavigationBarOpen(false);
  }, [location]);

  const showMenu = user.authenticated && location?.pathname !== '/demo';

  if (!showMenu) return null;

  return (
    <nav aria-label="Main" className="no-print">
      <a tabIndex={0} className="skip-main" href="#kritik-main">
        {localize({ message: 'Copy.SkipToMainContent' })}
      </a>
      <TopBar
        toggleNavigationBar={() => toggleNavigation()}
        content={
          <>
            <HelpTab position="top" />
            <ProfileTab position="top" />
          </>
        }
      />
      <div
        className={`Overlay ${isNavigationBarOpen ? 'Overlay--show' : ''}`}
        onClick={() => toggleNavigation()}
      />
      <div className={`NavigationBar ${isNavigationBarOpen ? 'NavigationBar--open' : ''}`}>
        {/*
             When course is available, then we render the menu based on that.
             Otherwise, we render based on if the user has the canCreateCourse permission.
           */}
        {course?.userRole === 'instructor' ||
        user.authUser.permissions.includes('canCreateCourse') ? (
          <InstructorMenu course={course} toggleNavigation={toggleNavigation} />
        ) : (
          <StudentMenu course={course} toggleNavigation={toggleNavigation} />
        )}
      </div>
    </nav>
  );
}

type InstructorMenuProps = {
  course: Course & { userRole: UserRole };
  toggleNavigation: () => void;
};

const InstructorMenu = ({ course, toggleNavigation }: InstructorMenuProps) => {
  const location = useLocation();

  const showGradeSyncTab = course && course.userRole === 'instructor' && isEdlinkLmsSetup(course);
  const showStudentsTab = course?.userRole === 'instructor' && location.pathname !== '/';

  return (
    <ul className="navigation__list" role="list">
      <HeaderMenu toggleNavigation={toggleNavigation} course={course} />
      {showStudentsTab && (
        <li role="listitem">
          <StudentsTab
            removedStudents={course?.removedStudents?.length || 0}
            enrolledStudents={course?.students?.length || 0}
            pendingStudents={course?.approvedStudents?.length || 0}
            activeRegex={[/^\/course\/(.*?)\/roster$/]}
            destination={`/course/${course._id}/roster`}
          />
        </li>
      )}
      {showGradeSyncTab && (
        <li role="listitem">
          <NavigationLink
            to={`/course/${course._id}/lms-grade-sync`}
            activeRegex={[/^\/course\/(.*?)\/lms-grade-sync$/]}
            selectable
            icon={<i className="fas fa-cogs" />}
            label={localize({ message: 'Navigation.Tabs.GradeSync.Label' })}
            testid="to-grade-sync"
          />
        </li>
      )}
      <CommunityLink />
      <HelpTab position="left" />
      <ProfileTab position="left" />
    </ul>
  );
};

type StudentMenuProps = {
  course: Course;
  toggleNavigation: () => void;
};

const StudentMenu = ({ course, toggleNavigation }: StudentMenuProps) => {
  return (
    <ul className="navigation__list" role="list">
      <HeaderMenu toggleNavigation={toggleNavigation} course={course} isStudentMenu />
      <HelpTab position="left" />
      <ProfileTab position="left" />
    </ul>
  );
};

type HeaderMenuProps = {
  course: Course;
  toggleNavigation: () => void;
  isStudentMenu?: boolean;
};

const HeaderMenu = ({ course, toggleNavigation, isStudentMenu }: HeaderMenuProps) => {
  const location = useLocation();

  if (course && location.pathname !== '/') {
    return (
      <>
        <HomeTab toggleNavigation={toggleNavigation} />
        <AllCoursesTab />
        <li className="CourseLabel main-menu-item" role="listitem">
          {formatPartialCourseBreadcrumb(course)}
        </li>
        <li role="listitem">
          <CourseInfoTab
            activeRegex={[/^\/course\/[a-z0-9]+(\/(score|details)\/edit)?$/]}
            destination={`/course/${course._id}`}
            course={course}
          />
        </li>
        <li role="listitem" style={isStudentMenu ? { flexGrow: 1 } : {}}>
          <ActivitiesTab
            activeRegex={[/^\/course\/(.*?)\/assignments/, /^\/course\/(.*?)\/assignment/]}
            destination={`/course/${course._id}/assignments`}
          />
        </li>
      </>
    );
  }
  return (
    <>
      <HomeTab toggleNavigation={toggleNavigation} />
      <AllCoursesTab isStudentMenu={isStudentMenu} />
    </>
  );
};

type HomeTabProps = {
  toggleNavigation: () => void;
};

function HomeTab({ toggleNavigation }: HomeTabProps) {
  return (
    <li className="NavigationBar__home" role="listitem">
      <button
        className="NavigationBar__toggle"
        name={localize({ message: 'Accessibility.Mobile.Closer' })}
        aria-label={localize({ message: 'Accessibility.Mobile.Closer' })}
        onClick={() => toggleNavigation()}
      >
        <i className="fas fa-times" />
      </button>
      <Link className="header-logo" to="/" data-testid="home-tab">
        <img src={logo} alt={localize({ message: 'Navigation.Logo' })} />
      </Link>
    </li>
  );
}

type AllCoursesTabProps = {
  isStudentMenu?: boolean;
};

function AllCoursesTab({ isStudentMenu }: AllCoursesTabProps) {
  return (
    <li role="listitem" style={isStudentMenu ? { flexGrow: 1 } : {}} className="main-menu-item">
      <NavigationLink
        to="/"
        selectable
        icon={<i className="fas fa-home" />}
        label={localize({ message: 'Navigation.Tabs.Home.Label' })}
        testid="all-courses-tab"
        activeRegex={[/^\/$/]}
      />
    </li>
  );
}

export default withRouter(Navigation);
