import React from 'react';
import StatusLabel from 'components/Creation/StatusLabel';
import * as CreationStatusUtil from '@kritik/utils/creation/status';
import * as LateSubmissionUtil from '@kritik/utils/creation/lateCreation';

export const LateSubmissionLabel = (props: any) => {
  const { creation } = props;
  if (LateSubmissionUtil.isLateSubmissionPending(creation)) {
    return (
      <StatusLabel
        status="warning"
        label="Late Creation"
        icon={<i className="fa fa-exclamation-triangle" />}
      />
    );
  }

  if (LateSubmissionUtil.isLateSubmissionRejected(creation)) {
    return (
      <StatusLabel
        status="danger"
        label="Late - Rejected"
        icon={<i className="fa fa-times-circle" />}
      />
    );
  }
  if (LateSubmissionUtil.isLateSubmissionAccepted(creation)) {
    return (
      <StatusLabel
        status="resolved"
        label="Late - Accepted"
        icon={<i className="fa fa-exclamation-triangle" />}
      />
    );
  }
  if (CreationStatusUtil.wasCreationSubmittedInGracePeriod(creation)) {
    return (
      <StatusLabel
        status="warning"
        label="Grace Period Creation"
        testid="grace-period-submission-label"
      />
    );
  }
  return null;
};

export default LateSubmissionLabel;
