import { normalize, denormalize } from 'normalizr';

import { mergeEntities } from 'actions/entities';
import { evaluationSchema } from 'schemas';
import { combineReducers } from 'redux';
import { generateAsyncReducer } from 'redux/utils';
import { evaluationService } from 'services';

export const GET_EVALUATION = 'GET_EVALUATION';
const GET_EVALUATION_SUCCESS = 'GET_EVALUATION_SUCCESS';
const GET_EVALUATION_FAILURE = 'GET_EVALUATION_FAILURE';

const asyncActions = {
  call: [GET_EVALUATION],
  success: [GET_EVALUATION_SUCCESS],
  error: [GET_EVALUATION_FAILURE],
};

function normalizeEvaluation(evaluation: any) {
  return (dispatch: any) => {
    const normalizedEvaluation = normalize(evaluation, evaluationSchema);
    dispatch(mergeEntities(normalizedEvaluation.entities));
  };
}

export function normalizeEvaluations(creations: any) {
  return (dispatch: any) => {
    const evaluations = [];
    for (const creation of creations) {
      for (const evaluation of creation.scores) {
        evaluations.push({
          ...evaluation,
          // add custom ref to creation for simplifying getting related creation in frontend
          creation: creation._id,
        });
      }
    }
    const normalizedEvaluations = normalize(evaluations, [evaluationSchema]);
    dispatch(mergeEntities(normalizedEvaluations.entities));
  };
}

export function getEvaluation(params: any) {
  return async (dispatch: any) => {
    dispatch({
      type: GET_EVALUATION,
    });
    try {
      const res = await evaluationService().get(params);
      if (res.data) {
        const evaluation = res.data;
        return dispatch(normalizeEvaluation(evaluation));
      }
      dispatch({
        type: GET_EVALUATION_FAILURE,
      });
    } catch (error) {
      dispatch({
        type: GET_EVALUATION_FAILURE,
        error,
      });
    }
  };
}

export const selectEvaluation = (state: any, evaluationId: any) => {
  const evaluation = denormalize(evaluationId, evaluationSchema, state.entities);
  if (!evaluation) {
    return null;
  }
  return {
    ...evaluation,
    getComment: () => {
      return evaluation.comment;
    },
    getCreationId: () => {
      return evaluation.creation;
    },
  };
};

export default combineReducers({
  async: generateAsyncReducer(asyncActions),
});
