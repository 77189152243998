import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Toast from 'components/General/Toast';
import Banner from 'components/core/Banner';
import { isScoreViewReady } from '@kritik/utils/creation/score';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/buttons/ButtonContainer';
import { dismissScoreViewNotification } from 'redux/creation';
import CELEBRATION_TAG from 'images/spotlight-icons/badge-celebration.svg';

const ScoreToast = (props: any) => {
  useEffect(() => {
    if (props.creation && isScoreViewReady(props.creation)) {
      window.scrollTo(0, 0);
    }
  }, [props.creation]);

  if (!props.creation) {
    return null;
  }

  const isOpen = () => {
    return isScoreViewReady(props.creation);
  };

  const onClose = () => {
    props.dismissScoreViewNotification(props.creation._id);
  };

  return (
    <Toast isOpen={isOpen()} onClose={onClose}>
      <Banner
        image={<img src={CELEBRATION_TAG} aria-hidden="true" />}
        title="Congrats! You completed all your feedback"
        description="Once you have viewed your Creation Score you won’t be able to edit any of your feedback."
      >
        <ButtonContainer>
          <Button data-testid="view-creation-score" type="secondary" onClick={onClose}>
            View Creation Score
          </Button>
        </ButtonContainer>
      </Banner>
    </Toast>
  );
};

export default connect(null, { dismissScoreViewNotification })(ScoreToast);
