export const STAR_RUBRIC_MARKS = {
  // Table displayed by default
  DEFAULT: 'default',
  // Table displayed under the evaluation tab for both student and instructor
  GRADING_SCORE: 'grading score',
  // Table displayed under the Creation tab for instructor
  OVERALL_CREATION_SCORE: 'instructor creation score',
  // Table displayed under the Creation tab for student
  STUDENT_CREATION_SCORE: 'student creation score',
};
