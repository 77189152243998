import { localize } from 'locales';

export const FIELD_NAME = 'weight';
export const FIELD_LABEL = localize({ message: 'Activity.WeightField.Label' });

export const DEFAULT_OPTIONS = {
  DEFAULT: {
    value: 'default',
    label: localize({ message: 'Activity.WeightField.Options.Default' }),
    testid: 'default-weight-option',
  },
  CUSTOM: {
    value: 'custom weight',
    label: localize({ message: 'Activity.WeightField.Options.Custom' }),
    testid: 'custom-weight-option',
  },
};

export const CALIBRATION_OPTIONS = {
  DEFAULT: {
    value: 'default',
    label: localize({ message: 'Activity.WeightField.Options.Default' }),
  },
  CUSTOM: {
    value: 'custom weight',
    label: localize({ message: 'Activity.WeightField.Options.Custom' }),
    isDisabled: true,
  },
};

export default {
  FIELD_NAME,
  FIELD_LABEL,
  DEFAULT_OPTIONS,
  CALIBRATION_OPTIONS,
};
