import * as _ from 'lodash-es';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'router';
import { RouterProp, withRouter } from 'utils/withRouter';
import VirtualizedAutoComplete from 'components/auth/VirtualizedAutoComplete';
import { activateUser } from 'actions/users';
import { getInstitutions } from 'actions/admin';
import { getEdlinkUserAccount } from 'services/edlink';
import * as GeneralUtils from '@kritik/utils/general';
import * as ValidateUtil from '@kritik/utils/validate';
import CourseSelectors from 'selectors/course';
import { selectInstitutions } from 'selectors/institution';
import {
  SignupContainer,
  SignupMainTitle,
  SignupButtons,
  SignupContent,
} from 'components/AuthWrapper';
import { Form } from 'components/Form';
import FormTextInput from 'components/core/form/TextField';
import FormPassword from 'components/core/form/PasswordField';
import Selection from 'components/core/form/SelectField';
import Button from 'components/buttons/Button';
import { countryCodesOptions } from 'components/Setting/countrycodes';
import RegionPicker from 'components/General/RegionPicker';
import FormFieldLabel from 'components/core/form/FieldLabel';
import FormField from 'components/core/form/Field';
import * as RegionUtils from 'utils/region';
import SSOActivation from 'components/auth/SSOActivation';
import TermsOfService from 'components/auth/TermsOfService';
import ProfileImageUpload from 'components/User/ProfileImageUpload';
import { FindTimeZone } from 'components/auth/ProfsWithOtherInstitutions';
import InlineInformation from 'components/layout/InlineInformation';
import { localize } from 'locales/index';
import { UserGlobalPermissions } from 'app-types';
import { User } from 'old-common/types.generated';
import { SIGNUP_USER } from 'types/index';

type AccountActivationState = any;

type AccountActivationProps = {
  activateUser: ({}) => Promise<any>;
  push: any;
  userToRegister: User & { permissions: UserGlobalPermissions[] };
  institutions: any;
  getInstitutions: () => void;
  router: RouterProp;
};

class AccountActivation extends Component<AccountActivationProps, AccountActivationState> {
  constructor(props: AccountActivationProps) {
    super(props);
    this.state = {
      password: '',
      firstName: '',
      lastName: '',
      step1Valid: false,
      institution: null,
      shouldAddNewInstitution: false,
      phoneNumber: '',
      countryCode: '',
      countryLabel: null,
      uploadedImg: '',
      isSSO: false,
      isLtiAdvantageUser: false,
    };
  }

  componentDidMount() {
    this.props.getInstitutions();
    getEdlinkUserAccount(this.props.userToRegister.email)
      .then((response) => {
        if (Array.isArray(response.integrations) && response.integrations.length > 0) {
          this.setState({ isSSO: true, isLtiAdvantageUser: response?.ltiAdvantageIntegration });
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  handleOnSubmit() {
    const {
      firstName,
      lastName,
      password,
      institution,
      newInstitution,
      timeZone,
      phoneNumber,
      shouldAddNewInstitution,
      uploadedImg,
    } = this.state;

    this.props
      .activateUser({
        email: this.props.userToRegister.email,
        password,
        confirmPassword: password,
        firstName,
        lastName,
        institution,
        shouldAddNewInstitution,
        newInstitution,
        timeZone,
        phoneNumber,
        uploadedImg,
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err: any) => {
        this.setState({ error: Object.values(err.errors)[0] });
      });
  }

  handleInputChange = (e: any) => {
    const { target } = e;
    let { value } = target;
    const { name } = target;
    if (name === 'phoneNumber') {
      value = GeneralUtils.sanitizePhoneNumberLength(value);
    }
    this.setState({
      [name]: value,
      error: null,
    });
  };

  verifyStep1() {
    const { password } = this.state;
    const errors = {};

    const { error: validationError } = ValidateUtil.validateNewPassword(password);
    if (validationError) {
      (errors as any).password = validationError;
    }

    if (this.state.firstName.length === 0) {
      (errors as any).firstName = 'Please enter your first name';
    }
    if (this.state.lastName.length === 0) {
      (errors as any).lastName = 'Please enter your last name';
    }
    if (!this.verifySelectInstitution()) {
      (errors as any).institution = 'Please enter your institution';
    }
    if (!this.verifyOtherInstitutionName()) {
      (errors as any).institution = 'Please enter institution name';
    }
    if (!this.verifyOtherInstitutionTimeZone()) {
      (errors as any).institution = 'Please choose suggested time zone';
    }
    if (
      this.state.phoneNumber.length > 0 &&
      !GeneralUtils.isValidE164PhoneNumber(this.state.countryCode + this.state.phoneNumber)
    ) {
      (errors as any).phoneNumber = localize({ message: 'WrongPhoneNumberFormat' });
    }
    if (!_.isEmpty(errors)) {
      return this.setState({ error: errors });
    }
    this.setState({
      phoneNumber:
        this.state.phoneNumber === '' ? '' : this.state.countryCode + this.state.phoneNumber,
    });
    return this.setState({ step1Valid: true });
  }

  verifySelectInstitution = () => {
    if (this.props.userToRegister?.permissions?.includes('canCreateCourse')) {
      return this.state.institution;
    }
    return true;
  };

  verifyOtherInstitutionTimeZone() {
    if (this.props.userToRegister?.permissions?.includes('canCreateCourse')) {
      if (this.state.shouldAddNewInstitution && !this.state.timeZone) {
        return false;
      }
    }
    return true;
  }

  verifyOtherInstitutionName() {
    if (this.props.userToRegister?.permissions?.includes('canCreateCourse')) {
      if (this.state.shouldAddNewInstitution && !this.state.newInstitution) {
        return false;
      }
    }
    return true;
  }

  handleClickContinue = () => {
    this.verifyStep1();
    this.verifySelectInstitution();
  };

  handleCountryCodeChange = (e: any) => {
    const selection = countryCodesOptions.find((option) => {
      return option.label === e.target.value;
    });
    const countryCode = GeneralUtils.sanitizeCountryCode(selection.value);
    this.setState({
      countryCode,
      countryLabel: selection.label,
      error: null,
    });
  };

  getCountryCodeOptions = () => {
    return countryCodesOptions.map((option) => {
      return {
        label: option.label,
        value: option.label,
      };
    });
  };

  getError(field: any) {
    if (this.state.error) {
      return this.state.error[field];
    }
    return null;
  }

  renderPhoneInput() {
    if (!this.props.userToRegister?.permissions?.includes('canCreateCourse')) {
      return (
        <React.Fragment>
          <Selection
            label="Country code"
            value={this.state.countryLabel}
            onChange={this.handleCountryCodeChange}
            options={this.getCountryCodeOptions()}
            placeholder="Select your country code"
            testid="country-code-select"
          />
          <FormTextInput
            id="phone-number"
            name="phoneNumber"
            label="Phone Number"
            value={this.state.phoneNumber}
            onChange={this.handleInputChange}
            error={this.getError('phoneNumber')}
            helpText="For example: 1234567890"
          />
          <p>
            Adding your phone number is optional, and will allow you to receive SMS notifications
            about important events and upcoming deadlines.
          </p>
        </React.Fragment>
      );
    }
    return null;
  }

  renderActivationStep1 = () => {
    const InstitutionSelection = () => {
      const handleInstitutionChange = (event: any, value: any, reason: any) => {
        const isOtherInstitution = value.name === 'Other';
        return this.setState({
          institution: value,
          shouldAddNewInstitution: isOtherInstitution,
          error: null,
        });
      };

      function getInstitutionOptions(institutions: any) {
        const options = [];
        let otherOption;

        Object.values(institutions).forEach((inst) => {
          if ((inst as any).name != 'Other') {
            options.push(inst);
          } else {
            otherOption = inst;
          }
        });

        options.push(otherOption);

        return options;
      }

      const institutionOptions = this.props.institutions
        ? getInstitutionOptions(this.props.institutions)
        : [];

      return (
        <FormField error={this.getError('institution')}>
          <VirtualizedAutoComplete
            name="institution"
            value={this.state.institution}
            options={institutionOptions}
            label="Please select your institution"
            onChange={(event: any, value: any, reason: any) => {
              return handleInstitutionChange(event, value, reason);
            }}
          />
        </FormField>
      );
    };

    return (
      <SignupContainer graphic="rocket">
        <SignupContent>
          <SignupMainTitle>
            Hi there,
            <br /> It looks like you don't have an account yet.
          </SignupMainTitle>
          <FormField>
            <FormFieldLabel label="Region" color="light" id="region-select-field" />
            <RegionPicker value={RegionUtils.getRegion()} disabled />
          </FormField>
          <Form>
            <FormField>
              <FormFieldLabel label="Email" />
              {this.props.userToRegister.email}
            </FormField>
            <FormTextInput
              id="first-name"
              name="firstName"
              label="First Name"
              value={this.state.firstName}
              onChange={this.handleInputChange}
              error={this.getError('firstName')}
              testid="first-name"
            />
            <FormTextInput
              id="last-name"
              name="lastName"
              label="Last Name"
              value={this.state.lastName}
              onChange={this.handleInputChange}
              error={this.getError('lastName')}
              testid="last-name"
            />
            {!this.props.userToRegister?.permissions?.includes('canCreateCourse') && (
              <InlineInformation type="information">
                Only the instructor will be able to see your name. You will be anonymous to all
                evaluators
              </InlineInformation>
            )}
            <FormPassword
              id="password-account-activation"
              name="password"
              label="Password"
              value={this.state.password}
              onChange={this.handleInputChange}
              error={this.getError('password')}
              helpText="Password must be at least 10 characters long"
              testid="password-account-activation"
            />
            {this.renderPhoneInput()}
            {this.props.userToRegister?.permissions?.includes('canCreateCourse') && (
              <InstitutionSelection />
            )}
            {this.state.shouldAddNewInstitution && (
              <FindTimeZone
                setTimeZone={(tz: any) => {
                  this.setState({
                    timeZone: tz,
                    error: null,
                  });
                }}
                timeZone={this.state.timeZone}
                newInstitution={this.state.newInstitution}
                setNewInstitution={(e: any) => {
                  return this.setState({ newInstitution: e.target.value, error: null });
                }}
                setGeoNamesErrorMessage={(err: any) => {
                  return this.setState({ geoNamesErrorMessage: err });
                }}
              />
            )}
            <SignupButtons>
              <Button
                type="primary"
                onClick={this.handleClickContinue}
                testid="activation-continue"
                inputType="submit"
                label={localize({ message: 'Button.Label.ContinueToNextStep' })}
              >
                Continue
              </Button>
            </SignupButtons>
            <TermsOfService />
          </Form>
        </SignupContent>
      </SignupContainer>
    );
  };

  renderActivationStep2 = () => {
    return (
      <ProfileImageUpload
        firstName={this.state.firstName}
        onImageUpload={(image: any) => {
          this.setState({ uploadedImg: image });
        }}
        onSubmit={() => {
          this.handleOnSubmit();
        }}
        uploadedImg={this.state.uploadedImg}
      />
    );
  };

  render() {
    if (this.state.isSSO) {
      return (
        <SSOActivation
          isNewUser
          emailToRegister={this.props.userToRegister.email}
          courseId={this.props.router.params.courseId}
          isLtiAdvantageUser={this.state.isLtiAdvantageUser}
        />
      );
    }
    return this.state.step1Valid ? this.renderActivationStep2() : this.renderActivationStep1();
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    course: CourseSelectors.getCourse(state, ownProps.router.params.courseId),
    institutions: selectInstitutions(state),
    signupSuccess: state.async[SIGNUP_USER].success,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    push,
    activateUser,
    getInstitutions,
  })(AccountActivation)
);
