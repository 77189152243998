import React from 'react';
import { connect } from 'react-redux';
import Button from 'components/buttons/Button';
import FileList from 'components/layout/AttachmentManager/FileList';
import {
  fileRestrictionStatus,
  FILE_UPLOAD_MAX_SIZE_IN_BYTES,
  FILE_UPLOAD_MAX_SIZE_IN_MB,
} from '@kritik/constants/activity';
import { InlineInformation } from 'components/layout';
import { getRestrictedFileStatus } from '@kritik/utils/activity';
import { uploadFiles, selectUploadStatus } from 'redux/upload';
import FormField from 'components/core/form/Field';

function generateFileFormData(fileList: any) {
  const formData = new FormData();
  fileList.forEach((file: any) => {
    formData.append('file[]', file, file.name);
  });
  return formData;
}

type OwnAttachmentManagerState = any;

type AttachmentManagerState = OwnAttachmentManagerState & typeof AttachmentManager.defaultProps;

class AttachmentManager extends React.Component<{}, AttachmentManagerState> {
  static defaultProps = {
    allowedExtensions: [],
    testid: 'file-uploader',
  };

  inputRef: any;

  constructor(props: {}) {
    super(props);
    this.state = {
      uploadSize: 0,
      isUploading: false,
    };
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps: {}, prevState: AttachmentManagerState) {
    if (
      !(prevProps as any).uploadError &&
      (this.props as any).uploadError &&
      this.state.isUploading
    ) {
      this.setState({ isUploading: false });
    }
  }

  handleNewFile(e: any) {
    if (this.isValidFileSize(Object.values(e.target.files))) {
      return this.handleFileUpload(e);
    }
    this.setState({ isUploading: false });
  }

  handleFileUpload = async (e: any) => {
    if (this.state.isUploading) {
      return [];
    }
    this.setState({ isUploading: true });
    const formData = generateFileFormData(Object.values(e.target.files));
    if ((this.props as any).allowedExtensions) {
      (formData as any).allowedExtensions = (this.props as any).allowedExtensions.join(',');
    }
    await (this.props as any).uploadFiles({
      formData,
      key: (this.props as any).actionId,
      callback: (uploadedFiles: any) => {
        // @ts-expect-error TS(2339) FIXME: Property 'fileList' does not exist on type 'Readon... Remove this comment to see the full error message
        this.handleFileChange([...this.props.fileList, ...uploadedFiles]);
      },
    });
  };

  handleFileChange(files: any) {
    (this.props as any).onFileChange(files);
    this.setState({ isUploading: false });
  }

  isValidFileSize(fileList: any) {
    const totalSize = fileList.reduce((sum: any, file: any) => {
      sum += file.size;
      return sum;
    }, 0);

    this.setState({ uploadSize: totalSize });
    if (totalSize <= FILE_UPLOAD_MAX_SIZE_IN_BYTES) {
      return true;
    }
    return false;
  }

  toggleFileSelection() {
    if ((this.props as any).onClickAddFiles) {
      (this.props as any).onClickAddFiles();
    }
    this.inputRef.current.click();
  }

  renderError() {
    let errorMessage;
    if (this.state.uploadSize >= FILE_UPLOAD_MAX_SIZE_IN_BYTES) {
      errorMessage = 'File is larger than maximum size allowed.';
    }
    if ((this.props as any).uploadError) {
      errorMessage = (this.props as any).uploadError;
    }
    if (errorMessage) {
      return (
        <FormField>
          <InlineInformation type="danger">{errorMessage}</InlineInformation>
        </FormField>
      );
    }
    return null;
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'fileList' does not exist on type 'Readon... Remove this comment to see the full error message
    const { fileList, allowedExtensions } = this.props;
    const restrictedFileStatus = getRestrictedFileStatus(allowedExtensions);
    return (
      <div role="button" aria-label={(this.props as any).label} className="AttachmentManager">
        <FileList
          files={fileList}
          onRemoveFile={(newList: any) => {
            return this.handleFileChange(newList);
          }}
        />
        <input
          data-testid={(this.props as any).testid}
          hidden
          type="file"
          ref={this.inputRef}
          accept={allowedExtensions ? allowedExtensions.join(', ') : ''}
          multiple
          onChange={(e) => {
            return this.handleNewFile(e);
          }}
        />
        {restrictedFileStatus === fileRestrictionStatus.ALL ? null : (
          <>
            <Button
              type="secondary"
              onClick={() => {
                return this.toggleFileSelection();
              }}
              loading={this.state.isUploading}
              aria-describedby="upload-hint-text"
            >
              Attach a File
            </Button>
            <p className="upload-hint" id="upload-hint-text">
              Maximum upload file size is {FILE_UPLOAD_MAX_SIZE_IN_MB} MB.
            </p>
          </>
        )}
        {this.renderError()}
      </div>
    );
  }
}
const mapStateToProps = (state: any, ownProps: any) => {
  const uploadStatus = selectUploadStatus(state, ownProps.actionId);
  return {
    uploadSuccess: uploadStatus.success,
    uploadError: uploadStatus.error,
  };
};

export default connect(mapStateToProps, { uploadFiles })(AttachmentManager);
