import { connect } from 'react-redux';
import {
  selectCurrentPage,
  selectPageCount,
  selectPageSize,
  selectTotalRowsCount,
} from 'redux/creationTable/select';
import {
  setCurrentPage,
  setTotalRowsCount,
  setPageCount,
  setPageSize,
} from 'redux/creationTable/actions';
import getPaginatedResults from 'actions/pagination/actions';
import { navigateToActivityPath } from 'actions/activity';
import PaginationControls from 'components/PaginationControls';
import { getUpdatedPath } from 'components/CreationList/PaginationMenu/utils';

function PaginationMenu(props: any) {
  if (props.totalRows === 0) {
    return null;
  }
  const handlePageChange = (newPage: number) => {
    props.setCurrentPage(newPage);
    const newUrl = getUpdatedPath(window.location, { param: 'page', value: newPage });
    props.navigateToActivityPath(newUrl);
    props.getPaginatedResults();
  };

  const handlePageSizeChange = (newPageSize: number) => {
    props.setCurrentPage(1);
    props.setPageSize(newPageSize);
    const newUrl = getUpdatedPath(
      window.location,
      { param: 'resultsPerPage', value: newPageSize },
      { param: 'page', value: 1 }
    );
    props.navigateToActivityPath(newUrl);
    props.getPaginatedResults();
  };
  return (
    <PaginationControls
      currentPage={props.currentPage}
      resultsPerPage={props.pageSize}
      totalItems={props.totalRows}
      onPageChange={handlePageChange}
      onResultsPerPageChange={handlePageSizeChange}
      hidePageNumbers={props.hidePageNumbers}
    />
  );
}

const mapStateToProps = (state: any) => {
  return {
    currentPage: selectCurrentPage(state),
    pageCount: selectPageCount(state),
    pageSize: selectPageSize(state),
    totalRows: selectTotalRowsCount(state),
    courseId: state.selected.courseId,
    activityId: state.selected.assignmentId,
  };
};

export default connect(mapStateToProps, {
  setCurrentPage,
  setTotalRowsCount,
  setPageCount,
  getPaginatedResults,
  navigateToActivityPath,
  setPageSize,
})(PaginationMenu);
