export const LABELS = {
  PENDING: 'Pending',
  GRADING: 'Grading',
  COMPLETE: 'Activity Complete',
  GRADED: 'Graded',
  NO_SET_SCHEDULE: 'No Set Schedule',
  OVERDUE: 'Overdue',
};

export const MIN_HOURS_FOR_TIMER = 12;
