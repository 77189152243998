import React from 'react';
import createSvgIcon from '../util/generateIconSVG';

export default createSvgIcon(
  <React.Fragment>
    <circle cx="32" cy="32" r="31.5" fill="#FFE0E0" stroke="#6A2324" />
    <line x1="8" y1="29.4667" x2="56" y2="29.4667" stroke="#6A2324" strokeWidth="1.06667" />
    <circle
      cx="42.7626"
      cy="28.7626"
      r="3.29592"
      fill="#EC3939"
      stroke="#6A2324"
      strokeWidth="1.06667"
    />
    <line x1="8" y1="39.4667" x2="56" y2="39.4667" stroke="#6A2324" strokeWidth="1.06667" />
    <circle
      cx="22.7626"
      cy="38.7626"
      r="3.29592"
      fill="#EC3939"
      stroke="#6A2324"
      strokeWidth="1.06667"
    />
  </React.Fragment>,
  '0 0 64 64'
);
