import { useState, useEffect, Fragment } from 'react';
import TabMenu, { TabMenuUtils } from 'components/layout/TabMenu';
import TabMenuItem from 'components/layout/TabMenu/Item';
import { Feedback } from 'components/Creation/Feedback';
import EvaluationOptions from 'components/Creation/Evaluate/Options';
import StudentContent from 'components/layout/StudentContent/index';
import WrittenEvaluation from 'components/Creation/Evaluate/WrittenEvaluation';
import NoticeBoard from 'components/layout/NoticeBoard/index';
import * as ActivityStatusUtil from '@kritik/utils/stage';
import * as ActivityUtil from '@kritik/utils/activity';
import { FeedbackScoreUtil } from '@kritik/utils/grade';
import * as FormatUtils from '@kritik/utils/format';
import { FeedbackScoreGroupFeedbackNotice } from 'components/Assignment/NoticeBoards';
import * as CreationFeedbackUtils from '@kritik/utils/creation/feedback';
import * as EvaluationUtils from '@kritik/utils/creation/evaluation';
import UserSelectors from 'selectors/user';
import { connect } from 'react-redux';
import * as StudentUtils from 'utils/student';
import { getCreationsFromEntities } from 'selectors/creation';
import { localize } from 'locales/index';
import { selectSpotlights } from 'redux/spotlight';
import { getAssignment } from 'selectors/activity';
import SubmissionSpotlight from 'components/Spotlight/Creation';
import VerticalList from 'components/layout/VerticalList';
import { userSelector } from 'selectors/index';
import { scoreHasTeacherComment } from '@kritik/utils/creation/evaluation';
import InstructorCommentView from 'components/CommentOnContent/EvaluationComment';
import { isCalibrationActivity } from '@kritik/utils/activity';
import { EvaluationStatusLabel } from 'components/Creation/Evaluate/EvaluationStatusLabel';
import { FlagBanner } from 'components/Creation/Evaluate/FlagBanner';
import { TextDisplay } from 'components/layout';
import HighlightedCriteria from 'components/Creation/Evaluate/Criteria';
import { useUserRoleInCourse } from 'hooks/course';

function FeedbackSummary(props: any) {
  const [activeTab, setActiveTab] = useState(0);
  const [scores, setScores] = useState([]);
  const { isInstructorInCourse } = useUserRoleInCourse();

  if (!ActivityStatusUtil.isFeedbackOrLater({ assignment: props.activity })) {
    return null;
  }

  const getEvaluations = () => {
    if (ActivityUtil.isPresentationActivity(props.activity)) {
      return CreationFeedbackUtils.getAssignedFeedbacksForPresentationActivity({
        creations: props.creations,
        studentId: props.student._id,
      });
    }
    return CreationFeedbackUtils.getAssignedFeedbacks(
      props.creation.scores,
      props.student._id
    ).filter((evaluation: any) => {
      return !EvaluationUtils.isSelfEvaluation({ evaluation, userId: props.creation.user._id });
    });
  };

  useEffect(() => {
    const evaluations = getEvaluations();
    setScores(evaluations);
    setActiveTab(0);
  }, [props.student._id, props.creations]);

  const getLabels = () => {
    const labels: any = [];
    scores.forEach((evaluation, index) => {
      labels.push({
        id: index,
        title: `FEEDBACK ${index + 1}`,
        status: TabMenuUtils.getFeedbackTabStatus(evaluation, props.activity),
      });
    });
    return labels;
  };

  const getEvalUser = () => {
    const userId = (scores[activeTab] as any).user;
    const user = UserSelectors.getUser(userId, props.entities);
    return user;
  };

  const getEvaluation = () => {
    return scores[activeTab];
  };

  const getFeedbackDate = () => {
    const evaluation = getEvaluation();
    const isFeedbackCompleted = FeedbackScoreUtil.isFOFCompleted(evaluation);
    if (!isFeedbackCompleted) {
      return null;
    }
    let feedbackCompletedDate = null;
    feedbackCompletedDate = FormatUtils.exactDate((evaluation as any).feedbackOnFeedback.createdAt);
    return (
      <span className="feedback-summary__date">
        <i className="fas fa-check-circle" style={{ color: '#19CA94', marginRight: '3px' }} />
        {feedbackCompletedDate}
      </span>
    );
  };

  const renderFeedbackGiven = () => {
    return (
      <>
        <div className="feedback-summary__header">
          <h3> Feedback Given </h3>
          {getFeedbackDate()}
        </div>
        <Feedback
          assignment={props.activity}
          submission={props.creation}
          evaluation={getEvaluation()}
          showBreakdown={
            ActivityStatusUtil.isFinalized({ assignment: props.activity }) &&
            !ActivityUtil.isGroupAssignment(props.activity)
          }
          showTitle={false}
        />
      </>
    );
  };

  const getCreation = (evaluationId) => {
    return props.creations.find((creation) =>
      creation.scores.some((score) => score._id === evaluationId)
    );
  };

  const displayEvaluationSpotlight = (spotlight) => {
    return <SubmissionSpotlight spotlight={spotlight} />;
  };

  const displayTeacherComment = (evaluation, creation) => {
    if (!scoreHasTeacherComment(evaluation)) {
      return null;
    }
    if (isInstructorInCourse) {
      return (
        <InstructorCommentView
          creationUserId={creation.user._id || creation.user}
          activityId={props.activity._id}
          evaluation={evaluation}
          isInstructor
          creation={creation}
        />
      );
    }
  };

  const displayCommentContent = (evaluation) => {
    if (evaluation.teachercomment) {
      return (
        <Fragment>
          <FlagBanner
            isInstructor={isInstructorInCourse}
            isEvaluationOwner={false}
            evaluation={evaluation}
          />
          <div className="evaluation-labels-container">
            <HighlightedCriteria creationScore={evaluation} rubric={props.activity.rubric} />
            <EvaluationStatusLabel evaluation={evaluation} />
          </div>
          <TextDisplay>
            <WrittenEvaluation evaluation={evaluation} />
          </TextDisplay>
        </Fragment>
      );
    }
    return null;
  };

  const displayEvaluationComment = (evaluation) => {
    if (isCalibrationActivity(props.activity)) {
      return;
    }
    return (
      <div className="evaluation-student-comment" data-testid="evaluation-student-content">
        {displayCommentContent(evaluation)}
      </div>
    );
  };

  const renderTab = () => {
    const evalUser = getEvalUser();
    const evaluation = getEvaluation();
    const creation = getCreation(evaluation._id);
    const spotlight = props.spotlights.find((s: any) => {
      return s.reference === evaluation._id;
    });
    const itemList = [
      displayEvaluationComment(evaluation),
      displayTeacherComment(evaluation, creation),
    ];
    if (spotlight) {
      itemList.splice(1, 0, displayEvaluationSpotlight(spotlight));
    }
    return (
      <>
        <StudentContent user={evalUser} score={StudentUtils.getNewKS(evalUser)}>
          <h5>Written Evaluation</h5>
          <WrittenEvaluation evaluation={getEvaluation()} />
          <EvaluationOptions evaluation={getEvaluation()} creation={creation} spotlight={spotlight}>
            <VerticalList space="sm" itemList={itemList} />
          </EvaluationOptions>
        </StudentContent>
        {renderFeedbackGiven()}
      </>
    );
  };

  const renderTabMenu = () => {
    const labels = getLabels();
    if (scores.length === 0) {
      if (ActivityUtil.isPresentationActivity(props.activity)) {
        return (
          <NoticeBoard type="caution">
            {localize({ message: 'Activity.Presentation.Overview.Feedback.NoneAssigned' })}
          </NoticeBoard>
        );
      }
      return <NoticeBoard type="danger">Student did not receive any evaluations</NoticeBoard>;
    }
    return (
      <>
        <TabMenu
          labels={labels}
          activeLabel={activeTab}
          onSelect={(selection: any) => {
            setActiveTab(selection);
          }}
        />
        <TabMenuItem>{renderTab()}</TabMenuItem>
      </>
    );
  };

  const getStudentName = () => {
    if (!props.creation) {
      return null;
    }
    return `${props.student.user.profile.name}'s`;
  };

  return (
    <>
      <div className="submission-overview__evaluation-header">
        <h3> {props.groupView ? '' : getStudentName()} Feedback Given </h3>
      </div>
      {props.groupView ? <FeedbackScoreGroupFeedbackNotice /> : renderTabMenu()}
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    entities: state.entities,
    creations: getCreationsFromEntities(state, state.selected.assignmentId),
    spotlights: selectSpotlights(state),
    activity: getAssignment(state),
    user: userSelector.selectAuthUser(state),
  };
};

export default connect(mapStateToProps, {})(FeedbackSummary);
