import { useEffect } from 'react';
import { connect } from 'react-redux';
import { setColumns, setInitialState, setTableListBy } from 'redux/creationTable/actions';
import {
  selectTableSettings,
  selectCreationTableFilter,
  selectCreationTableSearch,
  selectSortedColumn,
  selectIsCreationTableLoading,
} from 'redux/creationTable/select';
import CreationTable from 'components/CreationList/Table';
import Row from 'components/CreationList/ProgressTable/Row';
import localConstants from 'components/CreationList/ProgressTable/constants';
import studentSelector from 'selectors/student';
import creationSelector from 'selectors/creation';
import ActivitySelectors from 'selectors/activity';
import GroupSelectors from 'selectors/group';
import submissionSelector from 'selectors/creation';
import * as activityUtils from '@kritik/utils/activity';
import * as activityStatusUtils from '@kritik/utils/stage';
import tableUtil from 'components/CreationList/util';
import { TABLE_TYPE_OPTIONS, LIST_BY_TYPES, TABLE_HEADERS } from 'components/CreationList/constant';
import Spinner from 'components/Loaders/Spinner';
import { setCurrentPage } from 'redux/creationTable/actions';
import { navigateToActivityPage } from 'actions/activity';
import getPaginatedResults from 'actions/pagination/actions';
import { usePrevious } from 'hooks/usePrevious';
import { useGetStudentsLateEvaluationsInActivity } from 'hooks/evaluation';
import {
  getStudentIdsFromTableItemList,
  injectLateEvaluationsToTableItemList,
} from 'containers/assignments/creations/utils';

function ProgressTable(props: any) {
  const prevProps = usePrevious({
    listBy: props.tableSettings.listBy,
  });

  const studentIds = getStudentIdsFromTableItemList(props.itemList);
  const { data: lateEvaluationsMap, isInitialLoading: isLoadingLateEvaluations } =
    useGetStudentsLateEvaluationsInActivity({
      studentIds,
      activityId: props.activityId,
    });

  useEffect(() => {
    const tableType = TABLE_TYPE_OPTIONS.PROGRESS;
    let columns = localConstants.STUDENT_COLUMNS.PEER;
    const isShowEvalsCompleted = true;
    if (activityUtils.isCalibrationActivity(props.activity)) {
      // @ts-expect-error TS(2322) FIXME: Type '({ header: string; align: string; isSortable... Remove this comment to see the full error message
      columns = localConstants.STUDENT_COLUMNS.CALIBRATION;
    } else if (
      activityUtils.isGroupAssignment(props.activity) ||
      activityUtils.isGroupPresentationActivity(props.activity)
    ) {
      // @ts-expect-error TS(2322) FIXME: Type '({ header: string; align: string; isSortable... Remove this comment to see the full error message
      columns = tableUtil.getColumnsToRender(
        TABLE_TYPE_OPTIONS.PROGRESS,
        props.tableSettings.listBy
      );

      // We need to disable sorting for feedback column for presentation Activities
      if (activityUtils.isPresentationActivity(props.activity)) {
        columns = columns.map((column) => {
          if (column.header === TABLE_HEADERS.FEEDBACK) {
            return { ...column, isSortable: false };
          }
          return column;
        });
      }
    }
    columns = setActiveColumn(columns);

    // We need to disable sorting for feedback column for presentation Activities
    if (activityUtils.isPresentationActivity(props.activity)) {
      columns = columns.map((column) => {
        if (column.header === TABLE_HEADERS.FEEDBACK) {
          return { ...column, isSortable: false };
        }
        return column;
      });
    }

    props.setCurrentPage(1);
    props.setInitialState({ columns, type: tableType, isShowEvalsCompleted });
    props.navigateToActivityPage({ courseId: props.courseId, assignmentId: props.activityId });
    props.getPaginatedResults();
  }, []);

  useEffect(() => {
    if (
      activityUtils.isGroupAssignment(props.activity) ||
      activityUtils.isGroupPresentationActivity(props.activity)
    ) {
      if (prevProps && (prevProps as any).listBy !== props.tableSettings.listBy) {
        let columns = [];
        columns = tableUtil.getColumnsToRender(
          TABLE_TYPE_OPTIONS.PROGRESS,
          props.tableSettings.listBy
        );
        columns = setActiveColumn(columns);

        // We need to disable sorting for feedback column for presentation Activities
        if (activityUtils.isPresentationActivity(props.activity)) {
          columns = columns.map((column) => {
            if (column.header === TABLE_HEADERS.FEEDBACK) {
              return { ...column, isSortable: false };
            }
            return column;
          });
        }

        props.setColumns(columns);
      }
    }
    if (!props.tableSettings.listBy) {
      if (
        activityUtils.isGroupAssignment(props.activity) ||
        activityUtils.isGroupPresentationActivity(props.activity)
      ) {
        props.setTableListBy(LIST_BY_TYPES.GROUP);
      } else {
        props.setTableListBy(LIST_BY_TYPES.STUDENT);
      }
    }
  }, [props.tableSettings.listBy]);

  const setActiveColumn = (columns: any) => {
    const _columns = [...columns];
    let activeStatus;
    if (activityStatusUtils.isInCreationGracePeriod(props.activity)) {
      activeStatus = activityStatusUtils.getCreationStage(props.activity);
    } else {
      activeStatus = activityStatusUtils.getActiveStatus(props.activity);
    }
    for (const column of _columns) {
      if (column.header === activeStatus.name) {
        column.isActive = true;
      } else if (
        activeStatus.name === TABLE_HEADERS.EVALUATE &&
        column.header === TABLE_HEADERS.EVALUATIONS_RECEIVED
      ) {
        column.isActive = true;
      } else {
        column.isActive = false;
      }
    }
    return _columns;
  };

  if (isLoadingLateEvaluations || props.isLoadingCreationTable) {
    return (
      <div className="scoring-creation-table__loader--active">
        <Spinner />
      </div>
    );
  }
  return (
    <CreationTable
      id={`progress-creation-table-${props.activity._id}`}
      style={{ minWidth: '768px' }}
      rowGenerator={Row}
      rowList={injectLateEvaluationsToTableItemList({
        itemList: props.itemList,
        lateEvaluationsMap,
      })}
    />
  );
}

const mapStateToProps = (state: any) => {
  const itemList = tableUtil.generateRowListData()(
    {
      CreationSelectors: creationSelector,
      SubmissionSelectors: submissionSelector,
      GroupSelectors,
      StudentSelectors: studentSelector,
      ActivitySelectors,
    },
    state
  );

  return {
    itemList,
    filter: selectCreationTableFilter(state),
    search: selectCreationTableSearch(state),
    sortedColumn: selectSortedColumn(state),
    activity: ActivitySelectors.getAssignment(state, state.selected.assignmentId),
    tableSettings: selectTableSettings(state),
    feedbackReceivedMap: creationSelector.selectFeedbackReceivedMap(state),
    isLoadingCreationTable: selectIsCreationTableLoading(state),
    courseId: state.selected.courseId,
    activityId: state.selected.assignmentId,
  };
};

export default connect(mapStateToProps, {
  setInitialState,
  setColumns,
  setTableListBy,
  setCurrentPage,
  navigateToActivityPage,
  getPaginatedResults,
})(ProgressTable);
