import { useState } from 'react';
import { connect } from 'react-redux';
import {
  SignupContainer,
  SignupMainTitle,
  SignupButtons,
  SignupContent,
  SignupHeader,
  SignupText,
} from 'components/AuthWrapper';
import Button from 'components/buttons/Button';
import FormFieldLabel from 'components/core/form/FieldLabel';
import FormField from 'components/core/form/Field';
import RegionPicker from 'components/General/RegionPicker/index';
import * as RegionUtils from 'utils/region';
import TermsOfService from 'components/auth/TermsOfService';
import { redirectToEdlinkSSO } from 'actions/edlink';
import { getEdlinkUserAccount } from 'services/edlink';
import EdlinkConstants from 'actions/edlink/constants';
import NoticeBoard from 'components/layout/NoticeBoard';
import { userRoles } from '@kritik/constants/user';
import { trackEvent } from 'utils/userEvents';

function LtiAdvantageUserView() {
  return (
    <>
      <SignupText weight="strong">Single Sign-on instructions</SignupText>
      <SignupText color="dark">Your institution has a special way to access Kritik</SignupText>
      <SignupText color="dark">Please follow the instructions below to sign-in</SignupText>
      <div style={{ paddingLeft: '10px', marginBottom: '50px' }}>
        <p>1. Sign in to your Learning Management System (LMS) using your school credentials</p>
        <p>2. Navigate to your course to find the Kritik link</p>
        <p>3. Click on the Kritik link to sign in</p>
      </div>
    </>
  );
}

function NonLtiAdvantageUserView(props: any) {
  const [userHasLmsAccount, setUserHasLmsAccount] = useState(true);
  const handleSSOTrigger = async ({ emailToRegister, role, action, courseId }: any) => {
    if (!emailToRegister) {
      return;
    }
    const response = await getEdlinkUserAccount(emailToRegister);
    if (Array.isArray(response.integrations) && response.integrations.length === 0) {
      setUserHasLmsAccount(false);
    } else {
      if (action === EdlinkConstants.SSO_ACTION_SIGN_UP && role === userRoles.TEACHER) {
        trackEvent('Instructor Sign-up', null, {
          isSSO: true,
          instructorEmail: emailToRegister,
        });
      }
      await redirectToEdlinkSSO({
        userIntegrationsList: response.integrations,
        userEmail: emailToRegister,
        role,
        action,
        courseId,
      });
    }
  };

  const renderErrorNoLmsAccount = () => {
    if (userHasLmsAccount) {
      return null;
    }
    return (
      <NoticeBoard type="danger" title="No LMS account found">
        We didn't find your lms account. Please contact support
      </NoticeBoard>
    );
  };

  const getSSOAction = () => {
    return props.isSignIn ? EdlinkConstants.SSO_ACTION_SIGN_IN : EdlinkConstants.SSO_ACTION_SIGN_UP;
  };

  const getSSORole = () => {
    return props.isInstructor ? userRoles.TEACHER : userRoles.STUDENT;
  };
  return (
    <>
      <SignupText weight="strong">Single Sign-on (SSO)</SignupText>
      <SignupText color="dark">
        {`${
          props.isSignIn ? 'Sign-in' : 'Sign-up'
        } using your institution’s learning management system.`}
      </SignupText>
      <SignupButtons>
        <Button
          type="primary"
          onClick={async () => {
            await handleSSOTrigger({
              emailToRegister: props.emailToRegister || props.emailToSignIn,
              role: getSSORole(),
              action: getSSOAction(),
              courseId: props.courseId,
            });
          }}
        >
          Single Sign-on
        </Button>
      </SignupButtons>
      {renderErrorNoLmsAccount()}
      <div style={{ height: '100px' }} />
      <SignupText color="dark">
        Single Sign-on (SSO) has been enabled by your school. You can now use your school login
        credentials to access Kritik.
      </SignupText>
    </>
  );
}

function SSOActivation(props: any) {
  const renderTitle = () => {
    const title = props.isInstructor
      ? 'It looks like you have Single Sign-on'
      : `It looks your institution requires Single Sign-on`;
    return props.isNewUser ? (
      <SignupMainTitle>
        <div>Hi there,</div>
        <div>{title}</div>
      </SignupMainTitle>
    ) : (
      <SignupMainTitle>
        <div>Hey,</div>
        <div>
          {props.isSignIn
            ? 'Looks like you can use Single Sign-on'
            : 'Looks like you already have an account'}
        </div>
      </SignupMainTitle>
    );
  };

  return (
    <SignupContainer graphic="rocket">
      <SignupContent>
        {renderTitle()}
        <SignupHeader size="xl">{props.isSignIn ? 'Sign-in' : 'Sign-up'}</SignupHeader>
        <FormField>
          <FormFieldLabel color="light" label="Region" id="region-select-field" />
          <RegionPicker value={RegionUtils.getRegion()} disabled />
        </FormField>
        {props.isLtiAdvantageUser ? (
          <LtiAdvantageUserView />
        ) : (
          <NonLtiAdvantageUserView
            isSignIn={props.isSignIn}
            isInstructor={props.isInstructor}
            emailToRegister={props.emailToRegister}
            emailToSignIn={props.emailToSignIn}
          />
        )}
        <TermsOfService />
      </SignupContent>
    </SignupContainer>
  );
}

SSOActivation.defaultProps = {
  isInstructor: false,
  isSignIn: false,
  courseId: '',
};

export default connect(null, {})(SSOActivation);
