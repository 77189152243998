import React from 'react';
import { connect } from 'react-redux';
import { PanelDisplay } from 'components/layout';
import { getGroupOfStudent } from 'selectors/group';
import GroupCard from 'components/GroupManager/GroupCard';

const mapStateToProps = (state: any) => {
  return {
    studentGroup: getGroupOfStudent(
      state,
      state.selected.assignmentId,
      state.user.student.studentId
    ),
  };
};

const AssignmentGroup = ({ studentGroup }: any) => {
  if (!studentGroup) {
    return null;
  }

  return (
    <PanelDisplay>
      <GroupCard group={studentGroup} testid="group-member-list" />
    </PanelDisplay>
  );
};

export default connect(mapStateToProps)(AssignmentGroup);
