import * as _ from 'lodash-es';

const getStudent = (item: any) => {
  return item.student;
};

export const sortByStudentName = (itemList: any, ascending: any) => {
  const _itemList = itemList.filter((item) => {
    const itemCreation = item.creation;
    return itemCreation.dispute.status !== 'No dispute';
  });
  return _.orderBy(
    _itemList,
    [
      function (item) {
        const student = getStudent(item);
        return student.user.profile.lastName.toLowerCase();
      },
    ],
    [ascending ? 'asc' : 'desc']
  );
};

export const sortByGroupName = (itemList: any, ascending: any) => {
  const _itemList = itemList.filter((item) => {
    const itemCreation = item.creation;
    return itemCreation.dispute.status !== 'No dispute';
  });
  return _.orderBy(
    _itemList,
    [
      function (item) {
        const creation = item.creation;
        return creation.group.name.toLowerCase();
      },
    ],
    [ascending ? 'asc' : 'desc']
  );
};
