import React from 'react';
import createSvgIcon from '../util/generateIconSVG';

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M0.61607 7.28572C0.61607 7.58929 0.74107 7.88393 0.946428 8.09822L1.61607 8.76786C1.83036 8.97322 2.125 9.09822 2.42857 9.09822C2.73214 9.09822 3.02678 8.97322 3.23214 8.76786L5.85714 6.14286L5.85714 12.4286C5.85714 13.0536 6.375 13.5714 7 13.5714L8.14286 13.5714C8.76786 13.5714 9.28571 13.0536 9.28571 12.4286L9.28571 6.14286L11.9107 8.76786C12.1161 8.97321 12.4107 9.09822 12.7143 9.09822C13.0179 9.09822 13.3125 8.97321 13.5268 8.76786L14.1875 8.09821C14.4018 7.88393 14.5268 7.58929 14.5268 7.28571C14.5268 6.98214 14.4018 6.6875 14.1875 6.48214L8.375 0.660715C8.16964 0.455358 7.875 0.330358 7.57143 0.330358C7.26786 0.330358 6.97321 0.455358 6.75893 0.660715L0.946427 6.48214C0.74107 6.6875 0.61607 6.98214 0.61607 7.28572Z"
      fill="#4844A3"
    />
  </React.Fragment>,
  '0 0 15 14'
);
