import React from 'react';
import Evaluation from 'components/Spotlight/View/Evaluation';
import Creation from 'components/Spotlight/View/Creation';

function Reference(props: any) {
  const { spotlight } = props;
  const referenceId = spotlight.getReferenceId();

  return spotlight.isCreationType() ? (
    <Creation creationId={referenceId} />
  ) : (
    <Evaluation evaluationId={referenceId} />
  );
}

export default Reference;
