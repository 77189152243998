import React from 'react';
import {
  isEvaluate,
  isProcessing1,
  isFinalized,
  isProcessing2,
  isFeedback,
  isGrading,
} from '@kritik/utils/stage';
import * as CreationStatusUtil from '@kritik/utils/creation/status';
import * as LateSubmissionUtils from '@kritik/utils/creation/lateCreation';
import { Close, ExclamationTriangle } from 'images/icons/fa';

const labels = {
  GRACE: {
    label: 'Grace Period',
    status: 'caution',
  },
  MISSED: {
    label: 'Missed',
    status: 'danger',
  },
  OVERDUE: {
    label: 'Overdue',
    status: 'danger',
  },
  LATE_PENDING: {
    label: 'Late Creation',
    status: 'caution',
    icon: <ExclamationTriangle />,
  },
  LATE_ACCEPTED: {
    label: 'Late - Accepted',
    status: 'success',
    icon: <ExclamationTriangle />,
  },
  LATE_REJECTED: {
    label: 'Late - Rejected',
    status: 'danger',
    icon: <Close />,
  },
};

const generateCellLabel = (creation: any, activity: any) => {
  if (isProcessing1({ assignment: activity })) {
    if (LateSubmissionUtils.isLateSubmissionPending(creation)) {
      return labels.LATE_PENDING;
    }
    if (CreationStatusUtil.wasCreationSubmittedInGracePeriod(creation)) {
      return labels.GRACE;
    }
    if (LateSubmissionUtils.isLateSubmissionAccepted(creation)) {
      return labels.LATE_ACCEPTED;
    }
    if (LateSubmissionUtils.isLateSubmissionRejected(creation)) {
      return labels.LATE_REJECTED;
    }
  }

  if (isEvaluate({ assignment: activity }) || isProcessing2({ assignment: activity })) {
    if (CreationStatusUtil.isCreationEmpty(creation)) {
      return labels.OVERDUE;
    }
    if (LateSubmissionUtils.isLateSubmissionPending(creation)) {
      return labels.LATE_PENDING;
    }
    if (CreationStatusUtil.wasCreationSubmittedInGracePeriod(creation)) {
      return labels.GRACE;
    }
    if (CreationStatusUtil.wasCreationMissed(creation)) {
      return labels.MISSED;
    }
    if (LateSubmissionUtils.isLateSubmissionAccepted(creation)) {
      return labels.LATE_ACCEPTED;
    }
    if (LateSubmissionUtils.isLateSubmissionRejected(creation)) {
      return labels.LATE_REJECTED;
    }
  }

  if (isFeedback({ assignment: activity })) {
    if (CreationStatusUtil.isCreationEmpty(creation)) {
      return labels.OVERDUE;
    }
    if (LateSubmissionUtils.isLateSubmissionPending(creation)) {
      return labels.LATE_PENDING;
    }
    if (CreationStatusUtil.wasCreationSubmittedInGracePeriod(creation)) {
      return labels.GRACE;
    }
    if (CreationStatusUtil.wasCreationMissed(creation)) {
      return labels.MISSED;
    }
    if (LateSubmissionUtils.isLateSubmissionAccepted(creation)) {
      return labels.LATE_ACCEPTED;
    }
    if (LateSubmissionUtils.isLateSubmissionRejected(creation)) {
      return labels.LATE_REJECTED;
    }
  }

  if (isGrading({ assignment: activity })) {
    if (CreationStatusUtil.isCreationEmpty(creation)) {
      return labels.OVERDUE;
    }
    if (LateSubmissionUtils.isLateSubmissionPending(creation)) {
      return labels.LATE_PENDING;
    }
    if (CreationStatusUtil.wasCreationSubmittedInGracePeriod(creation)) {
      return labels.GRACE;
    }
    if (CreationStatusUtil.wasCreationMissed(creation)) {
      return labels.MISSED;
    }
    if (LateSubmissionUtils.isLateSubmissionAccepted(creation)) {
      return labels.LATE_ACCEPTED;
    }
    if (LateSubmissionUtils.isLateSubmissionRejected(creation)) {
      return labels.LATE_REJECTED;
    }
  }

  if (isFinalized({ assignment: activity })) {
    if (CreationStatusUtil.isCreationEmpty(creation)) {
      return labels.OVERDUE;
    }
    if (LateSubmissionUtils.isLateSubmissionPending(creation)) {
      return labels.LATE_PENDING;
    }
    if (CreationStatusUtil.wasCreationSubmittedInGracePeriod(creation)) {
      return labels.GRACE;
    }
    if (CreationStatusUtil.wasCreationMissed(creation)) {
      return labels.MISSED;
    }
    if (LateSubmissionUtils.isLateSubmissionAccepted(creation)) {
      return labels.LATE_ACCEPTED;
    }
    if (LateSubmissionUtils.isLateSubmissionRejected(creation)) {
      return labels.LATE_REJECTED;
    }
  }

  return {
    label: null,
  };
};

export default {
  generateCellLabel,
};
