import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FormField from 'components/core/form/FieldInfo';
import CriteriaList from 'components/Creation/Evaluate/Criteria/List';
import CriteriaSelection from 'components/Creation/Evaluate/Criteria/Selector';
import { getHighlightedCriteriaFromScore } from '@kritik/utils/creation/score';

const CriteriaEditor = (props: any) => {
  const [selectedCriteria, setSelectedCriteria] = useState([]);
  const [isClearedCriteria, setClearedCriteria] = useState(false);

  const generateCriteriaOptions = (criteriaList: any) => {
    return criteriaList.map((criterionIndex: any) => {
      const rubricCriterion = props.rubric.criteria[criterionIndex];
      return { label: rubricCriterion.name, key: criterionIndex };
    });
  };

  useEffect(() => {
    if (props.creationScore) {
      const highlightedCriteria = getHighlightedCriteriaFromScore(props.creationScore);
      const defaultCriteriaList = generateCriteriaOptions(highlightedCriteria);
      setSelectedCriteria(defaultCriteriaList);
      if (props.onChange) {
        props.onChange(highlightedCriteria);
      }
    }
  }, [props.creationScore]);

  const getSelectedCriteriaIndexes = (criteriaOjects: any) => {
    return Object.values(criteriaOjects).map((criterion) => {
      return (criterion as any).key;
    });
  };

  const onSelectCriterion = (selectedCriterion: any) => {
    const isAlreadySelected = selectedCriteria.find((criterion) => {
      return (criterion as any).key === selectedCriterion.key;
    });
    if (isAlreadySelected) {
      return;
    }
    setSelectedCriteria([...selectedCriteria, selectedCriterion]);
    const criteriaIndexes = getSelectedCriteriaIndexes([...selectedCriteria, selectedCriterion]);
    props.onChange(criteriaIndexes);
    return setClearedCriteria(false);
  };

  const onRemoveCriterion = (removedCriterionKey: any) => {
    const currentSelections = selectedCriteria.filter((criterion) => {
      return (criterion as any).key !== removedCriterionKey;
    });
    setSelectedCriteria(currentSelections);
    const criteriaIndexes = getSelectedCriteriaIndexes(currentSelections);
    props.onChange(criteriaIndexes);
    return setClearedCriteria(true);
  };

  const renderCriteriaSelector = () => {
    if (!props.isEditing) {
      return null;
    }
    return (
      <CriteriaSelection
        creationScore={props.creationScore}
        onSelect={(criterion: any) => {
          onSelectCriterion(criterion);
        }}
        rubric={props.rubric}
        isClearedCriteria={isClearedCriteria}
      />
    );
  };

  return (
    <div>
      {renderCriteriaSelector()}
      <FormField>
        <CriteriaList
          criteriaList={selectedCriteria}
          onRemoveCriterion={(removedKey: any) => {
            onRemoveCriterion(removedKey);
          }}
          isEditing={props.isEditing}
        />
      </FormField>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

CriteriaEditor.defaultProps = {
  creationScore: null,
  isEditing: false,
  onChange: null,
};

export default connect(mapStateToProps)(CriteriaEditor);
