const MIN_PASSWORD_LENGTH = 10;
const MAX_PASSWORD_LENGTH = 128;

export function validateNewPassword(password: string): { error: string } {
  let error: string;
  if (password.length < MIN_PASSWORD_LENGTH) {
    error = `Your password must be at least ${MIN_PASSWORD_LENGTH} characters long`;
  }
  if (password.length > MAX_PASSWORD_LENGTH) {
    error = `Your password must be no more than ${MAX_PASSWORD_LENGTH} characters long`;
  }
  return { error };
}
