import RubricConstants from '@kritik/constants/rubric';
import * as _ from 'lodash-es';
import type { RubricCriteria, Rubric } from '@kritik/types.generated';

export const isCriterionBinary = (rubric: Rubric, criterionIndex: number) => {
  const criterion = rubric.grid[criterionIndex];
  return isRowBinary(criterion);
};

export function getWeightOfEachStar(criterionIndex: number, rubric: Rubric) {
  const criterion = rubric.criteria[criterionIndex];
  if (criterion.weight == 0) {
    return 0;
  }

  const totalPossibleLevels = getLevelsInCriteria(rubric, criterionIndex);
  const starWeight = criterion.weight / totalPossibleLevels;
  return starWeight;
}

export function getLevelOfScore(rawScore: number, scoreIndex: number, rubric: Rubric) {
  if (rawScore === 0) {
    return 0;
  }
  const weightedScore = calcWeightedScoreFromMark(rawScore, scoreIndex, rubric);
  const starWeight = getWeightOfEachStar(scoreIndex, rubric);
  let level;
  if (starWeight === 0) {
    level = weightedScore;
  } else {
    level = weightedScore / starWeight;
  }
  return Math.ceil(level);
}

export const validateName = (name: string) => {
  if (!name) {
    return `Rubric name must exist`;
  }

  if (name.length > RubricConstants.MAX_NAME_LENGTH) {
    return `Rubric name must be at most ${RubricConstants.MAX_NAME_LENGTH} characters long`;
  }
  if (name.length < RubricConstants.MIN_NAME_LENGTH) {
    return `Rubric name must be at least ${RubricConstants.MIN_NAME_LENGTH} character${
      RubricConstants.MIN_NAME_LENGTH === 1 ? '' : 's'
    } long`;
  }
};

export const capitalizeName = (name: string) => {
  if (!name || !name.length) {
    return null;
  }

  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const getTotalCriteriaWeight = (rubric: Rubric) => {
  if (!rubric || !rubric.criteria) {
    return 0;
  }
  return rubric.criteria.reduce((sum, criterion) => {
    return (sum += Number(criterion.weight));
  }, 0);
};

export const getWeightOfCriteria = (criterionIndex: number, rubric: Rubric) => {
  return rubric.criteria[criterionIndex].weight;
};

export const isMultiLevelRubric = (rubric: Rubric) => {
  if (!rubric) {
    return;
  }
  if (!rubric.grid.length) {
    return false;
  }
  const baseLength = rubric.grid[0].length;
  for (const row of rubric.grid) {
    if (row.length != baseLength) {
      return true;
    }
  }
  return false;
};

export const getLevelsInCriteria = (rubric: Rubric, criterionIndex: number) => {
  const criterionLevels = rubric.grid[criterionIndex];
  return criterionLevels.length - 1;
};

export const getCriterion = (rubric: Rubric, criterionIndex: number) => {
  return rubric.criteria[criterionIndex];
};

export const isRowBinary = (row: { length: number }) => {
  return row.length === 2;
};

export function calcWeightedScoreFromMark(mark: number, criterionIndex: number, rubric: Rubric) {
  const starWeight = getWeightOfEachStar(criterionIndex, rubric);
  // fixes issue of float representation (eg. 3 * 0.2 = 0.60000000000001)
  return _.round(mark * starWeight, 15);
}
export function calcMarkFromWeightedScore(mark: number, criterionIndex: number, rubric: Rubric) {
  const starWeight = getWeightOfEachStar(criterionIndex, rubric);
  if (starWeight === 0) {
    return mark;
  }
  return mark / starWeight;
}

/*
  This helper is meant to account for when passLevel was wrongly
  stored as a string with the name of the level, 
  where it should have been stored as an index
*/
export const getPassLevelName = (rubric: Rubric) => {
  return Number.isNaN(parseInt(rubric.passLevel, 10))
    ? rubric.passLevel
    : rubric.levels[parseInt(rubric.passLevel, 10)];
};

export const isDefaultRubric = (rubric: Rubric) => {
  return rubric.default;
};

export function validateCriteriaName(criteria: RubricCriteria[]) {
  const error = criteria.find((criterion) => {
    const { name } = criterion;
    return name.trim().length === 0;
  });
  if (error) {
    return 'Criteria name cannot be empty';
  }
  return null;
}

export function isUseModeRubric(rubric: Rubric) {
  const { criteria } = rubric;
  return criteria.some((criterion) => criterion.useMode === true);
}

export function hasLessLevelsThanPassLevel(rubric: Rubric) {
  if (!rubric) {
    return false;
  }
  const levelsCount = rubric.levels.length;
  return parseInt(rubric.passLevel, 10) + 1 > levelsCount;
}

export function hasCriteriaOrLevelChanged(prevRubric: Rubric, currRubric: Rubric) {
  // check if criteria added/removed OR overall maximum level changes
  if (
    prevRubric.criteria.length !== currRubric.criteria.length ||
    prevRubric.levels.length !== currRubric.levels.length
  ) {
    return true;
  }
  // if a specific criteria changed its maximum level
  for (let i = 0; i < prevRubric.grid.length; i++) {
    if (prevRubric.grid[i].length !== currRubric.grid[i].length) {
      return true;
    }
  }
  return false;
}
