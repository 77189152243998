import classNames from 'classnames';

type InlineInformationProps = {
  children?: any | any[];
  title?: string;
  type?: 'information' | 'success' | 'warning' | 'danger' | 'attention' | 'prof-graded';
  className?: string;
  skipSeparator?: boolean;
  style?: any;
  testid?: string;
  information?: boolean;
};

const InlineInformation = (props: InlineInformationProps) => {
  const { children, title, type = 'information', className, skipSeparator, style, testid } = props;
  const containerClasses = classNames('inline-info', className, {
    'inline-info--information': type === 'information',
    'inline-info--attention': type === 'attention',
    'inline-info--warning': type === 'warning',
    'inline-info--danger': type === 'danger',
    'inline-info--success': type === 'success' || type === 'prof-graded',
  });

  const renderIcon = () => {
    const iconClasses = classNames('fa', {
      'fa-info-circle': type === 'attention' || type === 'information',
      'fa-exclamation-circle': type === 'warning' || type === 'danger',
      'fa-check-circle': type === 'success',
      'fa-book': type === 'prof-graded',
    });
    const mediaClasses = classNames('inline-info-media', iconClasses);
    return (
      <div className="inline-info__icon">
        <i className={mediaClasses} />
      </div>
    );
  };

  const renderTitle = () => {
    if (!title) {
      return null;
    }
    return <span className="inline-info__label">{title}</span>;
  };

  const renderSeparator = () => {
    if (!title || !children || skipSeparator) {
      return null;
    }
    return ' - ';
  };

  const renderChildren = () => {
    if (!children) {
      return null;
    }
    return <span>{children}</span>;
  };
  return (
    <div
      style={style}
      className={containerClasses}
      role={type === 'danger' || type === 'warning' ? 'alert' : 'status'}
      data-testid={testid}
    >
      {renderIcon()}
      <div className="inline-info__description">
        {renderTitle()}
        {renderSeparator()}
        {renderChildren()}
      </div>
    </div>
  );
};

export default InlineInformation;
