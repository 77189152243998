import * as _ from 'lodash-es';
import { isEvaluate, isFeedback, isGrading, isFinalized } from '@kritik/utils/stage';
import { getNumEvaluationsReceived } from '@kritik/utils/creation/evaluation';

export const sortByEvalReceived = (itemList: any, ascending: any, activity: any) => {
  let sortedList = itemList;
  if (isEvaluate({ assignment: activity })) {
    sortedList = sortInEvaluateStage(itemList);
  } else if (isFeedback({ assignment: activity })) {
    sortedList = sortInEvaluateStage(itemList);
  } else if (isGrading({ assignment: activity })) {
    sortedList = sortInEvaluateStage(itemList);
  } else if (isFinalized({ assignment: activity })) {
    sortedList = sortInEvaluateStage(itemList);
  }
  return ascending ? sortedList.reverse() : sortedList;
};

const getCreation = (item: any) => {
  return item.creation;
};

const sortInEvaluateStage = (itemList: any) => {
  return _.orderBy(
    itemList,
    [
      function (item) {
        const creation = getCreation(item);
        return getNumEvaluationsReceived(creation);
      },
    ],
    ['desc']
  );
};
