import { connect } from 'react-redux';
import { TextDisplay } from 'components/layout';
import FileList from 'components/layout/AttachmentManager/FileList';
import NoticeBoard from 'components/layout/NoticeBoard';
import StarRubricMarks from 'components/General/StarRubricMarks';
import { createEvaluationFlag } from 'actions/activity';
import { isFeedbackOrLater } from '@kritik/utils/stage';
import { isCommentRemoved } from '@kritik/utils/creation/status';
import { scoreHasTeacherComment } from '@kritik/utils/creation/evaluation';
import FlagMenu from 'components/Creation/Flag';
import { isCalibrationActivity } from '@kritik/utils/activity';
import HighlightedCriteria from 'components/Creation/Evaluate/Criteria';
import EvaluationOptions from 'components/Creation/Evaluate/Options';
import WrittenEvaluation from 'components/Creation/Evaluate/WrittenEvaluation';
import SubmissionSpotlight from 'components/Spotlight/Creation';
import { selectSpotlightByReference } from 'redux/spotlight';
import InstructorCommentView from 'components/CommentOnContent/EvaluationComment';
import { CreationScoreUtil } from '@kritik/utils/grade';
import VerticalList from 'components/layout/VerticalList';
import { EvaluationStatusLabel } from 'components/Creation/Evaluate/EvaluationStatusLabel';
import { FlagBanner } from 'components/Creation/Evaluate/FlagBanner';
import { localize } from 'locales/index';
import { UserRoleInCourse } from 'app-types';
import { useUserRoleInCourse } from 'hooks/course';
import { STAR_RUBRIC_MARKS } from 'components/General/constant';
import { Activity, Creation } from '@kritik/types.generated';

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    user: state.user,
    course: state.entities.courses[state.selected.courseId],
    spotlight: selectSpotlightByReference(ownProps.userScore._id, state),
  };
};

function CommentContent(props: { isEvaluationOwner: boolean; userScore: any; activity: any }) {
  const { isInstructorInCourse } = useUserRoleInCourse();
  const { userScore } = props;
  if (isCommentRemoved(userScore) && !isInstructorInCourse) {
    return <FlagBanner isEvaluationOwner={props.isEvaluationOwner} evaluation={userScore} />;
  }
  if (userScore.comment) {
    return (
      <>
        <FlagBanner
          isInstructor={isInstructorInCourse}
          isEvaluationOwner={props.isEvaluationOwner}
          evaluation={userScore}
        />
        <div className="evaluation-labels-container">
          <HighlightedCriteria creationScore={props.userScore} rubric={props.activity.rubric} />
          <EvaluationStatusLabel evaluation={props.userScore} />
        </div>
        <TextDisplay>
          <WrittenEvaluation evaluation={userScore} />
        </TextDisplay>
      </>
    );
  }
  return null;
}

function EvaluationSpotlight(props: { spotlight: any }) {
  if (!props.spotlight) {
    return null;
  }
  return <SubmissionSpotlight spotlight={props.spotlight} />;
}

function EvaluationComment(props: {
  activity: Activity;
  isEvaluationOwner: boolean;
  userScore: any;
  creation: Creation;
}) {
  if (isCalibrationActivity(props.activity)) {
    return;
  }
  return (
    <div className="evaluation-student-comment" data-testid="evaluation-student-content">
      <h4 className="header-4">
        {localize({ message: 'Creation.Evaluation.View.WrittenEvaluation' })}
      </h4>
      <CommentContent
        activity={props.activity}
        isEvaluationOwner={props.isEvaluationOwner}
        userScore={props.userScore}
      />
      {isFeedbackOrLater({ assignment: props.activity }) && (
        <div className="flag-icon-container">
          <FlagMenu creation={props.creation} evaluation={props.userScore} />
        </div>
      )}
    </div>
  );
}

function TeacherComment(props: { userScore: any; creation: any; activity: any; user: any }) {
  const { isInstructorInCourse } = useUserRoleInCourse();
  if (!scoreHasTeacherComment(props.userScore)) {
    return null;
  }
  const scoreBelongsToUser = props.userScore.user._id === props.user.authUser.id;
  if (isInstructorInCourse || scoreBelongsToUser) {
    return (
      <InstructorCommentView
        creationUserId={props.creation.user._id}
        activityId={props.activity._id}
        evaluation={props.userScore}
        isInstructor={isInstructorInCourse}
        creation={props.creation}
      />
    );
  }
}

function EvaluationScore(props: {
  userScore: any;
  activity: any;
  creation: any;
  showScoreLabel: boolean;
  isEvaluationOwner: boolean;
}) {
  const { isInstructorInCourse } = useUserRoleInCourse();
  if (props.isEvaluationOwner) {
    const shouldShowFilesUnderComment = isFeedbackOrLater(props.activity);
    return (
      <>
        {props.showScoreLabel && (
          <h3 className="header-3 score-header" data-testid="student-info-evaluation-score-header">
            {localize({ message: 'Creation.Evaluation.View.Score' })}
          </h3>
        )}
        <StarRubricMarks
          rubric={props.activity.rubric}
          comparisonMarks={
            props.activity.isCalibrationActivity && isInstructorInCourse
              ? [{ type: 'final-score', marks: CreationScoreUtil.getTeacherScore(props.creation) }]
              : null
          }
          isCalibrationActivity={props.activity.isCalibrationActivity}
          marks={props.userScore.marks}
          type="secondary"
          precision={0.5}
          tableType={STAR_RUBRIC_MARKS.DEFAULT}
        />
        {shouldShowFilesUnderComment && <FileList files={props.userScore.files} />}
      </>
    );
  }
  return null;
}

function EvaluationViewComponent(props: EvaluationViewProps) {
  const { isInstructorInCourse } = useUserRoleInCourse();

  const isEvaluationOwner = () => {
    if (isInstructorInCourse) {
      return true;
    }
    if (!props.userScore) {
      return false;
    }
    return props.user.authUser.id === props.userScore.user._id;
  };

  if (!props.userScore) {
    return isFeedbackOrLater({ assignment: props.assignment }) ? (
      <NoticeBoard
        type="danger"
        title={localize({ message: 'Creation.Evaluation.View.Missed.Notice.Title' })}
        testid="missed-evaluation-notice"
      >
        {localize({ message: 'Creation.Evaluation.View.Missed.Notice.Content' })}
      </NoticeBoard>
    ) : (
      <NoticeBoard
        type="information"
        title={localize({ message: 'Creation.Evaluation.View.NotYetEvaluated.Notice.Title' })}
      />
    );
  }

  return (
    <EvaluationOptions
      evaluation={props.userScore}
      creation={props.submission}
      spotlight={props.spotlight}
    >
      <VerticalList
        space="sm"
        itemList={[
          <EvaluationScore
            activity={props.assignment}
            creation={props.submission}
            isEvaluationOwner={isEvaluationOwner()}
            showScoreLabel={props.showScoreLabel}
            userScore={props.userScore}
          />,
          <EvaluationComment
            activity={props.assignment}
            creation={props.submission}
            isEvaluationOwner={isEvaluationOwner()}
            userScore={props.userScore}
          />,
          <EvaluationSpotlight spotlight={props.spotlight} />,
          <TeacherComment
            activity={props.assignment}
            creation={props.submission}
            user={props.user}
            userScore={props.userScore}
          />,
        ]}
      />
    </EvaluationOptions>
  );
}

type EvaluationViewProps = {
  userRole: UserRoleInCourse;
  userScore: any;
  submission: any;
  assignment: any;
  showScoreLabel: boolean;
  spotlight: any;
  user: any;
  course: any;
};

export default connect(mapStateToProps, {
  createEvaluationFlag,
})(EvaluationViewComponent);
