import React, { useState } from 'react';
import NavigationLink from 'components/Navigation/Link';
import { Popover } from '@material-ui/core';
import useMatchMedia, { useScreenSize } from 'hooks/useMatchMedia';

const PopoverTab = (props: any) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { isTablet } = useScreenSize();

  const handlePopoverOpen = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = !!anchorEl;

  return (
    <div className="PopoverTab">
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className="popover-link-container"
      >
        <NavigationLink to={props.destination} selectable {...props} />
      </div>
      <Popover
        style={{ pointerEvents: 'none' }}
        id="mouse-over-popover"
        open={isTablet && open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {props.content}
      </Popover>
    </div>
  );
};

export default PopoverTab;
