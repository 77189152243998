const GET_EDLINK_ACCOUNT_REQUEST = 'GET_EDLINK_ACCOUNT_REQUEST';
const GET_EDLINK_ACCOUNT_SUCCESS = 'GET_EDLINK_ACCOUNT_SUCCESS';
const GET_EDLINK_ACCOUNT_FAILURE = 'GET_EDLINK_ACCOUNT_FAILURE';

const GET_EDLINK_ACTIVITIES_REQUEST = 'GET_EDLINK_ACTIVITIES_REQUEST';
const GET_EDLINK_ACTIVITIES_SUCCESS = 'GET_EDLINK_ACTIVITIES_SUCCESS';
const GET_EDLINK_ACTIVITIES_FAILURE = 'GET_EDLINK_ACTIVITIES_FAILURE';

const GET_EDLINK_COURSES_REQUEST = 'GET_EDLINK_COURSES_REQUEST';
const GET_EDLINK_COURSES_SUCCESS = 'GET_EDLINK_COURSES_SUCCESS';
const GET_EDLINK_COURSES_FAILURE = 'GET_EDLINK_COURSES_FAILURE';

const GET_EDLINK_INTEGRATIONS_REQUEST = 'GET_EDLINK_INTEGRATIONS_REQUEST';
const GET_EDLINK_INTEGRATIONS_SUCCESS = 'GET_EDLINK_INTEGRATIONS_SUCCESS';
const GET_EDLINK_INTEGRATIONS_FAILURE = 'GET_EDLINK_INTEGRATIONS_FAILURE';

const EDLINK_SYNC_GRADES_REQUEST = 'EDLINK_SYNC_GRADES_REQUEST';
const EDLINK_SYNC_GRADES_SUCCESS = 'EDLINK_SYNC_GRADES_SUCCESS';
const EDLINK_SYNC_GRADES_FAILURE = 'EDLINK_SYNC_GRADES_FAILURE';

const EDLINK_AUTO_SYNC_SUCCESS = 'EDLINK_AUTO_SYNC_SUCCESS';
const EDLINK_AUTO_SYNC_FAILURE = 'EDLINK_AUTO_SYNC_FAILURE';

const types = {
  GET_EDLINK_ACCOUNT_REQUEST,
  GET_EDLINK_ACCOUNT_SUCCESS,
  GET_EDLINK_ACCOUNT_FAILURE,
  GET_EDLINK_ACTIVITIES_REQUEST,
  GET_EDLINK_ACTIVITIES_SUCCESS,
  GET_EDLINK_ACTIVITIES_FAILURE,
  GET_EDLINK_COURSES_REQUEST,
  GET_EDLINK_COURSES_SUCCESS,
  GET_EDLINK_COURSES_FAILURE,
  GET_EDLINK_INTEGRATIONS_REQUEST,
  GET_EDLINK_INTEGRATIONS_SUCCESS,
  GET_EDLINK_INTEGRATIONS_FAILURE,
  EDLINK_SYNC_GRADES_FAILURE,
  EDLINK_SYNC_GRADES_REQUEST,
  EDLINK_SYNC_GRADES_SUCCESS,
  EDLINK_AUTO_SYNC_SUCCESS,
  EDLINK_AUTO_SYNC_FAILURE,
};

export default types;
