import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';

import { setAccessibilityMessage } from 'actions/messages';
import { selectAssignment, selectCourse } from 'actions/select';

import ActivityEdit from 'components/ActivityEdit';
import Confirm from 'components/modals/ConfirmModal';

function CreateAssignment(props: any) {
  useEffect(() => {
    props.selectCourse(props.router.params.courseId);
    props.selectAssignment(props.router.params.assignmentId);
  }, []);

  const isEdit = props.router.location.pathname.includes('edit-assignment');
  const isDuplicating = props.router.location.pathname.includes('duplicate-assignment');

  const onCancel = () => {
    props.router.push(`/course/${props.router.params.courseId}/assignments`);
  };

  let header: string;
  if (isEdit) {
    header = 'Edit Activity';
  } else if (isDuplicating) {
    header = 'Duplicate an Activity';
  } else {
    header = 'Create an Activity';
  }

  if (props.router.params.assignmentId != props.activityId) {
    return null;
  }

  return (
    <React.Fragment>
      <ActivityEdit
        title={header}
        isEdit={isEdit}
        isDuplicating={isDuplicating}
        onCancel={onCancel}
        authUser={props.user.authUser}
      />
      <Confirm />
    </React.Fragment>
  );
}

function mapStateToProps(state: any) {
  return {
    entities: state.entities,
    user: state.user,
    activityId: state.selected.assignmentId,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    setAccessibilityMessage,
    selectAssignment,
    selectCourse,
  })(CreateAssignment)
);
