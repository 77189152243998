import React from 'react';
import classnames from 'classnames';

const CardActionArea = (props: any) => {
  const classes = classnames('spotlight-card__body', {
    'spotlight-card__body--clickable': props.isClickable,
  });
  return (
    <div
      onClick={props.onClick}
      className={classes}
      data-testid={`spotlight-action-area-${props.index}`}
    >
      {props.children}
    </div>
  );
};

CardActionArea.defaultProps = {
  onClick: null,
  isClickable: true,
};

export default CardActionArea;
