// Modals
export const OPEN_GLOBAL_MODAL = 'OPEN_GLOBAL_MODAL';
export const CLOSE_GLOBAL_MODAL = 'CLOSE_GLOBAL_MODAL';

export const OPEN_ERROR_MODAL = 'OPEN_ERROR_MODAL';
export const CLOSE_ERROR_MODAL = 'CLOSE_ERROR_MODAL';

export const OPEN_INFO_MODAL = 'OPEN_INFO_MODAL';
export const CLOSE_INFO_MODAL = 'CLOSE_INFO_MODAL';
export const INIT_INFO_MODAL = 'INIT_INFO_MODAL';

export const OPEN_LOADING_MODAL = 'OPEN_LOADING_MODAL';
export const CLOSE_LOADING_MODAL = 'CLOSE_LOADING_MODAL';
export const INIT_LOADING_MODAL = 'INIT_LOADING_MODAL';

export const OPEN_CONFIRM_MODAL = 'OPEN_CONFIRM_MODAL';
export const CLOSE_CONFIRM_MODAL = 'CLOSE_CONFIRM_MODAL';
export const INIT_CONFIRM_MODAL = 'INIT_CONFIRM_MODAL';

export const OPEN_CREATE_CONTENT_MODAL = 'OPEN_CREATE_CONTENT_MODAL';
