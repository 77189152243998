export const countryCodesOptions = [
  { label: 'Empty selection', value: '', key: 0 },
  { label: 'United States (+1)', value: '+1USA', key: 232 },
  { label: 'Canada (+1)', value: '+1', key: 38 },
  { label: 'Afghanistan (+93)', value: '+93', key: 1 },
  { label: 'Albania (+355)', value: '+355', key: 2 },
  { label: 'Algeria (+213)', value: '+213', key: 3 },
  { label: 'American Samoa (+1684)', value: '+1684', key: 4 },
  { label: 'Andorra (+376)', value: '+376', key: 5 },
  { label: 'Angola (+244)', value: '+244', key: 6 },
  { label: 'Anguilla (+1264)', value: '+1264', key: 7 },
  { label: 'Antarctica (+672)', value: '+672', key: 8 },
  { label: 'Antigua and Barbuda (+1268)', value: '+1268', key: 9 },
  { label: 'Argentina (+54)', value: '+54', key: 10 },
  { label: 'Armenia (+374)', value: '+374', key: 11 },
  { label: 'Aruba (+297)', value: '+297', key: 12 },
  { label: 'Australia (+61)', value: '+61', key: 13 },
  { label: 'Austria (+43)', value: '+43', key: 14 },
  { label: 'Azerbaijan (+994)', value: '+994', key: 15 },
  { label: 'Bahamas (+1242)', value: '+1242', key: 16 },
  { label: 'Bahrain (+973)', value: '+973', key: 17 },
  { label: 'Bangladesh (+880)', value: '+880', key: 18 },
  { label: 'Barbados (+1246)', value: '+1246', key: 19 },
  { label: 'Belarus (+375)', value: '+375', key: 20 },
  { label: 'Belgium (+32)', value: '+32', key: 21 },
  { label: 'Belize (+501)', value: '+501', key: 22 },
  { label: 'Benin (+229)', value: '+229', key: 23 },
  { label: 'Bermuda (+1441)', value: '+1441', key: 24 },
  { label: 'Bhutan (+975)', value: '+975', key: 25 },
  { label: 'Bolivia (+591)', value: '+591', key: 26 },
  { label: 'Bosnia and Herzegovina (+387)', value: '+387', key: 27 },
  { label: 'Botswana (+267)', value: '+267', key: 28 },
  { label: 'Brazil (+55)', value: '+55', key: 29 },
  { label: 'British Indian Ocean Territory (+246)', value: '+246', key: 30 },
  { label: 'British Virgin Islands (+1284)', value: '+1284', key: 31 },
  { label: 'Brunei (+673)', value: '+673', key: 32 },
  { label: 'Bulgaria (+359)', value: '+359', key: 33 },
  { label: 'Burkina Faso (+226)', value: '+226', key: 34 },
  { label: 'Burundi (+257)', value: '+257', key: 35 },
  { label: 'Cambodia (+855)', value: '+855', key: 36 },
  { label: 'Cameroon (+237)', value: '+237', key: 37 },
  { label: 'Cape Verde (+238)', value: '+238', key: 39 },
  { label: 'Cayman Islands (+1345)', value: '+1345', key: 40 },
  { label: 'Central African Republic (+236)', value: '+236', key: 41 },
  { label: 'Chad (+235)', value: '+235', key: 42 },
  { label: 'Chile (+56)', value: '+56', key: 43 },
  { label: 'China (+86)', value: '+86', key: 44 },
  { label: 'Christmas Island (+61)', value: '+61', key: 45 },
  { label: 'Cocos Islands (+61)', value: '+61', key: 46 },
  { label: 'Colombia (+57)', value: '+57', key: 47 },
  { label: 'Comoros (+269)', value: '+269', key: 48 },
  { label: 'Cook Islands (+682)', value: '+682', key: 49 },
  { label: 'Costa Rica (+506)', value: '+506', key: 50 },
  { label: 'Croatia (+385)', value: '+385', key: 51 },
  { label: 'Cuba (+53)', value: '+53', key: 52 },
  { label: 'Curacao (+599)', value: '+599', key: 53 },
  { label: 'Cyprus (+357)', value: '+357', key: 54 },
  { label: 'Czech Republic (+420)', value: '+420', key: 55 },
  { label: 'Democratic Republic of the Congo (+243)', value: '+243', key: 56 },
  { label: 'Denmark (+45)', value: '+45', key: 57 },
  { label: 'Djibouti (+253)', value: '+253', key: 58 },
  { label: 'Dominica (+1767)', value: '+1767', key: 59 },
  { label: 'Dominican Republic (+1809)', value: '+1809', key: 60 },
  { label: 'Dominican Republic (+1829)', value: '+1829', key: 61 },
  { label: 'Dominican Republic (+1849)', value: '+1849', key: 62 },
  { label: 'East Timor (+670)', value: '+670', key: 63 },
  { label: 'Ecuador (+593)', value: '+593', key: 64 },
  { label: 'Egypt (+20)', value: '+20', key: 65 },
  { label: 'El Salvador (+503)', value: '+503', key: 66 },
  { label: 'Equatorial Guinea (+240)', value: '+240', key: 67 },
  { label: 'Eritrea (+291)', value: '+291', key: 68 },
  { label: 'Estonia (+372)', value: '+372', key: 69 },
  { label: 'Ethiopia (+251)', value: '+251', key: 70 },
  { label: 'Falkland Islands (+500)', value: '+500', key: 71 },
  { label: 'Faroe Islands (+298)', value: '+298', key: 72 },
  { label: 'Fiji (+679)', value: '+679', key: 73 },
  { label: 'Finland (+358)', value: '+358', key: 74 },
  { label: 'France (+33)', value: '+33', key: 75 },
  { label: 'French Polynesia (+689)', value: '+689', key: 76 },
  { label: 'Gabon (+241)', value: '+241', key: 77 },
  { label: 'Gambia (+220)', value: '+220', key: 78 },
  { label: 'Georgia (+995)', value: '+995', key: 79 },
  { label: 'Germany (+49)', value: '+49', key: 80 },
  { label: 'Ghana (+233)', value: '+233', key: 81 },
  { label: 'Gibraltar (+350)', value: '+350', key: 82 },
  { label: 'Greece (+30)', value: '+30', key: 83 },
  { label: 'Greenland (+299)', value: '+299', key: 84 },
  { label: 'Grenada (+1473)', value: '+1473', key: 85 },
  { label: 'Guam (+1671)', value: '+1671', key: 86 },
  { label: 'Guatemala (+502)', value: '+502', key: 87 },
  { label: 'Guernsey (+441481)', value: '+441481', key: 88 },
  { label: 'Guinea (+224)', value: '+224', key: 89 },
  { label: 'Guinea-Bissau (+245)', value: '+245', key: 90 },
  { label: 'Guyana (+592)', value: '+592', key: 91 },
  { label: 'Haiti (+509)', value: '+509', key: 92 },
  { label: 'Honduras (+504)', value: '+504', key: 93 },
  { label: 'Hong Kong (+852)', value: '+852', key: 94 },
  { label: 'Hungary (+36)', value: '+36', key: 95 },
  { label: 'Iceland (+354)', value: '+354', key: 96 },
  { label: 'India (+91)', value: '+91', key: 97 },
  { label: 'Indonesia (+62)', value: '+62', key: 98 },
  { label: 'Iran (+98)', value: '+98', key: 99 },
  { label: 'Iraq (+964)', value: '+964', key: 100 },
  { label: 'Ireland (+353)', value: '+353', key: 101 },
  { label: 'Isle of Man (+441624)', value: '+441624', key: 102 },
  { label: 'Israel (+972)', value: '+972', key: 103 },
  { label: 'Italy (+39)', value: '+39', key: 104 },
  { label: 'Ivory Coast (+225)', value: '+225', key: 105 },
  { label: 'Jamaica (+1876)', value: '+1876', key: 106 },
  { label: 'Japan (+81)', value: '+81', key: 107 },
  { label: 'Jersey (+441534)', value: '+441534', key: 108 },
  { label: 'Jordan (+962)', value: '+962', key: 109 },
  { label: 'Kazakhstan (+7)', value: '+7', key: 110 },
  { label: 'Kenya (+254)', value: '+254', key: 111 },
  { label: 'Kiribati (+686)', value: '+686', key: 112 },
  { label: 'Kosovo (+383)', value: '+383', key: 113 },
  { label: 'Kuwait (+965)', value: '+965', key: 114 },
  { label: 'Kyrgyzstan (+996)', value: '+996', key: 115 },
  { label: 'Laos (+856)', value: '+856', key: 116 },
  { label: 'Latvia (+371)', value: '+371', key: 117 },
  { label: 'Lebanon (+961)', value: '+961', key: 118 },
  { label: 'Lesotho (+266)', value: '+266', key: 119 },
  { label: 'Liberia (+231)', value: '+231', key: 120 },
  { label: 'Libya (+218)', value: '+218', key: 121 },
  { label: 'Liechtenstein (+423)', value: '+423', key: 122 },
  { label: 'Lithuania (+370)', value: '+370', key: 123 },
  { label: 'Luxembourg (+352)', value: '+352', key: 124 },
  { label: 'Macau (+853)', value: '+853', key: 125 },
  { label: 'Macedonia (+389)', value: '+389', key: 126 },
  { label: 'Madagascar (+261)', value: '+261', key: 127 },
  { label: 'Malawi (+265)', value: '+265', key: 128 },
  { label: 'Malaysia (+60)', value: '+60', key: 129 },
  { label: 'Maldives (+960)', value: '+960', key: 130 },
  { label: 'Mali (+223)', value: '+223', key: 131 },
  { label: 'Malta (+356)', value: '+356', key: 132 },
  { label: 'Marshall Islands (+692)', value: '+692', key: 133 },
  { label: 'Mauritania (+222)', value: '+222', key: 134 },
  { label: 'Mauritius (+230)', value: '+230', key: 135 },
  { label: 'Mayotte (+262)', value: '+262', key: 136 },
  { label: 'Mexico (+52)', value: '+52', key: 137 },
  { label: 'Micronesia (+691)', value: '+691', key: 138 },
  { label: 'Moldova (+373)', value: '+373', key: 139 },
  { label: 'Monaco (+377)', value: '+377', key: 140 },
  { label: 'Mongolia (+976)', value: '+976', key: 141 },
  { label: 'Montenegro (+382)', value: '+382', key: 142 },
  { label: 'Montserrat (+1664)', value: '+1664', key: 143 },
  { label: 'Morocco (+212)', value: '+212', key: 144 },
  { label: 'Mozambique (+258)', value: '+258', key: 145 },
  { label: 'Myanmar (+95)', value: '+95', key: 146 },
  { label: 'Namibia (+264)', value: '+264', key: 147 },
  { label: 'Nauru (+674)', value: '+674', key: 148 },
  { label: 'Nepal (+977)', value: '+977', key: 149 },
  { label: 'Netherlands (+31)', value: '+31', key: 150 },
  { label: 'Netherlands Antilles (+599)', value: '+599', key: 151 },
  { label: 'New Caledonia (+687)', value: '+687', key: 152 },
  { label: 'New Zealand (+64)', value: '+64', key: 153 },
  { label: 'Nicaragua (+505)', value: '+505', key: 154 },
  { label: 'Niger (+227)', value: '+227', key: 155 },
  { label: 'Nigeria (+234)', value: '+234', key: 156 },
  { label: 'Niue (+683)', value: '+683', key: 157 },
  { label: 'North Korea (+850)', value: '+850', key: 158 },
  { label: 'Northern Mariana Islands (+1670)', value: '+1670', key: 159 },
  { label: 'Norway (+47)', value: '+47', key: 160 },
  { label: 'Oman (+968)', value: '+968', key: 161 },
  { label: 'Pakistan (+92)', value: '+92', key: 162 },
  { label: 'Palau (+680)', value: '+680', key: 163 },
  { label: 'Palestine (+970)', value: '+970', key: 164 },
  { label: 'Panama (+507)', value: '+507', key: 165 },
  { label: 'Papua New Guinea (+675)', value: '+675', key: 166 },
  { label: 'Paraguay (+595)', value: '+595', key: 167 },
  { label: 'Peru (+51)', value: '+51', key: 168 },
  { label: 'Philippines (+63)', value: '+63', key: 169 },
  { label: 'Pitcairn (+64)', value: '+64', key: 170 },
  { label: 'Poland (+48)', value: '+48', key: 171 },
  { label: 'Portugal (+351)', value: '+351', key: 172 },
  { label: 'Puerto Rico (+1787)', value: '+1787', key: 173 },
  { label: 'Puerto Rico (+1939)', value: '+1939', key: 174 },
  { label: 'Qatar (+974)', value: '+974', key: 175 },
  { label: 'Republic of the Congo (+242)', value: '+242', key: 176 },
  { label: 'Reunion (+262)', value: '+262', key: 177 },
  { label: 'Romania (+40)', value: '+40', key: 178 },
  { label: 'Russia (+7)', value: '+7', key: 179 },
  { label: 'Rwanda (+250)', value: '+250', key: 180 },
  { label: 'Saint Barthelemy (+590)', value: '+590', key: 181 },
  { label: 'Saint Helena (+290)', value: '+290', key: 182 },
  { label: 'Saint Kitts and Nevis (+1869)', value: '+1869', key: 183 },
  { label: 'Saint Lucia (+1758)', value: '+1758', key: 184 },
  { label: 'Saint Martin (+590)', value: '+590', key: 185 },
  { label: 'Saint Pierre and Miquelon (+508)', value: '+508', key: 186 },
  { label: 'Saint Vincent and the Grenadines (+1784)', value: '+1784', key: 187 },
  { label: 'Samoa (+685)', value: '+685', key: 188 },
  { label: 'San Marino (+378)', value: '+378', key: 189 },
  { label: 'Sao Tome and Principe (+239)', value: '+239', key: 190 },
  { label: 'Saudi Arabia (+966)', value: '+966', key: 191 },
  { label: 'Senegal (+221)', value: '+221', key: 192 },
  { label: 'Serbia (+381)', value: '+381', key: 193 },
  { label: 'Seychelles (+248)', value: '+248', key: 194 },
  { label: 'Sierra Leone (+232)', value: '+232', key: 195 },
  { label: 'Singapore (+65)', value: '+65', key: 196 },
  { label: 'Sint Maarten (+1721)', value: '+1721', key: 197 },
  { label: 'Slovakia (+421)', value: '+421', key: 198 },
  { label: 'Slovenia (+386)', value: '+386', key: 199 },
  { label: 'Solomon Islands (+677)', value: '+677', key: 200 },
  { label: 'Somalia (+252)', value: '+252', key: 201 },
  { label: 'South Africa (+27)', value: '+27', key: 202 },
  { label: 'South Korea (+82)', value: '+82', key: 203 },
  { label: 'South Sudan (+211)', value: '+211', key: 204 },
  { label: 'Spain (+34)', value: '+34', key: 205 },
  { label: 'Sri Lanka (+94)', value: '+94', key: 206 },
  { label: 'Sudan (+249)', value: '+249', key: 207 },
  { label: 'Suriname (+597)', value: '+597', key: 208 },
  { label: 'Svalbard and Jan Mayen (+47)', value: '+47', key: 209 },
  { label: 'Swaziland (+268)', value: '+268', key: 210 },
  { label: 'Sweden (+46)', value: '+46', key: 211 },
  { label: 'Switzerland (+41)', value: '+41', key: 212 },
  { label: 'Syria (+963)', value: '+963', key: 213 },
  { label: 'Taiwan (+886)', value: '+886', key: 214 },
  { label: 'Tajikistan (+992)', value: '+992', key: 215 },
  { label: 'Tanzania (+255)', value: '+255', key: 216 },
  { label: 'Thailand (+66)', value: '+66', key: 217 },
  { label: 'Togo (+228)', value: '+228', key: 218 },
  { label: 'Tokelau (+690)', value: '+690', key: 219 },
  { label: 'Tonga (+676)', value: '+676', key: 220 },
  { label: 'Trinidad and Tobago (+1868)', value: '+1868', key: 221 },
  { label: 'Tunisia (+216)', value: '+216', key: 222 },
  { label: 'Turkey (+90)', value: '+90', key: 223 },
  { label: 'Turkmenistan (+993)', value: '+993', key: 224 },
  { label: 'Turks and Caicos Islands (+1649)', value: '+1649', key: 225 },
  { label: 'Tuvalu (+688)', value: '+688', key: 226 },
  { label: 'U.S. Virgin Islands (+1340)', value: '+1340', key: 227 },
  { label: 'Uganda (+256)', value: '+256', key: 228 },
  { label: 'Ukraine (+380)', value: '+380', key: 229 },
  { label: 'United Arab Emirates (+971)', value: '+971', key: 230 },
  { label: 'United Kingdom (+44)', value: '+44', key: 231 },
  { label: 'Uruguay (+598)', value: '+598', key: 233 },
  { label: 'Uzbekistan (+998)', value: '+998', key: 234 },
  { label: 'Vanuatu (+678)', value: '+678', key: 235 },
  { label: 'Vatican (+379)', value: '+379', key: 236 },
  { label: 'Venezuela (+58)', value: '+58', key: 237 },
  { label: 'Vietnam (+84)', value: '+84', key: 238 },
  { label: 'Wallis and Futuna (+681)', value: '+681', key: 239 },
  { label: 'Western Sahara (+212)', value: '+212', key: 240 },
  { label: 'Yemen (+967)', value: '+967', key: 241 },
  { label: 'Zambia (+260)', value: '+260', key: 242 },
  { label: 'Zimbabwe (+263)', value: '+263', key: 243 },
];

export default {
  countryCodesOptions,
};
