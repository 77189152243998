import types from './types';

const defaultSorting = {
  isSorted: false,
  isAscending: false,
};

export const setColumns = (columns: any) => {
  let _columns = [...columns];
  _columns = _columns.map((col) => {
    if (col.isSortable) {
      if (col.sorting) {
        col.sorting = { ...col.sorting };
      } else {
        col.sorting = { ...defaultSorting };
      }
    }
    return col;
  });
  return (dispatch: any) => {
    dispatch({ type: types.SET_CREATION_TABLE_COLUMNS, payload: _columns });
  };
};

export const setInitialState = ({ columns, ...rest }: any) => {
  return (dispatch: any) => {
    dispatch({ type: types.SET_INITIAL_STATE, payload: rest });
    if (columns) {
      dispatch(setColumns(columns));
    }
  };
};

export const setSortedColumn = (columns: any, indexToSort: any) => {
  let _columns = [...columns];
  _columns = _columns.map((col, index) => {
    if (col.isSortable) {
      if (index === indexToSort) {
        col.sorting.isSorted = true;
        col.sorting.isAscending = !col.sorting.isAscending;
      } else {
        col.sorting = { ...defaultSorting };
      }
    }
    return col;
  });
  return (dispatch: any) => {
    dispatch({ type: types.SET_CREATION_TABLE_COLUMNS, payload: _columns });
  };
};

export const setTableType = (type: any) => {
  return (dispatch: any) => {
    dispatch({ type: types.SET_CREATION_TABLE_TYPE, payload: type });
  };
};

export const setTableListBy = (listBy: any) => {
  return (dispatch: any) => {
    dispatch({ type: types.SET_CREATION_TABLE_LIST_BY, payload: listBy });
  };
};

export const setCreationTableFilter = (filter: any) => {
  return (dispatch: any) => {
    dispatch({ type: types.SET_CREATION_TABLE_FILTER, payload: { filter } });
  };
};

export const setCreationTableSearch = (search: any) => {
  return (dispatch: any) => {
    dispatch({ type: types.SET_CREATION_TABLE_SEARCH, payload: search });
  };
};

export const setIsShowEvalsCompleted = (isShowEvalsCompleted: any) => {
  return (dispatch: any) => {
    dispatch({ type: types.SET_IS_SHOW_EVALS_COMPLETED, payload: isShowEvalsCompleted });
  };
};

export const setIsShowFeedbackCompleted = () => {
  return (dispatch: any) => {
    dispatch({ type: types.SET_IS_SHOW_FEEDBACK_COMPLETED });
  };
};

export const setCreationTableActivity = (activityId: any) => {
  return (dispatch: any) => {
    dispatch({ type: types.SET_CREATION_TABLE_ACTIVITY, payload: activityId });
  };
};

export const setCurrentPage = (pageNumber: any) => {
  return (dispatch: any) => {
    dispatch({ type: types.SET_CURRENT_PAGE, payload: pageNumber });
  };
};

export const setPageSize = (pageSize: any) => {
  return (dispatch: any) => {
    dispatch({ type: types.SET_PAGE_SIZE, payload: pageSize });
  };
};

export const setTotalRowsCount = (totalRowsCount: any) => {
  return (dispatch: any) => {
    dispatch({ type: types.SET_TOTAL_ROWS_COUNT, payload: totalRowsCount });
  };
};

export const setPageCount = (pageCount: any) => {
  return (dispatch: any) => {
    dispatch({ type: types.SET_PAGE_COUNT, payload: pageCount });
  };
};

export const setCreationTableIsLoadingData = (isLoading: any) => {
  return (dispatch: any) => {
    dispatch({ type: types.SET_CREATION_TABLE_LOADING, payload: isLoading });
  };
};
