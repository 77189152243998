import { ChangeEvent } from 'react';
import classNames from 'classnames';
import { sanitizeInputValue } from 'utils/input';
import { localize } from 'locales/index';

const PercentageInput = (props: any) => {
  function sanitizeInputChange(e: ChangeEvent): ChangeEvent {
    const eventTarget = e.target as HTMLInputElement;
    const value: string = eventTarget.value || '0';
    eventTarget.value = sanitizeInputValue({
      value,
      max: props.max,
      min: props.min,
      maxDecimals: props.maxDecimals,
    });
    return e;
  }

  const wrapperClasses = classNames('input-percent', {
    'input-percent--disabled': props.disabled === true,
  });

  return (
    <div className={wrapperClasses}>
      <label htmlFor={props.name} className="visually-hidden">
        {props.label}
      </label>
      <input
        id={props.name}
        className="input-percent__number"
        type="number"
        min={props.min}
        max={props.max}
        step={props.step}
        value={sanitizeInputValue({
          value: props.value.toString(),
          max: props.max,
          min: props.min,
          maxDecimals: props.maxDecimals,
        })}
        name={props.name}
        onChange={(e) => {
          return props.onChange(sanitizeInputChange(e));
        }}
        disabled={props.disabled}
        data-testid={props.testid}
      />
    </div>
  );
};

PercentageInput.defaultProps = {
  disabled: false,
  max: 100,
  min: 0,
  maxDecimals: 2,
  step: 'any',
};

export default PercentageInput;
