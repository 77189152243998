import { Button } from 'components/buttons';
import classNames from 'classnames';
import { ReactNode } from 'react';

type Props = {
  onActionButtonClick: () => void;
  actionButtonLabel: string;
  icon: ReactNode;
  className?: string;
  testid?: string;
  buttonTestId?: string;
};

function TopBarWithButton({
  onActionButtonClick,
  actionButtonLabel,
  icon,
  className,
  testid,
  buttonTestId,
}: Props) {
  const classes = classNames('top-view-bar__container', className);
  return (
    <div className={classes} data-testid={testid}>
      {icon}
      <div className="top-view-bar__btn">
        <Button onClick={onActionButtonClick} type="secondary" data-testid={buttonTestId}>
          {actionButtonLabel}
        </Button>
      </div>
    </div>
  );
}

export default TopBarWithButton;
