const FORBIDDEN_EMAILS = [
  'gmail.com',
  'hotmail.com',
  'outlook.com',
  'live.com',
  'yahoo.com',
  'yahoo.ca',
  'aol.com',
  'icloud.com',
];

type isForbiddenEmailForRegisterArgs = {
  email: string;
};

export function isForbiddenEmailForRegister({ email }: isForbiddenEmailForRegisterArgs): boolean {
  const domain = email.split('@')[1];
  return FORBIDDEN_EMAILS.includes(domain);
}
