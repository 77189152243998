import {
  isEvaluateOrLater,
  isPastEvaluationDeadline,
  isInCreationGracePeriod,
} from '@kritik/utils/stage';
import * as ActivityUtil from '@kritik/utils/activity';
import { userSubmissionStatuses } from '@kritik/constants/creation';
import { CreationScoreUtil } from '@kritik/utils/grade';
import * as TeacherCommentUtil from '@kritik/utils/creation/instructorComment';
import { Activity, Creation, CreationScore } from '@kritik/types.generated';
import * as LateSubmissionUtil from '@kritik/utils/creation/lateCreation';

export const EVAL_FLAGS = {
  NO_FLAG: 'No flag',
  FLAGGED: 'Flagged',
  RESOLVED: 'Resolved',
  DISMISSED: 'Dismissed',
};

export function isCreationEmpty(creation: Creation) {
  return creation.submissionStatus === userSubmissionStatuses.EMPTY;
}

export function isCreationInProgress(creation: Creation) {
  return creation.submissionStatus === userSubmissionStatuses.IN_PROGRESS;
}

export function isCompleted(creation: Creation) {
  if (!creation) {
    return false;
  }

  return (
    wasCreationSubmittedInGracePeriod(creation) ||
    wasCreationOnTime(creation) ||
    isCreationInProgress(creation) ||
    wasCreationSubmittedLate(creation)
  );
}

type ActivityWithCreation = Activity & {
  userAssignment: Creation;
};
export function isCreationOverdue(assignment: ActivityWithCreation, creation: Creation) {
  const _creation = creation || assignment.userAssignment;
  if (!_creation) {
    return false;
  }
  if (isCompleted(_creation)) {
    return false;
  }
  if (isInCreationGracePeriod(assignment)) {
    return true;
  }
  if (ActivityUtil.acceptsLateSubmissions(assignment)) {
    if (isCreationEmpty(_creation) && isEvaluateOrLater(assignment)) {
      return true;
    }
  }

  return false;
}

// complete but no score (evaluators have not evaluated student)
export function isCreationNotGraded(creation: Creation) {
  return (
    isCompleted(creation) &&
    !CreationScoreUtil.hasCreationScore(creation) &&
    !LateSubmissionUtil.isLateSubmissionRejected(creation)
  );
}

export function isCreationNotGradedAfterEvaluation(creation: Creation, activity: Activity) {
  return isCreationNotGraded(creation) && isPastEvaluationDeadline(activity);
}

export function wasCreationSubmittedLate(creation: Creation) {
  return creation.submissionStatus === userSubmissionStatuses.LATE;
}

export function wasCreationSubmittedInGracePeriod(creation: Creation) {
  return creation.submissionStatus === userSubmissionStatuses.GRACE;
}

export function wasCreationMissed(creation: Creation) {
  if (!creation) {
    return false;
  }
  return creation.submissionStatus === userSubmissionStatuses.MISSED;
}

export function wasCreationOnTime(creation: Creation) {
  return creation.submissionStatus === userSubmissionStatuses.ON_TIME;
}

export function isDispute(creation: Creation) {
  return creation.dispute.status == 'Dispute';
}
export function isDisputeResolved(creation: Creation) {
  return creation.dispute.status == 'Resolved';
}
export function hasBeenDisputed(creation: Creation) {
  return creation.dispute.status != 'No dispute';
}

export function isCommentNotFlagged(scoreReceived: CreationScore) {
  return scoreReceived.flag == EVAL_FLAGS.NO_FLAG;
}
export function isCommentFlagged(scoreReceived: CreationScore) {
  return scoreReceived.flag == EVAL_FLAGS.FLAGGED;
}
export function isCommentRemoved(scoreReceived: CreationScore) {
  return scoreReceived.flag == EVAL_FLAGS.RESOLVED;
}
export function isCommentFlagDismissed(scoreReceived: CreationScore) {
  return scoreReceived.flag == EVAL_FLAGS.DISMISSED;
}

export function isCommentFlagResolved(scoreReceived: CreationScore) {
  return isCommentRemoved(scoreReceived) || isCommentFlagDismissed(scoreReceived);
}

export function getNumOfCreationFlags(creation: Creation) {
  if (!creation) {
    return null;
  }
  const numFlags = creation.scores.reduce((acc, score) => {
    if (isCommentFlagged(score)) {
      acc += 1;
    }
    return acc;
  }, 0);
  return numFlags;
}

export function creationHasProfComment(creation: Creation) {
  return TeacherCommentUtil.resourceHasTeacherComment(creation);
}

export function isStudentSeenComment({
  creation,
  studentId,
}: {
  creation: Creation;
  studentId: string;
}) {
  return (creation.teacherComment.viewed as unknown as string[]).includes(studentId);
}

export const getSubmissionLastEditedDate = (creation: Creation) => {
  const successfulAttempts = creation.submissionAttempts.filter((attempt) => {
    return attempt.success;
  });
  const lastSuccessfulAttempt = successfulAttempts[successfulAttempts.length - 1];
  if (!lastSuccessfulAttempt) {
    return null;
  }
  return lastSuccessfulAttempt.resolveDate;
};
