import React from 'react';
import classNames from 'classnames';
import FieldHelpText from 'components/core/form/FieldHelpText';

type Props = {
  autoComplete?: 'off';
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  helpText?: string;
  icon?: any;
  id?: string;
  inputProps?: any;
  label?: string;
  name?: string;
  onBlur?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEnterKey?: () => void;
  placeholder?: string;
  testid?: string;
  useDefaultStyles?: boolean;
  value?: string;
  required?: boolean;
};
const TextInput = React.forwardRef((props: Props, ref: React.Ref<HTMLInputElement>) => {
  const { useDefaultStyles = true } = props;
  const inputClass = props.inputProps ? props.inputProps.className : '';

  const classes = classNames({ 'kritik-form-field__text-input': useDefaultStyles }, inputClass);
  const wrapperClasses = classNames('kritik-form-field__text-input-wrapper', props.className);

  const renderIcon = () => {
    if (!props.icon) {
      return null;
    }
    return <span className="kritik-form-field__text-input-icon">{props.icon}</span>;
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      props.onEnterKey?.();
    }
  };

  return (
    <>
      <div className={wrapperClasses}>
        <input
          {...props.inputProps}
          ref={ref}
          placeholder={props.placeholder}
          type={props.inputProps?.type || 'text'}
          autoFocus={props.autoFocus}
          aria-label={props['aria-label']}
          value={props.value}
          name={props.name}
          onChange={props.onChange}
          onKeyDown={handleKeyDown}
          onBlur={props.onBlur}
          className={classes}
          disabled={props.disabled}
          data-testid={props.testid}
          id={props.id}
          autoComplete={props.autoComplete}
          required={props.required}
        />
        {renderIcon()}
      </div>
      <FieldHelpText helpText={props.helpText} />
    </>
  );
});

TextInput.defaultProps = {
  icon: null,
};

export default TextInput;
