import React from 'react';
import createSvgIcon from '../util/generateIconSVG';

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M1 2.9375C1 3.26562 1.23438 3.5 1.5625 3.5H10V4.625C10 5.14062 10.5859 5.375 10.9375 5.02344L12.8125 3.14844C13.0469 2.9375 13.0469 2.58594 12.8125 2.375L10.9375 0.5C10.5859 0.125 10 0.382812 10 0.875V2H1.5625C1.23438 2 1 2.25781 1 2.5625V2.9375ZM12.4375 6.5H4V5.375C4 4.88281 3.39062 4.625 3.03906 5L1.16406 6.875C0.929688 7.08594 0.929688 7.4375 1.16406 7.64844L3.03906 9.52344C3.39062 9.875 4 9.64062 4 9.125V8H12.4375C12.7422 8 13 7.76562 13 7.4375V7.0625C13 6.75781 12.7422 6.5 12.4375 6.5Z"
      fill="#4844A3"
    />
  </React.Fragment>,
  '0 0 13 10'
);
