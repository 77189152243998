import InlineInformation from 'components/layout/InlineInformation';
import React from 'react';

const UpdateEmailNotice = ({ isInstructor }: any) => {
  const title = isInstructor ? 'Single Sign-On' : 'Email is locked';
  const text = isInstructor
    ? 'Your Kritik email must match the email in your school LMS system'
    : 'If you still want to change your email for any reason, please contact support';
  return (
    <InlineInformation title={title} className="account-settings__sso">
      {text}
    </InlineInformation>
  );
};

export default UpdateEmailNotice;
