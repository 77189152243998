import { useState } from 'react';
import { EvaluationView } from 'components/Creation/Evaluate';
import TabMenu, { statuses } from 'components/layout/TabMenu';
import * as ActivityUtils from '@kritik/utils/activity';
import StudentContent from 'components/layout/StudentContent';
import * as StudentUtils from 'utils/student';
import { Creation, Activity } from 'old-common/types.generated';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales/index';

type EvaluationsReceivedProps = {
  creation: Creation;
  activity: Activity;
};

export const EvaluationsReceived = ({ creation, activity }: EvaluationsReceivedProps) => {
  const [selectedEvaluationIndex, setSelectedEvaluationIndex] = useState(0);
  if (!ActivityUtils.isPresentationActivity(activity)) {
    return null;
  }

  if (!creation.scores || creation.scores.length === 0) {
    return null;
  }

  const labels = creation.scores.map((score, i: number) => {
    return {
      id: score._id,
      title: `${localize({ message: 'Activity.Evaluation' })} ${i + 1}`,
      status: statuses.SUCCESS,
    };
  });

  const evaluation = creation.scores[selectedEvaluationIndex];

  return (
    <div data-testid="evaluations-received">
      <h3 className="header-3" style={{ marginBottom: '10px' }}>
        <TranslatedText i18nKey="Activity.Creation.EvaluationsReceived" />
      </h3>
      <TabMenu
        labels={labels}
        onSelect={(labelId: string, index: number) => {
          return setSelectedEvaluationIndex(index);
        }}
        activeLabel={selectedEvaluationIndex}
      />
      <StudentContent user={evaluation.user} score={StudentUtils.getNewKS(evaluation.student)}>
        <EvaluationView
          assignment={activity}
          submission={creation}
          userScore={evaluation}
          showScoreLabel
        />
      </StudentContent>
    </div>
  );
};
