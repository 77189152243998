import React from 'react';
import InlineInformation from 'components/layout/InlineInformation';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/buttons/ButtonContainer';

export const RosterSyncError = ({ cancel, error }: any) => {
  return (
    <>
      <InlineInformation type="danger">
        {error || 'Something went wrong fetching data from your LMS. Please try again later.'}
      </InlineInformation>
      <ButtonContainer>
        <Button type="secondary" onClick={cancel}>
          Cancel
        </Button>
      </ButtonContainer>
    </>
  );
};

export default RosterSyncError;
