import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import { push } from 'router';
import Button from 'components/buttons/Button';
import { SignupContainer, SignupMainTitle, SignupContent } from 'components/AuthWrapper';
import { Form, FormButtons } from 'components/Form';
import FormPassword from 'components/core/form/PasswordField';
import { validateResetToken, resetPassword } from 'actions/users';
import { RESET_PASSWORD } from 'types';

const mapStateToProps = (state: any) => {
  return {
    resetBusy: state.async[RESET_PASSWORD].busy,
    resetSuccess: state.async[RESET_PASSWORD].success,
    resetError: state.async[RESET_PASSWORD].error,
  };
};

type ResetPasswordState = any;

class ResetPassword extends Component<{}, ResetPasswordState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      isFinishedLoading: false,
      isValidToken: false,
    };
  }

  componentDidMount() {
    (this.props as any)
      .validateResetToken({ token: (this.props as any).router.params.token })
      .then((isValid: any) => {
        this.setState({ isValidToken: isValid, isFinishedLoading: true });
      });
  }

  componentDidUpdate(prevProps: {}, prevState: ResetPasswordState) {
    if (!(prevProps as any).resetSuccess && (this.props as any).resetSuccess) {
      (this.props as any).router.push('/login');
    }
  }

  onSubmit() {
    if (this.state.isValidToken) {
      (this.props as any).resetPassword({
        token: (this.props as any).router.params.token,
        password: this.state.password,
        confirmPassword: this.state.confirmPassword,
      });
    }
  }

  handleInputChange = (e: any) => {
    const { target } = e;
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  getError = (field: any) => {
    if (!(this.props as any).resetError) {
      return null;
    }
    return (this.props as any).resetError[field];
  };

  renderReset() {
    return (
      <SignupContent>
        <SignupMainTitle>
          Almost there.
          <br />
          Create your new password
        </SignupMainTitle>
        <Form>
          <FormPassword
            testid="new-password"
            name="password"
            label="New Password"
            value={this.state.password}
            onChange={this.handleInputChange}
            error={this.getError('password')}
          />
          <FormPassword
            testid="confirm-password"
            name="confirmPassword"
            label="Confirm New Password"
            value={this.state.confirmPassword}
            onChange={this.handleInputChange}
            error={this.getError('confirmPassword')}
          />
          <FormButtons>
            <Button
              testid="update-password-button"
              type="primary"
              onClick={() => {
                return this.onSubmit();
              }}
              loading={(this.props as any).actionBusy}
              success={(this.props as any).actionSuccess}
              disabled={(this.props as any).actionBusy}
              inputType="submit"
            >
              Update Password
            </Button>
          </FormButtons>
        </Form>
      </SignupContent>
    );
  }

  renderContent() {
    if (!this.state.isFinishedLoading) {
      return;
    }
    if (!this.state.isValidToken) {
      return (
        <SignupContent>
          <SignupMainTitle>This is an invalid token</SignupMainTitle>
          <SignupContent>
            {' '}
            It may have already been used, or a new password reset request has been made.
          </SignupContent>
        </SignupContent>
      );
    }
    return this.renderReset();
  }

  render() {
    return <SignupContainer>{this.renderContent()}</SignupContainer>;
  }
}

export default withRouter(
  connect(mapStateToProps, {
    validateResetToken,
    resetPassword,
    push,
  })(ResetPassword)
);
