import { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Institution } from '@kritik/types.generated';
import { getInstitutions } from 'actions/admin';
import { FormSelection } from 'components/Form/FormComponents';
import { adminService } from 'services';

type UseInstitutionsParams = {
  getInstitutions: () => void;
  institutions: Institution[];
  filterString: string;
  isLMSIntegratedOptions: boolean;
};

const useInstitutions = ({
  getInstitutions,
  institutions,
  filterString,
  isLMSIntegratedOptions,
}: UseInstitutionsParams) => {
  const [integratedInstitution, setIntegratedInstitution] = useState(null);

  useEffect(() => {
    async function useIntegratedInstitution() {
      const integratedInstitutions = await adminService().fetchIntegratedInstitutionList();
      setIntegratedInstitution(integratedInstitutions.data);
    }
    getInstitutions();
    if (isLMSIntegratedOptions) {
      useIntegratedInstitution().catch(() => {});
    }
  }, []);

  const options = useMemo(() => {
    let integratedInstitutionInfo = null;
    if (institutions && integratedInstitution) {
      integratedInstitutionInfo = {};
      integratedInstitution.forEach((institutionId) => {
        integratedInstitutionInfo[institutionId] = institutions[institutionId];
      });
    }
    return Object.keys(integratedInstitutionInfo || institutions || {}).reduce(
      (options: any[], id) => {
        const institution = institutions[id];
        options.push({ label: (institution as any).name, value: (institution as any)._id });
        return options;
      },
      []
    );
  }, [institutions]);

  const filteredOptions = useMemo(() => {
    if (!filterString) {
      return options.slice(0, 100);
    }
    return options.filter((option) => {
      return option.label.toLowerCase().includes((filterString as any).toLowerCase());
    });
  }, [filterString, institutions]);

  if (!institutions) {
    return null;
  }
  return filteredOptions;
};

type SelectedInstitution = {
  value: string;
  label: string;
};

type Props = {
  getInstitutions: () => void;
  institutions: Institution[];
  setErrorMessage: (error: string) => void;
  setSelectedInstitution: (selectedInstitution: SelectedInstitution) => void;
  isLMSIntegratedOptions: boolean;
};

const InstitutionSelect = ({
  getInstitutions,
  institutions,
  setSelectedInstitution,
  setErrorMessage,
  isLMSIntegratedOptions,
}: Props) => {
  const [filterString, setFilterString] = useState('');

  const filteredOptions = useInstitutions({
    getInstitutions,
    institutions,
    filterString,
    isLMSIntegratedOptions,
  });
  const handleTypedInputChange = (e: any) => {
    setFilterString(e);
  };

  const handleInstitutionSelectionChange = (e: any) => {
    setErrorMessage(null);
    setSelectedInstitution(e);
  };

  return (
    <FormSelection
      label="Select an Institution"
      options={filteredOptions}
      onInputChange={handleTypedInputChange}
      onChange={handleInstitutionSelectionChange}
    />
  );
};

InstitutionSelect.defaultProps = {
  isLMSIntegratedOptions: false,
};

const mapStateToProps = (state: any) => {
  return {
    institutions: state.entities.institutions,
  };
};

export default connect(mapStateToProps, { getInstitutions })(InstitutionSelect);
