import React, { useEffect, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import NavigationLink from 'components/Navigation/Link';
import { useLocation } from 'react-router';

function MenuTab(props: any) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const location = useLocation();

  const handleMenuClick = (e: any) => {
    setAnchorEl(e.currentTarget);
    setIsExpanded(true);
  };

  const handleMenuClose = () => {
    setIsExpanded(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    setAnchorEl(null);
  }, [location]);

  return (
    <div className="MenuTab">
      <NavigationLink
        onClick={handleMenuClick}
        selectable
        {...props}
        aria-haspopup="menu"
        isExpanded={isExpanded}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={
          props.position === 'top'
            ? {
                vertical: 'bottom',
                horizontal: 'right',
              }
            : {
                vertical: 'center',
                horizontal: 'right',
              }
        }
        transformOrigin={
          props.position === 'top'
            ? {
                vertical: 'top',
                horizontal: 'right',
              }
            : {
                vertical: 'center',
                horizontal: 'left',
              }
        }
      >
        {props.content}
      </Menu>
    </div>
  );
}

export default MenuTab;
