import PageContainer from 'components/layout/PageContainer';
import Header from 'containers/PresentationSchedule/Header';
import Create from 'containers/PresentationSchedule/Create';
import Evaluate from 'containers/PresentationSchedule/Evaluate';
import Feedback from 'containers/PresentationSchedule/Feedback';
import Grading from 'containers/PresentationSchedule/Grading';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/buttons/ButtonContainer';
import { useEffect, useState } from 'react';
import {
  getCreationStage,
  getEvaluationStage,
  getEvaluationGracePeriodStage,
  getFeedbackStage,
  isCreateOrLater,
  isGradingOrLater,
  isFeedbackOrLater,
  isEvaluateOrLater,
  isFinalized,
  isInEvaluationGracePeriod,
} from '@kritik/utils/stage';
import { withRouter } from 'utils/withRouter';
import { localize } from 'locales';
import * as ErrorUtils from 'utils/error';
import { InlineInformation } from 'components/layout';
import { updateAssignmentSchedule } from 'actions/activity';
import { connect } from 'react-redux';
import moment from 'moment';

type Props = {
  activity: any;
  course: any;
  router: any;
  updateAssignmentSchedule: (data: any) => Promise<any>;
};

// 3 days initial interval between stages
const DAYS = 3;
const HOURS = 24;
const MINUTES = 60;
const SECONDS = 60;
const MILLISECONDS = 1000;
const SCHEDULE_INTERVAL = DAYS * HOURS * MINUTES * SECONDS * MILLISECONDS;
const GRACE_PERIOD_DURATION_MINUTES = 120;
const GRACE_PERIOD_DURATION_MILLISECONDS = GRACE_PERIOD_DURATION_MINUTES * 60 * 1000;

function getScheduleInterval(interval: number) {
  const now = new Date();
  now.setHours(9);
  now.setMinutes(0);
  now.setSeconds(0);
  return now.getTime() + interval;
}

function getCreateStartDate(activity: any) {
  const creationStage = getCreationStage(activity);
  if (creationStage.startDate) {
    const startDate = new Date(creationStage.startDate);
    return startDate.getTime();
  }
  return getScheduleInterval(SCHEDULE_INTERVAL);
}

function getCreateEndDate(activity: any) {
  const creationStage = getCreationStage(activity);
  if (creationStage.endDate) {
    const endDate = new Date(creationStage.endDate);
    return endDate.getTime();
  }
  return getScheduleInterval(2 * SCHEDULE_INTERVAL);
}

function getEvaluateStartDate(activity: any) {
  const evaluationStage = getEvaluationStage(activity);
  if (evaluationStage.startDate) {
    const startDate = new Date(evaluationStage.startDate);
    return startDate.getTime();
  }
  return getScheduleInterval(2 * SCHEDULE_INTERVAL);
}

function getEvaluateEndDate(activity: any) {
  const evaluationStage = getEvaluationStage(activity);
  if (evaluationStage.endDate) {
    const endDate = new Date(evaluationStage.endDate);
    return endDate.getTime();
  }
  return getScheduleInterval(3 * SCHEDULE_INTERVAL);
}

function getEvaluateGracePeriodEndDate(activity: any) {
  const evaluationGracePeriodStage = getEvaluationGracePeriodStage(activity);
  if (evaluationGracePeriodStage.endDate) {
    const endDate = new Date(evaluationGracePeriodStage.endDate);
    return endDate.getTime();
  }
  return getEvaluateEndDate(activity);
}

function getFeedbackStartDate(activity: any) {
  const feedbackStage = getFeedbackStage(activity);
  const evaluationGracePeriodStage = getEvaluationGracePeriodStage(activity);
  if (evaluationGracePeriodStage.endDate && feedbackStage.startDate) {
    const startDate = new Date(evaluationGracePeriodStage.endDate);
    return startDate.getTime();
  }
  if (!evaluationGracePeriodStage.endDate && feedbackStage.startDate) {
    const startDate = new Date(feedbackStage.startDate);
    return startDate.getTime();
  }
  return getScheduleInterval(3 * SCHEDULE_INTERVAL + GRACE_PERIOD_DURATION_MILLISECONDS);
}

function getFeedbackEndDate(activity: any) {
  const feedbackStage = getFeedbackStage(activity);
  if (feedbackStage.endDate) {
    const endDate = new Date(feedbackStage.endDate);
    return endDate.getTime();
  }
  return getScheduleInterval(4 * SCHEDULE_INTERVAL);
}

const getEvaluationGracePeriodIntervalInMinutes = (activity: any) => {
  const evaluationGracePeriodStage = getEvaluationGracePeriodStage(activity);
  const startDate = new Date(evaluationGracePeriodStage.startDate).getTime();
  const gracePeriodEndDate = new Date(evaluationGracePeriodStage.endDate).getTime();
  const interval = gracePeriodEndDate - startDate;
  return interval / (60 * 1000);
};

const getIsGracePeriodSelected = (activity: any) => {
  const evaluationGracePeriodStage = getEvaluationGracePeriodStage(activity);
  return evaluationGracePeriodStage.startDate ? true : false;
};

function Scheduler(props: Props) {
  const [createStartDate, setCreateStartDate] = useState(getCreateStartDate(props.activity));
  const [createEndDate, setCreateEndDate] = useState(getCreateEndDate(props.activity));
  const [evaluateStartDate, setEvaluateStartDate] = useState(getEvaluateStartDate(props.activity));
  const [evaluateEndDate, setEvaluateEndDate] = useState(getEvaluateEndDate(props.activity));
  const [evaluateGracePeriodEndDate, setEvaluateGracePeriodEndDate] = useState(
    getEvaluateGracePeriodEndDate(props.activity)
  );
  const [feedbackStartDate, setFeedbackStartDate] = useState(getFeedbackStartDate(props.activity));
  const [feedbackEndDate, setFeedbackEndDate] = useState(getFeedbackEndDate(props.activity));
  const [scheduleError, setScheduleError] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isGracePeriodSelected, setIsGracePeriodSelected] = useState(
    getIsGracePeriodSelected(props.activity)
  );
  const [selectedGracePeriod, setSelectedGracePeriod] = useState(
    getEvaluationGracePeriodIntervalInMinutes(props.activity) || GRACE_PERIOD_DURATION_MINUTES
  );

  const toggleGracePeriod = () => {
    if (isGracePeriodSelected) {
      const valueInMinutes = 0;
      onGracePeriodChange(valueInMinutes);
    } else {
      onGracePeriodChange(GRACE_PERIOD_DURATION_MINUTES);
    }
    setIsGracePeriodSelected(!isGracePeriodSelected);
  };

  function resetErrors() {
    setScheduleError('');
    setSubmitError('');
  }

  function validateSchedule() {
    resetErrors();
    let isValid = true;
    if (createStartDate >= evaluateStartDate || createStartDate >= createEndDate) {
      isValid = false;
    }
    if (!isCreateOrLater(props.activity) && createStartDate <= Date.now()) {
      isValid = false;
    }
    if (evaluateStartDate >= evaluateEndDate) {
      isValid = false;
    }
    if (evaluateEndDate > feedbackStartDate || feedbackStartDate >= feedbackEndDate) {
      isValid = false;
    }
    if (evaluateEndDate > evaluateGracePeriodEndDate) {
      isValid = false;
    }
    if (evaluateGracePeriodEndDate > feedbackStartDate) {
      isValid = false;
    }
    if (!isValid) {
      setScheduleError(localize({ message: 'Activity.Schedule.ScheduleError' }));
    }
    return isValid;
  }

  useEffect(() => {
    validateSchedule();
  }, [
    createStartDate,
    createEndDate,
    evaluateStartDate,
    evaluateEndDate,
    feedbackStartDate,
    feedbackEndDate,
    evaluateGracePeriodEndDate,
  ]);

  const onStartDateChange = (date: Date) => {
    setCreateStartDate(date.getTime());
  };

  const onEvaluateStartDateChange = (date: Date) => {
    const newEvaluateStartDate = date.getTime();
    setCreateEndDate(newEvaluateStartDate);
    setEvaluateStartDate(newEvaluateStartDate);
  };

  const onEvaluateEndDateChange = (date: Date) => {
    const newEvaluateEndDate = date.getTime();
    const selectedGracePeriodInMiliseconds = selectedGracePeriod * 60 * 1000;
    setEvaluateEndDate(newEvaluateEndDate);
    setEvaluateGracePeriodEndDate(newEvaluateEndDate + selectedGracePeriodInMiliseconds);
    setFeedbackStartDate(newEvaluateEndDate + selectedGracePeriodInMiliseconds);
  };

  const onFeedbackEndDateChange = (date: Date) => {
    const newFeedbackEndDate = date.getTime();
    setFeedbackEndDate(newFeedbackEndDate);
  };

  const onGracePeriodChange = (value: number) => {
    const valueInMiliseconds = value * 60 * 1000;
    const newGracePeriodEndDate = evaluateEndDate + valueInMiliseconds;
    setEvaluateGracePeriodEndDate(newGracePeriodEndDate);
    setSelectedGracePeriod(value);
    setFeedbackStartDate(newGracePeriodEndDate);
  };

  const getSchedule = () => {
    const createStage = getCreationStage(props.activity);
    const evaluateStage = getEvaluationStage(props.activity);
    const evaluateGraceStage = getEvaluationGracePeriodStage(props.activity);
    const feedbackStage = getFeedbackStage(props.activity);
    const _evaluateStartDate = moment(evaluateStartDate).add(1, 'minutes').toDate();
    const _feedbackStartDate = moment(evaluateEndDate).add(1, 'minutes').toDate();

    createStage.startDate = new Date(createStartDate);
    createStage.endDate = new Date(evaluateStartDate);
    evaluateStage.startDate = new Date(_evaluateStartDate);
    evaluateStage.endDate = new Date(evaluateEndDate);
    feedbackStage.startDate = new Date(_feedbackStartDate);
    feedbackStage.endDate = new Date(feedbackEndDate);

    const statusList = [createStage, evaluateStage, feedbackStage];
    if (isGracePeriodSelected) {
      const evaluateGraceStartDate = moment(evaluateEndDate).add(1, 'minutes').toDate();
      const evaluateGraceEndDate = moment(evaluateGracePeriodEndDate).add(1, 'minutes').toDate();
      const feedbackStartDateWithGrace = moment(evaluateGracePeriodEndDate)
        .add(1, 'minutes')
        .toDate();

      evaluateGraceStage.startDate = new Date(evaluateGraceStartDate);
      evaluateGraceStage.endDate = new Date(evaluateGraceEndDate);
      feedbackStage.startDate = new Date(feedbackStartDateWithGrace);
      feedbackStage.endDate = new Date(feedbackEndDate);
      statusList.splice(2, 0, evaluateGraceStage);
    }
    const data = {
      assignmentId: props.activity._id,
      activityType: props.activity.activityType,
      courseId: props.course._id,
      statusList,
    };
    return data;
  };

  const navigateToActivityPage = () => {
    props.router.push(`/course/${props.course._id}/assignment/${props.activity._id}`);
  };

  const handleScheduleUpdate = () => {
    const isValidSchedule = validateSchedule();
    if (!isValidSchedule) {
      return;
    }
    const data = getSchedule();
    try {
      setIsUpdating(true);
      props.updateAssignmentSchedule(data).then(() => {
        setIsUpdating(false);
        navigateToActivityPage();
      });
    } catch (error) {
      setIsUpdating(false);
      return setSubmitError(ErrorUtils.getErrorMessageFromResponse(error));
    }
  };

  return (
    <PageContainer>
      <Header course={props.course} activity={props.activity} />
      <Create
        startDate={createStartDate}
        onDateChange={onStartDateChange}
        error={scheduleError}
        disabled={isCreateOrLater(props.activity)}
      />
      <Evaluate
        startDate={evaluateStartDate}
        endDate={evaluateEndDate}
        onStartDateChange={onEvaluateStartDateChange}
        onEndDateChange={onEvaluateEndDateChange}
        disabledStartDate={isEvaluateOrLater(props.activity)}
        disabledEndDate={
          isInEvaluationGracePeriod(props.activity) ||
          isFeedbackOrLater({ assignment: props.activity })
        }
        toggleGracePeriod={toggleGracePeriod}
        isCheckedGracePeriod={isGracePeriodSelected}
        selectedGracePeriod={selectedGracePeriod}
        handleSelectGracePeriod={onGracePeriodChange}
      />
      <Feedback
        endDate={feedbackEndDate}
        onEndDateChange={onFeedbackEndDateChange}
        disabled={isGradingOrLater(props.activity)}
      />
      <Grading disabled={isFinalized({ assignment: props.activity })} />
      {submitError && <InlineInformation type="danger">{submitError}</InlineInformation>}
      <ButtonContainer className="scheduler__btn-container">
        <Button
          type="primary"
          onClick={handleScheduleUpdate}
          disabled={Boolean(scheduleError) || isUpdating}
          loading={isUpdating}
          testid="schedule-activity"
        >
          {localize({ message: 'Activity.ScheduleActivity' })}
        </Button>
        <Button type="secondary" onClick={navigateToActivityPage}>
          {localize({ message: 'Cancel' })}
        </Button>
      </ButtonContainer>
    </PageContainer>
  );
}

const mapStateToProps = (state: any) => {};

export default withRouter(connect(mapStateToProps, { updateAssignmentSchedule })(Scheduler));
