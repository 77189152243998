import { combineReducers } from 'redux';
import { generateAsyncReducer } from 'redux/utils';
import types from './types';

const asyncActions = {
  call: [types.LIST_STUDENT_EVALUATIONS, types.ASSIGN_EVALUATIONS],
  success: [types.LIST_STUDENT_EVALUATIONS_SUCCESS, types.ASSIGN_EVALUATIONS_SUCCESS],
  error: [types.LIST_STUDENT_EVALUATIONS_FAILURE, types.ASSIGN_EVALUATIONS_FAILURE],
};

export default combineReducers({
  async: generateAsyncReducer(asyncActions),
});
