import get from 'lodash/get';
/**
 * All error objects are constructed in server/app/utils/error.js,
 * and then reshaped in error handler middleware in server/index.js,
 * please check both of them to get the proper error message from backend
 */

function getErrors(res: any) {
  let errMessage = get(res, 'response.data.errors.message');
  if (!errMessage) {
    errMessage = get(res, 'response.data.message', res);
  }
  return errMessage;
}

/**
 * Get error message from nested field in backend response
 */
export function getErrorMessageFromResponse(res: any) {
  let message = '';
  if (res.data && res.data.multiErrors) {
    message = res.data.multiErrors;
  } else if (res.data && res.data.error) {
    message = res.data.error.message;
  } else if (res.data && res.data.errors) {
    message = res.data.errors.message || res.data.errors;
  }
  if (!message) {
    message = getErrors(res);
  }
  return message || 'Something went wrong, please try again later';
}
