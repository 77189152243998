const storage = window.localStorage || {
  setItem: () => {},
  getItem: () => {
    return undefined;
  },
};

export const setText = (id: any, text: any) => {
  storage.setItem(id, JSON.stringify(text));
};
export const getText = (id: any) => {
  if (!storage) {
    return null;
  }
  const text = storage.getItem(id);
  return text && JSON.parse(text);
};

export const removeText = (id: any) => {
  // @ts-expect-error TS(2339) FIXME: Property 'removeItem' does not exist on type 'Stor... Remove this comment to see the full error message
  storage.removeItem(id);
};
