import React, { Fragment } from 'react';
import { connect } from 'react-redux';
// utils
import { isCommentRemoved } from '@kritik/utils/creation/status';
import { getNewKS } from 'utils/student';
import { DisplayBox, DisplayBoxDivider, TextDisplay } from 'components/layout';
import NoticeBoard from 'components/layout/NoticeBoard';
import { Feedback } from 'components/Creation/Feedback';
import StudentContent from 'components/layout/StudentContent';
import { defineScoreNames } from '@kritik/constants/scoreNames';
import {
  isCalibrationActivity,
  isWithinGroupActivity,
  isPresentationActivity,
} from '@kritik/utils/activity';
import * as EvaluationUtils from '@kritik/utils/creation/evaluation';
import WrittenEvaluation from 'components/Creation/Evaluate/WrittenEvaluation/index';
import { WrittenEvalScoreUtil } from '@kritik/utils/grade';
import ScoreItem from 'components/DisplayBox/ScoreItem';
import { getStudentList } from 'selectors/student';
import EvaluationOptions from 'components/Creation/Evaluate/Options';
import VerticalList from 'components/layout/VerticalList';
import { selectSpotlights } from 'redux/spotlight';
import SubmissionSpotlight from 'components/Spotlight/Creation';
import InstructorCommentView from 'components/CommentOnContent/EvaluationComment';
import { scoreHasTeacherComment } from '@kritik/utils/creation/evaluation';
import { EvaluationStatusLabel } from 'components/Creation/Evaluate/EvaluationStatusLabel';
import { FlagBanner } from 'components/Creation/Evaluate/FlagBanner';
import HighlightedCriteria from 'components/Creation/Evaluate/Criteria';
import { useUserRoleInCourse } from 'hooks/course';

const WrittenEvaluationPanel = ({
  peersCreation,
  submission,
  studentsEval,
  assignment,
  isInstructor,
  student,
  students,
  spotlights,
}: any) => {
  const { isInstructorInCourse } = useUserRoleInCourse();
  const scoreNames = defineScoreNames(isInstructor);

  const isSelfEvaluator = () => {
    const evalUserId = studentsEval?.user?._id || studentsEval?.user;
    const peersCreationUserId = peersCreation?.user?._id || peersCreation?.user;
    return evalUserId === peersCreationUserId;
  };
  let feedbackSkillsChange = null;
  if (studentsEval.feedbackOnFeedback.createdAt) {
    feedbackSkillsChange = WrittenEvalScoreUtil.getCreationFeedbackSkillsChange(
      peersCreation,
      student._id
    ).totalChange;
  }

  const anonymous = !isWithinGroupActivity(assignment);

  const feedbackBreakdownSection = () => {
    if (EvaluationUtils.wasLateEvaluation({ evaluation: studentsEval })) {
      return null;
    }
    if (isPresentationActivity(assignment)) {
      const { student: studentId } = studentsEval.feedbackOnFeedback;
      const student = students.find((student) => student._id === studentId);

      return (
        <>
          <DisplayBoxDivider />
          <h5> Feedback Breakdown </h5>
          <StudentContent
            user={student?.user}
            score={getNewKS(student)}
            anonymous={anonymous}
            testid="feedback-breakdown"
          >
            <Feedback
              assignment={assignment}
              submission={peersCreation}
              evaluation={studentsEval}
              showBreakdown
              showTitle={false}
            />
          </StudentContent>
        </>
      );
    }

    if (!isSelfEvaluator()) {
      return (
        <React.Fragment>
          <DisplayBoxDivider />
          <h5> Feedback Breakdown </h5>
          <StudentContent
            user={peersCreation.user}
            score={getNewKS(peersCreation.student)}
            label="Creator"
            anonymous={anonymous}
            testid="feedback-breakdown"
          >
            <Feedback
              assignment={assignment}
              submission={peersCreation}
              evaluation={studentsEval}
              showBreakdown
              showTitle={false}
            />
          </StudentContent>
        </React.Fragment>
      );
    }
  };

  const displayEvaluationComment = (evaluation) => {
    if (isCalibrationActivity(assignment)) {
      return;
    }
    return (
      <div className="evaluation-student-comment" data-testid="evaluation-student-content">
        {displayCommentContent(evaluation)}
      </div>
    );
  };

  const displayTeacherComment = (evaluation, creation) => {
    if (!scoreHasTeacherComment(evaluation) || !isInstructorInCourse) {
      return null;
    }

    return (
      <InstructorCommentView
        creationUserId={creation.user._id || creation.user}
        activityId={assignment._id}
        evaluation={evaluation}
        isInstructor
        creation={creation}
      />
    );
  };

  const displayCommentContent = (evaluation) => {
    if (!evaluation.teachercomment) {
      return null;
    }
    return (
      <Fragment>
        <FlagBanner
          isInstructor={isInstructorInCourse}
          isEvaluationOwner={false}
          evaluation={evaluation}
        />
        <div className="evaluation-labels-container">
          <HighlightedCriteria creationScore={evaluation} rubric={assignment.rubric} />
          <EvaluationStatusLabel evaluation={evaluation} />
        </div>
        <TextDisplay>
          <WrittenEvaluation evaluation={evaluation} />
        </TextDisplay>
      </Fragment>
    );
  };

  const displayEvaluationSpotlight = (spotlight) => {
    return <SubmissionSpotlight spotlight={spotlight} />;
  };

  const numEvalsAssigned = EvaluationUtils.getNumEvaluationsToDo(submission, student._id);
  const writtenEvalScorePerEval = WrittenEvalScoreUtil.calcScorePerEval(
    numEvalsAssigned,
    feedbackSkillsChange
  );

  const spotlight = spotlights.find((s: any) => {
    return s.reference === studentsEval._id;
  });
  const itemList = [
    displayEvaluationComment(studentsEval),
    displayTeacherComment(studentsEval, peersCreation),
  ];
  if (spotlight) {
    itemList.splice(1, 0, displayEvaluationSpotlight(spotlight));
  }

  return (
    <DisplayBox
      header={
        <ScoreItem
          size="lg"
          title={scoreNames.WRITTEN_EVALUATION_SCORE}
          description="The quality of your written evaluation comments scored by your peers."
          value={writtenEvalScorePerEval}
          testid="written-eval-score"
        />
      }
      className="written-evaluation-panel-container"
    >
      <h5>{scoreNames.WRITTEN_EVALUATION}</h5>
      <StudentContent
        user={studentsEval.user}
        score={getNewKS(student)}
        label="Evaluator"
        testid="evaluator-block"
      >
        {isCommentRemoved(studentsEval) ? (
          <NoticeBoard type="danger" title="This comment has been removed by your instructor." />
        ) : (
          <React.Fragment>
            <WrittenEvaluation evaluation={studentsEval} />
            <EvaluationOptions
              evaluation={studentsEval}
              creation={peersCreation}
              spotlight={spotlight}
            >
              <VerticalList space="sm" itemList={itemList} />
            </EvaluationOptions>
          </React.Fragment>
        )}
      </StudentContent>
      {feedbackBreakdownSection()}
    </DisplayBox>
  );
};

WrittenEvaluationPanel.defaultProps = {
  isInstructor: false,
};

const mapStateToProps = (state: any) => {
  return {
    students: getStudentList(state),
    spotlights: selectSpotlights(state),
  };
};

export default connect(mapStateToProps)(WrittenEvaluationPanel);
