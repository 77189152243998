import { combineReducers } from 'redux';

import * as types from 'types';

const assignments = (
  state = {
    items: {},
  },
  action: any
) => {
  switch (action.type) {
    case types.GET_ASSIGNMENTS_REQUEST:
      if (action.payload && action.payload.params) {
        if (![action.payload.params.courseId]) {
          return {
            ...state,
            [action.payload.params.courseId]: { items: [] },
          };
        }
      }
      return state;

      break;

    case types.GET_ASSIGNMENTS_SUCCESS:
      if (action.payload && action.payload.params) {
        return {
          ...state,
          [action.payload.params.courseId]: { items: action.payload.items },
        };
      }
      return state;
    case types.GET_COURSE_SPOTLIGHT_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          spotlights: { items: action.payload.items },
        };
      }
      return state;

    case types.GET_STUDENT_SUCCESS:
      if (action.payload && action.payload.params) {
        return {
          ...state,
          student: action.payload,
        };
      }
      return state;

    case types.CREATE_ASSIGNMENT_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          [action.payload.course]: {
            items: [action.payload._id, ...state[action.payload.course].items],
          },
        };
      }
      return state;

    case types.UPDATE:
      if (action.payload) {
        return {
          ...state,
          [action.payload.course]: {
            items: [action.payload._id, ...state[action.payload.course].items],
          },
        };
      }
      return state;

    default:
      return state;
  }
};

const initCourseDashboardState = {
  avgOverallGrade: null,
  avgCreationGrade: null,
  avgEvalGrade: null,
  avgFeedbackGrade: null,
};

function dashboard(state = initCourseDashboardState, action: any) {
  switch (action.type) {
    case types.SET_AVG_OVERALL_SCORE:
      return {
        ...state,
        avgOverallGrade: action.payload.avgOverallGrade,
      };
    case types.SET_AVG_CREATION_SCORE:
      return {
        ...state,
        avgCreationGrade: action.payload.avgCreationGrade,
      };
    case types.SET_AVG_EVAL_SCORE:
      return {
        ...state,
        avgEvalGrade: action.payload.avgEvalGrade,
      };
    case types.SET_AVG_FEEDBACK_SCORE:
      return {
        ...state,
        avgFeedbackGrade: action.payload.avgFeedbackGrade,
      };
    case types.INIT_DASHBOARD_SCORES:
      return initCourseDashboardState;
    default:
      return state;
  }
}

const initCourseExportState = {
  exportCourseCreations: {
    request: false,
    success: false,
    errorMessage: '',
  },
};

function exportCourse(state = initCourseExportState, action: any) {
  switch (action.type) {
    case types.EXPORT_COURSE_CREATIONS_REQUEST:
      return {
        ...state,
        exportCourseCreations: {
          request: true,
          success: false,
          errorMessage: '',
        },
      };
    case types.EXPORT_COURSE_CREATIONS_SUCCESS:
      return {
        ...state,
        exportCourseCreations: {
          request: false,
          success: true,
          errorMessage: '',
        },
      };
    case types.EXPORT_COURSE_CREATIONS_FAILURE:
      return {
        ...state,
        exportCourseCreations: {
          request: false,
          success: false,
          errorMessage: 'There was an error in generating creations. Please contact support.',
        },
      };
    case types.EXPORT_COURSE_CREATIONS_INIT:
      return {
        ...state,
        exportCourseCreations: {
          request: false,
          success: false,
          errorMessage: '',
        },
      };
    default:
      return state;
  }
}

const initCourseDuplicateState = {
  loading: false,
  error: null,
};

function courseDuplicate(state = initCourseDuplicateState, action: any) {
  switch (action.type) {
    case types.CREATE_DUPLICATE_COURSE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.CREATE_DUPLICATE_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.CREATE_DUPLICATE_COURSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.CLEAR_DUPLICATE_COURSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
}

const courseReducer = combineReducers({
  assignments,
  dashboard,
  exportCourse,
  courseDuplicate,
});

export default courseReducer;
