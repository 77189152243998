import AvatarDisplay from 'components/layout/AvatarDisplay';
import { randomHexString } from 'utils/random';
import { getGravatarUrl } from '@kritik/utils/gravatar';
import { useParams } from 'react-router';
import { localize } from 'locales';
import { useUserRoleInCourse } from 'hooks/course';
import { Button, ButtonContainer } from 'components/buttons';
import { RouterProp, withRouter } from 'utils/withRouter';
import { connect } from 'react-redux';
import { Course } from '@kritik/types.generated';
import InfoPopup from 'components/core/InfoPopup';
import { TranslatedText } from 'components/TranslatedText';

type CollaboratorsProps = {
  course: Course;
  isStudent: boolean;
  router: RouterProp;
};

function Collaborators({ course, isStudent, router }: CollaboratorsProps) {
  const { courseId } = useParams();
  const { isInstructorInCourse } = useUserRoleInCourse();

  const renderUserInfo = (user: any) => {
    return (
      <div className="collaborators-panel__user-info">
        <div className="collaborators-panel__user-name">{user.profile.name}</div>
        <div className="collaborators-panel__user-email">{user.email}</div>
      </div>
    );
  };

  const getPendingInstructorInfo = (email: any) => {
    return (
      <div className="collaborators-panel__user-info">
        <div className="collaborators-panel__user-name">
          {email}
          <span className="collaborators-panel__pending-tag" data-testid="collaborator-pending-tag">
            {localize({ message: 'CourseDetails.CollaboratorPanel.PendingStatus' })}
          </span>
        </div>
      </div>
    );
  };

  const renderInstructorInfo = (collaborator: any, index: any) => {
    return (
      <div key={index} className="collaborators-panel__instructor">
        <AvatarDisplay
          user={collaborator}
          size="60px"
          testid="collaborator-avatar-container"
          showBorder={false}
          hoverable={false}
          anonymous={false}
        />
        {renderUserInfo(collaborator)}
      </div>
    );
  };

  const renderPendingInstructorInfo = (collaboratorEmail: any, index: any) => {
    return (
      <div key={index} className="collaborators-panel__instructor">
        {getPendingInstructorInfo(collaboratorEmail)}
      </div>
    );
  };

  const renderInviteButton = () => {
    return (
      <ButtonContainer className="collaborator-manager__list-header-btns">
        <Button
          testid="manage-collaborator-button"
          type="secondary"
          className="invite-users-btn"
          onClick={() => {
            return routeToCourseRoster();
          }}
        >
          {localize({
            message: 'CourseDetails.CollaboratorManager.Buttons.ManageCollaborators2',
          })}
          <i className="fa fa-arrow-right spacing" />
        </Button>
      </ButtonContainer>
    );
  };

  const routeToCourseRoster = () => {
    router.push(`/course/${courseId}/roster?tab=collaborators`);
  };

  const anonymizeCollaborator = (collaborator: any) => {
    const anyonymizedCollaborator = { ...collaborator };
    anyonymizedCollaborator.email = 'anonymous';
    anyonymizedCollaborator.profile.name = 'anonymous';
    anyonymizedCollaborator.profile.picture = getGravatarUrl(randomHexString(8));
    return anyonymizedCollaborator;
  };

  const renderCollaborators = () => {
    const shouldHide = course.courseSettings.isDemoCourse;
    return (
      <div className="collaborator-panel__list">
        {course.collaborators.map((collaborator: any, index: any) => {
          if (shouldHide) {
            const anonymized = anonymizeCollaborator(collaborator);
            return renderInstructorInfo(anonymized, index);
          }
          return renderInstructorInfo(collaborator, index);
        })}
        {!isStudent &&
          course.approvedCollaborators.map((collaborator: any, index: any) => {
            return renderPendingInstructorInfo(collaborator, index + course.collaborators.length);
          })}
        {!isStudent &&
          course.collaborators.length === 0 &&
          course.approvedCollaborators.length === 0 && (
            <p>{localize({ message: 'CourseDetails.CollaboratorPanel.NoCollaboratorInvited' })}</p>
          )}
        {isStudent && course.collaborators.length === 0 && (
          <p>{localize({ message: 'CourseDetails.CollaboratorPanel.NoCollaborator' })}</p>
        )}
      </div>
    );
  };

  const footerContent = () => {
    if (!isInstructorInCourse) {
      return null;
    }

    return renderInviteButton();
  };

  const getPopoverDescription = () => {
    return (
      <>
        <p className="top-copy">
          <TranslatedText i18nKey={'CourseDetails.CollaboratorPanel.InfoPopup.Description1'} />
        </p>
        <p className="no-margin">
          {localize({
            message: 'CourseDetails.CollaboratorPanel.InfoPopup.Description2',
          })}
        </p>
      </>
    );
  };

  return (
    <>
      <div className="course-details-copy__container">
        <p className="course-details__title no-margin">
          {localize({ message: 'CourseDetails.CollaboratorPanel.Title' })}
        </p>
        {isInstructorInCourse && (
          <InfoPopup
            testid="collaborator-info-popup"
            popoverContentClass="course-details__collaborator-info-popup"
            title={localize({
              message: 'CourseDetails.CollaboratorPanel.InfoPopup.Title',
            })}
            description={getPopoverDescription()}
          />
        )}
      </div>
      {renderCollaborators()}
      {footerContent()}
    </>
  );
}

export default withRouter(connect(null, null)(Collaborators));
