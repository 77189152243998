import moment from 'moment';
import * as FormatUtils from '@kritik/utils/format';

const getDateLabel = (startDate: any, interval: any) => {
  const start = moment(startDate);
  const end = moment(startDate).add(interval, 'seconds');
  const result = FormatUtils.timeBetweenDates({ startDate: start.toDate(), endDate: end.toDate() });
  if (result.unit === 'days') {
    const days = result.diffValue;
    return `${days} day${days === 1 ? '' : 's'}`;
  }
  return FormatUtils.displayTimeBetweenDates(start.toDate(), end.toDate());
};

export default {
  getDateLabel,
};
