import { combineReducers } from 'redux';
import { generateAsyncReducer } from 'redux/utils';
import types from './types';

const asyncActions = {
  call: [types.LIST_ACTIVITY_GROUPS],
  success: [types.LIST_ACTIVITY_GROUPS_SUCCESS],
  error: [types.LIST_ACTIVITY_GROUPS_FAILURE],
};

export default combineReducers({
  async: generateAsyncReducer(asyncActions),
});
