import React from 'react';
import classNames from 'classnames';

const STATUSES = {
  DANGER: 'danger',
  WARNING: 'warning',
  RESOLVED: 'resolved',
  GOOD: 'good',
  RANKUP: 'rankup',
};

const Count = ({ children }: any) => {
  if (!children) {
    return null;
  }
  return <div className="creation-status-label__count">{children}</div>;
};

const Icon = ({ children }: any) => {
  if (!children) {
    return null;
  }
  return <div className="creation-status-label__icon">{children}</div>;
};
const Label = ({ children }: any) => {
  if (!children) {
    return null;
  }
  return <div className="creation-status-label__label">{children}</div>;
};

const Labels = (props: any) => {
  const classes = classNames(
    'creation-status-label',
    {
      'creation-status-label--danger': props.status === STATUSES.DANGER,
      'creation-status-label--warning': props.status === STATUSES.WARNING,
      'creation-status-label--resolved': props.status === STATUSES.RESOLVED,
      'creation-status-label--good': props.status === STATUSES.GOOD,
      'creation-status-label--rankup': props.status === STATUSES.RANKUP,
    },
    props.className
  );
  return (
    <div className={classes} data-testid={props.testid}>
      <Count>{props.count}</Count>
      <Icon>{props.icon}</Icon>
      <Label>{props.label}</Label>
    </div>
  );
};

export default Labels;
