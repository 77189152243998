import { Field, useForm } from 'react-final-form';
import MultiSelectionField from 'components/core/form/MultiSelectionField';
import { localize } from 'locales';
import { Student } from '@kritik/types.generated';
import { ALL_STUDENTS, EXCLUDE_CREATORS } from '@kritik/constants/activity';

const StudentCreatorsField = ({ students }: { students: Student[] }) => {
  const form = useForm();
  const handleInputChange = (value: any, onChange: (val: any) => void) => {
    if (!value) {
      form.change('evaluatorType', EXCLUDE_CREATORS);
    }
    if (value?.length === students.length) {
      form.change('evaluatorType', ALL_STUDENTS);
    }
    onChange(value);
  };

  const studentsOptions = (students || []).map((student: any, index: number) => {
    return {
      label: student.user.profile.name,
      value: student._id,
      testid: `student-creator-option-${index}`,
    };
  });

  return (
    <>
      <div className="student-select-title">
        {localize({
          message: 'Activity.EditStudentParticipation.Individual.StudentCreatorField.Title',
        })}
      </div>
      <Field
        name="creatorStudents"
        parse={(values) => (values || []).map((v) => v.value)}
        format={(values) =>
          (values || []).map((v) => studentsOptions.find((student) => student.value === v))
        }
      >
        {({ input }) => {
          return (
            <div className="student-select-container">
              <MultiSelectionField
                value={input.value}
                name={input.name}
                onChange={(value) => handleInputChange(value, input.onChange)}
                placeholder="Students"
                options={studentsOptions}
                testid="presentation-select-students"
              />
            </div>
          );
        }}
      </Field>
    </>
  );
};

export default StudentCreatorsField;
