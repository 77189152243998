import React from 'react';
import { connect } from 'react-redux';
import { FormSelection } from 'components/Form';
import { Button } from 'components/buttons';

const mapStateToProps = (state: any) => {
  return {
    isLoading: state.admin.isLoadingAssignments,
  };
};

const FindAssignmentFromOptions = (props: any) => {
  const { assignment, changeAssignment, assignmentOptions, handleVerifyAssignment } = props;

  return (
    <React.Fragment>
      <FormSelection
        label="Assignment"
        isLoading={props.isLoading}
        value={assignment}
        onChange={changeAssignment}
        options={assignmentOptions}
      />

      {handleVerifyAssignment && (
        <Button style={{ marginBottom: '22px' }} type="secondary" onClick={handleVerifyAssignment}>
          Verify Assignment
        </Button>
      )}
    </React.Fragment>
  );
};

export default connect(mapStateToProps, null)(FindAssignmentFromOptions);
