import { schema } from 'normalizr';

import user from 'schemas/user';
import student from 'schemas/student';

const evaluationSchema = new schema.Entity(
  'evaluations',
  {
    /**
     * turn off user normalizing for now since the user in evaluation is
     * anonymize, so it will override the user data get from get groups api
     */
    // user,
    // student,
  },
  { idAttribute: '_id' }
);

export default evaluationSchema;
