import { createRestApiClient } from 'utils/createRestApiClient';

const apiEndpoint = import.meta.env.VITE_API_URL;

type ResolveCreationSimilarityReport = {
  creationId: string;
  note: string;
};

export default (options = {}) => {
  const client =
    (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint });
  return {
    getCreationPlagiarism: (params: {
      creationId: string;
    }): Promise<DataResponse<plagiarism.get_plagiarism_probability.GET.Response>> => {
      return client.request({
        method: 'GET',
        url: `/plagiarism/creation/${params.creationId}`,
      });
    },
    exportContentReport: (params: { activityId: string }) => {
      return client.request({
        method: 'GET',
        url: `/plagiarism/report/activity/${params.activityId}`,
      });
    },
    resolveCreationSimilarityReport: ({ creationId, note }: ResolveCreationSimilarityReport) => {
      return client.request({
        method: 'POST',
        url: `/plagiarism/reports/similarity/resolve`,
        data: { note, creationId },
      });
    },
    unresolveCreationSimilarityReport: (creationId: string) => {
      return client.request({
        method: 'POST',
        url: `/plagiarism/reports/similarity/unresolve`,
        data: { creationId },
      });
    },
  };
};
