import { getAssignment, getAssignments } from 'actions/activity';
import { getCourses } from 'actions/courses';

const list = ({ params, client }: any, { dispatch, getState }: any) => {
  return new Promise((resolve, reject) => {
    dispatch(getAssignments(params, { client, callback: resolve, reject }));
  }).catch((err) => {
    throw err;
  });
};

const get = ({ params, client }: any, { dispatch, getState }: any) => {
  return new Promise((resolve, reject) => {
    dispatch(getAssignment({ id: params.assignmentId }, { client, callback: resolve, reject }));
    dispatch(getCourses(params, { client, callback: resolve, reject }));
  }).catch((err) => {
    throw err;
  });
};

const listCourses = ({ params, client }: any, { dispatch, getState }: any) => {
  return new Promise((resolve, reject) => {
    dispatch(getCourses(params, { client, callback: resolve, reject }));
  }).catch((err) => {
    throw err;
  });
};

const getAssignmentAndCourses = ({ params, client }: any, store: any) => {
  return Promise.all([get({ params, client }, store), listCourses({ params, client }, store)]);
};

export default {
  get,
  list,
  getAssignmentAndCourses,
};
