import { rubricSchema } from 'schemas';
import { denormalize } from 'normalizr';
import types from './types';

export const selectIsEditingTemplate = (state: any) => {
  return state.rubricManager.isEditingTemplate;
};

export const selectRubricId = (state: any) => {
  return state.rubricManager.rubricId;
};

export const selectRubric = (state: any) => {
  const { rubrics } = state.entities;
  if (!rubrics) {
    return null;
  }

  const selectedId = selectRubricId(state);

  if (!selectedId) {
    return null;
  }

  const denormalizedRubric = denormalize(selectedId, rubricSchema, state.entities);

  return denormalizedRubric;
};

export const selectRubricManagerAsyncState = (state: any) => {
  return state.rubricManager.async;
};

export const selectDeleteRubricState = (state: any) => {
  return selectRubricManagerAsyncState(state)[types.DELETE_RUBRIC];
};

export const selectCopyRubricState = (state: any) => {
  return selectRubricManagerAsyncState(state)[types.COPY_RUBRIC];
};
