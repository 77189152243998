import React from 'react';
import PopoverTab from 'components/Navigation/PopoverTab';
import Typography from '@material-ui/core/Typography';
import { localize } from 'locales';

const PopoverContainerStyle = {
  padding: '15px',
  width: '250px',
};
const PopoverListStyle = {
  margin: '0',
  paddingLeft: '25px',
};

const StudentsTab = (props: any) => {
  const content =
    props.enrolledStudents + props.pendingStudents + props.removedStudents === 0 ? (
      <Typography style={PopoverContainerStyle}>
        {localize({ message: 'Navigation.Tabs.Students.Tip.Empty' })}
      </Typography>
    ) : (
      <Typography style={PopoverContainerStyle} component="div">
        <p>{localize({ message: 'Navigation.Tabs.Students.Tip.Populated' })}</p>
        <ul style={PopoverListStyle}>
          {props.enrolledStudents > 0 && <li>{props.enrolledStudents} enrolled</li>}
          {props.pendingStudents > 0 && <li>{props.pendingStudents} pending</li>}
          {props.removedStudents > 0 && <li>{props.removedStudents} removed</li>}
        </ul>
      </Typography>
    );

  return (
    <PopoverTab
      destination={props.destination}
      icon={<i className="fa fa-user-graduate" />}
      activeRegex={props.activeRegex}
      label={localize({ message: 'Navigation.Tabs.CourseRoster.Label' })}
      content={content}
      testid="students-tab"
    />
  );
};
export default StudentsTab;
