import { NavigateFunction, Params, useLocation, useNavigate, useParams } from 'react-router-dom';

export type RouterProp = {
  location: Location & { query: Record<string, string> };
  push: NavigateFunction;
  params: Readonly<Params<string>>;
};

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return (
      <Component
        {...props}
        router={{
          location: {
            ...location,
            query: Object.fromEntries(new URLSearchParams(location.search)),
          },
          push: navigate,
          params,
        }}
      />
    );
  }

  return ComponentWithRouterProp;
}
