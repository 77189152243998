import React from 'react';
import { connect } from 'react-redux';
import { addGroup, selectCurrentGroupSet } from 'redux/groupManager';
import { CreateCard } from 'components/cards';

function AddGroupCard({ groupSet, addGroup, style }: any) {
  const handleOnClick = () => {
    addGroup(groupSet._id);
  };
  return (
    <div className="add-group-card" style={style}>
      <CreateCard text="Add a group" onClick={handleOnClick} />
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    groupSet: selectCurrentGroupSet(state),
  };
};

export default connect(mapStateToProps, { addGroup })(AddGroupCard);
