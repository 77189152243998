import { Course } from '@kritik/types.generated';
import moment from 'moment';

export function isRevealingEvaluators(course: Course) {
  return course.courseSettings.revealEvaluatorIdentities;
}

export function isAverageGradeHidden(course: Course) {
  return course.courseSettings.hideAverageGrade;
}

export function isEvaluatorScoreEnabled(course: Course) {
  return course.courseSettings.useEvaluationScore;
}

export function evalCommentsDisabled(course: Course) {
  return course.courseSettings.evalCommentsDisabled;
}

export function getMarkingSchemeBreakDowns(course: Course) {
  const { markingScheme } = course;

  const creation = markingScheme.passedSubmissions;
  const evaluation = markingScheme.kritikScore + markingScheme.feedbackCommunicationEffectiveness;
  const grading = markingScheme.kritikScore;
  const writtenEvaluation = markingScheme.feedbackCommunicationEffectiveness;
  const feedback = markingScheme.participationInFof;
  const total = creation + evaluation + feedback;

  return {
    total,
    creation,
    evaluation,
    grading,
    writtenEvaluation,
    feedback,
  };
}

export function isPercentageGrading(course: Course) {
  return course.markingScheme.isPercentage;
}

export const getDefaultActivityWeight = (course: Course) => {
  return course.defaultActivityWeight;
};

//this config is not used anymore, but we need to keep it for backward compatibility
type OldLMSConfig = {
  api: string;
  lti: string;
};
export function isEdlinkLmsSetup(course: Course) {
  if (
    course.lms &&
    course.lms.connectedCourseId &&
    !(course.lms as unknown as OldLMSConfig).api &&
    !(course.lms as unknown as OldLMSConfig).lti
  ) {
    return true;
  }
  return false;
}

export const acceptsLateSubmissions = (course: Course) => {
  if (!course) {
    return false;
  }
  if (!course.hasOwnProperty('acceptLateSubmissions') || !course.acceptLateSubmissions) {
    return false;
  }
  return true;
};

export const autoAcceptsLateSubmissions = (course: Course) => {
  if (!course) {
    return false;
  }
  if (!course.hasOwnProperty('autoAccept') || !course.autoAccept) {
    return false;
  }
  return true;
};

export const getNextTerms = (): string[] => {
  const terms = ['Winter', 'Spring', 'Summer', 'Fall'];
  const currentMonth = moment().month();
  const currentYear = moment().year();

  let startTermIndex;

  if (currentMonth >= 0 && currentMonth <= 2) {
    startTermIndex = 0; // Winter
  } else if (currentMonth >= 3 && currentMonth <= 5) {
    startTermIndex = 1; // Spring
  } else if (currentMonth >= 6 && currentMonth <= 8) {
    startTermIndex = 2; // Summer
  } else {
    startTermIndex = 3; // Fall
  }

  const nextTerms = [];
  let year = currentYear;

  for (let i = 0; i < 8; i++) {
    nextTerms.push(`${terms[startTermIndex]} ${year}`);
    startTermIndex++;
    if (startTermIndex >= terms.length) {
      startTermIndex = 0;
      year++;
    }
  }

  return nextTerms;
};
