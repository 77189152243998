import React from 'react';
import type { PropsWithChildren } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { useFetchActivity } from 'hooks/activity';
import { useFetchCourse, useUserRoleInCourse } from 'hooks/course';
import { isPresentationActivity } from '@kritik/utils/activity';
import { studentParticipatesInActivityFromStudentView } from '@kritik/utils/stage';
import { formatFullCourseBreadcrumb, formatPartialCourseBreadcrumb } from 'utils/format';

type CourseDetailsPageProps = {};

export const CoursePage: React.FC<PropsWithChildren<CourseDetailsPageProps>> = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { data: course, error } = useFetchCourse(params.courseId);
  const { data: activity } = useFetchActivity(params.assignmentId);
  const { isInstructorInCourse } = useUserRoleInCourse();

  const canNavigateToActivity =
    isInstructorInCourse ||
    !isPresentationActivity(activity) ||
    studentParticipatesInActivityFromStudentView({ activity });

  if (!course) {
    if (error) {
      navigate('/');
    }
    return null;
  }
  return (
    <>
      <div className="page-content-container nested-page-container">
        <nav className="page-content nested-page-breadcrumbs" aria-label="Breadcrumb">
          <ol>
            <li>
              <NavLink to={`/course/${course._id}/assignments`}>
                <Typography
                  color="primary"
                  variant="body2"
                  className="course-title-header"
                  title={formatFullCourseBreadcrumb(course)}
                >
                  {formatPartialCourseBreadcrumb(course)}
                </Typography>
              </NavLink>
            </li>
            {activity && (
              <>
                <li className="breadcrumb-nav" aria-hidden="true">
                  /
                </li>
                <li>
                  {canNavigateToActivity ? (
                    <NavLink
                      to={`/course/${course._id}/assignment/${activity._id}`}
                      className={({ isActive }) => (isActive ? 'pointer-events-none' : '')}
                      end
                    >
                      <Typography color="primary" variant="body2" className="course-title-header">
                        {activity.title}
                      </Typography>
                    </NavLink>
                  ) : (
                    <Typography color="primary" variant="body2" className="course-title-header">
                      {activity.title}
                    </Typography>
                  )}
                </li>
              </>
            )}
          </ol>
        </nav>
      </div>
      <Outlet />
    </>
  );
};
