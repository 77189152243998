import classNames from 'classnames';
import LOADER from 'components/Loaders/Spinner/image.svg';

const Spinner = (props: any) => {
  const style = {
    width: props.size,
    height: props.size,
  };
  const classes = classNames('loading-spinner', props.className);
  return (
    <div className={classes}>
      <img style={style} className="loading-spinner__img" alt="Loading spinner" src={LOADER} />
    </div>
  );
};

Spinner.defaultProps = {
  size: '80px',
  className: '',
};

export default Spinner;
