export const STUDENT_COLUMNS = {
  PEER: [
    { header: 'Name', align: 'left', isSortable: true, width: 'lg' },
    { header: 'Create', align: 'center', isSortable: true },
    { header: 'Evaluate', align: 'center', isSortable: true },
    { header: 'Feedback', align: 'center', isSortable: true },
    { header: 'Overall', align: 'center', isSortable: true },
    { header: 'Issues', align: 'center', isSortable: true },
  ],
  GROUP: [
    { header: 'Name', align: 'left', isSortable: true, width: 'lg' },
    { header: 'Create', align: 'center', isSortable: true },
    { header: 'Evaluate', align: 'center', isSortable: true },
    { header: 'Feedback', align: 'center', isSortable: true },
    { header: 'Overall', align: 'center', isSortable: true },
    { header: 'Issues', align: 'center', isSortable: true },
  ],
  CALIBRATION: [
    { header: 'Name', align: 'left', isSortable: true, width: 'lg' },
    {
      header: 'Evaluate',
      align: 'center',
      isSortable: true,
      info: 'Students do not receive an evaluation score on calibration activities',
    },
    { header: '' },
    { header: '' },
    { header: '' },
    { header: 'Issues', align: 'center', isSortable: true },
  ],
};

export const GROUP_COLUMNS = {
  GROUP: [
    { header: 'Name', align: 'left', isSortable: true, width: 'lg' },
    { header: 'Create', align: 'center', isSortable: true },
    {
      header: 'Evaluate',
      align: 'center',
      isSortable: true,
      info: 'Groups do not receive an evaluation score',
    },
    {
      header: 'Feedback',
      align: 'center',
      isSortable: true,
      info: 'Groups do not receive a feedback score',
    },
    { header: '' },
    { header: 'Issues', align: 'center', isSortable: true },
    { header: ' ', align: 'right', isSortable: false, width: 'sm' },
  ],
};

export default {
  STUDENT_COLUMNS,
  GROUP_COLUMNS,
};
