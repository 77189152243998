import React from 'react';
import PageContainer from 'components/layout/PageContainer';
import InlineInformation from 'components/layout/InlineInformation';
import { Link } from 'react-router-dom';
import Button from 'components/buttons/Button';

const ErrorContainer = () => {
  return (
    <PageContainer>
      <div className="error-container" data-testid="error-container">
        <InlineInformation type="danger">
          Ooops, something went wrong. Please contact our Support from the Live Chat.
        </InlineInformation>
        <Link to="/">
          <Button className="error-back-button" type="primary">
            Back
          </Button>
        </Link>
      </div>
    </PageContainer>
  );
};

export default ErrorContainer;
