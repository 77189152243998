import { createRestApiClient } from 'utils/createRestApiClient';

const apiEndpoint = import.meta.env.VITE_API_URL;
import { createStorageClient } from 'utils/createStorageClient';

export default (options = {}) => {
  const client =
    (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint });
  const storage = createStorageClient().withConfig({ namespace: 'auth' });

  return {
    login: ({ email, password }: any) => {
      return client.request({
        method: 'POST',
        url: '/sessions',
        data: {
          email,
          password,
        },
      });
    },
    forgotPassword: ({ email }: any) => {
      return client.request({
        method: 'POST',
        url: '/auth/password/forgot',
        data: {
          email,
        },
      });
    },
    validateResetToken: ({ token }: any) => {
      return client.request({
        method: 'GET',
        url: `/auth/password/reset/${token}`,
      });
    },
    resetPassword: ({ token, password, confirmPassword }: any) => {
      return client.request({
        method: 'PUT',
        url: `/auth/password/reset/${token}`,
        data: {
          password,
          confirmPassword,
        },
      });
    },
    signUp: ({
      institution,
      identifier,
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
      role,
      studentId,
      shouldAddNewInstitution,
      newInstitution,
      timeZone,
      geoNamesErrorMessage,
    }: any) => {
      return client.request({
        method: 'POST',
        url: '/users',
        data: {
          institution,
          identifier,
          firstName,
          lastName,
          email,
          password,
          confirmPassword,
          role,
          studentId,
          shouldAddNewInstitution,
          newInstitution,
          timeZone,
          geoNamesErrorMessage,
        },
      });
    },
    logOut: async () => {
      const res = await client.request({
        method: 'DELETE',
        url: '/sessions',
      });
      await storage.saveItem('access_token', '');
      await storage.saveItem('refresh_token', '');
      return res;
    },
    user: () => {
      return client.request({
        method: 'GET',
        url: '/user',
      });
    },
    oauth: async ({ username, password }: any) => {
      const res = await client.request({
        method: 'POST',
        url: '/oauth/token',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: {
          grant_type: 'password',
          client_id: 'app-boilerplate',
          client_secret: '123456',
          username,
          password,
        },
      });
      if (!res.data) {
        return res;
      }
      await storage.saveItem('access_token', res.data.access_token);
      await storage.saveItem('refresh_token', res.data.refresh_token);
      return res;
    },
    viewAsStudent: (data: { studentId: string }) => {
      return client.request({
        method: 'POST',
        url: `/auth/viewAsStudent`,
        data,
      });
    },
    stopImpersonating: () => {
      return client.request({
        method: 'POST',
        url: `/auth/stopImpersonating`,
      });
    },
  };
};
