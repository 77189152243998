import React from 'react';
import * as LateSubmissionUtil from '@kritik/utils/creation/lateCreation';
import InlineInformation from 'components/layout/InlineInformation';

const RejectedCreation = ({ creation }: any) => {
  if (!LateSubmissionUtil.isLateSubmissionRejected(creation)) {
    return null;
  }
  return (
    <div className="late-submission__rejection-notice">
      <InlineInformation type="danger" title="Late - Rejected">
        Student will receive no score. To give the student a score you must accept the creation.
      </InlineInformation>
    </div>
  );
};

export default RejectedCreation;
