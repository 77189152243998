import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ScheduleSelectors, { selectSchedule } from 'redux/schedule/selectors';
import ScheduleDateInput from 'components/schedule/DateInput';
import STAGE_STATUS_ICONS from 'images/status-icons';
import StageContainer from 'containers/Schedule/StageContainer';
import DateWrapper from 'containers/Schedule/DateWrapper';
import * as formatUtils from '@kritik/utils/format';
import * as activityStatusUtils from '@kritik/utils/stage';
import { assignmentStatuses } from '@kritik/constants/stage';
import { updateSchedule } from 'redux/schedule/actions';
import { selectCurrentActivity } from 'selectors/activity';
import SchedulerArrow from './ArrowSeparator';
import localUtils from './utils';

const Schedule = (props: any) => {
  const activeStatus = activityStatusUtils.getActiveStatus(props.activity);

  const handleDateChange = (evalStartDate: any, evalEndDate: any) => {
    const newInterval = formatUtils.getSecondsBetweenDates(evalStartDate, evalEndDate);

    const newSchedule = {
      startDate: evalStartDate,
      evaluationEndDate: evalEndDate,
      evaluationInterval: newInterval,
    };
    props.updateSchedule(newSchedule);
  };

  const handleCustomTimeChange = (customTimeStr: any) => {
    const date = moment(customTimeStr, 'h:mm A');
    props.updateSchedule({
      customTime: [date.hours(), date.minutes()],
    });
  };

  if (!props.schedule) {
    return null;
  }

  const changeStartDate = (newDate: any) => {
    const date = localUtils.roundSelectedDate(newDate);
    handleDateChange(date, props.schedule.evaluationEndDate);
  };

  const changeEndDate = (newDate: any) => {
    const date = localUtils.roundSelectedDate(newDate);
    handleDateChange(props.schedule.startDate, date);
  };
  return (
    <div className="status-dates-container">
      <StageContainer>
        <SchedulerArrow
          image={<img aria-hidden="true" src={STAGE_STATUS_ICONS.EVALUATE} />}
          hideArrow
        />
        <DateWrapper
          title="Evaluate (Calibration)"
          description="During the Evaluate stage students will evaluate the three creations you graded and selected. The students will not be asked to provide written feedback. At the end of the activity, we adjust your students grading scores based on how close their evaluations were to yours."
          dateInput={
            <React.Fragment>
              <ScheduleDateInput
                testid="calibration-evaluate_start-date"
                label="Start Date"
                value={props.schedule.startDate}
                onDateChange={changeStartDate}
                minDate={new Date()}
                disabled={
                  !(
                    [
                      assignmentStatuses.DRAFT,
                      assignmentStatuses.CREATE,
                      assignmentStatuses.PROCESSING1,
                    ] as string[]
                  ).includes(activeStatus.name)
                }
                error={props.pastDateError?.Evaluate?.startDate}
                customTime={props.schedule.customTime}
                onCustomTimeChange={handleCustomTimeChange}
              />
              <ScheduleDateInput
                testid="calibration-evaluate_due-date"
                label="Due Date"
                value={props.schedule.evaluationEndDate}
                onDateChange={changeEndDate}
                minDate={localUtils.getMinDateFromPreviousEnd(props.schedule.startDate)}
                disabled={
                  !(
                    [
                      assignmentStatuses.DRAFT,
                      assignmentStatuses.CREATE,
                      assignmentStatuses.PROCESSING1,
                      assignmentStatuses.EVALUATE,
                    ] as string[]
                  ).includes(activeStatus.name)
                }
                customTime={props.schedule.customTime}
                onCustomTimeChange={handleCustomTimeChange}
              />
            </React.Fragment>
          }
        />
      </StageContainer>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    schedule: selectSchedule(state),
    activity: selectCurrentActivity(state),
    pastDateError: ScheduleSelectors.selectPastDateError(state),
  };
};

export default connect(mapStateToProps, {
  selectSchedule,
  updateSchedule,
})(Schedule);
