import React, { useState } from 'react';
import { Pencil } from 'images/icons/fa';
import Button from 'components/buttons/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { updateRubric } from 'actions/rubrics';
import { connect } from 'react-redux';
import TextInput from 'components/core/input/Text';
import FieldError from 'components/core/form/FieldError';
import * as rubricUtils from '@kritik/utils/rubric';
import ShortenText from 'components/General/ShortenText';

const ErrorInline = (props: any) => {
  return <FieldError error={props.error}></FieldError>;
};

const RubricName = (props: any) => {
  const [isEditingName, setIsEditingName] = useState(false);
  const [rubricName, setRubricName] = useState(props.rubric.name);

  const [error, setError] = useState(null);

  const handleRubricNameChange = (e: any) => {
    setError(null);
    setRubricName(e.target.value);
  };

  const handleRubricNameSave = () => {
    const nameError = rubricUtils.validateName(rubricName);

    if (nameError) {
      setError(nameError);
      return;
    }

    const capitalizedName = rubricUtils.capitalizeName(rubricName);

    setIsEditingName(false);
    if (capitalizedName !== props.rubric.name) {
      setRubricName(capitalizedName);
      props
        .updateRubric({
          ...props.rubric,
          name: capitalizedName,
          criteria: props.rubric.criteria.map((c: any) => {
            return {
              ...c,
              weight: Number(c.weight),
            };
          }),
        })
        .then((data: any) => {})
        .catch((err: any) => {
          setError(err.errors?.message || err.errors);
          setIsEditingName(true);
        });
    }
  };

  const displayNormalTitle = () => {
    const displayName = (
      <ShortenText
        className="rubric-name-edit__name"
        label={rubricName}
        hideToolTip
        maxLength={40}
      />
    );

    if (rubricUtils.isDefaultRubric(props.rubric)) {
      return displayName;
    }

    return (
      <Button
        type="hidden"
        onClick={() => {
          setIsEditingName(true);
        }}
        className="rubric-name-edit__button"
        data-testid="edit-rubric-name_button"
      >
        {displayName}
        <Pencil className="rubric-name-edit__button-icon" />
      </Button>
    );
  };

  const displayEditingName = () => {
    return (
      <ClickAwayListener
        onClickAway={() => {
          handleRubricNameSave();
        }}
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
      >
        <div>
          <TextInput
            className={`${error ? 'rubric-name-edit--error' : ''}`}
            value={rubricName}
            onChange={handleRubricNameChange}
            onEnterKey={() => {
              handleRubricNameSave();
            }}
            autoFocus
            testid="edit-rubric-name_input"
          />
          {error && <ErrorInline error={error} />}
        </div>
      </ClickAwayListener>
    );
  };

  if (isEditingName) {
    return displayEditingName();
  }
  return displayNormalTitle();
};

export default connect(null, { updateRubric })(RubricName);
