import { getFileExtentionsAllowed } from 'components/ActivityEdit/util';
import { FIELD_NAME as WEIGHT } from 'components/ActivityEdit/AdvancedOptions/Fields/Weight/constants';
import { FIELD_NAME as TITLE } from 'components/ActivityEdit/Details/Fields/Title/constants';
import { FIELD_NAME as OBJECTIVE } from 'components/ActivityEdit/Details/Fields/Objective/constants';
import { FIELD_NAME as INSTRUCTIONS } from 'components/ActivityEdit/Details/Fields/Instructions/constants';
import { FIELD_NAME as RUBRIC } from 'components/ActivityEdit/Details/Rubric/constants';
import { FIELD_NAME as EVALUATOR_NOTES } from 'components/ActivityEdit/Details/Fields/Notes/constants';
import {
  HAS_EVALUATOR_NOTES,
  EVALUATOR_NOTES_FILES,
} from 'components/ActivityEdit/Details/Fields/Notes/constants';
import { fileRestrictionStatus } from '@kritik/constants/activity';
import { FIELD_NAME as removeStudentFromActivityAfterMissedCreation } from 'components/ActivityEdit/AdvancedOptions/Fields/MissedCreation/constants';
import {
  ACCEPT_LATE_CREATIONS,
  AUTO_ACCEPT_LATE_CREATIONS,
} from 'components/LateCreation/constants';

export const getFormattedFormValues = (values: any, courseId: any) => {
  const requireFile =
    values.restrictFileTypes === fileRestrictionStatus.ALL ? false : values.requireFile;
  const data = {
    [TITLE]: values[TITLE],
    files: values.files,
    activityType: values.activityType,
    [OBJECTIVE]: values[OBJECTIVE],
    [INSTRUCTIONS]: values[INSTRUCTIONS],
    numericGrading: values.isNumericGrading,
    [RUBRIC]: {
      ...values[RUBRIC],
      course: courseId,
    },
    course: courseId,
    [WEIGHT]: values[WEIGHT],
    fileExtensionsAllowed: getFileExtentionsAllowed(values),
    requireFile,
    [HAS_EVALUATOR_NOTES]: values.hasEvaluatorNotes,
    [EVALUATOR_NOTES]: values.evaluatorNotes,
    [EVALUATOR_NOTES_FILES]: values.evaluatorNotesFiles,
    isGroupActivity: values.isGroupActivity,
    [removeStudentFromActivityAfterMissedCreation]: false,
    startingScore: values.startingScore,
    [ACCEPT_LATE_CREATIONS]: requireFile ? values.acceptLateSubmissions : false,
    [AUTO_ACCEPT_LATE_CREATIONS]: requireFile ? values.autoAccept : false,
  };
  return data;
};

export default {
  getFormattedFormValues,
};
