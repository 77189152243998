import React from 'react';
import FindCreation from './FindCreation';
import CourseProvider from '../context/CourseContext';
import CreationProvider from '../context/CreationContext';
import AssignmentProvider from '../context/AssignmentContext';
import AddNewEvalContainer from './AddNewEval/AddNewEvaluationContainer';
import CloseButton from '../CloseButton';

const LateEvaluationModal = (props: any) => {
  return (
    <div className="admin-panel-modal">
      <section className="admin-panel-modal__main admin-panel-modal__scrollable">
        <h1>Manage Evaluations</h1>
        <CreationProvider>
          <CourseProvider>
            <AssignmentProvider>
              <FindCreation />
              <AddNewEvalContainer />
            </AssignmentProvider>
          </CourseProvider>
        </CreationProvider>
      </section>
      <CloseButton onClose={props.handleClose} />
    </div>
  );
};

export default LateEvaluationModal;
