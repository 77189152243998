import React from 'react';
import STATUS_ICON from 'images/assignment-schedule';
import { getActionableStatusList } from '@kritik/utils/stage';
import StatusNode from 'components/ActivityCard/Schedule/StatusNode';
import StatusLabel from 'components/ActivityCard/Schedule/StatusNode/Label';

const ArrowSeparator = () => {
  return (
    <img className="ltr-schedule__arrow" aria-hidden="true" src={STATUS_ICON.HORIZONTAL_ARROW} />
  );
};

const StatusList = ({ activity, isStudent }: { activity: any; isStudent: boolean }) => {
  const statusList = getActionableStatusList(activity, isStudent);

  const isLastStatus = (index: any) => {
    return index === statusList.length - 1;
  };

  const renderStatuses = () => {
    return statusList.map((status, index) => {
      return (
        <React.Fragment key={index}>
          <td>
            <StatusNode status={status} activity={activity} />
          </td>
          {!isLastStatus(index) && (
            <td>
              <ArrowSeparator />
            </td>
          )}
        </React.Fragment>
      );
    });
  };

  const renderLabel = () => {
    return statusList.map((status, index) => {
      return (
        <React.Fragment key={index}>
          <td>
            <StatusLabel status={status} activity={activity} />
          </td>
          {!isLastStatus(index) && <td />}
        </React.Fragment>
      );
    });
  };

  return (
    <div className="ltr-schedule">
      <table role="presentation">
        <tbody>
          <tr className="ltr-schedule__status-icons">{renderStatuses()}</tr>
          <tr className="ltr-schedule__status-labels">{renderLabel()}</tr>
        </tbody>
      </table>
    </div>
  );
};

export default StatusList;
