import * as FormatUtils from '@kritik/utils/format';
import HtmlContent from 'components/General/HtmlContent';
import { TranslatedText } from 'components/TranslatedText';
import NoticeBoard from 'components/layout/NoticeBoard';

type StudentLateSubmissionNoticeBoardProps = {
  testid: string;
  type: 'pending' | 'accepted' | 'rejected';
  stageEndDate: Date;
  lateSubmission: {
    createdAt: Date;
    resolvedDate: Date;
    reason: string;
  };
  submissionType: 'creation' | 'evaluation';
  lateCreationPenaltyPercentage?: number;
};

export function StudentLateSubmissionNoticeBoard(props: StudentLateSubmissionNoticeBoardProps) {
  const submissionAcceptedText =
    props.submissionType === 'creation'
      ? 'StudentView.StudentLateSubmissionNoticeBoard.Creation.Title.Accepted'
      : 'StudentView.StudentLateSubmissionNoticeBoard.Evaluation.Title.Accepted';

  const submissionRejectedText =
    props.submissionType === 'creation'
      ? 'StudentView.StudentLateSubmissionNoticeBoard.Creation.Title.Rejected'
      : 'StudentView.StudentLateSubmissionNoticeBoard.Evaluation.Title.Rejected';

  const displayLatePenaltyWarning =
    props.lateCreationPenaltyPercentage > 0 && props.type !== 'rejected';

  return (
    <NoticeBoard
      type={props.type === 'accepted' ? 'success' : props.type === 'pending' ? 'caution' : 'danger'}
      testid={props.testid}
    >
      <div>
        <strong>
          <TranslatedText
            i18nKey={
              props.type === 'accepted'
                ? submissionAcceptedText
                : props.type === 'pending'
                  ? 'StudentView.StudentLateSubmissionNoticeBoard.Title.Pending'
                  : submissionRejectedText
            }
          />
        </strong>
      </div>
      <div>
        <TranslatedText
          i18nKey="StudentView.StudentLateSubmissionNoticeBoard.SubmittedOn"
          values={{
            howLate: FormatUtils.displayTimeBetweenDates(
              props.lateSubmission.createdAt,
              props.stageEndDate
            ),
            submittedOn: FormatUtils.exactDate(props.lateSubmission.createdAt),
          }}
        />
      </div>
      <div>
        {props.type === 'accepted' && (
          <TranslatedText
            i18nKey="StudentView.StudentLateSubmissionNoticeBoard.AcceptedOn"
            values={{
              acceptedOn: FormatUtils.exactDate(props.lateSubmission.resolvedDate),
            }}
          />
        )}
        {props.type === 'rejected' && (
          <TranslatedText
            i18nKey="StudentView.StudentLateSubmissionNoticeBoard.RejectedOn"
            values={{
              rejectedOn: FormatUtils.exactDate(props.lateSubmission.resolvedDate),
            }}
          />
        )}
      </div>
      {displayLatePenaltyWarning && (
        <div>
          <br />
          <TranslatedText
            i18nKey={
              props.type === 'accepted'
                ? 'StudentView.StudentLateSubmissionNoticeBoard.Creation.Accepted.LatePenalty'
                : 'StudentView.StudentLateSubmissionNoticeBoard.Creation.Pending.LatePenalty'
            }
            values={{ value: `${props.lateCreationPenaltyPercentage}` }}
          />
        </div>
      )}
      <br />
      <div>
        <div>
          <strong>
            <TranslatedText i18nKey="StudentView.StudentLateSubmissionNoticeBoard.Reason" />
          </strong>
        </div>
        <HtmlContent content={props.lateSubmission.reason} />
      </div>
    </NoticeBoard>
  );
}
