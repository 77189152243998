import React, { useState, useEffect } from 'react';
import CouponTable from 'components/Admin/CouponDashboard/CouponTable';
import { getCouponData } from 'actions/admin';
import { connect } from 'react-redux';
import Spinner from 'components/Loaders/Spinner';

const CouponDashboard = (props: any) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const { couponData } = props;

  const fetchIntialData = () => {
    setIsFetching(true);
    setTimeout(async () => {
      try {
        await props.getCouponData();
      } catch (err) {
        console.log(err);
      }
    }, 500);
    setIsFetching(false);
    setIsPageLoading(false);
  };

  useEffect(() => {
    fetchIntialData();
  }, []);

  const fetchNewData = async () => {
    if (!couponData.has_more) {
      return;
    }
    setIsFetching(true);
    try {
      await props.getCouponData({ starting_after: couponData.data[couponData.data.length - 1].id });
    } catch (err) {
      console.log(err);
    }
    setIsFetching(false);
  };

  return (
    <React.Fragment>
      {couponData && !isPageLoading ? (
        <div>
          <h1> Coupon Dashboard </h1>
          <CouponTable
            couponData={couponData}
            fetchNewData={fetchNewData}
            isFetching={isFetching}
          />
        </div>
      ) : (
        <Spinner />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    couponData: state.admin.couponData,
  };
};

export default connect(mapStateToProps, { getCouponData })(CouponDashboard);
