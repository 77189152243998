class StorageClient {
  namespace: any;
  saveItem(key: any, value: any, options = {}) {
    return new Promise((resolve, reject) => {
      resolve(localStorage.setItem(key, value));
    });
  }

  getItem(key: any, options: any) {
    return new Promise((resolve, reject) => {
      resolve(localStorage.getItem(key));
    });
  }

  constructor(config: any) {
    this.namespace = config.namespace || 'app';
  }
}

export const createStorageClient = () => {
  return {
    withConfig: (config: any) => {
      return new StorageClient(config);
    },
  };
};
