import * as _ from 'lodash-es';

const getCreation = (item: any) => {
  return item.creation;
};

const getDisputedDate = (item: any) => {
  const creation = getCreation(item);
  return creation.dispute.disputedOn;
};

export const sortByDisputedDate = (itemList: any, ascending: any) => {
  const _itemList = itemList.filter((item) => {
    const itemCreation = getCreation(item);
    return itemCreation.dispute.status !== 'No dispute';
  });
  return _.orderBy(
    _itemList,
    [
      function (item) {
        return getDisputedDate(item);
      },
    ],
    [ascending ? 'asc' : 'desc']
  );
};
