import { Course } from '@kritik/types.generated';
import { CourseListReturn } from 'app-types';
import { truncateTitle } from 'common/utils/course';

export const formatPartialCourseBreadcrumb = (course: Course | CourseListReturn) => {
  let courseTitle = course.code || truncateTitle(course.title);

  if (course.section) {
    courseTitle += ` - ${course.section}`;
  }
  if (course.term) {
    courseTitle += ` - ${course.term}`;
  }
  return courseTitle;
};

export const formatFullCourseBreadcrumb = (course: Course | CourseListReturn) => {
  let courseTitle = '';

  if (course.code) {
    courseTitle += `${course.code}`;
  }

  if (courseTitle.length > 0) {
    courseTitle += ` - ${course.title}`;
  } else {
    courseTitle += course.title;
  }

  if (course.section) {
    courseTitle += ` - ${course.section}`;
  }
  if (course.term) {
    courseTitle += ` - ${course.term}`;
  }
  return courseTitle;
};

// Converts a string representation of a financial amount into cents
// eg: 24.99 -> 2499
export function priceToCents(price: string) {
  if (price === '') {
    return 0;
  }
  const twoDecimalPlaces = Number.parseFloat(price).toFixed(2);
  return Math.round(Number.parseFloat(twoDecimalPlaces) * 100);
}
