import { useState } from 'react';
import Button from 'components/buttons/Button';
import FormTextInput from 'components/core/form/TextField';
import FormPassword from 'components/core/form/PasswordField';
import { impersonateUserMutation } from 'hooks/admin';
import { InlineInformation } from 'components/layout';
import PageHeader from 'components/layout/PageHeader';

const ImpersonateUser = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const impersonateUser = impersonateUserMutation();

  function handleImpersonateUser(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    impersonateUser.mutate(
      { email, password },
      {
        onSuccess: () => {
          window.location.href = `/`;
        },
        onError: (response: any) => {
          setError(response.response.data.errors.message);
        },
      }
    );
  }

  function handleChange(event: any, setter: any) {
    setError(null);
    setter(event.currentTarget.value);
  }

  return (
    <>
      <PageHeader title="Impersonate User" />
      <form>
        <FormTextInput
          autoFocus
          name="email"
          label="Email of user to impersonate"
          value={email}
          onChange={(e: any) => handleChange(e, setEmail)}
        />
        <FormPassword
          name="password"
          label="Your Kritik Password"
          value={password}
          onChange={(e: any) => handleChange(e, setPassword)}
        />
        {error && (
          <div style={{ marginBottom: '1em' }}>
            <InlineInformation type="danger" title={error} />
          </div>
        )}
        <Button type="primary" inputType="submit" onClick={handleImpersonateUser}>
          Impersonate User
        </Button>
      </form>
    </>
  );
};

export default ImpersonateUser;
