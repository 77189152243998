import { createRestApiClient } from 'utils/createRestApiClient';

const apiEndpoint = import.meta.env.VITE_API_URL;

export default (options = {}) => {
  const client =
    (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint });
  return {
    get: (params: any) => {
      return client.request({
        method: 'GET',
        url: `/evaluations/${params.id}`,
        params,
      });
    },
    getStudentEvaluationsInActivity: (params: any) => {
      return client.request({
        method: 'GET',
        url: `/evaluations/activity/${params.activityId}/student/${params.studentId}`,
        params,
      });
    },
    getStudentsLateEvaluationsInActivity: (params: {
      activityId: string;
      studentIds: string[];
    }) => {
      return client.request({
        method: 'POST',
        url: `/evaluations/late/activity/${params.activityId}`,
        data: {
          studentIds: params.studentIds,
        },
      });
    },
    assign: ({ studentId, creationIds, sendNotification }: any) => {
      return client.request({
        method: 'POST',
        url: `/evaluations/assign`,
        data: {
          studentId,
          creationIds,
          sendNotification,
        },
      });
    },
    removeEvaluation: ({
      comment,
      evaluation,
      activityId,
    }: {
      comment: string;
      evaluation: any;
      activityId: string;
    }) => {
      return client.request({
        method: 'DELETE',
        url: `/evaluations/${evaluation._id}`,
        params: { comment, activityId },
      });
    },
    restoreEvaluation: ({ evaluation, activityId }: { evaluation: any; activityId: string }) => {
      return client.request({
        method: 'PATCH',
        url: `/evaluations/restore/${evaluation._id}`,
        data: { activityId },
      });
    },
    acceptLateEvaluation: (evaluationId: string) => {
      return client.request({
        method: 'POST',
        url: `/evaluations/late/accept/${evaluationId}`,
      });
    },
    rejectLateEvaluation: (evaluationId: string) => {
      return client.request({
        method: 'POST',
        url: `/evaluations/late/reject/${evaluationId}`,
      });
    },
    createEvaluation: (data: CreateEvaluationArgs) => {
      return client.request({
        method: 'POST',
        url: `/evaluations`,
        data,
      });
    },
    updateEvaluation: (data: UpdateEvaluationArgs) => {
      return client.request({
        method: 'PUT',
        url: `/evaluations/${data.evaluationId}`,
        data,
      });
    },
  };
};

type CreateEvaluationArgs = {
  creationId: string;
  comment: string;
  files: {
    name?: string;
    url?: string;
    size?: number;
  }[];
  highlightedCriteria: number[];
  marks: number[];
  lateEvaluationReason?: string;
};

type UpdateEvaluationArgs = Omit<CreateEvaluationArgs, 'creationId'> & {
  evaluationId: string;
};
