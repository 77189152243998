import moment from 'moment';

const DateCell = (props: any) => {
  const { date } = props;

  const getDate = () => {
    if (!date) {
      return '-';
    }
    return moment(date).format('llll');
  };
  return <div>{getDate()}</div>;
};

export default DateCell;
