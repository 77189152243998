import { useQuery } from '@tanstack/react-query';
import { creationService, evaluationService } from 'services/index';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateCreationInReduxStore } from 'actions/creation';
import { useStore } from 'react-redux';

export function useGetStudentEvaluationsInActivity({
  studentId,
  activityId,
}: {
  studentId: string;
  activityId: string;
}) {
  return useQuery(['getStudentEvaluationsInActivity', studentId, activityId], async () => {
    const response = await evaluationService().getStudentEvaluationsInActivity({
      activityId,
      studentId,
    });
    return response.data;
  });
}

export function useGetStudentsLateEvaluationsInActivity({
  studentIds,
  activityId,
}: {
  studentIds: string[];
  activityId: string;
}) {
  return useQuery(
    ['getStudentLateEvaluationsInActivity', studentIds],
    async () => {
      const response = await evaluationService().getStudentsLateEvaluationsInActivity({
        activityId,
        studentIds,
      });
      return response.data;
    },
    { enabled: studentIds.length > 0, staleTime: 0 }
  );
}

export function useAcceptLateEvaluation() {
  const queryClient = useQueryClient();
  const store = useStore();
  return useMutation(
    async ({ evaluationId, creationId }: { evaluationId: string; creationId: string }) => {
      await evaluationService().acceptLateEvaluation(evaluationId);
      const result = await creationService().get({ id: creationId });
      // this is temporary
      // we need to sync the creation in the redux store because paginated creations are using redux store
      updateCreationInReduxStore(store.dispatch, result.data);
    },
    {
      mutationKey: ['acceptLateEvaluation'],
      onSuccess: async () => {
        await queryClient.invalidateQueries(['getCreationsToEvaluate']);
      },
    }
  );
}

export function useRejectLateEvaluation() {
  const queryClient = useQueryClient();
  const store = useStore();
  return useMutation(
    async ({ evaluationId, creationId }: { evaluationId: string; creationId: string }) => {
      await evaluationService().rejectLateEvaluation(evaluationId);
      const result = await creationService().get({ id: creationId });
      // this is temporary
      // we need to sync the creation in the redux store because paginated creations are using redux store
      updateCreationInReduxStore(store.dispatch, result.data);
    },
    {
      mutationKey: ['rejectLateEvaluation'],
      onSuccess: async () => {
        await queryClient.invalidateQueries(['getCreationsToEvaluate']);
      },
    }
  );
}
