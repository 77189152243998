import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAssignment } from 'selectors/activity';
import { getMySubmission } from 'selectors/creation';
import StatusNode from 'components/ActivityCard/Schedule/StatusNode';
import statusUtil from 'components/ActivityCard/Schedule/StatusNode/utils';
import classNames from 'classnames';
import {
  getActionableStatusListForPresentationActivity,
  getCreationStage,
  getEvaluationStage,
  getFeedbackStage,
  getFinalizedStage,
  getCreationGracePeriodStage,
  getGradingStage,
  hasGracePeriod,
  isScheduled,
  getEvaluationGracePeriodStage,
  hasEvaluationGracePeriod,
  isFinalized,
} from '@kritik/utils/stage';
import { assignmentStatuses } from '@kritik/constants/stage';
import { PanelDisplay } from 'components/layout';
import * as ActivityUtils from '@kritik/utils/activity';
import CreationStatus from 'containers/assignments/overview/CreationStatus';
import GracePeriodDisplay from './GracePeriodDisplay';
import { localize } from 'locales/index';
import { useUserRoleInCourse } from 'hooks/course';
import { UserRoleInCourse } from 'app-types';
import { EvaluationStatus } from './EvaluationStatus';
import { Student } from '@kritik/types.generated';

const mapStateToProps = (state: any) => {
  return {
    assignmentId: state.selected.assignmentId,
    assignment: getAssignment(state, state.selected.assignmentId),
    creation: getMySubmission(state, state.selected.assignmentId),
    user: state.user.authUser,
  };
};

const getMonth = (date: Date) => {
  return moment(date).format('MMM Do ');
};

const getTime = (date: Date) => {
  return moment(date).format('[@] h:mm A');
};

type AssignmentScheduleState = any;
type AssignmentScheduleProps = {
  userRole: UserRoleInCourse;
  assignmentId: string;
  assignment: any;
  user: any;
  creation: any;
  student: Student;
};

class AssignmentSchedule extends Component<AssignmentScheduleProps, AssignmentScheduleState> {
  setActiveStatus: any;
  constructor(props) {
    super(props);
    this.state = {
      currentYear: new Date(Date.now()).getFullYear().toString(),
      // @ts-expect-error TS(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
      currentDate: Date.parse(new Date(Date.now())),
      dueDate: null,
    };
  }

  componentDidMount() {
    /** This ensure that schedule display card always display date time in client timezone
     * as we set a different time zone globally when render schedule page if user client is
     * in different time zone from its institution
     */
    moment.tz.setDefault();
  }

  componentDidUpdate(prevProps: AssignmentScheduleProps, prevState: AssignmentScheduleState) {
    if (prevProps.assignmentId !== this.props.assignmentId) {
      this.setActiveStatus();
    }
  }

  renderStatusCards() {
    const { assignment, creation, userRole, student } = this.props;
    const gracePeriodStatus = getCreationGracePeriodStage(assignment);

    const creationStage = getCreationStage(assignment);
    const evaluationStage = getEvaluationStage(assignment);
    const evaluationGraceStage = getEvaluationGracePeriodStage(assignment);
    const feedbackStage = getFeedbackStage(assignment);
    const gradingStage = getGradingStage(assignment);

    if (ActivityUtils.isCalibrationActivity(assignment)) {
      if (creation) {
        creation.submissionStatus = 'ON_TIME';
      }
      <StatusCard
        status={evaluationStage}
        position={'last'}
        activity={assignment}
        userRole={userRole}
        creation={creation}
      />;
    }

    if (ActivityUtils.isPresentationActivity(assignment) && userRole.isStudentInCourse) {
      const statuses = getActionableStatusListForPresentationActivity(assignment);
      return (
        <ul>
          {statuses.map((status, index) => (
            <StatusCard
              key={`${status.name}-${index}`}
              status={status}
              position={index === statuses.length - 1 ? 'last' : 'middle'}
              activity={assignment}
              userRole={userRole}
              creation={creation}
            />
          ))}
        </ul>
      );
    }

    return (
      <ul>
        <StatusCard
          status={creationStage}
          position={'middle'}
          activity={assignment}
          userRole={userRole}
          creation={creation}
        />
        {hasGracePeriod(assignment) && this.props.userRole.isInstructorInCourse && (
          <GracePeriodDisplay
            state={statusUtil.getStageStatus(gracePeriodStatus, assignment)}
            gracePeriodStatus={gracePeriodStatus}
          />
        )}
        <StatusCard
          status={evaluationStage}
          position={'middle'}
          activity={assignment}
          userRole={userRole}
          creation={creation}
        />
        {hasEvaluationGracePeriod(assignment) && this.props.userRole.isInstructorInCourse && (
          <GracePeriodDisplay
            state={statusUtil.getStageStatus(evaluationGraceStage, assignment)}
            gracePeriodStatus={evaluationGraceStage}
            testid="evaluate-grace-period-display"
          />
        )}
        <StatusCard
          status={feedbackStage}
          position={'middle'}
          activity={assignment}
          userRole={userRole}
          creation={creation}
        />
        <StatusCard
          status={gradingStage}
          position={'last'}
          activity={assignment}
          userRole={userRole}
          creation={creation}
        />
      </ul>
    );
  }

  render() {
    if (!this.props.assignmentId) {
      return null;
    }

    return (
      <PanelDisplay>
        <h3 className="schedule--main-header">Schedule</h3>
        {this.renderStatusCards()}
      </PanelDisplay>
    );
  }
}

const StatusCard = ({ status, position, activity, userRole, creation }) => {
  const { isStudentInCourse, isInstructorInCourse } = userRole;
  const stageStatus = statusUtil.getStageStatus(status, activity);

  // for fixing if there is grace period label, the line between creation and evaluation should extend
  const shouldExtendLine =
    ((hasGracePeriod(activity) && status.name === assignmentStatuses.CREATE) ||
      (hasEvaluationGracePeriod(activity) && status.name === assignmentStatuses.EVALUATE)) &&
    isInstructorInCourse;

  const cardClasses = classNames('schedule--card', stageStatus, position, {
    extend: shouldExtendLine,
  });

  const cardClassesStatusDates = classNames('card-header--status-dates', stageStatus);
  const statusName = status.name === assignmentStatuses.PROCESSING3 ? 'Grading' : status.name;

  return (
    <li>
      <div className={`card-header ${cardClasses}`} tabIndex={0}>
        <div className="schedule-activity-stage-icon" tabIndex={0}>
          <StatusNode status={status} activity={activity} />
        </div>
        <div className="card-header--info" tabIndex={0}>
          <div className="card-header--status-title">{statusName}</div>
          {isStudentInCourse && statusName === assignmentStatuses.CREATE && (
            <CreationStatus activity={activity} creation={creation} />
          )}
          {isStudentInCourse &&
            statusName === assignmentStatuses.EVALUATE &&
            activity.hasGraceEvaluations && <EvaluationStatus />}
          <div className={cardClassesStatusDates}>
            <ScheduleDate status={status} dateStatus="startDate" activity={activity} />
            <span className="card-header--divider"> - </span>
            <ScheduleDate status={status} dateStatus="endDate" activity={activity} />
          </div>
        </div>
      </div>
      <div className="card-body" />
    </li>
  );
};

const ScheduleDate = ({ status, dateStatus, activity }) => {
  let date = dateStatus === 'startDate' ? status.startDate : status.endDate;

  if (status.name === assignmentStatuses.PROCESSING3) {
    const isActivityFinalized = isFinalized({ assignment: activity });
    if (!isScheduled(activity)) {
      return <span>{localize({ message: 'Activity.Schedule.NotScheduled' })}</span>;
    }
    if (dateStatus === 'endDate' && !isActivityFinalized) {
      return (
        <span className="card-header--due-date">
          {localize({ message: 'Activity.Schedule.Pending' })}
        </span>
      );
    }
    if (dateStatus === 'startDate') {
      date = getFeedbackStage(activity).endDate;
    }
    if (dateStatus === 'endDate' && isActivityFinalized) {
      date = getFinalizedStage(activity).startDate;
    }
  }

  if (!date) {
    return <span>{localize({ message: 'Activity.Schedule.NotScheduled' })}</span>;
  }
  return (
    <div className="card-header--due-date">
      {getMonth(date)}
      <span className="card-header--due-time">{getTime(date)}</span>
    </div>
  );
};

function AssignmentScheduleWrapper(props) {
  const userRole = useUserRoleInCourse();
  return <AssignmentSchedule {...props} userRole={userRole} />;
}

export default connect(mapStateToProps)(AssignmentScheduleWrapper);
