import React from 'react';
import { AssignmentAverageScore, AssignmentPassCriteria } from 'components/Assignment';
import RubricPreview from 'components/Rubric/Preview';
import RubricDropdown from 'components/Rubric/Dropdown';
import { isCalibrationActivity, isWithinGroupActivity } from '@kritik/utils/activity';
import CollapsibleArea from 'components/layout/CollapsibleArea';
import OverviewSection from 'containers/assignments/overview/Section';
import { isFinalized } from '@kritik/utils/stage';
import { useUserRoleInCourse } from 'hooks/course';

function GradeView({ activity }: any) {
  const { isInstructorInCourse } = useUserRoleInCourse();
  if (isInstructorInCourse) {
    const shouldShowActivityAvgScore =
      !isCalibrationActivity(activity) && !isWithinGroupActivity(activity);
    if (shouldShowActivityAvgScore) {
      return (
        <CollapsibleArea title="Creation Score (Class Average)" testid="class-average">
          <AssignmentAverageScore activity={activity} />
        </CollapsibleArea>
      );
    }
    return null;
  }
  return null;
}

function rubricPreview({ activity }: any) {
  const { isInstructorInCourse, isStudentInCourse } = useUserRoleInCourse();
  const isStudentFinalizedView = isStudentInCourse && isFinalized({ assignment: activity });
  if (isStudentFinalizedView) {
    return (
      <OverviewSection title="Rubric" hideSeparator>
        <RubricPreview
          rubric={activity.rubric}
          canSelectRow={!isInstructorInCourse}
          canViewMode={isInstructorInCourse}
        />
      </OverviewSection>
    );
  }
  return (
    <CollapsibleArea title="Rubric" testid="rubric">
      <RubricPreview
        rubric={activity.rubric}
        canSelectRow={!isInstructorInCourse}
        canViewMode={isInstructorInCourse}
      />
    </CollapsibleArea>
  );
}

const AssignmentEvaluation = ({ activity, user }: any) => {
  const { rubric } = activity;
  return (
    <React.Fragment>
      {rubricPreview({ user, activity })}
      <AssignmentPassCriteria assignment={activity} rubric={activity.rubric} />
      <GradeView rubric={rubric} activity={activity} user={user} />
      <RubricDropdown rubric={rubric} />
    </React.Fragment>
  );
};

export default AssignmentEvaluation;
