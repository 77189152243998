import { connect } from 'react-redux';
import { selectTableSettings } from 'redux/creationTable/select';
import { LIST_BY_TYPES } from 'components/CreationList/constant';

const TableTitle = (props: any) => {
  if (props.tableSettings.listBy === LIST_BY_TYPES.GROUP) {
    return 'Groups';
  }
  return 'Students';
};

const mapStateToProps = (state: any) => {
  return {
    tableSettings: selectTableSettings(state),
  };
};

export default connect(mapStateToProps, {})(TableTitle);
