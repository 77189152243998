import StudentParticipationForGroupPresentation from './GroupPresentation';
import StudentParticipationForIndividualPresentation from './IndividualPresentation';

import * as ActivityUtils from '@kritik/utils/activity';

const StudentParticipation = ({ activity, course }) => {
  if (ActivityUtils.isGroupPresentationActivity(activity)) {
    return <StudentParticipationForGroupPresentation activity={activity} course={course} />;
  } else {
    return <StudentParticipationForIndividualPresentation activity={activity} course={course} />;
  }
};

export default StudentParticipation;
