import { schema } from 'normalizr';

import user from 'schemas/user';

const student = new schema.Entity(
  'students',
  {
    user,
  },
  { idAttribute: '_id' }
);

export default student;
