export const getDisputeReason = (creation: any) => {
  return creation.dispute.reason;
};

export const getDisputeInstructorComment = (creation: any) => {
  return creation.dispute.teacherComment;
};

export const getResolvedDate = (creation: any) => {
  return creation.dispute.resolvedOn;
};

export const getDisputedDate = (creation: any) => {
  return creation.dispute.disputedOn;
};

export const getResolvedBy = (creation: any) => {
  return creation.dispute.resolvedBy;
};

export const getResolvedByName = (creation: any) => {
  const resolvedBy = getResolvedBy(creation);
  return resolvedBy.profile.name;
};
