import { createRestApiClient } from 'utils/createRestApiClient';

const apiEndpoint = import.meta.env.VITE_API_URL;

export default (options = {}) => {
  const client =
    (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint });
  return {
    uploadFiles: ({ formData, allowedExtensions }: any) => {
      return client.request({
        method: 'POST',
        url: '/upload-files',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          allowedExtensions,
        },
      });
    },
    create: (params: any) => {
      return client.request({
        method: 'POST',
        url: `/files/create`,
        data: params,
      });
    },
    getByUrl: (url: string) => {
      return client.request({
        method: 'POST',
        url: `/files/get-by-url`,
        data: { url },
      });
    },
  };
};
