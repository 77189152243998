import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { setColumns, setInitialState } from 'redux/creationTable/actions';
import {
  selectSortedColumn,
  selectTableSettings,
  selectCreationTableFilter,
  selectCreationTableSearch,
  selectIsCreationTableLoading,
} from 'redux/creationTable/select';
import Table from 'components/CreationList/Table';
import Row from 'components/CreationList/ScoringTable/Row';
import localConstants from 'components/CreationList/ScoringTable/constants';
import { getCourse } from 'selectors/course';
import studentSelector from 'selectors/student';
import creationSelector from 'selectors/creation';
import submissionSelector from 'selectors/creation';
import GroupSelectors from 'selectors/group';
import ActivitySelectors from 'selectors/activity';
import * as activityUtils from '@kritik/utils/activity';
import { TABLE_TYPE_OPTIONS } from 'components/CreationList/constant';
import tableUtil from 'components/CreationList/util';
import { getActivityScores } from 'redux/activityScores/actions';
import Spinner from 'components/Loaders/Spinner';
import ActivityScoresSelectors from 'redux/activityScores/select';
import { setCurrentPage } from 'redux/creationTable/actions';
import { navigateToActivityPage } from 'actions/activity';
import getPaginatedResults from 'actions/pagination/actions';
import { usePrevious } from 'hooks/usePrevious';

function ScoringTable(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const tableType = TABLE_TYPE_OPTIONS.SCORING;
    let columns = localConstants.STUDENT_COLUMNS.PEER;
    if (activityUtils.isCalibrationActivity(props.activity)) {
      // @ts-expect-error TS(2322) FIXME: Type '({ header: string; align: string; isSortable... Remove this comment to see the full error message
      columns = localConstants.STUDENT_COLUMNS.CALIBRATION;
    } else if (
      activityUtils.isGroupAssignment(props.activity) ||
      activityUtils.isGroupPresentationActivity(props.activity)
    ) {
      // @ts-expect-error TS(2322) FIXME: Type '({ header: string; align: string; isSortable... Remove this comment to see the full error message
      columns = tableUtil.getColumnsToRender(
        TABLE_TYPE_OPTIONS.SCORING,
        props.tableSettings.listBy
      );
    }
    props.setInitialState({ columns, type: tableType });
  }, []);
  const prevProps = usePrevious({
    listBy: props.tableSettings.listBy,
  });
  useEffect(() => {
    if (
      activityUtils.isGroupAssignment(props.activity) ||
      activityUtils.isGroupPresentationActivity(props.activity)
    ) {
      if (prevProps && (prevProps as any).listBy !== props.tableSettings.listBy) {
        const columns = tableUtil.getColumnsToRender(
          TABLE_TYPE_OPTIONS.SCORING,
          props.tableSettings.listBy
        );
        props.setColumns(columns);
      }
    }
  }, [props.tableSettings.listBy]);

  useEffect(() => {
    if (
      !props.isActivityScoresLoaded &&
      (activityUtils.isGroupAssignment(props.activity) ||
        activityUtils.isGroupPresentationActivity(props.activity))
    ) {
      setIsLoading(true);
      props.getActivityScores(props.activity._id).then(() => {
        setIsLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    props.setCurrentPage(1);
    props.navigateToActivityPage({ courseId: props.courseId, assignmentId: props.activityId });
    props.getPaginatedResults();
  }, []);

  const renderContent = () => {
    if (isLoading || props.isLoadingCreationTable) {
      return (
        <div className="scoring-creation-table__loader--active">
          <Spinner />
        </div>
      );
    }

    return (
      <Table
        id={`scoring-creation-table-${props.activity._id}`}
        style={{ minWidth: '768px' }}
        rowGenerator={Row}
        rowList={props.itemList}
      />
    );
  };
  return renderContent();
}

const mapStateToProps = (state: any) => {
  const itemList = tableUtil.generateRowListData()(
    {
      CreationSelectors: creationSelector,
      SubmissionSelectors: submissionSelector,
      GroupSelectors,
      StudentSelectors: studentSelector,
      ActivitySelectors,
    },
    state
  );
  return {
    itemList,
    filter: selectCreationTableFilter(state),
    search: selectCreationTableSearch(state),
    sortedColumn: selectSortedColumn(state),
    activity: ActivitySelectors.getAssignment(state, state.selected.assignmentId),
    tableSettings: selectTableSettings(state),
    course: getCourse(state),
    isActivityScoresLoaded: ActivityScoresSelectors.isActivityScoresLoaded(state),
    isLoadingCreationTable: selectIsCreationTableLoading(state),
    courseId: state.selected.courseId,
    activityId: state.selected.assignmentId,
  };
};

export default connect(mapStateToProps, {
  setInitialState,
  setColumns,
  getActivityScores,
  setCurrentPage,
  navigateToActivityPage,
  getPaginatedResults,
})(ScoringTable);
