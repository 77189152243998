import { getCourse as selectCourse } from 'selectors/course';
import { combineReducers } from 'redux';

import types from './types';

const lineItemInitState = {
  items: [],
  status: '',
  message: '',
};

function lineItemsReducer(state = lineItemInitState, action: any) {
  switch (action.type) {
    case types.GET_LINE_ITEMS_REQUEST:
      return {
        ...state,
        status: 'pending',
      };
    case types.GET_LINE_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        status: 'success',
      };
    case types.GET_LINE_ITEMS_FAILURE:
      return {
        ...state,
        status: 'fail',
      };
    default:
      return state;
  }
}

const createLineItemState = {
  status: '',
  lineItem: null,
  message: '',
};

function createLineItemReducer(state = createLineItemState, action: any) {
  switch (action.type) {
    case types.CREATE_LINE_ITEM_REQUEST:
      return {
        ...state,
        status: 'pending',
      };
    case types.CREATE_LINE_ITEM_SUCCESS:
      return {
        ...state,
        status: 'success',
        lineItem: action.payload.lineItem,
      };
    case types.CREATE_LINE_ITEM_FAILURE:
      return {
        ...state,
        status: 'fail',
        message: action.payload.message,
      };
    default:
      return state;
  }
}

export const selectLineItems = (state: any) => {
  return state.lms.lineItems.items;
};

export const selectDefaultLineItem = (state: any) => {
  const course = selectCourse(state);
  if (!course || !course.lms.defaultLineItemIdLink) {
    return null;
  }

  const lineItems = selectLineItems(state);
  const defaultLineItem = lineItems.find((item: any) => {
    return item.id == course.lms.defaultLineItemIdLink;
  });
  return defaultLineItem;
};

export default combineReducers({
  lineItems: lineItemsReducer,
  createLineItem: createLineItemReducer,
});
