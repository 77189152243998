import React from 'react';
import PopoverTab from 'components/Navigation/PopoverTab';
import { getInitials } from '@kritik/utils/format';
import { localize } from 'locales';
import { formatPartialCourseBreadcrumb } from 'utils/format';

type Props = {
  activeRegex: RegExp[];
  course: any;
  destination: string;
};

const CourseInfoTab = ({ activeRegex, course, destination }: Props) => {
  return (
    <PopoverTab
      destination={destination}
      icon={<i className="fa fa-book-open" />}
      activeRegex={activeRegex}
      label={localize({ message: 'Navigation.Tabs.CourseInfo.Label' })}
      content={
        <div id="course-menu-popup">
          <div className="course-info">{getInitials(course.title)}</div>
          <div className="description">
            <h4>{formatPartialCourseBreadcrumb(course)}</h4>
            <div>{course.department}</div>
          </div>
        </div>
      }
      testid="course-info"
    />
  );
};
export default CourseInfoTab;
