import * as types from 'types';

export const resetAll = () => {
  return { type: types.SELECT_RESET, payload: null };
};
export const selectAssignment = (assignmentId: any) => {
  return { type: types.SELECT_ASSIGNMENT, payload: assignmentId };
};
export const selectCourse = (courseId: any) => {
  return { type: types.SELECT_COURSE, payload: courseId };
};
export const selectSubmission = (submissionId: any) => {
  return { type: types.SELECT_SUBMISSION, payload: submissionId };
};
export const resetSubmission = () => {
  return { type: types.SELECT_SUBMISSION, payload: null };
};
export const selectGroupSet = (groupSetId: any) => {
  return { type: types.SELECT_GROUP_SET, payload: groupSetId };
};

export default {
  resetAll,
  resetSubmission,
  selectAssignment,
  selectCourse,
  selectSubmission,
  selectGroupSet,
};
