import * as _ from 'lodash-es';

const getStudent = (item: any) => {
  return item.student;
};
const getCreation = (item: any) => {
  return item.creation;
};

export const sortByStudentName = (
  itemList: any[],
  ascending: boolean,
  activity?: CommonActivity
) => {
  return _.orderBy(
    itemList,
    [
      function (item) {
        const student = getStudent(item);
        return student.user.profile.lastName.toLowerCase();
      },
    ],
    [ascending ? 'asc' : 'desc']
  );
};

export const sortByGroupName = (itemList: any, ascending: any) => {
  return _.orderBy(
    itemList,
    [
      function (item) {
        const creation = getCreation(item);
        return creation.group.name.toLowerCase();
      },
    ],
    [ascending ? 'asc' : 'desc']
  );
};
