import React from 'react';
import { getBadgeUrl } from 'utils/tags';
import { tags as TAGS } from '@kritik/constants/tags';
import Banner from 'components/core/Banner';

const Icon = ({ src, alt }: any) => {
  return <img className="spotlight-list-empty__icon" alt={alt} src={src} />;
};

const SpotlightList = () => {
  const renderIconList = () => {
    return (
      <div className="spotlight-list-empty__icon-list">
        <Icon src={getBadgeUrl(TAGS.CRITICAL)} alt="critical icon" />
        <Icon src={getBadgeUrl(TAGS.ABOVE_AND_BEYOND)} alt="above and beyond icon" />
        <Icon src={getBadgeUrl(TAGS.INNOVATIVE)} alt="innovative icon" />
      </div>
    );
  };
  return (
    <Banner
      image={renderIconList()}
      title="Turn student answers into teaching moments"
      description="You can spotlight a students’ creation or evaluation. Add comments and share anonymously with your students."
    />
  );
};

export default SpotlightList;
