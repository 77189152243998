import * as types from 'types';

export function dismissMessage() {
  return { type: types.DISMISS_MESSAGE };
}

export function setAccessibilityMessage(message: any) {
  return { type: types.SET_ACCESSIBILITY_MESSAGE, payload: { message } };
}

export default { dismissMessage, setAccessibilityMessage };
