import GridContainer from 'components/layout/GridContainer/index';
import StudentPermissionsOption from './StudentPermissionsOption';
import { DefaultActivitySettings } from './DefaultActivitySettings';

const CourseSettingOptions = ({ course }: any) => {
  return (
    <GridContainer
      nodes={[
        <DefaultActivitySettings course={course} />,
        <StudentPermissionsOption course={course} />,
      ]}
    />
  );
};

export default CourseSettingOptions;
