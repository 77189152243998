import React from 'react';
import { connect } from 'react-redux';
import { getStudents, getStudentFromUser } from 'selectors/student';
import * as GroupUtils from '@kritik/utils/group';
import { changeName, deleteEmptyGroup } from 'actions/groups';
import InlineInformation from 'components/layout/InlineInformation';
import GroupMember from 'components/GroupManager/GroupMember';
import {
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Button from 'components/buttons/Button';
import { useUserRoleInCourse } from 'hooks/course';
import { UserRoleInCourse } from 'app-types';
import { withUserRole } from 'utils/withUserRole';
import { Create, Check, Clear, MoreVert, Error } from '@material-ui/icons';
import { TranslatedText } from 'components/TranslatedText';
import { Group } from '@kritik/types.generated';

const CardMenu = ({ onChangeName, onDelete, group, disabled }: any) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { isInstructorInCourse } = useUserRoleInCourse();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="group-display-card__menu">
      {isInstructorInCourse && !disabled && (
        <button
          className="group-display-card__header-button"
          onClick={onChangeName}
          data-testid="edit-group-name-pencil"
        >
          <Create />
        </button>
      )}
      {isInstructorInCourse && group.members.length == 0 && (
        <div>
          <button
            className="group-member-menu__switch"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            data-testid="group-menu"
          >
            <MoreVert />
          </button>
          <Menu
            id="simple-menu"
            className="group-member-menu__popup"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem
              className="group-member-menu__popup-item"
              onClick={onDelete}
              data-testid="delete-group"
            >
              <TranslatedText i18nKey="Delete" />
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    courseId: state.selected.courseId,
    user: state.user,
    student: getStudentFromUser(state, state.selected.courseId),
    studentList: getStudents(GroupUtils.getGroupMemberIds(ownProps.group), state.entities),
  };
};

type GroupCardState = any;

type GroupCardProps = {
  changeName: ({ groupId, newName }: { groupId: string; newName: string }) => Promise<any>;
  deleteEmptyGroup: ({ groupId }: { groupId: string }) => Promise<any>;
  disabled: boolean;
  group: Group;
  groups: Group[];
  readOnly?: boolean;
  student: any;
  studentList: any[];
  testid: string;
  user: any;
  userRole: UserRoleInCourse;
};

class GroupCard extends React.Component<GroupCardProps, GroupCardState> {
  constructor(props: GroupCardProps) {
    super(props);
    this.state = {
      groupName: (props as any).group.name,
      isEditingName: false,
      submitting: false,
    };
  }

  isLeader(student: any) {
    return student._id == this.props.group.leader;
  }

  isLeaderViewing() {
    return this.props.student._id == this.props.group.leader;
  }

  sortStudents() {
    const sortedStudents: any = [];
    this.props.studentList.forEach((student: any) => {
      if (student) {
        this.isLeader(student) ? sortedStudents.unshift(student) : sortedStudents.push(student);
      }
    });
    return sortedStudents;
  }

  deleteEmptyGroup() {
    this.props.deleteEmptyGroup({ groupId: this.props.group._id }).then(() => {
      this.toggleDeleteConfirmation();
    });
  }

  toggleDeleteConfirmation() {
    this.setState({ isDeletingEmptyGroup: !this.state.isDeletingEmptyGroup });
  }

  renderConfirmGroupDeletion() {
    return (
      <Dialog
        open={this.state.isDeletingEmptyGroup}
        onClose={() => {
          return this.toggleDeleteConfirmation();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <TranslatedText i18nKey="RosterManager.GroupTab.DeleteGroupModal.Title" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="group-display-card__members--empty">
              <Error />
              <span>
                <TranslatedText i18nKey="RosterManager.GroupTab.DeleteGroupModal.Warning" />
              </span>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              return this.toggleDeleteConfirmation();
            }}
            type="secondary"
            autoFocus
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              return this.deleteEmptyGroup();
            }}
            type="primary"
            testid="delete-empty-group-confirm"
          >
            Delete Empty Group
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  submitNameChange() {
    if (!this.state.submitting) {
      this.setState({ submitting: true });
    }
    this.props
      .changeName({
        groupId: this.props.group._id,
        newName: this.state.groupName,
      })
      .then(() => {
        this.setState({ submitting: false });
        this.toggleNameEdit();
      });
  }

  renderNameEditor() {
    return (
      <div className="group-display-card__header">
        <input
          data-testid="edit-group-name-input"
          className="group-display-card__header-input"
          name="groupName"
          value={this.state.groupName}
          onChange={(ev) => {
            return this.onValueChange(ev);
          }}
        />
        <button
          className="group-display-card__header-button"
          onClick={() => {
            return this.submitNameChange();
          }}
          data-testid="edit-group-name-check"
        >
          <Check />
        </button>
        <button
          className="group-display-card__header-button"
          onClick={() => {
            return this.toggleNameEdit();
          }}
          data-testid="edit-group-name-close"
        >
          <Clear />
        </button>
      </div>
    );
  }

  toggleNameEdit() {
    this.setState({
      isEditingName: !this.state.isEditingName,
      groupName: this.props.group.name,
    });
  }

  onValueChange(ev: any) {
    this.setState({ [ev.target.name]: ev.target.value });
  }

  getTitle() {
    const { group } = this.props;
    let title = group.name;
    if (this.props.studentList.length < 1) {
      title += ' (Empty)';
      return (
        <h3
          className="group-display-card__header-title group-display-card__header-title--empty"
          data-testid="group-card-name-empty"
        >
          {title}
        </h3>
      );
    }
    return (
      <h3 className="group-display-card__header-title" data-testid="group-card-name">
        {title}
      </h3>
    );
  }

  renderCardHeader() {
    const {
      group,
      userRole: { isInstructorInCourse },
    } = this.props;
    if (isInstructorInCourse || this.isLeaderViewing()) {
      if (this.state.isEditingName && !this.props.readOnly) {
        return this.renderNameEditor();
      }
      return (
        <div className="group-display-card__header">
          {this.getTitle()}
          {!this.props.readOnly && (
            <CardMenu
              group={group}
              onChangeName={() => {
                return this.toggleNameEdit();
              }}
              onDelete={() => {
                return this.toggleDeleteConfirmation();
              }}
              disabled={this.props.disabled}
            />
          )}
        </div>
      );
    }
    return <h3 className="header-3">{group.name}</h3>;
  }

  renderMembers() {
    const { group } = this.props;
    if (this.props.studentList.length < 1) {
      return (
        <InlineInformation type="information" testid="empty-groups-info">
          Empty groups are ignored when the group set is used in an activity
        </InlineInformation>
      );
    }
    return this.sortStudents().map((student, idx) => {
      return (
        <GroupMember
          key={`group_card_member_${student.user._id}`}
          student={student}
          isLeader={this.isLeader(student)}
          group={group}
          groups={this.props.groups}
          disabled={this.props.disabled}
          testid={`${this.props.testid}-student-member-${idx}`}
          readOnly={this.props.readOnly}
        />
      );
    });
  }

  render() {
    return (
      <div
        className="group-display-card"
        key={this.props.group._id}
        data-testid={this.props.testid}
      >
        {this.renderCardHeader()}
        {this.renderMembers()}
        {this.state.isDeletingEmptyGroup && this.renderConfirmGroupDeletion()}
      </div>
    );
  }
}

export default withUserRole(
  connect(mapStateToProps, {
    changeName,
    deleteEmptyGroup,
  })(GroupCard)
);
