import { connect } from 'react-redux';
import TutorialBanner from 'components/tutorial/Banner';
import Badge from 'images/icons/svg/BadgeScored';
import { toggleTutorials, TUTORIAL_KEYS } from 'redux/tutorial/actions';
import { selectTutorialSettingsFromUser } from 'redux/tutorial/selectors';
import localUtils from 'components/tutorial/Banner/utils';

export const COPY = {
  key: TUTORIAL_KEYS.SCORE,
  image: <Badge />,
  title: 'How will I be scored in Kritik?',
  description: `Kritik's AI-Driven platform enables fair, accurate and quality
    peer evaluations. Click on 'learn more' to see a detailed breakdown of how
    your grades are calculated.`,
  link: {
    target: 'https://help.kritik.io/en/articles/6388500-how-scoring-works#h_abd6e01af2',
    type: 'url',
  },
};

const Banner = (props: any) => {
  const onClose = () => {
    props.toggleTutorials({
      id: props.authUser.id,
      tutorialLabels: {
        [COPY.key]: false,
      },
    });
  };

  if (!localUtils.shouldRenderTutorial(props.tutorialSettings, COPY.key)) {
    return null;
  }

  return (
    <TutorialBanner
      className={props.className}
      image={COPY.image}
      title={COPY.title}
      description={COPY.description}
      link={COPY.link}
      onClose={onClose}
    />
  );
};

const mapStateToProps = (state: any) => {
  return {
    authUser: state.user.authUser,
    tutorialSettings: selectTutorialSettingsFromUser(state),
  };
};

Banner.defaultProps = {
  className: '',
};

export default connect(mapStateToProps, {
  toggleTutorials,
})(Banner);
