import { normalize } from 'normalizr';
import { mergeEntities, removeEntities, updateEntities } from 'actions/entities';
import errorAction from 'actions/error';
import { selectGroupSet } from 'actions/select';
import { groupService } from 'services';
import { groupSchema, assignedGroupSchema, studentSchema } from 'schemas';
import * as types from 'types';

export function changeName({ groupId, newName }: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'GROUP_CHANGE_NAME' });
    return groupService()
      .changeName({ groupId, newName })
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, groupSchema);

          dispatch(mergeEntities(normalized.entities));
          dispatch({ type: 'GROUP_CHANGE_NAME_SUCCESS' });
        }
      })
      .catch((err: any) => {
        dispatch(errorAction('GROUP_CHANGE_NAME_FAILURE', err));
      });
  };
}

export function changeLeader({ groupId, leaderId }: any, { onSuccess }: any = {}) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'GROUP_CHANGE_LEADER' });
    return groupService()
      .changeLeader({ groupId, leaderId })
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, groupSchema);

          dispatch(mergeEntities(normalized.entities));
          dispatch({ type: 'GROUP_CHANGE_LEADER_SUCCESS' });
          onSuccess?.();
        }
      })
      .catch((err: any) => {
        dispatch(errorAction('GROUP_CHANGE_LEADER_FAILURE', err));
      });
  };
}

export function moveGroupMember({ memberId, oldGroupId, newGroupId, groupSetId }: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'GROUP_MOVE_MEMBER' });
    return groupService()
      .moveMember({ memberId, oldGroupId, newGroupId, groupSetId })
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, [groupSchema]);

          dispatch(updateEntities(normalized.entities));
          dispatch({ type: 'GROUP_MOVE_MEMBER_SUCCESS' });
        }
      })
      .catch((err: any) => {
        const errAction = errorAction('GROUP_MOVE_MEMBER_FAILURE', err);
        dispatch(errAction);
        return errAction.error;
      });
  };
}

export function getAllGroupSets({ courseId }: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'GROUP_GET_ALL_SETS' });
    return groupService()
      .getAllGroupSets({ courseId })
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, [assignedGroupSchema]);

          dispatch(updateEntities(normalized.entities));
          dispatch({ type: 'GROUP_GET_ALL_SETS_SUCCESS' });
        }
      })
      .catch((err: any) => {
        dispatch(errorAction('GROUP_GET_ALL_SETS_FAILURE', err));
      });
  };
}

export function getGroupsByAssignment({ courseId, assignmentId }: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'GROUP_GET_ALL_SETS' });
    return groupService()
      .getGroupsByAssignment({ courseId, assignmentId })
      .then((res: any) => {
        if (res.status === 200) {
          const { groups, students } = res.data;
          let normalized = normalize(groups, [groupSchema]);
          dispatch(mergeEntities(normalized.entities));
          normalized = normalize(students, [studentSchema]);
          dispatch(mergeEntities(normalized.entities));

          dispatch({ type: 'GROUP_GET_ALL_SETS_SUCCESS' });
        }
      })
      .catch((err: any) => {
        dispatch(errorAction('GROUP_GET_ALL_SETS_FAILURE', err));
      });
  };
}

export function generateGroupSet({ courseId, nameOfSet, maxMembersPerGroup }: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: types.GROUP_GENERATE_SET });
    return groupService()
      .generateGroupSet({ courseId, nameOfSet, maxMembersPerGroup })
      .then((res: any) => {
        if (res.status === 200) {
          const { groupSet, groupList } = res.data;

          const normalizedSet = normalize(groupSet, assignedGroupSchema);
          const normalizedGroups = normalize(groupList, [groupSchema]);

          const normalizedResult = {
            ...normalizedSet.entities,
            ...normalizedGroups.entities,
          };
          dispatch(mergeEntities(normalizedResult));
          dispatch({ type: types.GROUP_GENERATE_SET_SUCCESS });
          dispatch(selectGroupSet(groupSet._id));
        }
      })
      .catch((err: any) => {
        dispatch(errorAction(types.GROUP_GENERATE_SET_FAILURE, err));
        return err;
      });
  };
}

export function isGroupSetInUse({ groupSetId }: any) {
  return (dispatch: any, getState: any) => {
    return groupService()
      .isGroupSetInUse({ groupSetId })
      .then((res: any) => {
        return res.data;
      })
      .catch((err: any) => {
        dispatch(errorAction('GROUP_GET_ERROR', err));
      });
  };
}

export function deleteGroupSet({ groupSetId }: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'GROUP_DELETE_SET' });
    return groupService()
      .deleteGroupSet({ groupSetId })
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, assignedGroupSchema);
          dispatch(removeEntities(normalized.entities));
          dispatch({ type: 'GROUP_DELETE_SET_SUCCESS' });
        }
      })
      .catch((err: any) => {
        dispatch(errorAction('GROUP_DELETE_SET_FAILURE', err));
      });
  };
}

export function deleteEmptyGroup({ groupId }: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'GROUP_DELETE' });
    return groupService()
      .deleteEmptyGroup({ groupId })
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, assignedGroupSchema);

          dispatch(updateEntities(normalized.entities));
          dispatch({ type: 'GROUP_DELETE_SUCCESS' });
        }
      })
      .catch((err: any) => {
        dispatch(errorAction('GROUP_DELETE_FAILURE', err));
      });
  };
}

export default {
  changeName,
  changeLeader,
  moveGroupMember,
  getAllGroupSets,
  generateGroupSet,
  isGroupSetInUse,
  deleteGroupSet,
  deleteEmptyGroup,
  getGroupsByAssignment,
};
