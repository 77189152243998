import { useMemo } from 'react';
import { connect } from 'react-redux';
import SpotlightCardView from 'components/Spotlight/View/Card';
import { push } from 'router';
import EmptySpotlightList from 'components/Spotlight/List/Empty';

const SpotlightList = (props: any) => {
  const sortSpotlights = (s1: any, s2: any) => {
    const date1 = new Date(s1.createdAt);
    const date2 = new Date(s2.createdAt);
    // @ts-expect-error TS(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
    return date2 - date1;
  };

  const recentSpotlights = useMemo(() => {
    return props.spotlights.sort(sortSpotlights);
  }, [props.spotlights]);

  if (props.spotlights.length === 0) {
    return <EmptySpotlightList />;
  }

  return (
    <div className="spotlight-card-list">
      {recentSpotlights.map((spotlight: any, index: number) => {
        return (
          <div className="spotlight-card-list-item">
            <SpotlightCardView
              spotlight={spotlight}
              shortenText
              hideActivityLink={props.hideActivityLink}
              index={index}
            />
          </div>
        );
      })}
    </div>
  );
};

SpotlightList.defaultProps = {
  spotlights: [],
  hideActivityLink: false,
};

const mapStateToProps = (state: any) => {
  return {
    state,
    course: state.selected.courseId,
    assignments: state.entities.assignments,
  };
};

export default connect(mapStateToProps, { push })(SpotlightList);
