import BaseService from 'services/base';

export default class SpotlightTagService extends BaseService {
  list = (params: any) => {
    return this.client.request({
      method: 'GET',
      url: `/spotlightTags`,
      params,
    });
  };
}
