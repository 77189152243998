const MAX_LEVELS = 8;
const MIN_LEVELS = 2;
const MIN_CRITERIA = 1;
const MAX_NAME_LENGTH = 100;
const MIN_NAME_LENGTH = 1;
export default {
  MAX_LEVELS,
  MIN_LEVELS,
  MIN_CRITERIA,
  MAX_NAME_LENGTH,
  MIN_NAME_LENGTH,
};
