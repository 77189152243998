export const RADIO_VALUES = {
  DEFAULT: 'default',
  CUSTOM: 'custom',
};

export const CALIBRATION_OPTIONS = [
  {
    value: RADIO_VALUES.DEFAULT,
    label: 'Default: 0 pt',
  },
  { value: RADIO_VALUES.CUSTOM, label: 'Custom Value', isDisabled: true },
];

export default {
  RADIO_VALUES,
  CALIBRATION_OPTIONS,
};
