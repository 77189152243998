import { assignmentSchema } from 'schemas';
import { getGroupSet } from 'selectors/group';
import { denormalize } from 'normalizr';
import * as _ from 'lodash-es';
import { Activity } from '@kritik/utils/activity';
import { isFinalized } from '@kritik/utils/stage';

export const getAssignment = (state: any, assignmentId?: string) => {
  return denormalize(assignmentId || state.selected.assignmentId, assignmentSchema, state.entities);
};

export const getAssignmentsFromGroupSet = (state: any, groupSetId: any) => {
  const groupSet = getGroupSet(groupSetId, state.entities);
  const { assignments } = state.entities;

  if (!groupSet || _.isEmpty(assignments)) {
    return [];
  }

  // hack to filter out deleted activities' group sets
  const assignmentIds = groupSet.assignments.filter((assignment: any) => {
    return assignments[assignment];
  });

  return denormalize(assignmentIds, [assignmentSchema], state.entities);
};

export const getAssignmentList = (state: any, courseId?: string) => {
  const assignmentIds = state.course.assignments[state.selected.courseId]
    ? state.course.assignments[state.selected.courseId].items
    : [];

  return denormalize(assignmentIds, [assignmentSchema], state.entities);
};

export const getPastAssignments = (state: any, courseId: any) => {
  return getAssignmentList(state, courseId).filter((assignment: any) => {
    return isFinalized({ assignment }) && assignment.course === courseId;
  });
};

export const selectCurrentActivity = (state: any) => {
  const activity = getAssignment(state, state.selected.assignmentId);
  return Activity(activity);
};

export const selectActivityStatsById = (state: any, activityId: any) => {
  return state.assignment.stats[activityId];
};

export const selectActivityStats = (state: any) => {
  return state.assignment.stats;
};

export default {
  getAssignmentList,
  getAssignmentsFromGroupSet,
  getAssignment,
  getPastAssignments,
  selectCurrentActivity,
  selectActivityStatsById,
};
