import React from 'react';
import {
  isCreate,
  isDraft,
  isEvaluate,
  isProcessing1,
  isFinalized,
  isProcessing2,
  isFeedback,
  isGrading,
  getCreationStage,
  studentParticipatesInStageFromInstructorView,
} from '@kritik/utils/stage';
import * as CreationStatusUtil from '@kritik/utils/creation/status';
import * as LateSubmissionUtils from '@kritik/utils/creation/lateCreation';
import { CreationScoreUtil } from '@kritik/utils/grade';
import { Close, ExclamationTriangle } from 'images/icons/fa';
import { isPresentationActivity } from '@kritik/utils/activity';
import { localize } from 'locales/index';

const labels = {
  COMPLETE: {
    label: localize({ message: 'Activity.StudentTable.Cell.Complete' }),
    status: 'no-bg',
  },
  NOT_COMPLETE: {
    label: '0/1',
    status: 'no-bg',
  },
  NOT_GRADED: {
    label: localize({ message: 'Activity.StudentTable.Cell.NotGraded' }),
    status: 'danger',
  },
  NOT_STARTED: {
    label: localize({ message: 'Activity.StudentTable.Cell.NotStarted' }),
    status: 'unavailable',
  },
  GRACE: {
    label: localize({ message: 'Activity.StudentTable.Cell.Complete' }),
    status: 'no-bg',
  },
  MISSED: {
    label: localize({ message: 'Activity.StudentTable.Cell.Missed' }),
    status: 'danger',
  },
  OVERDUE: {
    label: localize({ message: 'Activity.StudentTable.Cell.Overdue' }),
    status: 'danger',
  },
  PROF_GRADED: {
    label: localize({ message: 'Activity.StudentTable.Cell.InstructorGraded' }),
    status: 'success',
  },
  LATE_PENDING: {
    label: localize({ message: 'Activity.StudentTable.Cell.LateCreation' }),
    status: 'caution',
    icon: <ExclamationTriangle />,
  },
  LATE_ACCEPTED: {
    label: localize({ message: 'Activity.StudentTable.Cell.LateAccepted' }),
    status: 'success',
    icon: <ExclamationTriangle />,
  },
  LATE_REJECTED: {
    label: localize({ message: 'Activity.StudentTable.Cell.LateRejected' }),
    status: 'success',
    icon: <Close />,
  },
  NOT_REQUIRED: {
    label: localize({ message: 'Activity.StudentTable.Cell.NotRequired' }),
    status: 'unavailable',
  },
};

export const generateCellLabel = (creation: any, activity: any) => {
  const creationStage = getCreationStage(activity);
  if (
    isPresentationActivity(activity) &&
    !studentParticipatesInStageFromInstructorView({
      stage: creationStage,
      studentId: creation.student,
    })
  ) {
    return labels.NOT_REQUIRED;
  }

  if (isDraft({ assignment: activity })) {
    return labels.NOT_STARTED;
  }

  if (isCreate({ assignment: activity })) {
    if (!CreationStatusUtil.isCompleted(creation)) {
      return labels.NOT_COMPLETE;
    }
    return labels.COMPLETE;
  }

  if (isProcessing1({ assignment: activity })) {
    if (!creation || !CreationStatusUtil.isCompleted(creation)) {
      return labels.NOT_COMPLETE;
    }
    if (LateSubmissionUtils.isLateSubmissionPending(creation)) {
      return labels.LATE_PENDING;
    }
    if (CreationStatusUtil.wasCreationOnTime(creation)) {
      return labels.COMPLETE;
    }
    if (CreationStatusUtil.wasCreationSubmittedInGracePeriod(creation)) {
      return labels.GRACE;
    }
    if (LateSubmissionUtils.isLateSubmissionAccepted(creation)) {
      return labels.LATE_ACCEPTED;
    }
    if (LateSubmissionUtils.isLateSubmissionRejected(creation)) {
      return labels.LATE_REJECTED;
    }
  }

  if (isEvaluate({ assignment: activity }) || isProcessing2({ assignment: activity })) {
    if (!creation) {
      return labels.MISSED;
    }

    if (
      CreationStatusUtil.isCreationEmpty(creation) &&
      !CreationScoreUtil.isInstructorGraded(creation)
    ) {
      return labels.OVERDUE;
    }
    if (LateSubmissionUtils.isLateSubmissionPending(creation)) {
      return labels.LATE_PENDING;
    }
    if (CreationScoreUtil.isInstructorGraded(creation)) {
      return labels.PROF_GRADED;
    }
    if (CreationStatusUtil.wasCreationOnTime(creation)) {
      return labels.COMPLETE;
    }
    if (CreationStatusUtil.wasCreationSubmittedInGracePeriod(creation)) {
      return labels.GRACE;
    }
    if (CreationStatusUtil.wasCreationMissed(creation)) {
      return labels.MISSED;
    }
    if (LateSubmissionUtils.isLateSubmissionAccepted(creation)) {
      return labels.LATE_ACCEPTED;
    }
    if (LateSubmissionUtils.isLateSubmissionRejected(creation)) {
      return labels.LATE_REJECTED;
    }
  }

  if (isFeedback({ assignment: activity })) {
    if (!creation) {
      return labels.MISSED;
    }

    if (
      CreationStatusUtil.isCreationEmpty(creation) &&
      !CreationScoreUtil.isInstructorGraded(creation)
    ) {
      return labels.OVERDUE;
    }
    if (LateSubmissionUtils.isLateSubmissionPending(creation)) {
      return labels.LATE_PENDING;
    }
    if (CreationStatusUtil.isCreationNotGraded(creation)) {
      return labels.NOT_GRADED;
    }
    if (CreationScoreUtil.isInstructorGraded(creation)) {
      return labels.PROF_GRADED;
    }
    if (CreationStatusUtil.wasCreationOnTime(creation)) {
      return labels.COMPLETE;
    }
    if (CreationStatusUtil.wasCreationSubmittedInGracePeriod(creation)) {
      return labels.GRACE;
    }
    if (CreationStatusUtil.wasCreationMissed(creation)) {
      return labels.MISSED;
    }
    if (LateSubmissionUtils.isLateSubmissionAccepted(creation)) {
      return labels.LATE_ACCEPTED;
    }
    if (LateSubmissionUtils.isLateSubmissionRejected(creation)) {
      return labels.LATE_REJECTED;
    }
  }

  if (isGrading({ assignment: activity })) {
    if (!creation) {
      return labels.MISSED;
    }

    if (
      CreationStatusUtil.isCreationEmpty(creation) &&
      !CreationScoreUtil.isInstructorGraded(creation)
    ) {
      return labels.OVERDUE;
    }
    if (LateSubmissionUtils.isLateSubmissionPending(creation)) {
      return labels.LATE_PENDING;
    }
    if (CreationStatusUtil.isCreationNotGraded(creation)) {
      return labels.NOT_GRADED;
    }
    if (CreationScoreUtil.isInstructorGraded(creation)) {
      return labels.PROF_GRADED;
    }
    if (CreationStatusUtil.wasCreationOnTime(creation)) {
      return labels.COMPLETE;
    }
    if (CreationStatusUtil.wasCreationSubmittedInGracePeriod(creation)) {
      return labels.GRACE;
    }
    if (CreationStatusUtil.wasCreationMissed(creation)) {
      return labels.MISSED;
    }
    if (LateSubmissionUtils.isLateSubmissionAccepted(creation)) {
      return labels.LATE_ACCEPTED;
    }
    if (LateSubmissionUtils.isLateSubmissionRejected(creation)) {
      return labels.LATE_REJECTED;
    }
  }

  if (isFinalized({ assignment: activity })) {
    if (!creation) {
      return labels.MISSED;
    }

    if (CreationStatusUtil.isCreationEmpty(creation)) {
      return labels.OVERDUE;
    }
    if (LateSubmissionUtils.isLateSubmissionPending(creation)) {
      return labels.LATE_PENDING;
    }
    if (CreationStatusUtil.wasCreationOnTime(creation)) {
      return labels.COMPLETE;
    }
    if (CreationStatusUtil.wasCreationSubmittedInGracePeriod(creation)) {
      return labels.GRACE;
    }
    if (CreationStatusUtil.wasCreationMissed(creation)) {
      return labels.MISSED;
    }
    if (LateSubmissionUtils.isLateSubmissionAccepted(creation)) {
      return labels.LATE_ACCEPTED;
    }
    if (LateSubmissionUtils.isLateSubmissionRejected(creation)) {
      return labels.LATE_REJECTED;
    }
  }

  return {
    label: '',
  };
};

export default {
  generateCellLabel,
};
