import React from 'react';
import ButtonType from './ButtonType';
import DefaultType from './DefaultType';
import { localize } from 'locales/index';

const RADIO_TYPES = {
  BUTTON: 'button',
  DEFAULT: 'default',
  CHECKBOX: 'checkbox',
};

export const Radio = (props: any) => {
  if (props.type === RADIO_TYPES.BUTTON) {
    if (props.legendText) {
      return (
        <fieldset className="fieldset-hidden">
          <legend className="legend-hidden">{localize({ message: props.legendText })}</legend>
          <ButtonType
            isDisabled={props.isDisabled}
            value={props.value}
            onChange={props.onChange}
            options={props.options}
            testid={props.testid}
          />
        </fieldset>
      );
    }
    return (
      <ButtonType
        isDisabled={props.isDisabled}
        value={props.value}
        onChange={props.onChange}
        options={props.options}
        testid={props.testid}
      />
    );
  }
  return (
    <fieldset className="fieldset-hidden">
      <legend className="legend-hidden">{localize({ message: props.legendText })}</legend>
      <DefaultType
        isDisabled={props.isDisabled}
        value={props.value}
        onChange={props.onChange}
        options={props.options}
      />
    </fieldset>
  );
};

Radio.defaultProps = {
  options: [],
  type: RADIO_TYPES.DEFAULT,
  isDisabled: false,
};

export default Radio;
