import * as _ from 'lodash-es';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { evaluateAssignmentSubmission, updateAssignmentSubmission } from 'actions/activity';
import { updateInstructorCreationComment } from 'actions/comment';
import FormRichTextEditor from 'components/core/form/RichTextEditor';
import Button from 'components/buttons/Button';
import { Rating } from '@material-ui/lab';
import { evalCommentsDisabled } from '@kritik/utils/course';
import AttachmentManager from 'components/layout/AttachmentManager';
import NoticeBoard from 'components/layout/NoticeBoard';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';
import {
  calcMarkFromWeightedScore,
  calcWeightedScoreFromMark,
  isRowBinary,
  getLevelOfScore,
} from '@kritik/utils/rubric';
import { isGroupCreation, creationHasTeacherComment } from '@kritik/utils/creation/general';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import FormFieldTitle from 'components/core/form/Title';
import FormSection from 'components/core/form/Section';
import FormField from 'components/core/form/Field';
import InlineInformation from 'components/layout/InlineInformation';
import {
  updateRubricHighlightedCriteria,
  updateRubricHighlightedLevel,
  openRubricDropdown,
} from 'actions/rubrics';
import { isFinalized } from '@kritik/utils/stage';
import HighlightedCriteria from 'components/Creation/Evaluate/Criteria';
import * as localStorage from 'utils/localStorage';
import ShortenText from 'components/General/ShortenText';
import { CreationScoreUtil } from '@kritik/utils/grade';
import * as ActivityUtils from '@kritik/utils/activity';
import Typography from 'components/core/Typography';
import * as generalUtils from '@kritik/utils/general';
import { localize } from 'locales/index';
import { getAssignment } from 'selectors/activity';
import { getAssignment as fetchActivity } from 'actions/activity';
import { getWordCountFromHTML } from '@kritik/utils/general';
import { UserGlobalPermissions, UserRoleInCourse } from 'app-types';
import { withUserRole } from 'utils/withUserRole';
import { useQueryClient } from '@tanstack/react-query';
import { getMySubmission } from 'selectors/creation';
import { TranslatedText } from 'components/TranslatedText';
import { sanitizeWeightedScore } from 'utils/score';

type OwnState = any;

type State = OwnState & typeof EvaluationEditComponent.defaultProps;

type EvaluationEditProps = {
  userRole: UserRoleInCourse;
  submission: any;
  userScore: any;
  rubric: any;
  isShowCriteria: boolean;
  isAdminPanel: boolean;
  user: {
    authUser: {
      permissions: UserGlobalPermissions;
    };
  };
  isInstructorGradingCalibrationCreation: boolean;
  commentOnly: boolean;
  submissionUser: any;
  assignment: any;
  course: any;
  evalUser: any;
  updateInstructorCreationComment: ({}) => Promise<any>;
  updateAssignmentSubmission: ({}) => Promise<any>;
  calibrationGradeSubmit: ({}) => Promise<any>;
  evaluateAssignmentSubmission: (data: any) => Promise<any>;
  highlightedCriteria: number;
  updateRubricHighlightedCriteria: (index: number) => void;
  openRubricDropdown: (val: boolean) => void;
  updateRubricHighlightedLevel: (val: number) => void;
  onCancel: () => void;
  highlightedRubricLevel: number;
  gradeOnly: boolean;
  title: string;
  isOverdue?: boolean;
  notifications?: {
    instructor: boolean;
    evaluator: boolean;
  };
  fetchActivity: ({ id }: { id: string }) => void;
};

class EvaluationEditComponent extends Component<EvaluationEditProps, State> {
  static defaultProps = {
    userScore: null,
    updateInstructorCreationComment: null,
    commentOnly: false,
    isInstructorGradingCalibrationCreation: false,
    calibrationGradeSubmit: null,
    isAdminPanel: false,
    evalUser: null,
    gradeOnly: false,
    highlightedCriteria: -1,
    title: null,
    isShowCriteria: false,
    isOverdue: false,
  };

  localInterval: any;

  constructor(props: EvaluationEditProps) {
    super(props);
    this.localInterval = null;
    this.state = {
      hover: [],
      evalRating: new Array(this.props.rubric.criteria.length).fill(0),
      weightedEvalRating: new Array(this.props.rubric.criteria.length).fill(0),
      isSelected: [],
      comment: '',
      error: false,
      fileList: [],
      highlightedCriteria: [],
      isLoading: true,
      reasonForLateEvaluation: '',
      instructorCreationScoreExplanation: '',
    };
  }

  componentDidMount() {
    const numOfCriteria = this.props.rubric.criteria.length;
    const {
      submission,
      userScore,
      userRole: { isInstructorInCourse, isStudentInCourse },
    } = this.props;
    let evalRating;
    let comment = '';
    let fileList = [];
    if (this.props.isInstructorGradingCalibrationCreation) {
      if (CreationScoreUtil.isInstructorGraded(submission)) {
        evalRating = CreationScoreUtil.getTeacherScore(submission);
      } else {
        evalRating = new Array(numOfCriteria).fill(null);
      }
    } else if (userScore) {
      evalRating = userScore.marks;
      comment = userScore.comment;
      fileList = userScore.files || [];
    } else if (
      !this.props.isAdminPanel &&
      isInstructorInCourse &&
      submission &&
      CreationScoreUtil.hasCreationScore(submission)
    ) {
      evalRating = CreationScoreUtil.getRawCreationScore(submission, this.props.rubric);
    } else {
      evalRating = new Array(numOfCriteria).fill(null);
    }
    if (this.props.commentOnly && creationHasTeacherComment(submission)) {
      comment = submission.teacherComment.text;
      fileList = submission.teacherComment.files;
    }
    const weightedEvalRating = evalRating.map((score: any, i: any) => {
      const weightedScore = calcWeightedScoreFromMark(score, i, this.props.rubric);
      return _.round(weightedScore, 2);
    });
    this.setState({
      hover: evalRating.slice(),
      evalRating,
      weightedEvalRating,
      isSelected: new Array(numOfCriteria).fill(false),
      comment,
      fileList,
      isLoading: false,
      reasonForLateEvaluation: userScore?.lateEvaluation?.reason || '',
    });
    if (isStudentInCourse) {
      const currentContent = localStorage.getText(
        `${this.props.submissionUser}${this.props.assignment._id}`
      );
      if (currentContent && currentContent.submitted === false) {
        this.setState({ comment: currentContent.text });
      }
      this.localInterval = setInterval(() => {
        const content = {
          submitted: false,
          text: this.state.comment,
        };
        localStorage.setText(`${this.props.submissionUser}${this.props.assignment._id}`, content);
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.localInterval);
  }

  getErrors() {
    if (!this.state.error) {
      return null;
    }
    return (
      <InlineInformation className="evaluation-editor__section__word-count" type="danger">
        {this.state.error}
      </InlineInformation>
    );
  }

  getCurrentGrade() {
    const { isInstructorInCourse } = this.props.userRole;
    if (this.props.submission && isInstructorInCourse && !this.props.isAdminPanel) {
      return CreationScoreUtil.getRawCreationScore(this.props.submission, this.props.rubric);
    }
    return this.state.evalRating;
  }

  getPrecision = (criterionLevels: any) => {
    const isBinaryCriterion = criterionLevels.length === 2;
    if (isBinaryCriterion) {
      return 1;
    }
    return 0.5;
  };

  getRatingIcon = (level: any) => {
    if (isRowBinary(level)) {
      return <CheckRoundedIcon style={{ pointerEvents: 'auto' }} />;
    }
    return <GradeRoundedIcon style={{ pointerEvents: 'auto' }} />;
  };

  setSubmissionError(errorMessage: any) {
    this.setState({
      error: errorMessage,
      submitting: false,
    });
  }

  clearLocalStorage = () => {
    localStorage.removeText(`${this.props.submissionUser}${this.props.assignment._id}`);
  };

  isValidScore = () => {
    const isAllScorePresent = this.state.evalRating.length === this.props.rubric.criteria.length;
    const indexOfNull = this.state.evalRating.indexOf(null);
    if (isAllScorePresent && indexOfNull === -1) {
      return true;
    }
    return false;
  };

  isValidCriteriaSelection = () => {
    return this.state.highlightedCriteria.length > 0;
  };

  isValidCommentLength = () => {
    const MIN_COMMENT_LENGTH = this.props.assignment.settings.minimumWordCountForEvaluations;
    const _comment = getWordCountFromHTML(this.state.comment);
    const isCommentLongEnough = _comment >= MIN_COMMENT_LENGTH;

    if (
      this.props.course &&
      !evalCommentsDisabled(this.props.course) &&
      !ActivityUtils.isCalibrationActivity(this.props.assignment) &&
      !isCommentLongEnough
    ) {
      return false;
    } else if (this.props.user.authUser.permissions.includes('isAdmin') && !isCommentLongEnough) {
      return false;
    }

    return true;
  };

  getHighlightedCriteria = () => {
    return this.state.highlightedCriteria;
  };

  handleStudentEvaluation = (data: any) => {
    data.assignment = this.props.assignment;
    data.creationId = this.props.submission._id;
    data.evaluationId = this.props.userScore?._id;
    data.isAdminPanel = this.props.isAdminPanel;
    data.user = this.props.submissionUser;
    data.evalUser = this.props.evalUser;
    data.feedbackOnFeedback = {};
    data.files = this.state.filelist;
    data.marks = this.state.evalRating.map((mark: any) => {
      return parseFloat(mark);
    });
    data.comment = this.state.comment;
    data.files = this.state.fileList;
    if (this.state.reasonForLateEvaluation.length > 0) {
      data.lateEvaluation = {
        reason: this.state.reasonForLateEvaluation,
      };
    }
    if (this.state.reasonForLateEvaluation.length === 0 && this.props.isAdminPanel) {
      data.lateEvaluation = {
        reason:
          'Submitted late by Kritik Support on behalf of the student, with instructor approval.',
      };
    }

    data.notifications = this.props.isAdminPanel ? this.props.notifications : undefined;

    if (!this.isValidCommentLength()) {
      const minimumWordCount = this.props.assignment.settings.minimumWordCountForEvaluations;
      return this.setSubmissionError(
        localize({
          message: 'WordCountMessage',
          options: { minimumWordCount },
        })
      );
    }

    if (
      !ActivityUtils.isCalibrationActivity(this.props.assignment) &&
      !this.isValidCriteriaSelection()
    ) {
      return this.setSubmissionError('Please select at least one criterion.');
    }

    if (this.props.isOverdue && this.state.reasonForLateEvaluation.length === 0) {
      return this.setSubmissionError(
        localize({ message: 'Creation.Evaluation.StudentView.ReasonForBeingLate' })
      );
    }

    this.submitStudentEvaluation(data);
  };

  handleInstructorEvaluation = (data: any) => {
    if (this.props.commentOnly) {
      this.props
        .updateInstructorCreationComment({
          creation: this.props.submission,
          text: this.state.comment,
          files: this.state.fileList,
        })
        .then(() => {
          // @ts-expect-error TS(2339) FIXME: Property 'onSubmit' does not exist on type 'Readon... Remove this comment to see the full error message
          return this.props.onSubmit();
        });
    }

    data.submissionId = this.props.submission._id;
    data.assignmentId = this.props.assignment._id;
    data.teacherScore = data.marks;
    this.props.updateAssignmentSubmission(data).then(() => {
      // @ts-expect-error TS(2339) FIXME: Property 'onSubmit' does not exist on type 'Readon... Remove this comment to see the full error message
      return this.props.onSubmit();
    });
  };

  onSubmit = () => {
    const { isInstructorInCourse, isStudentInCourse } = this.props.userRole;
    const data = {};
    if (!this.state.submitting) {
      this.setState({ submitting: true });
      (data as any).user = this.props.submissionUser;
      (data as any).feedbackOnFeedback = {};
      (data as any).highlightedCriteria = this.getHighlightedCriteria();
      if (!this.props.commentOnly) {
        (data as any).marks = this.state.evalRating.map((mark: any) => {
          return parseFloat(mark);
        });
      }

      if (!this.props.commentOnly && !this.isValidScore()) {
        return this.setSubmissionError('Please grade all criteria before submitting.');
      }

      if (this.props.isInstructorGradingCalibrationCreation) {
        return this.props.calibrationGradeSubmit({
          _id: this.props.submission._id,
          marks: (data as any).marks,
          instructorCreationScoreExplanation: this.state.instructorCreationScoreExplanation,
        });
      }

      if (isStudentInCourse || this.props.isAdminPanel) {
        return this.handleStudentEvaluation(data);
      }
      if (isInstructorInCourse) {
        return this.handleInstructorEvaluation(data);
      }
    }
  };

  submitStudentEvaluation(data: any) {
    this.props
      .evaluateAssignmentSubmission(data)
      .then(() => {
        if (!this.props.isAdminPanel) {
          this.clearLocalStorage();
          (this.props as any).queryClient.invalidateQueries(['getCreationsToEvaluate']);
        }
        // refetching activity in case activity.hasGraceEvaluations has changed
        this.props.fetchActivity({ id: this.props.assignment._id });
        // gfetch activity again
        // @ts-expect-error TS(2339) FIXME: Property 'onSubmit' does not exist on type 'Readon... Remove this comment to see the full error message
        return this.props.onSubmit();
      })
      .catch((err: any) => {
        const unknownErrorMessage =
          'Something went wrong while submitting your evaluation. Please contact support using the chatbox at the bottom right of your screen and a live agent will respond shortly.';
        let errorMessage = err.errors?.message ?? unknownErrorMessage;
        const missingFields = err.errors?.requiredFields || [];
        // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
        if (App.config.get('enableIntercom') && errorMessage.includes('does not exist')) {
          errorMessage = unknownErrorMessage;
          window.Intercom('showNewMessage', 'I need help submitting my evaluation!');
        }

        if (errorMessage === 'Comment too short.') {
          const minimumWordCount = this.props.assignment.settings.minimumWordCountForEvaluations;
          return this.setSubmissionError(
            localize({
              message: 'WordCountMessage',
              options: { minimumWordCount },
            })
          );
        }

        if (missingFields.includes('reason')) {
          (this.props as any).queryClient.invalidateQueries([
            'activity',
            (this.props as any).assignment._id,
          ]);
          return this.setSubmissionError(
            localize({ message: 'Creation.Evaluation.StudentView.ReasonForBeingLate' })
          );
        }

        this.setSubmissionError(errorMessage);
      });
  }

  setSelectedScore = (e: any, index: any, value: any) => {
    const newIsSelected = Array.from(this.state.isSelected);
    newIsSelected[index] = true;

    const evalRating = [...this.state.evalRating];
    evalRating[index] = value;

    let weightedScore = calcWeightedScoreFromMark(value, index, this.props.rubric);
    weightedScore = _.round(weightedScore, 2);
    const weightedEvalRating = [...this.state.weightedEvalRating];
    weightedEvalRating[index] = weightedScore;

    this.setState({
      isSelected: newIsSelected,
      evalRating,
      weightedEvalRating,
    });
  };

  handleManualInput = (e: any, index: any) => {
    let weightedScore = e.target.value;
    if (weightedScore < 0) {
      return;
    }
    weightedScore = Math.floor(weightedScore * 100) / 100;
    weightedScore = sanitizeWeightedScore({
      score: weightedScore,
      index,
      rubric: this.props.rubric,
    });

    const weightedEvalRating = [...this.state.weightedEvalRating];
    weightedEvalRating[index] = weightedScore;

    const evalRating = [...this.state.evalRating];
    let score = calcMarkFromWeightedScore(weightedScore, index, this.props.rubric);
    score = _.round(score, 3);
    evalRating[index] = score;

    this.setState({
      evalRating,
      weightedEvalRating,
    });
  };

  isSelectedRow = (index: any) => {
    const { isStudentInCourse } = this.props.userRole;
    if (isStudentInCourse && this.props.highlightedCriteria === index) {
      return `selected-row`;
    }
    return '';
  };

  selectRow = (index: any) => {
    const { isStudentInCourse } = this.props.userRole;
    if (isStudentInCourse) {
      this.props.updateRubricHighlightedCriteria(index);
      this.props.openRubricDropdown(true);
    }
  };

  setHoverScore = (value: any, index: any) => {
    const { isStudentInCourse } = this.props.userRole;
    if (isStudentInCourse) {
      this.props.updateRubricHighlightedCriteria(index);
      this.props.updateRubricHighlightedLevel(Math.ceil(value));
    }
  };

  onCancelEdit() {
    this.clearLocalStorage();
    return this.props.onCancel();
  }

  renderSeparator() {
    return (
      <tr className="score-editor__separator">
        <td colSpan={4} headers="title" />
      </tr>
    );
  }

  getMaxStarWidth = () => {
    const { levels } = this.props.rubric;
    const STAR_WIDTH = 24;
    return `${(levels.length + 1) * STAR_WIDTH + 8}px`;
  };

  renderStarRating = () => {
    if (this.props.commentOnly) {
      return;
    }
    const { isInstructorInCourse, isStudentInCourse } = this.props.userRole;
    const { rubric } = this.props;
    const { criteria, levels, grid } = rubric;
    const { hover, evalRating } = this.state;
    const currentGrade = this.getCurrentGrade();

    const getLevel = (criterionIndex: any) => {
      let levelIndex = -1;
      if (hover[criterionIndex] > -1) {
        levelIndex = Math.ceil(hover[criterionIndex]);
      } else {
        const rawScore = evalRating[criterionIndex];
        levelIndex = getLevelOfScore(rawScore, criterionIndex, rubric);
      }
      if (
        this.props.highlightedRubricLevel === 0 &&
        criterionIndex === this.props.highlightedCriteria
      ) {
        levelIndex = 0;
      }
      return {
        name: levels[levelIndex],
        desc: grid[criterionIndex][levelIndex],
      };
    };

    const scoreEditorScoreValueWrapperClass = classNames('score-editor__score-value-wrapper', {
      'w-30': isInstructorInCourse,
      'w-20': isStudentInCourse,
    });

    return (
      <div>
        <FormSection hideSeparator>
          <FormFieldTitle
            size="lg"
            label={this.props.title || localize({ message: 'Creation.Evaluate.Edit.Header' })}
          />
          <FormField>
            <table className="score-editor" data-testid="evaluation-score-table">
              <thead className="visually-hidden">
                <tr>
                  <th id="title">{localize({ message: 'Column.Header.Title' })}</th>
                  <th id="grade">{localize({ message: 'Column.Header.Grade' })}</th>
                  <th id="level">{localize({ message: 'Column.Header.Level' })}</th>
                  <th id="value">{localize({ message: 'Column.Header.Value' })}</th>
                </tr>
              </thead>
              <tbody>
                {this.renderSeparator()}
                {criteria.map((criterion: any, i: any) => {
                  const level = getLevel(i);
                  return (
                    <React.Fragment>
                      <tr
                        data-testid={`selected-row-${i}`}
                        key={i}
                        className={this.isSelectedRow(i)}
                        onClick={() => {
                          return this.selectRow(i);
                        }}
                      >
                        <td className="score-editor__criteria-title" headers="title" tabIndex={0}>
                          <ShortenText label={criterion.name} maxLength={50} />
                        </td>
                        <td
                          className="score-editor__star-display"
                          style={{
                            display: this.props.isShowCriteria ? 'flex' : 'block',
                          }}
                          headers="grade"
                        >
                          <div className="score-editor__criteria-label">
                            <ShortenText label={criterion.name} maxLength={25} />
                          </div>
                          <div>
                            <fieldset className="fieldset-hidden">
                              <legend className="legend-hidden">
                                {localize({
                                  message: 'Legend.Text.EvaluationScore.CriteriaRow',
                                  options: { criterion: criterion.name },
                                })}
                              </legend>
                              <span style={{ marginRight: '8px' }}>
                                <fieldset className="fieldset-hidden">
                                  <legend className="legend-hidden">
                                    {localize({
                                      message: 'Legend.Text.EvaluationScore.ZeroRating',
                                    })}
                                  </legend>
                                  <Rating
                                    data-testid={`clear-criteria-${i}`}
                                    max={1}
                                    icon={<ClearRoundedIcon style={{ pointerEvents: 'auto' }} />}
                                    value={this.state.evalRating[i] === 0 ? 1 : 0}
                                    onChange={(e) => {
                                      this.setSelectedScore(e, i, 0);
                                    }}
                                    onChangeActive={(e, value) => {
                                      value === -1
                                        ? this.setHoverScore(value, i)
                                        : this.setHoverScore(0, i);
                                    }}
                                    className="score-editor__star-rating-zero"
                                  />
                                </fieldset>
                              </span>
                              <fieldset className="fieldset-hidden">
                                <legend className="legend-hidden">
                                  {localize({ message: 'Legend.Text.EvaluationScore.StarRating' })}
                                </legend>
                                <Rating
                                  data-testid={`assign-criteria-rating-${i}`}
                                  name={`primary-edit-grade-${this.props.submissionUser}-${i}`}
                                  className="score-editor__star-rating"
                                  max={grid[i].length - 1}
                                  value={this.state.evalRating[i]}
                                  precision={this.getPrecision(grid[i])}
                                  onChange={(e, value) => {
                                    return this.setSelectedScore(e, i, value);
                                  }}
                                  onChangeActive={(e, value) => {
                                    hover[i] = value;
                                    this.setState({ hover });
                                    this.setHoverScore(value, i);
                                  }}
                                  icon={this.getRatingIcon(grid[i])}
                                />
                              </fieldset>
                            </fieldset>
                          </div>
                        </td>
                        <td
                          style={{ textAlign: 'left' }}
                          className="score-editor__criteria-level"
                          headers="level"
                        >
                          <div data-testid={`criteria-level-${i}`}>{level.name}</div>
                          {this.props.isShowCriteria && (
                            <div>
                              <Typography fontSize={12}>{level.desc}</Typography>
                            </div>
                          )}
                        </td>
                        <td className={scoreEditorScoreValueWrapperClass} headers="value">
                          <span className="score-editor__score-value">
                            {currentGrade[i] >= 0
                              ? calcWeightedScoreFromMark(currentGrade[i], i, rubric).toFixed(2)
                              : 'None'}
                          </span>
                          <span className="score-editor__score-weigth">
                            {!isInstructorInCourse && `/${criterion.weight} pts`}
                          </span>
                          {isInstructorInCourse &&
                            !this.props.isAdminPanel &&
                            this.renderInstructorGradingOptions(i, criterion.weight)}
                        </td>
                      </tr>
                      {this.renderSeparator()}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </FormField>
        </FormSection>
        {this.renderWarnings()}
      </div>
    );
  };

  handleFileChange(files: any) {
    this.setState({ fileList: files });
  }

  changeCriteriaHighlight = (highlightedCriteria: any) => {
    this.setState({ highlightedCriteria });
  };

  getWordCount = () => {
    return generalUtils.getWordCountFromHTML(this.state.comment);
  };

  renderStudentWrittenEvaluation = () => {
    return (
      <FormSection>
        <FormFieldTitle size="lg" label="Written Evaluation" />
        <HighlightedCriteria
          creationScore={this.props.userScore}
          onChange={(highlightedCriteria: any) => {
            this.changeCriteriaHighlight(highlightedCriteria);
          }}
          rubric={this.props.rubric}
          isEditing
        />
        <FormRichTextEditor
          label="Evaluation Comments"
          info="Provide an example from the creation and describe how it impacted the quality of their work"
          value={this.state.comment}
          onChange={(comment: any) => {
            this.setState({ comment });
          }}
          testid="evaluation-comment-editor"
        />
        <AttachmentManager
          onFileChange={(newList: any) => {
            return this.handleFileChange(newList);
          }}
          fileList={this.state.fileList}
          actionId={this.props.submissionUser}
        />
      </FormSection>
    );
  };

  renderInstructorWrittenEvaluation = () => {
    const { submission } = this.props;
    let destinationLabel = '';
    if (isGroupCreation(submission)) {
      // @ts-expect-error TS(2322) FIXME: Type 'Element' is not assignable to type 'string'.
      destinationLabel = (
        <p>
          <b>Private to: </b>
          {submission.group.name}
        </p>
      );
    } else {
      // @ts-expect-error TS(2322) FIXME: Type 'Element' is not assignable to type 'string'.
      destinationLabel = (
        <p>
          <b>Private to: </b>
          {submission.user.profile.name}
        </p>
      );
    }

    return (
      <React.Fragment>
        <FormFieldTitle size="lg" label="Comment on Creation" />
        {destinationLabel}
        <FormRichTextEditor
          value={this.state.comment}
          onChange={(comment: any) => {
            this.setState({ comment });
          }}
        />
        <AttachmentManager
          onFileChange={(newList: any) => {
            return this.handleFileChange(newList);
          }}
          fileList={this.state.fileList}
          actionId={this.props.submissionUser}
        />
      </React.Fragment>
    );
  };

  renderWrittenEvaluationEditor = () => {
    const { isInstructorInCourse } = this.props.userRole;
    if (this.props.gradeOnly || (this.props.course && evalCommentsDisabled(this.props.course))) {
      return null;
    }
    if (!this.props.isAdminPanel && isInstructorInCourse) {
      return this.renderInstructorWrittenEvaluation();
    }
    return this.renderStudentWrittenEvaluation();
  };

  renderWarnings = () => {
    const { isInstructorInCourse } = this.props.userRole;
    if (
      this.props.isInstructorGradingCalibrationCreation ||
      !isInstructorInCourse ||
      this.props.isAdminPanel
    ) {
      return null;
    }
    if (isFinalized({ assignment: this.props.assignment })) {
      return (
        <NoticeBoard type="information" title="There is no impact on evaluators">
          Editing a student's Creation Score after an activity has been marked as completed does not
          impact the scores of the students who evaluated the creation.
        </NoticeBoard>
      );
    }
    return (
      <NoticeBoard
        type="information"
        title="Your scoring will take precedence over the evaluators scores"
      >
        The student will still participate in all the stages of the activity.
      </NoticeBoard>
    );
  };

  renderInstructorGradingOptions = (index: any, criteriaWeight: any) => {
    const weightedScore = this.state.weightedEvalRating[index];
    return (
      <React.Fragment>
        <i className="fa fa-arrow-right score-editor__arrow-pointer" />
        <input
          type="number"
          value={weightedScore}
          step="0.1"
          placeholder={weightedScore}
          onChange={(e) => {
            return this.handleManualInput(e, index);
          }}
          className="score-editor__score-input"
          data-testid={`score-editor-${index}`}
        />
        <span className="score-editor__score-weigth">{`/${criteriaWeight} pts `}</span>
      </React.Fragment>
    );
  };

  getButtonLabel() {
    const { isInstructorInCourse } = this.props.userRole;
    if (isInstructorInCourse) {
      return 'Save';
    }
    return 'Save & Submit';
  }

  render() {
    const { submitting } = this.state;

    if (this.state.isLoading) {
      return null;
    }
    const isEvalCommentDisabled =
      this.props.gradeOnly || (this.props.course && evalCommentsDisabled(this.props.course));

    return (
      <>
        {this.renderStarRating()}
        {this.renderWrittenEvaluationEditor()}
        {this.props.course?.userRole === 'student' && !isEvalCommentDisabled && (
          <div className="word-count-container" data-testid="eval-word-count">
            Word count: {this.getWordCount()}
          </div>
        )}
        {this.props.isOverdue && (
          <FormRichTextEditor
            label={localize({ message: 'Creation.Evaluation.StudentView.ReasonForBeingLateLabel' })}
            value={this.state.reasonForLateEvaluation}
            onChange={(content: any) => {
              return this.setState({ reasonForLateEvaluation: content });
            }}
            testid="late-evaluation-reason-editor"
          />
        )}
        {this.props.isInstructorGradingCalibrationCreation && (
          <FormRichTextEditor
            label={<TranslatedText i18nKey="Activity.CalibrationActivity.Edit.EvaluationNotes" />}
            value={this.state.instructorCreationScoreExplanation}
            onChange={(v) => this.setState({ instructorCreationScoreExplanation: v })}
            testid="editor-instructor-creation-score-explanation"
          />
        )}
        <FormSubmitButtons errors={this.getErrors()}>
          <Button
            type="primary"
            disabled={
              submitting || (this.props.isOverdue && this.state.reasonForLateEvaluation === '')
            }
            loading={submitting}
            onClick={this.onSubmit}
            data-testid="submit-evaluation"
          >
            {this.getButtonLabel()}
          </Button>
          <Button
            className={`${this.props.onCancel ? '' : 'hidden'}`}
            type="secondary"
            onClick={() => {
              return this.onCancelEdit();
            }}
          >
            Cancel
          </Button>
        </FormSubmitButtons>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
    user: state.user,
    highlightedCriteria: state.rubric.rubrics.highlightedRubricCriteria,
    highlightedRubricLevel: state.rubric.rubrics.highlightedRubricLevel,
    mySubmission: getMySubmission(state, state.selected.assignmentId),
  };
}

const EvaluationEdit = (props: any) => {
  const queryClient = useQueryClient();

  return <EvaluationEditComponent {...props} queryClient={queryClient} />;
};

export default withUserRole(
  connect(mapStateToProps, {
    evaluateAssignmentSubmission,
    updateAssignmentSubmission,
    updateRubricHighlightedCriteria,
    updateRubricHighlightedLevel,
    updateInstructorCreationComment,
    openRubricDropdown,
    fetchActivity,
  })(EvaluationEdit)
);
