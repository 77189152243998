import NoticeBoard from 'components/layout/NoticeBoard';
import React from 'react';
import * as FormatUtils from '@kritik/utils/format';
import * as ActivityStatusUtil from '@kritik/utils/stage';
import InlineInformation from 'components/layout/InlineInformation';
import { isGroupAssignment, isGroupPresentationActivity } from '@kritik/utils/activity';
import { localize } from 'locales/index';
import { useTranslation } from 'react-i18next';

export const MissedWork = ({ field }: any) => {
  return (
    <NoticeBoard type="danger" title={`No ${field}`}>
      {`No ${field} received.`}
    </NoticeBoard>
  );
};

export const MissedFeedback = () => {
  return (
    <NoticeBoard type="danger" title="Feedback was missed">
      The student did not complete feedback before due date.
    </NoticeBoard>
  );
};

export const LateEnrollment = ({ stage }: any) => {
  let label = 'EVALUATE';
  if (stage) {
    label = stage;
  }
  return (
    <NoticeBoard type="danger" title="Late enrollment">
      {`You enrolled in this course after the ${label} stage for this activity began, and you will not
      be assigned any creations to evaluate.`}
    </NoticeBoard>
  );
};

export const NoAssignedCreations = () => {
  return (
    <NoticeBoard type="danger" title="No evaluations were assigned to you">
      It seems your peers did not submit their creation in time so you have no creations to
      evaluate. If you believe there is a mistake, please contact support.
    </NoticeBoard>
  );
};

export function NoAssignedEvaluations() {
  return (
    <NoticeBoard type="danger" title="No evaluations were assigned to you">
      {localize({
        message: 'Activity.removeStudentFromActivityAfterMissedCreation.Evaluate.StudentNotice',
      })}
    </NoticeBoard>
  );
}

export const NoAssignedFeedback = () => {
  const { t } = useTranslation();
  return (
    <NoticeBoard
      type="caution"
      title={t('Activity.Feedback.NoFeedbackAssignedNotice.Title')}
      testid="no-feedback-assigned-notice"
    >
      {t('Activity.Feedback.NoFeedbackAssignedNotice.Content')}
    </NoticeBoard>
  );
};

export const StartingSoon = ({ stage }: any) => {
  return (
    <NoticeBoard type="information" title="Starting soon">
      {`Please check your schedule to see when the ${stage} stage begins.`}
    </NoticeBoard>
  );
};

export const NoGroup = () => {
  return (
    <NoticeBoard
      type="danger"
      title="You have not been assigned to a group."
      information
      style={{ marginBottom: '20px' }}
      testid="contact-to-join-group-notice"
    >
      Please contact support to join a group.
    </NoticeBoard>
  );
};

export const LateSubmissionWrapper = ({ title, children }: any) => {
  return (
    <NoticeBoard
      type="caution"
      style={{ marginBottom: '20px' }}
      title={title}
      testid="late-submission-warning"
    >
      {children}
    </NoticeBoard>
  );
};

export function LateSubmissionInstructorNotice({ creationDueDate, submissionDate }: any) {
  const hourStr = FormatUtils.subTimeFromCreationDue(creationDueDate, submissionDate);
  return (
    <LateSubmissionWrapper title={`Creation was submitted ${hourStr} late`}>
      The student creation was submitted during the late grace period.
    </LateSubmissionWrapper>
  );
}

export function GracePeriodCreationStudentNotice({}) {
  return (
    <LateSubmissionWrapper title="Your creation was submitted late">
      You submitted your creation past the due date but it will be accepted by your instructor.
    </LateSubmissionWrapper>
  );
}

export function MissedSubmissionStudentNotice({ creation, activity, user }) {
  const isGroupActivity = isGroupAssignment(activity) || isGroupPresentationActivity(activity);
  const isGroupLeader = user._id === creation.user._id;

  const title = isGroupActivity
    ? isGroupLeader
      ? localize({
          message: 'Activity.Presentation.StudentView.MissedSubmission.Group.Leader.Title',
        })
      : localize({
          message: 'Activity.Presentation.StudentView.MissedSubmission.Group.Member.Title',
        })
    : localize({
        message: 'Activity.Presentation.StudentView.MissedSubmission.Title',
      });

  const content = isGroupActivity
    ? isGroupLeader
      ? localize({
          message: 'Activity.Presentation.StudentView.MissedSubmission.Group.Leader',
        })
      : localize({
          message: 'Activity.Presentation.StudentView.MissedSubmission.Group.Member',
        })
    : localize({
        message: 'Activity.Presentation.StudentView.MissedSubmission',
      });

  return (
    <NoticeBoard type="danger" title={title} data-testid="missed-creation">
      {content}
    </NoticeBoard>
  );
}

export function KSChangeUnavailableNotice({ esEnabled }: any) {
  const scoreType = esEnabled ? 'Evaluation' : 'Kritik';

  return (
    <NoticeBoard type="information" title={`${scoreType} Score Change unavailable`}>
      {`Student’s ${scoreType} Scores will be shown when the activity transitions to the Grading stage.`}
    </NoticeBoard>
  );
}

export function EvaluationScoreCalibrationActivityNotice() {
  return (
    <NoticeBoard type="information" title="">
      {"When activity is completed student's Evaluation Scores will be shown"}
    </NoticeBoard>
  );
}

export const CalibrationGradingNotice = ({ isInstructor }: any) => {
  const word = isInstructor ? 'student' : 'your';
  const link = isInstructor
    ? 'https://help.kritik.io/en/articles/6387297-calibration-activities'
    : 'https://help.kritik.io/en/articles/6388493-calibration-activities';
  return (
    <NoticeBoard type="information" title="Calibration activities are not graded">
      The Evaluation Score in a Calibration Activity is not counted towards {word} overall
      Evaluation Score. Learn more about how{' '}
      <a
        target="_blank"
        href={link}
        rel="noreferrer"
        title={localize({ message: 'Title.Text.CalibrationActivityInfo' })}
      >
        calibration activities
      </a>{' '}
      work.
    </NoticeBoard>
  );
};

export const LateSubmissionAvailability = ({ activity }: any) => {
  const statusEndDate = ActivityStatusUtil.getEndOfCreationPeriod(activity);
  return (
    <InlineInformation
      type="danger"
      title={`Your creation is overdue by ${FormatUtils.displayTimeBetweenDates(
        statusEndDate,
        new Date()
      )}`}
      testid="overdue-creation-notice"
    >
      You could still get a partial score if you submit your creation now.
    </InlineInformation>
  );
};

export const NoGroupInlineNotice = () => {
  return (
    <InlineInformation type="danger" title="You have not been assigned to a group." information>
      Please contact support to join a group.
    </InlineInformation>
  );
};

export const EnrolledAfterCreateStageInGroupActivity = () => {
  const { t } = useTranslation();
  return (
    <InlineInformation
      type="danger"
      information
      testid="enrolled-after-create-stage-group-activity-notice"
    >
      {t('Activity.GroupActivity.EnrolledAfterCreateStage')}{' '}
      {t('Activity.GroupActivity.EnrolledAfterCreateStage.Contact')}
    </InlineInformation>
  );
};

export const EvaluationScoreGroupScoreNotice = () => {
  return (
    <InlineInformation type="information" information>
      Groups do not receive evaluation score and average score cannot be edited.
    </InlineInformation>
  );
};

export const FeedbackScoreGroupScoreNotice = () => {
  return (
    <InlineInformation type="information" information>
      Groups do not receive feedback score and average score cannot be edited.
    </InlineInformation>
  );
};

export const EvaluationScoreGroupEvalsNotice = () => {
  return (
    <React.Fragment>
      <h4>Evaluations given</h4>
      <InlineInformation type="information" information>
        Groups do not give evaluations but individual students do.
      </InlineInformation>
    </React.Fragment>
  );
};

export const FeedbackScoreGroupFeedbackNotice = () => {
  return (
    <InlineInformation type="information" information>
      Groups do not give feedback but individual students do.
    </InlineInformation>
  );
};

export default {
  CalibrationGradingNotice,
  KSChangeUnavailableNotice,
  LateEnrollment,
  GracePeriodCreationStudentNotice,
  LateSubmissionInstructorNotice,
  MissedSubmissionStudentNotice,
  MissedWork,
  MissedFeedback,
  NoAssignedCreations,
  NoAssignedFeedback,
  NoGroup,
  NoGroupInlineNotice,
  StartingSoon,
  EvaluationScoreGroupEvalsNotice,
  EvaluationScoreGroupScoreNotice,
  FeedbackScoreGroupScoreNotice,
  FeedbackScoreGroupFeedbackNotice,
  EvaluationScoreCalibrationActivityNotice,
};
