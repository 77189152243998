import * as studentUtil from 'utils/student';
import { simplePercent } from '@kritik/utils/format';
import { isFinalized } from '@kritik/utils/stage';
import * as GradeHistoryUtils from '@kritik/utils/gradeHistory';

const OverallScoreCell = (props: any) => {
  const { activity, student, course } = props;
  const gradeHistory = GradeHistoryUtils.getStudentActivityGradeHistory(
    student.gradeHistories,
    activity._id
  );
  if (!gradeHistory && !isFinalized({ assignment: activity })) {
    return '-';
  }
  const overallScore = studentUtil.getOverallScoreByActivity(student, activity, course);
  return simplePercent(overallScore);
};

export default OverallScoreCell;
