import React, { useState } from 'react';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import CouponBookstoreModal from '../CouponBookstoreModal/CouponBookstoreModal';
import CouponEmailUsersModal from '../CouponEmailUsersModal/CouponEmailUsersModal';
import CouponTableToolbar from './CouponTableToolbar';
import CreateCouponModal from '../CreateCouponModal/CreateCouponModal';

const theme = createTheme({
  palette: {
    primary: {
      light: '#019CC3',
      main: '#4844A3',
      contrastText: '#fff',
    },
    secondary: {
      light: '#019CC3',
      main: '#4844A3',
      contrastText: '#fff',
    },
  },
});

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  };
});

const CouponTable = (props: any) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [displayCreateCouponModal, setDisplayCreateCouponModal] = useState(false);
  const [displayBookstoreModal, setDisplayBookstoreModal] = useState(false);
  const [displayCouponEmailUsersModal, setDisplayCouponEmailUsersModal] = useState(false);

  const { couponData, fetchNewData, isFetching } = props;

  const formatDate = (date: any) => {
    return new Date(date * 1000).toLocaleDateString();
  };

  const createData = (coupon: any) => {
    const { redeem_by, amount_off } = coupon;

    return {
      createdBy: coupon.metadata.createdBy,
      createdFor: coupon.name,
      id: coupon.id,
      created: formatDate(coupon.created),
      expiry: redeem_by ? formatDate(redeem_by) : 'None',
      discount: `${
        amount_off
          ? `${amount_off / 100} ${coupon.currency.toUpperCase()}`
          : `${coupon.percent_off}%`
      } OFF`,
      valid: coupon.valid,
    };
  };

  const rows = couponData.data.map((coupon: any) => {
    return createData(coupon);
  });

  const handleChangePage = (event: any, newPage: any) => {
    const lastRowNum = (newPage + 1) * rowsPerPage;
    const dataLength = couponData.data.length + rowsPerPage;
    if (lastRowNum === dataLength) {
      fetchNewData();
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => {
        return n.id;
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: any, id: any) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id: any) => {
    return selected.indexOf(id) !== -1;
  };

  const isNextPageAvailable = () => {
    const isLastPage = (page + 1) * rowsPerPage >= couponData.data.length;
    if (isFetching || (isLastPage && !couponData.has_more)) {
      return { disabled: true };
    }
    return { disabled: false };
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <CouponTableToolbar
            selected={selected}
            setDisplayBookstoreModal={setDisplayBookstoreModal}
            setDisplayCouponEmailUsersModal={setDisplayCouponEmailUsersModal}
            setDisplayCreateCouponModal={setDisplayCreateCouponModal}
          />
          <TableContainer>
            <Table className={classes.table} aria-label="coupon table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      className="admin-coupon-table-checkbox"
                      indeterminate={selected.length > 0 && selected.length < rows.length}
                      checked={rows.length > 0 && selected.length === rows.length}
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all coupons' }}
                    />
                  </TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell align="right">Created For</TableCell>
                  <TableCell align="right">Coupon</TableCell>
                  <TableCell align="right">Created</TableCell>
                  <TableCell align="right">Expiry</TableCell>
                  <TableCell align="right">Discount</TableCell>
                  <TableCell align="right">Valid</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching ? (
                  <TableRow>
                    <TableCell colSpan={4} />
                    <TableCell style={{ height: 53 * rowsPerPage }}>Loading...</TableCell>
                  </TableRow>
                ) : (
                  rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any) => {
                      const isItemSelected = isSelected(row.id);
                      return (
                        <TableRow
                          hover
                          key={row.id}
                          tabIndex={-1}
                          onClick={(event) => {
                            return handleClick(event, row.id);
                          }}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              className="admin-coupon-table-checkbox"
                            />
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            {row.createdBy}
                          </TableCell>
                          <TableCell align="right">
                            {row.createdFor ? row.createdFor : row.id}
                          </TableCell>
                          <TableCell align="right">{row.id}</TableCell>
                          <TableCell align="right">{row.created}</TableCell>
                          <TableCell align="right">{row.expiry}</TableCell>
                          <TableCell align="right">{row.discount}</TableCell>
                          <TableCell align="right">{row.valid ? 'Yes' : 'No'}</TableCell>
                        </TableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={-1}
            nextIconButtonProps={isNextPageAvailable()}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      {displayBookstoreModal && (
        <CouponBookstoreModal
          selectedCoupons={selected.map((id) => {
            return rows.find((row: any) => {
              return row.id === id;
            });
          })}
          handleClose={() => {
            return setDisplayBookstoreModal(false);
          }}
        />
      )}
      {displayCouponEmailUsersModal && (
        <CouponEmailUsersModal
          selectedCoupons={selected.map((id) => {
            return rows.find((row: any) => {
              return row.id === id;
            });
          })}
          handleClose={() => {
            return setDisplayCouponEmailUsersModal(false);
          }}
        />
      )}
      {displayCreateCouponModal && (
        <CreateCouponModal
          handleClose={() => {
            return setDisplayCreateCouponModal(false);
          }}
        />
      )}
    </MuiThemeProvider>
  );
};

export default CouponTable;
