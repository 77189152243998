import { SORT_OPTIONS } from 'components/CreationList/constant';
import { sortByStudentName } from './sortByName';
import { sortByCreationScore } from './sortByCreationScore';
import { sortByFeedbackScore } from './sortByFeedbackScore';
import { sortByEvaluationScore } from './sortByEvaluationScore';
import { sortByOverallScore } from './sortByOverallScore';
import { sortByIssues } from './sortByIssues';

export const sortItemList = (itemList: any, sortedColumn: any, activity: any, course: any) => {
  const ascending = sortedColumn.sorting.isAscending;

  switch (sortedColumn.header) {
    case SORT_OPTIONS.NAME: {
      return sortByStudentName(itemList, ascending);
    }
    case SORT_OPTIONS.CREATE: {
      return sortByCreationScore(itemList, ascending, activity);
    }
    case SORT_OPTIONS.EVALUATE: {
      return sortByEvaluationScore(itemList, ascending, activity, course);
    }
    case SORT_OPTIONS.FEEDBACK: {
      return sortByFeedbackScore(itemList, ascending, activity);
    }
    case SORT_OPTIONS.OVERALL: {
      return sortByOverallScore(itemList, ascending, activity, course);
    }
    case SORT_OPTIONS.ISSUES: {
      return sortByIssues(itemList, ascending, activity);
    }
    default: {
      return itemList;
    }
  }
};
