import React from 'react';
import * as _ from 'lodash-es';
import * as formatUtils from '@kritik/utils/format';
import PercentageInput from 'components/core/input/Percentage';
import InfoPopup from 'components/core/InfoPopup';
import NumberInput from 'components/core/input/Number';

type markingSchemeNodeProps = {
  label: string;
  name?: string;
  description: string;
  title: string;
  value: number;
  weight?: number;
  isEdit: boolean;
  isPercentage: boolean;
  forceInputPercentage?: boolean;
  popover?: {
    description: string;
    title: string;
    url: string;
  };
  onChange: (val: React.ChangeEvent) => void;
  testid?: string;
};

const Node = (props: markingSchemeNodeProps) => {
  const renderLabel = () => {
    let weight: React.ReactNode = null;
    if (props.weight !== null) {
      let label: string;
      if (props.isPercentage) {
        label = formatUtils.simplePercent(props.weight, 2);
      } else {
        label = formatUtils.simplePoint(props.weight, 2);
      }
      weight = <span className="marking-scheme__node-weight">{`${label}`}</span>;
    }
    return (
      <div>
        <div className="marking-scheme__node-label">{weight}</div>
        <div className="marking-scheme__node-label">{props.label}</div>
      </div>
    );
  };

  const renderDescription = () => {
    return <div className="marking-scheme__node-description">{props.description}</div>;
  };

  const renderValue = () => {
    if (props.isEdit) {
      if (props.isPercentage || props.forceInputPercentage) {
        return (
          <PercentageInput
            value={props.value}
            onChange={props.onChange}
            name={props.name}
            label={props.label}
            testid={props.testid}
          />
        );
      }
      return (
        <NumberInput
          value={props.value}
          onChange={props.onChange}
          name={props.name}
          testid={props.testid}
        />
      );
    }
    let label: string = null;
    if (props.isPercentage || props.forceInputPercentage) {
      label = formatUtils.simplePercent(props.value, 2);
    } else {
      label = formatUtils.simplePoint(props.value, 2);
    }
    return (
      <div className="marking-scheme__node-value" data-testid={props.testid}>
        {label}
      </div>
    );
  };

  const renderInfoPopUp = () => {
    const { popover } = props;
    if (!popover) {
      return null;
    }
    return (
      <InfoPopup
        title={popover.title}
        description={popover.description}
        learnMoreUrl={popover.url}
      />
    );
  };

  return (
    <div className="marking-scheme__node">
      <div className="marking-scheme__node-header">
        {renderLabel()}
        {renderInfoPopUp()}
      </div>
      {renderDescription()}
      {renderValue()}
    </div>
  );
};

Node.defaultProps = {
  onChange: null,
  isEdit: false,
  weight: null,
  isPercentage: false,
  popover: null,
};

export default Node;
