import React from 'react';
import { FormSpy } from 'react-final-form';
import InlineInformation from 'components/layout/InlineInformation';
import ErrorItem from 'components/ActivityEdit/ErrorMsg/Item';

function ValidationErrorMsg({ validationErrorsObj }: any) {
  // @ts-expect-error TS(2304) FIXME: Cannot find name 'generateValidationErrMsgs'.
  const errMsgs = generateValidationErrMsgs(validationErrorsObj);
  return (
    <div className="activity-edit__error-msg-list">
      {errMsgs.map((errMsg: any) => {
        return <ErrorItem message={errMsg} />;
      })}
    </div>
  );
}

function ErrorMsg(props: any) {
  if (!props.submitFailed) {
    return null;
  }

  if (props.submitError) {
    return <ErrorItem message={props.submitError} />;
  }
  if (props.hasValidationErrors) {
    return <ErrorItem message="Error - Please review the form and resolve the errors" />;
  }
  return null;
}

function ErrorMsgWrapper() {
  return <FormSpy component={ErrorMsg} />;
}

export default ErrorMsgWrapper;
