import React from 'react';
import { connect } from 'react-redux';
import { isListByGroup } from 'components/CreationList/util';
import { selectCreationTableListBy } from 'redux/creationTable/select';
import ShortenText from 'components/General/ShortenText';

function Name({ user, group, creationTableListBy }: any) {
  const renderName = () => {
    let name = '';
    if (group && isListByGroup(creationTableListBy)) {
      name = group.name;
    } else {
      name = user.profile.name;
    }
    return <ShortenText label={name} maxLength={20} />;
  };

  return <h5 className="student-info-name">{renderName()}</h5>;
}

const mapStateToProps = (state: any) => {
  return {
    creationTableListBy: selectCreationTableListBy(state),
  };
};

export default connect(mapStateToProps)(Name);
