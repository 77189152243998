import { useState, useEffect } from 'react';
import LinkIcon from '@material-ui/icons/Link';
import { FormSelection, FormMainError } from 'components/Form/FormComponents';
import FormNumberInput from 'components/core/form/NumberField';
import { connect } from 'react-redux';
import {
  getCourseAndInstitutionInfo,
  changeCoursePriceAndCurrency,
  getHubspotDealsByEmail,
  updateHubspotDeal,
} from 'actions/admin';
import { InlineInformation } from 'components/layout';
import { Button } from 'components/buttons';
import * as _ from 'lodash-es';
import { Card } from '@material-ui/core';
import PriceSyncCard from 'components/Admin/ChangeCoursePriceModal/PriceSync';
import { CURRENCY_OPTIONS } from '@kritik/constants/admin';
import FindDealFromOptions from 'components/Admin/Find/FindDealFromOptions';
import FindCourseFromOptions from 'components/Admin/Find/FindCourseFromOptions';
import FindStudentWithEmail from 'components/Admin/Find/FindStudentWithEmail';

const ChangeCoursePrice = (props: any) => {
  const [email, setEmail] = useState('');
  const [course, setCourse] = useState(null);
  const [price, setPrice] = useState('');
  const [currency, setCurrency] = useState(null);
  const [loading, setLoading] = useState(false);
  const [infoReady, setInfoReady] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [courseOptions, setCourseOptions] = useState([]);
  const [deal, setDeal] = useState(null);

  const changeEmail = (e: any) => {
    return setEmail(e.target.value.trim());
  };
  const changePrice = (e: any) => {
    return setPrice(e.target.value);
  };

  const changeDeal = (newDeal: any) => {
    setDeal(newDeal);
  };

  const generatePropertyMap = (properties: any) => {
    let pricingProperties = '';
    for (const [key, value] of Object.entries(properties)) {
      if (
        key.includes('$') ||
        key.toLowerCase().includes('price') ||
        key.toLowerCase().includes('pay')
      ) {
        pricingProperties += `${key}: ${(value as any).value}\n`;
      }
    }
    return pricingProperties;
  };

  const getDealInfo = () => {
    if (!deal) {
      return null;
    }
    const deals = props.fullDealInfo[email];
    if (!deals) {
      return null;
    }
    const fullHSData = deals.find((d: any) => {
      return _.isEqual(d.dealId, (deal as any).value);
    });
    const { properties } = fullHSData;
    return (
      <InlineInformation title="Some info from Hubspot">
        <div>HS Deal ID: {fullHSData.dealId}</div>
        <div>Deal Name: {properties.dealname.value}</div>
        <div>Deal Amount: {Number(properties?.amount?.value || 0).toFixed(2)}</div>
        <div>Deal Stage: {properties.stagename.value}</div>
        <div className="hubspot-link-container">
          <div>Link:</div>
          <a
            href={`https://app.hubspot.com/contacts/${fullHSData.portalId}/deal/${fullHSData.dealId}`}
            target="_blank"
          >
            <LinkIcon />
            Go To Hubspot
          </a>
        </div>
        <div>
          <b>Pricing</b>{' '}
        </div>
        {generatePropertyMap(properties)}
      </InlineInformation>
    );
  };

  const parsedDealOptions = () => {
    if (!props.fullDealInfo || Object.keys(props.fullDealInfo).length === 0) {
      return [];
    }
    const options = props.fullDealInfo[email];
    if (!options) {
      return [];
    }
    return options.map((deal: any) => {
      return {
        value: deal.dealId,
        label: `${deal.properties.dealname.value} (${deal.properties.campaign.value} campaign)`,
      };
    });
  };

  const setCourseAndDeals = (courseOptions: any) => {
    setCourseOptions(courseOptions);
    props.getHubspotDealsByEmail({ email });
  };

  const checkAllFields = () => {
    return email !== '' && course !== null && currency !== null && price !== '';
  };

  const getInfo = async () => {
    await props.getCourseAndInstitutionInfo({ email, courseId: course.value });
    setInfoReady(true);
  };

  const getError = () => {
    if (priceError) {
      return 'Please enter a valid price for this price';
    }
  };

  const countDecimals = (num: any) => {
    if (Math.floor(num) != num) {
      return num.toString().split('.')[1].length || 0;
    }
    return 0;
  };

  const changeCoursePriceAndCurrency = async () => {
    // @ts-expect-error TS(2365) FIXME: Operator '<' cannot be applied to types 'string' a... Remove this comment to see the full error message
    if (price === '' || price < 0 || price > 100000 || countDecimals(price) > 2) {
      return setPriceError(true);
    }
    setPriceError(false);
    setLoading(true);
    await props.changeCoursePriceAndCurrency({
      courseId: course.value,
      // @ts-expect-error TS(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      price: price * 100,
      currency: currency.value,
    });
    window.location.reload();
  };

  const renderKritikPriceChange = () => {
    return (
      <Card className="price-edit-card">
        <h4 className="price-edit-card-header">Change Course Price in Kritik</h4>
        <hr />
        <FormSelection
          label="New Price"
          value={currency}
          onChange={setCurrency}
          options={CURRENCY_OPTIONS}
        />
        <FormNumberInput label="" max="100000" step="0.05" value={price} onChange={changePrice} />
        <FormMainError message={getError()} isOpen={getError()} />
        <Button
          unavailable={!checkAllFields()}
          disabled={!checkAllFields()}
          loading={loading}
          className="admin-panel-submit-button"
          type="primary"
          onClick={changeCoursePriceAndCurrency}
        >
          Change
        </Button>
      </Card>
    );
  };

  const renderPriceSync = () => {
    let selectedDeal = null;
    if (props.fullDealInfo && deal) {
      const options = props.fullDealInfo[email];
      if (options) {
        selectedDeal = options.find((d: any) => {
          return _.isEqual(d.dealId, (deal as any).value);
        });
      }
    }
    return (
      <PriceSyncCard
        deal={selectedDeal}
        courseCurrency={props.courseCurrency}
        course={course}
        coursePrice={props.coursePrice}
      />
    );
  };

  useEffect(() => {
    infoReady &&
      props.courseCurrency !== null &&
      setCurrency(
        CURRENCY_OPTIONS.find((curr) => {
          return curr.value === props.courseCurrency;
        })
      );
  }, [infoReady, props.courseCurrency]);

  return (
    <div className="change-price-container">
      <h1>Course Pricing Panel</h1>
      <FindStudentWithEmail
        label="Instructor Email"
        email={email}
        changeEmail={changeEmail}
        changeCourse={setCourse}
        changeCourseOptions={setCourseAndDeals}
      />
      <div className="select-wrapper">
        <div className="select-container">
          <FindCourseFromOptions
            email={email}
            course={course}
            changeCourse={setCourse}
            courseOptions={courseOptions}
            handleVerifyCourse={getInfo}
          />
          {infoReady && (
            <InlineInformation
              title={`Here is what we know about this course in Kritik:\nCurrency: ${props.courseCurrency.toUpperCase()}\nPrice: ${(
                props.coursePrice / 100
              ).toFixed(2)}\nTime Zone: ${props.institution.identifier}\nSales Tax: ${
                props.institution.salesTax
              }%
          `}
            />
          )}
        </div>
        <div className="select-container">
          <FindDealFromOptions
            deal={deal}
            changeDeal={changeDeal}
            dealOptions={parsedDealOptions()}
          />
        </div>
        {getDealInfo()}
      </div>
      <br />
      <div className="price-edit-cards">
        {renderKritikPriceChange()}
        {renderPriceSync()}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    institution: state.admin.institution,
    courseCurrency: state.admin.courseCurrency,
    coursePrice: state.admin.coursePrice,
    fullDealInfo: state.admin.hubspotDeals,
  };
};
export default connect(mapStateToProps, {
  getCourseAndInstitutionInfo,
  changeCoursePriceAndCurrency,
  getHubspotDealsByEmail,
  updateHubspotDeal,
})(ChangeCoursePrice);
