import React from 'react';
import { EvaluationScoreView } from 'components/Creation/GradeView';

function EvaluationScoreDisplay(props: any) {
  const renderScorePanel = () => {
    return (
      <EvaluationScoreView
        assignment={props.activity}
        submissionsToEvaluate={props.submissionsToEvaluate}
        student={props.student}
        course={props.course}
        submission={props.creation}
      />
    );
  };
  return renderScorePanel();
}

export default EvaluationScoreDisplay;
