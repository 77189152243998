import React from 'react';
import classNames from 'classnames';
import ScoreDecorator from 'components/Assignment/Scores/ScoreDecorator';
import * as FormatUtils from '@kritik/utils/format';

const ScoreItem = (props: any) => {
  const classes = classNames(
    'display-box-score-item',
    {
      'display-box-score-item--lg': props.size === 'lg',
    },
    props.className
  );
  return (
    <div className={classes} data-testid={props.testid}>
      <div className="display-box-score-item__head">
        <div className="display-box-score-item__title" data-testid="score-item-title">
          {props.title}
          <span className="display-box-score-item__subtitle" data-testid="score-item-subtitle">
            {props.subtitle}
          </span>
        </div>
        <div className="display-box-score-item__description" data-testid="score-item-description">
          {props.description}
        </div>
      </div>
      <ScoreDecorator size={props.size}>{FormatUtils.simplePercent(props.value)}</ScoreDecorator>
    </div>
  );
};

ScoreItem.defaultProps = {
  size: 'default',
  subtitle: '',
};

export default ScoreItem;
