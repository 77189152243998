import {
  getRestrictedFileStatus,
  isMultipleTopics,
  getActivityTopics,
  isNumericGrading,
  acceptsLateSubmissions,
  autoAcceptsLateSubmissions,
} from '@kritik/utils/activity';
import { mimeTypes } from 'components/Assignment/constants';

import {
  DEFAULT_NUM_EVALS_TO_ASSIGN,
  DEFAULT_WEIGHT,
  fileRestrictionStatus,
} from '@kritik/constants/activity';
import { FIELD_NAME as WEIGHT } from 'components/ActivityEdit/AdvancedOptions/Fields/Weight/constants';
import {
  FIELD_NAME as TOPICS,
  IS_MULTIPLE_TOPICS,
  NUMBER_OF_TOPICS,
} from 'components/ActivityEdit/AdvancedOptions/Fields/Topics/constants';
import { FIELD_NAME as NUM_EVALS_TO_ASSIGN } from 'components/ActivityEdit/AdvancedOptions/Fields/NumEvalsToAssign/constants';
import * as courseUtil from '@kritik/utils/course';
import {
  ACCEPT_LATE_CREATIONS,
  AUTO_ACCEPT_LATE_CREATIONS,
  ACCEPT_LATE_EVALUATIONS,
  AUTO_ACCEPT_LATE_EVALUATIONS,
} from 'components/LateCreation/constants';
import { defaultFileRestrictions, allFilesRestricted } from './constants';
import { FIELD_NAME as removeStudentFromActivityAfterMissedCreation } from 'components/ActivityEdit/AdvancedOptions/Fields/MissedCreation/constants';
import { Activity, Course } from 'old-common/types.generated';
import { DEFAULT_LATE_PENALTY_PERCENTAGE } from 'common/constants/activity';

export const getInitialAdvOptionValues = (activity: Activity, course: Course) => {
  let fields = {};
  if (activity) {
    fields = {
      [NUM_EVALS_TO_ASSIGN]: activity.numEvaluationsToAssign,
      numericGrading: isNumericGrading(activity),
      restrictFileTypes: getRestrictedFileStatus(activity.fileExtensionsAllowed),
      [WEIGHT]: activity.weight,
      [IS_MULTIPLE_TOPICS]: isMultipleTopics(activity),
      [NUMBER_OF_TOPICS]: activity.topics.length,
      [TOPICS]: getActivityTopics(activity),
      ...getInitialFileRestrictions(activity.fileExtensionsAllowed),
      [ACCEPT_LATE_CREATIONS]: acceptsLateSubmissions(activity),
      [AUTO_ACCEPT_LATE_CREATIONS]: autoAcceptsLateSubmissions(activity),
      [ACCEPT_LATE_EVALUATIONS]: Boolean(activity.acceptLateEvaluations),
      [AUTO_ACCEPT_LATE_EVALUATIONS]: Boolean(activity.autoAcceptLateEvaluations),
      [removeStudentFromActivityAfterMissedCreation]:
        activity.removeStudentFromActivityAfterMissedCreation,
      startingScore: activity.startingScore,
      lateCreationPenaltyPercentage: activity.lateCreationPenaltyPercentage,
    };
  } else {
    fields = {
      [NUM_EVALS_TO_ASSIGN]: DEFAULT_NUM_EVALS_TO_ASSIGN,
      numericGrading: true,
      restrictFileTypes: fileRestrictionStatus.UNRESTRICTED,
      [WEIGHT]: course ? courseUtil.getDefaultActivityWeight(course) : DEFAULT_WEIGHT,
      [IS_MULTIPLE_TOPICS]: false,
      [NUMBER_OF_TOPICS]: 2,
      [TOPICS]: [],
      ...defaultFileRestrictions,
      [ACCEPT_LATE_CREATIONS]: courseUtil.acceptsLateSubmissions(course),
      [AUTO_ACCEPT_LATE_CREATIONS]: courseUtil.autoAcceptsLateSubmissions(course),
      [ACCEPT_LATE_EVALUATIONS]: Boolean(course.acceptLateEvaluations),
      [AUTO_ACCEPT_LATE_EVALUATIONS]: Boolean(course.autoAcceptLateEvaluations),
      [removeStudentFromActivityAfterMissedCreation]: false,
      startingScore: course.startingScore,
      lateCreationPenaltyPercentage: DEFAULT_LATE_PENALTY_PERCENTAGE,
    };
  }
  return fields;
};

export const getInitialPresentationActivityAdvancedOptionValues = (activity: any, course: any) => {
  let fields = {};
  if (activity) {
    fields = {
      restrictFileTypes: getRestrictedFileStatus(activity.fileExtensionsAllowed),
      ...getInitialFileRestrictions(activity.fileExtensionsAllowed),
      [WEIGHT]: activity.weight,
    };
  } else {
    fields = {
      restrictFileTypes: fileRestrictionStatus.UNRESTRICTED,
      ...defaultFileRestrictions,
      [WEIGHT]: course ? courseUtil.getDefaultActivityWeight(course) : DEFAULT_WEIGHT,
    };
  }
  return fields;
};

export const getInitialFileRestrictions = (fileExtensionsAllowed: any) => {
  const restrictFileTypes = getRestrictedFileStatus(fileExtensionsAllowed);
  let extensions = { ...allFilesRestricted };
  if (restrictFileTypes === fileRestrictionStatus.SOMEFILES) {
    fileExtensionsAllowed.forEach((extension: any) => {
      for (const key in mimeTypes) {
        if (mimeTypes[key].includes(extension)) {
          extensions[key] = true;
          continue;
        }
      }
    });
  } else {
    extensions = defaultFileRestrictions;
  }

  return extensions;
};

export default {
  getInitialAdvOptionValues,
  getInitialPresentationActivityAdvancedOptionValues,
};
