import { Activity, Creation, Evaluation } from 'old-common/types.generated';
import * as CreationStatusUtil from '@kritik/utils/creation/status';
import { GracePeriodCreationStudentNotice } from 'components/Assignment/NoticeBoards';
import { StudentLateSubmissionNoticeBoard } from './StudentLateSubmissionNoticeBoard';
import { CreationScoreUtil } from '@kritik/utils/grade';

type StudentLateCreationInfoProps = {
  mySubmission: Creation & { scores: Evaluation[] };
  assignment: Activity;
};

export function StudentLateCreationInfo(props: StudentLateCreationInfoProps) {
  if (!Boolean(props.mySubmission) || !Boolean(props.assignment)) {
    return null;
  }

  if (CreationStatusUtil.wasCreationSubmittedInGracePeriod(props.mySubmission)) {
    return <GracePeriodCreationStudentNotice />;
  }

  if (!Boolean(props.mySubmission.lateSubmission)) {
    return null;
  }

  const lateSubmission = {
    createdAt: props.mySubmission.createdAt,
    resolvedDate: props.mySubmission.lateSubmission.resolvedDate,
    reason: props.mySubmission.lateSubmission.reason,
  };

  const type = props.mySubmission.lateSubmission.status;
  const createStage = props.assignment.statuses.find((s) => s.name === 'Create');
  let testid = 'pending-late-creation';
  if (type === 'rejected') {
    testid = 'rejected-late-creation';
  }
  if (type === 'accepted') {
    testid = 'accepted-late-creation';
  }
  return (
    <StudentLateSubmissionNoticeBoard
      type={type}
      testid={testid}
      stageEndDate={createStage.endDate}
      lateSubmission={lateSubmission}
      submissionType="creation"
      lateCreationPenaltyPercentage={CreationScoreUtil.getAppliedLateCreationPenaltyPercentage({
        creation: props.mySubmission,
        lateCreationPenaltyPercentage: props.assignment.lateCreationPenaltyPercentage,
      })}
    />
  );
}
