import { SpotlightTag } from '@kritik/types.generated';
import { useQuery, useMutation } from '@tanstack/react-query';

import SpotlightTagService from 'services/spotlightTag';
import { spotlightService } from 'services';

const service = new SpotlightTagService();

export function useGetSpotlightTags({ type }: { type: string }) {
  return useQuery(['getSpotlightTags'], async () => {
    const response = await service.list({ type });
    return response.data as SpotlightTag[];
  });
}

export function usePublishSpotlight() {
  return useMutation({
    mutationFn: (spotlightId: string) => spotlightService().publish(spotlightId),
  });
}
