import React from 'react';
import SchedulerArrow from 'components/Assignment/scheduler/SchedulerArrow';

const Schedule = (props: any) => {
  const renderArrow = () => {
    if (props.hideArrow) {
      return null;
    }
    return (
      <div className="scheduler-separator__interval">
        <SchedulerArrow
          startDate={props.startDate}
          endDate={props.endDate}
          onChange={(interval: any) => {
            props.onChange(interval);
          }}
          tooltip={props.tooltip}
          footnote={props.footnote}
          testid={props.testid}
        />
      </div>
    );
  };
  return (
    <div className="scheduler-separator">
      <div className="scheduler-separator__img">{props.image}</div>
      {renderArrow()}
    </div>
  );
};

Schedule.defaultProps = {
  footnote: '',
  tooltip: '',
  locale: null,
};

export default Schedule;
