import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Button from 'components/buttons/Button';
import { closeGlobalModal } from 'actions/modals';
import { generateGroupSet } from 'actions/groups';
import { getCourse } from 'selectors/course';
import NoticeBoard from 'components/layout/NoticeBoard';
import InlineInformation from 'components/layout/InlineInformation';
import get from 'lodash/get';
import { GROUP_GENERATE_SET } from 'types';

export const MODAL_ID = 'generate-group-set-modal';

const mapStateToProps = (state: any) => {
  return {
    modalStatus: state.modal,
    course: getCourse(state),
    generateBusy: state.async[GROUP_GENERATE_SET].busy,
    generateSuccess: state.async[GROUP_GENERATE_SET].success,
    generateError: state.async[GROUP_GENERATE_SET].error,
  };
};

type GenerateGroupSetModalState = any;

class GenerateGroupSetModal extends React.Component<{}, GenerateGroupSetModalState> {
  state = {
    nameOfSet: '',
    maxMembersPerGroup: 5,
    error: null,
  };

  isOpen = () => {
    return (this.props as any).modalStatus.options.props.id === MODAL_ID;
  };

  cancel = () => {
    this.setState({ maxMembersPerGroup: 5, nameOfSet: '', error: null });
    (this.props as any).closeGlobalModal();
  };

  onValueChange = (ev: any) => {
    if (this.state.error != null) {
      this.setError(null);
    }
    if (ev.target.name === 'maxMembersPerGroup') {
      const number = Number(ev.target.value);
      if (!number || number < 0) {
        ev.target.value = 1;
      }
    }
    this.setState({ [ev.target.name]: ev.target.value });
  };

  setError(error: any) {
    this.setState({ error });
  }

  generateGroups = () => {
    if (!(this.props as any).generateBusy) {
      (this.props as any)
        .generateGroupSet({
          courseId: (this.props as any).course._id,
          nameOfSet: this.state.nameOfSet,
          maxMembersPerGroup: this.state.maxMembersPerGroup,
        })
        .then((res: any) => {
          if (res) {
            this.setError(get(res, 'response.data', 'Something went wrong'));
          } else {
            this.setState({ maxMembersPerGroup: 5, nameOfSet: '' });
            (this.props as any).closeGlobalModal();
          }
        });
    }
  };

  renderErrorMessage = (errorMessage: any) => {
    if (!errorMessage) {
      return null;
    }
    return (
      <InlineInformation type="danger" testid="group-set-error-message">
        {this.state.error}
      </InlineInformation>
    );
  };

  render() {
    return (
      <Dialog open={Boolean(this.isOpen())} maxWidth="xs" fullWidth>
        <div className="generate-set-modal">
          <div id="simple-dialog-title" className="generate-set-modal__header">
            Create a new set of groups
          </div>
          Group members will be randomly selected from the{' '}
          {(this.props as any).course.students.length} students currently enrolled in this course.
          <div className="generate-set-modal__input">
            <div className="group-set-name-label"> Group Set Name </div>
            <input
              className="group-set-name-input"
              name="nameOfSet"
              value={this.state.nameOfSet}
              onChange={(ev) => {
                return this.onValueChange(ev);
              }}
              placeholder="Enter name..."
              data-testid="group-set-name-input"
            />
          </div>
          <div className="generate-set-modal__input same-line">
            <label className="group-set-members-label"> How many members per group? </label>
            <input
              type="number"
              min="1"
              className="generate-set-modal__input-size"
              name="maxMembersPerGroup"
              value={this.state.maxMembersPerGroup}
              onChange={(ev) => {
                return this.onValueChange(ev);
              }}
            />
          </div>
          {(this.props as any).course.approvedStudents.length > 0 && (
            <NoticeBoard type="caution" title="">
              {(this.props as any).course.approvedStudents.length} pending students will not be
              added to your groups. You must add them manually when they sign up.
            </NoticeBoard>
          )}
          {this.renderErrorMessage(this.state.error)}
          <div className="generate-set-modal__buttons">
            <Button
              type="primary"
              onClick={() => {
                return this.generateGroups();
              }}
              disabled={(this.props as any).generateBusy}
              loading={(this.props as any).generateBusy}
              success={(this.props as any).generateSuccess}
              data-testid="confirm-create-group-set"
            >
              Create Groups
            </Button>
            <Button
              type="secondary"
              onClick={() => {
                return this.cancel();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default connect(mapStateToProps, { closeGlobalModal, generateGroupSet })(
  GenerateGroupSetModal
);
