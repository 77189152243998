import React from 'react';
import Intercom from 'react-intercom';

function LiveChat({
  email,
  userHash,
  userId,
  appID,
}: {
  email: string;
  userHash: string;
  userId: string;
  appID: string;
}) {
  if (!appID) {
    return null;
  }

  return (
    <Intercom
      appID={appID}
      email={email}
      user_id={userId}
      user_hash={userHash}
      custom_launcher_selector="#intercom-custom-launch"
    />
  );
}

LiveChat.defaultProps = {
  appID: '',
};

export default LiveChat;
