import React from 'react';
import * as _ from 'lodash-es';
import FormFieldLabel from 'components/core/form/FieldLabel';

const NumberInput = (props: any) => {
  const id = React.useId();
  const sanitizeInputChange = (e: any) => {
    let value = e.target.value ? e.target.value : 0;

    if (props.max) {
      value = Math.min(value, props.max);
    }
    if (props.min) {
      value = Math.max(value, props.min);
    }
    if (props.maxDecimals) {
      value = _.round(value, props.maxDecimals);
    }
    e.target.value = value;
    return e;
  };

  return (
    <React.Fragment>
      {props.label && (
        <FormFieldLabel
          label={props.label}
          isRequired={props.isRequired}
          id={props.id || id}
          className={props.className}
        />
      )}
      <input
        className="kritik-form-field__number-input"
        type="number"
        min={props.min}
        max={props.max}
        step={props.step}
        value={props.value}
        name={props.name}
        onChange={(e) => {
          return props.onChange(sanitizeInputChange(e));
        }}
        onBlur={props.onBlur}
        disabled={props.disabled}
        data-testid={props.testid}
        id={props.id || id}
        aria-label={props['aria-label']}
        aria-describedby={props['aria-describedby']}
      />
    </React.Fragment>
  );
};

NumberInput.defaultProps = {
  min: 0,
  max: null,
  step: 1,
  disabled: false,
  maxDecimals: 0,
};

export default NumberInput;
