export default {
  SET_SELECTED_RUBRIC: 'SET_SELECTED_RUBRIC',
  DELETE_RUBRIC: 'DELETE_RUBRIC',
  DELETE_RUBRIC_SUCCESS: 'DELETE_RUBRIC_SUCCESS',
  DELETE_RUBRIC_FAILURE: 'DELETE_RUBRIC_FAILURE',
  COPY_RUBRIC: 'COPY_RUBRIC',
  COPY_RUBRIC_SUCCESS: 'COPY_RUBRIC_SUCCESS',
  COPY_RUBRIC_FAILURE: 'COPY_RUBRIC_FAILURE',
  SET_IS_TEMPLATE_EDIT: 'SET_IS_TEMPLATE_EDIT',
  CREATE_NEW_BASIC_TEMPLATE: 'CREATE_NEW_BASIC_TEMPLATE',
  CREATE_NEW_BASIC_TEMPLATE_SUCCESS: 'CREATE_NEW_BASIC_TEMPLATE_SUCCESS',
  CREATE_NEW_BASIC_TEMPLATE_FAILURE: 'CREATE_NEW_BASIC_TEMPLATE_FAILURE',
};
