import React from 'react';
import createSvgIcon from '../util/generateIconSVG';

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M0 13.5C0 6.04416 6.04416 0 13.5 0H30.5C37.9558 0 44 6.04416 44 13.5C44 20.9558 37.9558 27 30.5 27H13.5C6.04416 27 0 20.9558 0 13.5Z"
      fill="#DCDBF0"
    />
    <path
      d="M28.7456 8.75L33.2456 17H24.2456L28.7456 8.75ZM13.7456 8.75L18.2456 17H9.24561L13.7456 8.75ZM23.3667 6.5H29.1206C29.3315 6.5 29.4956 6.33594 29.4956 6.125V5.375C29.4956 5.16406 29.3315 5 29.1206 5H23.3667C23.0503 4.12109 22.23 3.5 21.2456 3.5C20.2612 3.5 19.4409 4.12109 19.1245 5H13.3706C13.1597 5 12.9956 5.16406 12.9956 5.375V6.125C12.9956 6.33594 13.1597 6.5 13.3706 6.5H19.1245C19.3472 7.13281 19.8628 7.64844 20.4956 7.87109V23H13.3706C13.1597 23 12.9956 23.1641 12.9956 23.375V24.125C12.9956 24.3359 13.1597 24.5 13.3706 24.5H29.1206C29.3315 24.5 29.4956 24.3359 29.4956 24.125V23.375C29.4956 23.1641 29.3315 23 29.1206 23H21.9956V7.87109C22.6284 7.64844 23.144 7.13281 23.3667 6.5ZM21.2456 6.6875C20.73 6.6875 20.3081 6.26562 20.3081 5.75C20.3081 5.23437 20.73 4.8125 21.2456 4.8125C21.7612 4.8125 22.1831 5.23437 22.1831 5.75C22.1831 6.26562 21.7612 6.6875 21.2456 6.6875ZM33.9956 17C33.9956 16.543 29.9058 9.30078 29.4019 8.38672C29.2729 8.15234 29.0151 8 28.7456 8C28.4761 8 28.2183 8.15234 28.0894 8.38672C27.5854 9.30078 23.4956 16.543 23.4956 17C23.4956 19.4141 26.8355 20.375 28.7456 20.375C30.6558 20.375 33.9956 19.4141 33.9956 17ZM18.9956 17C18.9956 16.543 14.9058 9.30078 14.4019 8.38672C14.2729 8.15234 14.0151 8 13.7456 8C13.4761 8 13.2183 8.15234 13.0894 8.38672C12.5854 9.30078 8.49561 16.543 8.49561 17C8.49561 19.4141 11.8354 20.375 13.7456 20.375C15.6558 20.375 18.9956 19.4141 18.9956 17Z"
      fill="#302D6C"
    />
  </React.Fragment>,
  '0 0 44 27'
);
