import { getCourse } from 'selectors/course';
import { getAssignment } from 'selectors/activity';
import { getStudentFromUser } from 'selectors/student';
import * as ActivityUtils from '@kritik/utils/activity';

export const selectGradeHistories = (state: any) => {
  const course = getCourse(state);
  let gradeHistories;
  if (course.userRole === 'instructor') {
    gradeHistories = course.gradeHistories;
  } else {
    const myStudent = getStudentFromUser(state, course._id);
    gradeHistories = myStudent.gradeHistories;
  }
  return gradeHistories || [];
};

export const selectDashboardGrades = (state: any) => {
  return {
    avgOverallGrade: state.course.dashboard.avgOverallGrade,
    avgCreationGrade: state.course.dashboard.avgCreationGrade,
    avgEvalGrade: state.course.dashboard.avgEvalGrade,
    avgFeedbackGrade: state.course.dashboard.avgFeedbackGrade,
  };
};

export default {
  selectGradeHistories,
  selectDashboardGrades,
};
