import React from 'react';
import classNames from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary,',
};

const CheckboxInput = (props: any) => {
  const classes = classNames('input-checkbox', {
    'input-checkbox--disabled': props.isDisabled,
  });
  return (
    <FormControlLabel
      className={classes}
      disabled={props.isDisabled}
      control={
        <Checkbox
          data-testid={props.testid}
          // @ts-expect-error TS(2322) FIXME: Type '{ "data-testid": any; disableRipple: true; p... Remove this comment to see the full error message
          p={0}
          checked={props.isChecked}
          onChange={props.onChange}
          color={props.isDisabled ? 'default' : props.type}
          className="input-checkbox__box"
        />
      }
      label={props.label}
    />
  );
};

CheckboxInput.defaultProps = {
  isDisabled: false,
  isChecked: false,
  type: TYPES.PRIMARY,
};

export default CheckboxInput;
