function getLmsConnectedActivity(
  lmsColumns: { activityId: string }[],
  activitySyncInfo: { connectedActivityId: string }
) {
  const { connectedActivityId } = activitySyncInfo;
  if (!connectedActivityId) {
    return null;
  }
  const column = lmsColumns.find((_column: any) => {
    return _column.activityId === connectedActivityId;
  });
  return column;
}

function mapLineItems(lmsColumns: any[]) {
  const mappedLineItems = lmsColumns.map((column: any) => {
    return { id: column.activityId, label: column.title };
  });
  return mappedLineItems;
}

function mapSelectedColumn(column: any) {
  return { id: column.activityId, label: column.title };
}

function getSelectedLineItem(activityGradeSyncInformation: any, lmsColumns: any) {
  const column = getLmsConnectedActivity(lmsColumns, activityGradeSyncInformation);
  if (column) {
    return mapSelectedColumn(column);
  }
  return column;
}

function isActivitySyncedWithLms(activityGradeSyncInformation: any) {
  const isSynced =
    activityGradeSyncInformation.lastSyncAt && activityGradeSyncInformation.lastSyncAt.length > 0;
  return isSynced;
}

function isActivitySyncedWithLmsNoError(activityGradeSyncInformation: any) {
  return (
    isActivitySyncedWithLms(activityGradeSyncInformation) &&
    activityGradeSyncInformation?.syncStatus === 'success'
  );
}

function isActivitySyncedWithLmsWithError(activityGradeSyncInformation: any) {
  return (
    isActivitySyncedWithLms(activityGradeSyncInformation) &&
    activityGradeSyncInformation?.syncStatus === 'error'
  );
}

function getColumnLabelByColumnId({ columnId, lmsColumns }: any) {
  const item = lmsColumns.find((column: any) => {
    return column.activityId === columnId;
  });
  if (item) {
    return item.title;
  }
  return '';
}

export default {
  getLmsConnectedActivity,
  mapLineItems,
  mapSelectedColumn,
  getSelectedLineItem,
  isActivitySyncedWithLms,
  isActivitySyncedWithLmsNoError,
  isActivitySyncedWithLmsWithError,
  getColumnLabelByColumnId,
};
