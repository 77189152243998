import React from 'react';
import classNames from 'classnames';

const DisplayBoxDivider = ({ className }: any) => {
  const panelClasses = classNames('display-box-divider', className);

  return <div tabIndex={-1} className={panelClasses} />;
};

export default DisplayBoxDivider;
