import { combineReducers } from 'redux';

import * as modalTypes from 'types/modal';

const isOpen = (state = false, action: any) => {
  switch (action.type) {
    case modalTypes.OPEN_GLOBAL_MODAL:
      return true;

    case modalTypes.CLOSE_GLOBAL_MODAL:
      return false;

    default:
      return state;
  }
};

const options = (
  state = {
    title: '',
    content: '',
    props: {},
    actions: [],
    type: 'alert',
    callback: false,
    messageId: '',
  },
  action: any
) => {
  switch (action.type) {
    case modalTypes.OPEN_GLOBAL_MODAL:
      if (action.payload) {
        return action.payload;
      }
      return state;
    case modalTypes.CLOSE_GLOBAL_MODAL:
      return {
        title: '',
        content: '',
        props: {},
        actions: [],
        type: 'alert',
        callback: false,
        messageId: '',
      };

    default:
      return state;
  }
};

const initialInfoModalState = {
  open: false,
  title: '',
  content: '',
  props: {},
  actions: [],
  type: 'info',
  callback: false,
  messageId: '',
};

function infoModal(state = initialInfoModalState, action: any) {
  switch (action.type) {
    case modalTypes.OPEN_INFO_MODAL: {
      const { title, content, actions, type, callback, messageId, onClose } = action.payload;

      return {
        ...state,
        open: true,
        title,
        content,
        onClose,
        actions,
        type,
        callback,
        messageId,
      };
    }
    case modalTypes.CLOSE_INFO_MODAL: {
      return {
        ...state,
        open: false,
      };
    }
    case modalTypes.INIT_INFO_MODAL: {
      return initialInfoModalState;
    }
    default:
      return state;
  }
}

const initialErrorModalState = {
  open: false,
  title: '',
  content: '',
};

function errorModal(state = initialErrorModalState, action: any) {
  switch (action.type) {
    case modalTypes.OPEN_ERROR_MODAL: {
      const { title, content, btnText, handleClose } = action.payload;
      return {
        ...state,
        open: true,
        title,
        content,
        btnText,
        handleClose,
      };
    }
    case modalTypes.CLOSE_ERROR_MODAL: {
      return {
        ...state,
        open: false,
      };
    }
    default:
      return state;
  }
}

const initialLoadingModalState = {
  open: false,
  title: '',
  content: '',
};

function loadingModal(state = initialLoadingModalState, action: any) {
  switch (action.type) {
    case modalTypes.OPEN_LOADING_MODAL: {
      const { title, content } = action.payload;
      return {
        ...state,
        open: true,
        title,
        content,
      };
    }
    case modalTypes.CLOSE_LOADING_MODAL: {
      return {
        ...state,
        open: false,
      };
    }
    case modalTypes.INIT_LOADING_MODAL: {
      return initialLoadingModalState;
    }
    default:
      return state;
  }
}

const initialConfirmModalState = {
  isOpen: false,
  title: '',
  description: '',
  onCancel: null,
  onConfirm: null,
  // these are actually confirm/cancel button text
  confirmButton: '',
  cancelButton: '',
  children: null,
};

function confirmModal(state = initialConfirmModalState, action: any) {
  switch (action.type) {
    case modalTypes.OPEN_CONFIRM_MODAL: {
      const { title, description, confirmButton, cancelButton, onConfirm, children } =
        action.payload;
      return {
        ...state,
        isOpen: true,
        title,
        description,
        confirmButton,
        cancelButton,
        onConfirm,
        children,
      };
    }
    case modalTypes.CLOSE_CONFIRM_MODAL: {
      return {
        ...state,
        isOpen: false,
      };
    }
    case modalTypes.INIT_CONFIRM_MODAL: {
      return initialConfirmModalState;
    }
    default:
      return state;
  }
}

const modalReducer = combineReducers({
  isOpen,
  options,
  errorModal,
  infoModal,
  loadingModal,
  confirmModal,
});

// Rename it to modal reducer from answer reducer since it makes more sense
export default modalReducer;
