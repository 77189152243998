import React, { useState } from 'react';
import moment from 'moment-timezone';
import Select from 'components/core/input/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { CustomGracePeriodModal } from 'components/Assignment/scheduler';
import { timeDisplayFromMinutes, getMinutesBetweenDates } from '@kritik/utils/format';

const GRACE_PERIOD_OPTIONS = [
  { text: '2 Hours Grace', value: 2 * 60 },
  { text: '12 Hours Grace', value: 12 * 60 },
  { text: '1 Day Grace', value: 24 * 60 },
  { text: 'Custom', value: 'CUSTOM' },
];

function GracePeriodSelect({
  selectedGracePeriod,
  handleSelect,
  disabled,
  creationEndDate,
  testid,
}: any) {
  const [isSelectingCustom, setIsSelectingCustom] = useState(false);

  const getLabelForCustomPeriod = (startDate: any, customPeriod: any) => {
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    const customDate = new Date(moment(startDate).add(customPeriod, 'minutes'));
    const minutesBetween = getMinutesBetweenDates(customDate, startDate);
    return timeDisplayFromMinutes(minutesBetween);
  };

  const options = GRACE_PERIOD_OPTIONS.map((defaultOption) => {
    const option = {
      disabled: false,
      label: defaultOption.text,
      value: defaultOption.value,
    };
    if (moment() > moment(creationEndDate).add(defaultOption.value, 'minutes')) {
      // disable the options if currentDate > graceperiod startDate + grace hours
      // prevent prof select a pass grace period
      if (defaultOption.value !== 'CUSTOM') {
        option.disabled = true;
      }
    }
    return option;
  });

  const handleChange = (ev: any) => {
    if (ev.target.value === 'CUSTOM') {
      setIsSelectingCustom(true);
    } else {
      handleSelect(ev.target.value);
    }
  };

  const setCustomGracePeriod = (date: any) => {
    const minutesBetween = getMinutesBetweenDates(date, creationEndDate);
    const newEntryLabel = timeDisplayFromMinutes(minutesBetween);
    GRACE_PERIOD_OPTIONS.splice(-1, 0, {
      text: newEntryLabel,
      value: minutesBetween,
    });
    setIsSelectingCustom(false);
    handleSelect(minutesBetween);
  };

  const cancelCustomGracePeriod = () => {
    handleSelect(GRACE_PERIOD_OPTIONS[0].value);
    setIsSelectingCustom(false);
  };

  if (creationEndDate && selectedGracePeriod) {
    const isIncluded = options.find((option) => {
      if (option.value === selectedGracePeriod) {
        return true;
      }
      return false;
    });
    if (!isIncluded) {
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ label: string; value: any; }' ... Remove this comment to see the full error message
      options.push({
        label: getLabelForCustomPeriod(creationEndDate, selectedGracePeriod),
        value: selectedGracePeriod,
      });
    }
  }

  return (
    <React.Fragment>
      <div className="scheduler-grace-period__select">
        <Select
          disabled={disabled}
          value={selectedGracePeriod}
          onChange={handleChange}
          testid={testid ? testid : 'scheduler-grace-period-select'}
        >
          {options.map((option, idx) => {
            return (
              <MenuItem
                key={option.value}
                value={option.value}
                data-testid={`scheduler-grace-period-option-${idx}`}
              >
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <CustomGracePeriodModal
        isOpen={isSelectingCustom}
        onSelect={setCustomGracePeriod}
        onCancel={cancelCustomGracePeriod}
        minDate={creationEndDate}
      />
    </React.Fragment>
  );
}

GracePeriodSelect.defaultProps = {
  disabled: false,
};

export default GracePeriodSelect;
