import React from 'react';
import { FormMainError } from 'components/Form/FormComponents';

const ShowErrorMessage = () => {
  return (
    <FormMainError
      message="We're sorry we couldn't find a creation with the given information. Please check any typos or unexpected whitespaces."
      isOpen
    />
  );
};

export default ShowErrorMessage;
