import React from 'react';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { selectActivityStatsById } from 'selectors/activity';
import { getProgress } from 'components/AssignmentLabels/utils';
import { simplePercent } from '@kritik/utils/format';
import { assignmentStatuses } from '@kritik/constants/stage';
import { useUserRoleInCourse } from 'hooks/course';

function ProgressText({ progress, statusName }: any) {
  const namesMap = {
    Create: 'Creations',
    Evaluate: 'Evaluations',
    Feedback: 'Feedbacks',
  };
  return (
    <div className="progress-text">
      <div className="progress-text__title">{statusName} Stage</div>
      <div className="progress-text__content">
        {simplePercent(progress)} of {namesMap[statusName]} Completed.
      </div>
    </div>
  );
}

function ProgressTooltip({ activity, stats, status, children }: any) {
  const { isStudentInCourse } = useUserRoleInCourse();
  if (!stats || status.name === assignmentStatuses.PROCESSING3 || isStudentInCourse) {
    return children;
  }
  const progress = getProgress({
    activity,
    activityStats: stats,
    statusName: status.name,
  });

  return (
    <Tooltip title={<ProgressText progress={progress} statusName={status.name} />}>
      {children}
    </Tooltip>
  );
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    stats: selectActivityStatsById(state, ownProps.activity._id),
  };
};

export default connect(mapStateToProps)(ProgressTooltip);
