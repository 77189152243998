import { useMutation, useQuery } from '@tanstack/react-query';
import { trackEvent } from 'utils/userEvents';
import AiService from 'services/ai';
import { AiGeneratedActivity, AuthUser } from 'app-types';

type UseFetchGenerateActivityIdeasArgs = {
  courseCode?: string;
  courseName: string;
  activityType: string;
  syllabusFileId: string;
  numActivities?: number;
  moreLessLikeThis?: ai.generate_activity_ideas.POST.Request['moreLessLikeThis'];
  onSuccess: (data: any) => void;
};
export function useFetchGenerateActivityIdeas({
  courseCode,
  courseName,
  activityType,
  syllabusFileId,
  numActivities,
  moreLessLikeThis,
  onSuccess,
}: UseFetchGenerateActivityIdeasArgs) {
  return useQuery(
    ['generateActivityIdeas', courseName, activityType, syllabusFileId, moreLessLikeThis],
    async () => {
      const response = await AiService().generateActivityIdeas({
        courseCode,
        courseName,
        activityType,
        syllabusFileId,
        numActivities,
        moreLessLikeThis,
      });
      return response.data;
    },
    {
      enabled: Boolean(courseName),
      onSuccess,
      cacheTime: 0,
      staleTime: 0,
    }
  );
}

export function createAiActivityMutation({
  authUser,
  courseId,
}: {
  authUser: AuthUser;
  courseId: string;
}) {
  return useMutation({
    mutationFn: async ({
      idea,
      isProblemSet,
      isTeachAPeer,
      courseId,
      activityType,
      syllabusFileId,
    }: {
      idea: string;
      isProblemSet: boolean;
      isTeachAPeer: boolean;
      courseId: string;
      activityType: string;
      syllabusFileId: string;
    }) => {
      const response = await AiService().createActivity({
        idea,
        isProblemSet,
        isTeachAPeer,
        courseId,
        activityType,
        syllabusFileId,
      });
      return response.data;
    },
    onSuccess: (data: any) => {
      trackEvent('AI Created Activity', authUser, {
        courseId,
        activityId: data.activityId,
      });
    },
  });
}

export function createAiCourseMutation({ authUser }: { authUser: AuthUser }) {
  type CreateCourseMutationFn = {
    courseName: string;
    generatedActivities: AiGeneratedActivity[];
    syllabusFileId: string;
  };
  return useMutation({
    mutationFn: async ({
      courseName,
      generatedActivities,
      syllabusFileId,
    }: CreateCourseMutationFn) => {
      const response = await AiService().createCourse({
        courseName,
        generatedActivities,
        syllabusFileId,
      });
      return {
        ...response.data,
        courseName,
      };
    },
    onSuccess: (data: { courseId: string; courseName: string }) => {
      trackEvent('AI Created Course', authUser, {
        courseName: data.courseName,
        courseId: data.courseId,
      });
    },
  });
}

export function createAiRubricMutation({
  authUser,
  courseId,
  onSuccess,
  onError,
}: {
  authUser: AuthUser;
  courseId: string;
  onSuccess: (data: any) => void;
  onError: (data: any) => void;
}) {
  return useMutation({
    mutationFn: async ({
      courseId,
      activityTitle,
      learningObjectives,
      instructions,
    }: {
      courseId: string;
      activityTitle: string;
      learningObjectives: string;
      instructions: string;
    }) => {
      const response = await AiService().createRubric({
        courseId,
        activityTitle,
        learningObjectives,
        instructions,
      });
      return response.data;
    },
    onSuccess: (data: any) => {
      trackEvent('AI Created Rubric', authUser, {
        courseId,
        rubricId: data.id,
      });
      onSuccess(data);
    },
    onError,
  });
}
