import { combineReducers } from 'redux';
import { generateAsyncReducer } from 'redux/utils';
import types from './types';

const asyncActions = {
  call: [types.GET_ASSIGNMENT],
  success: [types.GET_ASSIGNMENT_SUCCESS],
  error: [types.GET_ASSIGNMENT_FAILURE],
};

export default combineReducers({
  async: generateAsyncReducer(asyncActions),
});
