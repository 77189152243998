export const oldScoreNames = {
  WRITTEN_EVALUATION_SCORE: 'Feedback Score',
  WRITTEN_EVALUATION: 'Evaluation Comments',
};

export const evaluationScoreNamesForTeacher = {
  WRITTEN_EVALUATION_SCORE: 'Written Evaluation Score',
  WRITTEN_EVALUATION: 'Written Evaluation',
};

export const evaluationScoreNamesForStudent = {
  WRITTEN_EVALUATION_SCORE: 'Written Evaluation Score',
  WRITTEN_EVALUATION: 'Your Written Evaluation',
};

export const defineScoreNames = (isInstructor: boolean) => {
  return isInstructor ? evaluationScoreNamesForTeacher : evaluationScoreNamesForStudent;
};
