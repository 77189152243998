import { combineReducers } from 'redux';
import { normalize, denormalize } from 'normalizr';
import { push } from 'router';

import { mergeEntities, updateEntities, replaceEntities } from 'actions/entities';
import { Spotlight } from 'utils/spotlight';

import { spotlightService } from 'services';
import { spotlightSchema } from 'schemas';
import { selectEvaluation } from 'redux/evaluation';
import { getSubmission } from 'selectors/creation';
import { isUserCreationOwner } from '@kritik/utils/creation/general';
import { isUserEvaluationOwner } from '@kritik/utils/creation/evaluation';
import * as activityUtils from '@kritik/utils/activity';
import { getSpotlightEditPage, getSpotlightPage } from 'utils/url';
import { navigateToInstructorCreationView, navigateToActivityPage } from 'actions/activity';
import { selectCurrentActivity } from 'selectors/activity';
import { trackEvent } from 'utils/userEvents';
import { generateAsyncReducer } from './utils';
import { selectCurrentCourse } from 'selectors/course';
import { selectSubmission } from 'actions/select';

export const CREATE_SPOTLIGHT = 'CREATE_SPOTLIGHT';
const CREATE_SPOTLIGHT_SUCCESS = 'CREATE_SPOTLIGHT_SUCCESS';
const CREATE_SPOTLIGHT_FAILURE = 'CREATE_SPOTLIGHT_FAILURE';

export const UPDATE_SPOTLIGHT = 'UPDATE_SPOTLIGHT';
const UPDATE_SPOTLIGHT_SUCCESS = 'UPDATE_SPOTLIGHT_SUCCESS';
const UPDATE_SPOTLIGHT_FAILURE = 'UPDATE_SPOTLIGHT_FAILURE';

export const DELETE_SPOTLIGHT = 'DELETE_SPOTLIGHT';
const DELETE_SPOTLIGHT_SUCCESS = 'DELETE_SPOTLIGHT_SUCCESS';
const DELETE_SPOTLIGHT_FAILURE = 'DELETE_SPOTLIGHT_FAILURE';

export const LIST_SPOTLIGHTS = 'LIST_SPOTLIGHTS';
const LIST_SPOTLIGHTS_SUCCESS = 'LIST_SPOTLIGHTS_SUCCESS';
const LIST_SPOTLIGHTS_FAILURE = 'LIST_SPOTLIGHTS_FAILURE';

export const GET_SPOTLIGHT = 'GET_SPOTLIGHT';
const GET_SPOTLIGHT_SUCCESS = 'GET_SPOTLIGHT_SUCCESS';
const GET_SPOTLIGHT_FAILURE = 'GET_SPOTLIGHT_FAILURE';

const CANCEL_SPOTLIGHT_CREATION = 'CANCEL_SPOTLIGHT_CREATION';

const asyncActions = {
  call: [CREATE_SPOTLIGHT, LIST_SPOTLIGHTS, UPDATE_SPOTLIGHT, DELETE_SPOTLIGHT, GET_SPOTLIGHT],
  success: [
    CREATE_SPOTLIGHT_SUCCESS,
    LIST_SPOTLIGHTS_SUCCESS,
    UPDATE_SPOTLIGHT_SUCCESS,
    DELETE_SPOTLIGHT_SUCCESS,
    GET_SPOTLIGHT_SUCCESS,
  ],
  error: [
    CREATE_SPOTLIGHT_FAILURE,
    LIST_SPOTLIGHTS_FAILURE,
    UPDATE_SPOTLIGHT_FAILURE,
    DELETE_SPOTLIGHT_FAILURE,
    GET_SPOTLIGHT_FAILURE,
  ],
};

export const SET_IS_PREVIEW = 'SET_IS_PREVIEW';

export const navigateToSpotlightEdit = (params: any) => {
  return push(getSpotlightEditPage(params));
};

export const navigateToSpotlight = (params: any) => {
  return push(getSpotlightPage(params));
};

export const setIsPreview = (isPreview: any) => {
  return (dispatch: any) => {
    dispatch({
      type: SET_IS_PREVIEW,
      payload: isPreview,
    });
  };
};

function isPreviewReducer(state = false, action: any) {
  switch (action.type) {
    case SET_IS_PREVIEW: {
      return action.payload;
    }
    default:
      return state;
  }
}

function getSpotlightCreationId({ state, spotlight }: any) {
  if (spotlight.isCreationType()) {
    return spotlight.getReferenceId();
  }
  const evaluation = selectEvaluation(state, spotlight.getReferenceId());
  const creation = getSubmission(state, evaluation.getCreationId());
  return creation._id;
}

export const cancelSpotlightCreation = (spotlight: any) => {
  return (dispatch: any, getState: any) => {
    dispatch({ type: CANCEL_SPOTLIGHT_CREATION });
    const state = getState();
    const activity = selectCurrentActivity(state);
    const creationId = getSpotlightCreationId({ state, spotlight });
    dispatch(
      navigateToInstructorCreationView({
        assignmentId: activity.getId(),
        courseId: activity.getCourseId(),
        creationId,
        groupView: activityUtils.isGroupAssignment(activity),
      })
    );
  };
};

export const createSpotlight = (data: any) => {
  return async (dispatch: any, getState: any) => {
    dispatch({
      type: CREATE_SPOTLIGHT,
    });
    try {
      const res = await spotlightService().create(data);
      if (res.status === 200) {
        trackEvent('Spotlight Created', getState().user.authUser, {
          spotlightType: data.type,
          spotlightNumWords: data.comment.split(' ').length,
          courseId: data.courseId,
        });

        dispatch({
          type: CREATE_SPOTLIGHT_SUCCESS,
        });
        const spotlight = res.data;
        const normalized = normalize(spotlight, spotlightSchema);
        dispatch(mergeEntities(normalized.entities));
        dispatch(setIsPreview(false));
        const state = getState();
        const activity = selectCurrentActivity(state);
        const creationId = getSpotlightCreationId({ state, spotlight: data });
        dispatch(
          navigateToInstructorCreationView({
            assignmentId: activity.getId(),
            courseId: activity.getCourseId(),
            creationId,
            groupView: activityUtils.isGroupAssignment(activity),
          })
        );
      }
    } catch (error) {
      dispatch({
        type: CREATE_SPOTLIGHT_FAILURE,
      });
    }
  };
};

export const updateSpotlight = (data: any) => {
  return async (dispatch: any, getState: any) => {
    dispatch({
      type: UPDATE_SPOTLIGHT,
    });
    try {
      const res = await spotlightService().update(data);
      if (res.data) {
        dispatch({
          type: UPDATE_SPOTLIGHT_SUCCESS,
        });
        const spotlight = res.data;
        const normalized = normalize(spotlight, spotlightSchema);
        const state = getState();
        const activity = selectCurrentActivity(state);
        const creationId = getSpotlightCreationId({ state, spotlight: data });
        dispatch(selectSubmission(creationId));
        dispatch(updateEntities(normalized.entities));
        dispatch(
          navigateToInstructorCreationView({
            assignmentId: activity.getId(),
            courseId: activity.getCourseId(),
            creationId,
            groupView: activityUtils.isGroupAssignment(activity),
          })
        );
      }
    } catch (error) {
      dispatch({
        type: UPDATE_SPOTLIGHT_FAILURE,
      });
    }
  };
};

export const deleteSpotlight = (id: any, shouldRedirect: any) => {
  return async (dispatch: any, getState: any) => {
    dispatch({
      type: DELETE_SPOTLIGHT,
    });
    try {
      const res = await spotlightService().delete(id);
      if (res.status === 200) {
        dispatch({
          type: DELETE_SPOTLIGHT_SUCCESS,
        });
        const state = getState();
        const activity = selectCurrentActivity(state);
        if (shouldRedirect) {
          if (activity) {
            dispatch(
              navigateToActivityPage({
                courseId: activity.getCourseId() as unknown as string,
                assignmentId: activity.getId(),
              })
            );
          }
        }
      }
    } catch (error) {
      dispatch({
        type: DELETE_SPOTLIGHT_FAILURE,
      });
    }
  };
};

export const listSpotlights = (params: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: LIST_SPOTLIGHTS,
    });
    try {
      const res = await spotlightService().list(params);
      if (res.status === 200) {
        dispatch({
          type: LIST_SPOTLIGHTS_SUCCESS,
        });
        const spotlights = res.data;
        const normalized = normalize(spotlights, [spotlightSchema]);
        if (spotlights.length === 0) {
          normalized.entities = { spotlightTags: {}, spotlights: {} };
        }
        dispatch(mergeEntities(normalized.entities));
      }
    } catch (error) {
      dispatch({
        type: LIST_SPOTLIGHTS_FAILURE,
      });
    }
  };
};

export const getSpotlight = (params: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: GET_SPOTLIGHT,
    });
    try {
      const res = await spotlightService().get(params);
      if (res.status === 200) {
        const spotlight = res.data;
        const normalized = normalize(spotlight, spotlightSchema);
        dispatch(mergeEntities(normalized.entities));
        dispatch({
          type: GET_SPOTLIGHT_SUCCESS,
          success: true,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_SPOTLIGHT_FAILURE,
        error: true,
      });
    }
  };
};

export const selectSpotlight = (state: any, id: any) => {
  const spotlight = denormalize(id, spotlightSchema, state.entities);
  return Spotlight(spotlight);
};

export const selectSpotlightAsyncActions = (state: any) => {
  return state.spotlight.async;
};

export const selectSpotlights = (state: any) => {
  const ids = Object.keys(state.entities.spotlights || {});
  const course = selectCurrentCourse(state);
  const activity = selectCurrentActivity(state);
  const spotlights = denormalize(ids, [spotlightSchema], state.entities);
  return spotlights.filter((spotlight) => {
    if (activity) {
      return spotlight.course._id === course._id && spotlight.activity._id === activity._id;
    }
    return spotlight.course._id === course._id;
  });
};

export const selectSpotlightReference = (state: any, spotlight: any) => {
  if (!spotlight) {
    return null;
  }
  const _spotlight = Spotlight(spotlight);
  const referenceId = _spotlight.getReferenceId();

  return _spotlight.isCreationType()
    ? getSubmission(state, referenceId)
    : selectEvaluation(state, referenceId);
};

export const selectIsPreview = (state: any) => {
  return state.spotlight.isPreview;
};

export function selectSpotlightByReference(referenceId: any, state: any) {
  if (!referenceId) {
    return false;
  }
  const spotlights = selectSpotlights(state);
  const item = spotlights.find((s: any) => {
    return s.reference._id === referenceId || s.reference === referenceId;
  });
  return item;
}

export const isMyContent = (state: any, user: any, spotlight: any) => {
  const content = selectSpotlightReference(state, spotlight);
  if (!content || !content.user) {
    return false;
  }
  if (spotlight.isCreationType()) {
    return isUserCreationOwner(content, user);
  }
  const creation = getSubmission(state, content.creation);
  if (!creation) {
    return false;
  }
  return isUserEvaluationOwner(user, content) || isUserCreationOwner(creation, user);
};

export const selectSpotlightStatus = (state: any) => {
  return state.spotlight.async[GET_SPOTLIGHT];
};

export default combineReducers({
  async: generateAsyncReducer(asyncActions),
  isPreview: isPreviewReducer,
});
