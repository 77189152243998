import moment from 'moment-timezone';
import * as formatUtils from '@kritik/utils/format';
import * as ActivityUtils from '@kritik/utils/activity';
import * as ActivityStatusUtils from '@kritik/utils/stage';
import { assignmentStatuses } from '@kritik/constants/stage';
import type { Activity } from '@kritik/types.generated';
import { diffInMinutes } from '@kritik/utils/general';

const FIELDS = {
  START: 'startDate',
  CREATION_END: 'creationEndDate',
  GRACE_END: 'gracePeriodEndDate',
  EVALUATION_END: 'evaluationEndDate',
  FEEDBACK_END: 'feedbackEndDate',
  GRACE_INTERVAL: 'gracePeriodInterval',
  EVALUATION_GRACE_INTERVAL: 'evaluationGracePeriodInterval',
};

const getCreationStageEndDate = (schedule: any) => {
  return schedule.gracePeriodEndDate || schedule.creationEndDate;
};

const getEvaluationStageEndDate = (schedule: any) => {
  return schedule.evaluationGracePeriodEndDate || schedule.evaluationEndDate;
};

const getMinDateFromPreviousEnd = (date: any) => {
  const prevDate = moment(date).add(5, 'minutes').toDate();
  const now = new Date();
  return prevDate > now ? prevDate : now;
};

const addIntervalToDate = (date: any, interval: any) => {
  const tempDate = new Date(date);
  return moment(tempDate).add(interval, 'seconds').toDate();
};

const roundSelectedDate = (selectedDate: any) => {
  const date = selectedDate;
  if (!date) {
    return;
  }
  date.setSeconds(0);
  date.setMilliseconds(0);
  if (date.getHours() === 0 && date.getMinutes() === 0) {
    date.setMinutes(59);
    date.setHours(23);
  }
  return date;
};

class Util {
  creationEndDate: any;
  creationInterval: any;
  evaluationEndDate: any;
  evaluationGracePeriodEndDate: Date;
  evaluationInterval: any;
  feedbackEndDate: any;
  feedbackInterval: any;
  gracePeriodEndDate: any;
  gracePeriodInterval: any;
  evaluationGracePeriodInterval: number;
  startDate: any;
  constructor(settings: any) {
    this.startDate = settings.startDate;
    this.creationEndDate = settings.creationEndDate;
    this.gracePeriodEndDate = settings.gracePeriodEndDate;
    this.evaluationEndDate = settings.evaluationEndDate;
    this.feedbackEndDate = settings.feedbackEndDate;
    this.creationInterval = settings.creationInterval;
    this.gracePeriodInterval = settings.gracePeriodInterval;
    this.evaluationInterval = settings.evaluationInterval;
    this.evaluationGracePeriodInterval = settings.evaluationGracePeriodInterval;
    this.feedbackInterval = settings.feedbackInterval;
  }

  getCreationEnd() {
    return this.gracePeriodEndDate || this.creationEndDate;
  }

  getEvaluationEnd() {
    return this.evaluationGracePeriodEndDate || this.evaluationEndDate;
  }

  updateCreationEnd() {
    const newDate = addIntervalToDate(this.startDate, this.creationInterval);
    this.creationEndDate = newDate;
    return this;
  }

  updateGracePeriodEnd() {
    if (!this.gracePeriodInterval) {
      return this;
    }
    const gracePeriodEndDate = addIntervalToDate(this.creationEndDate, this.gracePeriodInterval);
    this.gracePeriodEndDate = gracePeriodEndDate;
    return this;
  }

  updateEvaluationEnd() {
    const newDate = addIntervalToDate(this.getCreationEnd(), this.evaluationInterval);
    this.evaluationEndDate = newDate;
    return this;
  }

  updateEvaluationGracePeriodEnd() {
    if (!this.evaluationGracePeriodInterval) {
      return this;
    }
    const newDate = addIntervalToDate(this.evaluationEndDate, this.evaluationGracePeriodInterval);
    this.evaluationGracePeriodEndDate = newDate;
    return this;
  }

  updateFeedbackEnd() {
    if (!this.feedbackInterval) {
      return this;
    }
    const newDate = addIntervalToDate(this.getEvaluationEnd(), this.feedbackInterval);
    this.feedbackEndDate = newDate;
    return this;
  }

  getSchedule() {
    return {
      startDate: this.startDate,
      creationEndDate: this.creationEndDate,
      gracePeriodEndDate: this.gracePeriodEndDate,
      evaluationEndDate: this.evaluationEndDate,
      evaluationGracePeriodEndDate: this.evaluationGracePeriodEndDate,
      feedbackEndDate: this.feedbackEndDate,
    };
  }
}

const generateScheduleFromDates = ({ schedule, modified }: any) => {
  const temp = { ...schedule };

  if (modified === FIELDS.START) {
    const newSchedule = new Util(temp)
      .updateCreationEnd()
      .updateGracePeriodEnd()
      .updateEvaluationEnd()
      .updateEvaluationGracePeriodEnd()
      .updateFeedbackEnd()
      .getSchedule();
    return {
      ...temp,
      ...newSchedule,
    };
  }

  if (modified === FIELDS.CREATION_END && temp.creationEndDate) {
    const creationInterval = formatUtils.getSecondsBetweenDates(
      temp.creationEndDate,
      temp.startDate
    );
    if (creationInterval !== temp.creationInterval) {
      const newSchedule = new Util(temp)
        .updateGracePeriodEnd()
        .updateEvaluationEnd()
        .updateEvaluationGracePeriodEnd()
        .updateFeedbackEnd()
        .getSchedule();
      return {
        ...temp,
        ...newSchedule,
        creationInterval,
      };
    }
  }

  if (modified === FIELDS.GRACE_END && temp.gracePeriodEndDate) {
    const gracePeriodInterval = formatUtils.getSecondsBetweenDates(
      temp.gracePeriodEndDate,
      temp.creationEndDate
    );
    if (gracePeriodInterval !== temp.gracePeriodInterval) {
      const newSchedule = new Util(temp)
        .updateEvaluationEnd()
        .updateEvaluationGracePeriodEnd()
        .updateFeedbackEnd()
        .getSchedule();
      return {
        ...temp,
        ...newSchedule,
        gracePeriodInterval,
      };
    }
  }

  if (modified === FIELDS.EVALUATION_END && temp.evaluationEndDate) {
    const evaluationInterval = formatUtils.getSecondsBetweenDates(
      temp.evaluationEndDate,
      getCreationStageEndDate(temp) || temp.startDate
    );
    if (evaluationInterval !== temp.evaluationInterval) {
      const newSchedule = new Util(temp)
        .updateEvaluationGracePeriodEnd()
        .updateFeedbackEnd()
        .getSchedule();
      return {
        ...temp,
        ...newSchedule,
        evaluationInterval,
      };
    }
  }

  if (modified === FIELDS.FEEDBACK_END && temp.feedbackEndDate) {
    const feedbackInterval = formatUtils.getSecondsBetweenDates(
      temp.feedbackEndDate,
      temp.evaluationEndDate
    );
    return {
      ...temp,
      feedbackInterval,
    };
  }

  const evaluationInterval = formatUtils.getSecondsBetweenDates(
    temp.evaluationEndDate,
    getCreationStageEndDate(temp) || temp.startDate
  );
  if (evaluationInterval !== temp.evaluationInterval) {
    const newSchedule = new Util(temp).updateFeedbackEnd().getSchedule();
    return {
      ...temp,
      ...newSchedule,
      evaluationInterval,
    };
  }

  return temp;
};

const addTime = (startDate: any, intervalInSeconds: any) => {
  return moment(startDate).add(intervalInSeconds, 'seconds').toDate();
};

const addIntervalInDaysToSchedule = (schedule: any, name: string) => {
  const newSchedule = { ...schedule };
  if (name === 'creationInterval') {
    const creationEndDate = addTime(schedule.startDate, schedule.creationInterval);
    (newSchedule as any).creationEndDate = creationEndDate;
  }

  if (schedule.gracePeriodInterval) {
    const gracePeriodEndDate = moment((newSchedule as any).creationEndDate)
      .add(schedule.gracePeriodInterval, 'seconds')
      .toDate();
    (newSchedule as any).gracePeriodEndDate = gracePeriodEndDate;
  } else {
    (newSchedule as any).gracePeriodEndDate = null;
  }

  if (name === 'evaluationInterval') {
    const { evaluationInterval } = schedule;
    const evaluationEndDate = addTime(getCreationStageEndDate(newSchedule), evaluationInterval);
    (newSchedule as any).evaluationEndDate = evaluationEndDate;
  }

  if (schedule.evaluationGracePeriodInterval) {
    const evaluationGracePeriodEndDate = moment((newSchedule as any).evaluationEndDate)
      .add(schedule.evaluationGracePeriodInterval, 'seconds')
      .toDate();
    (newSchedule as any).evaluationGracePeriodEndDate = evaluationGracePeriodEndDate;
  } else {
    (newSchedule as any).evaluationGracePeriodEndDate = null;
  }

  if (name === 'feedbackInterval') {
    if (schedule.feedbackInterval) {
      const feedbackEndDate = addTime(
        getEvaluationStageEndDate(newSchedule),
        schedule.feedbackInterval
      );
      (newSchedule as any).feedbackEndDate = feedbackEndDate;
    }
  }

  return {
    ...schedule,
    ...newSchedule,
  };
};

const generateScheduleFromIntervals = (schedule: any) => {
  const newSchedule = {};
  const creationEndDate = moment(schedule.startDate)
    .add(schedule.creationInterval, 'seconds')
    .toDate();
  (newSchedule as any).creationEndDate = creationEndDate;

  if (schedule.gracePeriodInterval) {
    const gracePeriodEndDate = moment(creationEndDate)
      .add(schedule.gracePeriodInterval, 'seconds')
      .toDate();
    (newSchedule as any).gracePeriodEndDate = gracePeriodEndDate;
  } else {
    (newSchedule as any).gracePeriodEndDate = null;
  }

  const { evaluationInterval } = schedule;

  const evaluationEndDate = moment(getCreationStageEndDate(newSchedule))
    .add(evaluationInterval, 'seconds')
    .toDate();
  (newSchedule as any).evaluationEndDate = evaluationEndDate;

  if (schedule.evaluationGracePeriodInterval) {
    const evaluationGracePeriodEndDate = moment(evaluationEndDate)
      .add(schedule.evaluationGracePeriodInterval, 'seconds')
      .toDate();
    (newSchedule as any).evaluationGracePeriodEndDate = evaluationGracePeriodEndDate;
  } else {
    (newSchedule as any).evaluationGracePeriodEndDate = null;
  }

  if (schedule.feedbackInterval) {
    const feedbackEndDate = moment(getEvaluationStageEndDate(newSchedule))
      .add(schedule.feedbackInterval, 'seconds')
      .toDate();
    (newSchedule as any).feedbackEndDate = feedbackEndDate;
  }

  return {
    ...schedule,
    ...newSchedule,
  };
};

/*
  Checks if all schedulable dates are not past date
*/
const validateIsPastDate = (schedule: any, activity: any) => {
  const now = moment();
  const activeStatus = activity.statuses.find((status: any) => {
    const { startDate, endDate } = status;
    if (startDate && endDate) {
      return moment(startDate).isBefore(now) && moment(endDate).isAfter(now);
    }
    return false;
  });

  const { startDate, evaluationEndDate, feedbackEndDate, creationEndDate, gracePeriodEndDate } =
    schedule;

  // Active status is false will be false if activity is in draft stage
  if (activeStatus?.name === assignmentStatuses.DRAFT || !activeStatus) {
    if (moment(startDate).isBefore(now)) {
      return ActivityUtils.isCalibrationActivity(activity)
        ? {
            Evaluate: {
              startDate: 'Evaluate Stage start date has already passed.',
            },
          }
        : {
            Create: {
              startDate: 'Create Stage start date has already passed.',
            },
          };
    }
  } else if (activeStatus.name === assignmentStatuses.CREATE) {
    if (moment(creationEndDate).isBefore(now)) {
      return {
        Create: {
          endDate: 'Create Stage end date has already passed.',
        },
      };
    }
  } else if (activeStatus.name === assignmentStatuses.PROCESSING1) {
    if (moment(gracePeriodEndDate).isBefore(now)) {
      return {
        Processing1: {
          endDate: 'Grace period end date has already passed.',
        },
      };
    }
  } else if (activeStatus.name === assignmentStatuses.EVALUATE) {
    if (moment(evaluationEndDate).isBefore(now)) {
      return {
        Evaluate: {
          endDate: 'Evaluation Stage end date has already passed.',
        },
      };
    }
  } else if (
    [
      assignmentStatuses.PROCESSING2,
      assignmentStatuses.FEEDBACK,
      assignmentStatuses.PROCESSING3,
      assignmentStatuses.FINALIZED,
    ].includes(activeStatus.name)
  ) {
    if (moment(feedbackEndDate).isBefore(now)) {
      return {
        Feedback: {
          endDate: 'Feedback Stage end date has already passed.',
        },
      };
    }
  }
  return '';
};

const isValidCalibrationSchedule = (schedule: any) => {
  return (
    schedule.startDate &&
    schedule.evaluationEndDate &&
    Date.parse(schedule.startDate) < Date.parse(schedule.evaluationEndDate)
  );
};

const isValidSchedule = ({ schedule, currentDate, activeStatus }: any) => {
  const { startDate, creationEndDate, gracePeriodEndDate, evaluationEndDate, feedbackEndDate } =
    schedule;

  const creationStageEndDate = getCreationStageEndDate(schedule);
  const evaluationStageEndDate = getEvaluationStageEndDate(schedule);

  if (!startDate || !creationEndDate || !evaluationEndDate || !feedbackEndDate) {
    return false;
  }

  const parsedCreationStart = Date.parse(startDate);
  const parsedCreationEnd = Date.parse(creationEndDate);
  const parsedCreationStageEnd = Date.parse(creationStageEndDate);
  const parsedGracePeriodEnd = Date.parse(gracePeriodEndDate);
  const parsedEvaluationEnd = Date.parse(evaluationEndDate);
  const parsedEvaluationStageEnd = Date.parse(evaluationStageEndDate);
  const parsedFeedbackEnd = Date.parse(feedbackEndDate);

  const diffInMinutesBetweenEvaluationAndFeedbackEndDate = diffInMinutes(
    feedbackEndDate,
    evaluationStageEndDate
  );

  if (diffInMinutesBetweenEvaluationAndFeedbackEndDate <= 1) {
    return false;
  }

  if (
    parsedCreationStart >= parsedCreationEnd ||
    parsedCreationStageEnd >= parsedEvaluationEnd ||
    (parsedFeedbackEnd && parsedEvaluationStageEnd >= parsedFeedbackEnd)
  ) {
    return false;
  }

  if (
    parsedGracePeriodEnd &&
    (parsedGracePeriodEnd <= parsedCreationEnd || parsedGracePeriodEnd >= parsedEvaluationEnd)
  ) {
    return false;
  }

  if (parsedEvaluationStageEnd >= parsedFeedbackEnd) {
    return false;
  }

  if (activeStatus.name === assignmentStatuses.DRAFT) {
    if (parsedCreationEnd <= currentDate) {
      return false;
    }
  } else if (activeStatus.name === assignmentStatuses.CREATE) {
    if (parsedGracePeriodEnd <= currentDate) {
      return false;
    }
  } else if (activeStatus.name === assignmentStatuses.PROCESSING1) {
    if (parsedEvaluationEnd <= currentDate) {
      return false;
    }
  } else if (activeStatus.name === assignmentStatuses.PROCESSING2) {
    if (parsedEvaluationStageEnd <= currentDate) {
      return false;
    }
  }

  return true;
};

function generateScheduleFromStatuses(activity: any) {
  const { statuses } = activity;
  const evaluationStatus = ActivityStatusUtils.getEvaluationStage({
    statuses,
  } as Activity);
  const schedule = {
    evaluationEndDate: new Date(evaluationStatus.endDate),
  };

  if (ActivityUtils.isCalibrationActivity(activity)) {
    (schedule as any).startDate = new Date(evaluationStatus.startDate);
    return schedule;
  }

  const creationStatus = ActivityStatusUtils.getCreationStage({
    statuses,
  } as Activity);
  const gracePeriodStatus = ActivityStatusUtils.getCreationGracePeriodStage({
    statuses,
  } as Activity);

  // @ts-expect-error TS(2551) FIXME: Property 'creationEndDate' does not exist on type ... Remove this comment to see the full error message
  schedule.creationEndDate = new Date(creationStatus.endDate);
  (schedule as any).startDate = new Date(creationStatus.startDate);

  const evaluationGracePeriodStatus = ActivityStatusUtils.getEvaluationGracePeriodStage({
    statuses,
  } as Activity);

  const feedbackStatus = ActivityStatusUtils.getFeedbackStage({
    statuses,
  } as Activity);
  (schedule as any).feedbackEndDate = new Date(feedbackStatus.endDate);

  if (gracePeriodStatus.endDate) {
    (schedule as any).gracePeriodEndDate = new Date(gracePeriodStatus.endDate);
  }

  if (evaluationGracePeriodStatus.endDate) {
    (schedule as any).evaluationGracePeriodEndDate = new Date(evaluationGracePeriodStatus.endDate);
  }

  return schedule;
}

const generateIntervalsFromSchedule = (schedule: any) => {
  const intervals = {};
  const {
    creationEndDate,
    startDate,
    gracePeriodEndDate,
    evaluationEndDate,
    evaluationGracePeriodEndDate,
    feedbackEndDate,
  } = schedule;

  if (creationEndDate) {
    (intervals as any).creationInterval = formatUtils.getSecondsBetweenDates(
      startDate,
      creationEndDate
    );
  }

  if (gracePeriodEndDate) {
    (intervals as any).gracePeriodInterval = formatUtils.getSecondsBetweenDates(
      gracePeriodEndDate,
      creationEndDate
    );
    (intervals as any).evaluationInterval = formatUtils.getSecondsBetweenDates(
      evaluationEndDate,
      gracePeriodEndDate
    );
  } else {
    (intervals as any).evaluationInterval = formatUtils.getSecondsBetweenDates(
      evaluationEndDate,
      creationEndDate || startDate
    );
  }

  if (evaluationGracePeriodEndDate) {
    (intervals as any).evaluationGracePeriodInterval = formatUtils.getSecondsBetweenDates(
      evaluationGracePeriodEndDate,
      evaluationEndDate
    );
    if (feedbackEndDate) {
      (intervals as any).feedbackInterval = formatUtils.getSecondsBetweenDates(
        evaluationGracePeriodEndDate,
        feedbackEndDate
      );
    }
  } else if (feedbackEndDate) {
    (intervals as any).feedbackInterval = formatUtils.getSecondsBetweenDates(
      evaluationEndDate,
      feedbackEndDate
    );
  }
  return intervals;
};

const isGracePeriodEnabled = (schedule: any) => {
  return !!schedule.gracePeriodEndDate;
};

const initSchedule = () => {
  const nextMonday = moment()
    .day(1 + 7)
    .hours(9)
    .minutes(0)
    .seconds(0)
    .toDate();

  const startDate = nextMonday;

  const creationEndDate = moment(startDate).add(3, 'day').hours(23).minutes(59).seconds(0).toDate();
  const _creationEndDate = moment(creationEndDate).add(1, 'seconds').toDate();

  const gracePeriodEndDate = moment(_creationEndDate).add(2, 'hour').toDate();
  const _gracePeriodEndDate = moment(gracePeriodEndDate).add(1, 'seconds').toDate();

  const evaluationEndDate = moment(_gracePeriodEndDate)
    .add(2, 'day')
    .hours(23)
    .minutes(59)
    .seconds(0)
    .toDate();
  const _evaluationEndDate = moment(evaluationEndDate).add(1, 'seconds').toDate();

  const evaluationGracePeriodEndDate = moment(_evaluationEndDate).add(2, 'hour').toDate();

  const feedbackEndDate = moment(evaluationGracePeriodEndDate)
    .add(2, 'day')
    .hours(23)
    .minutes(59)
    .seconds(0)
    .toDate();

  const gracePeriodInterval = moment(gracePeriodEndDate).diff(moment(_creationEndDate), 'seconds');

  return {
    startDate,
    creationEndDate,
    gracePeriodEndDate,
    evaluationEndDate,
    evaluationGracePeriodEndDate,
    feedbackEndDate,
    creationInterval: moment(_creationEndDate).diff(moment(startDate), 'seconds'),
    gracePeriodInterval,
    evaluationInterval: moment(_evaluationEndDate).diff(_gracePeriodEndDate, 'seconds'),
    evaluationGracePeriodInterval: moment(evaluationGracePeriodEndDate).diff(
      _evaluationEndDate,
      'seconds'
    ),
    feedbackInterval: moment(feedbackEndDate).diff(evaluationGracePeriodEndDate, 'seconds'),
    customTime: null,
  };
};

export default {
  FIELDS,
  generateScheduleFromStatuses,
  generateIntervalsFromSchedule,
  generateScheduleFromDates,
  generateScheduleFromIntervals,
  addIntervalInDaysToSchedule,
  getCreationStageEndDate,
  getEvaluationStageEndDate,
  getMinDateFromPreviousEnd,
  addIntervalToDate,
  roundSelectedDate,
  isValidCalibrationSchedule,
  isValidSchedule,
  isGracePeriodEnabled,
  validateIsPastDate,
  initSchedule,
};
