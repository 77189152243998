import React from 'react';
import FormField from 'components/core/form/Field';
import FormFieldLabel from 'components/core/form/FieldLabel';
import FormFieldInfo from 'components/core/form/FieldInfo';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextInput from 'components/core/input/Text';
import * as _ from 'lodash-es';

type propsObj = {
  id?: string;
  onBlur?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onMouseDown?: () => void;
  value?: string;
};

export const AutoCompleteField = (props: any) => {
  const onChange = (e: any, newValue: any) => {
    props.onChange(newValue);
  };

  return (
    <FormField error={props.error} className={props.className}>
      <FormFieldLabel label={props.label} id={props.id} />
      <FormFieldInfo label={props.info} />
      <Autocomplete
        data-testid={props.testid}
        aria-label={props.label}
        id={props.id}
        options={props.options}
        getOptionLabel={(option) => {
          return option.label;
        }}
        getOptionSelected={(option, value) => {
          return option.key === value.key;
        }}
        onChange={onChange}
        className={props.input.className}
        renderInput={(params) => {
          const pickedProps = [
            'id',
            'onBlur',
            'onChange',
            'onFocus',
            'onMouseDown',
            'ref',
            'value',
          ];
          const inputProps: Object & propsObj = _.pick(params.inputProps, pickedProps);
          return (
            <div ref={params.InputProps.ref}>
              <TextInput
                {...inputProps}
                inputProps={{ ...inputProps }}
                placeholder={props.placeholder}
                icon={props.icon}
                value={props.isClearedCriteria ? '' : inputProps.value}
              />
            </div>
          );
        }}
      />
    </FormField>
  );
};

AutoCompleteField.defaultProps = {
  label: '',
  info: '',
  placeholder: '',
  error: null,
  className: '',
  input: {
    className: '',
  },
};

export default AutoCompleteField;
