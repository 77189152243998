import { Card } from '@material-ui/core';
import { localize } from 'locales';

const CardContent = (props: any) => {
  return (
    <button
      className="spotlight-card__wrapper"
      aria-label={localize({ message: 'Activity.SpotlightCard' })}
    >
      <Card className="spotlight-card">{props.children}</Card>
    </button>
  );
};

export default CardContent;
