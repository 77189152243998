import React from 'react';
import { labels as fileTypes } from 'components/Assignment/constants';
import Checkbox from 'components/core/input/Checkbox';
import FormField from 'components/core/form/Field';
import { Field, useFormState } from 'react-final-form';
import { Grid } from '@material-ui/core';

const FileRestrictionsSelection = (props: any) => {
  const formState = useFormState().values;

  const renderDescription = (description: any) => {
    return <div className="attachment-type__descriptions">{description}</div>;
  };

  const isAtFileRestrictionLimit = () => {
    let checkedFileCount = 0;
    checkedFileCount = Object.keys(fileTypes).reduce((acc, type) => {
      if (formState[`allow${type}`]) {
        acc++;
      }
      return acc;
    }, 0);

    if (checkedFileCount === 1) {
      return true;
    }
    return false;
  };

  const setIsAllowed = (isFileAllowed: any, onChangeCallback: any) => {
    isAtFileRestrictionLimit() && isFileAllowed
      ? onChangeCallback(true)
      : onChangeCallback(!isFileAllowed);
  };

  const fileColumn = (stateString: any, type: any) => {
    return (
      <Grid item key={type} xs={4}>
        <Field name={stateString}>
          {({ input }) => {
            return (
              <Checkbox
                testid={`${type}-option`}
                label={type}
                isChecked={input.value}
                onChange={() => {
                  return setIsAllowed(input.value, input.onChange);
                }}
                type="secondary"
                isDisabled={props.isDisabled}
              />
            );
          }}
        </Field>
        {renderDescription(fileTypes[type])}
      </Grid>
    );
  };

  return (
    <FormField>
      <Grid container spacing={4}>
        {Object.keys(fileTypes).map((type) => {
          const stateString = `allow${type}`;
          return fileColumn(stateString, type);
        })}
      </Grid>
    </FormField>
  );
};

export default FileRestrictionsSelection;
