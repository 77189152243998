import types from './types';

export const selectSchedule = (state: any) => {
  function getDate(dateString: any) {
    if (!dateString) {
      return null;
    }
    return new Date(dateString);
  }
  const { scheduling } = state.schedule;
  const {
    startDate,
    creationEndDate,
    gracePeriodEndDate,
    evaluationEndDate,
    feedbackEndDate,
    evaluationGracePeriodEndDate,
  } = scheduling;

  return {
    ...scheduling,
    startDate: getDate(startDate),
    creationEndDate: getDate(creationEndDate),
    gracePeriodEndDate: getDate(gracePeriodEndDate),
    evaluationEndDate: getDate(evaluationEndDate),
    evaluationGracePeriodEndDate: getDate(evaluationGracePeriodEndDate),
    feedbackEndDate: getDate(feedbackEndDate),
  };
};

const selectSchedulingAsyncState = (state: any) => {
  return state.schedule.async;
};

const selectGetSchedulingTemplateState = (state: any) => {
  return selectSchedulingAsyncState(state)[types.GET_SCHEDULING_TEMPLATE];
};

const selectPastDateError = (state: any) => {
  return state.schedule.error.pastDate;
};

export default {
  selectGetSchedulingTemplateState,
  selectSchedulingAsyncState,
  selectSchedule,
  selectPastDateError,
};
