import BaseService from 'services/base';

export default class GradebookService extends BaseService {
  get = (params: any) => {
    return this.client.request({
      method: 'GET',
      url: `/gradebooks`,
      params,
      cancelToken: this.source.token,
      responseType: 'blob',
    });
  };
}
