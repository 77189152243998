import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as activityUtils from '@kritik/utils/activity';
import StudentInfoCell from 'components/CreationList/StatusCells/StudentInfo';
import CreationCell from 'components/CreationList/StatusCells/Creation';
import EvaluationCell from 'components/CreationList/StatusCells/Evaluation';
import EvaluationsReceivedCell from 'components/CreationList/StatusCells/EvaluationsReceived';
import FeedbackCell from 'components/CreationList/StatusCells/Feedback';
import IssuesCell from 'components/CreationList/StatusCells/Issues';
import localUtil from 'components/CreationList/util';
import { getAssignment } from 'selectors/activity';
import Cell from 'components/CreationList/Body/Cell';
import TableRow from 'components/CreationList/Body/Row';
import ToggleCell from 'components/CreationList/Body/ToggleCell';
import { selectCreationTableListBy, selectIsShowEvalsCompleted } from 'redux/creationTable/select';
import { navigateToInstructorCreationView } from 'actions/activity';
import { selectSubmission } from 'actions/select';
import { selectStudent } from 'reducers/selected';
import { getSubmissions } from 'selectors/creation';
import ElipsesMenu from 'components/General/ElipsesMenu';
import { Activity } from 'old-common/types.generated';
import { localize } from 'locales';
import { RemoveStudentFromActivityModal } from '../RemoveStudentModal';
import getPaginatedResults from 'actions/pagination/actions';

const CalibrationRows = ({ columns, activity, creation, lateEvaluations }: any) => {
  return (
    <React.Fragment>
      <Cell column={columns[1]}>
        <EvaluationCell
          creation={creation}
          activity={activity}
          lateEvaluations={lateEvaluations || []}
        />
      </Cell>
      <Cell column={columns[2]} />
      <Cell column={columns[3]} />
      <Cell column={columns[4]}>
        <IssuesCell creation={creation} />
      </Cell>
    </React.Fragment>
  );
};

const GroupRows = (props: any) => {
  const {
    columns,
    activity,
    creation,
    group,
    listBy,
    student,
    toggleGroupMembers,
    parentId,
    isExpand,
    isShowEvalsCompleted,
    isGroupItem,
    lateEvaluations,
  } = props;
  const renderEvaluationColumns = () => {
    if (localUtil.isListByGroup(listBy) && !isShowEvalsCompleted) {
      return <EvaluationsReceivedCell creation={creation} activity={activity} />;
    }
    return (
      <EvaluationCell
        creation={creation}
        activity={activity}
        student={localUtil.isListByGroup(listBy) ? null : student}
        lateEvaluations={lateEvaluations || []}
      />
    );
  };

  return (
    <React.Fragment>
      <Cell column={columns[1]}>
        <CreationCell creation={creation} activity={activity} />
      </Cell>
      <Cell column={columns[2]}>{renderEvaluationColumns()}</Cell>
      <Cell column={columns[3]}>
        <FeedbackCell creation={creation} activity={activity} group={group} />
      </Cell>
      <Cell column={columns[4]}>
        <IssuesCell
          creation={creation}
          activity={activity}
          isGroupItem={isGroupItem}
          studentId={student?._id}
        />
      </Cell>
      {localUtil.isGroupItem({ parentId }, listBy) && (
        <ToggleCell column={columns[5]} onClick={toggleGroupMembers} isExpand={isExpand} />
      )}
    </React.Fragment>
  );
};

const IndividualRows = ({
  columns,
  activity,
  creation,
  submissions,
  student,
  lateEvaluations,
  openRemoveStudentModal,
}: any) => {
  return (
    <React.Fragment>
      <Cell column={columns[1]}>
        <CreationCell creation={creation} activity={activity} />
      </Cell>
      <Cell column={columns[2]}>
        <EvaluationCell
          creation={creation}
          activity={activity}
          student={student}
          lateEvaluations={lateEvaluations || []}
        />
      </Cell>
      <Cell column={columns[3]}>
        <FeedbackCell
          creation={creation}
          activity={activity}
          submissions={submissions}
          student={student}
        />
      </Cell>
      <Cell column={columns[4]}>
        <IssuesCell creation={creation} />
      </Cell>
      <Cell column={columns[5]}>
        {activityUtils.canRemoveStudentFromActivity(activity) && (
          <ElipsesMenu
            options={[
              {
                label: localize({ message: 'Activity.RemoveStudent.Menu.Item' }),
                testid: 'remove-student-from-activity',
                action: openRemoveStudentModal,
              },
            ]}
            labelI18nKey="Activity.RemoveStudent.Menu.Label"
          />
        )}
      </Cell>
    </React.Fragment>
  );
};

const GroupMemberRow = (props: any) => {
  const { columns, activity, creation, student, isShowEvalsCompleted, lateEvaluations } = props;
  return (
    <React.Fragment>
      <Cell column={columns[1]}>
        <CreationCell creation={creation} activity={activity} />
      </Cell>
      <Cell column={columns[2]}>
        {isShowEvalsCompleted ? (
          <EvaluationCell
            creation={creation}
            activity={activity}
            student={student}
            lateEvaluations={lateEvaluations || []}
          />
        ) : (
          '-'
        )}
      </Cell>
      <Cell column={columns[3]}>
        <FeedbackCell creation={creation} activity={activity} student={student} />
      </Cell>
      <Cell column={columns[4]}>
        <IssuesCell creation={creation} activity={props.activity} studentId={props.student._id} />
      </Cell>
      <Cell column={columns[5]} isMenu>
        {activityUtils.canRemoveStudentFromActivity(activity) && (
          <ElipsesMenu
            options={[
              {
                label: localize({ message: 'Activity.RemoveStudent.Menu.Item' }),
                action: props.openRemoveStudentModal,
              },
            ]}
            labelI18nKey="Activity.RemoveStudent.Menu.Label"
          />
        )}
      </Cell>
    </React.Fragment>
  );
};

type RowProps = {
  data: any;
  index: number;
  style: any;
  activity: Activity & { course: string };
  listBy: string;
  navigateToInstructorCreationView: (params: any) => void;
  selectSubmission: (creationId: string) => void;
  selectStudent: (studentId: string) => void;
  submissions: any[];
  isShowEvalsCompleted: boolean;
  getPaginatedResults: () => void;
};
const Row = (props: RowProps) => {
  const [isModalOpen, toggleRemoveStudentModal] = useState(false);
  const {
    data,
    index,
    style,
    activity,
    listBy,
    navigateToInstructorCreationView,
    selectSubmission,
    selectStudent,
    submissions,
    isShowEvalsCompleted,
    getPaginatedResults,
  } = props;
  const { stickyIndices, columns, toggleGroupMembers, rowList } = data;

  if (stickyIndices && stickyIndices.includes(index)) {
    return null;
  }
  if (localUtil.isListByGroup(listBy)) {
    if (columns.length !== 6) {
      return null;
    }
  } else if (columns.length !== 6) {
    return null;
  }

  const item = rowList[index];

  if (!item) {
    return <TableRow />;
  }

  const { student, creation, group, lateEvaluations } = item;

  type ColumnsProps = {
    openRemoveStudentModal: () => void;
  };
  function Columns(props: ColumnsProps) {
    if (activityUtils.isCalibrationActivity(activity)) {
      return (
        <CalibrationRows
          columns={columns}
          activity={activity}
          creation={creation}
          lateEvaluations={lateEvaluations}
        />
      );
    }
    if (
      activityUtils.isGroupAssignment(activity) ||
      activityUtils.isGroupPresentationActivity(activity)
    ) {
      const { parentId, isVisible, isExpand } = item;
      if (!parentId) {
        const handleToggleGroupMembers = () => {
          toggleGroupMembers(index);
        };
        return (
          <GroupRows
            columns={columns}
            activity={activity}
            creation={creation}
            group={group}
            listBy={listBy}
            student={student}
            isExpand={isExpand}
            toggleGroupMembers={handleToggleGroupMembers}
            isShowEvalsCompleted={isShowEvalsCompleted}
            isGroupItem={localUtil.isGroupItem({ parentId: item.parentId }, listBy)}
            lateEvaluations={lateEvaluations}
          />
        );
      }
      if (isVisible) {
        return (
          <GroupMemberRow
            columns={columns}
            activity={activity}
            creation={creation}
            submissions={submissions}
            student={student}
            isShowEvalsCompleted={isShowEvalsCompleted}
            lateEvaluations={lateEvaluations}
            openRemoveStudentModal={props.openRemoveStudentModal}
          />
        );
      }
      return null;
    }
    return (
      <IndividualRows
        columns={columns}
        activity={activity}
        creation={creation}
        submissions={submissions}
        student={student}
        lateEvaluations={lateEvaluations}
        openRemoveStudentModal={props.openRemoveStudentModal}
      />
    );
  }
  const renderStudentInfo = () => {
    if (localUtil.isListByGroup(listBy)) {
      const infoCellColumn = { ...columns[0] };
      if (!localUtil.isGroupItem({ parentId: item.parentId }, listBy)) {
        infoCellColumn.align = 'left';
        infoCellColumn.isGroupMember = true;
      }

      return (
        <Cell column={infoCellColumn}>
          <StudentInfoCell
            student={student}
            group={group}
            isListByGroup={localUtil.isListByGroup(listBy)}
          />
        </Cell>
      );
    }
    return (
      <Cell column={columns[0]}>
        <StudentInfoCell
          student={student}
          group={group}
          isListByGroup={localUtil.isListByGroup(listBy)}
        />
      </Cell>
    );
  };
  const handleClickRow = () => {
    if (creation) {
      selectStudent(student._id);
      selectSubmission(creation._id);
      navigateToInstructorCreationView({
        assignmentId: activity._id,
        courseId: activity.course,
        creationId: creation._id,
        groupView: !!localUtil.isGroupItem({ parentId: item.parentId }, listBy),
      });
    }
  };

  const onSuccessfullStudentRemoval = () => {
    toggleRemoveStudentModal(false);
    getPaginatedResults();
  };

  return (
    <>
      <RemoveStudentFromActivityModal
        open={isModalOpen}
        onSuccessfullStudentRemoval={onSuccessfullStudentRemoval}
        onCancelRemoveStudent={() => toggleRemoveStudentModal(false)}
        student={student}
        activity={activity}
      />
      <TableRow
        isWarning={localUtil.hasCreationWarning(creation, activity)}
        disableHover={!creation}
        style={style}
        onClick={handleClickRow}
        testid={`progress_creation-row-${creation.student}`}
      >
        {renderStudentInfo()}
        <Columns
          openRemoveStudentModal={() => {
            toggleRemoveStudentModal(true);
          }}
        />
      </TableRow>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
    listBy: selectCreationTableListBy(state),
    submissions: getSubmissions(state, state.selected.assignmentId),
    isShowEvalsCompleted: selectIsShowEvalsCompleted(state),
  };
};

export default connect(mapStateToProps, {
  navigateToInstructorCreationView,
  selectSubmission,
  selectStudent,
  getSubmissions,
  getPaginatedResults,
})(Row);
