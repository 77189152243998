import { useEffect, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';
const Delta = Quill.import('delta');

// This custom Matcher function aims to handle a specific case where a heading element
// contains only a single line break (<h2><br></h2>), as its child element. It deletes the heading's
// content and retains the line break, ensuring proper handling of such scenarios in the Quill editor's content.
const customMatcher = (node, delta, context) => {
  if (
    node.tagName.startsWith('H') &&
    node.children.length === 1 &&
    node.firstChild.tagName === 'BR'
  ) {
    const index = delta.length() - node.textContent.length;
    const length = node.textContent.length;
    return new Delta().retain(index).delete(length).retain(1, { block: false });
  }

  return delta;
};

const modules = {
  clipboard: {
    matchVisual: false,
    matchers: [
      ['h1', customMatcher],
      ['h2', customMatcher],
      ['h3', customMatcher],
      ['h4', customMatcher],
      ['h5', customMatcher],
      ['h6', customMatcher],
    ],
  },
  toolbar: [
    [{ header: [false, 1, 2, 3, 4, 5] }],
    ['bold', 'italic', 'underline', 'strike', 'code-block'],
    [{ script: 'super' }, { script: 'sub' }],
    [
      {
        color: [
          //https://developer.mozilla.org/en-US/docs/Web/CSS/named-color
          'black',
          'silver',
          'gray',
          'white',
          'maroon',
          'red',
          'purple',
          'fuchsia',
          'green',
          'lime',
          'olive',
          'yellow',
          'navy',
          'blue',
          'teal',
          'aqua',
        ],
      },
      { background: [] },
      'blockquote',
    ],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'clean'],
  ],
};

const formats = [
  'html',
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'color',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'script',
  'code-block',
];

type Input = {
  value?: string;
  onChange?: (content: string) => void;
};
type Props = {
  input: Input;
  testid: string;
  id: string;
};

export default function RichTextEditor(props: Props) {
  const editorRef = useRef<ReactQuill | null>(null); // Create a ref using useRef()

  useEffect(() => {
    if (props.testid) {
      window[props.testid] = editorRef.current; // Assign the ref value to a window property
    }
  }, [props.testid]);

  useEffect(() => {
    if (editorRef.current) {
      const quill = editorRef.current.getEditor();

      const preventImagePaste = (event) => {
        const clipboardData = event.clipboardData || window.Clipboard;
        const pastedData = clipboardData.getData('Text');

        if (pastedData.match(/data:image\//)) {
          event.preventDefault();

          alert(
            'Your creation cannot contain any inline images. If you need to add images or any files to your submission please use the upload attachment function'
          );
        }
      };

      // Attach the paste event listener to the Quill editor
      quill.root.addEventListener('paste', preventImagePaste);

      // Add aria-labels to the color and background color toolbar buttons
      const toolbar = quill.getModule('toolbar');
      const toolbarContainer = toolbar.container;

      const pickerItems = toolbarContainer.querySelectorAll('.ql-picker-item');
      pickerItems.forEach((item) => {
        const dataValue = item.getAttribute('data-value');
        item.setAttribute('aria-label', `color ${dataValue}`);
      });
    }
  }, []);

  return (
    <div className="rich-text-editor" data-testid={props.testid}>
      <ReactQuill
        ref={editorRef}
        theme="snow"
        modules={modules}
        formats={formats}
        {...props.input}
        onChange={(value) => {
          const quill = editorRef.current?.getEditor();
          let currentValue = value;

          const quillContents = quill?.getContents();
          if (
            quillContents &&
            ((quillContents.ops.length === 1 &&
              (quillContents.ops[0].insert === '\n' || quillContents.ops[0].insert === ' ')) ||
              (quillContents.ops.length === 2 &&
                quillContents.ops[0].insert === '\n' &&
                quillContents.ops[1].insert === '\n'))
          ) {
            currentValue = '';
            props.input.onChange(currentValue);
          }

          props.input.onChange(currentValue);
        }}
      />
    </div>
  );
}
