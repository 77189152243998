import React from 'react';
import Number from 'components/core/input/Number';
import { withForm } from 'components/core/form/utils/withForm';

export const NumberInput = (props: any) => {
  return <Number {...props} />;
};

NumberInput.defaultProps = {
  min: 0,
  max: null,
  step: 1,
  disabled: false,
  maxDecimals: 0,
};

export default withForm(NumberInput);
