import bcrypt from 'bcryptjs';
import { getPlainTextFromHTML } from '@kritik/utils/format';
import { ROUNDS } from '@kritik/constants/bcrypt';
import moment from 'moment';

const MAX_MOBILE_PHONE_DIGITS = 15;

export const isEmail = (value: string) => {
  // have the browser check email validity
  const input = (document as any).createElement('input');
  input.type = 'email';
  input.required = true;
  input.value = value;

  return typeof input.checkValidity === 'function'
    ? input.checkValidity()
    : /\S+@\S+\.\S+/.test(value);
};

export const sanitizePhoneNumberLength = (number: string = '') => {
  return number.slice(0, MAX_MOBILE_PHONE_DIGITS);
};

export const sanitizeCountryCode = (value: string) => {
  let countryCode = value;
  if (countryCode.includes('USA')) {
    countryCode = '+1';
  }
  return countryCode;
};

export function isValidE164PhoneNumber(fullNumber: string) {
  if (fullNumber === '') {
    return true;
  }
  // E.164 format: + followed by 9 to 15 digits
  const e164Regex = /^\+[1-9]\d{9,14}$/;
  return e164Regex.test(fullNumber);
}

export const sanitizeEmails = (emails: string) => {
  const noNewLines = emails.replace(/(\r\n|\n|\r)/gm, ' ');
  const noCommas = noNewLines.replace(/,/g, ' ');
  const singleSpaces = noCommas.replace(/\s\s+/g, ' ');
  const newList = singleSpaces.split(' ');
  const validEmailList = newList
    .filter((email) => {
      return isEmail(email);
    })
    .map((email) => {
      return email.toLowerCase();
    });
  return validEmailList;
};

/**
 * masks the username of an email address by replacing all characters
 * except the first, last, and every 4 characters in between with an asterisk
 */
export function maskEmail(email: string) {
  const [username, domain] = email.split('@');
  const maskedUsername = username
    .split('')
    .map((char, index) => {
      if (index === 0 || index % 4 === 0 || index === username.length - 1) {
        return char;
      }
      return '*';
    })
    .join('');
  return `${maskedUsername}@${domain}`;
}

export const getEnrollLink = async ({ courseId, email }: { courseId: string; email: string }) => {
  const salt = await bcrypt.genSalt(ROUNDS);
  const emailHash = await bcrypt.hash(email, salt);
  return `/${courseId}/enroll/${encodeURIComponent(emailHash.replace(/\//g, 'qwef'))}`;
};

export const getWordCount = (content: string) => {
  if (!content || content === '<p>&nbsp;</p>') {
    return 0;
  }
  return content.split(' ').length;
};

export const getWordCountFromHTML = (content: string) => {
  const plainText = getPlainTextFromHTML(content);
  return getWordCount(plainText);
};

/**
 * Return amount that after apply discount
 */
export const getDiscountAmount = (
  amount: number,
  coupon: { percentOff: number; amountOff: number }
) => {
  if (coupon.percentOff) {
    return amount * (coupon.percentOff / 100);
  }
  if (coupon.amountOff) {
    // stripe coupon amount off is in cents
    return coupon.amountOff;
  }
  return 0;
};

export function stripWhiteSpaces(stringValue: string) {
  return stringValue.trim();
}

type Headers = {
  [key: string]: string;
};
export const getFileNameFromHeaders = (headers: Headers) => {
  decodeURIComponent(headers['content-disposition'].split("attachment; filename*=UTF-8''")[1]);
};

export const createTestIdFromLabel = (label: string) => {
  return label.split(' ').join('-').toLowerCase();
};

export function diffInMinutes(dateLeft: Date, dateRight: Date): number {
  return moment(dateLeft).diff(moment(dateRight), 'minutes');
}
