import { useState, useEffect } from 'react';
import { courseService, groupService } from 'services';
import ButtonWithSelect from 'components/buttons/ButtonWithSelect';
import { withRouter } from 'utils/withRouter';
import { Button, ButtonContainer } from 'components/buttons';
import * as ActivityUtils from '@kritik/utils/activity';
import * as statusUtil from '@kritik/utils/stage';
import { localize } from 'locales/index';
import { useIsFeatureFlagEnabled } from 'context/growthbookContext';
import { initiateAiContentCheck } from './utils';
import { ADVANCE_ACTIVITY_STAGE, REVERT_ACTIVITY_STAGE } from '.';
import { BetaBadge } from 'components/Badges';

const AI_CONTENT_CHECK = localize({ message: 'Activity.Details.Action.ContentCheck' });

type MainButton = {
  label: string;
  value: string;
  onClick?: () => void;
  unavailable?: boolean;
  disabled?: boolean;
  type?: 'primary' | 'secondary';
  testid?: string;
};

type SecondaryButton = {
  label: string;
  value: string;
  disabled?: boolean;
  tooltip?: string;
  testid?: string;
};

type Actions = {
  main?: MainButton;
  secondary: SecondaryButton[];
};

const GroupPresentationActivityButtons = withRouter(
  ({
    activity,
    isScheduled,
    router,
    openRevertActivityModal,
    openAdvanceActivityStageModal,
  }: any) => {
    const [loading, setLoading] = useState(true);
    const [courseHasGroupSets, setCourseHasGroupSets] = useState(false);
    const [courseHasStudents, setCourseHasStudents] = useState(false);

    const { courseId } = router.params;
    const activityId = activity._id;
    const exportAiContentCheckEnabled = useIsFeatureFlagEnabled('export_ai_content_report');

    useEffect(() => {
      const getCourseGroupSets = async () => {
        try {
          const response = await groupService().getAllGroupSets({ courseId });
          if (response.data.length) {
            setCourseHasGroupSets(true);
          }
          const _response = await courseService().getStudents({ courseId });
          if (_response.data.length) {
            setCourseHasStudents(true);
          }
        } catch (ignore) {}
        setLoading(false);
      };
      void getCourseGroupSets();
    }, []);

    if (loading) {
      return null;
    }

    const createStageHasPassed = statusUtil.isEvaluateOrLater(activity);

    const handleActionChange = (e: any) => {
      const option = e.target.value;
      if (option === AI_CONTENT_CHECK) {
        return initiateAiContentCheck(activityId);
      }
      if (option === REVERT_ACTIVITY_STAGE) {
        return openRevertActivityModal();
      }
      if (option === ADVANCE_ACTIVITY_STAGE) {
        return openAdvanceActivityStageModal();
      }

      const path = e.target.value;
      router.push(path);
    };

    const editLink = `/course/${courseId}/assignment/${activityId}/edit-assignment`;
    const editParticipationLink = `/course/${courseId}/assignment/${activityId}/edit-participation`;
    const editScheduleLink = `/course/${courseId}/assignment/${activityId}/presentation-schedule`;

    const getActions = (): Actions => {
      if (statusUtil.isFinalized({ assignment: activity })) {
        const options: Actions = {
          main: {
            label: localize({ message: 'Activity.GroupPresentation.Overview.EditActivity' }),
            testid: 'group-presentation-edit-activity',
            value: editLink,
            type: 'secondary',
          },
          secondary: [],
        };
        if (exportAiContentCheckEnabled) {
          options.secondary.push({
            label: AI_CONTENT_CHECK,
            value: AI_CONTENT_CHECK,
            testid: 'ai-content-check',
          });
        }
        return options;
      }

      if (statusUtil.isGrading({ assignment: activity })) {
        const options: Actions = {
          secondary: [
            {
              label: localize({ message: 'Activity.GroupPresentation.Overview.EditActivity' }),
              value: editLink,
            },
            {
              label: localize({ message: 'Activity.GroupPresentation.Overview.EditParticipation' }),
              value: editParticipationLink,
              disabled: true,
              tooltip: localize({
                message: 'Activity.GroupPresentation.Overview.NoEditPastCreateStage',
              }),
            },
          ],
        };
        if (exportAiContentCheckEnabled) {
          options.secondary.push({
            label: AI_CONTENT_CHECK,
            value: AI_CONTENT_CHECK,
            testid: 'ai-content-check',
          });
        }
        return options;
      }

      if (createStageHasPassed) {
        const options: Actions = {
          main: {
            label: localize({ message: 'Activity.GroupPresentation.Overview.EditSchedule' }),
            value: editScheduleLink,
          },
          secondary: [
            {
              label: localize({ message: 'Activity.GroupPresentation.Overview.EditActivity' }),
              value: editLink,
              testid: 'group-presentation-edit-activity',
            },
            {
              label: localize({ message: 'Activity.GroupPresentation.Overview.EditParticipation' }),
              value: editParticipationLink,
              disabled: true,
              tooltip: localize({
                message: 'Activity.GroupPresentation.Overview.NoEditPastCreateStage',
              }),
            },
          ],
        };
        if (exportAiContentCheckEnabled) {
          options.secondary.push({
            label: AI_CONTENT_CHECK,
            value: AI_CONTENT_CHECK,
            testid: 'ai-content-check',
          });
        }
        return options;
      }

      if (isScheduled) {
        return {
          main: {
            label: localize({ message: 'Activity.GroupPresentation.Overview.EditSchedule' }),
            value: editScheduleLink,
            testid: 'schedule-activity',
          },
          secondary: [
            {
              label: localize({ message: 'Activity.GroupPresentation.Overview.EditActivity' }),
              value: editLink,
              testid: 'group-presentation-edit-activity',
            },
            {
              label: localize({ message: 'Activity.GroupPresentation.Overview.EditParticipation' }),
              value: editParticipationLink,
            },
          ],
        };
      }

      if (ActivityUtils.hasGroupCreators(activity)) {
        return {
          main: {
            label: localize({ message: 'Activity.GroupPresentation.Overview.ScheduleActivity' }),
            value: editScheduleLink,
            testid: 'schedule-activity',
          },
          secondary: [
            {
              label: localize({ message: 'Activity.GroupPresentation.Overview.EditActivity' }),
              value: editLink,
              testid: 'group-presentation-edit-activity',
            },
            {
              label: localize({ message: 'Activity.GroupPresentation.Overview.EditParticipation' }),
              value: editParticipationLink,
            },
          ],
        };
      }

      if (courseHasGroupSets) {
        return {
          main: {
            label: localize({ message: 'Activity.GroupPresentation.Overview.EditParticipation' }),
            value: editParticipationLink,
            testid: 'group-presentation-edit-participation',
          },
          secondary: [
            {
              label: localize({ message: 'Activity.GroupPresentation.Overview.EditActivity' }),
              value: editLink,
              testid: 'group-presentation-edit-activity',
            },
            {
              label: localize({ message: 'Activity.GroupPresentation.Overview.ScheduleActivity' }),
              value: editScheduleLink,
              disabled: true,
              tooltip: localize({
                message: 'Activity.GroupPresentation.Overview.SelectBeforeSchedule',
              }),
            },
          ],
        };
      }

      if (courseHasStudents) {
        return {
          main: {
            label: localize({ message: 'Activity.GroupPresentation.Overview.EditActivity' }),
            value: editLink,
            testid: 'group-presentation-edit-activity',
          },
          secondary: [
            {
              label: localize({ message: 'Activity.GroupPresentation.Overview.EditParticipation' }),
              value: editParticipationLink,
              disabled: true,
              tooltip: localize({
                message: 'Activity.GroupPresentation.Overview.GroupSetsBeforeEditParticipation',
              }),
            },
            {
              label: localize({ message: 'Activity.GroupPresentation.Overview.ScheduleActivity' }),
              value: editScheduleLink,
              disabled: true,
              tooltip: localize({
                message: 'Activity.GroupPresentation.Overview.GroupSetsBeforeSchedule',
              }),
            },
          ],
        };
      }

      return {
        main: {
          label: localize({ message: 'Activity.GroupPresentation.Overview.EditActivity' }),
          value: editLink,
          testid: 'group-presentation-edit-activity',
        },
        secondary: [
          {
            label: localize({ message: 'Activity.GroupPresentation.Overview.EditParticipation' }),
            value: editParticipationLink,
            disabled: true,
            tooltip: localize({
              message: 'Activity.GroupPresentation.Overview.StudentsBeforeEditParticipation',
            }),
          },
          {
            label: localize({ message: 'Activity.GroupPresentation.Overview.ScheduleActivity' }),
            value: editScheduleLink,
            disabled: true,
            tooltip: localize({
              message: 'Activity.GroupPresentation.Overview.StudentsBeforeSchedule',
            }),
          },
        ],
      };
    };

    const { secondary, main } = getActions();
    const canAdvanceStage =
      statusUtil.isScheduled(activity) &&
      !statusUtil.isDraft({ assignment: activity }) &&
      !statusUtil.isGradingOrLater(activity);
    const allSecondaryOptions = [
      {
        value: REVERT_ACTIVITY_STAGE,
        disabled: !statusUtil.isCreateOrLater(activity),
        label: (
          <>
            <span>{REVERT_ACTIVITY_STAGE}</span>
            <span style={{ marginLeft: '0.5em' }}>
              <BetaBadge />
            </span>
          </>
        ),
        testid: 'revert-activity-stage',
      },
      canAdvanceStage && {
        value: ADVANCE_ACTIVITY_STAGE,
        label: <span>{ADVANCE_ACTIVITY_STAGE}</span>,
        testid: 'advance-activity-stage',
      },
      ...secondary,
    ];

    return (
      <ButtonContainer align="right">
        <ButtonWithSelect
          options={allSecondaryOptions}
          onChange={handleActionChange}
          title="Actions"
          type="secondary"
          position="right"
          testid="presentation-actions"
        />
        {main && (
          <Button
            type={main.type ?? 'primary'}
            onClick={() => {
              main.value ? router.push(main.value) : main.onClick();
            }}
            disabled={main.disabled}
            unavailable={main.unavailable}
            testid={main.testid}
          >
            {main.label}
          </Button>
        )}
      </ButtonContainer>
    );
  }
);

export default GroupPresentationActivityButtons;
