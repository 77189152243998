import * as _ from 'lodash-es';
import {
  isCreate,
  isEvaluate,
  isFeedback,
  isProcessing1,
  isGrading,
  isFinalized,
} from '@kritik/utils/stage';
import { FeedbackScoreUtil } from '@kritik/utils/grade';
import * as creationStatusUtil from '@kritik/utils/creation/status';

export const sortByFeedback = (
  itemList: any,
  ascending: any,
  activity: any,
  tableSettings?: any,
  feedbackReceivedMap?: any
) => {
  let sortedItemlist = [];

  if (isCreate({ assignment: activity })) {
    sortedItemlist = sortInCreateStage(itemList);
  } else if (isProcessing1({ assignment: activity })) {
    sortedItemlist = sortInGracePeriod(itemList);
  } else if (isEvaluate({ assignment: activity })) {
    sortedItemlist = sortInEvaluateStage(itemList, ascending);
  } else if (isFeedback({ assignment: activity })) {
    sortedItemlist = sortInFeedbackStage({
      itemList,
      ascending,
      activity,
      tableSettings,
      feedbackReceivedMap,
    });
  } else if (isGrading({ assignment: activity }) || isFinalized({ assignment: activity })) {
    sortedItemlist = sortInGradingStage({
      itemList,
      ascending,
      activity,
      tableSettings,
      feedbackReceivedMap,
    });
  }
  return sortedItemlist;
};

const getCreation = (item: any) => {
  return item.creation;
};

const getStudent = (item: any) => {
  return item.student;
};

const sortInCreateStage = (itemList: any) => {
  return itemList;
};

const sortInGracePeriod = (itemList: any) => {
  return sortInCreateStage(itemList);
};

const sortInEvaluateStage = (itemList: any, ascending?: any) => {
  return sortInCreateStage(itemList);
};

const sortInFeedbackStage = ({
  itemList,
  ascending,
  activity,
  tableSettings,
  feedbackReceivedMap,
}: any) => {
  const order = ascending ? 'asc' : 'desc';
  const sortByFeedbackReceived = !tableSettings.isShowFeedbackCompleted;
  return _.orderBy(
    itemList,
    [
      function (item) {
        const creation = getCreation(item);
        const student = getStudent(item);
        if (sortByFeedbackReceived) {
          return FeedbackScoreUtil.getPercentageFOFReceivedForStudentFromMap(
            feedbackReceivedMap,
            creation
          );
        }
        const { toDo } = FeedbackScoreUtil.numFOFCompleted(creation, activity, student._id);
        if (creationStatusUtil.wasCreationMissed(creation)) {
          return 0;
        }
        if (toDo === 0) {
          return 0;
        }
        return FeedbackScoreUtil.getPercentFOFCompleted(creation, activity);
      },

      function (item) {
        const creation = getCreation(item);
        const student = getStudent(item);
        return sortByFeedbackReceived
          ? FeedbackScoreUtil.getNumFOFToReceiveForCreation(creation)
          : FeedbackScoreUtil.numFOFCompleted(creation, activity, student._id).toDo;
      },
    ],
    [order, order]
  );
};

const sortInGradingStage = ({
  itemList,
  ascending,
  activity,
  tableSettings,
  feedbackReceivedMap,
}: any) => {
  const order = ascending ? 'asc' : 'desc';
  const sortByFeedbackReceived = !tableSettings.isShowFeedbackCompleted;
  return _.orderBy(
    itemList,
    [
      function (item) {
        const creation = getCreation(item);
        const student = getStudent(item);

        if (sortByFeedbackReceived) {
          const received = FeedbackScoreUtil.getNumFOFReceivedForStudentFromMap(
            feedbackReceivedMap,
            student._id
          );
          return received !== 0;
        }
        const { toDo } = FeedbackScoreUtil.numFOFCompleted(creation, activity, student._id);
        return toDo !== 0;
      },
      // missed count
      function (item) {
        const creation = getCreation(item);
        const student = getStudent(item);
        if (sortByFeedbackReceived) {
          const toReceive = FeedbackScoreUtil.getNumFOFToReceiveForCreation(creation);
          const received = FeedbackScoreUtil.getNumFOFReceivedForStudentFromMap(
            feedbackReceivedMap,
            student._id
          );
          return toReceive - received;
        }
        const { toDo, completedFOF } = FeedbackScoreUtil.numFOFCompleted(
          creation,
          activity,
          student._id
        );
        return toDo - completedFOF;
      },
    ],
    [order, order]
  );
};
