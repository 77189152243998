import React from 'react';
import TextInput from 'components/core/input/Text';
import { Button } from 'components/buttons';
import { InlineInformation, PageHeader } from 'components/layout';
import Table from 'components/layout/SimpleTable';
import TableRow from 'components/layout/SimpleTable/Row';
import TableCell from 'components/layout/SimpleTable/Cell';
import TableHead from 'components/layout/SimpleTable/Head';
import TableBody from 'components/layout/SimpleTable/Body';
import { useGetEdlinkConnectionRequests } from 'hooks/admin';
import PageContainer from 'components/layout/PageContainer';

function LmsConnectionRequests() {
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState('');
  const { data, isFetching } = useGetEdlinkConnectionRequests({
    email,
    onError: (e) => setError(e.response?.data?.errors?.message || 'An error occurred'),
  });

  return (
    <PageContainer>
      <PageHeader title="LMS Connection Request By User" />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          setEmail(e.target['email'].value);
        }}
      >
        <TextInput
          inputProps={{
            autoComplete: 'off',
            required: true,
          }}
          name="email"
          testid="email"
          autoFocus
          required
        />
        <Button testid="submit" type="primary" inputType="submit" disabled={isFetching}>
          {isFetching ? 'Loading' : 'Get Data'}
        </Button>
        {error && (
          <InlineInformation testid="error-info" type="danger">
            {error}
          </InlineInformation>
        )}
        {data && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Edlink Person ID</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Approved At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((d) => (
                <TableRow key={d.edlinkPersonId}>
                  <TableCell>{d.edlinkPersonId}</TableCell>
                  <TableCell>{d.code}</TableCell>
                  <TableCell>{d.createdAt}</TableCell>
                  <TableCell>{d.approvedAt || 'Pending'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </form>
    </PageContainer>
  );
}

export default LmsConnectionRequests;
