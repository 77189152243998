import React from 'react';
import EXCHANGE_ARROWS from 'images/exchange-arrows';
import {
  setIsShowEvalsCompleted,
  setIsShowFeedbackCompleted,
  setCurrentPage,
} from 'redux/creationTable/actions';
import {
  selectIsShowEvalsCompleted,
  selectIsShowFeedbackCompleted,
  selectCreationTableViewType,
} from 'redux/creationTable/select';
import { connect } from 'react-redux';
import {
  EVALUATE_HEADER_LABEL,
  TABLE_HEADERS,
  TABLE_TYPE_OPTIONS,
} from 'components/CreationList/constant';
import getPaginatedResults from 'actions/pagination/actions';
import { navigateToActivityPage } from 'actions/activity';

function ViewType(props: any) {
  if (!props.column || props.tableViewType === TABLE_TYPE_OPTIONS.SCORING) {
    return null;
  }

  const getSwitchFunction = () => {
    if (props.column.header === TABLE_HEADERS.EVALUATE) {
      return props.setIsShowEvalsCompleted;
    }
    return props.setIsShowFeedbackCompleted;
  };

  const renderViewTypeLabel = () => {
    let label = EVALUATE_HEADER_LABEL.GIVEN;
    switch (props.column.header) {
      case TABLE_HEADERS.EVALUATE: {
        if (!props.isShowEvalsCompleted) {
          label = EVALUATE_HEADER_LABEL.RECEIVED;
        }
        break;
      }
      case TABLE_HEADERS.FEEDBACK: {
        if (!props.isShowFeedbackCompleted) {
          label = EVALUATE_HEADER_LABEL.RECEIVED;
        }
        break;
      }
      default:
        break;
    }
    return `${label} `;
  };

  const handleClick = (e: any) => {
    const changeView = getSwitchFunction();
    e.stopPropagation();
    changeView(!props.isShowEvalsCompleted);
    if (props.tableViewType === TABLE_TYPE_OPTIONS.PROGRESS && props.column.sorting.isSorted) {
      props.setCurrentPage(1);
      props.navigateToActivityPage({ courseId: props.courseId, assignmentId: props.activityId });
      props.getPaginatedResults();
    }
  };

  return (
    <button
      className="sort-button"
      onClick={handleClick}
      data-testid={`sort-button-${props.column.header}`}
      aria-live="assertive"
    >
      {renderViewTypeLabel()}
      <img src={EXCHANGE_ARROWS} aria-hidden="true" alt="sort icon" />
    </button>
  );
}

const mapStateToProps = (state: any) => {
  return {
    isShowEvalsCompleted: selectIsShowEvalsCompleted(state),
    isShowFeedbackCompleted: selectIsShowFeedbackCompleted(state),
    tableViewType: selectCreationTableViewType(state),
    courseId: state.selected.courseId,
    activityId: state.selected.assignmentId,
  };
};

export default connect(mapStateToProps, {
  setIsShowEvalsCompleted,
  setIsShowFeedbackCompleted,
  getPaginatedResults,
  navigateToActivityPage,
  setCurrentPage,
})(ViewType);
