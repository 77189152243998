import { Field, useForm } from 'react-final-form';
import MultiSelectionField from 'components/core/form/MultiSelectionField';
import { Group } from '@kritik/types.generated';

export function GroupEvaluatorsField() {
  const form = useForm();
  const { groups, creatorGroups } = form.getState().values;

  const handleInputChange = (value: any, onChange: (val: any) => void) => {
    onChange(value);
  };

  const groupsOptions = (groups || [])
    .map((group: Group) => {
      return {
        label: group.name,
        value: group._id,
      };
    })
    .filter((options) => {
      if (creatorGroups.length === 1) {
        return creatorGroups.some((group) => group !== options.value);
      }
      return true;
    });

  return (
    <div className="group-evaluators-field">
      <Field
        name="evaluatorGroups"
        parse={(values) => (values || []).map((v) => v.value)}
        format={(values) =>
          (values || []).map((v) => groupsOptions.find((group) => group.value === v))
        }
      >
        {({ input }) => {
          return (
            <div className="group-select-container">
              <MultiSelectionField
                value={input.value}
                name={input.name}
                onChange={(value) => handleInputChange(value, input.onChange)}
                placeholder="Groups"
                options={groupsOptions}
                testid="presentation-select-groups"
              />
            </div>
          );
        }}
      </Field>
    </div>
  );
}
