import { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Button from 'components/buttons/Button';
import { closeGlobalModal } from 'actions/modals';
import { sanitizeEmails } from '@kritik/utils/general';
import { InlineInformation } from 'components/layout';
import ModalTitle from 'components/modals/Components/Header';
import FieldLabel from 'components/core/form/FieldLabel';
import { localize } from 'locales';
import { getErrorMessageFromResponse } from 'utils/error';
import { TranslatedText } from 'components/TranslatedText';

export const COLLABORATOR_MODAL_ID = 'collaborator-invite-modal';
const ACTION_ID = 'collaborator-invite-bulk';

type CollaboratorInviteModalState = any;
type CollaboratorInviteModalProps = any;

class CollaboratorInviteModal extends Component<
  CollaboratorInviteModalProps,
  CollaboratorInviteModalState
> {
  constructor(props: {}) {
    super(props);
    this.state = {
      emailList: '',
      error: '',
    };
  }

  componentDidUpdate(prevProps: {}) {
    if (
      !(prevProps as any).emailSuccess &&
      (this.props as any).emailSuccess &&
      (this.props as any).emailSuccess == ACTION_ID
    ) {
      setTimeout(() => {
        this.cancel();
      }, 1000);
    }
  }

  onValueChange(ev: any) {
    this.setState({ [ev.target.name]: ev.target.value });
  }

  clearInput() {
    this.setState({
      emailList: '',
    });
  }

  isOpen() {
    return (this.props as any).modalStatus.options.props.id === COLLABORATOR_MODAL_ID;
  }

  cancel() {
    this.clearInput();
    this.setState({ error: '' });
    (this.props as any).closeGlobalModal();
  }

  inviteCollaborators() {
    const emails = sanitizeEmails(this.state.emailList);
    if (!(this.props as any).emailBusy && !(this.props as any).emailSuccess) {
      (this.props as any).inviteCollaborators.mutate(
        {
          courseId: (this.props as any).course._id,
          emails,
        },
        {
          onError: (err) => this.setState({ error: getErrorMessageFromResponse(err) }),
          onSuccess: () => {
            this.clearInput();
            (this.props as any).closeGlobalModal();
            this.setState({ error: '' });
          },
        }
      );
    }
  }

  isFormValid() {
    return this.state.emailList.length > 0;
  }

  render() {
    return (
      <Dialog
        className="main-modal"
        aria-labelledby="simple-dialog-title"
        open={Boolean(this.isOpen())}
        maxWidth="sm"
        fullWidth
      >
        <ModalTitle
          title={localize({
            message: 'RosterManager.CollaboratorsModal.Title',
          })}
        />
        <div className="main-modal__content">
          <div className="mail-modal__item">
            <TranslatedText i18nKey="RosterManager.CollaboratorsModal.Content1" />
          </div>
          <div className="mail-modal__item">
            <InlineInformation type="information">
              {localize({ message: 'RosterManager.CollaboratorsModal.InlineInformation' })}
            </InlineInformation>
          </div>
          {this.state.error && (
            <div>
              <InlineInformation
                type="danger"
                title="Error inviting as collaborator"
                testid="invite-collaborator-error"
              >
                {this.state.error}
              </InlineInformation>
            </div>
          )}
          <div className="main-modal__item">
            <p></p>
            <FieldLabel
              label={`${localize({
                message: 'RosterManager.CollaboratorsModal.EmailFieldLabel',
              })} *`}
            />
            <label htmlFor="collaboratorEmailList" className="visually-hidden">
              {localize({ message: 'Label.FormControl.InviteCollaborators' })}
            </label>
            <textarea
              data-testid="collaboratorEmailList"
              id="collaboratorEmailList"
              value={this.state.emailList}
              className="main-modal__textarea"
              name="emailList"
              placeholder="email1,email2,email3..."
              onChange={(ev) => {
                return this.onValueChange(ev);
              }}
              autoFocus
            />
            <p className="main-modal__textarea-hint">
              {localize({ message: 'RosterManager.CollaboratorsModal.EmailFieldHint' })}{' '}
            </p>
          </div>
        </div>
        <div className="main-modal__footer main-modal__buttons">
          <Button
            testid="invite-collaborator-modal-btn"
            type="primary"
            className="invite-users-btn"
            onClick={() => {
              return this.inviteCollaborators();
            }}
            loading={(this.props as any).emailBusy}
            success={(this.props as any).emailSuccess}
            unavailable={!this.isFormValid()}
            disabled={!this.isFormValid() || (this.props as any).emailBusy}
          >
            {localize({ message: 'RosterManager.CollaboratorsModal.SendButton' })}
          </Button>
          <Button
            type="secondary"
            onClick={() => {
              return this.cancel();
            }}
          >
            {localize({ message: 'RosterManager.CollaboratorsModal.CancelButton' })}
          </Button>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    modalStatus: state.modal,
  };
};

export default connect(mapStateToProps, { closeGlobalModal })(CollaboratorInviteModal);
