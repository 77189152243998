import React from 'react';
import classNames from 'classnames';

const getStyle = (className: any, color: any, weight: any) => {
  const classes = classNames(className, {
    'color-dark': color === 'dark',
    'color-default': color === 'default',
    'color-light': color === 'light',
    'color-attention': color === 'attention',
    'weight-strong': weight === 'strong',
    'weight-default': weight === 'normal',
  });

  return classes;
};

export const SignupMainHeader = ({ children }: any) => {
  return <div className="signup-content__header">{children}</div>;
};

export const SignupMainTitle = ({ children }: any) => {
  return <h1 className="signup-content__main-title">{children}</h1>;
};

export const SignupContent = ({ children }: any) => {
  return (
    <div className="signup-content__body">
      <div className="signup-content__wrapper">{children}</div>
    </div>
  );
};

export const SignupSecondaryTitle = ({ size = 'md', color, weight, children }: any) => {
  let headerClass = `signup-content__title-${size}`;
  headerClass = getStyle(headerClass, color, weight);
  return <h2 className={headerClass}>{children}</h2>;
};
export const SignupHeader = ({ size = 'md', color, weight, children }: any) => {
  let headerClass = `signup-content__title-${size}`;
  headerClass = getStyle(headerClass, color, weight);
  return <h2 className={headerClass}>{children}</h2>;
};

export const SignupText = ({ children, color, weight }: any) => {
  const textClass = getStyle('signup-content__text', color, weight);
  return <div className={textClass}>{children}</div>;
};

export const SignupButtons = ({ children }: any) => {
  return <div className="signup-content__button-container">{children}</div>;
};

export const SignupFooter = ({ children }: any) => {
  return <div className="signup-content__footer">{children}</div>;
};
