import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import ShowMessage from './messages/ShowMessage';
import AddNewEvaluation from './AddNewEvaluation.jsx';
import { AssignmentContext } from '../../context/AssignmentContext';
import { CreationContext } from '../../context/CreationContext';
import { InlineInformation } from 'components/layout';

const mapStateToProps = (state: any) => {
  return {
    evaluator: state.admin.student,
    creation: state.admin.creation,
  };
};

const AddNewEvalContainer = () => {
  const [assignment, setAssignment] = useContext(AssignmentContext);

  const { creation, setCreation } = useContext(CreationContext);

  const [shouldAddNewEval, setShouldAddNewEval] = useState(false);

  const changeShouldAddNewEval = (bool: any) => {
    return setShouldAddNewEval(bool);
  };

  useEffect(() => {
    setShouldAddNewEval(false);
    setCreation(null);
  }, [assignment]);

  return (
    <React.Fragment>
      {creation && (
        <InlineInformation
          style={{ marginTop: 20 }}
          title={
            creation.group
              ? `Creation by: ${creation.group.name} (${creation.user.profile.name} - ${creation.user.email})`
              : `Creation by ${creation.user.profile.name} - ${creation.user.email}`
          }
        />
      )}
      <ShowMessage changeShouldAddNewEval={changeShouldAddNewEval} />
      {shouldAddNewEval && (
        <div style={{ margin: '30px 0' }}>
          <AddNewEvaluation />
        </div>
      )}
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(AddNewEvalContainer);
