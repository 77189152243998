import * as modalTypes from 'types/modal';

export function openGlobalModal(options: any) {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: modalTypes.OPEN_GLOBAL_MODAL,
      payload: options,
    });
  };
}

export function closeGlobalModal() {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: modalTypes.CLOSE_GLOBAL_MODAL,
    });
  };
}

export function openErrorModal(payload: any) {
  return (dispatch: any) => {
    dispatch({
      type: modalTypes.OPEN_ERROR_MODAL,
      payload,
    });
  };
}

export function closeErrorModal() {
  return (dispatch: any) => {
    dispatch({
      type: modalTypes.CLOSE_ERROR_MODAL,
    });
  };
}

export function openInfoModal(payload: any) {
  return (dispatch: any) => {
    dispatch({
      type: modalTypes.OPEN_INFO_MODAL,
      payload,
    });
  };
}

export function initInfoModal() {
  return (dispatch: any) => {
    dispatch({
      type: modalTypes.INIT_INFO_MODAL,
    });
  };
}

export function closeInfoModal() {
  return (dispatch: any) => {
    dispatch({
      type: modalTypes.CLOSE_INFO_MODAL,
    });
    dispatch(initInfoModal());
  };
}

export function openLoadingModal(payload: any) {
  return (dispatch: any) => {
    dispatch({
      type: modalTypes.OPEN_LOADING_MODAL,
      payload,
    });
  };
}

export function closeLoadingModal(payload?: any) {
  return (dispatch: any) => {
    dispatch({
      type: modalTypes.CLOSE_LOADING_MODAL,
      payload,
    });
    dispatch({
      type: modalTypes.INIT_LOADING_MODAL,
    });
  };
}

export function initLoadingModal(payload: any) {
  return (dispatch: any) => {
    dispatch({
      type: modalTypes.INIT_LOADING_MODAL,
      payload,
    });
  };
}

export function openConfirmModal(payload: any) {
  return (dispatch: any) => {
    dispatch({
      type: modalTypes.OPEN_CONFIRM_MODAL,
      payload,
    });
  };
}

export function closeConfirmModal(payload: any) {
  return (dispatch: any) => {
    try {
      dispatch({
        type: modalTypes.CLOSE_CONFIRM_MODAL,
        payload,
      });
    } finally {
      dispatch({
        type: modalTypes.INIT_CONFIRM_MODAL,
      });
    }
  };
}

export function initConfirmModal(payload: any) {
  return (dispatch: any) => {
    dispatch({
      type: modalTypes.INIT_CONFIRM_MODAL,
      payload,
    });
  };
}
