import BaseService from 'services/base';

export default class ConfigService extends BaseService {
  get = () => {
    return this.client.request({
      method: 'GET',
      url: `/config`,
      cancelToken: this.source.token,
    });
  };
}
