import React from 'react';

const WeightInput = (props: any) => {
  if (!props.node.firstChild) {
    return <p>{props.value}</p>;
  }
  return null;
};

export default WeightInput;
