import { normalize } from 'normalizr';
import { combineReducers } from 'redux';

import { mergeEntities } from 'actions/entities';
import { creationSchema } from 'schemas';
import { creationService } from 'services';
import { getGroup } from 'selectors/group';
import { generateAsyncReducer } from 'redux/utils';
import { FeedbackScoreUtil } from '@kritik/utils/grade/feedback';
import { SCORE_VIEW_STATUS } from '@kritik/utils/types';

const SET_STUDENT_CREATION_MAP = 'SET_STUDENT_CREATION_MAP';
const SET_STUDENT_FEEDBACK_RECEIVED_MAP = 'SET_STUDENT_FEEDBACK_RECEIVED_MAP';

export const GET_CREATION = 'GET_CREATION';
const GET_CREATION_SUCCESS = 'GET_CREATION_SUCCESS';
const GET_CREATION_FAILURE = 'GET_CREATION_FAILURE';

export const LIST_CREATIONS = 'LIST_CREATIONS';
const LIST_CREATIONS_SUCCESS = 'LIST_CREATIONS_SUCCESS';
const LIST_CREATIONS_FAILURE = 'LIST_CREATIONS_FAILURE';

const RECORD_SCORE_VIEW = 'RECORD_SCORE_VIEW';
const RECORD_SCORE_VIEW_SUCCESS = 'RECORD_SCORE_VIEW_SUCCESS';
const RECORD_SCORE_VIEW_FAILURE = 'RECORD_SCORE_VIEW_FAILURE';

const DISMISS_SCORE_VIEW = 'DISMISS_SCORE_VIEW';
const DISMISS_SCORE_VIEW_SUCCESS = 'DISMISS_SCORE_VIEW_SUCCESS';
const DISMISS_SCORE_VIEW_FAILURE = 'DISMISS_SCORE_VIEW_FAILURE';

const asyncActions = {
  call: [GET_CREATION, LIST_CREATIONS],
  success: [GET_CREATION_SUCCESS, LIST_CREATIONS_SUCCESS],
  error: [GET_CREATION_FAILURE, LIST_CREATIONS_FAILURE],
};

export function normalizeCreation(creation: any) {
  return (dispatch: any) => {
    const normalizedCreation = normalize(creation, creationSchema);
    dispatch(mergeEntities(normalizedCreation.entities));
  };
}

export function normalizeCreations(creations: any) {
  return (dispatch: any) => {
    const normalizedCreations = normalize(creations, [creationSchema]);
    dispatch(mergeEntities(normalizedCreations.entities));
  };
}

export const getCreation = (params: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: GET_CREATION,
    });
    try {
      const res = await creationService().get(params);
      if (res.data) {
        const creation = res.data;
        dispatch(normalizeCreation(creation));
        dispatch({
          type: GET_CREATION_SUCCESS,
          success: true,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_CREATION_FAILURE,
        error,
      });
    }
  };
};

export const listCreations = (params: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: LIST_CREATIONS,
    });
    try {
      const res = await creationService().list(params);
      if (res.data) {
        const creations = res.data;
        dispatch(normalizeCreations(creations));
        dispatch({
          type: LIST_CREATIONS_SUCCESS,
          success: true,
        });
      }
    } catch (error) {
      dispatch({
        type: LIST_CREATIONS_FAILURE,
        error,
      });
    }
  };
};

export const dismissScoreViewNotification = (creationId: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: DISMISS_SCORE_VIEW,
    });
    try {
      const res = await creationService().updateScoreView({
        creationId,
        data: {
          scoreViewStatus: SCORE_VIEW_STATUS.DISMISSED,
        },
      });
      if (res.data) {
        const { creation } = res.data;
        dispatch(normalizeCreation(creation));
        dispatch({
          type: DISMISS_SCORE_VIEW_SUCCESS,
          success: true,
        });
      }
    } catch (error) {
      dispatch({
        type: DISMISS_SCORE_VIEW_FAILURE,
        error,
      });
    }
  };
};

export const recordScoreViewed = (creationId: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: RECORD_SCORE_VIEW,
    });
    try {
      const res = await creationService().updateScoreView({
        creationId,
        data: {
          scoreViewStatus: SCORE_VIEW_STATUS.VIEWED,
        },
      });
      if (res.data) {
        const { creation } = res.data;
        dispatch(normalizeCreation(creation));
        dispatch({
          type: RECORD_SCORE_VIEW_SUCCESS,
          success: true,
        });
      }
    } catch (error) {
      dispatch({
        type: RECORD_SCORE_VIEW_FAILURE,
        error,
      });
    }
  };
};

export const setStudentCreationMap = (creations: any) => {
  return (dispatch: any, getState: any) => {
    const { entities } = getState();
    const studentCreationMap = {};
    for (const creation of creations) {
      if (creation.group) {
        const group = creation.group.members ? creation.group : getGroup(creation.group, entities);
        for (const memberId of group.members) {
          const studentId = memberId;
          studentCreationMap[studentId] = creation._id;
        }
      } else {
        const studentId = creation.student._id || creation.student;
        studentCreationMap[studentId] = creation._id;
      }
    }
    dispatch({
      type: SET_STUDENT_CREATION_MAP,
      payload: {
        studentCreationMap,
      },
    });
  };
};

export const setStudentFeedbackReceivedMap = (creations: any) => {
  return (dispatch: any) => {
    const studentFeedbackReceivedMap = {};

    for (const creation of creations) {
      if (!creation.scores) {
        continue;
      }

      const { scores } = creation;
      for (const score of scores) {
        const studentId = score.student;

        if (FeedbackScoreUtil.isFOFCompleted(score)) {
          if (studentFeedbackReceivedMap[studentId]) {
            studentFeedbackReceivedMap[studentId] += 1;
          } else {
            studentFeedbackReceivedMap[studentId] = 1;
          }
        }
      }
    }
    dispatch({
      type: SET_STUDENT_FEEDBACK_RECEIVED_MAP,
      payload: {
        studentFeedbackReceivedMap,
      },
    });
  };
};

const studentMapReducer = (state = {}, action: any) => {
  switch (action.type) {
    case SET_STUDENT_CREATION_MAP:
      return action.payload.studentCreationMap;
    default:
      return state;
  }
};

const studentFeedbackReceivedMapReducer = (state = {}, action: any) => {
  switch (action.type) {
    case SET_STUDENT_FEEDBACK_RECEIVED_MAP:
      return action.payload.studentFeedbackReceivedMap;
    default:
      return state;
  }
};

export default combineReducers({
  async: generateAsyncReducer(asyncActions),
  studentCreationMap: studentMapReducer,
  studentFeedbackReceivedMap: studentFeedbackReceivedMapReducer,
});
