import { useState } from 'react';
import { connect } from 'react-redux';
import PageContainer from 'components/layout/PageContainer';
import PageHeader from 'components/layout/PageHeader';
import { RubricView } from 'components/Rubric';
import { selectRubricById } from 'redux/rubric';
import { getAssignment } from 'selectors/activity';
import Button from 'components/buttons/Button';
import { Tooltip } from '@material-ui/core';
import { selectAuthUser } from 'selectors/user';
import { trackEvent } from 'utils/userEvents';
import { localize } from 'locales';

const RubricViewContainer = (props: any) => {
  if (!props.activity || !props.rubric) {
    return null;
  }
  const [printFriendly, setPrintFriendly] = useState(false);
  const pdfName = `${props.activity.title}_rubric.pdf`;

  const printPDF = () => {
    window.document.title = pdfName;
    setPrintFriendly(true);
    window.print();
    setPrintFriendly(false);
  };

  const getTitle = () => {
    return `${props.activity.title} Rubric`;
  };

  return (
    <PageContainer>
      <PageHeader title={getTitle()}>
        <Tooltip
          title={localize({ message: 'Rubric.View.PrintButton.Tooltip' })}
          placement="bottom"
          classes={{ tooltip: 'no-print' }}
        >
          <Button
            type="primary"
            data-testid="create-activity"
            onClick={() => {
              printPDF();
              trackEvent('Rubric Printed', props.authUser, {
                rubricId: props.rubric._id,
                rubricName: props.rubric.name,
                courseName: props.rubric.course.name,
                activityId: props.activity._id,
              });
            }}
            label={localize({ message: 'Button.Label.PrintRubric' })}
          >
            {localize({ message: 'Rubric.View.PrintButton.Text' })}
          </Button>
        </Tooltip>
      </PageHeader>
      <RubricView rubric={props.rubric} printFriendly={printFriendly} />
    </PageContainer>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    rubric: selectRubricById(state, ownProps.router.params.rubricId),
    activity: getAssignment(state, state.selected.assignmentId),
    authUser: selectAuthUser(state),
  };
};

export default connect(mapStateToProps)(RubricViewContainer);
