import classNames from 'classnames';

const SIZES = {
  SMALL: 'sm',
  MEDIUM: 'md',
  DEFAULT: 'default',
  LARGE: 'lg',
  XLARGE: 'xl',
};

type SizeKeys = keyof typeof SIZES;
type FormFieldTitleProps = {
  children?: JSX.Element;
  className?: string;
  label: string;
  size?: (typeof SIZES)[SizeKeys];
};
const FormFieldTitle = (props: FormFieldTitleProps) => {
  const classes = classNames(
    'kritik-form-field__title',
    {
      'kritik-form-field__title--sm': props.size === SIZES.SMALL,
      'kritik-form-field__title--md': props.size === SIZES.MEDIUM,
      'kritik-form-field__title--lg': props.size === SIZES.LARGE,
      'kritik-form-field__title--xl': props.size === SIZES.XLARGE,
    },
    props.className
  );
  return (
    <div className={classes} tabIndex={0}>
      {props.label}
      {props.children}
    </div>
  );
};

FormFieldTitle.defaultProps = {
  size: 'default',
  children: null,
  className: null,
};

export default FormFieldTitle;
