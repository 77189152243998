export function getPageCount(totalRows: any, pageSize: any) {
  if (totalRows === 0 || typeof totalRows === 'undefined' || totalRows === null) {
    return 1;
  }
  const modulo = totalRows % pageSize;
  if (modulo > 0) {
    return Math.floor(totalRows / pageSize) + 1;
  }
  return totalRows / pageSize;
}

export function getUpdatedPath(windowLocation: any, ...params: any[]) {
  const { pathname } = windowLocation;
  const searchParams = new URLSearchParams(window.location.search);
  for (const param of params) {
    searchParams.set(param.param, param.value.toString());
  }
  return `${pathname}?${searchParams.toString()}`;
}
