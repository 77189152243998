import React from 'react';
import classNames from 'classnames';

export const RowSeparator = () => {
  return (
    <tr className="simple-table-row__separator" aria-hidden>
      <td colSpan={100} />
    </tr>
  );
};

const Row = (props: any) => {
  const renderSeparator = () => {
    if (!props.renderSeparator) {
      return null;
    }
    return <RowSeparator />;
  };

  const classes = classNames(
    'simple-table-row',
    {
      'simple-table-row--selectable': props.isSelectable,
      'simple-table-row--selected': props.isSelected,
      'simple-table-row--hoverable': props.isHoverable,
    },
    props.className
  );

  return (
    <React.Fragment>
      <tr
        className={classes}
        onClick={props.onSelectRow}
        onMouseOver={props.onMouseOver}
        onFocus={props.onMouseOver}
        onMouseLeave={props.onMouseLeave}
        onBlur={props.onMouseLeave}
        data-testid={props.testid}
      >
        {props.children}
      </tr>
      {renderSeparator()}
    </React.Fragment>
  );
};

Row.defaultProps = {
  isSelectable: false,
  isSelected: false,
  isHoverable: true,
  renderSeparator: true,
  onSelectRow: null,
  onMouseLeave: null,
  onMouseOver: null,
};

export default Row;
