import * as _ from 'lodash-es';
import { getFeedbackScoreFromHistory } from 'utils/student';
import { getStudent } from './student';

export function sortByFeedbackScore(itemList: any, ascending: any, activity: any) {
  return _.orderBy(
    itemList,
    [
      function (item) {
        const student = getStudent(item);
        const feedbackScore = getFeedbackScoreFromHistory(student, activity._id);
        return feedbackScore;
      },
    ],
    [ascending ? 'asc' : 'desc']
  );
}
