import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Slider from '@material-ui/core/Slider';
import { getMySubmission, getSubmission } from 'selectors/creation';
import { WrittenEvalScoreUtil } from '@kritik/utils/grade';
import * as EvaluationUtils from '@kritik/utils/creation/evaluation';
import * as FormatUtils from '@kritik/utils/format';
import ScoreDecorator from 'components/Assignment/Scores/ScoreDecorator';
import { TEMPLATES, TYPES, SUBMITTING_DESCRIPTIONS, VIEWING_DESCRIPTIONS } from './constants';
import { localize } from '../../../locales';

const mapStateToProps = (state: any) => {
  // student view doesn't have selected submission Id
  const submission = state.selected.submissionId
    ? getSubmission(state, state.selected.submissionId)
    : getMySubmission(state, state.selected.assignmentId);

  return {
    submission,
  };
};

type OwnFOFSliderState = any;

type FOFSliderState = OwnFOFSliderState & typeof FOFSlider.defaultProps;

class FOFSlider extends React.Component<{}, FOFSliderState> {
  static defaultProps = {
    disabled: false,
    criticalChange: null,
    motivationalChange: null,
  };

  state = {
    critical: 0,
    motivational: 0,
    isPristine: { critical: true, motivational: true },
  };

  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'critical' does not exist on type 'Readon... Remove this comment to see the full error message
    const { critical, motivational } = this.props;
    let isCriticalPristine = true;
    let isMotivationalPristine = true;
    if (critical !== this.state.critical) {
      isCriticalPristine = false;
    }
    if (motivational !== this.state.motivational) {
      isMotivationalPristine = false;
    }
    this.setState({
      motivational,
      critical,
      isPristine: { critical: isCriticalPristine, motivational: isMotivationalPristine },
    });
  }

  componentDidUpdate(prevProps: {}, prevState: FOFSliderState) {
    // @ts-expect-error TS(2339) FIXME: Property 'critical' does not exist on type 'Readon... Remove this comment to see the full error message
    const { critical, motivational } = this.props;
    let isCriticalPristine = true;
    let isMotivationalPristine = true;
    if (critical !== prevState.critical) {
      isCriticalPristine = false;
    }
    if (motivational !== prevState.motivational) {
      isMotivationalPristine = false;
    }
    if (!isCriticalPristine || !isMotivationalPristine) {
      this.setState({
        motivational,
        critical,
        isPristine: { critical: isCriticalPristine, motivational: isMotivationalPristine },
      });
    }
  }

  handleChange(value: any, category: any) {
    const { isPristine } = this.state;
    isPristine[category] = false;
    this.setState({ [category]: value, isPristine });
    (this.props as any).onChange({ currentTarget: { name: category, value } });
  }

  renderSlider(type: any) {
    const isSubmitting = !(this.props as any).disabled;
    const sliderContainerClasses = classNames('fof-slider__container', {
      submitting: isSubmitting,
    });
    const classesSlider = {
      root: 'fof-slider-root',
      track: 'fof-slider-track',
      rail: 'fof-slider-rail',
      mark: 'fof-slider-mark',
      markLabel: 'fof-slider-mark-label',
      markActive: 'fof-slider-mark-active',
      thumb: isSubmitting ? 'fof-slider-thumb-inactive' : 'fof-slider-thumb',
      disabled: 'is-disabled',
    };

    if (!this.state.isPristine[type]) {
      classesSlider.thumb = 'fof-slider-thumb';
    }

    const scoreDiff =
      type === TYPES.CRITICAL
        ? (this.props as any).criticalChange
        : (this.props as any).motivationalChange;
    const template = TEMPLATES[type];
    const descriptions = isSubmitting ? SUBMITTING_DESCRIPTIONS[type] : VIEWING_DESCRIPTIONS[type];
    const templateIdx = this.state[type] - 1;
    const commentsRequired = isSubmitting && (this.props as any).commentsRequired;

    return (
      <div className={sliderContainerClasses}>
        <div className="fof-slider__banner">
          <div
            className={`fof-slider__banner-image ${type}`}
            style={{ backgroundImage: `url(${template.background})` }}
          >
            <img src={template.badge} aria-hidden="true" />
          </div>
          <div className="fof-slider__banner-text-container">
            <div className="fof-slider__banner-title-container">
              <p className="fof-slider__banner-title" tabIndex={0}>
                {template.title}
              </p>
              {(scoreDiff || scoreDiff === 0) && (
                <ScoreDecorator>
                  {FormatUtils.simplePercent(
                    WrittenEvalScoreUtil.calcScorePerCategory({
                      numEvalsAssigned: EvaluationUtils.getNumEvaluationsToDo(
                        (this.props as any).submission,
                        (this.props as any).studentId
                      ),
                      feedbackSkillsChange: scoreDiff,
                    })
                  )}
                </ScoreDecorator>
              )}
            </div>
            <p className="fof-slider__banner-description" tabIndex={0}>
              {template.description}
            </p>
          </div>
        </div>
        <div className="fof-slider__controls-container">
          <div className="fof-slider__controls">
            <Slider
              data-testid={`fof-slider-${type}`}
              disabled={(this.props as any).disabled}
              aria-label={`${type} slider`}
              valueLabelDisplay={isSubmitting && this.state.isPristine[type] ? 'off' : 'on'}
              value={this.state[type]}
              step={1}
              onChangeCommitted={(e, value) => {
                return this.handleChange(value, type);
              }}
              marks={template.marks}
              classes={classesSlider}
              min={1}
              max={4}
              getAriaValueText={(value) => {
                return `${value} ${template.marks[value - 1].label}`;
              }}
            />
          </div>
          <div className="fof-slider__mark-description-container">
            {!template.marks[templateIdx] && (
              <div className="fof-slider__mark-description-empty">
                {localize({ message: 'FOFSlider.description.empty' })}
              </div>
            )}
            <p className="fof-slider__mark-description-label">
              {template.marks[templateIdx] && template.marks[templateIdx].label}
            </p>
            <p className="fof-slider__mark-description-content">{descriptions[templateIdx]}</p>
            {commentsRequired && (
              <>
                <div className="fof-slider__feedback-required-label">Written Feedback Required</div>
                <p className="fof-slider__feedback-required-content">
                  {template.marks[templateIdx] &&
                    `Please help your peer better understand what made the tone
                    of their written evaluation ${template.marks[
                      templateIdx
                    ].label.toLowerCase()}.`}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderSlider(TYPES.MOTIVATIONAL)}
        {this.renderSlider(TYPES.CRITICAL)}
      </>
    );
  }
}

export default connect(mapStateToProps, {})(FOFSlider);
