import TopBarWithButton from 'components/TopBarWithButton';
import { stopImpersonatingMutation } from 'hooks/user';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales';
import { InlineInformation } from 'components/layout';

type Props = {
  name: string;
};

function ImpersonatingTopBar({ name }: Props) {
  const stopImpersonating = stopImpersonatingMutation();
  const handleImpersonationExit = () => {
    stopImpersonating.mutate();
  };
  // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
  const impersonate = App.config.get('impersonate');
  if (!impersonate) {
    return null;
  }

  const exitButtoni18n = impersonate.asAdmin
    ? 'ImpersonatingTopBar.Admin.ExitButton'
    : 'ImpersonatingTopBar.StudentView.ExitButton';

  return (
    <TopBarWithButton
      icon={
        <InlineInformation className="impersonating-bar__info">
          <TranslatedText i18nKey="ImpersonatingTopBar.Info" /> {name}
        </InlineInformation>
      }
      onActionButtonClick={handleImpersonationExit}
      actionButtonLabel={localize({ message: exitButtoni18n })}
      className="impersonating-bar__container"
      buttonTestId="exit-impersonation"
    />
  );
}

export default ImpersonatingTopBar;
