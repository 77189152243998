import React from 'react';
import MarginVertical from 'components/Spotlight/View/MarginVertical';

function ReferenceContentWrapper(props: any) {
  const { title, children } = props;
  return (
    <div className="kritik-form-container">
      <h3>{title}</h3>
      <MarginVertical size="sm" />
      <div className="spotlight-view__reference-content">{children}</div>
    </div>
  );
}

export default ReferenceContentWrapper;
