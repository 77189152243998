import * as types from 'types';

const INITAL_STATE = {
  errors: {},
  // late submission
  courses: null,
  assignments: null,
  student: null,
  potentialReviewers: null,
  numOfEvalsProfWants: 0,
  prof: null,
  reviewers: null,
  files: [],
  content: '',
  isLoadingCourses: false,
  isLoadingAssignments: false,
  // late evaluation
  creation: null,
  // change course price
  institution: null,
  courseCurrency: null,
  coursePrice: 0,
  // add new institution,
  institutionExists: null,
  // admin dashboard
  dashboardLastCourseShown: false,
  dashboardCourses: [],
  totalMaxAvailable: 0,
  totalResultCount: 0,
  hubspotDeals: {},
};

const adminReducer = (state = INITAL_STATE, action: any) => {
  switch (action.type) {
    case types.SET_ERRORS:
      if (action.payload) {
        return {
          ...state,
          errors: {
            ...state.errors,
            [action.payload.entity]: action.payload.error,
          },
        };
      }
      break;
    case types.GET_COURSES_FOR_LATE_SUBMISSION:
      return {
        ...state,
        isLoadingCourses: true,
        isLoadingAssignments: false,
      };
    case types.GET_COURSES_FOR_LATE_SUBMISSION_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          courses: action.payload.courses,
          isLoadingCourses: false,
          isLoadingAssignments: false,
        };
      }
      break;
    case types.GET_ASSIGNMENTS_FOR_LATE_SUBMISSION:
      return {
        ...state,
        isLoadingAssignments: true,
        isLoadingCourses: false,
      };
    case types.GET_ASSIGNMENTS_FOR_LATE_SUBMISSION_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          assignments: action.payload.assignments,
          student: action.payload.student,
          prof: action.payload.prof,
          isLoadingAssignments: false,
          isLoadingCourses: false,
        };
      }
      break;
    case types.GET_POTENTIAL_REVIEWERS_FOR_LATE_SUBMISSION_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          potentialReviewers: action.payload.potentialReviewers,
          files: action.payload.files,
          content: action.payload.content,
          numOfEvalsProfWants: action.payload.numOfEvalsProfWants,
        };
      }
      break;
    case types.GET_SNAPSHOT_OF_ALL_COURSES_SUCCESS:
      if (action.payload) {
        if (action.payload.courses.length < 100) {
          return {
            ...state,
            dashboardCourses: action.payload.courses,
            dashboardLastCourseShown: true,
            totalResultCount: action.payload.totalResultCount,
            totalMaxAvailable: action.payload.totalMaxAvailable,
          };
        }
        return {
          ...state,
          dashboardCourses: action.payload.courses,
          dashboardLastCourseShown: false,
          totalResultCount: action.payload.totalResultCount,
          totalMaxAvailable: action.payload.totalMaxAvailable,
        };
      }
      break;
    case types.GET_HUBSPOT_DEALS_SUCCESS:
      if (action.payload) {
        const { hubspotDeals } = state;
        hubspotDeals[action.payload.email] = action.payload.deals;
        return {
          ...state,
          hubspotDeals: {
            ...state.hubspotDeals,
            [action.payload.email]: action.payload.deals,
          },
        };
      }
      break;
    case types.FIND_CREATION_FOR_MISSED_EVALUATION_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          creation: action.payload.creation,
        };
      }
    case types.GET_COURSE_AND_INSTITUTION_INFO_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          courseCurrency: action.payload.courseCurrency,
          coursePrice: action.payload.coursePrice,
          institution: action.payload.institution,
        };
      }
    case types.VERIFY_INSTITUTION:
      return {
        ...state,
        institutionExists: null,
      };
    case types.VERIFY_INSTITUTION_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          institutionExists: action.payload.institution,
        };
      }
    case types.GET_COUPON_DATA_SUCCESS:
      if (!(state as any).couponData) {
        return {
          ...state,
          couponData: action.payload.data,
        };
      }
      return {
        ...state,
        couponData: {
          ...action.payload.data,
          data: [...(state as any).couponData.data, ...action.payload.data.data],
        },
      };
    default:
      return state;
  }
};

export default adminReducer;
