import { Field, useForm } from 'react-final-form';
import MultiSelectionField from 'components/core/form/MultiSelectionField';
import { Student } from '@kritik/types.generated';

export function getStudentOptions(creatorStudents: string[], students: Student[]) {
  const studentsOptions = (students || []).map((student: any, index: number) => {
    return {
      label: student.user.profile.name,
      value: student._id,
      testid: `student-evaluator-option-${index}`,
    };
  });
  if (creatorStudents.length === 1) {
    return studentsOptions.filter((option) => option.value !== creatorStudents[0]);
  }
  return studentsOptions;
}

const StudentEvaluatorsField = ({ students }: { students: Student[] }) => {
  const form = useForm();
  const { creatorStudents } = form.getState().values;

  const handleInputChange = (value: any, onChange: (val: any) => void) => {
    onChange(value);
  };

  const studentsOptions = getStudentOptions(creatorStudents, students);

  return (
    <div className="student-evaluators-field">
      <Field
        name="evaluatorStudents"
        parse={(values) => (values || []).map((v) => v.value)}
        format={(values) =>
          (values || []).map((v) => studentsOptions.find((student) => student.value === v))
        }
      >
        {({ input }) => {
          return (
            <div className="student-select-container">
              <MultiSelectionField
                value={input.value}
                name={input.name}
                onChange={(value) => handleInputChange(value, input.onChange)}
                placeholder="Students"
                options={studentsOptions}
                testid="presentation-select-students"
              />
            </div>
          );
        }}
      </Field>
    </div>
  );
};

export default StudentEvaluatorsField;
