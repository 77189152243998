import { combineReducers } from 'redux';
import { normalize, denormalize } from 'normalizr';
import { mergeEntities } from 'actions/entities';

import { SpotlightTagService } from 'services';
import { spotlightTagSchema } from 'schemas';
import { BaseStore, generateAsyncReducer } from 'redux/utils';
import { SpotlightTag } from 'utils/spotlightTag';

export const LIST_SPOTLIGHT_TAGS = 'LIST_SPOTLIGHT_TAGS';
const LIST_SPOTLIGHT_TAGS_SUCCESS = 'LIST_SPOTLIGHT_TAGS_SUCCESS';
const LIST_SPOTLIGHT_TAGS_FAILURE = 'LIST_SPOTLIGHT_TAGS_FAILURE';

const asyncActions = {
  call: [LIST_SPOTLIGHT_TAGS],
  success: [LIST_SPOTLIGHT_TAGS_SUCCESS],
  error: [LIST_SPOTLIGHT_TAGS_FAILURE],
};

export const listSpotlightTags = (params: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: LIST_SPOTLIGHT_TAGS,
    });
    const spotlightTagService = new SpotlightTagService();
    try {
      const res = await spotlightTagService.list(params);
      if (res.data) {
        dispatch({
          type: LIST_SPOTLIGHT_TAGS_SUCCESS,
        });
        const spotlightTags = res.data;
        const normalized = normalize(spotlightTags, [spotlightTagSchema]);
        dispatch(mergeEntities(normalized.entities));
      }
    } catch (error) {
      dispatch({
        type: LIST_SPOTLIGHT_TAGS_FAILURE,
      });
    }
  };
};

export class SpotlightTagStore extends BaseStore {
  getEntity() {
    return this.state.entities.spotlightTags || {};
  }

  getById(id: any) {
    const tag = denormalize(id, spotlightTagSchema, this.getEntities());
    return SpotlightTag(tag);
  }

  listByType(type: any) {
    const ids = Object.keys(this.getEntity());
    const tags = denormalize(ids, [spotlightTagSchema], this.getEntities());
    const filterTypedTags = tags.filter((tag: any) => {
      return tag.type === type;
    });

    return filterTypedTags.map((tag: any) => {
      return SpotlightTag(tag);
    });
  }
}

export default combineReducers({
  async: generateAsyncReducer(asyncActions),
});
