import React from 'react';
import classNames from 'classnames';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const STATES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  FAIL: 'fail',
};

type StatusResultProps = {
  status: string;
  label?: string;
  ariaLabel: string;
  symbol?: string;
};

const StatusResult = (props: StatusResultProps) => {
  const classes = classNames('activity-stage-result', {
    'activity-stage-result--success': props.status === STATES.SUCCESS,
    'activity-stage-result--warning': props.status === STATES.WARNING,
    'activity-stage-result--failure': props.status === STATES.FAIL,
  });

  const getIcon = () => {
    if (props.label) {
      return props.label;
    }
    let _symbol = props.symbol;
    if (!_symbol) {
      _symbol = props.status;
    }
    switch (_symbol) {
      case STATES.SUCCESS:
        return <DoneIcon aria-hidden={undefined} role="img" aria-label={props.ariaLabel} />;
      case STATES.WARNING:
        return <PriorityHighIcon aria-hidden={undefined} role="img" aria-label={props.ariaLabel} />;
      case STATES.FAIL:
        return <CloseIcon aria-hidden={undefined} role="img" aria-label={props.ariaLabel} />;
      default:
        return null;
    }
  };

  return (
    <div className={classes} data-testid={`${props.symbol}-${props.status}-icon`}>
      {getIcon()}
    </div>
  );
};

export default StatusResult;
