import ACTIVITY_TYPE_ICONS from 'images/activity-types';
import { ACTIVITY_TYPES } from '@kritik/constants/activity';
import classNames from 'classnames';

const getIcon = (type: string) => {
  switch (type) {
    case ACTIVITY_TYPES.PEER:
      return ACTIVITY_TYPE_ICONS.PEER;
    case ACTIVITY_TYPES.GROUP:
      return ACTIVITY_TYPE_ICONS.GROUP;
    case ACTIVITY_TYPES.WITHIN_GROUP:
      return ACTIVITY_TYPE_ICONS.WITHIN_GROUP;
    case ACTIVITY_TYPES.CALIBRATION:
      return ACTIVITY_TYPE_ICONS.CALIBRATION;
    case ACTIVITY_TYPES.GROUP_PRESENTATION:
    case ACTIVITY_TYPES.INDIVIDUAL_PRESENTATION:
      return ACTIVITY_TYPE_ICONS.PRESENTATION_BIG;
    default:
      return ACTIVITY_TYPE_ICONS.PEER;
  }
};

const getType = (type: any) => {
  if (type === ACTIVITY_TYPES.PEER) {
    return ACTIVITY_TYPES.INDIVIDUAL;
  }
  return type;
};

const ActivityType = (props: { type: string; className?: string }) => {
  const { type } = props;
  if (!type) {
    return null;
  }

  const wrapperClasses = classNames('activity-type-small-display', props.className);

  return (
    <div className={wrapperClasses}>
      <img
        className="activity-type-small-display__icon"
        src={getIcon(type)}
        aria-hidden="true"
        data-testid={`${type}-icon`}
      />
      <div className="activity-type-small-display__text">{getType(type)} Activity</div>
    </div>
  );
};

export default ActivityType;
