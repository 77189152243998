import { useState } from 'react';
import { connect } from 'react-redux';
import Modal from 'components/modals/Components/Modal';
import ModalTitle from 'components/modals/Components/Header';
import ModalAction from 'components/modals/Components/Action';
import ModalContent from 'components/modals/Components/Content';
import { isFeedbackOrLater } from '@kritik/utils/stage';
import getPaginatedResults from 'actions/pagination/actions';
import { getCreationsToEvaluate } from 'actions/creation';
import { getStudent } from 'selectors/student';
import { getCourse } from 'selectors/course';
import { getAssignment } from 'selectors/activity';
import { localize } from 'locales/index';
import { isCalibrationActivity } from '@kritik/utils/activity';
import { EvaluationScoreUtil } from '@kritik/utils/grade';
import { selectAuthUser } from 'selectors/user';
import { useRestoreEvaluation } from 'hooks/activity';
import { TranslatedText } from 'components/TranslatedText';
import { AuthUser } from 'app-types';

type RestoreEvaluationProps = {
  evaluation: any;
  activity: any;
  getPaginatedResults: () => void;
  getCreationsToEvaluate: (params: any) => void;
  student: any;
  ActionComponent: any;
  actionTitle: string;
  isInappropriate: boolean;
  course: any;
  creation: any;
  onClose?: () => void;
  disabled?: boolean;
  authUser: AuthUser;
  users: any[];
};

const RestoreEvaluation = ({
  evaluation,
  activity,
  getPaginatedResults,
  getCreationsToEvaluate,
  student,
  ActionComponent,
  actionTitle,
  isInappropriate,
  course,
  creation,
  onClose,
  disabled,
  authUser,
  users,
}: RestoreEvaluationProps) => {
  const [isRestoringEvaluation, setIsRestoringEvaluation] = useState(false);

  const restoreEvaluationMutation = useRestoreEvaluation({
    onSuccess: () => {
      setIsRestoringEvaluation(false);
      getPaginatedResults();
      getCreationsToEvaluate({
        activityId: activity._id,
        courseId: course._id,
        studentId: student._id,
      });
      if (onClose) {
        onClose();
      }
    },
  });

  const handleRestoreEvaluation = () => {
    const feedbacker = Object.values(users).find(
      (user) => evaluation.feedbackOnFeedback.user === user._id
    );
    const evaluator = Object.values(users).find((user) => evaluation.user === user._id);

    restoreEvaluationMutation.mutate({
      evaluation,
      activity,
      isInappropriate,
      creation,
      authUser,
      feedbackerEmail: feedbacker?.email ?? '',
      evaluatorEmail: evaluator?.email ?? '',
    });
  };

  if (!isFeedbackOrLater({ assignment: activity }) || isCalibrationActivity(activity)) {
    return null;
  }

  return (
    <>
      <ActionComponent
        type="secondary"
        onClick={(e: any) => {
          e.stopPropagation();
          if (EvaluationScoreUtil.isEvaluationRemoved(evaluation)) {
            setIsRestoringEvaluation(true);
          } else {
            handleRestoreEvaluation();
          }
        }}
        testid="restore-evaluation"
        disabled={disabled}
      >
        {actionTitle}
      </ActionComponent>
      <Modal isOpen={isRestoringEvaluation} maxWidth="sm" onClick={(e: any) => e.stopPropagation()}>
        <ModalTitle
          title={
            isInappropriate
              ? localize({ message: 'Creation.Evaluation.Action.MarkAppropriateAndRestore' })
              : localize({ message: 'Creation.Evaluation.Action.Restore' })
          }
        />
        <ModalContent>
          <TranslatedText i18nKey="Creation.Evaluation.RestoreModal.Info" />
        </ModalContent>
        <ModalAction
          actions={[
            {
              label: localize({ message: 'Creation.Evaluation.RestoreModal.ConfirmRestore' }),
              type: 'primary',
              onClick: () => {
                handleRestoreEvaluation();
              },
              isLoading: restoreEvaluationMutation.isLoading,
              isDisabled: restoreEvaluationMutation.isLoading,
              testid: 'confirm-restore-evaluation',
            },
            {
              label: localize({ message: 'Creation.Evaluation.RestoreModal.Cancel' }),
              type: 'secondary',
              onClick: () => {
                setIsRestoringEvaluation(false);
                if (onClose) {
                  onClose();
                }
              },
              testid: 'cancel-restore-evaluation',
            },
          ]}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    course: getCourse(state),
    activity: getAssignment(state),
    student: getStudent(state.selected.studentId, state.entities),
    authUser: selectAuthUser(state),
    users: state.entities.users,
  };
};

export default connect(mapStateToProps, { getPaginatedResults, getCreationsToEvaluate })(
  RestoreEvaluation
);
