import React from 'react';
import AvatarDisplay from 'components/layout/AvatarDisplay';
import { useScreenSize } from 'hooks/useMatchMedia';

const StudentContent = ({
  user,
  score,
  children,
  label,
  group,
  anonymous,
  showName,
  testid,
}: any) => {
  const { isTablet } = useScreenSize();
  const renderLabel = label ? <div className="student-content__label">{label}</div> : null;

  return (
    <div className="student-content" data-testid={testid}>
      {isTablet && (
        <div className="student-content__student-info" data-testid="student-info">
          <AvatarDisplay
            user={user}
            group={group}
            kritikScore={score}
            showName={showName}
            anonymous={anonymous}
          />
          {renderLabel}
        </div>
      )}
      <div
        className="student-content__evaluation-info kritik-form-container"
        data-testid="student-content-evaluation-info"
      >
        {children}
      </div>
    </div>
  );
};

StudentContent.defaultProps = {
  showName: true,
};

export default StudentContent;
