import { InlineInformation } from 'components/layout';
import NoticeBoard from 'components/layout/NoticeBoard';
import { localize } from 'locales';

function PresentationInfo() {
  const title = localize({ message: 'Activity.Presentation.HowPresentationActivitiesWork' });
  const p1 = localize({ message: 'Activity.Presentation.HowPresentationActivitiesWork.p1' });
  const p2 = localize({ message: 'Activity.Presentation.HowPresentationActivitiesWork.p2' });
  const p3 = localize({ message: 'Activity.Presentation.HowPresentationActivitiesWork.p3' });
  return (
    <div className="presentation-info">
      <NoticeBoard title={title}>
        <ul>
          <li>{p1}</li>
          <li>{p2}</li>
          <li>{p3}</li>
        </ul>
      </NoticeBoard>
    </div>
  );
}

export default PresentationInfo;
