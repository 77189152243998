import StudentMenu from 'components/Creation/Flag/StudentMenu';
import { useUserRoleInCourse } from 'hooks/course';

export const FlagMenu = (props: any) => {
  const { isInstructorInCourse } = useUserRoleInCourse();
  if (isInstructorInCourse) {
    return null;
  }
  return (
    <div className="eval-flag-menu">
      <StudentMenu evaluation={props.evaluation} creation={props.creation} />
    </div>
  );
};

export default FlagMenu;
