import NoticeBoard from 'components/layout/NoticeBoard';
import InlineInformation from 'components/layout/InlineInformation';
import EmailList from 'components/RosterManager/SyncRosterPopup/EmailList';

export const UpdatedCourseRoster = ({ rosterChanges }) => {
  const {
    studentsAdded,
    studentsRemoved,
    studentsUpdated,
    collaboratorsAdded,
    collaboratorsRemoved,
    collaboratorsUpdated,
  } = rosterChanges;
  const hasAddedStudents = Boolean(studentsAdded.length);
  const hasRemovedStudents = Boolean(studentsRemoved.length);
  const hasUpdatedStudents = Boolean(studentsUpdated.length);
  const hasAddedCollaborators = Boolean(collaboratorsAdded.length);
  const hasRemovedCollaborators = Boolean(collaboratorsRemoved.length);
  const hasUpdatedCollaborators = Boolean(collaboratorsUpdated.length);

  if (
    !hasAddedStudents &&
    !hasRemovedStudents &&
    !hasAddedCollaborators &&
    !hasRemovedCollaborators
  ) {
    const title =
      hasUpdatedStudents || hasUpdatedCollaborators
        ? 'Syncing the roster will update some student and/or collaborator records, but no students and/or collaborator will be added or removed.'
        : 'No students will be added or removed from the Roster.';
    return <InlineInformation type="information" title={title} />;
  }
  return (
    <NoticeBoard type="caution" title="PLEASE NOTE syncing the roster will:">
      <ul className="sync-rostal-modal__student-count">
        {hasAddedStudents && (
          <li className="sync-rostal-modal__student-count-item">
            Add {studentsAdded.length} Student(s)
          </li>
        )}
        {hasRemovedStudents && (
          <li className="sync-rostal-modal__student-count-item">
            Remove {studentsRemoved.length} Student(s)
          </li>
        )}
      </ul>
      <EmailList added={studentsAdded} removed={studentsRemoved} />
      <ul className="sync-rostal-modal__student-count">
        {hasAddedCollaborators && (
          <li className="sync-rostal-modal__student-count-item">
            Add {collaboratorsAdded.length} Collaborator(s)
          </li>
        )}
        {hasRemovedCollaborators && (
          <li className="sync-rostal-modal__student-count-item">
            Remove {collaboratorsRemoved.length} Collaborators(s)
          </li>
        )}
      </ul>
      <EmailList added={collaboratorsAdded} removed={collaboratorsRemoved} />
    </NoticeBoard>
  );
};

export default UpdatedCourseRoster;
