const getState = (state: any) => {
  return state.activityImport;
};

export const selectActivityImportCourses = (state: any) => {
  return getState(state).courses;
};

export const selectActivityOptions = (state: any) => {
  return getState(state).activities;
};

export const selectActivityIdsToImport = (state: any) => {
  return getState(state).selectedActivities;
};

export const selectSuccessfulImports = (state: any) => {
  return getState(state).successfullyImportedActivities;
};

export const selectIsImporting = (state: any) => {
  return getState(state).isImporting;
};

export default {
  selectActivityImportCourses,
  selectActivityOptions,
  selectActivityIdsToImport,
  selectSuccessfulImports,
  selectIsImporting,
};
