import { createRestApiClient } from 'utils/createRestApiClient';

const apiEndpoint = import.meta.env.VITE_API_URL;

export default function EdlinkService(options = {}) {
  const client =
    (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint });
  return {
    getUserAccount: (userEmail: any) => {
      return client.request({
        method: 'GET',
        url: `/edlink/account/${userEmail}`,
      });
    },
    getEdlinkActivities: (courseId: any) => {
      return client.request({ method: 'GET', url: `/edlink/courses/${courseId}/activities` });
    },
    syncGrades: ({ edlinkCourseId, data }: any) => {
      return client.request({
        method: 'POST',
        url: `/edlink/courses/${edlinkCourseId}/activities`,
        data,
      });
    },
    getEdlinkCourses: ({
      userId,
      edlinkIntegrationId,
      courseId,
    }: {
      userId: string;
      edlinkIntegrationId: string;
      courseId: string;
    }) => {
      return client.request({
        method: 'GET',
        url: `/edlink/getEdlinkCourses/${userId}?edlinkIntegrationId=${edlinkIntegrationId}&courseId=${courseId}`,
      });
    },
    getEdlinkIntegrationsByInstitution: ({
      institutionId,
      courseId,
    }: {
      institutionId: string;
      courseId: string;
    }) => {
      return client.request({
        method: 'GET',
        url: `/edlink/integrations/${institutionId}?courseId=${courseId}`,
      });
    },
    getEdlinkIntegration: (integrationId: any) => {
      return client.request({ method: 'GET', url: `/edlink/integration/${integrationId}` });
    },
  };
}

export async function getEdlinkUserAccount(userEmail: string) {
  try {
    let integrationResponse = null;
    const response = await EdlinkService().getUserAccount(userEmail);
    if (response.status === 200) {
      if (response.data.length === 1) {
        try {
          integrationResponse = await EdlinkService().getEdlinkIntegration(
            response.data[0].edlinkIntegrationId
          );
        } catch (err) {
          throw new Error(err);
        }
      }
      return {
        integrations: response.data,
        ltiAdvantageIntegration: integrationResponse?.data?.ltiAdvantageIntegration,
      };
    }
    throw new Error('Could not get edlink user account!');
  } catch (err) {}
}
