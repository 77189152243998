import { userService } from 'services';
import { updateAuthUser } from 'actions/users';

export const TUTORIAL_KEYS = {
  ACTIVITY: 'activityTutorial',
  SCORE: 'scoreTutorial',
};

export function toggleTutorials({ id, tutorialLabels }: any) {
  return (dispatch: any, getState: any) => {
    return userService()
      .toggleTutorials({ id, tutorialLabels })
      .then((res: any) => {
        dispatch(updateAuthUser(res.data));
      });
  };
}

export function resetAllTutorials(userId: any) {
  const tutorialLabels = Object.values(TUTORIAL_KEYS).reduce((acc, key) => {
    acc[key] = true;
    return acc;
  }, {});
  return toggleTutorials({ id: userId, tutorialLabels });
}

export default {
  TUTORIAL_KEYS,
  toggleTutorials,
  resetAllTutorials,
};
