import React, { Component } from 'react';
import Page from 'pages/Page';
import CourseData from 'containers/Admin/CourseData';

class CourseDataPage extends Component {
  getMetaData() {
    return {
      title: this.pageTitle(),
      meta: this.pageMeta(),
      link: this.pageLink(),
      script: this.pageScript(),
    };
  }

  pageTitle = () => {
    return 'Course Data';
  };

  pageMeta = () => {
    return [];
  };

  pageLink = () => {
    return [];
  };

  pageScript = () => {
    return [];
  };

  render() {
    return (
      <Page {...this.getMetaData()}>
        <CourseData {...this.props} />
      </Page>
    );
  }
}

export default CourseDataPage;
