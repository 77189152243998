import merge from 'lodash/merge';
import * as types from 'types';

const initialState = {
  assignedGroups: {},
  assignments: {},
  courses: {},
  groups: {},
  rubrics: {},
  statuses: {},
  students: {},
  user_assignments: {},
  users: {},
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case types.MERGE_ENTITIES:
      return mergeEntities(state, action.entities);
    case types.SYNC_ENTITIES:
      return syncEntities(state, action.entity, action.key);
    case types.UPDATE_ENTITIES:
      return updateEntities(state, action.entities);
    case types.REPLACE_ENTITIES:
      return replaceEntities(state, action.entities);
    case types.REMOVE_ENTITIES:
      return removeEntities(state, action.entities);
    case types.CLEAR_ENTITIES:
    case types.LOGOUT_USER:
    case types.LOGIN_SUCCESS_USER:
      return clearEntities();
  }
  return state;
}

function mergeEntities(state: any, entities: any) {
  return merge({}, state, entities);
}

function syncEntities(state: any, entity: any, key: any) {
  return {
    ...state,
    [key]: {
      ...state[key],
      [entity._id]: entity,
    },
  };
}

function updateEntities(state: any, entities: any) {
  const oldState = { ...state };
  for (const key in entities) {
    Object.values(entities[key]).forEach((obj) => {
      oldState[key][(obj as any)._id] = entities[key][(obj as any)._id];
    });
  }

  return oldState;
}

function replaceEntities(state: any, newEntities: any) {
  const oldState = { ...state };

  for (const key in newEntities) {
    oldState[key] = newEntities[key];
  }

  return oldState;
}

function removeEntities(state: any, entities: any) {
  const oldState = { ...state };
  for (const key in entities) {
    Object.values(entities[key]).forEach((obj) => {
      delete oldState[key][(obj as any)._id];
    });
  }

  return oldState;
}

function clearEntities() {
  return initialState;
}
