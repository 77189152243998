import classNames from 'classnames';
import images from 'images/registration';

const SignupContainer = ({ className, status, graphic, children }: any) => {
  const classes = classNames('signup-container', {}, className);

  const getMediaFooter = () => {
    if (status === 'success') {
      return images.BUBBLE_BG_SUCCESS;
    }
    return images.BUBBLE_BG;
  };

  const getGraphicURL = () => {
    switch (graphic) {
      case 'rocket':
        return images.ROCKETSHIP;
      case 'checkout':
        return images.CHECKOUT;
      case 'checkout-success':
        return images.CHECKOUT_SUCCESS;
      case 'invite':
        return images.INVITE;
      case 'planet':
        return images.PLANET;
      case 'classroom':
        return images.CLASSROOM;
      default:
    }
  };

  const renderGraphicsPanel = () => {
    const mediaClasses = classNames('signup-media', {
      success: status == 'success',
      error: status == 'error',
    });

    return (
      <div className={mediaClasses}>
        <div className="signup-media__graphic">
          <img className="" src={getGraphicURL()} aria-hidden="true" />
        </div>
        <img className="signup-media__footer" src={getMediaFooter()} aria-hidden="true" />
      </div>
    );
  };

  return (
    <div className={classes}>
      <div className="signup-container__lpanel">{renderGraphicsPanel()}</div>
      <div className="signup-container__rpanel">
        <div className="signup-content">{children}</div>
      </div>
    </div>
  );
};

export default SignupContainer;
