import classNames from 'classnames';

type Props = {
  stepIndex: number;
  children?: React.ReactNode;
  title: string;
  disabled?: boolean;
};

export const CollapsibleStep = ({ stepIndex, children, title, disabled }: Props) => {
  const collapsibleHeaderClasses = classNames('collapsible-step-header', {
    'collapsible-step-header-disabled': disabled,
  });

  return (
    <div className="kritik-form-container collapsible-step">
      <div className={collapsibleHeaderClasses}>
        <div className="step-index">{stepIndex}</div>
        <div className="step-title">{title}</div>
      </div>
      <div className="step-children-container">{!disabled && children}</div>
    </div>
  );
};
