import { connect } from 'react-redux';
import * as ActivityStatusUtil from '@kritik/utils/stage';
import * as creationUtil from '@kritik/utils/creation/general';
import {
  isWithinGroupActivity,
  isGroupAssignment,
  isGroupPresentationActivity,
  isPresentationActivity,
  canSubmitLate,
  isIndividualPresentationActivity,
} from '@kritik/utils/activity';
import LabeledProgressBar from 'components/layout/ProgressBarWithLabel';
import { getStudentFromUser } from 'selectors/student';
import { selectActivityStats } from 'selectors/activity';
import { FeedbackScoreUtil } from '@kritik/utils/grade';
import {
  getNumEvaluationsCompleted,
  getNumEvaluationsToDo,
} from '@kritik/utils/creation/evaluation';
import * as CreationStatusUtil from '@kritik/utils/creation/status';
import {
  LateSubmissionAvailability,
  NoGroupInlineNotice,
  EnrolledAfterCreateStageInGroupActivity,
} from 'components/Assignment/NoticeBoards';
import { getGroupOfStudent } from 'selectors/group';
import {
  getNumberOfGroupsParticipatingInCreateStage,
  getNumberOfStudentsParticipatingInStage,
} from '@kritik/utils/stage';
import { getCreationsFromEntities } from 'selectors/creation';
import { useUserRoleInCourse } from 'hooks/course';
import { getCourse } from 'selectors/course';

const DraftState = () => {
  return (
    <div className="status-progress__label status-progress__label--attention">Schedule Now</div>
  );
};

const NoEvalsAssignedState = () => {
  return (
    <div className="status-progress__label status-progress__label--info">
      No Evaluations assigned
    </div>
  );
};

const NoFeedbackAssignedState = () => {
  return (
    <div className="status-progress__label status-progress__label--info">No Feedback assigned</div>
  );
};

export const CreateState = ({ activityStats, activity, isInstructor, creation }: any) => {
  if (isInstructor && !activityStats) {
    return null;
  }

  const creationStage = ActivityStatusUtil.getCreationStage(activity);

  let progress = 0;
  let progressLabel = '';
  if (isInstructor) {
    if (isGroupAssignment(activity)) {
      const { groups, creationsSubmitted } = activityStats;
      progress = creationUtil.calcProgress(creationsSubmitted, groups.length);
      progressLabel = `${creationsSubmitted} / ${groups.length}`;
    } else if (isGroupPresentationActivity(activity)) {
      const { creationsSubmitted } = activityStats;
      const groupCount = getNumberOfGroupsParticipatingInCreateStage(activity);

      progress = creationUtil.calcProgress(creationsSubmitted, groupCount);
      progressLabel = `${creationsSubmitted} / ${groupCount}`;
    } else if (isIndividualPresentationActivity(activity)) {
      const { creationsSubmitted } = activityStats;
      const numberOfParticipants = getNumberOfStudentsParticipatingInStage(creationStage);

      progress = creationUtil.calcProgress(creationsSubmitted, numberOfParticipants);
      progressLabel = `${creationsSubmitted} / ${numberOfParticipants}`;
    } else if (isWithinGroupActivity(activity)) {
      const { groups, creationsSubmitted } = activityStats;
      const membersInGroups = groups.reduce((acc: any, group: any) => {
        acc += group.members.length;
        return acc;
      }, 0);
      progress = creationUtil.calcProgress(creationsSubmitted, membersInGroups);
      progressLabel = `${creationsSubmitted} / ${membersInGroups}`;
    } else {
      const { creationsExpected, creationsSubmitted } = activityStats;
      progress = creationUtil.calcProgress(creationsSubmitted, creationsExpected);
      progressLabel = `${creationsSubmitted} / ${creationsExpected}`;
    }
  } else {
    if (
      isPresentationActivity(activity) &&
      !(creationStage as PresentationAssignmentStatus).isParticipating
    ) {
      return null;
    }
    if ((isGroupAssignment(activity) || isGroupPresentationActivity(activity)) && !creation) {
      return null;
    }
    if (!CreationStatusUtil.isCompleted(creation)) {
      progress = 0;
      progressLabel = '0 / 1';
    } else {
      progress = 100;
      progressLabel = '1 / 1';
    }
  }
  let label = 'Creations Completed';
  if (!isInstructor) {
    label = progress === 100 ? 'Creation Submitted' : 'Submit Your Creation';
  }

  if (isPresentationActivity(activity)) {
    if (activity.fileExtensionsAllowed === null) {
      return null;
    } else {
      if (activity.requireFile) {
        return (
          <LabeledProgressBar label={label} progress={progress} progressLabel={progressLabel} />
        );
      } else {
        return null;
      }
    }
  }

  return <LabeledProgressBar label={label} progress={progress} progressLabel={progressLabel} />;
};

export const EvaluateState = ({
  activityStats,
  activity,
  creation,
  isInstructor,
  student,
}: any) => {
  if (isInstructor && !activityStats) {
    return null;
  }

  let progress = 0;
  let progressLabel = '';
  let numEvalsCompleted = 0;
  let numEvalsAssigned = 0;

  if (isInstructor) {
    numEvalsAssigned = activityStats.numEvalsExpected;
    numEvalsCompleted = activityStats.numEvalsCompleted;
  } else {
    const evaluationStage = ActivityStatusUtil.getEvaluationStage(activity);
    if (
      isPresentationActivity(activity) &&
      !(evaluationStage as PresentationAssignmentStatus).isParticipating
    ) {
      return null;
    }
    if (!creation || !student) {
      return null;
    }
    numEvalsCompleted = getNumEvaluationsCompleted(creation, student._id);
    numEvalsAssigned = getNumEvaluationsToDo(creation, student._id);
  }

  if (numEvalsAssigned === 0) {
    return <NoEvalsAssignedState />;
  }
  progress = creationUtil.calcProgress(numEvalsCompleted, numEvalsAssigned);
  progressLabel = `${numEvalsCompleted} / ${numEvalsAssigned}`;

  return (
    <LabeledProgressBar
      label="Evaluations Completed"
      progress={progress}
      progressLabel={progressLabel}
    />
  );
};

export const FeedbackState = ({
  activityStats,
  activity,
  creation,
  isInstructor,
  student,
  creations,
}: any) => {
  if (isInstructor && !activityStats) {
    return null;
  }
  let progress = 0;
  let progressLabel = '';
  if (isInstructor) {
    const { numFOFCompleted, numFOFExpected } = activityStats;
    if (numFOFExpected === 0) {
      return <NoFeedbackAssignedState />;
    }
    progress = creationUtil.calcProgress(numFOFCompleted, numFOFExpected);
    progressLabel = `${numFOFCompleted} / ${numFOFExpected}`;
  } else {
    const feedbackStage = ActivityStatusUtil.getFeedbackStage(activity);

    if (isPresentationActivity(activity)) {
      if (!(feedbackStage as PresentationAssignmentStatus).isParticipating) {
        return null;
      }

      const { completedFOF, toDo } = FeedbackScoreUtil.numFOFCompletedForPresentationActivity({
        creations,
        studentId: student._id,
      });

      if (toDo === 0) {
        return <NoFeedbackAssignedState />;
      }
      progress = creationUtil.calcProgress(completedFOF, toDo);
      progressLabel = `${completedFOF} / ${toDo}`;
    } else {
      if (!creation || CreationStatusUtil.wasCreationMissed(creation)) {
        return null;
      }

      const { completedFOF, toDo } = FeedbackScoreUtil.numFOFCompleted(
        creation,
        activity,
        student._id
      );
      if (toDo === 0) {
        return <NoFeedbackAssignedState />;
      }
      progress = creationUtil.calcProgress(completedFOF, toDo);
      progressLabel = `${completedFOF} / ${toDo}`;
    }
  }
  return (
    <LabeledProgressBar
      label="Feedback Completed"
      progress={progress}
      progressLabel={progressLabel}
    />
  );
};

const GradingState = ({ isInstructor }: any) => {
  const title = isInstructor ? 'Mark as complete' : 'Review your grades';
  return (
    <div
      data-testid={isInstructor ? 'activity-card_mark-as-complete' : 'activity-card_review-grades'}
      className="status-progress__label status-progress__label--attention"
    >
      {title}
    </div>
  );
};

const CardProgress = (props: any) => {
  if (!props.activity) {
    return null;
  }
  const { isInstructorInCourse } = useUserRoleInCourse();
  const creation = !isInstructorInCourse ? props.activity.userAssignment : null;

  let _activityStats = null;
  if (props.activity) {
    _activityStats = props.activityStats[props.activity._id];
  }
  if (!ActivityStatusUtil.isScheduled(props.activity)) {
    return <DraftState />;
  }
  if (
    !isInstructorInCourse &&
    (isGroupAssignment(props.activity) ||
      isWithinGroupActivity(props.activity) ||
      isGroupPresentationActivity(props.activity)) &&
    !props.studentGroup
  ) {
    if (ActivityStatusUtil.isEvaluateOrLater(props.activity)) {
      return <EnrolledAfterCreateStageInGroupActivity />;
    }
    return <NoGroupInlineNotice />;
  }
  if (
    ActivityStatusUtil.isCreate({ assignment: props.activity }) ||
    ActivityStatusUtil.isInCreationGracePeriod(props.activity) ||
    ActivityStatusUtil.isProcessing1({ assignment: props.activity })
  ) {
    return (
      <CreateState
        activityStats={_activityStats}
        activity={props.activity}
        isInstructor={isInstructorInCourse}
        creation={creation}
      />
    );
  }
  if (
    props.student &&
    !CreationStatusUtil.isCompleted(creation) &&
    canSubmitLate(creation, props.activity)
  ) {
    return <LateSubmissionAvailability activity={props.activity} />;
  }

  if (
    ActivityStatusUtil.isEvaluate({ assignment: props.activity }) ||
    ActivityStatusUtil.isInEvaluationGracePeriod(props.activity)
  ) {
    return (
      <EvaluateState
        activityStats={_activityStats}
        activity={props.activity}
        isInstructor={isInstructorInCourse}
        creation={creation}
        student={props.student}
      />
    );
  }
  if (ActivityStatusUtil.isFeedback({ assignment: props.activity })) {
    return (
      <FeedbackState
        activityStats={_activityStats}
        activity={props.activity}
        isInstructor={isInstructorInCourse}
        creation={creation}
        student={props.student}
        creations={props.creations}
      />
    );
  }
  if (ActivityStatusUtil.isGrading({ assignment: props.activity })) {
    return <GradingState isInstructor={isInstructorInCourse} />;
  }
  return null;
};

const mapStateToProps = (state: any, ownProps: any) => {
  const { user } = state;
  const student = getStudentFromUser(state, state.selected.courseId);
  const course = getCourse(state, state.selected.courseId);
  const isInstructorInCourse = course.userRole === 'instructor';
  const studentGroup = isInstructorInCourse
    ? null
    : getGroupOfStudent(state, ownProps.activity._id, student._id);
  return {
    activityStats: selectActivityStats(state),
    user,
    student,
    studentGroup,
    creations: getCreationsFromEntities(state, ownProps.activity._id),
  };
};

CardProgress.defaultProps = {
  student: null,
};

export default connect(mapStateToProps)(CardProgress);
