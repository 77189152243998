import { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { Field, reduxForm } from 'redux-form';
import { uploadUserImage } from 'actions/users';
import FormTextInput from 'components/core/form/TextField';
import InlineInformation from 'components/layout/InlineInformation';
import Button from 'components/buttons/Button';
import { localize } from 'locales';
import {
  PROFILE_IMAGE_MAX_SIZE_IN_MB,
  PROFILE_IMAGE_MAX_SIZE_IN_BYTES,
} from '@kritik/constants/activity';
import { Card, CardActions, CardContent } from '@material-ui/core';

const mapStateToProps = (state: any) => {
  return {
    user: state.user.authUser,
  };
};

type UserProfileModalState = any;

class UserProfileModal extends Component<{}, UserProfileModalState> {
  state = {
    uploadedImg: null,
    validationError: null,
  };

  handleImageUpload = (profile: any, files: any) => {
    const file = files[0];
    if (!file) {
      return;
    }
    if (file.size > PROFILE_IMAGE_MAX_SIZE_IN_BYTES) {
      this.setState({
        validationError: `Image is too large, must be less than ${PROFILE_IMAGE_MAX_SIZE_IN_MB} MB`,
      });
      return;
    }
    const formData = new FormData();
    formData.set('file', file, file.name);

    axios
      .post(`/api/v1/upload-profile-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        this.setState({ uploadedImg: response.data[0].Location });
        (this.props as any).uploadUserImage(profile, response.data[0].Location);
      })
      .catch((err) => {
        throw err;
      });
  };

  parseName(name: string) {
    return name.replace(/^\s+/g, '');
  }

  createComponentForField({ input, meta, ...rest }) {
    return <FormTextInput error={meta.error && meta.touched && meta.error} {...input} {...rest} />;
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'uploading' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { handleSubmit, profile, onSubmit, submitting } = this.props;
    return (
      <form
        id="edit-user-modal-form"
        onSubmit={handleSubmit(onSubmit)}
        data-testid="upload-profile-image"
      >
        <Card>
          <CardContent>
            {this.state.validationError && (
              <InlineInformation type="danger">{this.state.validationError}</InlineInformation>
            )}
            <Dropzone
              style={{ border: 'none' }}
              name="image"
              onDrop={(files: any) => {
                return this.handleImageUpload(profile, files);
              }}
              accept="image/jpeg,image/png"
            >
              <div className="user-about__dropzone">
                <img
                  className="user-about__dropzone-image"
                  alt={profile.name}
                  style={{
                    maxWidth: 150,
                    display: 'inline',
                    marginBottom: 10,
                  }}
                  src={this.state.uploadedImg || profile.picture}
                />
                <Button type="primary">{localize({ message: 'UploadImage' })}</Button>
              </div>
            </Dropzone>

            <Field
              id="firstName"
              name="firstName"
              placeholder={localize({ message: 'FirstName' })}
              component={this.createComponentForField}
              label={localize({ message: 'FirstName' })}
              parse={this.parseName}
              testid="about-firstName-input"
              props={{ autoFocus: true, isRequired: true }}
            />
            <Field
              id="lastName"
              name="lastName"
              placeholder={localize({ message: 'LastName' })}
              label={localize({ message: 'LastName' })}
              testid="about-lastName-input"
              component={this.createComponentForField}
              parse={this.parseName}
              props={{ isRequired: true }}
            />
            {!(this.props as any).user?.permissions.includes('canCreateCourse') && (
              <InlineInformation type="information">
                Only the instructor will be able to see your name. You will be anonymous to all
                evaluators
              </InlineInformation>
            )}
          </CardContent>
          <CardActions>
            <Button
              type="primary"
              testid="update-account-settings"
              inputType="submit"
              disabled={submitting}
              loading={submitting}
              data-testid="edit-about-information"
            >
              {localize({ message: 'Update' })}
            </Button>
          </CardActions>
        </Card>
      </form>
    );
  }
}

export default reduxForm({
  form: 'user',
})(
  connect(mapStateToProps, {
    uploadUserImage,
  })(UserProfileModal)
);
