import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'router';
import { withRouter } from 'utils/withRouter';
import get from 'lodash/get';
import { userService } from 'services';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CheckoutForm from 'components/payment/StripeCheckout';
import { displayCurrencyPrice } from '@kritik/utils/format';
import { getDiscountAmount } from '@kritik/utils/general';
import { getCourse } from 'selectors/course';
import userSelector from 'selectors/user';
import Button from 'components/buttons/Button';
import { Form, FormFieldNotification } from 'components/Form';
import { chargeStudentAndEnrollInCourse } from 'actions/payments';
import { STRIPE_CHARGE_USER } from 'types';
import FormFieldLabel from 'components/core/form/FieldLabel';
import FormField from 'components/core/form/Field';
import FormFieldInput from 'components/core/form/FieldInput';
import TextInput from 'components/core/input/Text';
import { trackEvent } from 'utils/userEvents';
import { localize } from 'locales/index';

const mapStateToProps = (state: any) => {
  return {
    studentId: state.user.student.studentId,
    course: getCourse(state, state.selected.courseId),
    enrollBusy: state.async[STRIPE_CHARGE_USER].busy,
    enrollSuccess: state.async[STRIPE_CHARGE_USER].success,
    enrollError: state.async[STRIPE_CHARGE_USER].error,
    authUser: userSelector.selectAuthUser(state),
  };
};

type CheckoutState = any;

class Checkout extends Component<{}, CheckoutState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      couponId: '',
      appliedCouponId: '',
      course: null,
      coupon: {
        percentOff: 0,
        amountOff: 0,
      },
      total: (this.props as any).course.price,
      stripe: null,
      validateCouponError: null,
    };
  }

  componentDidMount() {
    // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
    const stripeScriptId = App.config.get('stripeScriptId');
    const existingScript = document.getElementById(stripeScriptId);

    if (!existingScript) {
      const script = document.createElement('script');
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
      script.src = App.config.get('stripeScriptSource');
      script.id = stripeScriptId;
      // @ts-expect-error TS(2322) FIXME: Type 'number' is not assignable to type 'boolean'.
      script.async = 1;
      document.body.appendChild(script);

      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.createStripeInstance();
      });
    } else {
      this.createStripeInstance();
    }
  }

  createStripeInstance() {
    // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
    const stripeKey = App.config.get('stripePublishableKey');
    this.setState({ stripe: (window as any).Stripe(stripeKey) });
  }

  componentDidUpdate(prevProps: {}, prevState: CheckoutState) {
    if (
      (this.props as any).enrollSuccess &&
      (this.props as any).enrollSuccess != (prevProps as any).enrollSuccess
    ) {
      this.onSuccessfulEnrollment();
    }
  }

  onSuccessfulEnrollment = () => {
    setTimeout(() => {
      (this.props as any).onSuccess();
    }, 500);
  };

  handleInputChange = (e: any) => {
    const { target } = e;
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCouponChange = (e: any) => {
    if (this.state.validateCouponError) {
      this.setState({ validateCouponError: null });
    }
    this.handleInputChange(e);
  };

  validateCoupon = () => {
    const couponId = this.state.couponId.trim();
    this.setState({ validateCouponError: null });
    userService()
      .validateCoupon({
        couponId,
        courseId: (this.props as any).course._id,
      })
      .then((res: any) => {
        const coupon = res.data;
        this.applyCoupon(coupon, couponId);
      })
      .catch((err: any) => {
        this.setState({
          validateCouponError: get(err.response.data, 'errors.message', 'Something went wrong'),
        });
        this.applyCoupon({}, '');
      });
  };

  applyCoupon = (coupon: any, couponId: any) => {
    this.setState({
      appliedCouponId: couponId,
      coupon,
    });
  };

  generateChargeAndEnroll = (token?: any) => {
    // @ts-expect-error TS(2339) FIXME: Property 'course' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { course, studentId, chargeStudentAndEnrollInCourse, authUser } = this.props;
    const description = 'Subscription charge.';
    const data = {
      courseId: course._id,
      description,
      source: token ? token.id : null,
      studentId,
    };

    if (!this.state.validationError) {
      (data as any).couponId = this.state.appliedCouponId;
    }

    chargeStudentAndEnrollInCourse(data).then(() => {
      trackEvent('Student Enrolled in Course', authUser);
    });
  };

  renderCouponNotification = () => {
    let type;
    let message = null;
    if (this.state.appliedCouponId) {
      message = 'Coupon discount applied';
      type = 'success';
    } else if (this.state.validateCouponError) {
      message = this.state.validateCouponError;
      type = 'danger';
    }
    return <FormFieldNotification message={message} type={type} />;
  };

  getCoursePrice = () => {
    return (this.props as any).course.price / 100;
  };

  getDiscount = () => {
    if (this.state.discount == 0) {
      return 0;
    }
    const discount = getDiscountAmount(this.getCoursePrice(), this.state.coupon);
    // if discount is coupon.amountOff it is in cents so need to adjust
    return this.state.coupon.percentOff ? discount : discount / 100;
  };

  getTax = () => {
    const subTotal = this.getCoursePrice() - this.getDiscount();
    const salesTax = ((this.props as any).course.user.institution.salesTax / 100) * subTotal;
    return salesTax;
  };

  getTotal = () => {
    const coursePrice = this.getCoursePrice();
    const tax = this.getTax();
    const discount = this.getDiscount();
    const total = coursePrice - discount + tax;
    return total;
  };

  renderCouponField = () => {
    return (
      <FormField>
        <FormFieldLabel label="Have a coupon?" />
        <FormFieldInput className="checkout-form-coupon">
          <TextInput
            value={this.state.couponId}
            onChange={this.handleCouponChange}
            name="couponId"
            aria-label="Coupon code"
            testid="coupon-input"
          />
          <Button
            type="secondary"
            onClick={() => {
              return this.validateCoupon();
            }}
            disabled={this.state.couponId.length == 0}
            className="checkout-form-coupon__submit"
            testid="apply-button"
            label={localize({ message: 'Button.Label.ApplyCoupon' })}
          >
            Apply
          </Button>
        </FormFieldInput>
      </FormField>
    );
  };

  render() {
    const { currency } = (this.props as any).course.user.institution;
    const renderPrice = (
      <table className="checkout-form-price-table">
        <tbody>
          <tr>
            <td className="checkout-form-price-table__label"> Price: </td>
            <td className="checkout-form-price-table__value">
              {displayCurrencyPrice(this.getCoursePrice(), currency)}
            </td>
          </tr>
          <tr className={`${this.state.discount <= 0 ? 'hidden' : ''}`}>
            <td className="checkout-form-price-table__label"> Discount: </td>
            <td className="checkout-form-price-table__value">
              {`- ${displayCurrencyPrice(this.getDiscount(), currency)}`}
            </td>
          </tr>
          <tr>
            <td className="checkout-form-price-table__label"> Tax: </td>
            <td className="checkout-form-price-table__value">
              {displayCurrencyPrice(this.getTax(), currency)}
            </td>
          </tr>
          <tr className="checkout-form-price-table__total">
            <td className="checkout-form-price-table__label"> Total: </td>
            <td className="checkout-form-price-table__value">
              {displayCurrencyPrice(this.getTotal(), currency)}
            </td>
          </tr>
        </tbody>
      </table>
    );

    const renderCheckoutForm =
      this.getTotal() > 0 ? (
        <React.Fragment>
          <StripeProvider stripe={this.state.stripe}>
            <Elements>
              <CheckoutForm
                onSuccess={(token: any) => {
                  return this.generateChargeAndEnroll(token);
                }}
                onCancel={(this.props as any).onCancel}
                loading={(this.props as any).enrollBusy}
                success={(this.props as any).enrollSuccess}
                error={(this.props as any).enrollError}
              />
            </Elements>
          </StripeProvider>
        </React.Fragment>
      ) : (
        <Button
          className="enrollment-checkout-btn"
          type="primary"
          onClick={() => {
            return this.generateChargeAndEnroll();
          }}
          loading={(this.props as any).enrollBusy}
          success={(this.props as any).enrollSuccess}
          error={(this.props as any).enrollError}
          testid="enroll-in-course"
          inputType="submit"
        >
          Enroll in Course
        </Button>
      );

    return (
      <Form>
        {this.renderCouponField()}
        {this.renderCouponNotification()}
        {renderPrice}
        {renderCheckoutForm}
      </Form>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, {
    chargeStudentAndEnrollInCourse,
    push,
  })(Checkout)
);
