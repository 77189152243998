import React, { useState } from 'react';
import NoticeBoard from 'components/layout/NoticeBoard';
import {
  getDisputeReason,
  getDisputeInstructorComment,
  getResolvedDate,
  getDisputedDate,
  getResolvedBy,
} from 'utils/creation/dispute';
import { isDisputeResolved } from '@kritik/utils/creation/status';
import * as FormatUtils from '@kritik/utils/format';
import {
  DisputeSection,
  DisputeHeader,
  DisputeDescription,
} from 'components/Creation/Disputing/Layout';
import { Button } from 'components/buttons';
import HtmlContent from 'components/General/HtmlContent';
import { connect } from 'react-redux';
import { getUser } from 'selectors/user';

const ResolvedDisputeView = (props: any) => {
  if (!isDisputeResolved(props.creation)) {
    return null;
  }

  const [showDetails, setShowDetails] = useState(false);

  const renderDates = () => {
    let disputedDate = getDisputedDate(props.creation);
    disputedDate = FormatUtils.exactDate(disputedDate);
    let resolvedDate = getResolvedDate(props.creation);
    resolvedDate = FormatUtils.exactDate(resolvedDate);
    return (
      <React.Fragment>
        <DisputeDescription>Creation score dispute on {disputedDate}</DisputeDescription>
        <DisputeDescription>
          Dispute resolved by <b>{props.resolvedBy}</b> on {resolvedDate}
        </DisputeDescription>
      </React.Fragment>
    );
  };

  const renderDetails = () => {
    if (!showDetails) {
      return null;
    }
    return (
      <React.Fragment>
        <DisputeSection>
          <DisputeHeader label={"Student's Reason for Dispute"} />
          <DisputeDescription>
            <HtmlContent content={getDisputeReason(props.creation)} />
          </DisputeDescription>
        </DisputeSection>
        <DisputeSection>
          <DisputeHeader label="Resolution Message" />
          <DisputeDescription>
            <HtmlContent content={getDisputeInstructorComment(props.creation)} />
          </DisputeDescription>
        </DisputeSection>
      </React.Fragment>
    );
  };

  const toggleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  const renderButton = () => {
    let label = 'Show Details';
    if (showDetails) {
      label = 'Hide Details';
    }
    return (
      <DisputeSection>
        <Button type="secondary" onClick={toggleShowDetails} testid="toggle-dispute-details">
          {label}
        </Button>
      </DisputeSection>
    );
  };

  return (
    <NoticeBoard title="Dispute Resolved" type="success">
      {renderDates()}
      {renderDetails()}
      {renderButton()}
    </NoticeBoard>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  const resolvedByUserId = getResolvedBy(ownProps.creation);
  let resolvedBy = getUser(resolvedByUserId, state.entities);
  resolvedBy = resolvedBy?.profile?.name || 'Instructor';
  return {
    resolvedBy,
  };
};

export default connect(mapStateToProps, {})(ResolvedDisputeView);
