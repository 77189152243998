import React from 'react';
import InfoLabel from 'components/layout/InfoLabel';
import Button from 'components/buttons/Button';
import ShortenText from 'components/General/ShortenText';

const CriteriaList = (props: any) => {
  const onRemoveCriterion = (removedCriterionKey: any) => {
    props.onRemoveCriterion(removedCriterionKey);
  };

  const renderRemoveButton = (criterion: any) => {
    if (!props.isEditing) {
      return null;
    }
    return (
      <Button
        onClick={() => {
          onRemoveCriterion(criterion.key);
        }}
        type="hidden"
        className="eval-hl-criteria__btn"
      >
        <i className="fas fa-times-circle" />
      </Button>
    );
  };

  const getCriterionLabel = (criterion: any) => {
    return <ShortenText label={criterion.label} maxLength={30} />;
  };

  return (
    <div className="eval-hl-criteria">
      {props.criteriaList.map((criterion: any, index: number) => {
        return (
          <div className="eval-hl-criteria__item" key={`criteriaList-${index}`}>
            <InfoLabel
              type="attention"
              label={
                <React.Fragment>
                  {getCriterionLabel(criterion)}
                  {renderRemoveButton(criterion)}
                </React.Fragment>
              }
            />
          </div>
        );
      })}
    </div>
  );
};

CriteriaList.defaultProps = {
  isEditing: false,
  criteriaList: [],
  onRemoveCriterion: null,
};

export default CriteriaList;
