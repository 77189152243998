import { SORT_OPTIONS } from 'components/CreationList/constant';
import { sortByCreate } from './sortByCreate';
import { sortByStudentName } from './sortByName';
import { sortByEvaluate } from './sortByEvaluate';
import { sortByEvalReceived } from './sortByEvalReceived';
import { sortByFeedback } from './sortByFeedback';
import { sortByIssues } from './sortByIssues';

export const sortItemList = (
  itemList: any,
  sortedColumn: any,
  activity: any,
  tableSettings: any,
  feedbackReceivedMap: any
) => {
  const ascending = sortedColumn.sorting.isAscending;
  switch (sortedColumn.header) {
    case SORT_OPTIONS.NAME: {
      return sortByStudentName(itemList, ascending, activity);
    }
    case SORT_OPTIONS.CREATE: {
      return sortByCreate(itemList, ascending, activity);
    }
    case SORT_OPTIONS.EVALUATE: {
      return sortByEvaluate(itemList, ascending, activity, tableSettings);
    }
    case SORT_OPTIONS.EVALUATIONS_RECEIVED: {
      return sortByEvalReceived(itemList, ascending, activity);
    }
    case SORT_OPTIONS.FEEDBACK: {
      return sortByFeedback(itemList, ascending, activity, tableSettings, feedbackReceivedMap);
    }
    case SORT_OPTIONS.ISSUES: {
      return sortByIssues(itemList, ascending, activity);
    }
    default: {
      return itemList;
    }
  }
};

export { FilterItemList } from './filter';
