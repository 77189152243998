import { combineReducers } from 'redux';
import { generateAsyncReducer } from 'redux/utils';
import types from './types';

function rubricIdReducer(state = '', action: any) {
  switch (action.type) {
    case types.SET_SELECTED_RUBRIC:
      return action.payload;
    default:
      return state;
  }
}

function isEditReducer(state = false, action: any) {
  switch (action.type) {
    case types.SET_IS_TEMPLATE_EDIT:
      return action.payload;
    default:
      return state;
  }
}

const asyncActions = {
  call: [types.DELETE_RUBRIC, types.COPY_RUBRIC],
  success: [types.DELETE_RUBRIC_SUCCESS, types.COPY_RUBRIC_SUCCESS],
  error: [types.DELETE_RUBRIC_FAILURE, types.COPY_RUBRIC_FAILURE],
};

export default combineReducers({
  rubricId: rubricIdReducer,
  isEditingTemplate: isEditReducer,
  async: generateAsyncReducer(asyncActions),
});
