import React from 'react';
import FormTitle from 'components/core/form/Title';
import FormInfo from 'components/core/form/FieldInfo';
import Typography from 'components/core/Typography';

const DateWrapper = (props: any) => {
  return (
    <div className="scheduler-date">
      <FormTitle label={props.title} />
      <FormInfo label={props.description} />
      <div className="scheduler-date__input">{props.dateInput}</div>
      {props.children}
      <FormInfo label={props.warning && <Typography color="accent">{props.warning}</Typography>} />
      <FormInfo
        label={props.attention && <Typography color="accent">{props.attention}</Typography>}
      />
    </div>
  );
};

export default DateWrapper;
