export const DASHBOARD_RESULT_LIMIT = 100;

export const studentEmailFilters = {
  ENROLLED_STUDENTS: 'enrolledStudents',
  PENDING_STUDENTS: 'pendingStudents',
  MISSED_CREATION: 'missedCreation',
  MISSED_EVALUATION: 'missedEvaluation',
  MISSED_FEEDBACK: 'missedFeedback',
};

export const CURRENCY_OPTIONS = [
  { value: 'cad', label: 'CAD ($)' },
  { value: 'usd', label: 'USD ($)' },
  { value: 'eur', label: 'EUR (€)' },
  { value: 'sgd', label: 'SGD ($)' },
  { value: 'gbp', label: 'GBP (£)' },
  { value: 'aud', label: 'AUD ($)' },
];
