import { isGroupCreation } from '@kritik/utils/creation/general';
import * as TeacherCommentUtil from '@kritik/utils/creation/instructorComment';
import * as FeedbackUtils from '@kritik/utils/creation/feedback';
import * as CreationStatusUtils from '@kritik/utils/creation/status';
import { Creation, CreationScore, User, Student, Activity } from '@kritik/types.generated';
import { isInEvaluationGracePeriod } from '@kritik/utils/stage';

export const getTotalFromGroupEvalsMapping = (
  evalsMap: GroupEvalsAssigned | GroupEvalsCompleted
) => {
  const total = Object.values(evalsMap).reduce((sum, num) => {
    sum += num;
    return sum;
  }, 0);
  return total;
};

export const getNumEvaluationsCompleted = (creation: Creation, studentId?: string) => {
  if (!creation) {
    return 0;
  }

  if (isGroupCreation(creation)) {
    if (!creation.groupEvalsCompleted) {
      return 0;
    }
    if (studentId) {
      return creation.groupEvalsCompleted[studentId] ? creation.groupEvalsCompleted[studentId] : 0;
    }
    return getTotalFromGroupEvalsMapping(creation.groupEvalsCompleted);
  }
  return creation.numEvalsCompleted;
};

export const getNumEvaluationsToDo = (creation: Creation, studentId?: string) => {
  if (isGroupCreation(creation)) {
    if (!creation.groupEvalsAssigned) {
      return 0;
    }
    if (studentId) {
      return creation.groupEvalsAssigned[studentId] ? creation.groupEvalsAssigned[studentId] : 0;
    }
    return getTotalFromGroupEvalsMapping(creation.groupEvalsAssigned);
  }
  return creation.numEvalsAssigned;
};

export const getNumEvaluationsReceived = (creation: Creation) => {
  const numEvalsReceived = creation.scores.reduce((acc, score) => {
    if (score.marks.length > 0) {
      acc++;
    }
    return acc;
  }, 0);
  return numEvalsReceived;
};

export const getNumEvaluationsToBeReceived = (creation: Creation) => {
  return creation.reviewers.length;
};

export const getPercentEvaluationsReceived = (creation: Creation) => {
  const numEvalsToBeReceived = getNumEvaluationsToBeReceived(creation);
  if (numEvalsToBeReceived === 0) {
    return 0;
  }
  const numEvalsReceived = getNumEvaluationsReceived(creation);
  return numEvalsReceived / numEvalsToBeReceived;
};

export const getPercentEvaluationsCompleted = (creation: Creation, studentId: string) => {
  let evaluationsToDo = getNumEvaluationsToDo(creation, studentId);
  if (evaluationsToDo == 0) {
    evaluationsToDo = 1;
  }
  const evaluationsCompleted = getNumEvaluationsCompleted(creation, studentId);
  return evaluationsCompleted / evaluationsToDo;
};

export const getNumEvaluationRemaining = (creation: Creation, studentId: string) => {
  const evaluationsToDo = getNumEvaluationsToDo(creation, studentId);
  const evaluationsCompleted = getNumEvaluationsCompleted(creation, studentId);
  return evaluationsToDo - evaluationsCompleted;
};

export const isUserEvaluationOwner = (user: UserWithAuthUser, evaluation: CreationScore) => {
  if (!evaluation) {
    return false;
  }

  const authUserId = user?.authUser?._id;
  const evaluationCreatorId = (evaluation.user as User)?._id || evaluation.user;

  return Boolean(authUserId) && Boolean(evaluationCreatorId) && authUserId === evaluationCreatorId;
};

export const isCompleted = (creation: Creation, studentId: string) => {
  const _percentEvalsCompleted = getPercentEvaluationsCompleted(creation, studentId);
  return _percentEvalsCompleted === 1;
};

export const filterOutSelfEvaluation = (creation: Creation) => {
  const evaluations = creation.scores;
  return evaluations.filter((evaluation) => {
    const evalUserId = (evaluation.student as Student)._id || evaluation.student;
    const creationUserId = (creation.student as Student)._id || creation.student;
    return evalUserId.toString() !== creationUserId.toString();
  });
};

export const scoreHasTeacherComment = (evaluation: CreationScore) => {
  return TeacherCommentUtil.resourceHasTeacherComment(evaluation);
};

export const evaluationHasComment = (evaluation: CreationScore) => {
  if (
    evaluation &&
    ((evaluation.files && evaluation.files.length) ||
      (evaluation.comment && evaluation.comment.length > 0))
  ) {
    return true;
  }
  return false;
};

export const getEvaluationByStudentId = (evaluations: CreationScore[], studentId: string) => {
  const evaluation = evaluations.find((score) => {
    // TODO: remove check on user type when data from server is 100% normalized
    return ((score.student as Student)._id || (score.student as string)) === studentId;
  });
  return evaluation;
};

export const isEvaluationFlaggedByStudent = (evaluation: CreationScore, studentId: string) => {
  if (
    evaluation.flag === 'Flagged' &&
    (evaluation.feedbackOnFeedback.student as unknown as string) === studentId
  ) {
    return true;
  }
  return false;
};

export const isAnyEvaluationFlaggedByStudent = (
  evaluations: CreationScore[],
  studentId: string
) => {
  let isFlagged = false;
  for (const evaluation of evaluations) {
    if (isEvaluationFlaggedByStudent(evaluation, studentId)) {
      isFlagged = true;
      break;
    }
  }
  return isFlagged;
};

export const isAllEvaluationsFlagsResolved = (evaluations: CreationScore[], studentId: string) => {
  let flagged = 0;
  let resolved = 0;
  const allAssignedFeedback = FeedbackUtils.getAssignedFeedbacks(evaluations, studentId);
  for (const score of allAssignedFeedback) {
    if (CreationStatusUtils.isCommentFlagResolved(score)) {
      resolved += 1;
    }
    if (CreationStatusUtils.isCommentFlagged(score)) {
      flagged += 1;
    }
  }
  return flagged === 0 && resolved > 0;
};

export const isSelfEvaluation = ({
  evaluation,
  userId,
}: {
  evaluation: CreationScore;
  userId: string;
}) => {
  const evaluationUserId = (evaluation.user as User)._id || evaluation.user;
  return evaluationUserId.toString() === userId;
};

// Student is set to null when the late evaluation is submitted through the admin panel
// This is the only way we can check that the evaluation was late for now
export const wasLateEvaluation = ({ evaluation }: { evaluation: CreationScore }) => {
  return evaluation.feedbackOnFeedback.student === null;
};

type IsEvaluationOverdueArgs = {
  activity: Activity & {
    userAssignment: Creation;
  };
  creation?: Creation;
  student: Student;
};
export function isEvaluationOverdue({ activity, creation, student }: IsEvaluationOverdueArgs) {
  if (!creation) {
    return false;
  }
  if (isCompleted(creation, student._id)) {
    return false;
  }
  return isInEvaluationGracePeriod(activity);
}
