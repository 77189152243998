const minLength = (min: any) => {
  return (value: any) => {
    return value && value.length < min ? `Min number of characters is ${min}` : undefined;
  };
};

const maxLength = (max: any) => {
  return (value: any) => {
    return value && value.length <= max ? undefined : `Max number of characters is ${max}`;
  };
};

const isRequired = (value: any) => {
  return value || value === 0 || value === false ? undefined : 'Required';
};

const mustBeNumber = (value: any) => {
  return isNaN(value) ? 'Must be a number' : undefined;
};

const minValue = (min: any) => {
  return (value: any) => {
    return isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;
  };
};

const maxValue = (max: any) => {
  return (value: any) => {
    return isNaN(value) || value <= max ? undefined : `Should be less than ${max}`;
  };
};

const doesNotContainImageAndisRequired = (section: any) => {
  return (value: any) => {
    return value
      ? value.toLowerCase().match(/data:image\//)
        ? `${section} should not contain inline image`
        : undefined
      : 'Required';
  };
};

const composeValidators = (...validators: any[]) => {
  return (value: any) => {
    return validators.reduce((error, validator) => {
      return error || validator(value);
    }, undefined);
  };
};

export default {
  minLength,
  isRequired,
  maxLength,
  mustBeNumber,
  minValue,
  maxValue,
  doesNotContainImageAndisRequired,
  composeValidators,
};
