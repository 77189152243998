import { useMutation, useQuery } from '@tanstack/react-query';
import { adminService as AdminService } from 'services/index';

type CourseDataRecord = {
  courseId: string;
  courseTitle: string;
  activityId: string;
  activityTitle: string;
  evaluationParticipationRatio: number;
  disputeRatio: number;
  evaluationsDone: number;
  numEvaluationsToAssign: number;
  evaluationStageDurationInDays: number;
};
export function useGetCourseData({
  instructorEmail,
  onError,
}: {
  instructorEmail: string;
  onError: (e: any) => void;
}) {
  return useQuery(
    ['courseData', instructorEmail],
    async () => {
      const result = await AdminService().getCourseData({ instructorEmail });
      return result.data as CourseDataRecord[];
    },
    {
      staleTime: 0,
      enabled: Boolean(instructorEmail),
      onError,
    }
  );
}

type EdlinkConnectionRequestRecord = {
  email: string;
  edlinkPersonId: string;
  approvedAt?: Date;
  code: string;
  createdAt: Date;
};
export function useGetEdlinkConnectionRequests({
  email,
  onError,
}: {
  email: string;
  onError: (e: any) => void;
}) {
  return useQuery(
    ['edlinkConnectionRequests', email],
    async () => {
      const result = await AdminService().getEdlinkConnectionRequests({ email });
      return result.data as EdlinkConnectionRequestRecord[];
    },
    {
      staleTime: 0,
      enabled: Boolean(email),
      onError,
    }
  );
}

export function impersonateUserMutation() {
  type UseMutationArgs = {
    email: string;
    password: string;
  };
  return useMutation(async ({ email, password }: UseMutationArgs) => {
    await AdminService().impersonateUser({ email, password });
  });
}
