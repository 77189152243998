import { ACTIVITY_TYPES } from '@kritik/constants/activity';
import { getFileExtentionsAllowed } from 'components/ActivityEdit/util';
import { FIELD_NAME as WEIGHT } from 'components/ActivityEdit/AdvancedOptions/Fields/Weight/constants';
import { FIELD_NAME as NUM_EVALS_TO_ASSIGN } from 'components/ActivityEdit/AdvancedOptions/Fields/NumEvalsToAssign/constants';
import { FIELD_NAME as TITLE } from 'components/ActivityEdit/Details/Fields/Title/constants';
import { FIELD_NAME as OBJECTIVE } from 'components/ActivityEdit/Details/Fields/Objective/constants';
import { FIELD_NAME as INSTRUCTIONS } from 'components/ActivityEdit/Details/Fields/Instructions/constants';
import { FIELD_NAME as RUBRIC } from 'components/ActivityEdit/Details/Rubric/constants';
import {
  FIELD_NAME as TOPICS,
  IS_MULTIPLE_TOPICS,
  NUMBER_OF_TOPICS,
} from 'components/ActivityEdit/AdvancedOptions/Fields/Topics/constants';
import {
  HAS_EVALUATOR_NOTES,
  EVALUATOR_NOTES_FILES,
  FIELD_NAME as EVALUATOR_NOTES,
} from 'components/ActivityEdit/Details/Fields/Notes/constants';
import { isWithinGroupActivity } from '@kritik/utils/activity';
import {
  ACCEPT_LATE_CREATIONS,
  AUTO_ACCEPT_LATE_CREATIONS,
  ACCEPT_LATE_EVALUATIONS,
  AUTO_ACCEPT_LATE_EVALUATIONS,
} from 'components/LateCreation/constants';
import { isCreate, isInCreationGracePeriod } from '@kritik/utils/stage';
import { Activity } from '@kritik/types.generated';
import { FIELD_NAME as removeStudentFromActivityAfterMissedCreation } from 'components/ActivityEdit/AdvancedOptions/Fields/MissedCreation/constants';

export const getFormattedFormValues = ({ values, courseId }: { values: any; courseId: string }) => {
  const data = {
    [TITLE]: values[TITLE],
    files: values.files,
    activityType: values.activityType,
    [OBJECTIVE]: values[OBJECTIVE],
    [INSTRUCTIONS]: values[INSTRUCTIONS],
    numericGrading: values.isNumericGrading,
    [RUBRIC]: {
      ...values[RUBRIC],
      course: courseId,
    },
    [NUM_EVALS_TO_ASSIGN]: values[NUM_EVALS_TO_ASSIGN],
    isGroupActivity: values.activityType === ACTIVITY_TYPES.GROUP,
    groupSetId: values.selectedGroupSet,
    course: courseId,
    [WEIGHT]: values[WEIGHT],
    fileExtensionsAllowed: getFileExtentionsAllowed(values),
    [TOPICS]: values[TOPICS],
    [IS_MULTIPLE_TOPICS]: values[IS_MULTIPLE_TOPICS],
    [NUMBER_OF_TOPICS]: values[NUMBER_OF_TOPICS],
    [HAS_EVALUATOR_NOTES]: values[HAS_EVALUATOR_NOTES],
    [EVALUATOR_NOTES]: values[EVALUATOR_NOTES],
    [EVALUATOR_NOTES_FILES]: values[EVALUATOR_NOTES_FILES],
    [ACCEPT_LATE_CREATIONS]: values[ACCEPT_LATE_CREATIONS],
    [AUTO_ACCEPT_LATE_CREATIONS]: values[AUTO_ACCEPT_LATE_CREATIONS],
    [ACCEPT_LATE_EVALUATIONS]: values[ACCEPT_LATE_EVALUATIONS],
    [AUTO_ACCEPT_LATE_EVALUATIONS]: values[AUTO_ACCEPT_LATE_EVALUATIONS],
    [removeStudentFromActivityAfterMissedCreation]:
      values[removeStudentFromActivityAfterMissedCreation],
    startingScore: values.startingScore,
    lateCreationPenaltyPercentage: values.lateCreationPenaltyPercentage,
  };
  if (isWithinGroupActivity(values)) {
    (data as any).selfEvaluate = values.selfEvaluate;
    (data as any).removeStudentFromActivityAfterMissedCreation = false;
  }
  return data;
};

export const getActivityGroupSet = (groupSetList: any[], activity: any) => {
  const groupSet = (groupSetList || []).find((group: any) => {
    return group.assignments.includes(activity._id);
  });
  return groupSet?._id ?? null;
};

export const getActivityTemplateGroupSet = ({
  activity,
  groupSets = [],
}: {
  activity: any;
  groupSets: any[];
}) => {
  const activityGroupSet = groupSets.find((groupSet) =>
    groupSet.assignments.includes(activity._id)
  );

  if (isCreate({ assignment: activity }) || isInCreationGracePeriod(activity as Activity)) {
    return activityGroupSet;
  }

  if (activityGroupSet?.sourceTemplateId) {
    return groupSets.find(
      (groupSet) => groupSet._id === activityGroupSet.sourceTemplateId && !groupSet.isInstance
    );
  }

  return null;
};
export default {
  getFormattedFormValues,
  getActivityGroupSet,
  getActivityTemplateGroupSet,
};
