import { useState } from 'react';
import { connect } from 'react-redux';
import { getCourse } from 'selectors/course';
import * as LateSubmissionUtil from '@kritik/utils/creation/lateCreation';
import { NumericGradeView } from 'components/Creation/GradeView';
import { Button, ButtonContainer } from 'components/buttons';
import * as CreationStatusUtil from '@kritik/utils/creation/status';
import { CreationScoreUtil } from '@kritik/utils/grade';
import NewTab from 'images/icons/fa/NewTab';
import * as ActivityStatusUtil from '@kritik/utils/stage';
import * as ActivityUtil from '@kritik/utils/activity';
import RejectedCreation from './RejectedCreation';
import CreationScore from './CreationScore';
import CreationDispute from './CreationDispute';
import { TranslatedText } from 'components/TranslatedText';
import classNames from 'classnames';

const SCORE = 'score';
const COMMENT = 'comment';

const CreationScoring = ({ activity, creation, course }: any) => {
  const [viewClassAverage, setViewClassAverage] = useState(false);
  const [viewAIScore, setViewAIScore] = useState(false);
  const [currentEditor, setCurrentEditor] = useState(null);
  const [isResolvingDispute, setIsResolvingDispute] = useState(false);

  const handleCommentOnCreation = () => {
    setCurrentEditor(COMMENT);
  };
  const handleEditScore = () => {
    setCurrentEditor(SCORE);
  };
  const resetEditor = () => {
    setCurrentEditor(null);
  };
  const handleIsResolvingDispute = () => {
    setIsResolvingDispute((prevIsResolvingDispute) => !prevIsResolvingDispute);
  };
  const handleViewClassAverage = () => {
    setViewClassAverage((prevViewClassAverage) => !prevViewClassAverage);
  };

  const isCommentingOnCreation = currentEditor === COMMENT;
  const isEditingScore = currentEditor === SCORE;

  if (LateSubmissionUtil.isLateSubmissionPending(creation)) {
    return null;
  }

  const openRubricPage = () => {
    window.open(
      `/course/${activity.course}/assignment/${activity._id}/rubric/${activity.rubric._id}`
    );
  };

  const showViewAverageButton =
    CreationScoreUtil.hasCreationScore(creation) &&
    ActivityStatusUtil.isGradingOrLater(activity) &&
    !ActivityUtil.isWithinGroupActivity(activity);

  const showViewAIScoreButton = creation.aiScores?.length > 0;

  const containerClass = classNames('submission-overall-evaluation__container', {
    'submission-overall-evaluation__container-background': isEditingScore,
  });

  const displayActions = !isEditingScore && !isCommentingOnCreation;

  return (
    <div className={containerClass}>
      <div className="submission-overall-evaluation">
        <div className="submission-overall-evaluation__header" tabIndex={0}>
          <h3 className="submission-overall-evaluation__header-title">
            {isEditingScore ? (
              <TranslatedText i18nKey="Activity.EditCreationScoreTable.EditCreationScore" />
            ) : (
              <TranslatedText i18nKey="Activity.CreationScoreTable.CreationScore" />
            )}
          </h3>
          <NumericGradeView
            creation={creation}
            rubric={activity.rubric}
            numericGrading={ActivityUtil.isNumericGrading(activity)}
            activity={activity}
            viewAIScore={viewAIScore}
          />
          {CreationScoreUtil.getAppliedLateCreationPenaltyPercentage({
            creation,
            lateCreationPenaltyPercentage: activity.lateCreationPenaltyPercentage,
          }) > 0 && (
            <div className="submission-overall-evaluation__late-penalty">
              <TranslatedText
                i18nKey="Activity.Creation.OverallCreationScore.LatePenalty"
                values={{ value: activity.lateCreationPenaltyPercentage }}
              />
            </div>
          )}
        </div>
      </div>
      {isResolvingDispute && (
        <CreationDispute creation={creation} handleIsResolvingDispute={handleIsResolvingDispute} />
      )}
      <RejectedCreation creation={creation} />
      <CreationScore
        creation={creation}
        activity={activity}
        isCommentingOnCreation={isCommentingOnCreation}
        resetEditor={resetEditor}
        isEditingScore={isEditingScore}
        course={course}
        viewClassAverage={viewClassAverage}
        viewAIScore={viewAIScore}
      />
      {displayActions && (
        <ButtonContainer>
          {CreationStatusUtil.isDispute(creation) && (
            <Button
              onClick={() => {
                setIsResolvingDispute((prevIsResolvingDispute) => !prevIsResolvingDispute);
              }}
              type={isResolvingDispute ? '' : 'warning'}
              disabled={isResolvingDispute}
              testid="manage-dispute"
            >
              Manage Dispute
            </Button>
          )}
          {creation && !CreationStatusUtil.creationHasProfComment(creation) && (
            <Button
              type={isCommentingOnCreation ? '' : 'secondary'}
              disabled={isCommentingOnCreation}
              onClick={handleCommentOnCreation}
              testid="comment-creation"
            >
              Comment on Creation
            </Button>
          )}
          {activity && !LateSubmissionUtil.isLateSubmissionRejected(creation) && (
            <Button
              type={isEditingScore ? '' : 'secondary'}
              disabled={isEditingScore}
              onClick={handleEditScore}
              data-testid="edit-score"
            >
              Edit Score
            </Button>
          )}
          {(showViewAverageButton || showViewAIScoreButton) && (
            <div className="submission-overall-evaluation__comparison-buttons">
              {showViewAverageButton && (
                <Button
                  onClick={handleViewClassAverage}
                  type="secondary"
                  isActive={viewClassAverage}
                >
                  <TranslatedText i18nKey="Activity.CreationScoreTable.ClassAverage" />
                </Button>
              )}
              {showViewAIScoreButton && (
                <Button
                  onClick={() => setViewAIScore((prev) => !prev)}
                  type="secondary"
                  isActive={viewAIScore}
                >
                  <TranslatedText i18nKey="Activity.CreationScoreTable.AIScore" />
                </Button>
              )}
            </div>
          )}
          <Button onClick={openRubricPage} type="secondary" testid="full-rubric">
            <NewTab />
            &nbsp; Full Rubric
          </Button>
        </ButtonContainer>
      )}
    </div>
  );
};

export default connect((state: any) => {
  return {
    course: getCourse(state),
  };
}, {})(CreationScoring);
