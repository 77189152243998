import React from 'react';
import classNames from 'classnames';

const TableRow = (props: any) => {
  const cellClasses = classNames(
    'creation-table__row',
    {
      'creation-table__row--warning': props.isWarning,
      'creation-table__row--empty': !props.children,
      'creation-table__row--no-hover': props.disableHover || !props.children,
      'creation-table__row--active': props.isActive,
    },
    props.className
  );
  const renderChildren = () => {
    if (!props.children) {
      return 'There are no creations that match the selected filter';
    }
    return props.children;
  };
  return (
    <tr
      className={cellClasses}
      style={props.style}
      onClick={props.onClick}
      data-testid={props.testid}
    >
      {renderChildren()}
    </tr>
  );
};

export default TableRow;
