import { createRestApiClient } from 'utils/createRestApiClient';
const apiEndpoint = import.meta.env.VITE_API_URL;

export default (options = {}) => {
  const client =
    (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint });
  return {
    markInstructorCommentSeen: ({ creation, viewed }: any) => {
      return client.request({
        method: 'PATCH',
        url: `/submissions/${creation._id}/comments`,
        data: { viewed },
      });
    },
    updateInstructorCreationComment: ({ creationId, text, files, commenterId }: any) => {
      return client.request({
        method: 'PATCH',
        url: `/submissions/${creationId}/comments`,
        data: { text, files, commenterId },
      });
    },
  };
};
