import LmsIntegrationsContainer from 'containers/Admin/LmsIntegrations';
import Page from 'pages/Page';

function LmsIntegrations() {
  return (
    <Page title="LMS integrations" meta={[]} link={[]} script={[]}>
      <LmsIntegrationsContainer />
    </Page>
  );
}

export default LmsIntegrations;
