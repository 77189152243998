import React, { useState } from 'react';
import { FormMainError } from 'components/Form/FormComponents';
import FormFieldLabel from 'components/core/form/FieldLabel';
import FormTextInput from 'components/core/form/TextField';
import { Button } from 'components/buttons';
import { sendCouponEmails } from 'actions/admin';
import { connect } from 'react-redux';
import CloseButton from '../CloseButton';

const CouponEmailUsersModal = (props: any) => {
  const [subject, setSubject] = useState('Kritik Coupon Code');
  const [course, setCourse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { selectedCoupons } = props;

  const changeSubject = (e: any) => {
    setError('');
    setSubject(e.target.value);
  };

  const changeCourse = (e: any) => {
    setError('');
    setCourse(e.target.value);
  };

  const checkAllFields = () => {
    return subject !== '' && course !== '';
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await props.sendCouponEmails({ coupons: selectedCoupons, subject, course_name: course });
      setError('');
    } catch (error) {
      console.log(error);
      setError('Unable to send email');
    }
    setLoading(false);
  };

  return (
    <div className="admin-panel-modal">
      <section className="admin-panel-modal__main admin-panel-modal__scrollable">
        <h1>Send Coupons to User's Email</h1>
        <FormTextInput name="subject" label="Subject" value={subject} onChange={changeSubject} />
        <FormFieldLabel label="To" />
        <textarea
          value={selectedCoupons
            .map((coupon: any) => {
              return coupon.createdFor;
            })
            .join(', ')}
          className="main-modal__textarea"
          name="emails"
          disabled
        />
        <FormTextInput name="course" label="Course Name" value={course} onChange={changeCourse} />
        <Button
          unavailable={!checkAllFields()}
          disabled={!checkAllFields()}
          loading={loading}
          className="admin-panel-submit-button"
          type="primary"
          onClick={handleSubmit}
        >
          Send Email
        </Button>
        <FormMainError message={error} isOpen={error} />
      </section>
      <CloseButton onClose={props.handleClose} />
    </div>
  );
};

export default connect(null, { sendCouponEmails })(CouponEmailUsersModal);
