import React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash-es';

import { updateInstructorCreationComment } from 'actions/comment';
import { selectAuthUser } from 'selectors/user';
import CommentOnContentHandler from '../index';

const CreationComment = (props: any) => {
  function onSubmit(data: any) {
    let _data = _.pick(data, ['comment', 'files', 'userId']);
    const evaluationData = {
      commenterId: _data.userId,
      text: _data.comment,
      creationId: props.creation._id,
    };
    _data = { ..._data, ...evaluationData };
    return props.updateInstructorCreationComment(_data);
  }

  return (
    <CommentOnContentHandler
      creationUserId={props.creationUserId}
      activityId={props.activityId}
      isInstructor={props.isInstructor}
      creation={props.creation}
      onSubmit={onSubmit}
      resource={props.creation}
      contentOwner={props.creation.user}
      onClose={props.onClose}
      isCreationComment
      isViewOnly={props.isViewOnly}
    />
  );
};

function mapStateToProps(state: any) {
  return {
    entities: state.entities,
    user: selectAuthUser(state),
  };
}

CreationComment.defaultProps = {
  isInstructor: false,
  isViewOnly: false,
  onClose: null,
};

export default connect(mapStateToProps, { updateInstructorCreationComment })(CreationComment);
