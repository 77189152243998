import MemberMenu from 'components/GroupManager/GroupMemberMenu';
import AvatarDisplay from 'components/layout/AvatarDisplay';
import { getNewKS } from 'utils/student';
import ShortenText from 'components/General/ShortenText';
import StatusLabel from 'components/Creation/StatusLabel';

const GroupMember = ({ student, isLeader, group, groups, disabled, testid, readOnly }: any) => {
  const renderName = () => {
    return <ShortenText label={student.user.profile.name} maxLength={30} />;
  };
  const renderEmail = () => {
    return <ShortenText label={student.user.email} maxLength={23} />;
  };
  const memberTestId = isLeader ? 'leader-member' : 'regular-member';

  const hideMenu = disabled || (readOnly && isLeader);

  return (
    <div
      className={`group-member${student.removedOn ? ' removed' : ''}`}
      data-testid={memberTestId}
    >
      <AvatarDisplay user={student.user} kritikScore={getNewKS(student)} size="50px" groupCard />
      <div className="group-member--info">
        <div className="group-member--info-header">
          {renderName()}
          {isLeader ? <StatusLabel className="leader-pill" status="rankup" label={`Leader`} /> : ''}
        </div>
        <div
          className="group-member--info-sub-header"
          data-testid={`${memberTestId}-email-${student.user.email}`}
        >
          {renderEmail()}
        </div>
      </div>
      {!hideMenu && (
        <MemberMenu
          canMoveToDifferentGroup={!readOnly}
          group={group}
          groups={groups}
          member={student}
          testid={testid}
        />
      )}
    </div>
  );
};

export default GroupMember;
