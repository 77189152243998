import { isCompleted } from '@kritik/utils/creation/status';
import { ACTIVITY_TYPES, fileRestrictionStatus } from '@kritik/constants/activity';
import { CreationScoreUtil } from '@kritik/utils/grade';
import * as LateSubmissionUtils from '@kritik/utils/creation/lateCreation';
import * as CreationStatusUtil from '@kritik/utils/creation/status';
import * as ActivityStatusUtil from '@kritik/utils/stage';
import { Activity, Course, Topic, Creation } from '@kritik/types.generated';

export function getRestrictedFileStatus(assignmentFileExtensionsAllowed: string[]) {
  if (!assignmentFileExtensionsAllowed) {
    return fileRestrictionStatus.ALL;
  }
  if (assignmentFileExtensionsAllowed && assignmentFileExtensionsAllowed.length > 0) {
    return fileRestrictionStatus.SOMEFILES;
  }
  return fileRestrictionStatus.UNRESTRICTED;
}

export function isCalibrationActivity(activity: Activity) {
  return activity.activityType === ACTIVITY_TYPES.CALIBRATION;
}

export function isGroupAssignment(activity: Activity) {
  return activity.activityType === ACTIVITY_TYPES.GROUP;
}

export function isPeerActivity(activity: Activity) {
  return activity.activityType === ACTIVITY_TYPES.PEER;
}

export function isWithinGroupActivity(activity: Activity) {
  return activity.activityType === ACTIVITY_TYPES.WITHIN_GROUP;
}

export function isGroupPresentationActivity(activity: Activity) {
  return activity?.activityType === ACTIVITY_TYPES.GROUP_PRESENTATION;
}

export function isIndividualPresentationActivity(activity: Activity) {
  return activity?.activityType === ACTIVITY_TYPES.INDIVIDUAL_PRESENTATION;
}

export function isPresentationActivity(activity: Activity) {
  return (
    activity?.activityType === ACTIVITY_TYPES.GROUP_PRESENTATION ||
    activity?.activityType === ACTIVITY_TYPES.INDIVIDUAL_PRESENTATION
  );
}

export function isSelfEvaluate(activity: Activity) {
  return isWithinGroupActivity(activity) && activity.selfEvaluate;
}

export const getNumFlags = (activity: Activity) => {
  return activity.numFlags;
};

export const getNumFlagsResolved = (activity: Activity) => {
  return activity.numFlagsResolved;
};

export const getNumDisputes = (activity: Activity) => {
  return activity.numDisputes;
};

export const getNumNotGraded = (creationList: Creation[]) => {
  return creationList.reduce((sum, creation) => {
    if (
      !LateSubmissionUtils.isLateSubmissionRejected(creation) &&
      isCompleted(creation) &&
      !CreationScoreUtil.hasCreationScore(creation)
    ) {
      return sum + 1;
    }
    return sum;
  }, 0);
};

export const getActivityWeight = (activity: Activity) => {
  return activity?.weight;
};

export const getTotalWeightOfActivityList = (activityList: Activity[]) => {
  return activityList.reduce((acc, activity) => {
    return acc + activity.weight;
  }, 0);
};
export const isMultipleTopics = (activity: Activity) => {
  return !!activity.isMultipleTopics;
};

export const getActivityTopics = (activity: Activity) => {
  return activity.topics;
};

type UserWithStudentId = {
  student: {
    studentId: string;
  };
};
export const getStudentTopic = (activity: Activity, user: UserWithStudentId) => {
  if (!activity || !isMultipleTopics(activity)) {
    return null;
  }

  const topics = getActivityTopics(activity);
  const studentTopic = topics?.find((topic) => {
    return ((topic as Topic).students as unknown as string[])?.includes(user.student.studentId);
  });
  if (studentTopic === undefined) {
    return null;
  }
  return studentTopic;
};

export const getStudentTopicWithSubmission = (activity: Activity, creation: Creation) => {
  if (!activity || !isMultipleTopics(activity)) {
    return null;
  }
  const topics = getActivityTopics(activity);
  const studentTopic = topics.find((topic) => {
    return (topic as Topic)._id === (creation.topic as Topic)._id;
  });
  if (studentTopic === undefined) {
    return null;
  }
  return studentTopic;
};

export const getEvaluatorNotes = (activity: Activity) => {
  if (!hasEvaluatorNotes(activity)) {
    return null;
  }

  return activity.evaluatorNotes;
};

export const getEvaluatorNotesFiles = (activity: Activity): FileItem[] => {
  if (!hasEvaluatorNotes(activity)) {
    return [];
  }

  return activity.evaluatorNotesFiles;
};

export const hasEvaluatorNotes = (activity: Activity) => {
  if (!activity.hasOwnProperty('hasEvaluatorNotes') || !activity.hasEvaluatorNotes) {
    return false;
  }
  return true;
};

export const acceptsLateSubmissions = (activity: Activity) => {
  return Boolean(activity.acceptLateSubmissions);
};

export const autoAcceptsLateSubmissions = (activity: Activity) => {
  return Boolean(activity.autoAccept);
};

export const acceptsLateEvaluations = (activity: Activity) => {
  return Boolean(activity.acceptLateEvaluations);
};

export const autoAcceptsLateEvaluations = (activity: Activity) => {
  return Boolean(activity.autoAcceptLateEvaluations);
};

export const getNumEvaluationsToAssign = (activity: Activity) => {
  return activity.numEvaluationsToAssign;
};

export function Activity(activity: Activity) {
  if (!activity) {
    return null;
  }
  return {
    ...activity,
    getTitle: () => {
      return activity.title;
    },
    getId: () => {
      return activity._id;
    },
    getCourseId: () => {
      return (activity.course as Course)?._id || activity.course || activity.courseId;
    },
  };
}

export function isNumericGrading(activity: Activity) {
  return !!activity.numericGrading;
}

export function hasGroupCreators(activity: Activity) {
  return (
    isGroupPresentationActivity(activity) &&
    activity.participationSettings?.creatorGroups?.length > 0
  );
}

export function hasIndividualCreators(activity: Activity) {
  return (
    isIndividualPresentationActivity(activity) &&
    activity.participationSettings?.creatorStudents?.length > 0
  );
}

/**
 * returns true if creation has not been submitted and activity
 * settings allows late submission
 */
export function canSubmitLate(creation: Creation, activity: Activity) {
  if (!creation || !activity) {
    return;
  }

  if (!ActivityStatusUtil.isEvaluateOrLater(activity)) {
    return false;
  }

  return (
    (!creation && (isGroupAssignment(activity) || isWithinGroupActivity(activity))) ||
    CreationStatusUtil.isCreationEmpty(creation) ||
    (LateSubmissionUtils.isLateSubmissionPending(creation) &&
      !LateSubmissionUtils.isLateSubmissionContentLocked(creation))
  );
}

export function canRemoveStudentFromActivity(activity: Activity) {
  return (
    (ActivityStatusUtil.isEvaluate({ assignment: activity }) ||
      ActivityStatusUtil.isFeedback({ assignment: activity })) &&
    isPresentationActivity(activity)
  );
}
