import React from 'react';
import { Field } from 'react-final-form';
import FormRichTextEditor from 'components/core/form/RichTextEditor';
import { COURSE_FIELD_NAMES } from 'components/Course/Edit/constants';

const CourseDescriptionField = () => {
  return (
    <Field required name={COURSE_FIELD_NAMES.DESCRIPTION}>
      {({ input }) => {
        return (
          <React.Fragment>
            <FormRichTextEditor
              key="course_description_instructions"
              label="Description"
              {...input}
            />
          </React.Fragment>
        );
      }}
    </Field>
  );
};
export default CourseDescriptionField;
