import CreationStatusIcon from 'images/icons/svg/ActivityStatusCreation';
import EvaluationStatusIcon from 'images/icons/svg/ActivityStatusEvaluation';
import FeedbackStatusIcon from 'images/icons/svg/ActivityStatusFeedback';
import { localize } from 'locales';

const CREATION = {
  icon: <CreationStatusIcon />,
  title: 'Create',
  description: localize({ message: 'Course.GradingScheme.Create.Description' }),
};

const CREATION_SCORE = {
  title: 'Creation Score',
  description: localize({ message: 'Course.GradingScheme.Create.Content' }),
  popover: {
    title: 'Creation Score',
    description: `Creation score is determined by our algorithm but
      instructors will still be able to overwrite this manually.`,
    url: `https://help.kritik.io/en/articles/6386864-course-grading-scheme#h_d6e4c1d6e8`,
  },
};

const EVALUATION = {
  icon: <EvaluationStatusIcon />,
  title: 'Evaluate',
  description: `Students will be assigned as evaluators to score
    and provide written comments on their peers' creations.`,
};

const EVALUATION_SCORE = {
  title: 'Evaluation Score',
  description: `This is the student's score for the quality of their
    peer evaluation. It consists of the following two factors:
    grading score and written evaluation score.`,
};

const GRADING_SCORE = {
  title: 'Grading Score',
  description: `This is determined by how accurately a student has
    evaluated their peer according to the activity rubric.`,
  popover: {
    title: 'Grading Score',
    description: `This is calculated by comparing the score the student gave
      a peer's creation to the final grade of that creation.`,
    url: `https://help.kritik.io/en/articles/6386864-course-grading-scheme#h_3be992a859`,
  },
};

const WRITTEN_EVAL_SCORE = {
  title: 'Written Evaluation Score',
  description: `This is determined by how motivational and critical a student's
    written evaluation has been perceived by their peers.`,
  popover: {
    title: 'Written Evaluation Score',
    description: `This score will allow students to improve their
      evaluation skills for future activities.`,
    url: `https://help.kritik.io/en/articles/6386864-course-grading-scheme#h_26ba588f70`,
  },
};

const FEEDBACK = {
  icon: <FeedbackStatusIcon />,
  title: 'Feedback',
  description: `Students will rate and provide feedback on the written
    evaluations they received from their peers.`,
};

const FEEDBACK_SCORE = {
  title: 'Feedback Score',
  description: `This is the participation score students receive
    for completing their feedback.`,
  popover: {
    title: 'Feedback Score',
    description: `The higher the students' participation is in the
      Feedback stage, the more accurate the Written Evaluation Scores will be.`,
    url: `https://help.kritik.io/en/articles/6386864-course-grading-scheme#h_3d85637619`,
  },
};

export default {
  CREATION,
  CREATION_SCORE,
  EVALUATION,
  EVALUATION_SCORE,
  GRADING_SCORE,
  WRITTEN_EVAL_SCORE,
  FEEDBACK,
  FEEDBACK_SCORE,
};
