const GET_ACTIVITY_SCORES = 'GET_ACTIVITY_SCORES';
const GET_ACTIVITY_SCORES_SUCCESS = 'GET_ACTIVITY_SCORES_SUCCESS';
const GET_ACTIVITY_SCORES_FAILURE = 'GET_ACTIVITY_SCORES_FAILURE';
const GET_AVG_GROUP_SCORES = 'GET_AVG_GROUP_SCORES';
const GET_AVG_GROUP_SCORES_SUCCESS = 'GET_AVG_GROUP_SCORES_SUCCESS';
const GET_AVG_GROUP_SCORES_FAILURE = 'GET_AVG_GROUP_SCORES_FAILURE';

export default {
  GET_ACTIVITY_SCORES,
  GET_ACTIVITY_SCORES_SUCCESS,
  GET_ACTIVITY_SCORES_FAILURE,
  GET_AVG_GROUP_SCORES,
  GET_AVG_GROUP_SCORES_SUCCESS,
  GET_AVG_GROUP_SCORES_FAILURE,
};
