import * as types from 'types';

/*
 * Message store for global messages, i.e. Network messages / Redirect messages
 * that need to be communicated on the page itself. Ideally
 * messages/notifications should appear within the component to give the user
 * more context. - My 2 cents.
 */
export default function message(
  state = {
    message: '',
    type: 'SUCCESS',
  },
  action = {}
) {
  switch ((action as any).type) {
    case types.LOGIN_SUCCESS_USER:
    case types.SIGNUP_USER_SUCCESS:
      return { ...state, message: (action as any).payload.message, type: 'SUCCESS' };
    case types.DISMISS_MESSAGE:
    case '@@router/LOCATION_CHANGE':
      return { ...state, message: '', type: 'SUCCESS' };
    default:
      if ((action as any).type.match(/_FAILURE$/)) {
        const err =
          (action as any).payload &&
          ((action as any).payload.err || (action as any).payload.error || (action as any).payload);

        if (err && err.message === 'Network Error') {
          return {
            ...state,
            message: 'Error.NetworkErrorPleaseRefresh',
            type: 'ERROR',
          };
        }
      }

      return state;
  }
}
