import React from 'react';
import createSvgIcon from '../util/generateIconSVG';

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M29.609 8.52937C29.9995 8.13885 29.9995 7.50568 29.609 7.11516L23.245 0.751198C22.8545 0.360673 22.2213 0.360673 21.8308 0.751198C21.4403 1.14172 21.4403 1.77489 21.8308 2.16541L27.4876 7.82227L21.8308 13.4791C21.4403 13.8696 21.4403 14.5028 21.8308 14.8933C22.2213 15.2839 22.8545 15.2839 23.245 14.8933L29.609 8.52937ZM0.901855 8.82227H28.9019V6.82227H0.901855V8.82227Z"
      fill="#4844A3"
    />
  </React.Fragment>,
  '0 0 30 16'
);
