import { useState } from 'react';
import { Form } from 'components/Form';
import FindAssignmentFromOptions from './FindAssignmentFromOptions';
import FindCourseFromOptions from './FindCourseFromOptions';
import FindStudentWithEmail from './FindStudentWithEmail';

export const useForm = () => {
  const [course, changeCourse] = useState(null);
  const [activityOption, changeActivityOption] = useState(null);

  const reset = () => {
    changeCourse(null);
    changeActivityOption(null);
  };

  return {
    course,
    changeCourse,
    activityOption,
    changeActivityOption,
    reset,
  };
};

const FindAssignmentContainer = (props: any) => {
  const {
    course,
    changeCourse,
    assignment,
    changeAssignment,
    changeShowContent,
    handleVerifyAssignment,
  } = props;
  const [email, setEmail] = useState('');
  const [courseOptions, setCourseOptions] = useState([]);
  const [assignmentOptions, setAssignmentOptions] = useState([]);

  const changeEmail = (e: any) => {
    changeShowContent && changeShowContent(false);
    setEmail(e.target.value.trim());
  };

  return (
    <Form>
      <FindStudentWithEmail
        email={email}
        changeEmail={changeEmail}
        changeCourse={changeCourse}
        changeAssignment={changeAssignment}
        changeCourseOptions={setCourseOptions}
      />

      <FindCourseFromOptions
        email={email}
        course={course}
        changeCourse={changeCourse}
        courseOptions={courseOptions}
        changeAssignment={changeAssignment}
        changeAssignmentOptions={setAssignmentOptions}
      />

      <FindAssignmentFromOptions
        assignment={assignment}
        changeAssignment={changeAssignment}
        assignmentOptions={assignmentOptions}
        handleVerifyAssignment={handleVerifyAssignment}
      />
    </Form>
  );
};

export default FindAssignmentContainer;
