export const FIELD_NAME = 'calibrationSource';
export const FIELD_LABEL = 'Apply calibration to';

export const TYPES = {
  PAST_ACTIVITY: 'past_activity',
  NEW_ACTIVITY: 'new_activity',
};

export const copy = {
  existing_activity:
    'Use a past activity as the basis for your calibration activity. If the activity has been completed you will be able to use real student responses for your students to calibrate.',
  new_activity: 'Create a new activity as the basis for your calibration activity.',
};

export default {
  FIELD_NAME,
  FIELD_LABEL,
  copy,
  TYPES,
};
