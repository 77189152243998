import React from 'react';
import EvaluationType from './EvaluationType';
import GroupSetSelect from './GroupSetSelect';

const GroupOptions = (props: any) => {
  const shouldShowEvalType = !props.isEdit && !props.isDuplicating;
  return (
    <React.Fragment>
      {shouldShowEvalType && (
        <div className="group-options__field">
          <EvaluationType
            formState={props.form.getState()}
            mutators={props.form.mutators}
            settings={props.values.settings}
          />
        </div>
      )}
      <div className="group-options__field">
        <GroupSetSelect settings={props.values.settings} />
      </div>
    </React.Fragment>
  );
};

export default GroupOptions;
