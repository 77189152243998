import * as _ from 'lodash-es';
import BaseService from 'services/base';

export default class SchedulingService extends BaseService {
  getTemplate = (courseId: any) => {
    return this.client.request({
      method: 'GET',
      url: `/courses/${courseId}/scheduling/template`,
    });
  };

  createTemplate = (courseId: any, data: any) => {
    return this.client.request({
      method: 'POST',
      url: `/courses/${courseId}/scheduling/template`,
      data: _.pickBy(data, _.identity),
    });
  };

  updateTemplate = (courseId: any, data: any) => {
    return this.client.request({
      method: 'PATCH',
      url: `/courses/${courseId}/scheduling/template`,
      data: _.pickBy(data, _.identity),
    });
  };
}
