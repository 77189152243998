import React, { useState, createContext } from 'react';

export const EvaluationContext = createContext(null);

const EvaluationProvider = (props: any) => {
  const [evaluation, setEvaluation] = useState(null);

  return (
    <EvaluationContext.Provider value={[evaluation, setEvaluation]}>
      {props.children}
    </EvaluationContext.Provider>
  );
};

export default EvaluationProvider;
