import React from 'react';
import Typography from 'components/core/Typography';
import Table from 'components/layout/SimpleTable';
import TableRow from 'components/layout/SimpleTable/Row';
import TableBody from 'components/layout/SimpleTable/Body';
import TableCell from 'components/layout/SimpleTable/Cell';
import ShortenText from 'components/General/ShortenText';

const RubricList = (props: any) => {
  const renderRubricList = () => {
    return props.rubricList.map((rubric: any) => {
      return (
        <TableRow
          key={rubric._id}
          isSelectable
          isSelected={rubric._id === props.selectedId}
          renderSeparator={false}
          onSelectRow={() => props.onSelect(rubric._id)}
        >
          <TableCell>
            <Typography fontSize="16px" color="dark">
              <ShortenText label={rubric.name} maxLength={25} hideToolTip />
            </Typography>
          </TableCell>
        </TableRow>
      );
    });
  };
  return (
    <Table renderSeparator={false}>
      <TableBody>{renderRubricList()}</TableBody>
    </Table>
  );
};

export default RubricList;
