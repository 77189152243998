import React, { useState } from 'react';
import { connect } from 'react-redux';
import Confirm from 'components/modals/ConfirmModal';
import ElipsesMenu from 'components/General/ElipsesMenu';
import {
  isFinalized,
  isCreateOrLater,
  isDraft,
  isGradingOrLater,
  isScheduled,
} from '@kritik/utils/stage';
import { isCalibrationActivity } from '@kritik/utils/activity';
import {
  deleteAssignment,
  deleteFinalizedActivity,
  navigateToEditActivity,
  navigateToDuplicateActivity,
  navigateToActivityPage,
} from 'actions/activity';
import { TranslatedText } from 'components/TranslatedText';
import { BetaBadge } from 'components/Badges';
import { localize } from 'locales/index';

const SpotlightCardMenu = (props: any) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteActivity = () => {
    const deleteParams = { id: props.activity._id, courseId: props.courseId };
    if (isFinalized({ assignment: props.activity })) {
      props.deleteFinalizedActivity(deleteParams);
    } else {
      props.deleteAssignment(deleteParams);
    }
  };

  const renderDeleteConfim = () => {
    return (
      <Confirm
        isOpen={isDeleting}
        onCancel={() => {
          setIsDeleting(false);
        }}
        onConfirm={() => {
          setIsDeleting(false);
          handleDeleteActivity();
        }}
        cancelButton="No"
        confirmButton="Yes"
        title="Delete this activity?"
        description={`You will not be able to undo this action.
            ${
              isFinalized(props.activity)
                ? ' Any Grading/Feedback score changes will be reverted.'
                : ''
            }
          `}
      />
    );
  };

  const handleMenuDelete = () => {
    setIsDeleting(props.activity._id);
  };

  const getOptions = () => {
    const canRevertActivity = isCreateOrLater(props.activity);

    const canAdvanceStage =
      isScheduled(props.activity) &&
      !isDraft({ assignment: props.activity }) &&
      !isGradingOrLater(props.activity);

    return [
      {
        label: 'Edit',
        action: () =>
          props.navigateToEditActivity({
            courseId: props.courseId,
            activityId: props.activity._id,
          }),
        testid: 'edit-option',
      },
      canRevertActivity && {
        label: (
          <div>
            <TranslatedText i18nKey="Activity.Details.Action.Revert" />
            <span style={{ marginLeft: '0.5em' }}>
              <BetaBadge />
            </span>
          </div>
        ),
        action: () => {
          props.navigateToActivityPage({
            courseId: props.courseId,
            assignmentId: props.activity._id,
            showRevertActivityModal: true,
          });
        },
        testid: 'revert-option',
      },
      canAdvanceStage && {
        label: <TranslatedText i18nKey="Activity.AdvanceStage.Title" />,
        action: () => {
          props.navigateToActivityPage({
            courseId: props.courseId,
            assignmentId: props.activity._id,
            showAdvanceActivityStageModal: true,
          });
        },
        testid: 'advance-stage-option',
      },
      {
        label: 'separator',
      },
      !isCalibrationActivity(props.activity) && {
        label: 'Duplicate',
        action: () =>
          props.navigateToDuplicateActivity({
            courseId: props.courseId,
            activityId: props.activity._id,
          }),
        testid: 'duplicate-option',
      },
      {
        label: 'Delete',
        action: handleMenuDelete,
        testid: 'delete-option',
      },
    ].filter(Boolean);
  };

  return (
    <React.Fragment>
      {renderDeleteConfim()}
      <ElipsesMenu
        options={getOptions()}
        testid="activity-card-elipses"
        labelI18nKey="ActivityCard.Menu.Label"
        labelI18nOptions={{ title: props.activity.title }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    courseId: state.selected.courseId,
  };
};

export default connect(mapStateToProps, {
  deleteAssignment,
  deleteFinalizedActivity,
  navigateToEditActivity,
  navigateToDuplicateActivity,
  navigateToActivityPage,
})(SpotlightCardMenu);
