import * as CreationStatusUtil from '@kritik/utils/creation/status';
import { CreationView } from 'components/Creation/Create/View';
import NoticeBoard from 'components/layout/NoticeBoard';
import { isPresentationActivity } from '@kritik/utils/activity';

const CreationContent = ({ creation, activity, children }: any) => {
  if (
    CreationStatusUtil.wasCreationMissed(creation) &&
    !CreationStatusUtil.creationHasProfComment(creation)
  ) {
    return (
      <NoticeBoard type="danger" title="Missed Creation">
        The student did not submit their creation before the due date.{' '}
        {!isPresentationActivity(activity) &&
          'No evaluators will be assigned and the student cannot participate in the feedback stage.'}
      </NoticeBoard>
    );
  }
  return <CreationView submission={creation}>{children}</CreationView>;
};

export default CreationContent;
