import React, { useState, createContext } from 'react';

export const AssignmentContext = createContext(null);

const AssignmentProvider = (props: any) => {
  const [assignment, setAssignment] = useState(null);

  return (
    <AssignmentContext.Provider value={[assignment, setAssignment]}>
      {props.children}
    </AssignmentContext.Provider>
  );
};

export default AssignmentProvider;
