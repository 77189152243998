import { connect } from 'react-redux';
import { MenuField, MenuLabel, MenuInput } from 'components/CreationList/Menu/Input';
import Button from 'components/buttons/Button';
import getPaginatedResults from 'actions/pagination/actions';
import { TABLE_TYPE_OPTIONS } from 'components/CreationList/constant';
import { setCreationTableSearch } from 'redux/creationTable/actions';
import { selectCreationTableViewType } from 'redux/creationTable/select';
import { navigateToActivityPage } from 'actions/activity';
import Search from 'components/core/input/Search';

function CreationListSearch(props: any) {
  const { label } = props;

  const renderSearchIcon = () => {
    if (props.viewType === TABLE_TYPE_OPTIONS.SCORING) {
      return null;
    }
    return (
      <Button
        type="hidden"
        className="kritik-search__clear-btn"
        onClick={props.getPaginatedResults}
      >
        <i className="fas fa-search" />
      </Button>
    );
  };

  const handlePressEnterKey = () => {
    props.navigateToActivityPage({ courseId: props.courseId, assignmentId: props.activityId });
    props.getPaginatedResults();
  };

  return (
    <MenuField>
      {label ? <MenuLabel id="kritik-search-input" label={label} /> : ''}
      <MenuInput>
        <Search
          onSearch={props.setCreationTableSearch}
          label={label}
          icon={renderSearchIcon()}
          onEnterKey={handlePressEnterKey}
          onClearSearch={handlePressEnterKey}
          id="kritik-search-input"
        />
      </MenuInput>
    </MenuField>
  );
}

const mapStateToProps = (state: any) => {
  return {
    viewType: selectCreationTableViewType(state),
    courseId: state.selected.courseId,
    activityId: state.selected.assignmentId,
  };
};

export default connect(mapStateToProps, {
  setCreationTableSearch,
  getPaginatedResults,
  navigateToActivityPage,
})(CreationListSearch);
