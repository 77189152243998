import React from 'react';

export const ActiveStatus = ({ content, selected }: { content: string; selected?: boolean }) => {
  return (
    <div
      className={`${selected ? 'selected ' : ''}ProgressLabel active`}
      data-testid="progress-label"
    >
      {content}
    </div>
  );
};
