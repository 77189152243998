import { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RouterProp, withRouter } from 'utils/withRouter';
import { change as changeForm } from 'redux-form';
import About from 'components/User/About';
import AccountSetting from 'components/Setting/AccountSetting';
import { isAdmin } from 'utils/permission';
import PageContainer from 'components/layout/PageContainer';
import PageHeader from 'components/layout/PageHeader';
import { updateUser } from 'actions/users';
import { localize } from 'locales';
import { InlineInformation } from 'components/layout';
import { Grid } from '@material-ui/core';
import { enqueueSnackbar } from 'notistack';
import { TranslatedText } from 'components/TranslatedText';

type SettingProps = {
  router: RouterProp;
};

type SettingState = any;

class Setting extends Component<SettingProps, SettingState> {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
    };
    this.handleAboutSubmit = this.handleAboutSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps: SettingProps) {
    if (prevProps.router.params.tab !== this.props.router.params.tab) {
      this.setState({ error: false });
    }
  }

  showSuccessMessage() {
    enqueueSnackbar(
      localize({
        message: 'Settings.ProfileUpdated.Message',
      }),
      {
        title: localize({ message: 'Settings.ProfileUpdated.Title' }),
        variant: 'success',
      }
    );
  }

  handleAboutSubmit(data: any) {
    (this.props as any)
      .updateUser(data)
      .then(this.showSuccessMessage)
      .catch((err: any) => {
        this.setState({ error: Object.values(err.errors)[0] });
      });
  }

  onChange() {
    this.setState({ error: false });
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'routes' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { user } = this.props;
    const { tab } = this.props.router.params;

    if (!user.authUser.profile) {
      return null;
    }

    const profile = user && user.authUser.profile;

    profile._id = user.authUser.id;

    if (user.authUser && isAdmin(user.authUser) && user.authUser.role) {
      profile.role = user.authUser.role;
    }

    const initialValues = profile;
    initialValues.studentId = user.authUser.studentId;

    return (
      <PageContainer>
        <PageHeader title="Account Settings" />
        <Grid container spacing={3}>
          <Grid item sm={12} md={3}>
            <nav className="account-settings__menu">
              <NavLink to="/settings/about" data-testid="about-setting-button">
                <TranslatedText i18nKey="Settings.About" />
              </NavLink>
              <NavLink to="/settings/account" data-testid="account-setting-button">
                <TranslatedText i18nKey="Settings.Account" />
              </NavLink>
            </nav>
          </Grid>
          <Grid item sm={12} md={9}>
            {this.state.error && (
              <InlineInformation type="danger">{this.state.error}</InlineInformation>
            )}
            {tab === 'account' && (
              <AccountSetting authUser={user.authUser} onSuccess={this.showSuccessMessage} />
            )}
            {tab === 'about' && (
              <About
                uploading={user.isUploadingImage}
                initialValues={initialValues}
                profile={profile}
                user={user}
                onSubmit={this.handleAboutSubmit}
                onChange={this.onChange}
              />
            )}
          </Grid>
        </Grid>
      </PageContainer>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    entities: state.entities,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { changeForm, updateUser })(Setting));
