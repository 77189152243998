import { FeedbackScoreUtil } from '@kritik/utils/grade';
import { Creation, CreationScore, Activity, User } from '@kritik/types.generated';

export function hasFinishedFOF(creation: Creation, activity: Activity) {
  if (!creation || !creation.scores.length) {
    return true;
  }
  const completed = FeedbackScoreUtil.getPercentFOFCompleted(creation, activity);
  return completed === 100;
}

export function getAssignedFeedbacks(evaluations: CreationScore[], studentId: string) {
  return evaluations.filter((evaluation) => {
    if (!evaluation.feedbackOnFeedback.student) {
      return false;
    }
    const fofAssignee = evaluation.feedbackOnFeedback.student.toString();
    return fofAssignee === studentId.toString();
  });
}

export function getAssignedFeedbacksForPresentationActivity({
  creations,
  studentId,
}: {
  creations: Creation[];
  studentId: string;
}) {
  return getAssignedFeedbacks(creations.map((creation) => creation.scores).flat(), studentId);
}

export function isFeedbackAssignee(
  feedback: CreationScore['feedbackOnFeedback'],
  studentId: string
) {
  if (!studentId || !feedback.student) {
    return false;
  }
  return feedback.student.toString() === studentId.toString();
}

export function isSelfFeedbackToEvaluation(evaluation: CreationScore) {
  return (evaluation.user as User)._id === evaluation.feedbackOnFeedback.user;
}

export function getAssignedFeedbacksFromAllCreations({
  creations,
  studentId,
}: {
  creations: Creation[];
  studentId: string;
}) {
  return creations.map((creation) => getAssignedFeedbacks(creation.scores, studentId)).flat();
}
