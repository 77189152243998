import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getAssignmentsForLateSubmission } from 'actions/admin';
import { FormSelection, FormMainError } from 'components/Form/FormComponents';
import Button from 'components/buttons/Button';

const mapStateToProps = (state: any) => {
  return {
    assignments: state.admin.assignments,
    isLoading: state.admin.isLoadingCourses,
  };
};

const DEFAULT_BUTTTON_NAME = 'Verify Course';
const DEFAULT_BUTTTON_DESIGN = 'secondary';
const DEFAULT_ERROR_MESSAGE = 'Please choose a deal from the options.';

const FindDealFromOptions = (props: any) => {
  const [showMessage, setShowMessage] = useState({ isOpen: false, message: DEFAULT_ERROR_MESSAGE });

  const { deal, dealOptions, changeDeal, children } = props;

  return (
    <React.Fragment>
      <FormSelection
        label="Associated Hubspot Deal"
        value={deal}
        onChange={changeDeal}
        options={dealOptions}
      />

      {children}

      <FormMainError message={showMessage.message} isOpen={showMessage.isOpen} />
    </React.Fragment>
  );
};

export default connect(mapStateToProps, {})(FindDealFromOptions);
