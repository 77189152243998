import { getMarkingSchemeBreakDowns, isPercentageGrading } from '@kritik/utils/course';
import { GradeHistoryUtil } from '@kritik/utils/grade';
import { Course } from '@kritik/types.generated';

export class OverallScoreUtil {
  /**
   * Calculate overall score for an activity
   */
  static calculateOverallScore(grades: GradeHistory, course: Course) {
    let overallScore = 0;
    if (!grades) {
      return overallScore;
    }
    const { grading, writtenEvaluation, feedback } = grades;
    const markingSchemeBreakDowns = getMarkingSchemeBreakDowns(course);
    const creation = GradeHistoryUtil.getCreationScoreFromGradeHistory(grades);

    if (isPercentageGrading(course)) {
      overallScore =
        creation * (markingSchemeBreakDowns.creation / 100) +
        grading * (markingSchemeBreakDowns.grading / 100) +
        writtenEvaluation * (markingSchemeBreakDowns.writtenEvaluation / 100) +
        feedback * (markingSchemeBreakDowns.feedback / 100);
    } else {
      overallScore =
        (creation * markingSchemeBreakDowns.creation +
          grading * markingSchemeBreakDowns.grading +
          writtenEvaluation * markingSchemeBreakDowns.writtenEvaluation +
          feedback * markingSchemeBreakDowns.feedback) /
        markingSchemeBreakDowns.total;
    }

    return overallScore;
  }
}
