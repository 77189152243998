import React, { useState } from 'react';
import { connect } from 'react-redux';
import { updateAssignmentSubmission } from 'actions/activity';
import FeedbackScoreEdit from 'components/Creation/CreationOverview/FeedbackTab/FeedbackScore/Edit';
import FeedbackScoreView from 'components/Creation/CreationOverview/FeedbackTab/FeedbackScore/View';
import { getCreationsFromEntities } from 'selectors/creation';

const FeedbackScore = (props: any) => {
  const [isEditingScore, setIsEditingScore] = useState(false);

  const toggleEdit = () => {
    setIsEditingScore(!isEditingScore);
  };

  const renderContent = () => {
    if (isEditingScore) {
      return (
        <FeedbackScoreEdit
          onCancel={toggleEdit}
          creation={props.creation}
          activity={props.activity}
          creations={props.creations}
        />
      );
    }
    return (
      <FeedbackScoreView
        onEdit={toggleEdit}
        creation={props.creation}
        activity={props.activity}
        groupView={props.groupView}
      />
    );
  };
  return renderContent();
};
const mapStateToProps = (state: any) => {
  return {
    creations: getCreationsFromEntities(state, state.selected.assignmentId),
  };
};

export default connect(mapStateToProps, { updateAssignmentSubmission })(FeedbackScore);
