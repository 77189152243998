const metaAssets = () => {
  return [
    { charset: 'utf-8' },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, maximum-scale=5, user-scalable=yes',
    },

    // Add to homescreen for Chrome on Android
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'theme-color', content: '#FFFFFF' },

    // iOS
    { name: 'apple-mobile-web-app-capable', content: 'yes' },
    { name: 'apple-mobile-web-app-status-bar-style', content: 'white' },
    { name: 'apple-touch-fullscreen', content: 'yes' },
  ];
};

const linkAssets = () => {
  const links = [
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css?family=Roboto:400,500,700,900|Tajawal:400,500,700,800,900|Roboto+Mono:400,500,700,800,900&display=swap',
    },
  ];

  return links;
};

export const title = 'Kritik';
export const meta = metaAssets();
export const link = linkAssets();
