export default {
  GET_LINE_ITEMS_REQUEST: 'GET_LINE_ITEMS_REQUEST',
  GET_LINE_ITEMS_SUCCESS: 'GET_LINE_ITEMS_SUCCESS',
  GET_LINE_ITEMS_FAILURE: 'GET_LINE_ITEMS_FAILURE',

  CREATE_LINE_ITEM_REQUEST: 'CREATE_LINE_ITEM_REQUEST',
  CREATE_LINE_ITEM_SUCCESS: 'CREATE_LINE_ITEM_SUCCESS',
  CREATE_LINE_ITEM_FAILURE: 'CREATE_LINE_ITEM_FAILURE',

  SYNC_ROSTER_REQUEST: 'SYNC_ROSTER_REQUEST',
  SYNC_ROSTER_SUCCESS: 'SYNC_ROSTER_SUCCESS',
  SYNC_ROSTER_FAILURE: 'SYNC_ROSTER_FAILURE',
};
