import { useState } from 'react';
import * as ActivityStatusUtil from '@kritik/utils/stage';
import { wasCreationMissed } from '@kritik/utils/creation/status';
import { MissedWork } from 'components/Assignment/NoticeBoards';
import NoticeBoard from 'components/layout/NoticeBoard';
import * as ActivityUtils from '@kritik/utils/activity';
import InstructorView from './InstructorView';
import { Activity, Creation, Student, User } from '@kritik/types.generated';

type InstructorEvaluationsOverviewProps = {
  activity: Activity;
  creation: Creation & { user: User } & { student: Student };
};

function InstructorEvaluationsOverview({ activity, creation }: InstructorEvaluationsOverviewProps) {
  if (
    (!creation || wasCreationMissed(creation)) &&
    !ActivityUtils.isPresentationActivity(activity)
  ) {
    return <MissedWork field="feedback" />;
  }

  if (!ActivityStatusUtil.isEvaluateOrLater(activity)) {
    return (
      <NoticeBoard type="information" title="No Evaluations Received">
        This creation has not received any evaluations at this time.
      </NoticeBoard>
    );
  }

  return <InstructorView activity={activity} creation={creation} />;
}

export default InstructorEvaluationsOverview;
