export default class App {
  config: any;
  constructor(config: any) {
    this.config = {
      vals: config,
      get: function (name: any) {
        if (typeof this.vals[name] === 'undefined') {
          throw Error(`No config value found for: ${name}`);
        }
        return this.vals[name];
      },
    };
  }
}
