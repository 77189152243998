import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { Button } from 'components/buttons';
import PageContainer from 'components/layout/PageContainer';
import PageHeader from 'components/layout/PageHeader';
import FormTextInput from 'components/core/form/TextField';
import { InlineInformation } from 'components/layout';
import { deleteUserMutation, useFetchUser } from 'hooks/user';

function isValidEmail(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}/;
  return emailRegex.test(email);
}

function DeleteUser() {
  const deleteUser = deleteUserMutation();
  const [validEmail, setValidEmail] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const { data, error: userError, isFetching, isSuccess } = useFetchUser(validEmail);

  useEffect(() => {
    if (deleteUser.isError && deleteUser.error instanceof AxiosError) {
      setError(deleteUser.error.response.data.errors.message);
    }

    if (userError && userError instanceof AxiosError) {
      setError(userError.response.data.error.message);
    }
  }, [deleteUser.isError, userError]);

  useEffect(() => {
    if (isSuccess && data.deleted) {
      setError(`User ${data.email} is already deleted`);
    }
    if (isSuccess && data.role === 'admin') {
      setError(`Cannot remove an admin`);
    }
  }, [isSuccess]);

  const handleChange = (event) => {
    setEmail(event.target.value);
    setValidEmail('');
    setError(null);
    setSuccess(null);
  };

  const handleGetEmail = (e: any) => {
    e.preventDefault();
    setValidEmail(isValidEmail(email) && email.trim() !== '' ? email : '');
  };

  return (
    <PageContainer>
      <PageHeader title="Delete User" />
      <form onSubmit={handleGetEmail}>
        <FormTextInput
          autoFocus
          name="promoteId"
          label="Enter an email address"
          value={email}
          onChange={handleChange}
          error={error}
        />

        {isFetching && (
          <InlineInformation type="information" style={{ marginBottom: '20px' }}>
            <div>Searching...</div>
          </InlineInformation>
        )}

        <Button
          inputType="submit"
          type="primary"
          style={{ marginBottom: '20px' }}
          disabled={isValidEmail(email) ? false : true}
        >
          Search User
        </Button>
      </form>

      {success ? (
        <InlineInformation type="success" style={{ marginBottom: '20px' }}>
          <div>{success}</div>
        </InlineInformation>
      ) : null}

      {data && isSuccess && (
        <div className="delete-user-container">
          <div className="delete-user-container__email">{data.email}</div>
          <div className="delete-user-container__deleted">
            {data.deleted ? 'User Deleted' : 'User Active'}
          </div>
          <div className="delete-user-container__button">
            <Button
              disabled={data.deleted || data.role === 'admin'}
              type="primary"
              onClick={() => {
                deleteUser.mutate(data._id, {
                  onSuccess: (data) => {
                    setSuccess(data.status);
                  },
                });
              }}
            >
              Delete User
            </Button>
          </div>
        </div>
      )}
    </PageContainer>
  );
}

export default DeleteUser;
