export const FIELD_NAME = 'evaluatorNotes';
export const FIELD_LABEL = 'Notes';

export const HAS_EVALUATOR_NOTES = 'hasEvaluatorNotes';
export const EVALUATOR_NOTES_FILES = 'evaluatorNotesFiles';

export const OPTIONS = {
  DEFAULT: {
    value: false,
    label: 'No Notes',
    testid: 'no-notes',
  },
  ENABLED: {
    value: true,
    label: 'Evaluator Notes',
    testid: 'evaluator-notes',
  },
};

export default {
  FIELD_NAME,
  FIELD_LABEL,
};
