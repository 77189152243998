import { userService } from 'services';
import * as types from 'types';
import errorAction from 'actions/error';
import { enrollStudentInCourse } from 'actions/students';

export function chargeStudentAndEnrollInCourse(data: any) {
  const { studentId } = data;
  return (dispatch: any) => {
    dispatch({ type: types.STRIPE_CHARGE_USER, payload: studentId });
    return userService()
      .chargeStripe(data)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({
            type: types.STRIPE_CHARGE_USER_SUCCESS,
            success: true,
            payload: res.data,
          });
        }
        dispatch(enrollStudentInCourse(data.courseId));
      })
      .catch((err: any) => {
        dispatch(errorAction(types.STRIPE_CHARGE_USER_FAILURE, err));
      });
  };
}

export function reEnrollStudentInCourse(courseId: any) {
  return (dispatch: any) => {
    dispatch(enrollStudentInCourse(courseId));
  };
}

export default {
  chargeStudentAndEnrollInCourse,
  reEnrollStudentInCourse,
};
