import React from 'react';
import { connect } from 'react-redux';
import * as creationStatusUtil from '@kritik/utils/creation/status';
import creationListUtil from 'components/CreationList/util';
import RAISE_DISPUTE from 'images/disputes/dispute_red.svg';
import RESOLVED_DISPUTE from 'images/disputes/dispute_green.svg';
import IssuesCell from 'components/CreationList/StatusCells/Issues/Cell';
import { getAssignment } from 'selectors/activity';
import * as ActivityUtils from '@kritik/utils/activity';
import { CreationScoreUtil, EvaluationScoreUtil, FeedbackScoreUtil } from '@kritik/utils/grade';
import * as LateSubmissionUtil from '@kritik/utils/creation/lateCreation';

const ICON_STYLES = {
  width: 'inherit',
};

const LateStatus = ({ creation }: any) => {
  if (!LateSubmissionUtil.isLateSubmission(creation)) {
    return null;
  }
  const text = (status: any) => {
    switch (status) {
      case LateSubmissionUtil.LATE_STATUS.ACCEPTED:
        return 'Accepted';
      case LateSubmissionUtil.LATE_STATUS.REJECTED:
        return 'Rejected';
      default:
        return 'Pending';
    }
  };

  const pending = LateSubmissionUtil.isLateSubmissionPending(creation);
  const rejected = LateSubmissionUtil.isLateSubmissionRejected(creation);

  const status = pending ? 'caution' : 'resolved';
  const icon = rejected ? 'fa-times-circle' : 'fa-exclamation-triangle';

  return (
    <IssuesCell
      status={status}
      label={`Late Creation - ${text(creation.lateSubmission.status)}`}
      icon={<i className={`fa ${icon}`} style={ICON_STYLES} />}
    />
  );
};

const FlagStatus = ({ creation, activity, studentId, isGroupItem }: any) => {
  const numFlags = creationStatusUtil.getNumOfCreationFlags(creation);
  if (!numFlags) {
    return null;
  }
  if (
    ActivityUtils.isGroupAssignment(activity) ||
    ActivityUtils.isGroupPresentationActivity(activity)
  ) {
    const shouldShowIssueFlag = creationListUtil.shouldShowFlagForGroupMember(
      creation.scores,
      studentId
    );
    if (!shouldShowIssueFlag && !isGroupItem) {
      return null;
    }
  }
  return (
    <IssuesCell
      status="warning"
      label="Flagged Comments"
      testid="creation-table-flagged-evaluation"
      icon={<i className="fa fa-flag" style={ICON_STYLES} />}
    />
  );
};

const DisputeStatus = ({ creation }: any) => {
  if (creationStatusUtil.isDisputeResolved(creation)) {
    return (
      <IssuesCell
        status="resolved"
        label="Resolved Grade Dispute"
        icon={<img alt="Disputes" src={RESOLVED_DISPUTE} style={ICON_STYLES} />}
      />
    );
  }
  if (creationStatusUtil.isDispute(creation)) {
    return (
      <IssuesCell
        status="warning"
        label="Grade Disputed"
        icon={<img alt="Disputes" src={RAISE_DISPUTE} style={ICON_STYLES} />}
      />
    );
  }
  return null;
};

const ScoreStatus = ({ creation, activity }: any) => {
  if (
    !LateSubmissionUtil.isLateSubmissionRejected(creation) &&
    creationStatusUtil.isCreationNotGradedAfterEvaluation(creation, activity) &&
    !ActivityUtils.isCalibrationActivity(activity)
  ) {
    return (
      <IssuesCell
        status="warning"
        label="Not Graded"
        icon={<i className="fa fa-book" style={ICON_STYLES} />}
      />
    );
  }

  const isGradedByInstructor =
    CreationScoreUtil.isInstructorGraded(creation) ||
    CreationScoreUtil.isProfRevised(creation) ||
    EvaluationScoreUtil.isEvaluationScoredByTeacher(creation) ||
    FeedbackScoreUtil.isFeedbackScoredByTeacher(creation);

  if (isGradedByInstructor) {
    return (
      <IssuesCell
        status="resolved"
        label="Graded by Instructor"
        testid="graded-by-instructor"
        icon={<i className="fa fa-book" style={ICON_STYLES} />}
      />
    );
  }

  return null;
};

const IssuesList = (props: any) => {
  if (!props.creation) {
    return null;
  }
  return (
    <div className="creation-table-issue-list">
      <LateStatus creation={props.creation} />
      <DisputeStatus creation={props.creation} />
      <FlagStatus
        creation={props.creation}
        activity={props.activity}
        studentId={props.studentId}
        isGroupItem={props.isGroupItem}
      />
      <ScoreStatus creation={props.creation} activity={props.activity} />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
  };
};

export default connect(mapStateToProps)(IssuesList);
