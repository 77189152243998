import React, { useState, useContext, useEffect } from 'react';
import Button from 'components/buttons/Button';
import { connect } from 'react-redux';
import { findEvaluationForMissedFeedback } from 'actions/admin';
import FormNumberInput from 'components/core/form/NumberField';
import { CourseContext } from '../context/CourseContext';
import { EvaluationContext } from '../context/EvaluationContext';
import { AssignmentContext } from '../context/AssignmentContext';
import FindAssignmentContainer from '../Find/FindAssignmentContainer';

const mapStateToProps = (state: any) => {
  return {
    evaluator: state.admin.student,
    creation: state.admin.creation,
  };
};

const FindEvaluation = (props: any) => {
  const [course, setCourse] = useContext(CourseContext);

  const [evaluation, setEvaluation] = useContext(EvaluationContext);

  const [assignment, setAssignment] = useContext(AssignmentContext);
  const [evalNum, setEvalNum] = useState('');

  const changeCourse = (course: any) => {
    return setCourse(course);
  };
  const changeAssignment = (newAssignment: any) => {
    setEvalNum('');
    setAssignment(newAssignment);
  };
  const changeEvalNum = (e: any) => {
    return setEvalNum(e.target.value);
  };

  const findEval = async () => {
    const { evaluator, findEvaluationForMissedFeedback } = props;
    if (assignment) {
      const data = await findEvaluationForMissedFeedback({
        assignment: assignment.value._id,
        student: evaluator.id,
        evaluationNumber: evalNum,
      });
      if (data !== 'not found') {
        data.confirmed = false;
      }
      setEvaluation(data);
    }
  };

  return (
    <React.Fragment>
      <FindAssignmentContainer
        course={course}
        changeCourse={changeCourse}
        assignment={assignment}
        changeAssignment={changeAssignment}
      />

      <FormNumberInput
        name="evalNumber"
        label="Evaluation Number"
        value={evalNum}
        onChange={changeEvalNum}
        min={1}
      />

      <Button type="secondary" onClick={findEval}>
        Find Evaluation
      </Button>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, {
  findEvaluationForMissedFeedback,
})(FindEvaluation);
