import * as types from 'types';
import { normalize } from 'normalizr';
import { mergeEntities, updateEntities } from 'actions/entities';
import { creationSchema } from 'schemas';
import { commentService } from 'services';

export function markInstructorCommentSeen({ creation, viewed }: any) {
  return (dispatch: any) => {
    dispatch({ type: types.UPDATE_CREATION_COMMENT_REQUEST });
    return commentService()
      .markInstructorCommentSeen({ creation, viewed })
      .then((res: any) => {
        if (res.status === 200) {
          const normalizedCreation = normalize(res.data, creationSchema);
          dispatch(mergeEntities(normalizedCreation.entities));
          dispatch({ type: types.UPDATE_CREATION_COMMENT_SUCCESS });
        }
      })
      .catch((err: any) => {
        console.log(err);
        dispatch({ type: types.UPDATE_CREATION_COMMENT_FAILURE });
      });
  };
}

export function updateInstructorCreationComment({ creationId, text, files, commenterId }: any) {
  return (dispatch: any) => {
    dispatch({ type: types.UPDATE_CREATION_COMMENT_REQUEST });
    return commentService()
      .updateInstructorCreationComment({ creationId, text, files, commenterId })
      .then((res: any) => {
        if (res.status === 200) {
          const normalizedCreation = normalize(res.data, creationSchema);
          dispatch(updateEntities(normalizedCreation.entities));
          dispatch({ type: types.UPDATE_CREATION_COMMENT_SUCCESS });
        }
      })
      .catch((err: any) => {
        console.log(err);
        dispatch({ type: types.UPDATE_CREATION_COMMENT_FAILURE });
      });
  };
}

export default {
  markInstructorCommentSeen,
  updateInstructorCreationComment,
};
