export default {
  title: {
    Overall: 'Overall Score',
    Creation: 'Creation Score',
    Evaluation: 'Evaluation Score',
    Feedback: 'Feedback Score',
  },
  content: {
    Overall: `You can view your Overall Score once an activity has
      been marked as completed by your instructor. Click on 'Learn more' to see
      how your Overall Score is calculated.`,
    Creation: `Your creation will be assigned to a few peers, who will
      score your creation. Each peer's impact on your creation score is
      different based on their Grading power. Learn more about how grading
      power works and how it affects your creation score.`,
    Evaluation: `There are two components that determine how you will be
      scored as an evaluator. Your grading score is based on how well you
      grade your peers, and your written evaluation score is determined by
      how critical and motivational your peers view your written
      evaluation to be.`,
    Feedback: `You will receive a score depending on the number of
      Feedback assigned to you in a particular activity and the
      number of feedback you completed. Click on 'Learn more' to
      see how you can achieve the highest Feedback Score.`,
  },
  links: {
    Overall: 'https://help.kritik.io/en/articles/6388500-how-scoring-works',
    Creation:
      'https://help.kritik.io/en/articles/6845495-grading-power-and-score-calculation-in-kritik',
    Evaluation: 'https://help.kritik.io/en/articles/6388500-how-scoring-works#h_853eac162e',
    Feedback: 'https://help.kritik.io/en/articles/6388500-how-scoring-works#h_53b4baa610',
  },
};
