import React from 'react';

export const MenuInput = ({ icon, children }: any) => {
  const renderIcon = icon ? (
    <span className="creation-table-menu__field-input-icon">{icon}</span>
  ) : null;
  return (
    <div className="creation-table-menu__field-input-wrapper">
      <div className="creation-table-menu__field-input">{children}</div>
      {renderIcon}
    </div>
  );
};

export const MenuLabel = ({ label, id }: any) => {
  return (
    <label htmlFor={id} className="creation-table-menu__field-label">
      {label}
    </label>
  );
};

export const MenuField = ({ children }: any) => {
  return <div className="creation-table-menu__field">{children}</div>;
};
