const SET_SCHEDULE = 'SET_SCHEDULE';
const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';

export default {
  SET_SCHEDULE,
  UPDATE_SCHEDULE,
  RESET_SCHEDULE: 'RESET_SCHEDULE',
  GET_SCHEDULING_TEMPLATE: 'GET_SCHEDULING_TEMPLATE',
  GET_SCHEDULING_TEMPLATE_SUCCESS: 'GET_SCHEDULING_TEMPLATE_SUCCESS',
  GET_SCHEDULING_TEMPLATE_FAILURE: 'GET_SCHEDULING_TEMPLATE_FAILURE',
  CREATE_SCHEDULING_TEMPLATE: 'CREATE_SCHEDULING_TEMPLATE',
  CREATE_SCHEDULING_TEMPLATE_SUCCESS: 'CREATE_SCHEDULING_TEMPLATE_SUCCESS',
  CREATE_SCHEDULING_TEMPLATE_FAILURE: 'CREATE_SCHEDULING_TEMPLATE_FAILURE',
  UPDATE_SCHEDULING_TEMPLATE: 'UPDATE_SCHEDULING_TEMPLATE',
  UPDATE_SCHEDULING_TEMPLATE_SUCCESS: 'UPDATE_SCHEDULING_TEMPLATE_SUCCESS',
  UPDATE_SCHEDULING_TEMPLATE_FAILURE: 'UPDATE_SCHEDULING_TEMPLATE_FAILURE',
  SET_ERROR_PAST_DATE: 'SET_ERROR_PAST_DATE',
};
