import React from 'react';
import * as LateSubmissionUtil from '@kritik/utils/creation/lateCreation';
import { getSubmissionLastEditedDate } from '@kritik/utils/creation/status';
import { exactDate } from '@kritik/utils/format';
import HtmlContent from 'components/General/HtmlContent';

export const SubmittedDate = (props: any) => {
  const submissionDate = getSubmissionLastEditedDate(props.creation);
  const formattedSubmissionDate = exactDate(submissionDate);
  return <div>{`Submitted ${formattedSubmissionDate}`}</div>;
};

export const LateReason = (props: any) => {
  const reason = LateSubmissionUtil.getReasonForLateSubmission(props.creation);
  return (
    <React.Fragment>
      <div className="late-submission-boards__reason">Reason For Submitting Late </div>
      <HtmlContent content={reason} />
    </React.Fragment>
  );
};

export const RejectedDate = (props: any) => {
  const rejectedDate = exactDate(LateSubmissionUtil.getResolvedDate(props.creation));
  return <div>{`Rejected ${rejectedDate}`}</div>;
};

export const AcceptedDate = (props: any) => {
  const date = exactDate(LateSubmissionUtil.getResolvedDate(props.creation));
  return <div>{`Accepted ${date}`}</div>;
};
