import React from 'react';
import classNames from 'classnames';

const StageContainer = (props: any) => {
  const classes = classNames('stage-container', {
    'has-next-stage': props.hasNextStage,
  });
  return <div className={classes}>{props.children}</div>;
};

export default StageContainer;
