import { Course } from '@kritik/types.generated';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import lmsService from 'services/lms';
import { courseService } from 'services/index';

export function lmsDisconnectCourseMutation(courseId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (courseId: string) => {
      const result = await lmsService().disconnectCourse(courseId);
      return result.data as Course;
    },
    onSettled: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: ['course', courseId],
      });
    },
  });
}

export interface SyncRoster {
  studentsAdded: StudentsAdded[];
  studentsUpdated: StudentsAdded[];
  studentsRemoved: StudentsAdded[];
  apply: boolean;
  message: string | null;
}

export interface StudentsAdded {
  email: string;
  edlinkPersonId: string;
  firstName: string;
  lastName: string;
}

export function lmsSyncRosterMutation(courseId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ courseId, apply }: { courseId: string; apply: boolean }) => {
      const result = await lmsService().syncRoster({ courseId, apply });
      return result.data as SyncRoster;
    },
    onSettled: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: ['course', courseId],
      });
    },
  });
}

export function updateAutoSyncSettingMutation(courseId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ courseId, autoRosterSync }: any) => {
      const result = await courseService().update({
        id: courseId,
        data: {
          lms: {
            autoRosterSync,
          },
        },
      });

      return result.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['course', courseId],
      });
    },
  });
}

export function useApprovePendingLMSConnection() {
  return useMutation({
    mutationFn: async ({ code }: { code: string }) => {
      await lmsService().approvePendingConnection(code);
    },
  });
}

export function useResendPendingLMSConnectionCode() {
  return useMutation({
    mutationFn: async () => {
      await lmsService().resendPendingConnectionCode();
    },
  });
}

type UseFetchCourseLastGroupSyncResultsArgs = {
  courseId: string;
};

export function useFetchCourseLastGroupSyncsResults({
  courseId,
}: UseFetchCourseLastGroupSyncResultsArgs) {
  return useQuery(['getCourseLastGroupSyncResults'], async () => {
    const response = await lmsService().getCourseLastGroupSyncResults({ courseId });
    return response.data;
  });
}
