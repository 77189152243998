import { Card, CardActions, CardContent } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { TranslatedText } from 'components/TranslatedText';
import { Link } from 'react-router-dom';
import { trackEvent } from 'utils/userEvents';
import { useAuthUserFromRedux } from 'hooks/user';
import INSTRUCTOR_SVG from 'images/robot-instructor.svg';
import { localize } from 'locales/index';

export default function CreateCourseAICard() {
  const authUser = useAuthUserFromRedux();

  return (
    <Link
      to={'/?create-course-modal=true'}
      onClick={() => trackEvent('Clicked on Create Course with AI', authUser)}
    >
      <Card className="card-ai card-ai-background-color">
        <CardContent className="card-ai-content">
          <div className="card-ai-content__left">
            <div className="card-ai-title">
              <i className="fa fa-magic" /> <TranslatedText i18nKey="Course.Create.AI.Title" />
            </div>
            <p className="card-ai-subtitle">
              <TranslatedText i18nKey="Course.Create.AI.Subtitle" />
            </p>
          </div>
          <img src={INSTRUCTOR_SVG} aria-hidden="true" width="170px" height="128px" />
        </CardContent>
        <CardActions
          className="card-ai-actions card-ai-cta-background-color"
          aria-label={localize({ message: 'Course.Create.AI.CTA' })}
          tabIndex={0}
        >
          <div className="card-ai-actions__cta">
            <TranslatedText i18nKey="Course.Create.AI.CTA" />
            <span className="icon">
              <ArrowForward />
            </span>
          </div>
        </CardActions>
      </Card>
    </Link>
  );
}
