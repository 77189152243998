import React from 'react';
import createSvgIcon from '../util/generateIconSVG';

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M0 13.5C0 6.04416 6.04416 0 13.5 0H25.5C32.9558 0 39 6.04416 39 13.5C39 20.9558 32.9558 27 25.5 27H13.5C6.04416 27 0 20.9558 0 13.5Z"
      fill="#DCDBF0"
    />
    <path
      d="M15.2007 14C14.5913 13.1211 14.2515 12.0664 14.2515 11C14.2515 10.7422 14.2749 10.4844 14.3101 10.2266C13.8062 10.4023 13.2905 10.4961 12.7515 10.4961C11.1694 10.4961 9.95068 9.5 9.70459 9.5C8.21631 9.5 8.25146 12.6875 8.25146 13.6367C8.25146 14.9375 9.35303 15.5 10.5249 15.5H12.0952C12.8804 14.5625 13.9819 14.0352 15.2007 14ZM27.7515 21.4648C27.7515 18.8164 27.1304 14.75 23.6968 14.75C23.2983 14.75 21.8452 16.3789 19.5015 16.3789C17.1577 16.3789 15.7046 14.75 15.3062 14.75C11.8726 14.75 11.2515 18.8164 11.2515 21.4648C11.2515 23.3633 12.5054 24.5 14.3804 24.5H24.6226C26.4976 24.5 27.7515 23.3633 27.7515 21.4648ZM15.7515 6.5C15.7515 4.84765 14.4038 3.5 12.7515 3.5C11.0991 3.5 9.75146 4.84765 9.75146 6.5C9.75146 8.15234 11.0991 9.5 12.7515 9.5C14.4038 9.5 15.7515 8.15234 15.7515 6.5ZM24.0015 11C24.0015 8.51562 21.9858 6.5 19.5015 6.5C17.0171 6.5 15.0015 8.51562 15.0015 11C15.0015 13.4844 17.0171 15.5 19.5015 15.5C21.9858 15.5 24.0015 13.4844 24.0015 11ZM30.7515 13.6367C30.7515 12.6875 30.7866 9.5 29.2983 9.5C29.0522 9.5 27.8335 10.4961 26.2515 10.4961C25.7124 10.4961 25.1968 10.4023 24.6929 10.2266C24.728 10.4844 24.7515 10.7422 24.7515 11C24.7515 12.0664 24.4116 13.1211 23.8022 14C25.021 14.0352 26.1226 14.5625 26.9077 15.5H28.478C29.6499 15.5 30.7515 14.9375 30.7515 13.6367ZM29.2515 6.5C29.2515 4.84765 27.9038 3.5 26.2515 3.5C24.5991 3.5 23.2515 4.84765 23.2515 6.5C23.2515 8.15234 24.5991 9.5 26.2515 9.5C27.9038 9.5 29.2515 8.15234 29.2515 6.5Z"
      fill="#302D6C"
    />
  </React.Fragment>,
  '0 0 39 27'
);
