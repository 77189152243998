import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import CourseList from 'components/Course/List';
import { resetAll } from 'actions/select';
import PageContainer from 'components/layout/PageContainer';
import PageHeader from 'components/layout/PageHeader';
import UserDetails from 'components/auth/UserDetails';
import { push } from 'router';
import { getEnrollLink } from '@kritik/utils/general';
import LtiLaunchBar from 'components/LtiLaunchBar';
import Cookies from 'js-cookie';
import { COOKIES } from '@kritik/constants/cookie';
import { useFetchCourses } from 'hooks/course';
import { TranslatedText } from 'components/TranslatedText';
import Button from 'components/buttons/Button';

type EdlinkClassInfo = {
  id: string;
  name: string;
  edlinkIntegrationId: string;
  lmsClassId?: string;
} | null;

const Home = (props: any) => {
  const [enrollLink, setEnrollLink] = React.useState(null);
  const [newCourseAdded, setNewCourseAdded] = React.useState(null);
  const { data: coursesList } = useFetchCourses();
  const canCreateCourse = props.user.authUser?.permissions?.includes('canCreateCourse');
  const { user, resetAll, router } = props;

  React.useEffect(() => {
    resetAll();

    const searchParams = new URLSearchParams(document.location.search);
    const courseId = searchParams && searchParams.get('courseId');
    if (courseId) {
      const getEnrollLinkInit = async () => {
        const enrollLink = await getEnrollLink({
          courseId,
          email: user.email,
        });
        if (enrollLink) {
          setEnrollLink(`${window.location.origin}${enrollLink}`);
        }
      };
      void getEnrollLinkInit();
    }
  }, []);

  const isUserDataComplete = () => {
    return (
      user.authUser?.profile?.firstName &&
      user.authUser?.profile?.lastName &&
      user.authUser?.profile?.picture &&
      Boolean(user.authUser?.email) &&
      !Boolean(user.authUser?.phoneNumber === null && user.authUser?.edlinkPersonIds.length > 0)
    );
  };

  const getSearchParams = () => {
    return new URLSearchParams(document.location.search);
  };

  const showSuccessMessage = (courseName: string) => {
    setNewCourseAdded(courseName);
  };

  if (!user.authenticated) {
    router.push('/login');
    return null;
  }

  const searchParams = getSearchParams();
  const courseId = searchParams && searchParams.get('courseId');
  const action = searchParams && searchParams.get('action');
  const shouldRedirectToCheckout = courseId && action === 'sign-up';

  const edlinkClassInfoCookie = Cookies.get(COOKIES.EDLINKCLASSINFO);
  let edlinkClassInfo: EdlinkClassInfo = null;
  if (edlinkClassInfoCookie && edlinkClassInfoCookie !== '""') {
    const edlinkClassInfoData = JSON.parse(decodeURIComponent(edlinkClassInfoCookie).substring(2));
    edlinkClassInfo = {
      id: edlinkClassInfoData.id,
      name: edlinkClassInfoData.name,
      edlinkIntegrationId: edlinkClassInfoData.edlinkIntegrationId,
      lmsClassId: edlinkClassInfoData.lmsClassId,
    };
  }

  if (!isUserDataComplete()) {
    return (
      <PageContainer>
        <UserDetails shouldRedirectToCheckout={shouldRedirectToCheckout} courseId={courseId} />
      </PageContainer>
    );
  }

  if (shouldRedirectToCheckout && enrollLink) {
    window.location.href = enrollLink;
  }

  return (
    <PageContainer>
      <PageHeader title="Courses" testid="courses-list-page-title">
        {canCreateCourse && (
          <Button
            type="primary"
            data-testid="create-course-top-button"
            onClick={() => {
              router.push(`?create-course-modal=true`);
            }}
            aria-haspopup="dialog"
          >
            <TranslatedText i18nKey={`Course.Create`} />
          </Button>
        )}
      </PageHeader>
      {newCourseAdded && (
        <div className="banner" data-testid="success-message">
          <i className="fa fa-info-circle" style={{ margin: 10, color: '#4844a3' }} />
          <p style={{ paddingBottom: 10 }}>
            <TranslatedText
              i18nKey="LtiLaunchBar.SuccessMessage"
              values={{ newCourseAdded: newCourseAdded }}
            />
          </p>
        </div>
      )}

      <LtiLaunchBar
        edlinkClassInfo={edlinkClassInfo}
        canDuplicate={coursesList && coursesList.length > 0}
        showSuccessMessage={(courseName: string) => showSuccessMessage(courseName)}
      />
      <CourseList
        coursesList={coursesList && coursesList}
        showSuccessMessage={showSuccessMessage}
        newCourseAdded={newCourseAdded}
        router={router}
      />
    </PageContainer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    resetAll,
    push,
  })(Home)
);
