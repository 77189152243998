import InfoLabel from 'components/layout/InfoLabel';
import { TranslatedText } from 'components/TranslatedText';

export const EvaluationStatus = () => {
  return (
    <InfoLabel
      testid={`status-label-grace-period`}
      type={'caution'}
      label={
        <span>
          <TranslatedText i18nKey="Evaluation.GracePeriod.LearnMore.Title" />
        </span>
      }
    />
  );
};
