import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getAssignment } from 'selectors/activity';
import RadioInput from 'components/core/input/Radio';
import FormTitle from 'components/core/form/Title';
import Confirm from 'components/modals/ConfirmModal';
import { Field } from 'react-final-form';
import { ACTIVITY_TYPES } from '@kritik/constants/activity';
import * as statusUtil from '@kritik/utils/stage';
import validators from 'components/ActivityEdit/Details/Fields/validators';
import FormFieldInfo from 'components/core/form/FieldInfo';
import { FIELD_NAME, FIELD_LABEL } from './constants';

export const LABEL = {
  value: FIELD_NAME,
  label: FIELD_LABEL,
};

const EvaluationType = (props: any) => {
  const [targetEvaluationType, setTargetEvaluationType] = useState(null);
  const [changeTypeConfirm, setChangeTypeConfirm] = useState(null);
  const getRadioOptions = () => {
    return [
      {
        value: ACTIVITY_TYPES.GROUP,
        label: 'Between Groups',
        testid: 'between-groups',
      },
      {
        value: ACTIVITY_TYPES.WITHIN_GROUP,
        label: 'Within Group',
        testid: 'within-group',
      },
    ];
  };

  const changeEvaluationType = () => {
    if (
      props.settings.activity &&
      !props.settings.isCloning &&
      !statusUtil.isDraft({ assignment: props.settings.activity })
    ) {
      return;
    }
    props.mutators.setValue(LABEL.value, targetEvaluationType);
    cancelTypeSwitch();
  };

  const cancelTypeSwitch = () => {
    setChangeTypeConfirm(null);
  };

  useEffect(() => {
    changeEvaluationType();
  }, [targetEvaluationType]);

  const renderConfirm = () => {
    return (
      <Confirm
        title="Are you sure you want to switch the evaluation type?"
        description="All your unsaved changes will be lost."
        isOpen={!!changeTypeConfirm}
        onCancel={() => {
          return cancelTypeSwitch();
        }}
        onConfirm={() => {
          return setTargetEvaluationType(changeTypeConfirm);
        }}
        cancelButton="No"
        confirmButton="Yes"
        testid="change-evaluation-type"
      />
    );
  };

  const changeEvaluationTypeRequest = (currentEvaluationType: any, targetType: any) => {
    if (currentEvaluationType !== targetType) {
      setChangeTypeConfirm(targetType);
    }
  };

  return (
    <Field name={LABEL.value} validate={validators.isRequired}>
      {({ input, meta }) => {
        return (
          <React.Fragment>
            <FormTitle label={LABEL.label} />
            <RadioInput
              value={input.value}
              options={getRadioOptions()}
              onChange={(e: any) => {
                return changeEvaluationTypeRequest(input.value, e.target.value);
              }}
              type="button"
              legendText="Legend.Text.GroupEvaluationType"
            />
            {meta.error && meta.touched && <span>{meta.error}</span>}
            <div className="evaluation-type__info">
              <FormFieldInfo
                label={
                  input.value === ACTIVITY_TYPES.GROUP ? (
                    'Students work together as a group on an activity and submit one creation for the group. During the evaluation stage students individually evaluate the creations of other groups.'
                  ) : (
                    <>
                      There are two types of activities that can be done using this method:
                      <ul className="withing-group-label">
                        <li>
                          Students submit an individual creation. During the evaluation stage,
                          students evaluate all of the creations of the other members within their
                          own group.
                        </li>
                        <li>
                          Students submit a self-reflection about their contribution to their
                          group’s work (in relation to a separate group project/presentation).
                          During the evaluation stage, students evaluate each group member’s
                          contribution to the group’s work.
                        </li>
                      </ul>
                    </>
                  )
                }
              />
            </div>
            {renderConfirm()}
          </React.Fragment>
        );
      }}
    </Field>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
  };
};

export default connect(mapStateToProps, {})(EvaluationType);
