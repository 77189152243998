import { useState } from 'react';
import { connect } from 'react-redux';
import {
  getEvaluationGracePeriodStage,
  getEvaluationStage,
  isEvaluate,
  isEvaluateOrLater,
  isFeedback,
  isFeedbackOrLater,
  isInEvaluationGracePeriod,
  isProcessing1,
  isProcessing2,
} from '@kritik/utils/stage';
import * as FormatUtils from '@kritik/utils/format';
import * as CreationStatusUtil from '@kritik/utils/creation/status';
import { isRevealingEvaluators } from '@kritik/utils/course';
import * as StudentUtils from 'utils/student';
import { isGroupLeader, findGroupFromStudentAndActivity } from '@kritik/utils/group';
import { FeedbackScoreUtil } from '@kritik/utils/grade';
import { getGroupList, getGroupOfStudent } from 'selectors/group';
import { getMySubmission } from 'selectors/creation';
import { getAssignment } from 'selectors/activity';
import { getCourse } from 'selectors/course';
import { getStudentFromUser } from 'selectors/student';
import {
  StartingSoon,
  LateEnrollment,
  NoAssignedCreations,
  LateSubmissionAvailability,
  NoGroup,
  NoAssignedEvaluations,
} from 'components/Assignment/NoticeBoards';
import TabMenu, { TabMenuUtils } from 'components/layout/TabMenu';
import { CreationView } from 'components/Creation/Create/View';
import { EvaluationEdit, EvaluationView } from 'components/Creation/Evaluate';
import { Feedback } from 'components/Creation/Feedback';
import Button from 'components/buttons/Button';
import StudentContent from 'components/layout/StudentContent';
import {
  isWithinGroupActivity,
  isGroupAssignment,
  hasEvaluatorNotes,
  isCalibrationActivity,
  isGroupPresentationActivity,
  isPresentationActivity,
  isIndividualPresentationActivity,
} from '@kritik/utils/activity';
import { selectGradeHistories } from 'selectors/grade';
import EvaluatorNotes from 'containers/assignments/overview/ActivityBody/EvaluatorNotes';
import StageInstructions from 'components/Creation/StageInstruction';
import VerticalList from 'components/layout/VerticalList';
import Checkbox from 'components/core/input/Checkbox';
import NoticeBoard from 'components/layout/NoticeBoard';
import * as ActivityUtils from '@kritik/utils/activity';
import * as EvaluationUtil from '@kritik/utils/creation/evaluation';
import { waiveLateSubmissionRight, getAssignmentSubmissions } from 'actions/activity';
import { localize } from 'locales/index';
import { useUserRoleInCourse } from 'hooks/course';
import InstructionsCard from 'components/InstructionsCard/InstructionsCard';
import EVAL_IMAGE_1 from 'images/card-instructions/eval1.jpg';
import EVAL_IMAGE_2 from 'images/card-instructions/eval2.jpg';
import EVAL_IMAGE_3 from 'images/card-instructions/eval3.jpg';
import * as EvaluationUtils from '@kritik/utils/creation/evaluation';
import { useTranslation } from 'react-i18next';
import { TranslatedText } from 'components/TranslatedText';
import { Activity, CreationScore } from 'old-common/types.generated';
import { StudentLateSubmissionNoticeBoard } from '../StudentLateSubmissionNoticeBoard';
import { GracePeriodWarning } from './GracePeriodWarning';
import { useGetCreationsToEvaluate } from 'hooks/creations';

const getUserEvaluation = ({ creation, user }) => {
  return (
    creation.scores.find((score) => {
      return score.user._id === user.authUser.id;
    }) || false
  );
};

function StudentView(props: any) {
  const {
    activity,
    course,
    user,
    myCreation,
    myStudent,
    studentGroup,
    waiveLateSubmissionRight,
    submission,
    entities,
    groups,
  } = props;
  const { data: creationsToEvaluate, isLoading } = useGetCreationsToEvaluate({
    activityId: activity._id,
    courseId: course._id,
    studentId: myStudent._id,
  });

  const [selectedCreationToEvaluateIndex, setSelectedCreationToEvaluateIndex] = useState(0);

  const [showTab, setShowTab] = useState(false);

  const { isStudentInCourse } = useUserRoleInCourse();

  const shouldShowEvaluatorNotes = hasEvaluatorNotes(activity) && isStudentInCourse;

  const isOwnGroupSubmissionForCalibration = (activity: any, creation: any) => {
    if (!isCalibrationActivity(activity) || !groups || !groups.length) {
      return false;
    }
    const studentGroup = findGroupFromStudentAndActivity(
      groups,
      myStudent,
      activity.isCalibrationActivity
    );

    if (studentGroup) {
      return isGroupLeader(creation.student, studentGroup);
    }
    return false;
  };

  if (!activity || !creationsToEvaluate || isLoading) {
    return null;
  }

  if (
    myCreation?.submissionStatus === 'MISSED' &&
    activity.removeStudentFromActivityAfterMissedCreation
  ) {
    return <NoAssignedEvaluations />;
  }

  if (
    ActivityUtils.canSubmitLate(myCreation, activity) &&
    (!isFeedbackOrLater({ assignment: activity }) ||
      (isFeedback({ assignment: activity }) && activity.acceptLateEvaluations))
  ) {
    return (
      <LateSubmissionOptions
        activity={activity}
        myCreation={myCreation}
        studentGroup={studentGroup}
        waiveLateSubmissionRight={waiveLateSubmissionRight}
        myStudent={myStudent}
      />
    );
  }

  const wasStudentEnrolledInFeedbackStage = StudentUtils.wasStudentEnrolledInFeedbackStageOrLater(
    activity,
    myStudent
  );
  if (wasStudentEnrolledInFeedbackStage) {
    return <LateEnrollment stage="FEEDBACK" />;
  }

  if (!creationsToEvaluate || !creationsToEvaluate.length) {
    if (!isEvaluateOrLater(activity)) {
      return <StartingSoon stage="EVALUATE" />;
    }
    const wasStudentEnrolledInEvaluateStage = StudentUtils.wasStudentEnrolledLate(
      activity,
      myStudent
    );

    if (wasStudentEnrolledInEvaluateStage) {
      return <LateEnrollment stage="EVALUATE" />;
    }

    return <NoAssignedCreations />;
  }

  const creationToEvaluate = creationsToEvaluate[selectedCreationToEvaluateIndex];
  const isMyGroupSubmissionCalibration = isOwnGroupSubmissionForCalibration(
    activity,
    creationToEvaluate
  );
  if (!creationToEvaluate) {
    return null;
  }

  const evaluation = getUserEvaluation({ creation: creationToEvaluate, user });

  const getCreationTitle = ({ activity, user, index }: any) => {
    const shouldShowRealName =
      isRevealingEvaluators(course) ||
      isWithinGroupActivity(activity) ||
      isPresentationActivity(activity);

    return shouldShowRealName
      ? `${
          isGroupPresentationActivity(activity) ? creationToEvaluate.group.name : user.profile.name
        }'s Creation`
      : `Anonymous ${isGroupAssignment(activity) ? 'Group' : 'Peer'} Creation ${index + 1}`;
  };

  const { completedFOF } = FeedbackScoreUtil.numFOFCompleted(myCreation, props.activity._id);
  const labels = creationsToEvaluate.map((creation: any, i: any) => {
    const studentEvaluation = EvaluationUtil.getEvaluationByStudentId(
      creation.scores,
      myStudent._id
    );

    const getTabTitle = (i) => {
      const isSelfEvaluation = creation.user._id === myStudent.user;
      if (isGroupPresentationActivity(activity)) {
        return creation.group.name;
      }
      if (isIndividualPresentationActivity(activity)) {
        return creation.user.profile.name;
      }
      if (isSelfEvaluation) {
        return <TranslatedText i18nKey="Activity.WithinGroup.Evaluations.SelfEvaluation" />;
      }
      return `Evaluation ${i + 1}`;
    };

    return {
      id: studentEvaluation?._id || i,
      title: getTabTitle(i),
      status: TabMenuUtils.getEvaluationTabStatus({
        evaluation: studentEvaluation,
        activity,
      }),
    };
  });

  const numEvalsCompleted =
    (submission && EvaluationUtils.getNumEvaluationsCompleted(submission, myStudent?._id)) || 0;

  return (
    <div>
      <EvaluationInstructions />
      {shouldShowEvaluatorNotes && (
        <StageInstructions title="Evaluator Notes">
          <EvaluatorNotes user={user.authUser} activity={activity} isSection={false} />
        </StageInstructions>
      )}
      {(isEvaluate({ assignment: activity }) && numEvalsCompleted === 0 && !showTab) ||
      (!isFeedbackOrLater({ assignment: activity }) && !showTab && numEvalsCompleted === 0) ? (
        <Button
          type="primary"
          disabled={submission && Boolean(submission.length > 0)}
          unavailable={false}
          onClick={() => setShowTab(true)}
          label={localize({ message: 'Button.Label.SkipCreateAndStartToEvaluate' })}
          data-testid={'button-start-evaluations'}
        >
          <i className="fas fa-pen" />
          <span className="button-span-copy">
            {localize({
              message: 'Activity.StudentView.EvaluationInstructions.ReadyToEvaluate',
            })}
          </span>
        </Button>
      ) : null}

      {showTab ||
      (isEvaluate({ assignment: activity }) && numEvalsCompleted > 0) ||
      (isInEvaluationGracePeriod(activity) && numEvalsCompleted > 0) ||
      isFeedbackOrLater({ assignment: activity }) ? (
        <>
          <TabMenu
            labels={labels}
            onSelect={(labelId: string, index: number) => {
              return setSelectedCreationToEvaluateIndex(index);
            }}
            activeLabel={selectedCreationToEvaluateIndex}
          />
          <VerticalList
            itemList={[
              <OverdueEvaluationNotice
                evaluation={evaluation}
                activity={activity}
                completedFOF={completedFOF}
              />,
              <StudentContent
                user={creationToEvaluate.user}
                score={StudentUtils.getNewKS(entities.students[creationToEvaluate.student._id])}
                group={creationToEvaluate.group}
                anonymous={
                  !isWithinGroupActivity(activity) &&
                  !isMyGroupSubmissionCalibration &&
                  !isPresentationActivity(activity)
                }
                key={selectedCreationToEvaluateIndex}
              >
                <CreationView
                  title={getCreationTitle({
                    activity,
                    user: creationToEvaluate.user,
                    index: selectedCreationToEvaluateIndex,
                  })}
                  submission={creationToEvaluate}
                  canFlagCreation
                />
              </StudentContent>,
              <Evaluation
                activity={activity}
                creation={creationToEvaluate}
                evaluation={evaluation}
                course={course}
                user={user}
                myStudent={myStudent}
                completedFOF={completedFOF}
              />,
              evaluation && evaluation.feedbackOnFeedback.motivational !== 0 ? (
                <StudentContent
                  anonymous={!isWithinGroupActivity(activity)}
                  user={creationToEvaluate.user}
                  score={StudentUtils.getNewKS(creationToEvaluate.student)}
                >
                  <Feedback
                    assignment={activity}
                    submission={creationToEvaluate}
                    evaluation={evaluation}
                  />
                </StudentContent>
              ) : null,
            ]}
          />
        </>
      ) : null}
    </div>
  );
}

const EvaluationInstructions = () => {
  return (
    <div data-testid="student-view-evaluator-notes">
      <h2 className="stage-instructions__title">
        {localize({
          message: 'Activity.StudentView.EvaluationInstructions.Title',
        })}
      </h2>
      <div className="instructions-card-grid-container">
        <div className="instructions-card-grid" data-testid="evaluation-cards">
          <InstructionsCard
            title={localize({
              message: 'Activity.Evaluation.StudentView.Tab.InstructionsCard1.Title',
            })}
            imageSrc={EVAL_IMAGE_1}
          >
            <p className="truncate-text">
              {localize({
                message: 'Activity.Evaluation.StudentView.Tab.InstructionsCard1.content',
              })}
            </p>
          </InstructionsCard>
          <InstructionsCard
            title={localize({
              message: 'Activity.Evaluation.StudentView.Tab.InstructionsCard2.Title',
            })}
            imageSrc={EVAL_IMAGE_2}
          >
            <p className="truncate-text">
              {localize({
                message: 'Activity.Evaluation.StudentView.Tab.InstructionsCard2.content',
              })}
            </p>
          </InstructionsCard>
          <InstructionsCard
            title={localize({
              message: 'Activity.Evaluation.StudentView.Tab.InstructionsCard3.Title',
            })}
            imageSrc={EVAL_IMAGE_3}
          >
            <p className="truncate-text">
              {localize({
                message: 'Activity.Evaluation.StudentView.Tab.InstructionsCard3.content',
              })}
            </p>
          </InstructionsCard>
        </div>
      </div>
    </div>
  );
};

const OverdueEvaluationNotice = ({
  evaluation,
  activity,
  completedFOF,
}: {
  evaluation: CreationScore;
  activity: Activity;
  completedFOF: number;
}) => {
  const { t } = useTranslation();
  const evaluateStage = getEvaluationStage(activity);

  const canSubmitLateEvaluation =
    !evaluation &&
    isFeedback({ assignment: activity }) &&
    activity.acceptLateEvaluations &&
    completedFOF === 0;

  if (canSubmitLateEvaluation) {
    return (
      <NoticeBoard type="pending" testid="late-evaluation-notice">
        <b>
          <TranslatedText
            i18nKey="Creation.Evaluation.StudentView.LateEvaluation.Status.Overdue"
            values={{
              overdueBy: FormatUtils.displayTimeBetweenDates(evaluateStage.endDate, new Date()),
            }}
          />
        </b>{' '}
        - {t('Creation.Evaluation.StudentView.LateEvaluation.Status.Overdue.CanStillSubmit')}
      </NoticeBoard>
    );
  }
  if (!evaluation.lateEvaluation) {
    return null;
  }
  return (
    <StudentLateSubmissionNoticeBoard
      type={
        evaluation.lateEvaluation.status === 'Accepted'
          ? 'accepted'
          : evaluation.lateEvaluation.status === 'Pending'
            ? 'pending'
            : 'rejected'
      }
      testid={
        evaluation.lateEvaluation.status === 'Accepted'
          ? 'accepted-late-evaluation-notice'
          : evaluation.lateEvaluation.status === 'Pending'
            ? 'pending-late-evaluation-notice'
            : 'rejected-late-evaluation-notice'
      }
      stageEndDate={evaluateStage.endDate}
      lateSubmission={{
        createdAt: evaluation.lateEvaluation.createdAt,
        reason: evaluation.lateEvaluation.reason,
        resolvedDate: evaluation.lateEvaluation.resolvedDate,
      }}
      submissionType="evaluation"
    />
  );
};

const Evaluation = ({ activity, creation, evaluation, course, user, myStudent, completedFOF }) => {
  const [editingEvaluation, setEditingEvaluation] = useState<any>(false);
  const [graceEvaluationModalOpen, setGraceEvaluationModalOpen] = useState<any>(false);
  const toggleEvaluationEditting = (status: any) => {
    setEditingEvaluation(status);
    setGraceEvaluationModalOpen(false);
  };
  const isEditingEvaluation = (evaluationId: any) => {
    return editingEvaluation === evaluationId;
  };

  const canSubmitLateEvaluation =
    !evaluation &&
    isFeedback({ assignment: activity }) &&
    activity.acceptLateEvaluations &&
    completedFOF === 0;

  const canEditLateEvaluation =
    isFeedback({ assignment: activity }) &&
    evaluation?.lateEvaluation?.status === 'Pending' &&
    activity.acceptLateEvaluations &&
    completedFOF === 0;

  const handleToggleEvaluationEditing = () => {
    const isEvaluationGracePeriod = isInEvaluationGracePeriod(activity);
    if (isEvaluationGracePeriod) {
      setGraceEvaluationModalOpen(true);
      return;
    }
    toggleEvaluationEditting(creation._id);
  };

  const evaluationGracePeriod = getEvaluationGracePeriodStage(activity);
  const timeLeft = FormatUtils.timeUntilDate(evaluationGracePeriod.endDate);

  if (canSubmitLateEvaluation) {
    return (
      <StudentContent user={user.authUser} score={StudentUtils.getNewKS(myStudent)}>
        <EvaluationEdit
          key={`evaluation_edit_${creation.user._id}`}
          assignment={activity}
          course={course}
          rubric={activity.rubric}
          submission={creation}
          submissionUser={creation.user._id}
          gradeOnly={isCalibrationActivity(activity)}
          userScore={evaluation}
          onSubmit={() => {
            return toggleEvaluationEditting(null);
          }}
          onCancel={
            evaluation
              ? () => {
                  return toggleEvaluationEditting(null);
                }
              : null
          }
          isOverdue
        />
      </StudentContent>
    );
  }
  if (
    (evaluation ||
      (!isProcessing1({ assignment: activity }) &&
        !isEvaluate({ assignment: activity }) &&
        !isProcessing2({ assignment: activity }))) &&
    !isEditingEvaluation(creation._id)
  ) {
    return (
      <StudentContent user={user.authUser} score={StudentUtils.getNewKS(myStudent)}>
        <GracePeriodWarning
          isOpen={graceEvaluationModalOpen}
          onConfirm={() => {
            return toggleEvaluationEditting(creation._id);
          }}
          onCancel={() => {
            return setGraceEvaluationModalOpen(false);
          }}
          timeLeft={timeLeft}
        />
        <EvaluationView
          assignment={activity}
          submission={creation}
          userScore={evaluation}
          showScoreLabel
        />
        {(isEvaluate({ assignment: activity }) ||
          isInEvaluationGracePeriod(activity) ||
          canEditLateEvaluation) && (
          <div className="evaluation-entry__btn-container">
            <Button onClick={handleToggleEvaluationEditing} type="primary" testid="edit-score">
              Edit Evaluation
            </Button>
          </div>
        )}
      </StudentContent>
    );
  }

  return (
    <StudentContent user={user.authUser} score={StudentUtils.getNewKS(myStudent)}>
      <GracePeriodWarning
        isOpen={graceEvaluationModalOpen}
        onConfirm={() => {
          return toggleEvaluationEditting(creation._id);
        }}
        onCancel={() => {
          return setGraceEvaluationModalOpen(false);
        }}
        timeLeft={timeLeft}
      />
      <EvaluationEdit
        key={`evaluation_edit_${creation.user._id}`}
        assignment={activity}
        course={course}
        rubric={activity.rubric}
        submission={creation}
        submissionUser={creation.user._id}
        gradeOnly={isCalibrationActivity(activity)}
        userScore={evaluation}
        onSubmit={() => {
          return toggleEvaluationEditting(null);
        }}
        onCancel={
          evaluation
            ? () => {
                return toggleEvaluationEditting(null);
              }
            : null
        }
        isOverdue={isFeedback({ assignment: activity })}
      />
    </StudentContent>
  );
};

const LateSubmissionOptions = ({
  activity,
  studentGroup,
  myCreation,
  waiveLateSubmissionRight,
  myStudent,
}) => {
  const handleWaiveLateSubmissionRight = () => {
    waiveLateSubmissionRight({
      creationId: myCreation._id,
      assignmentId: activity._id,
    });
  };

  const [lateSubmissionExemption, setLateSubmissionExemption] = useState(false);
  if ((isGroupAssignment(activity) || isWithinGroupActivity(activity)) && !studentGroup) {
    return <NoGroup />;
  }
  let lateSubmissionBanner = null;
  if (!isGroupAssignment(activity) || isGroupLeader(myStudent, myCreation.group)) {
    const creationNotSubmitted = (
      <NoticeBoard
        title="Submit your creation before starting your evaluations"
        testid="submit-creation-before-evaluations-notice"
      >
        <div>If you start your evaluations, you will no longer be able to submit a creation.</div>
        <Checkbox
          isChecked={lateSubmissionExemption}
          onChange={() => {
            return setLateSubmissionExemption(!lateSubmissionExemption);
          }}
          label="I will not submit a creation and want to start my evaluations"
          testid="confirm-skip-creation-to-evaluate"
        />
        <Button
          type="primary"
          disabled={!lateSubmissionExemption}
          unavailable={!lateSubmissionExemption}
          onClick={handleWaiveLateSubmissionRight}
          label={localize({ message: 'Button.Label.SkipCreateAndStartToEvaluate' })}
          data-testid="start-evaluations"
        >
          Start Evaluations
        </Button>
      </NoticeBoard>
    );
    const creationSubmitted = (
      <NoticeBoard
        title="Are you sure you want to start your evaluations?"
        testid="start-evaluations-notice"
      >
        <div>If you start your evaluations, you will no longer be able to edit your creation.</div>
        <Checkbox
          isChecked={lateSubmissionExemption}
          onChange={() => {
            return setLateSubmissionExemption(!lateSubmissionExemption);
          }}
          label={localize({ message: 'Activity.Evaluation.StudentView.ReadyToEvaluate' })}
          testid="confirm-start-evaluations"
        />
        <Button
          type="primary"
          disabled={!lateSubmissionExemption}
          unavailable={!lateSubmissionExemption}
          onClick={handleWaiveLateSubmissionRight}
          label={localize({ message: 'Button.Label.SkipCreateAndStartToEvaluate' })}
          testid="start-evaluations"
        >
          Start Evaluations
        </Button>
      </NoticeBoard>
    );
    lateSubmissionBanner = CreationStatusUtil.isCompleted(myCreation)
      ? creationSubmitted
      : creationNotSubmitted;
  }
  if (!lateSubmissionBanner) {
    lateSubmissionBanner = (
      <NoticeBoard
        title="You cannot start evaluations until your creation is submitted"
        data-testid={'notice-cannot-start-evaluations'}
      >
        <div>Co-ordinate with your group leader to submit your creation.</div>
      </NoticeBoard>
    );
  }
  return (
    <>
      {!CreationStatusUtil.isCompleted(myCreation) && (
        <LateSubmissionAvailability activity={activity} />
      )}
      <EvaluationInstructions />
      {lateSubmissionBanner}
    </>
  );
};

const mapStateToProps = (state: any) => {
  const myStudent = getStudentFromUser(state, state.selected.courseId);
  const activityId = state.selected.assignmentId;
  const studentGroup = getGroupOfStudent(state, activityId, myStudent._id);
  return {
    entities: state.entities,
    user: state.user,
    myStudent,
    course: getCourse(state),
    activity: getAssignment(state, activityId),
    myCreation: getMySubmission(state, activityId),
    groups: getGroupList(state),
    loadingBar: state.loadingBar,
    gradeHistories: selectGradeHistories(state),
    studentGroup,
  };
};

export default connect(mapStateToProps, { waiveLateSubmissionRight, getAssignmentSubmissions })(
  StudentView
);
