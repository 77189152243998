import React from 'react';
import Icon from './Icon';

export default function createSvgIcon(path: any) {
  const Component = (props: any, ref: any) => (
    <Icon component="i" ref={ref} className={path} {...props} width="auto" height="auto" />
  );

  return React.memo(React.forwardRef(Component));
}
