import { createRestApiClient } from 'utils/createRestApiClient';
const apiEndpoint = import.meta.env.VITE_API_URL;

export default (options = {}) => {
  const client =
    (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint });
  return {
    list: () => {
      return client.request({
        method: 'GET',
        url: '/institutions',
      });
    },
  };
};
