import React, { useState } from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from 'components/core/form/SelectField';
import Popover from '@material-ui/core/Popover';
import TextInput from 'components/core/form/TextField';
import NumberInput from 'components/core/form/NumberField';
import Button from 'components/buttons/Button';
import { Divider } from '@material-ui/core';
import TableUtils from 'components/LmsGradeSync/LmsTable/ActivityTable/utils';
import CourseSelectors from 'selectors/course';
import { Plus } from 'images/icons/fa';
import { useParams } from 'react-router';
import { useGetCachedCourse } from 'hooks/course';

function ColumnSelector(props: any) {
  const { courseId } = useParams();
  const course = useGetCachedCourse(courseId);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isCreatingNewCol, setIsCreatingNewCol] = useState(false);
  const [newColLabel, setNewColLabel] = useState('');
  const [newMaxScore, setNewMaxScore] = useState(course.markingScheme.isPercentage ? 100 : 1);
  const [newColError, setNewColError] = useState('');

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectColumn = ({ lineItem, activityId }: any) => {
    props.setSelectedColumn({ lineItem, activityId, isNewColumn: false });
    handleClose();
  };

  const handleSelectNewCol = () => {
    if (newColLabel === '') {
      return setNewColError('Must have a name');
    }
    const lineItem = {
      id: '',
      label: newColLabel,
      scoreMaximum: newMaxScore,
      isNewColumn: true,
    };
    props.setSelectedColumn({
      lineItem,
      activityId: props.activityId,
      isNewColumn: true,
    });
    setNewColError('');
    handleClose();
  };

  const changeNewColLabel = (e: any) => {
    if (newColError) {
      setNewColError('');
    }
    setNewColLabel(e.target.value);
  };

  const getSelectionOptions = () => {
    return props.lineItems.map((lineItem: any) => {
      return (
        <MenuItem
          key={lineItem.id}
          value={lineItem.id}
          onClick={() => {
            return handleSelectColumn({ lineItem, activityId: props.activityId });
          }}
        >
          {lineItem.label}
        </MenuItem>
      );
    });
  };

  const selectColumnMenu = () => {
    return (
      <React.Fragment>
        <div
          className="select-col-create-btn"
          onClick={() => {
            return setIsCreatingNewCol(true);
          }}
          data-testid="create-new-column"
        >
          <i className="fas fa-plus" /> Create a new column
        </div>
        {props.lineItems.length > 0 && (
          <React.Fragment>
            <Divider />
            {getSelectionOptions()}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const createColumnMenu = () => {
    return (
      <div className="create-col-menu">
        <div
          className="create-col-menu__back-btn"
          onClick={() => {
            return setIsCreatingNewCol(false);
          }}
        >
          <i className="fas fa-chevron-left" /> Back
        </div>
        <Divider />
        <React.Fragment>
          <TextInput
            label="Column Name"
            value={newColLabel}
            onChange={changeNewColLabel}
            error={newColError}
            testid="column-name"
          />
          <NumberInput
            label="Points"
            value={newMaxScore}
            min={1}
            onChange={(e: any) => {
              return setNewMaxScore(e.target.value);
            }}
            maxDecimals={2}
          />
          <Button
            type="primary"
            onClick={() => {
              return handleSelectNewCol();
            }}
            testid="confirm-create-column"
          >
            Done
          </Button>
        </React.Fragment>
      </div>
    );
  };

  const getSelectValue = () => {
    if (props.selectedColumn) {
      if (props.selectedColumn.id === '') {
        return 'state';
      }
      return props.selectedColumn.id;
    }
    return null;
  };

  const getNewColumnLabel = (selectedColumn: any) => {
    if (selectedColumn.isNewColumn) {
      return (
        <>
          <Plus />
          <span className="column-selector__label">{selectedColumn.label}</span>
        </>
      );
    }
    return selectedColumn.label;
  };

  const getSelectOptions = () => {
    const options = [];
    if (props.selectedColumn) {
      options.push({
        value: getSelectValue(),
        label: getNewColumnLabel(props.selectedColumn),
      });
    }
    options.push({
      value: null,
      label: 'Choose a column',
      testid: 'choose-column',
    });
    return options;
  };

  return (
    <div className="column-selector">
      <Select
        onOpen={handleClick}
        open={false}
        value={getSelectValue()}
        options={getSelectOptions()}
        placeholder="Choose a column"
      />
      <Popover anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <div className="column-selector__menu">
          {isCreatingNewCol ? createColumnMenu() : selectColumnMenu()}
        </div>
      </Popover>
    </div>
  );
}

function mapStateToProps(state: any, ownProps: any) {
  const course = CourseSelectors.getCourse(state);
  return {
    lineItems: TableUtils.mapLineItems(ownProps.lmsColumns || []),
    course,
  };
}

export default connect(mapStateToProps)(ColumnSelector);
