import { connect } from 'react-redux';
import { getAssignment as getActivity } from 'selectors/activity';
import { getCourse } from 'selectors/course';
import { Field } from 'react-final-form';
import validators from 'components/ActivityEdit/Details/Fields/validators';
import FormFieldTitle from 'components/core/form/Title';
import FormFieldInput from 'components/core/form/FieldInput';
import NumberInput from 'components/core/input/Number';
import { localize } from 'locales/index';
import { TranslatedText } from 'components/TranslatedText';
import InfoPopup from 'components/core/InfoPopup';
import FormInfo from 'components/core/form/FieldInfo';
import { Activity, Course } from '@kritik/types.generated';
import { isEvaluateOrLater } from '@kritik/utils/stage';

type Props = {
  course: Course;
  activity: Activity;
  isDuplicatingActivity: boolean;
};
function StartingScore(props: Props) {
  const FIELD_NAME = 'startingScore';

  return (
    <Field
      name={FIELD_NAME}
      validate={validators.composeValidators(
        validators.isRequired,
        validators.minValue(0),
        validators.maxValue(99)
      )}
    >
      {({ input }) => {
        return (
          <>
            <FormFieldTitle label={localize({ message: 'Activity.StartingScoreField.Label' })}>
              <InfoPopup
                popoverContentClass="course-scoring-starting-score-popup"
                title={localize({
                  message: 'Course.Scoring.Edit.DefaultStartingScore.InfoPopup.Label',
                })}
                description={
                  <TranslatedText i18nKey="Course.Scoring.Edit.DefaultStartingScore.InfoPopup.Description" />
                }
                learnMoreUrl={localize({ message: 'Activity.StartingScore.Link' })}
              />
            </FormFieldTitle>
            <FormFieldInput>
              <NumberInput
                className="not-bold"
                name={FIELD_NAME}
                value={input.value}
                onChange={(e: any) => {
                  return input.onChange(Number.parseInt(e.target.value, 10));
                }}
                disabled={isEvaluateOrLater(props.activity) && !props.isDuplicatingActivity}
                min={0}
                max={99}
                testid="starting-score-input"
              />
              <span className="input_unit">%</span>
              {props.course && (
                <FormInfo
                  label={
                    <>
                      <TranslatedText
                        i18nKey="Activity.StartingScoreField.HelpText"
                        values={{
                          default: props.course.startingScore.toString(),
                        }}
                      />
                      <a
                        target="_blank"
                        href={`/course/${props.course._id.toString()}/score/edit`}
                        className="underlined-link"
                      >
                        <TranslatedText i18nKey="Activity.Edit.CourseSettings" />
                      </a>
                      .
                    </>
                  }
                />
              )}
            </FormFieldInput>
          </>
        );
      }}
    </Field>
  );
}

const mapStateToProps = (state: any) => {
  return {
    activity: getActivity(state, state.selected.assignmentId),
    course: getCourse(state, state.selected.courseId),
  };
};

export default connect(mapStateToProps, {})(StartingScore);
