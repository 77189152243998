import classNames from 'classnames';

const ButtonContainer = ({ className, align, children }: any) => {
  const componentClasses = classNames(
    'component-button-container',
    {
      'component-button-container__right': align === 'right',
    },
    className
  );
  return <div className={componentClasses}>{children}</div>;
};

export default ButtonContainer;
