import React from 'react';
import { Avatar, Card, CardContent, Typography } from '@material-ui/core';
import classNames from 'classnames';

type MaintenancePageTemplateProps = {
  icon: string;
  iconVariant: 'yellow';
  title: string;
  descriptions: string[];
};

const MaintenancePageTemplate = ({
  icon,
  iconVariant = null,
  title,
  descriptions,
}: MaintenancePageTemplateProps) => {
  return (
    <>
      <div className="icon-container">
        <Avatar
          className={classNames('avatar-icon', {
            'yellow-icon': iconVariant === 'yellow',
          })}
        >
          {icon}
        </Avatar>
      </div>
      <Card className="error-container">
        <CardContent>
          <Typography variant="h4" component="h4">
            <b>{title}</b>
          </Typography>
          <div className="error-section-margin">
            {descriptions.map((description) => (
              <Typography variant="h6" component="p" className="error-text-margin">
                {description}
              </Typography>
            ))}
          </div>
        </CardContent>
      </Card>
    </>
  );
};
export default MaintenancePageTemplate;
