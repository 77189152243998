import { useUserRoleInCourse } from 'hooks/course';

export function withUserRole(Component) {
  function ComponentWithRouterProp(props) {
    const userRole = useUserRoleInCourse();

    return <Component {...props} userRole={userRole} />;
  }

  return ComponentWithRouterProp;
}
