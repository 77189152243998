import Spinner from 'components/Loaders/Spinner';
import { TranslatedText } from 'components/TranslatedText';

function StudentQuotes() {
  const generationQuotes = [
    <TranslatedText i18nKey="InformativeLoadingScreen.StudentQuotes.Quote1" />,
    <TranslatedText i18nKey="InformativeLoadingScreen.StudentQuotes.Quote2" />,
  ];

  return (
    <>
      <span className="fa-stack fa-3x">
        <i className="fas fa-circle fa-stack-2x icon-purple"></i>
        <i className="fas fa-quote-left fa-stack-1x fa-inverse"></i>
      </span>

      <h3>
        <TranslatedText i18nKey="InformativeLoadingScreen.StudentQuotes.Heading" />
      </h3>
      {generationQuotes.map((quote, index) => {
        return (
          <p key={index} className="informative-screen__quote">
            “{quote}”
          </p>
        );
      })}
    </>
  );
}

function StudentImprovements() {
  return (
    <>
      <span className="fa-stack fa-3x">
        <i className="fas fa-circle fa-stack-2x icon-purple"></i>
        <i className={`fas fa-list fa-stack-1x fa-inverse`}></i>
      </span>

      <h3>
        <TranslatedText i18nKey="InformativeLoadingScreen.StudentImprovement.Quote1.Header" />
      </h3>
      <p>
        <TranslatedText i18nKey="InformativeLoadingScreen.StudentImprovement.Quote1.Text" />
      </p>
      <h3>
        <TranslatedText i18nKey="InformativeLoadingScreen.StudentImprovement.Quote2.Header" />
      </h3>
      <p>
        <TranslatedText i18nKey="InformativeLoadingScreen.StudentImprovement.Quote2.Text" />
      </p>
    </>
  );
}

function CreateCourseWhatsNext() {
  return (
    <>
      <span className="fa-stack fa-3x">
        <i className="fas fa-circle fa-stack-2x icon-purple"></i>
        <i className={`fas fa-list-ul fa-stack-1x fa-inverse`}></i>
      </span>

      <h3>
        <TranslatedText i18nKey="InformativeLoadingScreen.CreateCourseWhatsNext.Header" />
      </h3>
      <ul className="loading-whats-next">
        <li>
          <TranslatedText i18nKey="InformativeLoadingScreen.CreateCourseWhatsNext.BulletPoint1" />
        </li>
        <li>
          <TranslatedText i18nKey="InformativeLoadingScreen.CreateCourseWhatsNext.BulletPoint2" />
        </li>
        <li>
          <TranslatedText i18nKey="InformativeLoadingScreen.CreateCourseWhatsNext.BulletPoint3" />
        </li>
      </ul>
    </>
  );
}

type Props = {
  loadingText: string;
  left: 'student_quotes' | 'student_improvements' | 'create_course_whats_next';
  right: 'student_quotes' | 'student_improvements' | 'create_course_whats_next';
};
function InformativeLoadingScreen(props: Props) {
  return (
    <div className="informative-screen">
      <Spinner />
      <p>{props.loadingText}</p>

      <div className="informative-screen-grid">
        <div>
          {props.left === 'student_quotes' && <StudentQuotes />}
          {props.left === 'student_improvements' && <StudentImprovements />}
          {props.left === 'create_course_whats_next' && <CreateCourseWhatsNext />}
        </div>

        <div>
          {props.right === 'student_quotes' && <StudentQuotes />}
          {props.right === 'student_improvements' && <StudentImprovements />}
          {props.right === 'create_course_whats_next' && <CreateCourseWhatsNext />}
        </div>
      </div>
    </div>
  );
}

export default InformativeLoadingScreen;
