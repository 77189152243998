import { FILTERS } from 'components/CreationList/constant';
import types from './types';

const initialState = {
  columns: null,
  listBy: '',
  type: '',
  filter: FILTERS.SHOW_ALL,
  search: '',
  isShowEvalsCompleted: true,
  isShowFeedbackCompleted: true,
  activityId: null,
  currentPage: 1,
  pageSize: 50,
  totalRowsCount: 0,
  pageCount: 1,
  isLoading: false,
};

function creationTableReducer(state = initialState, action: any) {
  switch (action.type) {
    case types.SET_INITIAL_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_CREATION_TABLE_COLUMNS:
      return {
        ...state,
        columns: action.payload,
      };
    case types.SET_CREATION_TABLE_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case types.SET_CREATION_TABLE_LIST_BY:
      return {
        ...state,
        listBy: action.payload,
      };
    case types.SET_CREATION_TABLE_FILTER:
      return {
        ...state,
        filter: action.payload.filter,
      };
    case types.SET_CREATION_TABLE_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case types.SET_IS_SHOW_EVALS_COMPLETED:
      return {
        ...state,
        isShowEvalsCompleted: action.payload,
      };
    case types.SET_IS_SHOW_FEEDBACK_COMPLETED:
      return {
        ...state,
        isShowFeedbackCompleted: !state.isShowFeedbackCompleted,
      };
    case types.SET_CREATION_TABLE_ACTIVITY:
      return {
        ...state,
        activityId: action.payload,
      };
    case types.SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case types.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case types.SET_TOTAL_ROWS_COUNT:
      return {
        ...state,
        totalRowsCount: action.payload,
      };
    case types.SET_PAGE_COUNT:
      return {
        ...state,
        pageCount: action.payload,
      };
    case types.SET_CREATION_TABLE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}

export default creationTableReducer;
