import React from 'react';
import linkifyHtml from 'linkifyjs/html';
import parse from 'html-react-parser';

const HtmlContent = ({ content, noFollow = true, ...custom }: any) => {
  if (!content) {
    return null;
  }

  content = linkifyHtml(content, {
    // attributes: {
    //  rel: 'nofollow noopener'
    // },
    // target: {
    //  url: '_blank'
    // }
  });

  if (content.indexOf('$$') != -1) {
    content = content.replace(/\$\$\$([^\$]+)\$\$\$/g, '<span class="block-math">$1</span>');
    content = content.replace(/\$\$([^\$]+)\$\$/g, '<span class="inline-math">$1</span>');
  }

  const transform = (node: any) => {
    let convert = false;

    if (node.type == 'tag' && node.children && node.children.length) {
      if (['ul', 'ol'].indexOf(node.name) == -1) {
        let n = node;
        while (n.children && n.children.length) {
          n = n.children[0];
        }
        if (n.type == 'text' && n.data && n.data.match && n.data.match(/^[\s0-9]*[a-zA-Z]/)) {
          node.attribs.class = node.attribs.class ? `${node.attribs.class} ltr` : 'ltr';
          convert = true;
        }
      }
    }

    if (node.type == 'tag' && node.name === 'a') {
      node.attribs.rel = 'nofollow noopenner';
      node.attribs.target = '_blank';
      convert = true;
    } else if (node.name == 'table') {
      node.attribs.class = node.attribs.class
        ? `${node.attribs.class} ui celled table`
        : 'ui celled table';
    }
  };

  return (
    <div className="htmlcontent-container ql-editor" {...custom}>
      {parse(content, { replace: transform })}
    </div>
  );
};

export default HtmlContent;
