import classNames from 'classnames';

const FormFieldInput = ({ error, warning, children, className }: any) => {
  const classes = classNames(
    'kritik-form-field__input',
    {
      'kritik-form-field__input--warning': warning,
      'kritik-form-field__input--error': error,
    },
    className
  );
  return <div className={classes}>{children}</div>;
};

FormFieldInput.defaultProps = {
  error: null,
  warning: false,
  className: '',
};

export default FormFieldInput;
