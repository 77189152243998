import get from 'lodash/get';

export default (type: any, err: any, msg = 'Something went wrong, please try again') => {
  const errMessage =
    get(err, 'response.data.errors.message') ||
    get(err, 'response.data.error.message') ||
    get(err, 'response.data.message') ||
    msg;

  return {
    type,
    error: errMessage,
    payload: err,
  };
};
