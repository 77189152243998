import React from 'react';
import createSvgIcon from '../util/generateIconSVG';

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M0 13.5C0 6.04416 6.04416 0 13.5 0H29.5C36.9558 0 43 6.04416 43 13.5C43 20.9558 36.9558 27 29.5 27H13.5C6.04416 27 0 20.9558 0 13.5Z"
      fill="#DCDBF0"
    />
    <path
      d="M16.25 13.625C18.752 13.625 20.8438 11.5742 20.8438 9.03125C20.8438 6.5293 18.752 4.4375 16.25 4.4375C13.707 4.4375 11.6562 6.5293 11.6562 9.03125C11.6562 11.5742 13.707 13.625 16.25 13.625ZM19.3672 14.9375H19.0391C18.1777 15.3477 17.2344 15.5938 16.25 15.5938C15.2246 15.5938 14.2812 15.3477 13.4199 14.9375H13.0918C10.4668 14.9375 8.375 17.0703 8.375 19.6953V20.8438C8.375 21.9512 9.23633 22.8125 10.3438 22.8125H22.1562C23.2227 22.8125 24.125 21.9512 24.125 20.8438V19.6953C24.125 17.0703 21.9922 14.9375 19.3672 14.9375ZM28.0625 13.625C30.2363 13.625 32 11.8613 32 9.6875C32 7.51367 30.2363 5.75 28.0625 5.75C25.8887 5.75 24.125 7.51367 24.125 9.6875C24.125 11.8613 25.8887 13.625 28.0625 13.625ZM30.0312 14.9375H29.8672C29.293 15.1426 28.6777 15.2656 28.0625 15.2656C27.4062 15.2656 26.791 15.1426 26.2168 14.9375H26.0938C25.2324 14.9375 24.4531 15.1836 23.7969 15.5938C24.7812 16.6602 25.4375 18.0957 25.4375 19.6953V21.2539C25.4375 21.3359 25.3965 21.418 25.3965 21.5H32.6562C33.7227 21.5 34.625 20.6387 34.625 19.5312C34.625 17.0293 32.5332 14.9375 30.0312 14.9375Z"
      fill="#302D6C"
    />
  </React.Fragment>,
  '0 0 43 27'
);
