import { useId } from 'react';
import { FormHelperText, InputLabel, Switch, SwitchProps } from '@material-ui/core';
import InfoPopup from 'components/core/InfoPopup';

type FormInputSwitchProps = {
  checked: boolean;
  label: string;
  helpText?: string;
  name: string;
  onChange: SwitchProps['onChange'];
  labelPopup?: {
    title: JSX.Element;
    description: JSX.Element;
  };
  disabled?: boolean;
  testId?: string;
  content?: JSX.Element | string;
};

export default function FormInputSwitch(props: FormInputSwitchProps) {
  const id = useId();

  return (
    <div className="kritik-form-switch">
      <div className="kritik-form-switch__container">
        <Switch
          className="kritik-form-switch__input"
          inputProps={{ role: 'switch' }}
          id={id}
          checked={props.checked}
          name={props.name}
          aria-describedby={props.helpText ? `${props.name}-helper` : undefined}
          onChange={props.onChange}
          disabled={props.disabled}
          data-testid={props.testId}
        />
      </div>
      <div className="kritik-form-switch__label-container">
        <div className="kritik-form-switch__label">
          <InputLabel htmlFor={id}>{props.label}</InputLabel>
          {props.labelPopup && (
            <InfoPopup title={props.labelPopup.title} description={props.labelPopup.description} />
          )}
        </div>
        {props.helpText && (
          <FormHelperText id={`${props.name}-helper`}>{props.helpText}</FormHelperText>
        )}
        {props.content && <div className="kritik-form-switch__content">{props.content}</div>}
      </div>
    </div>
  );
}
