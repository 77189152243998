import * as _ from 'lodash-es';

type SanitizeInputValueParams = {
  value: string;
  max: number;
  min: number;
  maxDecimals: number;
};

export function sanitizeInputValue({
  value,
  max,
  min,
  maxDecimals,
}: SanitizeInputValueParams): string {
  let sanitized = value;

  const splittedValue = sanitized.split('.');
  if (splittedValue[splittedValue.length - 1] === '0') {
    return sanitized;
  }
  if (typeof max === 'number') {
    sanitized = Math.min(Number(sanitized), max).toString();
  }
  if (typeof min === 'number') {
    sanitized = Math.max(Number(sanitized), min).toString();
  }
  if (typeof maxDecimals === 'number') {
    sanitized = _.round(Number(sanitized), maxDecimals).toString();
  }
  return sanitized;
}
