import FlagIcon from '@material-ui/icons/Flag';
import InfoLabel from 'components/layout/InfoLabel';
import { Ban, Check } from 'images/icons/fa';
import {
  isCommentFlagDismissed,
  isCommentFlagged,
  isCommentRemoved,
  isCommentNotFlagged,
} from '@kritik/utils/creation/status';
import { localize } from 'locales/index';
import { EvaluationScoreUtil } from '@kritik/utils/grade';

export const EvaluationStatusLabel = ({ evaluation }) => {
  if (isCommentFlagDismissed(evaluation)) {
    return (
      <InfoLabel
        type="success"
        label={
          <div className="evaluation-status-label">
            <Check className="evaluation-status-icon" />{' '}
            {localize({ message: 'Creation.Evaluation.CommentFlag.Resolved' })}
          </div>
        }
      />
    );
  }

  if (isCommentRemoved(evaluation)) {
    return (
      <InfoLabel
        type="danger"
        label={
          <div className="evaluation-status-label">
            <Ban className="evaluation-status-icon" />{' '}
            {localize({ message: 'Creation.Evaluation.CommentFlag.RemovedInappropriate' })}
          </div>
        }
      />
    );
  }

  if (EvaluationScoreUtil.isEvaluationRemoved(evaluation) && isCommentNotFlagged(evaluation)) {
    return (
      <InfoLabel
        type="caution"
        label={
          <div className="evaluation-status-label">
            <Ban className="evaluation-status-icon" />{' '}
            {localize({ message: 'Creation.Evaluation.CommentFlag.Removed' })}
          </div>
        }
      />
    );
  }

  if (isCommentFlagged(evaluation)) {
    return (
      <InfoLabel
        testid="reported-as-inappropriate"
        type="caution"
        label={
          <div className="evaluation-status-label">
            <FlagIcon fontSize="inherit" />{' '}
            {localize({ message: 'Creation.Evaluation.CommentFlag.ReportedAsInappropriate' })}
          </div>
        }
      />
    );
  }
  return null;
};
