import * as _ from 'lodash-es';
import {
  getPercentEvaluationsCompleted,
  getNumEvaluationsToDo,
  getNumEvaluationRemaining,
  getNumEvaluationsToBeReceived,
  getPercentEvaluationsReceived,
} from '@kritik/utils/creation/evaluation';
import {
  isCreate,
  isEvaluate,
  isFeedback,
  isProcessing1,
  isGrading,
  isFinalized,
} from '@kritik/utils/stage';

export const sortByEvaluate = (
  itemList: any,
  ascending: any,
  activity: any,
  tableSettings: any
) => {
  /**
   * All are sorted by the percentage evals completed
   */
  let sortedItemList = [];

  if (isCreate({ assignment: activity })) {
    sortedItemList = sortInCreateStage(itemList);
  } else if (isProcessing1({ assignment: activity })) {
    sortedItemList = sortInGracePeriod(itemList);
  } else if (isEvaluate({ assignment: activity })) {
    sortedItemList = sortInEvaluateStage(itemList, ascending, tableSettings);
  } else if (isFeedback({ assignment: activity })) {
    sortedItemList = sortInFeedbackStage(itemList, ascending, tableSettings);
  } else if (isGrading({ assignment: activity })) {
    sortedItemList = sortInGradingStage(itemList, ascending, tableSettings);
  } else if (isFinalized({ assignment: activity })) {
    sortedItemList = sortInFinalizedStage(itemList, ascending, tableSettings);
  }
  return sortedItemList;
};

const getCreation = (item: any) => {
  return item.creation;
};

const getStudent = (item: any) => {
  return item.student;
};

const sortInCreateStage = (itemList: any) => {
  return itemList;
};

const sortInGracePeriod = (itemList: any) => {
  return sortInCreateStage(itemList);
};

const sortInEvaluateStage = (itemList: any, ascending: any, tableSettings: any) => {
  const order = ascending ? 'asc' : 'desc';
  const sortByEvalsReceived = !tableSettings.isShowEvalsCompleted;
  return _.orderBy(
    itemList,
    [
      function (item) {
        const creation = getCreation(item);
        const student = getStudent(item);
        return sortByEvalsReceived
          ? getPercentEvaluationsReceived(creation)
          : getPercentEvaluationsCompleted(creation, student._id);
      },
      function (item) {
        const creation = getCreation(item);
        const student = getStudent(item);
        return sortByEvalsReceived
          ? getNumEvaluationsToBeReceived(creation)
          : getNumEvaluationsToDo(creation, student._id);
      },
    ],
    [order, order]
  );
};

const sortInFeedbackStage = (itemList: any, ascending: any, tableSettings: any) => {
  const order = ascending ? 'asc' : 'desc';
  const sortByEvalsReceived = !tableSettings.isShowEvalsCompleted;
  return _.orderBy(
    itemList,
    [
      function (item) {
        const creation = getCreation(item);
        const student = getStudent(item);
        return sortByEvalsReceived
          ? getPercentEvaluationsReceived(creation)
          : getNumEvaluationsToDo(creation, student._id) !== 0;
      },
      // missed count
      function (item) {
        const creation = getCreation(item);
        const student = getStudent(item);
        return sortByEvalsReceived
          ? getNumEvaluationsToBeReceived(creation)
          : getNumEvaluationRemaining(creation, student._id);
      },
    ],
    [order, order]
  );
};

const sortInGradingStage = (itemList: any, ascending: any, tableSettings: any) => {
  return sortInFeedbackStage(itemList, ascending, tableSettings);
};

const sortInFinalizedStage = (itemList: any, ascending: any, tableSettings: any) => {
  return sortInFeedbackStage(itemList, ascending, tableSettings);
};
