import FormTextInput from 'components/core/form/TextField';
import { Field } from 'react-final-form';
import { COURSE_FIELD_NAMES } from 'components/Course/Edit/constants';
import { localize } from 'locales/index';

const CourseSectionField = () => {
  return (
    <Field name={COURSE_FIELD_NAMES.SECTION} type="text">
      {({ input, meta }) => {
        return (
          <div className="course-details__section-field">
            <FormTextInput
              label="Course Section"
              value={input.value}
              placeholder={localize({ message: 'Course.Create.CourseSectionField.Placeholder' })}
              onChange={input.onChange}
              testid="course-section"
              id="course-section"
            />
          </div>
        );
      }}
    </Field>
  );
};
export default CourseSectionField;
