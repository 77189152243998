export const assignmentColor = {
  Draft: 'blue',
  Create: 'green',
  Evaluate: 'yellow',
  Feedback: 'grey',
  Finalized: 'purple',
};

// Colors: FFC400 | FF4453 | 41C560 | 0077FF | DEF6FE

const passLevel = '0';
const passCriteriaRule = 'any';
const levels = ['Novice', 'Intermediate', 'Proficient', 'Advanced'];

export const activityTemplates = {
  question: {
    type: 'question',
    objective:
      'Example Objective: To gain learning curiosity toward a particular topic by creating questions.',
    instructions:
      'Example Instruction: Read section 1.1 through 1.3 of your textbook. Think of a good question that can be answered by reading AND fully comprehending the content of these sections. The question should evoke higher order thinking within the context of the materials. The rubric provided below will be used to assess your score.',
    rubric: {
      levels,
      criteria: [
        {
          name: 'Rich Information and Context',
          weight: 1,
        },
        {
          name: 'Complexity',
          weight: 1,
        },
        {
          name: 'Scope',
          weight: 1,
        },
        {
          name: 'Relevance',
          weight: 1,
        },
        {
          name: 'Creativity',
          weight: 1,
        },
      ],
      grid: [
        [
          'The question demonstrates no comprehension of the subject',
          'The question is based on weak understanding of the subject',
          'The question is based on the comprehension of most parts of the subject',
          'The question demonstrates rich and deep comprehension of the context',
        ],
        [
          'Basic and simple question with incorrect structure',
          'The question is general ',
          'The question is complicated but vaguely supported',
          'The question is complex but well supported ',
        ],
        [
          'Question is general and simple',
          'Question does not allow for exploration of the subject',
          'Question allows for exploration of some parts of the subject',
          'Question manipulates exploration of the subject in more depth',
        ],
        [
          'Not related to the subject',
          'Question is not explicitly relevant to the topic',
          'Question is relevant to a specific part of the topic',
          'The question is explicitly relevant to the topic',
        ],
        [
          'Question lacks creativity and innovation',
          'Question is general with minimum creativity',
          'Question manipulates some new perspective',
          'Unique and original question that manipulates a new perspective',
        ],
      ],
      passLevel,
      passCriteriaRule,
    },
  },
  essay: {
    type: 'essay',
    objective: 'Example Objective: To develop writing and analytical skills.',
    instructions:
      'Example Instruction: Read section 1.1 through 1.3 of your textbook. Think of a controversial topic related to the content covered in these sections and pick a position. Write a short persuasive essay(200- 250 words), and build a case using facts and logic, as well as examples, expert opinion, and sound reasoning, why a certain position is correct. The rubric below will be used to assess your score.',
    rubric: {
      levels,
      criteria: [
        {
          name: 'Clarity of Thoughts',
          weight: 1,
        },
        {
          name: 'Accuracy',
          weight: 1,
        },
        {
          name: 'Creative and Critical Thinking',
          weight: 1,
        },
        {
          name: 'Source and evidence',
          weight: 1,
        },
      ],
      grid: [
        [
          'Central idea and clarity of purpose absent or incompletely maintained',
          'Central idea expressed vaguely. Minimal sense of purpose present',
          'Central idea and clarity of purpose generally evident',
          'Well developed central idea. Clarity of purpose demonstrated throughout the essay',
        ],
        [
          'Incorrect and unsupported information',
          'Important information left out. Disreputable opinions ',
          'Few inaccurate facts. Information based on evidence',
          'All the information and facts are accurate and based on evidence',
        ],
        [
          'Position was simplistic and obvious',
          'Displayed a solid understanding of the topic. Failed to address challenging thoughts',
          'Sharpened the focus, sorted ideas for relevance, focused on key points',
          'Original concepts synthesized. Honoured multiple perspectives and made thoughtful connections',
        ],
        [
          'Lacks citation. Incorrect and disreputable evidence',
          'Some sources are missing',
          'Sources are cited correctly and some are used critically',
          'Sources are cited consistent stye and are used critically',
        ],
      ],
      passLevel,
      passCriteriaRule,
    },
  },
  peerContent: {
    type: 'peerContent',
    objective:
      'Example Objective: To enhance comprehension and memorization of the course materials through teaching peers.',
    instructions:
      "Example Instruction: Read section 1.1 through 1.3 of your textbook or the slides used by your instructor to teach them in the class. Think of a better way you can teach the same concept(s) to your peers. Feel free to use visual aids and refer to any known pop culture, characters or external examples known among your peers to convey the message or make a point clear or make it stick to peers's memory for longer. The rubric will be used to assess your score.",
    rubric: {
      levels,
      criteria: [
        {
          name: 'Organization',
          weight: 1,
        },
        {
          name: 'Relevance',
          weight: 1,
        },
        {
          name: 'Clarity',
          weight: 1,
        },
        {
          name: 'Knowledge of Content',
          weight: 1,
        },
      ],
      grid: [
        [
          'No clear sequence',
          'Material explained/presented in a confusing manner',
          'Material explained in a logical manner',
          'Material explained in a logical, sophisticated, and engaging manner',
        ],
        [
          'Material is not related to the subject',
          'Material is not directly relevant to the subject',
          'Material only explains select parts of the subject',
          'Composed material is explicitly relevant to the subject',
        ],
        [
          'Material is confusing and not understandable',
          'Material composition is confusing at most parts',
          'Material composed in a clear way with few distracting parts.',
          'Material composed very clearly and easy to understand',
        ],
        [
          'Displays little/no understanding of the concept',
          'Displays some understanding of the concepts. Leaves important information out',
          'Displays sufficient understanding of the concept',
          'Displays thorough understanding of content. Extra research is evident',
        ],
      ],
      passLevel,
      passCriteriaRule,
    },
  },
  multimedia: {
    type: 'multimedia',
    objective:
      'Example Objective: To develop creativity and presentation skills through making or adopting multimedia and visual modules.',
    instructions:
      "Example instruction: How can the attached text be presented more creatively? Augment the text with an illustration, infographic item, summary table, short video, animation, or anything that will help convey the message faster or make it more engaging than the plain text. You can either use your creative skills to build one, or find an open-source content online. Make sure to cite if you used others' creation. The rubric will be used to assess your activity.",
    rubric: {
      levels,
      criteria: [
        {
          name: 'Organization',
          weight: 1,
        },
        {
          name: 'Knowledge',
          weight: 1,
        },
        {
          name: 'Text and Readability',
          weight: 1,
        },
        {
          name: 'Creativity',
          weight: 1,
        },
        {
          name: 'Visual Aid',
          weight: 1,
        },
      ],
      grid: [
        [
          'No clear sequence of presentation',
          'The sequence of presentation is difficult for the audience to follow',
          'Presentation follows a logical sequence',
          'Logical, engaging and sophisticated sequence of presentation',
        ],
        [
          'No understanding of information. Incorrect and unsupported information',
          'Inaccurate information presented. Important information left out',
          'Accurate information presented comfortably',
          'Thorough and deep understanding of the topic. Information presented accurately',
        ],
        [
          'Poor readability and incorrect use of fonts',
          'Difficult to read. Inappropriate use of fonts',
          'Good readability with some distraction due to font selection',
          'Carefully planned font format which enhances readability',
        ],
        [
          'Repetitive delivery with no originality in presentation techniques',
          'Material presented with little originality or interpretation',
          'Some original material and interpretation',
          'Excepionally original material and interpretation',
        ],
        [
          'Does not connect to speech. Disorganized and basic.',
          'Basic and somewhat organized. Minimal connection to speech',
          'Connects to speech. Mostly organized, creative, and aesthetically pleasing',
          'Complements the speech well. Organized, creative and aesthetically pleasing',
        ],
      ],
      passLevel,
      passCriteriaRule,
    },
  },
};

export const mimeTypes = {
  allowImages: [
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/webp',
    'image/svg+xml',
    'image/heic',
    'image/heif',
  ],
  allowVideo: [
    'video/x-msvideo',
    'video/mpeg',
    'video/webm',
    'video/mp4',
    'video/x-flv',
    'video/quicktime',
    'video/x-ms-wmv',
  ],
  allowAudio: ['audio/midi', 'audio/x-midi', 'audio/mpeg', 'audio/wav', 'audio/webm', 'audio/mp4'],
  allowPDF: ['application/pdf'],
  allowWord: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  allowExcel: [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
  ],
  allowPowerpoint: [
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ],
  allowText: ['text/plain'],
  allowZip: ['application/x-rar-compressed', 'application/zip'],
};

export const assignmentTypeData = {
  [activityTemplates.question.type]: {
    objective: activityTemplates.question.objective,
    instructions: activityTemplates.question.instructions,
    rubric: activityTemplates.question.rubric,
  },
  [activityTemplates.essay.type]: {
    objective: activityTemplates.essay.objective,
    instructions: activityTemplates.essay.instructions,
    rubric: activityTemplates.essay.rubric,
  },
  [activityTemplates.peerContent.type]: {
    objective: activityTemplates.peerContent.objective,
    instructions: activityTemplates.peerContent.instructions,
    rubric: activityTemplates.peerContent.rubric,
  },
  [activityTemplates.multimedia.type]: {
    objective: activityTemplates.multimedia.objective,
    instructions: activityTemplates.multimedia.instructions,
    rubric: activityTemplates.multimedia.rubric,
  },
};

export const assignmentTypeOptions = [
  { text: 'Create a Question', value: 'question' },
  { text: 'Create an Essay', value: 'essay' },
  { text: 'Create Content to Teach Peers', value: 'peerContent' },
  { text: 'Creative Communication', value: 'multimedia' },
];

export const activityTypeOptions = [
  { text: 'Peer Review', value: 'peer' },
  { text: 'Calibration Activity', value: 'calibration' },
];

export const fieldNames = {
  NUMEVALSTOASSIGN: 'numEvalsToAssign',
};

export const labels = {
  Images: 'All popular image formats',
  Video: 'All popular video formats',
  Audio: 'All popular audio formats',
  PDF: 'PDF format',
  Word: 'All popular word processing documents (.doc files not supported)',
  Excel: 'Microsoft Excel workbooks (.xls files not supported)',
  Powerpoint: 'Microsoft Powerpoint presentations (.ppt files not supported)',
  Text: 'Any text editor format',
  Zip: 'Zip file compression format',
};
