import * as FeedbackUtil from '@kritik/utils/creation/feedback';
import * as CreationStatusUtil from '@kritik/utils/creation/status';

import {
  isFeedback,
  isGrading,
  isFinalized,
  isFeedbackOrLater,
  getFeedbackStage,
  studentParticipatesInStageFromInstructorView,
} from '@kritik/utils/stage';
import { FeedbackScoreUtil } from '@kritik/utils/grade';
import { isPresentationActivity } from '@kritik/utils/activity';
import { localize } from 'locales/index';

export const labels = {
  NOT_STARTED: {
    label: localize({ message: 'Activity.StudentTable.Cell.NotStarted' }),
    status: 'unavailable',
  },
  NONE_ASSIGNED: {
    label: localize({ message: 'Activity.StudentTable.Cell.NoneAssigned' }),
  },
  COMPLETE: {
    label: localize({ message: 'Activity.StudentTable.Cell.Complete' }),
    status: 'no-bg',
  },
  NOT_REQUIRED: {
    label: localize({ message: 'Activity.StudentTable.Cell.NotRequired' }),
    status: 'unavailable',
  },
};

const generateCountLabel = (completed: any, todo: any) => {
  return {
    label: `${completed}/${todo}`,
    status: 'unavailable',
  };
};

export const generateFeedbackCellLabel = (
  creation: any,
  activity: any,
  isShowFeedbackCompleted: any,
  feedbackReceivedMap: any,
  student: any,
  group: any,
  creations: any[]
) => {
  if (isPresentationActivity(activity)) {
    return generateFeedbackCellLabelForPresentationActivity({
      creation,
      activity,
      isShowFeedbackCompleted,
      student,
      group,
      creations,
    });
  }

  const groupMemberId = student && student._id;

  if (!isFeedbackOrLater({ assignment: activity })) {
    return labels.NOT_STARTED;
  }

  if (!isShowFeedbackCompleted) {
    const expectedFOFReceived = FeedbackScoreUtil.getNumFOFToReceiveForCreation(
      creation,
      activity,
      groupMemberId
    );
    const isGroupView = creation.group && !groupMemberId;
    let actualFOFReceived: any;
    if (isGroupView) {
      actualFOFReceived = 0;
      group.members.forEach((member: any) => {
        actualFOFReceived += FeedbackScoreUtil.getNumFOFReceivedForStudentFromMap(
          feedbackReceivedMap,
          member._id || member
        );
      });
    } else {
      actualFOFReceived = FeedbackScoreUtil.getNumFOFReceivedForStudentFromMap(
        feedbackReceivedMap,
        groupMemberId || creation.student
      );
    }
    if (expectedFOFReceived === 0) {
      return labels.NONE_ASSIGNED;
    }
    return generateCountLabel(actualFOFReceived, expectedFOFReceived);
  }

  if (!creation) {
    return labels.NONE_ASSIGNED;
  }
  const { completedFOF, toDo: numOfFOFToDo } = FeedbackScoreUtil.numFOFCompleted(
    creation,
    activity,
    groupMemberId
  );

  if (CreationStatusUtil.wasCreationMissed(creation) || numOfFOFToDo === 0) {
    return labels.NONE_ASSIGNED;
  }

  if (isFeedback({ assignment: activity })) {
    if (!FeedbackUtil.hasFinishedFOF(creation, activity)) {
      return generateCountLabel(completedFOF, numOfFOFToDo);
    }
    return labels.COMPLETE;
  }

  if (isFinalized({ assignment: activity }) || isGrading({ assignment: activity })) {
    if (numOfFOFToDo !== completedFOF && !FeedbackUtil.hasFinishedFOF(creation, activity)) {
      return {
        label: localize({
          message: 'Activity.StudentTable.Cell.CountMissed',
          options: { count: numOfFOFToDo - completedFOF },
        }),
        status: 'fail',
      };
    }
    return labels.COMPLETE;
  }

  return {
    label: '',
  };
};

export const generateFeedbackCellLabelForPresentationActivity = ({
  creation,
  activity,
  isShowFeedbackCompleted,
  student,
  group,
  creations,
}: {
  creation: any;
  activity: any;
  isShowFeedbackCompleted: any;
  student: any;
  group: any;
  creations: any[];
}) => {
  const feedbackStage = getFeedbackStage(activity);

  if (
    !studentParticipatesInStageFromInstructorView({
      stage: feedbackStage,
      studentId: student?._id || creation.student,
    })
  ) {
    return labels.NOT_REQUIRED;
  }

  if (!isFeedbackOrLater({ assignment: activity })) {
    return labels.NOT_STARTED;
  }

  const groupMemberId = student && student._id;
  const isGroupView = creation.group && !groupMemberId;

  if (!isShowFeedbackCompleted) {
    let expectedFOFReceived = 0;
    let actualFOFReceived = 0;

    if (isGroupView) {
      group.members.forEach((member: any) => {
        expectedFOFReceived += FeedbackScoreUtil.getNumFOFToReceiveForPresentationActivity({
          creations,
          studentId: member._id || member,
        });
      });

      group.members.forEach((member: any) => {
        actualFOFReceived += FeedbackScoreUtil.getNumFOFReceivedForPresentationActivity({
          creations,
          studentId: member._id || member,
        });
      });
    } else {
      expectedFOFReceived += FeedbackScoreUtil.getNumFOFToReceiveForPresentationActivity({
        creations,
        studentId: student._id,
      });
      actualFOFReceived += FeedbackScoreUtil.getNumFOFReceivedForPresentationActivity({
        creations,
        studentId: student._id,
      });
    }

    if (expectedFOFReceived === 0) {
      return labels.NONE_ASSIGNED;
    }

    return generateCountLabel(actualFOFReceived, expectedFOFReceived);
  }

  let completedFOF = 0;
  let numOfFOFToDo = 0;

  if (isGroupView) {
    group.members.forEach((member: any) => {
      const result = FeedbackScoreUtil.numFOFCompletedForPresentationActivity({
        creations,
        studentId: member._id || member,
      });

      completedFOF += result.completedFOF;
      numOfFOFToDo += result.toDo;
    });
  } else {
    const result = FeedbackScoreUtil.numFOFCompletedForPresentationActivity({
      creations,
      studentId: student._id,
    });
    completedFOF += result.completedFOF;
    numOfFOFToDo += result.toDo;
  }

  if (numOfFOFToDo === 0) {
    return labels.NONE_ASSIGNED;
  }

  if (isFeedback({ assignment: activity })) {
    if (completedFOF === numOfFOFToDo) {
      return labels.COMPLETE;
    }
    return generateCountLabel(completedFOF, numOfFOFToDo);
  }

  if (isFinalized({ assignment: activity }) || isGrading({ assignment: activity })) {
    if (completedFOF === numOfFOFToDo) {
      return labels.COMPLETE;
    }

    return {
      label: localize({
        message: 'Activity.StudentTable.Cell.CountMissed',
        options: { count: numOfFOFToDo - completedFOF },
      }),
      status: 'fail',
    };
  }

  return {
    label: '',
  };
};
