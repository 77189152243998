// Convert decimal to hex
// i.e. 0-255 -> '00'-'ff'
function dec2hex(dec: any) {
  return dec.toString(16).padStart(2, '0');
}

function randomHexString(length = 40) {
  if (length <= 0) {
    throw new Error('Refusing to randomize a string of zero or less length');
  }

  if (length % 2 !== 0) {
    throw new Error('randomHexString must be divisible by 2');
  }

  const arr = new Uint8Array(length / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
}

export { randomHexString };
