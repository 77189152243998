import { connect } from 'react-redux';
import { isEvaluateOrLater, isFeedbackOrLater } from '@kritik/utils/stage';
import {
  hasEvaluatorNotes,
  getEvaluatorNotes,
  getEvaluatorNotesFiles,
} from '@kritik/utils/activity';
import OverviewSection from 'containers/assignments/overview/ActivityBody/OverviewSection';
import { InlineInformation } from 'components/layout';
import HtmlContent from 'components/General/HtmlContent';
import FileList from 'components/layout/AttachmentManager/FileList';
import { getMySubmissionsToEvaluate } from 'selectors/creation';
import { TEACHER_NOTE, STUDENT_NOTE } from './constants';
import { useUserRoleInCourse } from 'hooks/course';

const mapStateToProps = (state: any) => {
  return {
    state,
  };
};

function EvaluatorNotes({ activity, isSection, state, showInfo = true }: any) {
  if (!hasEvaluatorNotes(activity)) {
    return null;
  }
  const { isInstructorInCourse } = useUserRoleInCourse();
  let submissions = null;
  if (!isInstructorInCourse) {
    submissions = getMySubmissionsToEvaluate(state, state.selected.assignmentId);
  }
  const hasSubmissionsToEvaluate = !!submissions && submissions.length > 0;
  const evaluateOrLater = isEvaluateOrLater(activity);
  const feedbackOrLater = isFeedbackOrLater({ assignment: activity });

  const noteContent = () => {
    return (
      <div data-testid="evaluator-notes-content">
        {showInfo && (
          <div className="evaluator-note">
            <InlineInformation
              type="information"
              title={isInstructorInCourse ? 'Evaluator Only' : 'Evaluator Notes'}
            >
              {isInstructorInCourse ? TEACHER_NOTE : STUDENT_NOTE}
            </InlineInformation>
          </div>
        )}
        <div className="evaluator-note">
          <HtmlContent content={getEvaluatorNotes(activity)} />
        </div>
        <FileList files={getEvaluatorNotesFiles(activity)} />
      </div>
    );
  };
  if (isInstructorInCourse || feedbackOrLater || (evaluateOrLater && hasSubmissionsToEvaluate)) {
    if (isSection) {
      return <OverviewSection title="Evaluator Notes">{noteContent()}</OverviewSection>;
    }
    if (isInstructorInCourse) {
      return null;
    }
    return noteContent();
  }
  return null;
}

export default connect(mapStateToProps, { getMySubmissionsToEvaluate })(EvaluatorNotes);
