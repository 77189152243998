import React from 'react';
import classNames from 'classnames';
import { Tooltip } from '@material-ui/core';
import { localize } from 'locales';

const LABELS = {
  DANGER: 'danger',
  INFORMATION: 'information',
  LIGHT_BLUE: 'light-blue',
  NORMAL: 'normal',
  PREVIEW: 'preview',
};

type ModeLabelProps = {
  className?: string;
  testid?: string;
  title: string;
  style: string;
  type: string;
  location: 'bottom' | 'left' | 'right' | 'top';
};

const ModeLabel = ({ className, location, style, type, testid, title }: ModeLabelProps) => {
  const displayClasses = classNames(
    {
      'mode-label': LABELS.NORMAL === style,
      'mode-label__preview': LABELS.PREVIEW === style,
    },
    {
      'mode-label__danger': LABELS.DANGER === type,
      'mode-label__information': LABELS.INFORMATION === type,
      'mode-label__light-blue': LABELS.LIGHT_BLUE === type,
    },
    className
  );

  return (
    <Tooltip title={title} placement={location} classes={{ tooltip: 'no-print' }}>
      <span className={displayClasses} data-testid={testid}>
        {localize({ message: 'Rubric.Mode.Label' })}
      </span>
    </Tooltip>
  );
};

export default ModeLabel;
