import STAGE_STATUS_ICONS from 'images/status-icons';
import StageTitle from '../StageTitle';
import PRESENT_ICON from './present.svg';
import PRESENT_ICON_DISABLED from './present_disabled.png';
import DateInput from 'components/schedule/DateInput';
import ArrowDown from 'containers/PresentationSchedule/ArrowDown';
import { localize } from 'locales';
import GracePeriod from 'containers/PresentationSchedule/Evaluate/GracePeriod';

type EvaluateProps = {
  disabledStartDate?: boolean;
  disabledEndDate?: boolean;
  startDate: number;
  endDate: number;
  onStartDateChange: (date: Date) => void;
  onEndDateChange: (date: Date) => void;
  toggleGracePeriod: () => void;
  isCheckedGracePeriod: boolean;
  selectedGracePeriod: number;
  handleSelectGracePeriod: (value: number) => void;
};

function Evaluate(props: EvaluateProps) {
  const handleSelectGracePeriod = (value: number) => {
    props.handleSelectGracePeriod(value);
  };

  return (
    <div className="evaluate__container">
      <div className="evaluate__icon-container">
        <img
          src={props.disabledEndDate ? PRESENT_ICON_DISABLED : PRESENT_ICON}
          aria-hidden="true"
          style={{ zIndex: 5, position: 'relative' }}
        />
        <img
          src={
            props.disabledEndDate
              ? STAGE_STATUS_ICONS.EVALUATE_DISABLED
              : STAGE_STATUS_ICONS.EVALUATE
          }
          aria-hidden="true"
          style={{ position: 'absolute', top: '45px', left: '20px' }}
        />
        <ArrowDown className="evaluate__arrow" />
      </div>
      <div>
        <StageTitle title={localize({ message: 'Activity.Schedule.PresentAndEvaluate' })} />
        <p>{localize({ message: 'Activity.Schedule.PresentAndEvaluate.Note1' })}</p>
        <p>{localize({ message: 'Activity.Schedule.PresentAndEvaluate.Note2' })}</p>
        <DateInput
          label={localize({ message: 'Activity.Schedule.PresentAndEvaluate.StartDate' })}
          labelColor="dark"
          value={props.startDate}
          onDateChange={props.onStartDateChange}
          footNote={localize({
            message: 'Activity.Schedule.PresentAndEvaluate.StartDate.FootNote',
          })}
          disabled={props.disabledStartDate}
        />
        <DateInput
          label={localize({
            message: 'Activity.Schedule.EvaluationDueDate',
          })}
          labelColor="dark"
          value={props.endDate}
          onDateChange={props.onEndDateChange}
          footNote={localize({
            message: 'Activity.Schedule.PresentAndEvaluate.StartDate.FootNote2',
          })}
          disabled={props.disabledEndDate}
        />
        <GracePeriod
          isCheckedGracePeriod={props.isCheckedGracePeriod}
          selectedGracePeriod={props.selectedGracePeriod}
          handleCheck={props.toggleGracePeriod}
          handleSelect={handleSelectGracePeriod}
          evaluationEndDate={props.endDate}
        />
      </div>
    </div>
  );
}

export default Evaluate;
