import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';

type PageProps = {
  title?: any;
  meta?: any[];
  link?: any[];
  script?: any[];
  children: React.ReactElement;
};

class Page extends Component<PageProps> {
  render() {
    let { title, link, meta, script, children } = this.props;

    link = (Array as any).concat([], link, []);

    title = title.includes('Kritik') ? title : `${title} | Kritik`;

    return (
      <React.Fragment>
        <Helmet title={title} link={link} meta={meta} script={script} />
        {children}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    page: state.page,
  };
}

export default connect(mapStateToProps, {})(Page);
