import moment from 'moment';

const customTimeFormatString = 'h:mm A';

function combineDateAndTime(date: any, time: any) {
  const customTime = moment(time.value, customTimeFormatString);

  return moment(date).hours(customTime.hours()).minutes(customTime.minutes()).seconds(0).toDate();
}

function convertCustomTimeToString(customTime: any) {
  return moment().hours(customTime[0]).minutes(customTime[1]).format(customTimeFormatString);
}

function hasCustomTime(customTime: any) {
  return customTime && customTime.length === 2;
}

export default {
  combineDateAndTime,
  convertCustomTimeToString,
  hasCustomTime,
};
