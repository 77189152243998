import { connect } from 'react-redux';
import FileList from 'components/layout/AttachmentManager/FileList';
import HtmlContent from 'components/General/HtmlContent';
import {
  acceptsLateEvaluations,
  autoAcceptsLateEvaluations,
  getActivityWeight,
  isPresentationActivity,
  autoAcceptsLateSubmissions,
  acceptsLateSubmissions,
} from '@kritik/utils/activity';
import OverviewSection from 'containers/assignments/overview/ActivityBody/OverviewSection';
import WeightWarning from 'components/Assignment/Weight/Warning';
import MultiTopic from 'containers/assignments/overview/ActivityBody/MultiTopic';
import EvaluatorNotes from 'containers/assignments/overview/ActivityBody/EvaluatorNotes';
import { isMultipleTopics } from 'utils/topic';
import { selectAuthUser } from 'selectors/user';
import CollapsibleArea from 'components/layout/CollapsibleArea';
import { isFinalized } from '@kritik/utils/stage';
import ActivityType from 'containers/assignments/overview/Type';
import InfoPopup from 'components/core/InfoPopup';
import { useUserRoleInCourse } from 'hooks/course';
import { Activity } from 'old-common/types.generated';
import { TranslatedText } from 'components/TranslatedText';

const lateSubmissionDescription = (acceptLateSubmissions = false) => {
  if (acceptLateSubmissions) {
    return 'The late submission settings are not visible to students. All late submissions will be marked as late.';
  }

  return 'The late submission settings are not visible to students.';
};

const ActivityBody = ({ activity, user }: any) => {
  const { isInstructorInCourse, isStudentInCourse } = useUserRoleInCourse();

  const isStudentFinalizedView = isStudentInCourse && isFinalized({ assignment: activity });

  return (
    <>
      <h3 className="visually-hidden" tabIndex={0}>
        Activity Details Section
      </h3>
      <CollapsibleArea
        title="Activity Details"
        isCollapsible={!isStudentFinalizedView}
        testid="activity-details-collapse"
      >
        <ActivityType type={activity.activityType} />
        <ActivityWeight activity={activity} />
        <OverviewSection title="Objective" testid="activity-objective-title">
          <HtmlContent content={activity.objective} data-testid="activity-objective" />
        </OverviewSection>
        <OverviewSection title="Instructions" testid="activity-instructions-title">
          <HtmlContent content={activity.instructions} data-testid="activity-instructions" />
        </OverviewSection>
        {isMultipleTopics(activity) && (
          <OverviewSection title={isInstructorInCourse ? 'Multi-topic' : 'Topic'}>
            <MultiTopic />
          </OverviewSection>
        )}
        <ActivityFiles files={activity.files} />
        <EvaluatorNotes user={user} activity={activity} isSection />
        {!isStudentInCourse && (
          <OverviewSection
            title={
              <div>
                Late Submissions
                <InfoPopup
                  description={lateSubmissionDescription(activity?.acceptLateSubmissions)}
                  learnMoreUrl={`/course/${activity.course}/assignment/${activity._id}/edit-assignment`}
                  learnMoreLabel="Update settings"
                />
              </div>
            }
          >
            <LateSubmissionsSettings activity={activity} />
            {!isPresentationActivity(activity) && <LateEvaluationsSettings activity={activity} />}
          </OverviewSection>
        )}
      </CollapsibleArea>
    </>
  );
};

const LateSubmissionsSettings = ({ activity }: { activity: Activity }) => {
  if (acceptsLateSubmissions(activity) && !autoAcceptsLateSubmissions(activity)) {
    return (
      <p>
        <TranslatedText i18nKey="LateSubmissionSettings.Creations.AcceptLateNeedApproval" />
      </p>
    );
  }
  if (acceptsLateSubmissions(activity) && autoAcceptsLateSubmissions(activity)) {
    return (
      <p>
        <TranslatedText i18nKey="LateSubmissionSettings.Creations.AcceptLateAutoAccept" />
      </p>
    );
  }
  return (
    <p>
      <TranslatedText i18nKey="LateSubmissionSettings.Creations.NoLate" />
    </p>
  );
};

const LateEvaluationsSettings = ({ activity }: { activity: Activity }) => {
  if (acceptsLateEvaluations(activity) && !autoAcceptsLateEvaluations(activity)) {
    return (
      <p>
        <TranslatedText i18nKey="LateSubmissionSettings.Evaluations.AcceptLateNeedApproval" />
      </p>
    );
  }
  if (acceptsLateSubmissions(activity) && autoAcceptsLateSubmissions(activity)) {
    return (
      <p>
        <TranslatedText i18nKey="LateSubmissionSettings.Evaluations.AcceptLateAutoAccept" />
      </p>
    );
  }
  return (
    <p>
      <TranslatedText i18nKey="LateSubmissionSettings.Evaluations.NoLate" />
    </p>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: selectAuthUser(state),
  };
};

const ActivityWeight = ({ activity }: any) => {
  const weight = getActivityWeight(activity);
  const label = `${weight} ${weight === 1 ? 'pt' : 'pts'}`;
  return (
    <OverviewSection title="Weight" testid="activity-weight-title">
      <div className="activity-overview__weight-display" data-testid="activity-weight">
        {label}
      </div>
      <WeightWarning activity={activity} />
    </OverviewSection>
  );
};

const ActivityFiles = ({ files }: any) => {
  if (files.length === 0) {
    return null;
  }
  return (
    <OverviewSection title="Resources">
      <FileList files={files} />
    </OverviewSection>
  );
};

export default connect(mapStateToProps)(ActivityBody);
