import React from 'react';
import { connect } from 'react-redux';
import MarkingScheme from 'components/Course/MarkingScheme';
import { EvaluationScoreUtil } from '@kritik/utils/grade/evaluation';
import MarkingSchemeLayout from 'components/Course/MarkingSchemeLayout';
import markingSchemeCopy from 'components/Course/MarkingScheme/copy';
import SectionHeader from 'containers/Course/partials/SectionHeader';
import VerticalList from 'components/layout/VerticalList';
import ScoringOptions from 'components/Course/ScoringOptions';
import { navigateToCourseScoreEdit } from 'redux/redirect';
import Access from 'components/Access';
import { TranslatedText } from 'components/TranslatedText';
import { useUserRoleInCourse } from 'hooks/course';

const ScoringView = (props: any) => {
  const { isInstructorInCourse } = useUserRoleInCourse();
  const { markingScheme } = props.course;

  const getTotalEvaluationWeight = () => {
    return EvaluationScoreUtil.getEvaluationTotalWeight(markingScheme);
  };

  const getRelativeEvalWeight = (value: any) => {
    const totalWeight = getTotalEvaluationWeight();
    return (value / totalWeight) * 100;
  };

  const renderCreationScore = () => {
    const copy = markingSchemeCopy.CREATION;
    const scoreCopy = markingSchemeCopy.CREATION_SCORE;

    const creationUrl = isInstructorInCourse
      ? scoreCopy.popover.url
      : 'https://help.kritik.io/en/articles/6387889-understanding-the-grading-scheme#h_df9164ed1d';

    const popOver = {
      ...scoreCopy.popover,
      url: creationUrl,
    };

    return (
      <MarkingScheme
        title={copy.title}
        description={copy.description}
        icon={copy.icon}
        color="cyan"
        isPercentage={markingScheme.isPercentage}
        category={{
          label: scoreCopy.title,
          name: 'creationScore',
          description: scoreCopy.description,
          value: markingScheme.passedSubmissions,
          popover: popOver,
          testid: 'marking-scheme-creation-score',
        }}
      />
    );
  };

  const renderEvaluationScore = () => {
    const { isInstructorInCourse } = useUserRoleInCourse();
    const copy = markingSchemeCopy.EVALUATION;
    const scoreCopy = markingSchemeCopy.EVALUATION_SCORE;
    const gradingCopy = markingSchemeCopy.GRADING_SCORE;
    const writtenCopy = markingSchemeCopy.WRITTEN_EVAL_SCORE;

    const customGradingUrl = isInstructorInCourse
      ? gradingCopy.popover.url
      : 'https://help.kritik.io/en/articles/6387889-understanding-the-grading-scheme#h_5b5b369b69';

    const customWrittenUrl = isInstructorInCourse
      ? writtenCopy.popover.url
      : 'https://help.kritik.io/en/articles/6387889-understanding-the-grading-scheme#h_2c86ff3464';

    const popOverGrading = {
      ...gradingCopy.popover,
      url: customGradingUrl,
    };

    const popOverWritten = {
      ...writtenCopy.popover,
      url: customWrittenUrl,
    };

    return (
      <MarkingScheme
        title={copy.title}
        description={copy.description}
        icon={copy.icon}
        color="tan"
        isPercentage={markingScheme.isPercentage}
        category={{
          label: scoreCopy.title,
          name: 'evaluationScore',
          description: scoreCopy.description,
          value: getTotalEvaluationWeight(),
          testid: 'marking-scheme-evaluate-score',
        }}
        subCategoryList={[
          {
            label: gradingCopy.title,
            name: 'gradingScore',
            description: gradingCopy.description,
            value: getRelativeEvalWeight(markingScheme.kritikScore),
            weight: markingScheme.kritikScore,
            popover: popOverGrading,
            testid: 'marking-scheme-grading-score',
          },
          {
            label: writtenCopy.title,
            name: 'writtenEvalScore',
            description: writtenCopy.description,
            value: getRelativeEvalWeight(markingScheme.feedbackCommunicationEffectiveness),
            weight: markingScheme.feedbackCommunicationEffectiveness,
            popover: popOverWritten,
            testid: 'marking-scheme-written-eval-score',
          },
        ]}
      />
    );
  };

  const renderFeedbackScore = () => {
    const copy = markingSchemeCopy.FEEDBACK;
    const scoreCopy = markingSchemeCopy.FEEDBACK_SCORE;
    return (
      <MarkingScheme
        title={copy.title}
        description={copy.description}
        icon={copy.icon}
        color="red"
        isPercentage={markingScheme.isPercentage}
        category={{
          label: scoreCopy.title,
          name: 'feedbackScore',
          description: scoreCopy.description,
          value: markingScheme.participationInFof,
          testid: 'marking-scheme-feedback-score',
        }}
      />
    );
  };

  const renderGradingScheme = () => {
    return (
      <div style={{ marginTop: 16 }}>
        <h3 className="new-header-3 course-details__margin-bottom-md">
          <TranslatedText i18nKey="CourseDetails.ScoringOptions.GradingScheme.Title" />
        </h3>
        <MarkingSchemeLayout
          nodeList={[renderCreationScore(), renderEvaluationScore(), renderFeedbackScore()]}
        />
      </div>
    );
  };

  const redirectToEdit = () => {
    props.navigateToCourseScoreEdit({ courseId: props.course._id });
  };

  const renderHeading = () => {
    return (
      <Access
        instructor={
          <SectionHeader
            type="primary"
            label="Scoring"
            action={{
              action: redirectToEdit,
              label: 'Edit Scoring',
            }}
            testid="edit-scoring"
          />
        }
        student={<SectionHeader label="Scoring" />}
      />
    );
  };

  return (
    <React.Fragment>
      {renderHeading()}
      <ScoringOptions course={props.course} />
      {renderGradingScheme()}
    </React.Fragment>
  );
};

export default connect(null, { navigateToCourseScoreEdit })(ScoringView);
