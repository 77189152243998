import SOURCE from 'components/ActivityEdit/Calibration/CalibrationSource/constants';

/*
 * Whether a user is creating a calibration from a past activity
 * @param {Object} formState - form values
 * @return {bool} - true if user is sourcing a past activity
 */
const isSourcingPastActivity = (formState: any) => {
  return formState[SOURCE.FIELD_NAME] === SOURCE.TYPES.PAST_ACTIVITY;
};

export default {
  isSourcingPastActivity,
};
