import axios from 'axios';

class RestApiClient {
  CancelToken: any;
  client: any;
  constructor(config: any) {
    this.client = axios.create(config);
    this.CancelToken = axios.CancelToken;

    this.client.interceptors.response.use((res: any) => {
      return res;
    }, this.handleError);
  }

  request(options: any) {
    return this.client.request(options);
  }

  handleError = (error: any) => {
    const errorStatus = error.response.status;

    switch (errorStatus) {
      case 303:
        window.location.href = error.response?.data?.redirectUrl;
        break;
      case 401:
        window.location.href = '/login';
        break;
      default:
        return Promise.reject(error);
    }
  };
}

export const createRestApiClient = () => {
  return {
    withConfig: (config: any) => {
      return new RestApiClient(config);
    },
  };
};
