import * as _ from 'lodash-es';
import { getStudent, getStudentCreation } from './student';

export const sortByStudentName = (itemList: any, ascending: any) => {
  return _.orderBy(
    itemList,
    [
      function (item) {
        const student = getStudent(item);
        return student.user.profile.lastName.toLowerCase();
      },
    ],
    [ascending ? 'asc' : 'desc']
  );
};

export const sortByGroupName = (itemList: any, ascending: any) => {
  return _.orderBy(
    itemList,
    [
      function (item) {
        const creation = getStudentCreation(item);
        return creation.group.name.toLowerCase();
      },
    ],
    [ascending ? 'asc' : 'desc']
  );
};
