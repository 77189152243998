import * as types from 'types';

export function mergeEntities(entities: any) {
  return {
    type: types.MERGE_ENTITIES,
    entities,
  };
}

export function syncEntities(entity: any, key: any) {
  return {
    type: types.SYNC_ENTITIES,
    entity,
    key,
  };
}

export function updateEntities(entities: any) {
  return {
    type: types.UPDATE_ENTITIES,
    entities,
  };
}

export function replaceEntities(entities: any) {
  return {
    type: types.REPLACE_ENTITIES,
    entities,
  };
}

export function removeEntities(entities: any) {
  return {
    type: types.REMOVE_ENTITIES,
    entities,
  };
}

export function clearEntities() {
  return {
    type: types.CLEAR_ENTITIES,
  };
}
