import React, { useRef, useEffect } from 'react';
import { isStudentSeenComment } from '@kritik/utils/creation/status';
import { markInstructorCommentSeen } from 'actions/comment';
import { connect } from 'react-redux';
import { useUserRoleInCourse } from 'hooks/course';

const RefWrapper = (props: any) => {
  const { isStudentInCourse } = useUserRoleInCourse();
  const viewPortElem = useRef(null);
  const isNotifiedRef = useRef(false);

  const isElemVisible = (el: any) => {
    if (!el || !el.current) {
      return false;
    }
    const rect = el.current.getBoundingClientRect();
    const viewHeight = window.innerHeight;
    return rect.bottom <= viewHeight;
  };

  const shouldSendCommentUpdate = () => {
    if (!props.creation || !props.student) {
      return false;
    }
    if (
      isStudentSeenComment({ creation: props.creation, studentId: props.student._id }) ||
      isNotifiedRef.current
    ) {
      return false;
    }
    return true;
  };

  const sendCommentUpdateWhenInView = () => {
    if (isElemVisible(viewPortElem) && shouldSendCommentUpdate()) {
      isNotifiedRef.current = true;
      props.markInstructorCommentSeen({
        creation: props.creation,
        viewed: [`${props.student._id}`],
      });
    }
  };

  const handleScroll = () => {
    sendCommentUpdateWhenInView();
  };

  const addScrollListener = () => {
    if (isStudentInCourse) {
      window.addEventListener('scroll', handleScroll);
    }
  };

  const removeScrollListener = () => {
    if (isStudentInCourse) {
      window.removeEventListener('scroll', handleScroll);
    }
  };

  useEffect(() => {
    sendCommentUpdateWhenInView();
    addScrollListener();
    return () => {
      removeScrollListener();
    };
  }, []);

  return <div ref={viewPortElem}>{props.children}</div>;
};
const mapStateToProps = (state: any) => {
  return {};
};

export default connect(mapStateToProps, { markInstructorCommentSeen })(RefWrapper);
