import i18next from 'i18next';
import translationEn from './en/en.json';

export { translationEn };

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18next.init({
  fallbackLng: 'en',
  resources: {
    en: {
      translation: translationEn,
    },
  },
});

export const i18nInstance = i18next;

type TranslateParams = {
  message: I18nKey;
  locale?: string;
  options?: any;
};

export function localize({ message, options, locale = 'en' }: TranslateParams) {
  const t = i18next.getFixedT(locale);
  const result: string = t(message, options);
  return result;
}

export type I18nKey = keyof typeof translationEn;
