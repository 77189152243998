import React from 'react';
import createSvgIcon from '../util/generateIconSVG';

export default createSvgIcon(
  <React.Fragment>
    <circle cx="32" cy="32" r="31.5" fill="#FFF6E0" stroke="#6A5523" />
    <path
      d="M16.8803 19.2924C16.5502 18.3329 15.1932 18.3329 14.863 19.2924L13.7375 22.5642H10.1627C9.11473 22.5642 8.6951 23.9174 9.55923 24.5104L12.3978 26.4581L11.3014 29.6452C10.9669 30.6173 12.0659 31.4533 12.9135 30.8717L15.8717 28.8418L18.8299 30.8717C19.6775 31.4533 20.7765 30.6173 20.442 29.6452L19.3456 26.4581L22.1842 24.5104C23.0483 23.9174 22.6286 22.5642 21.5806 22.5642H18.0059L16.8803 19.2924Z"
      fill="#FFB300"
      stroke="#6A5523"
      strokeWidth="1.06667"
    />
    <path
      d="M32.9424 19.3963C32.6231 18.4136 31.2328 18.4136 30.9135 19.3963L29.8842 22.5642H26.5533C25.52 22.5642 25.0903 23.8864 25.9263 24.4938L28.6211 26.4517L27.5918 29.6196C27.2725 30.6023 28.3972 31.4195 29.2332 30.8121L31.928 28.8543L34.6228 30.8121C35.4587 31.4195 36.5835 30.6023 36.2642 29.6196L35.2349 26.4517L37.9296 24.4938C38.7656 23.8864 38.336 22.5642 37.3027 22.5642H33.9717L32.9424 19.3963Z"
      fill="#FFB300"
      stroke="#6A5523"
      strokeWidth="1.06667"
    />
    <path
      d="M48.9926 19.2924C48.6625 18.3329 47.3055 18.3329 46.9754 19.2924L45.8498 22.5642H42.275C41.227 22.5642 40.8074 23.9174 41.6715 24.5104L44.5101 26.4581L43.4137 29.6452C43.0792 30.6173 44.1782 31.4533 45.0258 30.8717L47.984 28.8418L50.9422 30.8717C51.7898 31.4533 52.8888 30.6173 52.5543 29.6452L51.4579 26.4581L54.2965 24.5104C55.1606 23.9174 54.741 22.5642 53.693 22.5642H50.1182L48.9926 19.2924Z"
      fill="#FFF6E0"
      stroke="#6A5523"
      strokeWidth="1.06667"
    />
    <rect x="8.06055" y="39.6978" width="46.8669" height="1.73581" rx="0.867905" fill="#6A5523" />
    <rect x="8.06055" y="36.2261" width="46.8669" height="1.73581" rx="0.867905" fill="#6A5523" />
    <rect x="8.06055" y="43.1694" width="22.5655" height="1.73581" rx="0.867905" fill="#6A5523" />
  </React.Fragment>,
  '0 0 64 64'
);
