import { normalize } from 'normalizr';
import { mergeEntities, replaceEntities } from 'actions/entities';
import { courseSchema, institutionSchema } from 'schemas';
import { institutionService, adminService } from 'services';
import * as types from 'types';
import * as ErrorUtils from 'utils/error';

export const setErrors = (entity: any, err: any) => {
  const message =
    typeof err === 'object' ? ErrorUtils.getErrorMessageFromResponse(err.response) : err;
  return { type: types.SET_ERRORS, payload: { entity, error: message } };
};

export function createLateSubmission({
  student,
  assignmentID,
  assignmentContent,
  reviewers,
  files,
  submissionStatus,
  notifications,
}: any) {
  return async (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'showLogin'.
      return dispatch(showLogin({ redirect: location.pathname }));
    }
    dispatch({ type: types.CREATE_LATE_SUBMISSION });
    try {
      const createLateSubmissionResponse = await adminService().createLateSubmission({
        student,
        assignmentID,
        assignmentContent,
        reviewers,
        files,
        submissionStatus,
        notifications,
      });
      if (createLateSubmissionResponse && createLateSubmissionResponse.status === 200) {
        return dispatch({
          type: types.CREATE_LATE_SUBMISSION_SUCCESS,
          payload: createLateSubmissionResponse.data,
        });
      }
    } catch (err) {
      dispatch({ type: types.CREATE_LATE_SUBMISSION_FAILURE, payload: err });
      throw new Error();
    }
  };
}

export function getCourses(params = {}, { callback, client }: any = {}) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: types.GET_COURSES_REQUEST, payload: { params } });
    return adminService({ client })
      .getCourses(params)
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, [courseSchema]);
          delete normalized.entities.students;
          if (!normalized.entities.courses) {
            normalized.entities.courses = {};
          }
          dispatch(replaceEntities(normalized.entities));
          dispatch({
            type: 'GET_COURSES_SUCCESS',
            payload: { params, items: normalized.result },
          });
          if (typeof callback === 'function') {
            callback(res.data);
          }
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.GET_COURSES_FAILURE, payload: err });
        if (typeof callback === 'function') {
          callback([]);
        }
      });
  };
}

export function getCoursesForLateSubmission({ email }: any) {
  return (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'showLogin'.
      return dispatch(showLogin({ redirect: location.pathname }));
    }
    dispatch({ type: types.GET_COURSES_FOR_LATE_SUBMISSION });

    return adminService()
      .getCoursesForLateSubmission({ email })
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({
            type: types.GET_COURSES_FOR_LATE_SUBMISSION_SUCCESS,
            payload: { courses: res.data },
          });
          return res.data;
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.GET_COURSES_FOR_LATE_SUBMISSION_FAILURE, payload: err });
        throw new Error('invalid error');
      });
  };
}
export function getAssignmentsForLateSubmission({ courseID, email }: any) {
  return (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'showLogin'.
      return dispatch(showLogin({ redirect: location.pathname }));
    }
    dispatch({ type: types.GET_ASSIGNMENTS_FOR_LATE_SUBMISSION });

    return adminService()
      .getAssignmentsForLateSubmission({ courseID, email })
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({
            type: types.GET_ASSIGNMENTS_FOR_LATE_SUBMISSION_SUCCESS,
            payload: {
              assignments: res.data.assignments,
              student: res.data.student,
              prof: res.data.prof,
            },
          });
          return res.data.assignments;
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.GET_ASSIGNMENTS_FOR_LATE_SUBMISSION_FAILURE, payload: err });
        throw new Error('invalid error');
      });
  };
}

export function getPotentialReviewersForLateSubmission({ assignmentID, studentID }: any) {
  return (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'showLogin'.
      return dispatch(showLogin({ redirect: location.pathname }));
    }
    dispatch({ type: types.GET_POTENTIAL_REVIEWERS_FOR_LATE_SUBMISSION });

    return adminService()
      .getPotentialReviewersForLateSubmission({ assignmentID, studentID })
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({
            type: types.GET_POTENTIAL_REVIEWERS_FOR_LATE_SUBMISSION_SUCCESS,
            payload: {
              potentialReviewers: res.data.potentialReviewers,
              files: res.data.files,
              content: res.data.content,
              numOfEvalsProfWants: res.data.numOfEvalsProfWants,
            },
          });
        }
      })
      .catch((err: any) => {
        dispatch({
          type: types.GET_POTENTIAL_REVIEWERS_FOR_LATE_SUBMISSION_FAILURE,
          payload: err,
        });
        throw new Error('invalid error');
      });
  };
}

export function findCreationForMissedEvaluation({ assignment, student, creationNumber }: any) {
  return async (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'showLogin'.
      return dispatch(showLogin({ redirect: location.pathname }));
    }
    dispatch({ type: types.FIND_CREATION_FOR_MISSED_EVALUATION });
    try {
      const res = await adminService().findCreationForMissedEvaluation({
        assignment,
        student,
        creationNumber,
      });
      if (res && res.status === 200) {
        dispatch({
          type: types.FIND_CREATION_FOR_MISSED_EVALUATION_SUCCESS,
          payload: { creation: res.data },
        });
        return res.data;
      }
    } catch (err) {
      dispatch({
        type: types.FIND_CREATION_FOR_MISSED_EVALUATION_FAILURE,
        payload: err,
      });
      throw new Error('invalid error');
    }
  };
}

export function getCourseAndInstitutionInfo({ email, courseId }: any) {
  return async (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'showLogin'.
      return dispatch(showLogin({ redirect: location.pathname }));
    }
    dispatch({ type: types.GET_COURSE_AND_INSTITUTION_INFO });

    try {
      const res = await adminService().getCourseAndInstitutionInfo({ email, courseId });
      if (res && res.status === 200) {
        return dispatch({ type: types.GET_COURSE_AND_INSTITUTION_INFO_SUCCESS, payload: res.data });
      }
    } catch (err) {
      dispatch({ type: types.GET_COURSE_AND_INSTITUTION_INFO_SUCCESS_FAILURE, payload: err });
      throw new Error('invalid error');
    }
  };
}

export function findEvaluationForMissedFeedback({ assignment, student, evaluationNumber }: any) {
  return async (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'showLogin'.
      return dispatch(showLogin({ redirect: location.pathname }));
    }
    dispatch({ type: 'FIND_EVALUATION_FOR_MISSED_FOF' });
    try {
      const res = await adminService().findEvaluationForMissedFof({
        assignment,
        student,
        evaluationNumber,
      });
      if (res && res.status === 200) {
        dispatch({
          type: 'FIND_EVALUATION_FOR_MISSED_FOF_SUCCESS',
        });
        return res.data;
      }
    } catch (err) {
      dispatch({
        type: 'FIND_EVALUATION_FOR_MISSED_FOF_FAILURE',
        payload: err,
      });
      throw new Error('invalid error');
    }
  };
}

export function createMissedFeedback({ evaluationId, newFof, notifications }: any) {
  return async (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'showLogin'.
      return dispatch(showLogin({ redirect: location.pathname }));
    }
    dispatch({ type: 'CREATE_MISSED_FEEDBACK' });

    try {
      const createMissedFeedbackResponse = await adminService().createMissedFof({
        evaluationId,
        newFof,
        notifications,
      });
      if (createMissedFeedbackResponse && createMissedFeedbackResponse.status === 200) {
        return dispatch({ type: 'CREATE_MISSED_FEEDBACK_SUCCESS' });
      }
    } catch (err) {
      dispatch({ type: 'CREATE_MISSED_FEEDBACK_FAILURE', payload: err });
      throw err;
    }
  };
}

export function changeCoursePriceAndCurrency({ courseId, price, currency }: any) {
  return async (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'showLogin'.
      return dispatch(showLogin({ redirect: location.pathname }));
    }
    dispatch({ type: 'CHANGE_COURSE_PRICE_AND_CURRENCY' });

    try {
      const res = await adminService().changeCoursePriceAndCurrency({ courseId, price, currency });
      if (res && res.status === 200) {
        return dispatch({ type: 'CHANGE_COURSE_PRICE_AND_CURRENCY_SUCCESS' });
      }
    } catch (err) {
      dispatch({ type: 'CHANGE_COURSE_PRICE_AND_CURRENCY_FAILURE', payload: err });
      throw new Error('invalid error');
    }
  };
}

export function verifyInstitution(domain: any) {
  return async (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'showLogin'.
      return dispatch(showLogin({ redirect: location.pathname }));
    }
    dispatch({ type: types.VERIFY_INSTITUTION });
    try {
      const res = await adminService().verifyInstitution({ domain });
      if (res && res.status === 200) {
        dispatch({
          type: types.VERIFY_INSTITUTION_SUCCESS,
          payload: { institution: res.data },
        });
      }
    } catch (err) {
      dispatch({ type: types.VERIFY_INSTITUTION_FAILURE, payload: err });
      throw new Error('invalid error');
    }
  };
}

export function recalculateWeightedAvgs() {
  return (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'showLogin'.
      return dispatch(showLogin({ redirect: location.pathname }));
    }
    dispatch({ type: 'RECALCULATE_WEIGHTED_AVGS' });
    return (adminService() as any)
      .recalculateWeightedAvgs()
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({ type: 'RECALCULATE_WEIGHTED_AVGS_SUCCESS' });
        }
      })
      .catch((err: any) => {
        dispatch({ type: 'RECALCULATE_WEIGHTED_AVGS_FAILURE', payload: err });
        throw new Error('invalid error');
      });
  };
}

export function getInstitutions() {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'GET_INSTITUTIONS' });
    return institutionService()
      .list()
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, [institutionSchema]);
          dispatch(mergeEntities(normalized.entities));
          dispatch({ type: 'GET_INSTITUTIONS_SUCCESS' });
        }
      })
      .catch((err: any) => {
        dispatch({ type: 'GET_INSTITUTIONS_FAILURE', payload: err });
        throw new Error('invalid error');
      });
  };
}

export function sendCouponEmails(params: any) {
  return async (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'showLogin'.
      return dispatch(showLogin({ redirect: location.pathname }));
    }
    dispatch({ type: types.SEND_COUPON_EMAIL_REQUEST });
    try {
      const sendEmailResponse = await adminService().sendCouponEmails(params);
      if (sendEmailResponse && sendEmailResponse.status === 200) {
        return dispatch({ type: types.SEND_COUPON_EMAIL_SUCCESS });
      }
    } catch (err) {
      dispatch({ type: types.SEND_COUPON_EMAIL_FAILURE, payload: err });
      throw new Error('invalid error');
    }
  };
}

export function createSingleCoupon(params: any) {
  return async (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'showLogin'.
      return dispatch(showLogin({ redirect: location.pathname }));
    }
    dispatch({ type: types.CREATE_SINGLE_COUPON_REQUEST });
    try {
      const createSingleCouponResponse = await adminService().createSingleCoupon(params);
      if (createSingleCouponResponse && createSingleCouponResponse.status === 200) {
        return dispatch({ type: types.CREATE_SINGLE_COUPON_SUCCESS });
      }
    } catch (err) {
      dispatch({ type: types.CREATE_SINGLE_COUPON_FAILURE, payload: err });
      throw new Error('invalid error');
    }
  };
}

export function createMultipleCoupons(params: any) {
  return async (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'showLogin'.
      return dispatch(showLogin({ redirect: location.pathname }));
    }
    dispatch({ type: types.CREATE_MULTIPLE_COUPONS_REQUEST });
    try {
      const createMultipleCouponsResponse = await adminService().createMultipleCoupons(params);
      if (createMultipleCouponsResponse && createMultipleCouponsResponse.status === 200) {
        return dispatch({ type: types.CREATE_MULTIPLE_COUPONS_SUCCESS });
      }
    } catch (err) {
      dispatch({ type: types.CREATE_MULTIPLE_COUPONS_FAILURE, payload: err });
      throw new Error('invalid error');
    }
  };
}

export function deleteCoupons(params: any) {
  return async (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'showLogin'.
      return dispatch(showLogin({ redirect: location.pathname }));
    }
    dispatch({ type: types.DELETE_COUPONS_REQUEST });
    try {
      const deleteCouponsResponse = await adminService().deleteCoupons(params);
      if (deleteCouponsResponse && deleteCouponsResponse.status === 200) {
        return dispatch({ type: types.DELETE_COUPONS_SUCCESS });
      }
    } catch (err) {
      dispatch({ type: types.DELETE_COUPONS_FAILURE, payload: err });
      throw new Error('invalid error');
    }
  };
}

export function getCouponData(params: any) {
  return async (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'showLogin'.
      return dispatch(showLogin({ redirect: location.pathname }));
    }
    dispatch({ type: types.GET_COUPON_DATA_REQUEST });
    try {
      const getCouponDataResponse = await adminService().getCouponData(params);
      if (getCouponDataResponse && getCouponDataResponse.status === 200) {
        return dispatch({ type: types.GET_COUPON_DATA_SUCCESS, payload: getCouponDataResponse });
      }
    } catch (err) {
      dispatch({ type: types.GET_COUPON_DATA_FAILURE, payload: err });
      throw new Error('invalid error');
    }
  };
}

export function getHubspotDealsByEmail(params: any) {
  return async (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'showLogin'.
      return dispatch(showLogin({ redirect: location.pathname }));
    }
    dispatch({ type: types.GET_HUBSPOT_DEALS_REQUEST });
    try {
      const res = await adminService().getHubspotDealsByEmail(params);
      if (res && res.status === 200) {
        const payload = {
          deals: res.data,
          email: params.email,
        };
        dispatch({ type: types.GET_HUBSPOT_DEALS_SUCCESS, payload });
        return res.data;
      }
    } catch (err) {
      dispatch({ type: types.GET_HUBSPOT_DEALS_FAILURE, payload: err });
      return false;
    }
  };
}

export function updateHubspotDeal(params: any) {
  return async (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'showLogin'.
      return dispatch(showLogin({ redirect: location.pathname }));
    }
    dispatch({ type: types.UPDATE_HUBSPOT_DEAL_REQUEST });
    try {
      const res = await adminService().updateHubspotDeal(params);
      if (res && res.status === 200) {
        return dispatch({ type: types.UPDATE_HUBSPOT_DEAL_SUCCESS, payload: res.data });
      }
    } catch (err) {
      dispatch({ type: types.UPDATE_HUBSPOT_DEAL_FAILURE, payload: err });
      throw new Error('invalid error');
    }
  };
}

export function promoteUserToAdmin(params: any) {
  return async () => {
    try {
      const res = await adminService().promoteUserToAdmin(params);
      return res;
    } catch (err) {
      const error = ErrorUtils.getErrorMessageFromResponse((err as any).response);
      return { error: (error as any).message || error };
    }
  };
}

export async function updateUserEmail(params: { currentEmail: string; newEmail: string }) {
  try {
    await adminService().updateUserEmail(params);
    return { success: true };
  } catch (err) {
    const error = ErrorUtils.getErrorMessageFromResponse(err);
    return { success: false, message: error };
  }
}
