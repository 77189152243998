import React from 'react';
import Access from 'components/Access';
import InstructorActivityList from 'components/ActivityList/Instructor';
import StudentActivityList from 'components/ActivityList/Student';

const ActivityList = (props: any) => {
  return (
    <Access
      instructor={
        <InstructorActivityList
          activityList={props.activityList}
          renderHeader={props.renderHeader}
          isFinalized={props.isFinalized}
        />
      }
      student={
        <StudentActivityList activityList={props.activityList} renderHeader={props.renderHeader} />
      }
    />
  );
};

ActivityList.defaultProps = {
  renderHeader: true,
  isFinalized: false,
};

export default ActivityList;
