import { connect } from 'react-redux';
import moment from 'moment';
import { navigateToActivitySchedule } from 'actions/activity';
import { isFinalized, getFinalizedStage } from '@kritik/utils/stage';
import { localize } from 'locales';
import ActionCell from '../ActionCell';

type Props = {
  activity: any;
  isRowHovered: boolean;
  navigateToActivitySchedule: (params: { courseId: string; activity: any }) => void;
};

const ScheduleCell = (props: Props) => {
  if (isFinalized({ assignment: props.activity })) {
    const finalizedStatus = getFinalizedStage(props.activity);
    return moment(finalizedStatus.startDate).format('ddd, MMM D');
  }

  return (
    <ActionCell
      handleAction={() =>
        props.navigateToActivitySchedule({
          courseId: props.activity.course,
          activity: props.activity,
        })
      }
      isRowHovered={props.isRowHovered}
      id={`schedule-${props.activity._id}`}
      actionText={localize({ message: 'Activity.ScheduleActivity' })}
      text={localize({ message: 'Activity.UnscheduledActivity' })}
    />
  );
};

ScheduleCell.defaultProps = {
  isRowHovered: false,
};

export default connect(null, {
  navigateToActivitySchedule,
})(ScheduleCell);
