import { connect } from 'react-redux';
import { DisplayBox } from 'components/layout';
import ScoreDisplay from 'components/Assignment/ActivityListDashboard/ScoreCard/ScoreDisplay';
import { isPercentageGrading } from '@kritik/utils/course';
import { selectCurrentCourse } from 'selectors/course';

type ScoreCardProps = {
  scoreName: string;
  description: string;
  score: number;
  weight: number;
  scoreTrend?: number;
  course: any;
  ariaLabel: string;
};

function ScoreCard({
  scoreName,
  description,
  score,
  weight,
  scoreTrend,
  course,
  ariaLabel,
}: ScoreCardProps) {
  return (
    <div className="score-card__wrapper">
      <DisplayBox>
        <ScoreDisplay
          scoreTrend={scoreTrend}
          score={score}
          scoreName={scoreName}
          weight={weight}
          description={description}
          isPercentageGrading={isPercentageGrading(course)}
          ariaLabel={ariaLabel}
        />
      </DisplayBox>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    course: selectCurrentCourse(state),
  };
};

export default connect(mapStateToProps, {})(ScoreCard);
