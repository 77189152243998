import React from 'react';
import Select from 'react-select';
import FormField from 'components/core/form/Field';
import FormFieldLabel from 'components/core/form/FieldLabel';

const MultiSelection = (props: any) => {
  return (
    <FormField error={props.error} className={props.className}>
      <FormFieldLabel isRequired={props.isRequired} label={props.label} />
      <div className="kritik-form-field__select" data-testid={props.testid}>
        <Select
          classNamePrefix="kritik-field"
          isMulti
          closeMenuOnSelect={false}
          isLoading={props.isLoading}
          value={props.value}
          onChange={props.onChange}
          options={props.options}
        />
      </div>
    </FormField>
  );
};

MultiSelection.defaultProps = {
  isLoading: false,
  label: '',
  isRequired: false,
  error: null,
  className: '',
};

export default MultiSelection;
