import React from 'react';
import { connect } from 'react-redux';
import Table from 'components/layout/SimpleTable';
import TableRow from 'components/layout/SimpleTable/Row';
import TableHead from 'components/layout/SimpleTable/Head';
import TableBody from 'components/layout/SimpleTable/Body';
import TableCell from 'components/layout/SimpleTable/Cell';
import CourseSelectors from 'selectors/course';
import SyncStatusIcon from 'components/LmsGradeSync/LmsTable/ActivityTable/SyncStatusIcon';
import SyncStatusLabel from 'components/LmsGradeSync/LmsTable/ActivityTable/SyncStatusLabel';
import RowMenu from 'components/LmsGradeSync/LmsTable/ActivityTable/RowMenu';
import LmsColumnCell from 'components/LmsGradeSync/LmsTable/ActivityTable/LmsColumnCell';
import HelpIcon from 'images/lms-grade-sync/help.svg';
import Tooltip from '@material-ui/core/Tooltip';

function LmsActivityTable(props: any) {
  const handleSyncStatusChange = (currentStatus: any, activityId: any) => {
    const newActivitiesSyncStatus: any = [];
    props.activities.forEach((activity: any) => {
      const newActivityStatus = { ...activity };
      if (newActivityStatus._id === activityId) {
        if (
          activity.status === 'synced' ||
          activity.status === 'not-synced' ||
          activity.status === 'error'
        ) {
          newActivityStatus.status = 'ready-to-sync';
        }
        if (activity.status === 'ready-to-sync') {
          newActivityStatus.status = 'not-synced';
        }
      }
      newActivitiesSyncStatus.push(newActivityStatus);
    });
    props.onChangeActivities(newActivitiesSyncStatus);
  };

  const handleColumnChangeForActivity = ({ lineItem, activityId }: any) => {
    const updatedActivities: any = [];
    props.activities.forEach((activity: any) => {
      const _activity = { ...activity };
      if (_activity._id === activityId) {
        _activity.column = lineItem;
      }
      updatedActivities.push(_activity);
    });
    props.onChangeActivities(updatedActivities);
  };

  const handleOnChangeRadioSelection = ({ activityId, radioValue }: any) => {
    const updatedActivities: any = [];
    props.activities.forEach((activity: any) => {
      const _activity = { ...activity };
      if (_activity._id === activityId) {
        _activity.status = radioValue;
      }
      updatedActivities.push(_activity);
    });
    props.onChangeActivities(updatedActivities);
  };

  const renderRow = ({ title, activityId, lmsGradeSyncInformation, status, column }: any) => {
    const syncDate = lmsGradeSyncInformation.lastSyncAt || null;
    return (
      <TableRow>
        <TableCell>{title}</TableCell>
        <TableCell>
          <SyncStatusIcon
            status={status}
            activityId={activityId}
            handleSyncStatusChange={(currentStatus: any, activityId: any) => {
              handleSyncStatusChange(currentStatus, activityId);
            }}
          />
        </TableCell>
        <TableCell>
          <LmsColumnCell
            lmsColumns={props.lmsColumns}
            lmsGradeSyncInformation={lmsGradeSyncInformation}
            selectedColumn={column}
            setSelectedColumn={handleColumnChangeForActivity}
            activityId={activityId}
          />
        </TableCell>
        <TableCell width="">
          <SyncStatusLabel status={status} lastSyncAt={syncDate} />
        </TableCell>
        <TableCell>
          <RowMenu
            activityId={activityId}
            onChangeRadioSelection={handleOnChangeRadioSelection}
            syncStatus={status}
          />
        </TableCell>
      </TableRow>
    );
  };

  const TableHeadCell = ({ children }: any) => {
    return <div className="activity-table-cell__head">{children}</div>;
  };

  return (
    <div>
      <Table>
        <TableHead>
          <TableCell width="35%">
            <TableHeadCell>Kritik Scores</TableHeadCell>
          </TableCell>
          <TableCell></TableCell>
          <TableCell>
            <TableHeadCell>LMS Columns</TableHeadCell>
          </TableCell>
          <TableCell width="20%">
            <TableHeadCell>Status</TableHeadCell>
          </TableCell>
          <TableCell width="5%">
            <Tooltip
              title="To sync your activity, please press the Sync Activities button below the activity list"
              placement="top"
            >
              <div className="activity-table-cell__head-help-icon">
                <img src={HelpIcon} aria-hidden="true" />
              </div>
            </Tooltip>
          </TableCell>
        </TableHead>
        <TableBody>
          {props.activities.map((item: any) => {
            return renderRow({
              title: item.title,
              activityId: item._id,
              lmsGradeSyncInformation: item.lmsGradeSyncInformation,
              status: item.status,
              column: item.column,
            });
          })}
        </TableBody>
      </Table>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    course: CourseSelectors.getCourse(state, state.selected.courseId),
  };
};

export default connect(mapStateToProps)(LmsActivityTable);
