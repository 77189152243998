import RadioInput from 'components/core/input/Radio';
import FormTitle from 'components/core/form/Title';
import FormInfo from 'components/core/form/FieldInfo';
import { Field } from 'react-final-form';
import validators from 'components/ActivityEdit/Details/Fields/validators';
import { fileRestrictionStatus } from '@kritik/constants/activity';
import { AdvancedOptionField } from 'components/ActivityEdit/AdvancedOptions/Field';
import FileRestrictionsSelection from './FileRestrictionsSelection';
import { localize } from 'locales';

const AttachmentType = (props) => {
  const getRadioOptions = () => {
    const radioOptions = [
      {
        value: fileRestrictionStatus.UNRESTRICTED,
        label: localize({ message: 'Activity.FileRestrictionStatus.Unrestricted' }),
        testid: 'all-files-option',
      },
      {
        value: fileRestrictionStatus.SOMEFILES,
        label: localize({ message: 'Activity.FileRestrictionStatus.SomeFiles' }),
        testid: 'some-files-option',
      },
      {
        value: fileRestrictionStatus.ALL,
        label: localize({ message: 'Activity.FileRestrictionStatus.AllFiles' }),
        testid: 'no-files-option',
      },
    ];
    return radioOptions;
  };

  const renderConditional = () => {
    return (
      <Field name="restrictFileTypes" subscription={{ value: true }}>
        {({ input: { value } }) => {
          if (value === fileRestrictionStatus.SOMEFILES) {
            return <FileRestrictionsSelection isDisabled={props.disabled} />;
          }
          return null;
        }}
      </Field>
    );
  };

  return (
    <AdvancedOptionField>
      <FormTitle label={localize({ message: 'Activity.FileAttachmentField.Label' })} />
      <Field name="restrictFileTypes" validate={validators.isRequired}>
        {({ input }) => {
          return (
            <RadioInput
              value={input.value}
              options={getRadioOptions()}
              onChange={input.onChange}
              type="button"
              legendText="Legend.Text.FileAttachmentOptions"
              isDisabled={props.disabled}
            />
          );
        }}
      </Field>
      <FormInfo label={localize({ message: 'Activity.FileAttachmentField.Info' })} />
      {renderConditional()}
    </AdvancedOptionField>
  );
};

export default AttachmentType;
