import { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { SignupText, SignupHeader } from 'components/AuthWrapper/components';
import axios from 'axios';
import SearchLocation from './SearchLocation';

type FindTimeZoneProps = {
  setTimeZone: any;
  timeZone: any;
  newInstitution: string;
  setNewInstitution: any;
  setGeoNamesErrorMessage: any;
};

export function FindTimeZone({
  setTimeZone,
  timeZone,
  newInstitution,
  setNewInstitution,
  setGeoNamesErrorMessage,
}: FindTimeZoneProps) {
  const [isGeoNamesWorking, setIsGeoNamesWorking] = useState({ value: true, checked: false });

  const _isGeoNamesWorking = async () => {
    // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
    const geonamesUsername = App.config.get('geonamesUsername');
    const testUrl = `https://secure.geonames.org/searchJSON?name_startsWith=Winnipeg&username=${geonamesUsername}`;
    try {
      const res = await axios.get(testUrl);
      if (!res.data.geonames) {
        throw res;
      }
      setIsGeoNamesWorking({ value: true, checked: true });
    } catch (err) {
      setGeoNamesErrorMessage(
        (err as any).response
          ? (err as any).response.data.status.message
          : (err as any).data.status.message
      );
      setIsGeoNamesWorking({ value: false, checked: true });
    }
  };

  useEffect(() => {
    if (isGeoNamesWorking.checked === false) {
      void _isGeoNamesWorking();
    }
  }, []);

  const onSelectLocation = (timezoneId: string) => {
    onChangeTZ({ value: timezoneId });
  };

  const onSelectTimezone = (e: any, value: any) => {
    onChangeTZ({ value });
  };

  const onChangeTZ = (change: any) => {
    setTimeZone({ ...timeZone, ...change });
  };

  return isGeoNamesWorking.value ? (
    <div style={{ margin: '30px 0' }}>
      <SignupHeader size="sm">Provide the name of your institution</SignupHeader>
      <div style={{ margin: '10px 0 30px' }}>
        <TextField
          fullWidth
          value={newInstitution}
          label="Institution Name"
          variant="outlined"
          onChange={setNewInstitution}
        />
      </div>
      <SignupHeader size="sm">Provide the location of your institution</SignupHeader>
      <SignupText>This will help us set your time zone and currency</SignupText>
      <SearchLocation
        onSelect={onSelectLocation}
        timeZone={timeZone}
        setTimeZone={onSelectTimezone}
      />
      <p style={{ marginTop: '5px', float: 'right' }}>
        Powered by
        <a target="_blank" href="https://www.geonames.org">
          GeoNames
        </a>
      </p>
    </div>
  ) : null;
}
