import { User } from '@kritik/types.generated';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AuthUser } from 'app-types';
import { useSelector } from 'react-redux';
import { adminService, userService, authService } from 'services';
import { useViewAsStudentExitUrl } from './activity';

export function useFetchUser(email: string) {
  return useQuery(
    ['userEmail', email],
    async () => {
      const result = await userService({}).getUserByEmail({ email });
      return result.data as User;
    },
    { enabled: Boolean(email), retry: 0, cacheTime: 0, staleTime: 0 }
  );
}

export function deleteUserMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      const result = await adminService({}).deleteUser({ id });
      return result.data;
    },
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(['userEmail', data.email]);
      },
    }
  );
}

export function useAuthUserFromRedux() {
  return useSelector((state: any) => state.user.authUser as AuthUser);
}

export function handleSetStudentViewMutation() {
  return useMutation(async ({ studentId }: { studentId: string }) => {
    await authService().viewAsStudent({ studentId });
  });
}

export function stopImpersonatingMutation() {
  const { viewAsStudentExitUrl } = useViewAsStudentExitUrl();
  // @ts-expect-error Cannot find name App
  const impersonate = App.config.get('impersonate');
  return useMutation(
    async () => {
      await authService().stopImpersonating();
    },
    {
      onSuccess: () => {
        window.location.href =
          impersonate.asAdmin || !viewAsStudentExitUrl ? '/' : viewAsStudentExitUrl;
      },
    }
  );
}
