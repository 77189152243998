import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  setTableType,
  setCreationTableFilter,
  setTableListBy,
  setCreationTableActivity,
} from 'redux/creationTable/actions';
import { selectTableSettings } from 'redux/creationTable/select';
import { getAssignment } from 'selectors/activity';
import ProgressTable from 'components/CreationList/ProgressTable';
import ScoringTable from 'components/CreationList/ScoringTable';
import DisputeTable from 'components/CreationList/DisputeTable';
import TableMenu from 'components/CreationList/Menu';
import { TABLE_TYPE_OPTIONS, FILTERS, LIST_BY_TYPES } from 'components/CreationList/constant';
import PaginationMenu from 'components/CreationList/PaginationMenu';
import { getActivityScores } from 'redux/activityScores/actions';
import * as activityUtils from '@kritik/utils/activity';
import { Alert } from '@material-ui/lab';
import { Outlet } from 'react-router';

const CreationList = (props: any) => {
  const [error, setError] = useState(null);
  const [itemCount, setItemCount] = useState(0);
  useEffect(() => {
    if (props.tableSettings.activityId !== props.activity._id) {
      const searchParams = new URLSearchParams(window.location.search);
      const viewType = searchParams.get('viewType');
      if (viewType && viewType !== TABLE_TYPE_OPTIONS.PROGRESS) {
        props.setTableType(viewType);
      } else {
        props.setTableType(TABLE_TYPE_OPTIONS.PROGRESS);
      }
      if (props.activity) {
        if (
          activityUtils.isGroupAssignment(props.activity) ||
          activityUtils.isGroupPresentationActivity(props.activity)
        ) {
          props.setTableListBy(LIST_BY_TYPES.GROUP);
        } else {
          props.setTableListBy(LIST_BY_TYPES.STUDENT);
        }
      } else {
        props.setTableListBy(LIST_BY_TYPES.STUDENT);
      }
      props.setCreationTableActivity(props.activity._id);
    }
    return function cleanUp() {
      props.setCreationTableFilter(FILTERS.SHOW_ALL);
    };
  }, []);

  const renderTable = () => {
    switch (props.tableSettings.type) {
      case TABLE_TYPE_OPTIONS.DISPUTE:
        return <DisputeTable setItemCount={setItemCount} />;
      case TABLE_TYPE_OPTIONS.SCORING:
        return <ScoringTable />;
      case TABLE_TYPE_OPTIONS.SCORE_COMPARISON:
        return <Outlet />;
      case TABLE_TYPE_OPTIONS.PROGRESS:
      default:
        return <ProgressTable />;
    }
  };

  const disableFilter = props.tableSettings.type === TABLE_TYPE_OPTIONS.DISPUTE;
  const isPresentationActivityType = activityUtils.isPresentationActivity(props.activity);

  return (
    <div className="creation-table-wrapper" key={`creation-table-${props.activity._id}`}>
      <div className="creation-table-wrapper__menu">
        {error && (
          <Alert
            severity="error"
            onClose={() => {
              setError(null);
            }}
          >
            {error}
          </Alert>
        )}
        <TableMenu
          renderSearch={!isPresentationActivityType}
          disabled={disableFilter}
          setError={setError}
          itemCount={itemCount}
        />
        {!isPresentationActivityType && <PaginationMenu />}
      </div>
      <div className="creation-table-wrapper__table">{renderTable()}</div>
      {!isPresentationActivityType && <PaginationMenu />}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    tableSettings: selectTableSettings(state),
    activity: getAssignment(state, state.selected.assignmentId),
  };
};

export default connect(mapStateToProps, {
  setTableType,
  setCreationTableFilter,
  setTableListBy,
  setCreationTableActivity,
  getActivityScores,
})(CreationList);
