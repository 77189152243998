export const disputeStatuses = {
  NO_DISPUTE: 'No dispute',
  DISPUTE: 'Dispute',
  RESOLVED: 'Resolved',
};

export enum userSubmissionStatuses {
  EMPTY = 'EMPTY', // creation has no content yet
  IN_PROGRESS = 'IN_PROGRESS', // creation has content, but creation stage has not yet finished
  ON_TIME = 'ON_TIME', // submitted during creation period
  GRACE = 'GRACE', // submitted during grace period
  LATE = 'LATE', // late creation accepted by prof
  MISSED = 'MISSED',
  NOT_REQUIRED = 'NOT_REQUIRED', // For presentation activities
}

export default {
  disputeStatuses,
  userSubmissionStatuses,
};
