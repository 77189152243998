import { getActivityWeight, isCalibrationActivity } from '@kritik/utils/activity';
import InlineInformation from 'components/layout/InlineInformation';
import { MIN_WEIGHT } from '@kritik/constants/activity';
import { useUserRoleInCourse } from 'hooks/course';

const WeightWarning = (props: any) => {
  const { isInstructorInCourse } = useUserRoleInCourse();

  if (isInstructorInCourse) {
    if (isCalibrationActivity(props.activity)) {
      return (
        <InlineInformation type="information">
          Calibration activities affect the students' grading power but not their overall score.
        </InlineInformation>
      );
    }
    if (getActivityWeight(props.activity) === MIN_WEIGHT) {
      return (
        <InlineInformation type="information">
          The activity has no grading weight and will not count towards the overall grade of your
          students within Kritik.
        </InlineInformation>
      );
    }
  } else {
    if (isCalibrationActivity(props.activity)) {
      return (
        <InlineInformation type="information">
          Calibration activities affect your grading power but not your overall score.
        </InlineInformation>
      );
    }
    if (getActivityWeight(props.activity) === MIN_WEIGHT) {
      return (
        <InlineInformation type="information">
          The activity has no grading weight and will not count towards your Overall Score in
          Kritik.
        </InlineInformation>
      );
    }
  }
  return null;
};

export default WeightWarning;
