import React from 'react';
import classNames from 'classnames';

function ScoreDecorator(props: any) {
  const classes = classNames('grading-score kritik-score-decorator', {
    'kritik-score-decorator--lg': props.size === 'lg',
  });
  return (
    <span className={classes} data-testid="grading-score">
      {props.children}
    </span>
  );
}

ScoreDecorator.defaultProps = {
  size: 'default',
};

export default ScoreDecorator;
