import STAGE_STATUS_ICONS from 'images/status-icons';
import StageTitle from '../StageTitle';
import ScheduleDateInput from 'components/schedule/DateInput';
import ArrowDown from '../ArrowDown';
import InlineInformation from 'components/layout/InlineInformation';
import { localize } from 'locales';

type CreateProps = {
  disabled?: boolean;
  startDate: number;
  onDateChange: (date: Date) => void;
  error: string;
};

function Create(props: CreateProps) {
  return (
    <div>
      {props.error && (
        <div className="create__error-container">
          <InlineInformation
            title={localize({ message: 'Activity.Schedule.InvalidSchedule' })}
            type="danger"
          >
            {props.error}
          </InlineInformation>
        </div>
      )}
      <div className="create__container">
        <div className="create__icon-container">
          <img
            src={props.disabled ? STAGE_STATUS_ICONS.CREATE_DISABLED : STAGE_STATUS_ICONS.CREATE}
            aria-hidden="true"
          />
          <ArrowDown />
        </div>
        <div>
          <StageTitle title={localize({ message: 'Activity.Schedule.ActivityStart' })} />
          <p>{localize({ message: 'Activity.Schedule.ActivityStart.Note' })}</p>
          <ScheduleDateInput
            disabled={props.disabled}
            label={localize({ message: 'Activity.Schedule.StartDate' })}
            labelColor="dark"
            value={props.startDate}
            onDateChange={props.onDateChange}
          />
        </div>
      </div>
    </div>
  );
}

export default Create;
