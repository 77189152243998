import * as _ from 'lodash-es';

import { getCourse, getCourses } from 'actions/courses';
import { getAssignments } from 'actions/activity';
import { listRubrics } from 'actions/rubrics';

const list = ({ params, client }: any, { dispatch, getState }: any) => {
  return new Promise((resolve, reject) => {
    dispatch(getCourses(params, { client, callback: resolve, reject }));
  }).catch((err) => {
    throw err;
  });
};

const get = ({ params, client }: any, { dispatch, getState }: any) => {
  return new Promise((resolve, reject) => {
    dispatch(getCourse({ id: params.courseId }, { client, callback: resolve, reject }));
  }).catch((err) => {
    throw err;
  });
};

const getWithoutRefetch = ({ params, client }: any, { dispatch, getState }: any) => {
  const currentState = getState();

  if (currentState.entities.courses[params.courseId]) {
    return Promise.resolve();
  }
  return new Promise((resolve, reject) => {
    dispatch(getCourse({ id: params.courseId }, { client, callback: resolve, reject }));
  }).catch((err) => {
    throw err;
  });
};

const getCourseAssignments = ({ params, client }: any, { dispatch, getState }: any) => {
  return new Promise((resolve, reject) => {
    dispatch(getAssignments({ courseId: params.courseId }, { client, callback: resolve }));
  });
};

const getCourseRubrics = ({ params, client }: any, { dispatch, getState }: any) => {
  return new Promise((resolve, reject) => {
    dispatch(listRubrics({ courseId: params.courseId }, { client, callback: resolve }));
  });
};

const getCourseAndAssignments = ({ params, client }: any, store: any) => {
  return Promise.all([
    get({ params, client }, store),
    getCourseAssignments({ params: { courseId: params.courseId }, client }, store),
    getCourseRubrics({ params: { courseId: params.courseId }, client }, store),
  ]);
};

const getCourseAndAssignmentsWithoutRefetch = ({ params, client }: any, store: any) => {
  const currentState = store.getState();

  /**
   * if the entity exists but the `students` property is not defined,
   * it means that we don't have the full course data yet, only the data loaded in the `list`
   * endpoint, which doesn't have a  bunch of fields. In this case, we refetch the course data.
   */
  if (currentState.entities.courses[params.courseId]?.students !== undefined) {
    return getCourseAssignments({ params: { courseId: params.courseId }, client }, store);
  }
  return Promise.all([
    get({ params, client }, store),
    getCourseAssignments({ params: { courseId: params.courseId }, client }, store),
  ]);
};

const getCourseAndAssignmentWithoutRefetch = ({ params, client }: any, store: any) => {
  const currentState = store.getState();

  if (currentState.entities.courses[params.courseId]) {
    return getCourseAssignments({ params: { courseId: params.courseId }, client }, store);
  }
  return Promise.all([
    get({ params, client }, store),
    getCourseAssignments({ params: { courseId: params.courseId }, client }, store),
  ]);
};

export default {
  get,
  getCourseAndAssignments,
  getCourseAssignments,
  list,
  getCourseAndAssignmentsWithoutRefetch,
  getWithoutRefetch,
  getCourseAndAssignmentWithoutRefetch,
};
