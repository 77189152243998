import { useRef } from 'react';
import DatePicker from 'react-datepicker';

// Adding a custom input allows to pass aria attributes
const CustomInput = ({ className, value, onClick, onFocus, id, ...props }: any) => (
  <input
    className={className}
    type="text"
    id={id}
    value={value}
    onClick={onClick}
    onFocus={onFocus}
    readOnly
    aria-label={props['aria-label']}
  />
);

const DateInput = (props: any) => {
  const datePickerRef = useRef(null);
  const handleFocus = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };
  return (
    <DatePicker
      ref={datePickerRef}
      selected={props.value}
      disabled={props.disabled}
      minDate={props.minDate}
      onChange={props.onChange}
      id={props.id}
      customInput={
        <CustomInput
          className="date-input"
          id={props.id}
          aria-label={props['aria-label']}
          onFocus={handleFocus}
        />
      }
    />
  );
};

DateInput.defaultProps = {
  disabled: false,
};

export default DateInput;
