import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouterProp, withRouter } from 'utils/withRouter';
import { push } from 'router';
import { getCourse } from 'selectors/course';
import { getStudentFromUser } from 'selectors/student';
import { getStudentInCourse, getUserByEmail } from 'actions/users';
import { selectCourse } from 'actions/select';
import { getFromEnrollLink, addCollaborator } from 'actions/courses';
import { reEnrollStudentInCourse } from 'actions/payments';
import {
  canJoinCourse,
  hasCourseInviteAsCollaborator,
  hasCourseInviteAsStudent,
} from 'utils/permission';
import Button from 'components/buttons/Button';
import Checkout from 'components/Course/enrollment';
import Login from 'components/auth/Login';
import bcrypt from 'bcryptjs';
import { displayCurrencyPrice } from '@kritik/utils/format';
import { isAccountActive } from 'utils/user';
import { InlineInformation } from 'components/layout';
import {
  SignupContainer,
  SignupMainTitle,
  SignupButtons,
  SignupContent,
  SignupHeader,
  SignupText,
  SignupFooter,
} from 'components/AuthWrapper';
import AcceptedCreditCards from 'components/payment/AcceptedCreditCards';
import AccountActivation from 'components/auth/AccountActivation';
import HtmlContent from 'components/General/HtmlContent';
import { FormFieldNotification } from 'components/Form';
import { studentService } from 'services';
import Spinner from 'components/Loaders/Spinner';

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    student: getStudentFromUser(state, state.selected.courseId),
    course: getCourse(state),
  };
};

type RegisterLandingPageState = any;

type RegisterLandingPageProps = {
  user: any;
  student: any;
  course: any;
  router: RouterProp;
  getFromEnrollLink: ({ courseId }: { courseId: string }) => void;
  getStudentInCourse: any;
  addCollaborator: ({ courseId }: { courseId: string }) => Promise<any>;
  getUserByEmail: ({ email }: { email: string }) => Promise<any>;
  reEnrollStudentInCourse: any;
  getStudentFromUser: any;
  getCourse: any;
};

class RegisterLandingPage extends Component<RegisterLandingPageProps, RegisterLandingPageState> {
  constructor(props) {
    super(props);
    this.state = {
      isPaymentInProgress: false,
      isPaymentComplete: false,
      userToRegister: null,
      isEnrollingTA: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    const { params } = this.props.router;
    this.props.getFromEnrollLink({
      courseId: params.courseId,
    });
  }

  async componentDidUpdate(
    prevProps: RegisterLandingPageProps,
    prevState: RegisterLandingPageState
  ) {
    if (this.props.course && !prevProps.course) {
      if (this.props.course.userRole === 'student') {
        this.props.getStudentInCourse({ courseId: this.props.course._id });
        if (this.props.course.price === 0 && !this.props.student) {
          try {
            this.setState({ isLoading: true });
            const response = await studentService().enrollStudentInInstitutionPaidCourse(
              this.props.course._id
            );
            if (response.status === 200) {
              this.props.router.push(`/course/${this.props.course._id}/assignments`);
            }
          } catch (err) {
            this.setState({ isLoading: false });
          }
        }
      }

      const hashedEmail = await this.getEmailFromHash();
      if (hashedEmail) {
        this.props.getUserByEmail({ email: hashedEmail }).then((user: any) => {
          this.setState({ userToRegister: user });
        });
      }
    }
  }

  setPaymentInProgress = () => {
    this.setState({ isPaymentInProgress: true });
  };

  setPaymentComplete = () => {
    this.setState({ isPaymentComplete: true });
  };

  setIsEnrollingTA = (isEnrollingTA: any) => {
    this.setState({ isEnrollingTA });
  };

  cancelRegistration = () => {
    this.props.router.push('/');
  };

  goToCourse = () => {
    this.props.router.push(`/course/${this.props.course._id}`);
  };

  joinAsCollaborator = () => {
    if (!this.state.isEnrollingTA) {
      this.setIsEnrollingTA(true);
      this.props
        .addCollaborator({ courseId: this.props.course._id })
        .then(() => {
          setTimeout(() => {
            this.goToCourse();
          }, 500);
        })
        .catch((err: any) => {
          this.setIsEnrollingTA(false);
        });
    }
  };

  renderCourseInformation = ({ showPrice }: any) => {
    const { course } = this.props;
    let coursePrice;

    if (showPrice) {
      coursePrice = (
        <SignupHeader size="sm" weight="strong" color="default">
          Course Subscription Price:{' '}
          {displayCurrencyPrice(course.price / 100, course.user.institution.currency)}
        </SignupHeader>
      );
    }
    return (
      <React.Fragment>
        <SignupHeader size="md">{course.title}</SignupHeader>
        <SignupHeader size="sm" color="default">
          {course.user.profile.name}
        </SignupHeader>
        <HtmlContent content={course.description} />
        {coursePrice}
      </React.Fragment>
    );
  };

  renderWrongInviteList = () => {
    let userTypeIs;
    let userTypeRequired;
    const isStudent = this.props.course && this.props.course.userRole === 'student';
    if (isStudent) {
      userTypeIs = 'a student';
      userTypeRequired = 'an instructor';
    } else {
      userTypeIs = 'an instructor';
      userTypeRequired = 'a student';
    }
    return (
      <SignupContainer graphic="invite">
        <SignupMainTitle>
          Hi {this.props.user.authUser.profile.firstName}, you have been invited to the following
          course
        </SignupMainTitle>
        {this.renderCourseInformation({ showPrice: isStudent })}
        <SignupText>
          <InlineInformation
            type="danger"
            title={`You can't join the course because you have ${userTypeIs} account`}
          />
        </SignupText>
        <SignupText>
          You were invited to the course to participate as {userTypeRequired}. Since, you have{' '}
          {userTypeIs} account you cannot join.
        </SignupText>
        <SignupHeader size="sm">
          Contact Support via chat to help you resolve this situation.
        </SignupHeader>
      </SignupContainer>
    );
  };

  renderEnrollmentProhibited = () => {
    const { user, course } = this.props;
    // student inv as instructor
    if (
      course.userRole === 'student' &&
      hasCourseInviteAsCollaborator({
        user: user.authUser,
        course,
      })
    ) {
      return this.renderWrongInviteList();
    }
    // instructor inv as student
    if (
      course.userRole === 'instructor' &&
      hasCourseInviteAsStudent({
        user: user.authUser,
        course,
      })
    ) {
      return this.renderWrongInviteList();
    }

    // not in any list
    return (
      <SignupContainer>
        <SignupMainTitle>
          Enrollment in <span className="color-attention">{course.title}</span> is by invite only.
        </SignupMainTitle>
        <SignupText>
          <b>{this.props.user.authUser.email}</b> is not on the course's invite list. Make sure your
          account is using your school email address. If you are still having issues contact your
          instructor or Kritik support.
        </SignupText>
        <SignupButtons>
          <Button
            type="primary"
            onClick={() => {
              return this.cancelRegistration();
            }}
          >
            Go back
          </Button>
        </SignupButtons>
      </SignupContainer>
    );
  };

  renderTAEnrollment = () => {
    return (
      <SignupContainer graphic="invite">
        <SignupMainTitle>
          Hi {this.props.user.authUser.profile.firstName}, you have been invited to collaborate on
          the following course
        </SignupMainTitle>
        {this.renderCourseInformation({ showPrice: false })}
        <SignupButtons>
          <Button
            type="primary"
            onClick={() => {
              return this.joinAsCollaborator();
            }}
            loading={this.state.isEnrollingTA}
            disabled={this.state.isEnrollingTA}
          >
            Join as collaborator
          </Button>
          <Button
            type="secondary"
            onClick={() => {
              return this.cancelRegistration();
            }}
          >
            Cancel
          </Button>
        </SignupButtons>
      </SignupContainer>
    );
  };

  renderStudentEnrollment = () => {
    if (this.state.isPaymentComplete) {
      return this.renderStudentCheckoutSuccess();
    }
    if (this.state.isPaymentInProgress) {
      return this.renderStudentCheckout();
    }
    return this.renderStudentLandingPage();
  };

  renderStudentLandingPage = () => {
    const studentHasPaid = this.props.course?.coursePaid;

    return (
      <SignupContainer graphic="invite">
        <SignupContent>
          <SignupMainTitle>
            Hi {this.props.user.authUser.profile.firstName}, you have been invited to the following
            course
          </SignupMainTitle>
          {!studentHasPaid && (
            <>
              {this.renderCourseInformation({ showPrice: true })}
              <SignupButtons>
                <Button
                  type="primary"
                  onClick={() => {
                    return this.setPaymentInProgress();
                  }}
                  testid="proceed-to-checkout"
                >
                  Proceed to checkout
                </Button>
                <Button
                  type="secondary"
                  onClick={() => {
                    return this.cancelRegistration();
                  }}
                >
                  Cancel
                </Button>
              </SignupButtons>
            </>
          )}

          {studentHasPaid && (
            <>
              <FormFieldNotification message="Payment already received." type="success" />
              <Button
                className="enrollment-checkout-btn"
                type="primary"
                onClick={() => {
                  this.props.reEnrollStudentInCourse(this.props.course._id);
                  this.props.router.push(`/course/${this.props.course._id}/assignments`);
                }}
                testid="enrolled"
              >
                Enroll in Course
              </Button>
            </>
          )}
        </SignupContent>
        <SignupFooter>
          <SignupHeader size="sm" weight="strong" color="default">
            We accept the following payment methods
          </SignupHeader>
          <AcceptedCreditCards />
        </SignupFooter>
      </SignupContainer>
    );
  };

  renderStudentCheckout = () => {
    return (
      <SignupContainer graphic="checkout">
        <SignupContent>
          <SignupHeader size="xl"> Checkout </SignupHeader>
          {this.renderCourseInformation({ showPrice: false })}
          <Checkout
            onSuccess={() => {
              return this.setPaymentComplete();
            }}
            onCancel={() => {
              return this.cancelRegistration();
            }}
          />
        </SignupContent>
      </SignupContainer>
    );
  };

  renderStudentCheckoutSuccess = () => {
    return (
      <SignupContainer status="success" graphic="checkout-success">
        <SignupContent>
          <SignupMainTitle>
            Thank you {this.props.user.authUser.profile.firstName}
            ,
            <br />
            Your payment has been received.
          </SignupMainTitle>
          {this.renderCourseInformation({ showPrice: false })}
          <SignupButtons>
            <Button
              type="primary"
              onClick={() => {
                return this.props.router.push(`/course/${this.props.course._id}/assignments`);
              }}
              testid="enter-course-button"
            >
              Enter your course
            </Button>
          </SignupButtons>
        </SignupContent>
      </SignupContainer>
    );
  };

  async getEmailFromHash() {
    if (!this.props.course) {
      return null;
    }
    if (this.props.user.authenticated) {
      return this.props.user.email;
    }

    const hash = this.props.router.params.enrollId;
    let hashedEmail;

    for (let i = 0; i < this.props.course.approvedStudents.length; i++) {
      const inviteNode = this.props.course.approvedStudents[i];
      const studentEmail = inviteNode.email;
      const studentEmailIsValid = await bcrypt.compare(studentEmail, hash.replace(/qwef/g, '/'));
      if (studentEmailIsValid) {
        hashedEmail = studentEmail;
        break;
      }
    }

    if (!hashedEmail) {
      for (let j = 0; j < this.props.course.approvedCollaborators.length; j++) {
        const instructorEmail = this.props.course.approvedCollaborators[j];
        const instructorEmailIsValid = await bcrypt.compare(
          instructorEmail,
          hash.replace(/qwef/g, '/')
        );
        if (instructorEmailIsValid) {
          hashedEmail = instructorEmail;
          break;
        }
      }
    }

    return hashedEmail;
  }

  render() {
    if (!this.props.user.authenticated) {
      if (this.state.userToRegister) {
        if (isAccountActive(this.state.userToRegister)) {
          return (
            <div className="login-container">
              <Login prefillEmail={this.state.userToRegister.email} accountActive />
            </div>
          );
        }
        return (
          <div className="login-container">
            <AccountActivation userToRegister={this.state.userToRegister} />
          </div>
        );
      }
      return (
        <div className="login-container">
          <Login
            prefillEmail={this.state.userToRegister ? this.state.userToRegister.email : null}
          />
        </div>
      );
    }

    if (!this.props.course || !this.props.user.authenticated) {
      return null;
    }
    if (
      (this.props.student &&
        this.props.course?.studentIds?.find((studentId) => studentId === this.props.student.id) &&
        !this.state.isPaymentInProgress) ||
      this.props.course.userRole === 'instructor'
    ) {
      this.cancelRegistration();
    }

    if (
      !canJoinCourse({
        user: this.props.user.authUser,
        course: this.props.course,
      })
    ) {
      return <div className="container-course-enrollment">{this.renderEnrollmentProhibited()}</div>;
    }

    const content =
      this.props.course.userRole === 'instructor'
        ? this.renderTAEnrollment()
        : this.renderStudentEnrollment();

    return (
      <div className="container-course-enrollment">
        {this.state.isLoading ? <Spinner /> : content}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, {
    push,
    selectCourse,
    getFromEnrollLink,
    getStudentInCourse,
    addCollaborator,
    getUserByEmail,
    reEnrollStudentInCourse,
    getStudentFromUser,
    getCourse,
  })(RegisterLandingPage)
);
