import React from 'react';
import classNames from 'classnames';
import { getGracePeriodLengthInMinutes } from '@kritik/utils/stage';
import { timeDisplayFromMinutes } from '@kritik/utils/format';

type Props = {
  gracePeriodStatus: any;
  state: any;
  testid?: string;
};
function GracePeriodDisplay({ gracePeriodStatus, state, testid }: Props) {
  const lengthInMinutes = getGracePeriodLengthInMinutes(gracePeriodStatus);

  const generateText = () => {
    if (lengthInMinutes === 2 * 60) {
      return '2 hours Grace';
    }
    if (lengthInMinutes === 12 * 60) {
      return '12 hours Grace';
    }
    if (lengthInMinutes === 24 * 60) {
      return '1 day Grace';
    }
    const message = timeDisplayFromMinutes(lengthInMinutes);
    return `${message} grace`;
  };

  const statusClass = state;
  const containerClassNames = classNames('grace-period-display-container', statusClass);
  return (
    <div className={containerClassNames}>
      <div className="grace-period-display-text">
        <span data-testid={testid}>{generateText()}</span>
      </div>
    </div>
  );
}

GracePeriodDisplay.defaultProps = {
  state: 'inactive',
};

export default GracePeriodDisplay;
