import { useEffect } from 'react';
import { connect } from 'react-redux';

import LmsGradeSyncContainer from 'containers/LmsGradeSync';
import Page from 'pages/Page';
import { selectCourse } from 'actions/select';
import { withRouter } from 'utils/withRouter';

function LmsGradeSync(props: any) {
  useEffect(() => {
    props.selectCourse(props.router.params.courseId);
  }, []);
  const pageTitle = () => {
    return 'Lms Grade Sync';
  };

  const pageMeta = () => {
    return [];
  };

  const pageLink = () => {
    return [];
  };

  const pageScript = () => {
    return [];
  };

  function getMetaData() {
    return {
      title: pageTitle(),
      meta: pageMeta(),
      link: pageLink(),
      script: pageScript(),
    };
  }

  return (
    <Page {...getMetaData()}>
      <LmsGradeSyncContainer {...props} />
    </Page>
  );
}

function mapStateToProps(state: any) {
  return {};
}

export default withRouter(connect(mapStateToProps, { selectCourse })(LmsGradeSync));
