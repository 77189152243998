import Rubric from 'components/Rubric/Component';
import { AssignmentPassCriteria } from 'components/Assignment';

const RubricView = ({ rubric, assignment, printFriendly }: any) => {
  return (
    <>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ rubric: any; isEditable: false; }' is not ... Remove this comment to see the full error message */}
      <Rubric rubric={rubric} isEditable={false} printFriendly={printFriendly} />
      <AssignmentPassCriteria assignment={assignment} rubric={rubric} />
    </>
  );
};

export default RubricView;
