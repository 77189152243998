import React from 'react';
import { LATE_STATUS } from '@kritik/utils/creation/lateCreation';
import Modal from 'components/modals/Components/Modal';
import ModalTitle from 'components/modals/Components/Header';
import ModalAction from 'components/modals/Components/Action';
import ModalContent from 'components/modals/Components/Content';

const RejectionWarningModal = ({
  isRejectingApprovedCreation,
  resolveLateCreation,
  cancelRejection,
}: any) => {
  return (
    <Modal isOpen={isRejectingApprovedCreation}>
      <ModalTitle title="You are rejecting already approved late creation" />
      <ModalContent>
        <div className="late-submission-modal__text">
          You will not be able to auto assign evaluators after you reject already approved creation.
        </div>
      </ModalContent>
      <ModalAction
        actions={[
          {
            label: 'Reject',
            type: 'secondary',
            onClick: () => {
              resolveLateCreation(LATE_STATUS.REJECTED, null);
            },
          },
          {
            label: 'Cancel',
            type: 'primary',
            onClick: () => {
              cancelRejection();
            },
          },
        ]}
      />
    </Modal>
  );
};

export default RejectionWarningModal;
