import BUBBLE_BG from './bg-bubbles-purple.svg';
import BUBBLE_BG_SUCCESS from './bg-bubbles-green.svg';
import CHECKOUT from './checkout-default.svg';
import CHECKOUT_SUCCESS from './checkout-success.svg';
import INVITE from './invite.svg';
import LOGO from './logo.svg';
import ROCKETSHIP from './rocketship.svg';
import PLANET from './planet.svg';
import CLASSROOM from './classroom.svg';

export default {
  BUBBLE_BG,
  BUBBLE_BG_SUCCESS,
  CHECKOUT,
  CHECKOUT_SUCCESS,
  INVITE,
  LOGO,
  ROCKETSHIP,
  PLANET,
  CLASSROOM,
};
