import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouterProp, withRouter } from 'utils/withRouter';
import { Link } from 'react-router-dom';
import {
  SignupContainer,
  SignupMainTitle,
  SignupButtons,
  SignupContent,
  SignupHeader,
  SignupText,
} from 'components/AuthWrapper';
import FormCheckbox from 'components/core/input/Checkbox';
import Button from 'components/buttons/Button';
import InstructorSignup from 'components/auth/InstructorSignup';
import { localize } from 'locales/index';

const initialState = {
  isStudentSignup: false,
  isInstructorSignup: false,
  isConfirmedInstructor: false,
  proceedAsInstructor: false,
  allowLtiUserSignup: false,
};

type RegisterProps = {
  router: RouterProp;
};

type RegisterState = any;

class Register extends Component<RegisterProps, RegisterState> {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
  }

  componentDidMount() {
    this.setInitialState();
    const urlParams = new URLSearchParams(document.location.search);
    const allowLtiUserSignUp = urlParams.get('allowLtiUserSignup') === 'true';
    if (allowLtiUserSignUp) {
      this.setState({ allowLtiUserSignup: true });
    }
  }

  componentDidUpdate(prevProps: RegisterProps, prevState: RegisterState) {
    if (this.props.router.location.pathname != prevProps.router.location.pathname) {
      this.setInitialState();
    }
  }

  handleInputChange = (e: any) => {
    const { target } = e;
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  setInitialState = () => {
    const path = this.props.router.location.pathname.split('/').pop();
    switch (path) {
      case 'student':
        this.resetState();
        this.setStudentSignup();
        break;
      case 'instructor':
        this.setInstructorSignup();
        break;
      default:
        break;
    }
  };

  resetState = () => {
    this.setState({
      ...initialState,
    });
  };

  setStudentSignup = () => {
    this.setState({ isStudentSignup: true, isInstructorSignup: false });
  };

  setInstructorSignup = () => {
    this.setState({ isStudentSignup: false, isInstructorSignup: true });
  };

  setConfirmedInstructor = () => {
    this.setState({ isConfirmedInstructor: !this.state.isConfirmedInstructor });
  };

  setProceedAsInstructor = () => {
    this.setState({ proceedAsInstructor: true });
  };

  redirectTo = (route: any) => {
    this.props.router.push(`/signup/${route}`);
  };

  renderLandingPage = () => {
    return (
      <SignupContainer graphic="rocket">
        <SignupMainTitle>
          Let's get started.
          <br />
          Are you an instructor or a student?
        </SignupMainTitle>
        <SignupButtons>
          <Button
            type="secondary"
            onClick={() => {
              return this.redirectTo('instructor');
            }}
            label={localize({ message: 'Button.Label.Instructor' })}
          >
            Instructor
          </Button>
          <Button
            type="secondary"
            onClick={() => {
              return this.redirectTo('student');
            }}
            label={localize({ message: 'Button.Label.Student' })}
          >
            Student
          </Button>
        </SignupButtons>
        <SignupText weight="strong">
          Already have an account?{' '}
          <Link className="underlined-link" to="/login">
            Sign-in
          </Link>
        </SignupText>
      </SignupContainer>
    );
  };

  renderStudentSignup = () => {
    return (
      <SignupContainer graphic="invite">
        <SignupContent>
          <SignupMainTitle>Student sign-up is by instructor invite only</SignupMainTitle>
          <SignupText>
            In order for you to sign up to Kritik, you will need to follow the link emailed to you
            by your instructor.
          </SignupText>
          <SignupText>
            <i>
              This helps identify you to your instructor, and ensures that during grading time there
              are no complications.
            </i>
          </SignupText>
          <SignupHeader size="sm" color="attention">
            Can’t find your email invite?
          </SignupHeader>
          <SignupText>
            Make sure you check your school inbox or your spam folder. If you are still having
            issues, speak with us directly through chat.
          </SignupText>
        </SignupContent>
        <SignupText weight="strong">
          Hmm... now that I think about it,{' '}
          <Link className="underlined-link" to="/signup/instructor">
            I am an instructor.
          </Link>
        </SignupText>
      </SignupContainer>
    );
  };

  renderInstructorSignupConfirmation = () => {
    return (
      <SignupContainer graphic="rocket">
        <SignupMainTitle>We would like to confirm you are an instructor</SignupMainTitle>
        <FormCheckbox
          isChecked={this.state.isConfirmedInstructor}
          label={localize({ message: 'Button.Label.Instructor' })}
          onChange={() => {
            return this.setConfirmedInstructor();
          }}
          type="secondary"
          testid="confirm-is-instructor"
        />
        <SignupButtons>
          <Button
            type="primary"
            disabled={!this.state.isConfirmedInstructor}
            unavailable={!this.state.isConfirmedInstructor}
            onClick={() => {
              return this.setProceedAsInstructor();
            }}
            testid="sign-up-as-instructor"
          >
            Instructor Sign-up
          </Button>
        </SignupButtons>
        <SignupText weight="strong">
          Wait a minute...{' '}
          <Link className="underlined-link" to="/signup/student">
            I'm a student
          </Link>
        </SignupText>
        <SignupText weight="strong">
          Already have an account?{' '}
          <Link className="underlined-link" to="/login">
            Sign-in
          </Link>
        </SignupText>
      </SignupContainer>
    );
  };

  renderSignupFlow = () => {
    if (this.state.proceedAsInstructor) {
      return <InstructorSignup allowLtiUserSignUp={this.state.allowLtiUserSignup} />;
    }
    if (this.state.isInstructorSignup) {
      return this.renderInstructorSignupConfirmation();
    }
    if (this.state.isStudentSignup) {
      return this.renderStudentSignup();
    }
    return this.renderLandingPage();
  };

  render() {
    return <div className="direct-registration-container">{this.renderSignupFlow()}</div>;
  }
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

export default withRouter(connect(mapStateToProps, {})(Register));
