import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Activity } from '@kritik/types.generated';
import ActivityService from 'services/activity';
import CourseService from 'services/course';
import { FIELD_NAME as OBJECTIVE } from 'components/ActivityEdit/Details/Fields/Objective/constants';
import { FIELD_NAME as INSTRUCTIONS } from 'components/ActivityEdit/Details/Fields/Instructions/constants';
import { GOAL_TYPES, ACTIVITY_TEMPLATES } from 'components/ActivityEdit/Details/constants';
import { ACTIVITY_TYPES } from '@kritik/constants/activity';
import { AuthUser } from 'app-types';
import { assignmentService, evaluationService } from 'services';
import * as creationStatus from '@kritik/utils/creation/status';
import { getActiveStatus } from '@kritik/utils/stage';
import { trackEvent } from 'utils/userEvents';
import * as formatUtil from '@kritik/utils/format';
import { updateActivityInReduxStore } from 'actions/activity';
import { useStore } from 'react-redux';
import { AssignmentStatusesValues } from '@kritik/constants/stage';
import { activeStages } from '@kritik/utils/stage';

export function useFetchActivity(activityId: string) {
  const store = useStore();
  return useQuery(
    ['activity', activityId],
    async () => {
      const result = await ActivityService().get({ id: activityId });
      updateActivityInReduxStore(store.dispatch, result.data);
      return result.data as Activity;
    },
    { enabled: Boolean(activityId) }
  );
}

export function useGetCachedActivity(activityId: string) {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  const activity = queryCache.find<Activity>(['activity', activityId]);
  return activity.state.data;
}

export const useCreateAssignment = ({ authUser }: { authUser: AuthUser }) => {
  const navigate = useNavigate();
  return useMutation(
    async ({ redirectTo, ...data }: { data: any; redirectTo?: string }) => {
      const result = await ActivityService().create({ data });
      const course = await CourseService().get({ id: result.data.course });
      return { activity: result.data, redirectTo, courseInfo: course.data };
    },
    {
      mutationKey: ['createAssignment'],
      onSuccess: (result) => {
        const { activity, redirectTo, courseInfo } = result;
        const { course, id } = activity;

        trackEvent('Activity Created', authUser, {
          activityId: activity._id,
          activityName: activity.title,
          activityType: activity.activityType,
          courseId: activity.course,
          courseName: courseInfo.title,
          createdAt: activity.createdAt,
          isMultipleTopics: !!activity.isMultipleTopics,
          numCriteria: activity.rubric ? activity.rubric.grid.length : 0,
        });
        if (activity.startingScore > 0) {
          trackEvent('Activity has a Starting Score', authUser, {
            activityId: activity._id,
            activityName: activity.title,
            startingScore: activity.startingScore,
          });
        }
        trackObjectiveOrInstructionsUpdatedIfChanged(activity, authUser);

        if (redirectTo === 'edit-participation') {
          navigate(`/course/${course}/assignment/${id}/edit-participation`);
        } else {
          navigate(`/course/${course}/assignment/${id}`);
        }
      },
    }
  );
};

export const useEditIndividualParticipationSettings = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  return useMutation(
    async ({
      activityId,
      creatorStudents,
      evaluatorStudents,
      evaluatorType,
    }: {
      creatorStudents: string[];
      evaluatorStudents: string[];
      evaluatorType: string;
      activityId: string;
    }) => {
      await ActivityService().editStudentParticipation({
        activityId,
        data: {
          creatorStudents,
          evaluatorStudents,
          evaluatorType,
        },
      });
    },
    {
      mutationKey: ['editIndividualParticipationSettings'],
      onSuccess: () => {
        onSuccess();
      },
    }
  );
};

// Evaluations

export const useRemoveEvaluation = ({ onSuccess }: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      comment,
      evaluation,
      isInappropriate,
      creation,
      activity,
      authUser,
      evaluatorEmail,
      feedbackerEmail,
    }: {
      comment: string;
      evaluation: any;
      isInappropriate?: boolean;
      creation: any;
      activity: any;
      authUser: any;
      evaluatorEmail: string;
      feedbackerEmail: string;
    }) => {
      if (isInappropriate) {
        await ActivityService().createEvaluationFlag({
          submissionId: creation._id,
          scoreId: evaluation._id,
          data: {
            scoreId: evaluation._id,
            submissionId: creation._id,
            flag: creationStatus.EVAL_FLAGS.RESOLVED,
            comment,
          },
        });
      }
      await evaluationService().removeEvaluation({
        comment,
        evaluation,
        activityId: activity._id,
      });

      const activeStage = getActiveStatus(activity);

      trackEvent('Evaluation Removed', authUser, {
        comment,
        activityId: activity._id,
        courseId: activity.course,
        stage: activeStage.name,
        evaluatorEmail,
        feedbackerEmail,
      });
    },
    {
      mutationKey: ['removeEvaluation'],
      onSuccess: async () => {
        await queryClient.invalidateQueries(['getCreationsToEvaluate']);
        onSuccess();
      },
    }
  );
};

export const useRestoreEvaluation = ({ onSuccess }: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      evaluation,
      isInappropriate,
      creation,
      activity,
      authUser,
      evaluatorEmail,
      feedbackerEmail,
    }: {
      evaluation: any;
      isInappropriate?: boolean;
      creation: any;
      activity: any;
      authUser: AuthUser;
      evaluatorEmail: string;
      feedbackerEmail: string;
    }) => {
      if (isInappropriate) {
        await ActivityService().createEvaluationFlag({
          submissionId: creation._id,
          scoreId: evaluation._id,
          data: {
            scoreId: evaluation._id,
            submissionId: creation._id,
            flag: creationStatus.EVAL_FLAGS.DISMISSED,
          },
        });
      }
      await evaluationService().restoreEvaluation({
        evaluation,
        activityId: activity._id,
      });

      const activeStage = getActiveStatus(activity);

      trackEvent('Evaluation Included', authUser, {
        activityId: activity._id,
        courseId: activity.course,
        stage: activeStage.name,
        evaluatorEmail,
        feedbackerEmail,
      });
    },
    {
      mutationKey: ['restoreEvaluation'],
      onSuccess: async () => {
        await queryClient.invalidateQueries(['getCreationsToEvaluate']);
        onSuccess();
      },
    }
  );
};

function trackObjectiveOrInstructionsUpdatedIfChanged(activity: any, authUser: any) {
  if (activity.activityType !== ACTIVITY_TYPES.CALIBRATION) {
    const activityGoal =
      activity.activityType === ACTIVITY_TYPES.WITHIN_GROUP
        ? GOAL_TYPES.WITHIN_GROUP
        : GOAL_TYPES.QUESTION;

    const template = ACTIVITY_TEMPLATES[activityGoal];

    const deltaFromInitialObjective = getDeltaFromInitialValue({
      newValue: activity[OBJECTIVE],
      initialValue: template[OBJECTIVE],
    });

    const deltaFromInitialInstructions = getDeltaFromInitialValue({
      newValue: activity[INSTRUCTIONS],
      initialValue: template[INSTRUCTIONS],
    });

    if (deltaFromInitialObjective !== 0) {
      trackEvent('Activity Objective Updated', authUser, {
        delta: deltaFromInitialObjective,
        courseId: activity.course.toString(),
        activityId: activity._id,
      });
    }

    if (deltaFromInitialInstructions !== 0) {
      trackEvent('Activity Instructions Updated', authUser, {
        delta: deltaFromInitialInstructions,
        courseId: activity.course.toString(),
        activityId: activity._id,
      });
    }
  }
}

function getDeltaFromInitialValue({ newValue, initialValue }) {
  const newObjectiveLength = formatUtil.getPlainTextFromHTML(newValue).length;
  const initialObjectiveLength = formatUtil.getPlainTextFromHTML(initialValue).length;
  return newObjectiveLength - initialObjectiveLength;
}

export type RevertActivityData = {
  activeStage: (typeof activeStages)[keyof typeof activeStages];
  previousStage: AssignmentStatusesValues;
  canRevert: boolean;
  error?: string;
};

export function useGetActivityValidRevertStage(activityId: string) {
  const { data: revertData, isLoading } = useQuery(
    ['activityValidRevertStage', activityId],
    async () => {
      return await assignmentService().getActivityValidRevertStage({ activityId });
    },
    {
      enabled: Boolean(activityId),
    }
  );

  return { data: revertData?.data, isLoading } as {
    data: RevertActivityData;
    isLoading: boolean;
  };
}

export function useRevertActivityMutation(activityId: string) {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ activityId, revertTo }: { activityId: string; revertTo: string }) => {
      return await assignmentService().revertActivity({ activityId, revertTo });
    },
    {
      mutationKey: ['revertActivity'],
      onSuccess: async () => {
        await queryClient.invalidateQueries(['activity', activityId]);
        await queryClient.invalidateQueries(['activityValidRevertStage', activityId]);
      },
    }
  );
}

export function useAdvanceActivityStageMutation(activityId: string) {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ activityId }: { activityId: string }) => {
      return await assignmentService().advanceActivityStageById(activityId);
    },
    {
      mutationKey: ['revertActivity'],
      onSuccess: async () => {
        await queryClient.invalidateQueries(['activity', activityId]);
        await queryClient.invalidateQueries(['activityValidRevertStage', activityId]);
      },
    }
  );
}

export function useRemoveStudentFromPresentationActivity() {
  return useMutation(
    async ({ activityId, studentId }: { activityId: string; studentId: string }) => {
      return await assignmentService().removeStudentFromPresentationActivity({
        activityId,
        studentId,
      });
    },
    {
      mutationKey: ['removeStudentFromPresentationActivity'],
    }
  );
}

export function useViewAsStudentExitUrl() {
  const key = 'k.viewAsStudentExitUrl';
  return {
    viewAsStudentExitUrl: window.localStorage.getItem(key),
    setViewAsStudentExitUrl: (url: string) => window.localStorage.setItem(key, url),
  };
}
