import React from 'react';
import Search from 'components/core/input/Search';

function GroupSearch({ onSearch }: any) {
  return (
    <div className="group-manager__search">
      <Search onSearch={onSearch} placeholder="Search groups" label="Search groups" />
    </div>
  );
}

export default GroupSearch;
