import React, { useEffect } from 'react';
import classNames from 'classnames';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { FeedbackScoreUtil } from '@kritik/utils/grade';
import * as FeedbackUtil from '@kritik/utils/creation/feedback';
import * as ActivityStatusUtil from '@kritik/utils/stage';
import { CloseCircle, CheckCircle, MinusCircle, Magic } from 'images/icons/fa';
import { useSearchParams } from 'react-router-dom';

export const statuses = {
  SUCCESS: 'success',
  DANGER: 'danger',
  CAUTION: 'caution',
  DEFAULT: 'default',
  AIEVALUATION: 'aiEvaluation',
};

export const TabMenuUtils = {
  getEvaluationTabStatus: ({ evaluation, activity }: { evaluation: any; activity: any }) => {
    if (!evaluation) {
      return ActivityStatusUtil.isFeedbackOrLater({ assignment: activity })
        ? statuses.DANGER
        : statuses.DEFAULT;
    }
    return statuses.SUCCESS;
  },
  getFeedbackTabStatus: (evaluation: any, activity: any) => {
    if (
      FeedbackScoreUtil.isFOFCompleted(evaluation) ||
      FeedbackUtil.isSelfFeedbackToEvaluation(evaluation)
    ) {
      return statuses.SUCCESS;
    }

    if (ActivityStatusUtil.isPastFeedbackDeadline(activity)) {
      return statuses.DANGER;
    }

    return statuses.DEFAULT;
  },
};

const CustomTab = ({ index, label, activeLabel, onSelect }: any) => {
  const tabClasses = classNames('tab-menu__label', {
    'tab-menu__label--active': activeLabel === label.id,
    'tab-menu__label--undone': !label.status || label.status === statuses.DEFAULT,
  });

  const iconWrapper = (icon: any) => {
    const tabIconClasses = classNames('tab__icon', {
      'tab__icon--danger': label.status === statuses.DANGER || label.status === statuses.CAUTION,
      'tab__icon--success': label.status === statuses.SUCCESS,
    });
    return <span className={tabIconClasses}>{icon}</span>;
  };

  const renderIcon = () => {
    switch (label.status) {
      case statuses.DANGER:
        return iconWrapper(<CloseCircle />);
      case statuses.CAUTION:
        return iconWrapper(<MinusCircle />);
      case statuses.SUCCESS:
        return iconWrapper(<CheckCircle />);
      case statuses.AIEVALUATION:
        return iconWrapper(<Magic />);
      default:
        return null;
    }
  };

  const renderLabel = () => {
    const tabPillActive = classNames('tab__icon', {
      'tab-menu-item__pill-active': activeLabel === label.id,
      'tab-menu-item__pill-inactive': activeLabel !== label.id,
    });
    return (
      <div data-testid={`tab-wrapper-${label.id}`}>
        {renderIcon()}
        {label.title}
        {label.pill ? (
          <span className={`tab-menu-item__pill ${tabPillActive}`}>{label.pill}</span>
        ) : null}
      </div>
    );
  };

  return (
    <Tab
      className={tabClasses}
      id={`tab-menu__${label.title}-${index}`}
      key={`tab-menu__${label.title}-${index}`}
      data-testid={`tab-${label.id}`}
      value={label.id}
      label={renderLabel()}
      onClick={() => {
        return onSelect(label.id, index);
      }}
      disableRipple
      tabIndex={0}
      title={label.title}
    />
  );
};

const TabMenu = ({ labels, activeLabel, onSelect }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = Array.from(searchParams).reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

  useEffect(() => {
    if (
      activeLabel === 'pending' ||
      activeLabel === 'students' ||
      activeLabel === 'groups' ||
      activeLabel === 'collaborators'
    ) {
      setSearchParams({ ...params, tab: activeLabel });
    }
  }, [activeLabel]);

  let _activeLabel = activeLabel;
  if (typeof activeLabel === 'string') {
    _activeLabel = labels.findIndex((label: any) => {
      return label.id === activeLabel;
    });
  }

  return (
    <Tabs
      aria-label={'Tab Menu'}
      className="tab-menu"
      variant="scrollable"
      scrollButtons="on"
      classes={{
        indicator: 'tab-menu__active-indicator',
      }}
      TabScrollButtonProps={{
        classes: {
          root: 'tab-menu__scroll-button',
          disabled: 'tab-menu__scroll-button--disabled',
        },
      }}
      value={_activeLabel}
    >
      {labels.map((label: any, index: any) => {
        return (
          <CustomTab
            key={index}
            index={index}
            label={label}
            activeLabel={activeLabel}
            onSelect={onSelect}
            title={label.title}
          />
        );
      })}
    </Tabs>
  );
};

TabMenu.defaultProps = {
  labels: [],
  activeLabel: null,
};

export default TabMenu;
