import React from 'react';
import Page from 'pages/Page';
import LMSConnectionPendingContainer from 'containers/LMSConnectionPendingContainer';

function LMSConnectionPending() {
  return (
    <Page title="LMS Connection Pending" meta={[]} link={[]} script={[]}>
      <LMSConnectionPendingContainer />
    </Page>
  );
}

export default LMSConnectionPending;
