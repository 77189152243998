import { useEffect, useState } from 'react';
import Button from 'components/buttons/Button';
import { Divider } from '@material-ui/core';

import { connect } from 'react-redux';
import { getEdlinkIntegrationsByInstitution } from 'actions/edlink';
import { isEdlinkLmsSetup } from '@kritik/utils/course';
import LmsCoursesModal from 'components/LmsGradeSync/LmsCoursesModal';
import { ConnectedCourse } from '../ConnectedCourse';
import { InlineInformation } from 'components/layout';
import { Course } from '@kritik/types.generated';
import { AuthUser } from 'app-types';
import { TranslatedText } from 'components/TranslatedText';

type Props = {
  authUser: AuthUser;
  course: Course;
  isEdlinkIntegrated: boolean;
};

function IntegrationHelp(props: Props) {
  const [isSelectingCourses, setSelectingCourses] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(document.location.search);
    if (urlSearchParams.get('scroll-to-lms') === 'true') {
      const anchorTarget = document.getElementById('lms-section-course-edit');
      if (anchorTarget) {
        anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, []);

  let integrationHelpText = null;
  let edlinkIntegrationOptions = null;

  if (isSelectingCourses) {
    return (
      <LmsCoursesModal
        course={props.course}
        open={isSelectingCourses}
        close={() => setSelectingCourses(false)}
        isEdlinkIntegrated
        authUser={props.authUser}
      />
    );
  }

  if (!props.isEdlinkIntegrated) {
    const contactSupportURI =
      'https://help.kritik.io/en/articles/6381306-introduction-to-lms-integrations';
    integrationHelpText = (
      <p>
        Connect your LMS to sync your grades. Currently we support Canvas, D2L and Blackboard.
        <a target="_blank" href={contactSupportURI}>
          {' '}
          See instructions{' '}
        </a>{' '}
        to get help with setting up your LMS.
      </p>
    );
  } else {
    integrationHelpText = (
      <p>
        Connect this Kritik course to your Learning Management System (LMS) to sync your grades.
      </p>
    );

    if (isEdlinkLmsSetup(props.course)) {
      edlinkIntegrationOptions = <ConnectedCourse course={props.course} />;
    } else {
      edlinkIntegrationOptions = (
        <Button
          type="secondary"
          testid="setup-lms-button"
          onClick={() => setSelectingCourses(true)}
        >
          Setup LMS
        </Button>
      );
    }
  }

  return (
    <>
      <h2 className="new-header-2" id="lms-section-course-edit">
        Learning Management System
      </h2>
      {integrationHelpText}
      <InlineInformation className="course-integrations-notice">
        <TranslatedText i18nKey="CourseDetails.LMS.Notice" />
      </InlineInformation>
      {edlinkIntegrationOptions}
    </>
  );
}

function CourseIntegrations({
  authUser,
  course,
  getEdlinkIntegrationsByInstitution,
}: {
  authUser: AuthUser;
  course: Course & { user: { institution: { _id: string } } };
  getEdlinkIntegrationsByInstitution: ({
    institutionId,
    courseId,
  }: {
    institutionId: string;
    courseId: string;
  }) => any;
}) {
  const [isEdlinkIntegrated, setIsEdlinkIntegrated] = useState(false);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    async function fetchIntegrations({
      institutionId,
      courseId,
    }: {
      institutionId: string;
      courseId: string;
    }) {
      const res = await getEdlinkIntegrationsByInstitution({ institutionId, courseId });
      setIsEdlinkIntegrated(res.success);
    }
    if (course && !fetched) {
      fetchIntegrations({
        institutionId: course.user.institution._id,
        courseId: course._id,
      })
        .then(() => {
          setFetched(true);
        })
        .catch(() => {});
    }
  }, [fetched]);
  if (!isEdlinkIntegrated) {
    return null;
  }
  return (
    <div className="course-integrations">
      <h2 className="new-header-2">Course Integrations</h2>
      <Divider />
      <IntegrationHelp
        course={course}
        isEdlinkIntegrated={isEdlinkIntegrated}
        authUser={authUser}
      />
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {};
};

export default connect(mapStateToProps, { getEdlinkIntegrationsByInstitution })(CourseIntegrations);
