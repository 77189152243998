import React from 'react';

const StageInstructions = (props: any) => {
  return (
    <div className="stage-instructions">
      <div className="stage-instructions__title">{props.title}</div>
      <div>{props.description}</div>
      {props.children}
    </div>
  );
};

export default StageInstructions;
