import types from 'redux/activityImport/types';

const initialState = {
  courses: [],
  activities: [],
  selectedActivities: [],
  successfullyImportedActivities: [],
  isImporting: false,
  abortImport: false,
};

function importActivityReducer(state = initialState, action: any) {
  switch (action.type) {
    case types.GET_ACTIVITY_IMPORT_COURSES_SUCCESS:
      return {
        ...state,
        courses: [...action.payload],
      };
    case types.GET_ACTIVITIES_TO_IMPORT_SUCCESS:
      return {
        ...state,
        activities: [...action.payload],
      };
    case types.SET_ACTIVITIES_TO_IMPORT:
      return {
        ...state,
        selectedActivities: [...action.payload],
      };
    case types.RESET_ACTIVITIES_TO_IMPORT:
      return {
        ...state,
        activities: [],
      };
    case types.ACTIVITY_IMPORT_STREAM_REQUEST:
      return {
        ...state,
        isImporting: true,
      };
    case types.STOP_IMPORT:
      return {
        ...state,
        isImporting: false,
        abortImport: true,
      };
    case types.ACTIVITY_IMPORT_STREAM_SUCCESS:
    case types.ACTIVITY_IMPORT_STREAM_FAILURE:
      return {
        ...state,
        isImporting: false,
      };
    case types.IMPORT_ACTIVITY_SUCCESS:
      return {
        ...state,
        successfullyImportedActivities: [...state.successfullyImportedActivities, action.payload],
      };
    case types.RESET_ACTIVITY_IMPORT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default importActivityReducer;
