import React from 'react';
import classNames from 'classnames';
import InfoLabel from 'components/layout/InfoLabel';

const STATES = {
  UNAVAILABLE: 'unavailable',
  FAIL: 'fail',
};
const StatusLabels = (props: any) => {
  const classes = classNames('creation-table-status', {
    'creation-table-status--unavailable': props.status === STATES.UNAVAILABLE,
  });

  const getStatus = () => {
    if (props.status === STATES.UNAVAILABLE) {
      return 'no-bg';
    }

    if (props.status === STATES.FAIL) {
      return 'danger';
    }

    return props.status;
  };

  const testid = `${props.label.split(' ').join('-').toLowerCase()}-label`;
  return (
    <InfoLabel
      type={getStatus()}
      label={
        <div className={classes}>
          {props.icon && <span className="creation-table-status__icon">{props.icon}</span>}
          <span className="creation-table-status__label" data-testid={testid}>
            {props.label}
          </span>
        </div>
      }
    />
  );
};

StatusLabels.defaultProps = {
  status: '',
  icon: null,
};

export default StatusLabels;
