import { useTranslation } from 'react-i18next';

import { Course } from 'old-common/types.generated';

export const DefaultActivitySettings = (props: { course: Course }) => {
  const { t } = useTranslation();
  return (
    <div className="course-details__default-settings course-details__section-right-separator">
      <h2 className="new-header-2">{t('ActivitySettings.Default')}</h2>
      <div className="course-details">
        <p className="course-details__subtitle">
          {t('CourseDetails.MinimumWordCountEvaluation.Title')}
        </p>
        <p>{props.course.courseSettings?.minimumWordCountForEvaluations}</p>
      </div>
      <div className="course-details__padding-bottom-md">
        <h3 className="course-details__title">{t('CourseDetails.LateSubmissionOptions.Title')}</h3>
        <p className="course-details__user-email course-details__margin-right">
          {t('CourseDetails.LateSubmissionOptions.Description')}
        </p>
        <p className="course-details__subtitle course-details__section-margin-top">
          {t('CourseDetails.LateSubmissionOptions.AllowLateCreation')}
        </p>
        <p>
          {props.course.acceptLateSubmissions
            ? t('CourseDetails.Options.Yes')
            : t('CourseDetails.Options.No')}
        </p>
        {props.course.acceptLateSubmissions && (
          <>
            <p className="course-details__subtitle course-details__section-margin-top">
              {t('CourseDetails.LateSubmissionOptions.AutoAcceptLateCreation')}
            </p>
            <p>
              {props.course.autoAccept
                ? t('CourseDetails.LateSubmissionOptions.AutoAccept.On')
                : t('CourseDetails.LateSubmissionOptions.AutoAccept.Off')}
            </p>
          </>
        )}
        <p className="course-details__subtitle course-details__section-margin-top">
          {t('CourseDetails.LateSubmissionOptions.AllowLateEvaluation')}
        </p>
        <p>
          {props.course.acceptLateEvaluations
            ? t('CourseDetails.Options.Yes')
            : t('CourseDetails.Options.No')}
        </p>
        {props.course.acceptLateEvaluations && (
          <>
            <p className="course-details__subtitle course-details__section-margin-top">
              {t('CourseDetails.LateSubmissionOptions.AutoAcceptLateEvaluation')}
            </p>
            <p>
              {props.course.autoAcceptLateEvaluations
                ? t('CourseDetails.LateSubmissionOptions.AutoAccept.On')
                : t('CourseDetails.LateSubmissionOptions.AutoAccept.Off')}
            </p>
          </>
        )}
      </div>
    </div>
  );
};
