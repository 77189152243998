import React from 'react';
import { Field } from 'react-final-form';
import FormTitle from 'components/core/form/Title';
import FormInfo from 'components/core/form/FieldInfo';
import FormRadio from 'components/core/form/RadioField';
import { COURSE_FIELD_NAMES } from 'components/Course/Edit/constants';
import { localize } from 'locales/index';

const CourseStudentPermissions = () => {
  return (
    <>
      <h2 className="new-header-2">Student Permissions</h2>
      <h3 className="course-details__class-average-title">Class Average</h3>
      <fieldset>
        <legend>
          <FormInfo label="This setting manages students' visibility of the class average. When enabled, this setting allows students to see the class average for the creation score." />
        </legend>
        <Field
          name={COURSE_FIELD_NAMES.HIDE_AVERAGE_GRADE}
          parse={(v) => v === 'true'}
          format={(v) => (v ? 'true' : 'false')}
        >
          {({ input }) => {
            return (
              <FormRadio
                options={[
                  {
                    value: 'true',
                    label: 'Hide Class Average',
                    'aria-pressed': input.value === 'true',
                  },
                  {
                    value: 'false',
                    label: 'Show Class Average',
                    'aria-pressed': input.value === 'false',
                  },
                ]}
                onChange={(e: any) => {
                  input.onChange(e.target.value);
                }}
                value={input.value}
                disabled
                type="button"
              />
            );
          }}
        </Field>
      </fieldset>
    </>
  );
};

export default CourseStudentPermissions;
