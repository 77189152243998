import types from 'actions/creation/types';

const creationsToEvaluate = (state = {}, action: any) => {
  switch (action.type) {
    case types.GET_CREATIONS_TO_EVALUATE_SUCCESS:
      return {
        ...state,
        creations: action.payload,
      };
    default:
      return state;
  }
};

export default creationsToEvaluate;
