import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InfoLabel from 'components/layout/InfoLabel';
import LocalUtils from './utils';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
  },
  icon: {
    marginRight: '4px',
  },
  label: {
    textAlign: 'left',
  },
});

const StatusLabels = (props: any) => {
  if (!props.activity || !props.creation) {
    return null;
  }
  // @ts-expect-error TS(2339) FIXME: Property 'icon' does not exist on type '{ label: s... Remove this comment to see the full error message
  const { icon, label, status } = LocalUtils.generateCellLabel(props.creation, props.activity);
  if (!label) {
    return null;
  }
  const classes = useStyles();
  return (
    <InfoLabel
      testid={`status-label-${label}`}
      type={status}
      label={
        <div className={classes.root}>
          {icon && <span className={classes.icon}>{icon}</span>}
          <span className={classes.label}>{label}</span>
        </div>
      }
    />
  );
};

export default StatusLabels;
