import { TABLE_HEADERS, TABLE_TYPE_OPTIONS } from 'components/CreationList/constant';
import { connect } from 'react-redux';
import {
  selectCreationTableListBy,
  selectCreationTableViewType,
  selectSortedColumn,
} from 'redux/creationTable/select';

import CreationCell from 'components/CreationList/StatusCells/Creation';
import EvaluationCell from 'components/CreationList/StatusCells/Evaluation';
import EvaluationsReceivedCell from 'components/CreationList/StatusCells/EvaluationsReceived';
import FeedbackCell from 'components/CreationList/StatusCells/Feedback';
import CreationScoreCell from 'components/CreationList/StatusCells/CreationScore';
import EvaluationScoreCell from 'components/CreationList/StatusCells/EvaluationScore';

import { selectCurrentActivity } from 'selectors/activity';
import { selectCurrentCourse } from 'selectors/course';
import FeedbackScoreCell from 'components/CreationList/StatusCells/FeedbackScore';
import OverallScoreCell from 'components/CreationList/StatusCells/OverallScore';

const withText = (Comp: any, text: any) => {
  return (
    <div>
      {text}:{Comp}
    </div>
  );
};

const createProgressInfo = ({
  creation,
  sortedColumn,
  activity,
  student,
  lateEvaluations,
}: any) => {
  switch (sortedColumn.header) {
    case TABLE_HEADERS.CREATE: {
      return <CreationCell creation={creation} activity={activity} />;
    }
    case TABLE_HEADERS.EVALUATIONS_RECEIVED: {
      return withText(
        <EvaluationsReceivedCell creation={creation} activity={activity} />,
        TABLE_HEADERS.EVALUATIONS_RECEIVED
      );
    }
    case TABLE_HEADERS.EVALUATE: {
      return (
        <EvaluationCell
          creation={creation}
          activity={activity}
          student={student}
          lateEvaluations={lateEvaluations || []}
        />
      );
    }
    case TABLE_HEADERS.FEEDBACK: {
      return <FeedbackCell creation={creation} activity={activity} student={student} />;
    }
    default:
      return null;
  }
};

const createScoringInfo = ({ creation, sortedColumn, activity, course, student }: any) => {
  switch (sortedColumn.header) {
    case TABLE_HEADERS.CREATE: {
      // @ts-expect-error TS(2786) FIXME: 'CreationScoreCell' cannot be used as a JSX compon... Remove this comment to see the full error message
      return <CreationScoreCell creation={creation} activity={activity} />;
    }
    case TABLE_HEADERS.EVALUATE: {
      // @ts-expect-error TS(2786) FIXME: 'EvaluationScoreCell' cannot be used as a JSX comp... Remove this comment to see the full error message
      return <EvaluationScoreCell student={student} activity={activity} course={course} />;
    }
    case TABLE_HEADERS.FEEDBACK: {
      // @ts-expect-error TS(2786) FIXME: 'FeedbackScoreCell' cannot be used as a JSX compon... Remove this comment to see the full error message
      return <FeedbackScoreCell activity={activity} student={student} />;
    }
    case TABLE_HEADERS.OVERALL: {
      // @ts-expect-error TS(2786) FIXME: 'OverallScoreCell' cannot be used as a JSX compone... Remove this comment to see the full error message
      return <OverallScoreCell student={student} activity={activity} course={course} />;
    }
    default:
      return null;
  }
};

function CreationInfo({ item, viewType, listBy, sortedColumn, activity, course }: any) {
  const { creation, student, lateEvaluations } = item;
  if (!sortedColumn) {
    return null;
  }
  const generateCreationInfo = () => {
    switch (viewType) {
      case TABLE_TYPE_OPTIONS.PROGRESS:
        return createProgressInfo({
          creation,
          sortedColumn,
          activity,
          listBy,
          student,
          lateEvaluations,
        });
      case TABLE_TYPE_OPTIONS.SCORING:
        return createScoringInfo({
          creation,
          sortedColumn,
          activity,
          student,
          course,
        });
      default:
        return null;
    }
  };
  return <div className="creation-info-container">{generateCreationInfo()}</div>;
}

const mapStateToProps = (state: any) => {
  return {
    viewType: selectCreationTableViewType(state),
    listBy: selectCreationTableListBy(state),
    sortedColumn: selectSortedColumn(state),
    activity: selectCurrentActivity(state),
    course: selectCurrentCourse(state),
  };
};

export default connect(mapStateToProps)(CreationInfo);
