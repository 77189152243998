import { Select, MenuItem, Typography } from '@material-ui/core';
import { useSearchParams } from 'react-router-dom';
import { Pagination } from '@material-ui/lab';
import { TranslatedText } from 'components/TranslatedText';

export type PaginationControlsProps = {
  totalItems: number;
  currentPage: number;
  resultsPerPage: number;
  resultsPerPageOptions?: number[];
  onPageChange?: (newPage: number) => void;
  onResultsPerPageChange?: (newResultsPerPage: number) => void;
  hidePageNumbers?: boolean;
};

function PaginationControls(props: PaginationControlsProps) {
  if (props.totalItems === 0) {
    return null;
  }
  const setSearchParams = useSearchParams()[1];

  const numberOfPages = Math.ceil(props.totalItems / props.resultsPerPage);
  const startItem = (props.currentPage - 1) * props.resultsPerPage + 1;
  const endItem = Math.min(props.currentPage * props.resultsPerPage, props.totalItems);

  const handlePageChange = (newPage: number) => {
    if (props.onPageChange) {
      props.onPageChange(newPage);
    } else {
      setSearchParams((prevParams) => ({
        ...Object.fromEntries(prevParams),
        page: newPage.toString(),
      }));
    }
  };

  const handleResultsPerPageChange = (newResultsPerPage: number) => {
    if (props.onResultsPerPageChange) {
      props.onResultsPerPageChange(newResultsPerPage);
    } else {
      setSearchParams((prevParams) => ({
        ...Object.fromEntries(prevParams),
        resultsPerPage: newResultsPerPage.toString(),
        page: '1', // Reset to first page when changing page size
      }));
    }
  };

  return (
    <div className="pagination-controls__root">
      <Select
        data-testid="pagination-results-per-page"
        className="pagination-controls__select"
        value={props.resultsPerPage}
        onChange={(e) => handleResultsPerPageChange(e.target.value as number)}
        variant="standard"
        disableUnderline
      >
        {(props.resultsPerPageOptions || [25, 50, 100]).map((n) => (
          <MenuItem value={n} key={n} data-testid={`pagination-results-per-page-${n}`}>
            <TranslatedText i18nKey="Pagination.PerPage" values={{ number: n.toString() }} />
          </MenuItem>
        ))}
      </Select>
      <Typography variant="body2">
        <TranslatedText
          i18nKey="Pagination.CurrentPageInformation"
          values={{
            startItem: startItem.toString(),
            endItem: endItem.toString(),
            totalItems: props.totalItems.toString(),
          }}
        />
      </Typography>
      <Pagination
        data-testid="pagination-pages"
        classes={{
          ul: props.hidePageNumbers && 'pagination-controls__pagination-list-no-pages',
        }}
        count={numberOfPages}
        page={props.currentPage}
        onChange={(_, page) => handlePageChange(page)}
        boundaryCount={1}
        siblingCount={1}
        shape="rounded"
      />
    </div>
  );
}

export default PaginationControls;
