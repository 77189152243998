import { creationService } from 'services';
import types from './types';
import { normalize } from 'normalizr';
import { creationSchema } from 'schemas/index';
import { mergeEntities } from 'actions/entities';
import { Creation } from 'old-common/types.generated';

export function getCreationsToEvaluate({ activityId, courseId, studentId }: any) {
  return (dispatch: any) => {
    dispatch({ type: types.GET_CREATIONS_TO_EVALUATE_REQUEST });
    return creationService()
      .getCreationsToEvaluate({ activityId, courseId, studentId })
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({ type: types.GET_CREATIONS_TO_EVALUATE_SUCCESS, payload: res.data });
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.GET_CREATIONS_TO_EVALUATE_FAILURE, payload: err });
      });
  };
}

export function updateCreationInReduxStore(dispatch: any, data: Creation) {
  const normalized = normalize(data, creationSchema);
  dispatch(mergeEntities({ user_assignments: normalized.entities.user_assignments || {} }));
  dispatch({ type: 'GET_CREATION_SUCCESS', payload: normalized.result });
}

export default {
  getCreationsToEvaluate,
};
