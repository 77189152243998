import { useNavigate, useParams } from 'react-router';
import { Grid } from '@material-ui/core';
import { TranslatedText } from 'components/TranslatedText';
import { Button } from 'components/buttons';
import { useFetchActivityActionItems } from 'hooks/activityActionItems';
import { useGetCachedCourse } from 'hooks/course';
import { useState } from 'react';
import { finalizeAssignment } from 'actions/activity';
import { useDispatch } from 'react-redux';
import { isCourseConnected } from 'utils/lms';
import { ConfirmModal } from 'components/modals';
import { InlineInformation } from 'components/layout';
import { Activity } from '@kritik/types.generated';
import { isGrading } from '@kritik/utils/stage';

type ActionItems = {
  LateCreation: number;
  LateEvaluation: number;
  UngradedCreation: number;
  GradeDispute: number;
  FlaggedEvaluation: number;
};

function sortAndFilterActionItems(actionItems: ActionItems) {
  const filtered = Object.fromEntries(
    Object.entries(actionItems).filter(([key, value]) => value !== 0)
  );

  const sortedFiltered = Object.fromEntries(Object.entries(filtered).sort(([, a], [, b]) => b - a));
  return sortedFiltered;
}

type ActionItemsBoxProps = {
  activity: Activity;
  setShowSyncGradesModal: (param: boolean) => void;
};

export function ActionItemsBox({ activity, setShowSyncGradesModal }: ActionItemsBoxProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseId, assignmentId } = useParams();
  const course = useGetCachedCourse(courseId);
  const [isFinalizing, setIsFinalizing] = useState(false);
  const [showConfirmFinalizeModal, setShowConfirmFinalizeModal] = useState(false);

  const { data: actionItems } = useFetchActivityActionItems({
    activityId: assignmentId,
    courseId,
  });

  const handleFinalize = () => {
    if (isFinalizing) {
      return;
    }
    setIsFinalizing(true);
    setShowConfirmFinalizeModal(false);
    finalizeAssignment(activity._id, courseId)(dispatch, null)
      .then(() => {
        setIsFinalizing(false);
        if (isCourseConnected(course)) {
          setShowSyncGradesModal(true);
        }
      })
      .catch(() => {
        setIsFinalizing(false);
      });
  };

  if (!actionItems) {
    return null;
  }

  const totalCount = Object.values(actionItems).reduce(
    (sum: number, value: number) => sum + value,
    0
  );

  const displayMarkAsComplete =
    actionItems.UngradedCreation === 0 && isGrading({ assignment: activity });

  return (
    <div>
      {displayMarkAsComplete && (
        <>
          <h3 className="action-items-box__title">
            <TranslatedText i18nKey="Activity.ActionItems.Button.MarkAsComplete" />
          </h3>
          <div>
            <TranslatedText i18nKey="Activity.ActionItems.Info" />
          </div>
          <Button
            onClick={() => setShowConfirmFinalizeModal(true)}
            type="primary"
            unavailable={isFinalizing}
            disabled={isFinalizing}
            data-testid="mark-as-complete"
            style={{ marginTop: 16 }}
          >
            <TranslatedText i18nKey="Activity.ActionItems.Button.MarkAsComplete" />
          </Button>
        </>
      )}
      {displayMarkAsComplete && totalCount > 0 && <hr className="action-items-box__separator" />}
      {totalCount > 0 && (
        <div>
          <h3 className="action-items-box__title">
            <TranslatedText i18nKey="Activity.ActionItems" />{' '}
            {totalCount > 0 && (
              <span
                className={
                  actionItems.UngradedCreation > 0
                    ? 'action-items-box__chip-warning'
                    : 'action-items-box__chip-info'
                }
              >
                {totalCount}
              </span>
            )}
          </h3>
          {actionItems.UngradedCreation > 0 && (
            <InlineInformation
              type="warning"
              style={{ marginBottom: 16 }}
              testid="cannot-finalize-warning"
            >
              <TranslatedText i18nKey="Activity.ActionItems.CannotFinalize" />
            </InlineInformation>
          )}
          {Object.entries(sortAndFilterActionItems(actionItems)).map(
            ([key, entry]: [keyof ActionItems, number]) => {
              return (
                <Grid container key={key} className="action-items-box__actions-row">
                  <Grid item xs={10} data-testid={`${key}-issue`}>
                    <TranslatedText i18nKey={`Activity.ActionItems.Label.${key}`} />
                  </Grid>
                  <Grid item xs={2} className="action-items-box__actions-count">
                    <span
                      className={
                        key === 'UngradedCreation' && entry > 0
                          ? 'action-items-box__chip-warning'
                          : 'action-items-box__chip-no-background'
                      }
                    >
                      {entry}
                    </span>
                  </Grid>
                </Grid>
              );
            }
          )}
          <Button
            type="secondary"
            onClick={() => navigate(`/course/${courseId}/action-items?activityId=${assignmentId}`)}
            testid="view-action-items-details"
          >
            <TranslatedText i18nKey="Activity.ActionItems.Button.ViewDetails" />
          </Button>
        </div>
      )}
      <ConfirmModal
        isOpen={showConfirmFinalizeModal}
        title={<TranslatedText i18nKey="Activity.ActionItems.ConfirmComplete" />}
        onConfirm={() => {
          return handleFinalize();
        }}
        confirmButton={<TranslatedText i18nKey="Confirm" />}
        onCancel={() => setShowConfirmFinalizeModal(false)}
        cancelButton={<TranslatedText i18nKey="Cancel" />}
      />
    </div>
  );
}
