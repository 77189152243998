import React from 'react';

export const SubtitleDisplay = ({ text, testid }: any) => {
  if (!text) {
    return null;
  }
  return (
    <div>
      <div className="subtitle-display" data-testid={testid}>
        {text}
      </div>
    </div>
  );
};

export default {
  SubtitleDisplay,
};
