import { SORT_OPTIONS } from 'components/CreationList/constant';
import {
  isGroupAssignment,
  isCalibrationActivity,
  isGroupPresentationActivity,
} from '@kritik/utils/activity';
import { isListByGroup, isProgressView, isScoringView } from 'components/CreationList/util';

const getGroupActivitySortOptions = (
  viewType: string,
  listBy: string,
  activity?: CommonActivity
) => {
  if (isListByGroup(listBy)) {
    if (isProgressView(viewType)) {
      return {
        [SORT_OPTIONS.NAME]: {
          label: 'Last Name',
          value: SORT_OPTIONS.NAME,
        },
        [SORT_OPTIONS.EVALUATIONS_RECEIVED]: {
          label: 'Evaluations Received',
          value: SORT_OPTIONS.EVALUATIONS_RECEIVED,
        },
        [SORT_OPTIONS.ISSUES]: {
          label: 'Issues',
          value: SORT_OPTIONS.ISSUES,
        },
      };
    }
    if (isScoringView(viewType)) {
      return {
        [SORT_OPTIONS.NAME]: {
          label: 'Last Name',
          value: SORT_OPTIONS.NAME,
        },
        [SORT_OPTIONS.ISSUES]: {
          label: 'Issues',
          value: SORT_OPTIONS.ISSUES,
        },
      };
    }
  } else {
    if (isProgressView(viewType)) {
      return {
        [SORT_OPTIONS.NAME]: {
          label: 'Last Name',
          value: SORT_OPTIONS.NAME,
        },
        [SORT_OPTIONS.CREATE]: {
          label: 'Creations Completed',
          value: SORT_OPTIONS.CREATE,
        },
        [SORT_OPTIONS.EVALUATE]: {
          label: 'Evaluations Completed',
          value: SORT_OPTIONS.EVALUATE,
        },
        [SORT_OPTIONS.ISSUES]: {
          label: 'Issues',
          value: SORT_OPTIONS.ISSUES,
        },
      };
    }
    if (isScoringView(viewType)) {
      return {
        [SORT_OPTIONS.NAME]: {
          label: 'Last Name',
          value: SORT_OPTIONS.NAME,
        },
        [SORT_OPTIONS.CREATE]: {
          label: 'Creation Score',
          value: SORT_OPTIONS.CREATE,
        },
        [SORT_OPTIONS.EVALUATE]: {
          label: 'Evaluation Score',
          value: SORT_OPTIONS.EVALUATE,
        },
        [SORT_OPTIONS.OVERALL]: {
          label: 'Overall Score',
          value: SORT_OPTIONS.OVERALL,
        },
      };
    }
  }
};

const getCalibrationActivitySortOptions = (viewType: any) => {
  if (isProgressView(viewType)) {
    return {
      [SORT_OPTIONS.NAME]: {
        label: 'Last Name',
        value: SORT_OPTIONS.NAME,
      },
      [SORT_OPTIONS.EVALUATE]: {
        label: 'Evaluations Completed',
        value: SORT_OPTIONS.EVALUATE,
      },
    };
  }
  if (isScoringView(viewType)) {
    return {
      [SORT_OPTIONS.NAME]: {
        label: 'Last Name',
        value: SORT_OPTIONS.NAME,
      },
      [SORT_OPTIONS.EVALUATE]: {
        label: 'Evaluation Score',
        value: SORT_OPTIONS.EVALUATE,
      },
    };
  }
};

const getIndividualActivitySortOptions = (viewType: any) => {
  if (isProgressView(viewType)) {
    return {
      [SORT_OPTIONS.NAME]: {
        label: 'Last Name',
        value: SORT_OPTIONS.NAME,
      },
      [SORT_OPTIONS.CREATE]: {
        label: 'Creations Completed',
        value: SORT_OPTIONS.CREATE,
      },
      [SORT_OPTIONS.EVALUATIONS_GIVEN]: {
        label: 'Evaluations Given',
        value: SORT_OPTIONS.EVALUATIONS_GIVEN,
      },
      [SORT_OPTIONS.EVALUATIONS_RECEIVED]: {
        label: 'Evaluations Received',
        value: SORT_OPTIONS.EVALUATIONS_RECEIVED,
      },
      [SORT_OPTIONS.FEEDBACK]: {
        label: 'Feedback Completed',
        value: SORT_OPTIONS.FEEDBACK,
      },
      [SORT_OPTIONS.ISSUES]: {
        label: 'Issues',
        value: SORT_OPTIONS.ISSUES,
      },
    };
  }
  if (isScoringView(viewType)) {
    return {
      [SORT_OPTIONS.NAME]: {
        label: 'Last Name',
        value: SORT_OPTIONS.NAME,
      },
      [SORT_OPTIONS.CREATE]: {
        label: 'Creation Score',
        value: SORT_OPTIONS.CREATE,
      },
      [SORT_OPTIONS.EVALUATE]: {
        label: 'Evaluation Score',
        value: SORT_OPTIONS.EVALUATE,
      },
      [SORT_OPTIONS.FEEDBACK]: {
        label: 'Feedback Score',
        value: SORT_OPTIONS.FEEDBACK,
      },
      [SORT_OPTIONS.OVERALL]: {
        label: 'Overall Score',
        value: SORT_OPTIONS.OVERALL,
      },
    };
  }
};

export const getSortOptions = (viewType: any, listBy: any, activity: any) => {
  if (isGroupAssignment(activity) || isGroupPresentationActivity(activity)) {
    return getGroupActivitySortOptions(viewType, listBy, activity);
  }
  if (isCalibrationActivity(activity)) {
    return getCalibrationActivitySortOptions(viewType);
  }
  return getIndividualActivitySortOptions(viewType);
};

export default {
  getSortOptions,
};
