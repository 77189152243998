import React from 'react';
import { connect } from 'react-redux';
import { push } from 'router';
import { withRouter } from 'utils/withRouter';
import { getAllGroupSetList, getGroupSetList } from 'selectors/group';
import Select from 'components/core/form/SelectField';
import { Field } from 'react-final-form';
import { Button } from 'components/buttons';
import * as statusUtil from '@kritik/utils/stage';
import FormFieldInfo from 'components/core/form/FieldInfo';
import FormFieldTitle from 'components/core/form/Title';
import NoticeBoard from 'components/layout/NoticeBoard';
import { getAssignment, selectCurrentActivity } from 'selectors/activity';
import FormFieldLabel from 'components/core/form/FieldLabel';
import { localize } from 'locales/index';
import { getActivityTemplateGroupSet } from 'components/ActivityEdit/Group/utils';

const GroupSetSelect = (props: any) => {
  const changeGroupSet = (e: any, onChangeCallBack: any) => {
    onChangeCallBack(e);
  };

  const isScheduled = statusUtil.isScheduled(props.assignment);
  const isEval = statusUtil.isEvaluateOrLater(props.assignment);

  const getGroupSets = () => {
    return props.allGroupSetList
      .filter((group) => {
        if ((props.settings.isEdit && isScheduled && isEval) || (props.isScheduling && isEval)) {
          return group.assignments.includes(props.activity._id);
        }

        return group.isInstance === false;
      })
      .map((groupSet: any) => {
        return {
          label: groupSet.name.includes('-') ? groupSet.name.split('-')[0] : groupSet.name,
          value: groupSet._id,
          testid: groupSet._id,
        };
      });
  };

  const routeToManageGroups = () => {
    props.router.push(`/course/${props.router.params.courseId}/roster?tab=groups`);
  };

  const routeToGroupManagerButton = (label: any) => {
    return (
      <Button
        type="secondary"
        className="group-set-select__manage-groups-button"
        testid="manage-groups-button"
        onClick={() => {
          return routeToManageGroups();
        }}
      >
        {label}
      </Button>
    );
  };

  const renderNoGroupSetWarning = () => {
    return (
      <NoticeBoard
        type="information"
        title="In order to schedule this group activity you will need to create a group set first"
      >
        {routeToGroupManagerButton('Create a Group Set')}
      </NoticeBoard>
    );
  };

  const renderGroupSetChanged = () => {
    return (
      <NoticeBoard
        type="caution"
        title="Some students may be evaluating peers they haven't worked with before"
        data-testid="groupset-change-notice-board"
      >
        It looks like your group set has changed since the last activity was completed. There are
        some students who haven't worked together in their current groups and may be evaluating
        students who they haven't worked with before.
      </NoticeBoard>
    );
  };

  const renderGroupSetHeader = () => {
    return (
      <React.Fragment>
        <FormFieldTitle label="Select your Group set" />
        <FormFieldInfo label="Choose the group set you would like to use for this activity." />
      </React.Fragment>
    );
  };

  const isDisabled = () => {
    if (props.settings.isDuplicating || !props.settings.activity) {
      return false;
    }
    return statusUtil.isCreateOrLater(props.settings.activity);
  };

  const renderGroupSetSelect = (input: any, meta: any) => {
    return (
      <div className="group-set-select__wrapper">
        <Select
          label="Group Set"
          onChange={(e: any) => {
            return changeGroupSet(e, input.onChange);
          }}
          value={input.value}
          warning={meta.data.warning}
          disabled={isDisabled()}
          placeholder="Select Group Set"
          options={getGroupSets()}
          testid="select-group-set"
        />
      </div>
    );
  };

  const renderGroupSetSelectAtSchedule = () => {
    return (
      <div className="group-set-select__wrapper">
        <Select
          label="Group Set"
          onChange={(e: any) => {
            return props.setGroupSet(e.target.value);
          }}
          value={props.groupSet}
          disabled={isDisabled()}
          placeholder="Select Group Set"
          options={getGroupSets()}
        />
      </div>
    );
  };

  if (isDisabled()) {
    const templateGroupSet = getActivityTemplateGroupSet({
      activity: props.activity,
      groupSets: props.allGroupSetList,
    });

    return (
      <>
        <FormFieldLabel label={localize({ message: 'Activity.Edit.GroupSetField.Title' })} />
        {templateGroupSet?.name ?? ''}
        <div className="group-set-select__warning">
          {localize({ message: 'Activity.Edit.GroupSetField.Warning' })}
        </div>
      </>
    );
  }

  if (props.isScheduling) {
    return (
      <React.Fragment>
        {renderGroupSetHeader()}
        {props.groupSetList.length > 0 ? (
          <React.Fragment>
            <div className="group-set-select">
              {renderGroupSetSelectAtSchedule()}
              {routeToGroupManagerButton('Manage Groups')}
            </div>
          </React.Fragment>
        ) : (
          renderNoGroupSetWarning()
        )}
      </React.Fragment>
    );
  }

  return (
    <Field name="selectedGroupSet">
      {({ input, meta }) => {
        return (
          <React.Fragment>
            {renderGroupSetHeader()}
            {props.groupSetList.length > 0 ? (
              <React.Fragment>
                <div className="group-set-select">
                  {renderGroupSetSelect(input, meta)}
                  {routeToGroupManagerButton('Manage Groups')}
                </div>
                {meta.data.warning && renderGroupSetChanged()}
              </React.Fragment>
            ) : (
              renderNoGroupSetWarning()
            )}
          </React.Fragment>
        );
      }}
    </Field>
  );
};

const mapStateToProps = (state: any) => {
  return {
    groupSetList: getGroupSetList(state),
    allGroupSetList: getAllGroupSetList(state),
    activity: getAssignment(state, state.selected.assignmentId),
    state: state,
    assignment: selectCurrentActivity(state),
  };
};

export default withRouter(connect(mapStateToProps, { push })(GroupSetSelect));
