import React, { Component } from 'react';
import { get } from 'lodash';

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;
const KEY_TAB = 9;
const KEY_ENTER = 13;

type State = any;

export default class NumericEditor extends Component<{}, State> {
  cancelBeforeStart: any;
  constructor(props: {}) {
    super(props);
    this.cancelBeforeStart =
      (this.props as any).charPress && '1234567890'.indexOf((this.props as any).charPress) < 0;

    this.state = this.createInitialState(props);

    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  createInitialState(props: any) {
    let startValue;
    let highlightAllOnFocus = true;

    if (props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE) {
      // if backspace or delete pressed, we clear the cell
      startValue = '';
    } else if (props.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = props.charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = props.value;
      if (props.keyPress === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    return {
      value: startValue,
      highlightAllOnFocus,
    };
  }

  componentDidMount() {
    (this.refs.input as any).addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    (this.refs.input as any).removeEventListener('keydown', this.onKeyDown);
  }

  afterGuiAttached() {
    // get ref from React component
    const eInput = this.refs.input;
    (eInput as any).focus();
    if (this.state.highlightAllOnFocus) {
      (eInput as any).select();

      this.setState({
        highlightAllOnFocus: false,
      });
    } else {
      // when we started editing, we want the carot at the end, not the start.
      // this comes into play in two scenarios: a) when user hits F2 and b)
      // when user hits a printable character, then on IE (and only IE) the carot
      // was placed after the first character, thus 'apply' would end up as 'pplea'
      const length = (eInput as any).value ? (eInput as any).value.length : 0;
      if (length > 0) {
        (eInput as any).setSelectionRange(length, length);
      }
    }
  }

  getValue() {
    return this.state.value;
  }

  isCancelBeforeStart() {
    return this.cancelBeforeStart;
  }

  // extra validation after edit end
  isCancelAfterEnd() {
    const value = Number(this.getValue());
    return value < 0 || !Number.isInteger(value) || value > 1000000;
  }

  onKeyDown(event: any) {
    if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
      event.stopPropagation();
      return;
    }

    if (!this.finishedEditingPressed(event) && !this.isKeyPressedNumeric(event)) {
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }

  isLeftOrRight(event: any) {
    return [37, 39].indexOf(event.keyCode) > -1;
  }

  handleChange(event: any) {
    this.setState({ value: event.target.value });
  }

  getCharCodeFromEvent(event: any) {
    event = event || window.event;
    return typeof event.which === 'undefined' ? event.keyCode : event.which;
  }

  isCharNumeric(charStr: any) {
    return !!/\d/.test(charStr);
  }

  isKeyPressedNumeric(event: any) {
    const charCode = this.getCharCodeFromEvent(event);
    const charStr = event.key ? event.key : String.fromCharCode(charCode);
    return this.isCharNumeric(charStr);
  }

  render() {
    return (
      <input
        ref="input"
        value={this.state.value}
        onChange={this.handleChange}
        style={{ width: '100%' }}
      />
    );
  }

  deleteOrBackspace(event: any) {
    return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.keyCode) > -1;
  }

  finishedEditingPressed(event: any) {
    const charCode = this.getCharCodeFromEvent(event);
    return charCode === KEY_ENTER || charCode === KEY_TAB;
  }
}
