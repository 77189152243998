import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'components/buttons';
import { logOut } from 'actions/users';
import { userService } from 'services';
import { trackEvent } from 'utils/userEvents';
import { getProfEmailFromDemoEmail } from '@kritik/utils/format';
import { AuthUser } from 'app-types';

type Props = {
  authUser: AuthUser;
  logOut: ({ destination }: { destination?: string }) => void;
};
const DemoBar = (props: Props) => {
  const [instructorEmail, setHasInstructorEmail] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const _checkHasIntructorAccount = async () => {
      try {
        const response = await userService().getInstructorEmail({ id: props.authUser.id });
        setHasInstructorEmail(response.data?.instructorEmail);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setHasInstructorEmail(false);
      }
    };
    void _checkHasIntructorAccount();
  }, []);

  function redirectToSignIn() {
    if (!instructorEmail) {
      trackEvent('Student Experience Sign Up', props.authUser, {
        instructorEmail: getProfEmailFromDemoEmail(props.authUser.email),
      });
    }
    props.logOut({
      destination: instructorEmail
        ? `/login?email=${encodeURIComponent(instructorEmail)}`
        : '/signup',
    });
  }

  if (loading) {
    return null;
  }

  return (
    <div className="demo-bar">
      <h6 className="demo-bar__title">
        {instructorEmail
          ? "Feel free to explore the app and experience Kritik from the student's view"
          : 'Feel free to explore the app. When are you are ready, sign up and set up your first course'}
      </h6>
      <Button type="primary" onClick={() => redirectToSignIn()}>
        {instructorEmail ? 'Sign In' : 'Sign Up'}
      </Button>
    </div>
  );
};

export default connect(
  (state: any) => ({
    authUser: state.user.authUser,
  }),
  { logOut }
)(DemoBar);
