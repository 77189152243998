import {
  SignupContainer,
  SignupMainTitle,
  SignupText,
  SignupContent,
} from 'components/AuthWrapper';

function NoUserAccount() {
  return (
    <div className="no-account__container">
      <SignupContainer graphic="invite">
        <SignupContent>
          <SignupMainTitle>Looks like you don't have a Kritik account yet</SignupMainTitle>
          <SignupText>
            Please contact your instructor to add you to the Kritik course. Change might take up to
            48 hours to take effect.
          </SignupText>
          <SignupText>
            If you are still having issues, speak with us directly through chat.
          </SignupText>
        </SignupContent>
      </SignupContainer>
    </div>
  );
}

export default NoUserAccount;
