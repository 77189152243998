import Page from 'pages/Page';
import NoAccount from 'containers/NoAccount';
import { localize } from 'locales';

function NoUserAccount() {
  return (
    <Page title={localize({ message: 'NoUserAccount.PageTitle' })} meta={[]} link={[]} script={[]}>
      <NoAccount />
    </Page>
  );
}

export default NoUserAccount;
