import PricingPlan from 'components/RosterManager/StudentInviteModal/PricingPlan';

function PricingPlanConfirmation(props: any) {
  if (props.course.price === 0 || props.studentsAdded === 0) {
    return null;
  }
  return (
    <PricingPlan
      course={props.course}
      institution={props.course.user.institution}
      onChange={props.onChange}
      isChecked={props.isChecked}
    />
  );
}

export default PricingPlanConfirmation;
