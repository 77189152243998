import { schema } from 'normalizr';

import user from 'schemas/user';
import student from 'schemas/student';

const course = new schema.Entity(
  'courses',
  {
    students: [student],
    user,
  },
  { idAttribute: '_id' }
);

export default course;
