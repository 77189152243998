import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import { setAccessibilityMessage } from 'actions/messages';
import HtmlContent from 'components/General/HtmlContent';
import * as statusUtil from '@kritik/utils/stage';
import * as ActivityUtils from '@kritik/utils/activity';
import * as FormatUtils from '@kritik/utils/format';
import * as StudentUtils from 'utils/student';
import * as CreationStatusUtil from '@kritik/utils/creation/status';
import * as GroupUtils from '@kritik/utils/group';
import { getGroupList, getGroupOfStudent } from 'selectors/group';
import { getMySubmissionsToEvaluate, getMySubmission } from 'selectors/creation';
import { getAssignment } from 'selectors/activity';
import { getCourse } from 'selectors/course';
import { getStudentFromUser } from 'selectors/student';
import {
  StartingSoon,
  NoGroup,
  MissedSubmissionStudentNotice,
  LateSubmissionAvailability,
  EnrolledAfterCreateStageInGroupActivity,
} from 'components/Assignment/NoticeBoards';
import { AssignmentOverview } from 'containers/assignments/overview';
import PageContainer from 'components/layout/PageContainer';
import {
  PageHeader,
  DisplayBox,
  ExpansionPanel,
  TextDisplay,
  InlineInformation,
} from 'components/layout';
import FileList from 'components/layout/AttachmentManager/FileList';
import NoticeBoard from 'components/layout/NoticeBoard';
import TabMenu, { TabMenuUtils } from 'components/layout/TabMenu';
import TabMenuItem from 'components/layout/TabMenu/Item';
import { SubmissionEdit } from 'components/Creation/Create';
import { CreationView } from 'components/Creation/Create/View';
import { EvaluationScoreView } from 'components/Creation/GradeView';
import WarningModalLateSubmission from 'components/Assignment/WarningModalLateCreation';
import { Feedback } from 'components/Creation/Feedback';
import { StudentEvaluationsOverview } from 'components/Creation';
import Button from 'components/buttons/Button';
import StarRubricMarks from 'components/General/StarRubricMarks';
import StageInstructions from 'components/Creation/StageInstruction';
import CreationTutorial from 'components/tutorial/Banner/CreationTutorial';
import FinalizedPanelDisplay from 'containers/assignments/FinalizedPanelDisplay';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import StudentContent from 'components/layout/StudentContent';
import GradingChangeSideBox from 'containers/assignments/creations/GradingChangeSideBox';
import { selectGradeHistories } from 'selectors/grade';
import SideBar from 'containers/assignments/overview/SideBar';
import PeerCreationTab from 'containers/assignments/StudentView/PeerCreationTab';
import * as EvaluationUtils from '@kritik/utils/creation/evaluation';
import EvaluationTabs from 'containers/assignments/StudentView/Evaluations';
import CreationSummary from 'containers/assignments/StudentView/CreationSummary';
import creationSelector from 'selectors/creation';
import ScoreToast from 'containers/assignments/StudentView/ScoreToast';
import FeedbackSummary from 'containers/assignments/StudentView/FeedbackSummary';
import VerticalList from 'components/layout/VerticalList';
import * as GradeHistoryUtil from '@kritik/utils/gradeHistory';
import CollapsibleComponent from 'components/layout/CollapsibleComponent';
import { localize } from 'locales';
import { MENU_OPTIONS } from './constants';
import { getFinalizedMenuOptions, getMenuOptions, getActiveTabBasedOnActivityType } from './utils';
import { userSubmissionStatuses } from '@kritik/constants/creation';
import { isScoreViewCompleted } from '@kritik/utils/creation/score';
import { useGetCreation } from 'hooks/creations';
import { isEvaluateOrLater, isFeedbackOrLater } from '@kritik/utils/stage';
import { FeedbackScoreUtil } from '@kritik/utils/grade';
import Spinner from 'components/Loaders/Spinner';
import { Activity, Student, Creation } from 'old-common/types.generated';
import { StudentLateCreationInfo } from './StudentLateCreationInfo';
import { STAR_RUBRIC_MARKS } from 'components/General/constant';
import { EvaluationsReceived } from './Evaluations/EvaluationsReceived';

const mapStateToProps = (state: any) => {
  const myStudent = getStudentFromUser(state, state.selected.courseId);
  const activityId = state.selected.assignmentId;
  const studentGroup = getGroupOfStudent(state, activityId, myStudent._id);

  return {
    entities: state.entities,
    user: state.user,
    myStudent,
    editModal: false,
    course: getCourse(state),
    assignmentId: activityId,
    assignment: getAssignment(state, state.selected.assignmentId),
    submissions: getMySubmissionsToEvaluate(state, state.selected.assignmentId),
    mySubmission: getMySubmission(state, state.selected.assignmentId),
    groups: getGroupList(state),
    loadingBar: state.loadingBar,
    gradeHistories: selectGradeHistories(state),
    feedbackReceivedMap: creationSelector.selectFeedbackReceivedMap(state),
    studentGroup,
  };
};
function OnlyGroupLeaderCanCreate() {
  return (
    <NoticeBoard
      title="Only your group leader can edit or submit a creation"
      type="information"
      style={{ marginBottom: '20px' }}
      testid="only-leader-can-submit-notice"
    >
      Co-ordinate with your group leader to create a submission.
    </NoticeBoard>
  );
}
function EditCreationButton({ openEditCreation }: any) {
  return (
    <Button onClick={openEditCreation} type="primary" testid="edit-creation">
      Edit Creation
    </Button>
  );
}

type StudentViewState = any;

class StudentViewComponent extends Component<{}, StudentViewState> {
  creationProgress: any;
  kritikProgress: any;
  participationProgress: any;
  pointsEarned: any;
  totalWeights: any;
  constructor(props: {}) {
    super(props);
    this.state = {
      isEditingCreation: false,
      selectedPrimaryTab: MENU_OPTIONS[0].id,
      selectedEvaluationTab: 0,
      selectedCreationTab: 0,
      selectedCreationToEvaluateTab: 0,
      showWarningModal: true,
      showWarningLateSubmissionModal: false,
    };
    this.pointsEarned = 0;
    this.totalWeights = 0;
    this.participationProgress = '0%';
    this.creationProgress = '0%';
    this.kritikProgress = '0%';
  }

  componentDidMount() {
    if ((this.props as any).assignment) {
      this.setActiveTab();
    }
  }

  componentDidUpdate(prevProps: {}, prevState: StudentViewState) {
    if (
      (this.props as any).mySubmission &&
      (prevProps as any).mySubmission &&
      (this.props as any).mySubmission._id != (prevProps as any).mySubmission._id
    ) {
      if (this.participationProgress !== '0%') {
        this.participationProgress = '0%';
      }
    }

    if (
      ((this.props as any).assignment && !(prevProps as any).assignment) ||
      (this.props as any).assignment._id != (prevProps as any).assignment._id
    ) {
      this.setActiveTab();
    }
  }

  handleTabSelect(labelId: any, secondary = false) {
    switch (secondary) {
      case false: {
        this.setState({ selectedPrimaryTab: labelId });
        break;
      }
      // @ts-expect-error TS(2678) FIXME: Type 'string' is not comparable to type 'boolean'.
      case 'peerCreations': {
        this.setState({ selectedCreationTab: labelId });
        break;
      }
      // @ts-expect-error TS(2678) FIXME: Type 'string' is not comparable to type 'boolean'.
      case 'peerCreationsToEvaluate': {
        this.setState({ selectedCreationToEvaluateTab: labelId });

        break;
      }
      default:
        this.setState({ selectedEvaluationTab: labelId });
    }
  }

  setActiveTab() {
    const assignment = (this.props as any).assignment as Activity;
    const creation = (this.props as any).mySubmission as Creation;
    const myStudent = (this.props as any).myStudent as Student;
    const isCreationSummaryShown =
      statusUtil.isFinalized({ assignment }) ||
      (statusUtil.isGrading({ assignment }) && ActivityUtils.isPresentationActivity(assignment)) ||
      !!StudentUtils.getStudentActivityScoreHistory(
        myStudent.scoreHistories,
        (this.props as any).assignment._id
      );

    const { activeTab } = (this.props as any).router.location.query;

    //presentation activities don't support late evaluations
    //https://kritikio.slack.com/archives/CLP9KPN8K/p1707335108645289
    if (creation && !ActivityUtils.isPresentationActivity(assignment)) {
      const studentId = myStudent._id;
      const isEvaluateStage = statusUtil.isEvaluate({ assignment });
      if (
        isEvaluateStage &&
        assignment.acceptLateSubmissions &&
        creation.submissionStatus === 'EMPTY'
      ) {
        return this.handleTabSelect('submission');
      }

      const isFeedbackStage = statusUtil.isFeedback({ assignment });
      const { completedFOF } = FeedbackScoreUtil.numFOFCompleted(creation, assignment, studentId);
      const isGroupActivity = ActivityUtils.isGroupAssignment(assignment);
      if (
        isFeedbackStage &&
        assignment.acceptLateEvaluations &&
        !isGroupActivity &&
        creation.numEvalsCompleted < creation.numEvalsAssigned &&
        completedFOF === 0
      ) {
        return this.handleTabSelect('evaluations');
      }
      const groupEvalsCompleted = creation.groupEvalsCompleted[studentId] || 0;
      const groupEvalsAssigned = creation.groupEvalsAssigned[studentId] || 0;
      if (
        isFeedbackStage &&
        assignment.acceptLateEvaluations &&
        isGroupActivity &&
        groupEvalsCompleted < groupEvalsAssigned &&
        completedFOF === 0
      ) {
        return this.handleTabSelect('evaluations');
      }
    }

    if (activeTab === 'creation') {
      return this.handleTabSelect(isCreationSummaryShown ? 'creation' : 'submission');
    }

    const newActiveTab = getActiveTabBasedOnActivityType({
      activity: (this.props as any).assignment,
      isCreationSummaryShown,
    });

    return this.handleTabSelect(newActiveTab);
  }

  closeWarning() {
    this.setState({ showWarningModal: false });
  }

  renderWarningNoGroup() {
    return (
      <Dialog
        open={this.state.showWarningModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
        {isEvaluateOrLater((this.props as any).assignment) ? (
          <DialogContent id="alert-dialog-description">
            {localize({ message: 'Activity.GroupActivity.EnrolledAfterCreateStage' })}
            <br />
            {localize({ message: 'Activity.GroupActivity.EnrolledAfterCreateStage.Contact' })}
          </DialogContent>
        ) : (
          <DialogContent id="alert-dialog-description">
            {localize({ message: 'Activity.GroupActivity.NotEnrolledInAGroup' })}
            <br />
            {localize({ message: 'Activity.GroupActivity.NotEnrolledInAGroup.Contact' })}
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              return this.closeWarning();
            }}
            type="primary"
            autoFocus
            label={localize({ message: 'Button.Label.AcknowledgeWarning' })}
            testid="acknowledge-warning-button"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  closeWarningLateSubmission = () => {
    this.setState({ showWarningLateSubmissionModal: false });
  };

  closeWarningLateSubmissionAndEdit = () => {
    this.setState({ showWarningLateSubmissionModal: false });
    this.setState({ isEditingCreation: true });
  };

  renderWarningLateSumbission = () => {
    const nextDate = statusUtil.getNextDate((this.props as any).assignment);
    const hoursLeft = FormatUtils.timeUntilDate(nextDate);
    return (
      <WarningModalLateSubmission
        handleOpen={this.state.showWarningLateSubmissionModal}
        handleClose={this.closeWarningLateSubmission}
        handleEdit={this.closeWarningLateSubmissionAndEdit}
        hoursLeft={hoursLeft}
      />
    );
  };

  openEditCreation = () => {
    if (statusUtil.isInCreationGracePeriod((this.props as any).assignment)) {
      this.setState({ showWarningLateSubmissionModal: true });
    } else {
      this.setState({ isEditingCreation: true });
    }
  };

  closeEditCreation = () => {
    this.setState({ isEditingCreation: false });
  };

  renderMySubmissionEdit() {
    // @ts-expect-error TS(2339) FIXME: Property 'assignment' does not exist on type 'Read... Remove this comment to see the full error message
    const { assignment } = this.props;
    const creation = (this.props as any).mySubmission;

    // Render Editor if creation is not completed
    const onCancel = () => {
      if (CreationStatusUtil.isCompleted(creation)) {
        this.closeEditCreation();
      }
    };

    const renderStageInstruction = () => {
      return (
        <StageInstructions
          title="Submit your Creation"
          description="Carefully read the activity and then complete and submit your creation."
        />
      );
    };

    if (
      ActivityUtils.isPresentationActivity(assignment) &&
      assignment.fileExtensionsAllowed === null
    ) {
      return (
        <InlineInformation>
          {localize({ message: 'Activity.Presentation.StudentView.NoSubmissionRequired' })}
        </InlineInformation>
      );
    }

    if (
      ActivityUtils.isGroupAssignment(assignment) ||
      ActivityUtils.isGroupPresentationActivity(assignment)
    ) {
      // Render Only Group Leader warning instead of Editor if not group leader
      if (!GroupUtils.isGroupLeader((this.props as any).myStudent, creation.group)) {
        return (
          <VerticalList itemList={[this.renderCreationWarning(), <OnlyGroupLeaderCanCreate />]} />
        );
      }

      // Render Editor if is group leader
      return (
        <VerticalList
          itemList={[
            renderStageInstruction(),
            <CreationTutorial />,
            this.renderCreationWarning(),
            <NoticeBoard
              title={`You are ${creation.group.name}'s Leader`}
              attention
              style={{ marginBottom: '20px' }}
              testid="responsible-for-submitting-notice"
            >
              {localize({
                message: 'Activity.Presentation.StudentView.Form.ResponsibleForSubmitting',
              })}
            </NoticeBoard>,
            <SubmissionEdit
              submission={creation}
              onSubmit={this.closeEditCreation}
              onCancel={onCancel}
            />,
          ]}
        />
      );
    }

    // Render Editor if not group activity
    return (
      <VerticalList
        itemList={[
          renderStageInstruction(),
          <CreationTutorial />,
          this.renderCreationWarning(),
          <SubmissionEdit
            submission={creation}
            onSubmit={this.closeEditCreation}
            onCancel={onCancel}
          />,
        ]}
      />
    );
  }

  renderNoGroupWarning() {
    return (
      <React.Fragment>
        {this.renderWarningNoGroup()}
        {isEvaluateOrLater((this.props as any).assignment) ? (
          <EnrolledAfterCreateStageInGroupActivity />
        ) : (
          <NoGroup />
        )}
      </React.Fragment>
    );
  }

  renderCreationWarning() {
    // @ts-expect-error TS(2339) FIXME: Property 'assignment' does not exist on type 'Read... Remove this comment to see the full error message
    const { assignment, mySubmission, user } = this.props;
    if (
      ActivityUtils.canSubmitLate(mySubmission, assignment) &&
      !CreationStatusUtil.isCompleted(mySubmission)
    ) {
      return <LateSubmissionAvailability activity={assignment} />;
    }
    if (
      CreationStatusUtil.wasCreationMissed(mySubmission) ||
      (!mySubmission && !statusUtil.canSubmitCreation(assignment))
    ) {
      return (
        <MissedSubmissionStudentNotice
          creation={mySubmission}
          activity={assignment}
          user={user.authUser}
        />
      );
    }
    return null;
  }

  renderMySubmission() {
    // @ts-expect-error TS(2339) FIXME: Property 'assignment' does not exist on type 'Read... Remove this comment to see the full error message
    const { assignment, studentGroup, mySubmission: creation } = this.props;

    const isCreate = statusUtil.isCreate({ assignment });

    // Render Start Soon if creation state no start
    if (statusUtil.isDraft({ assignment })) {
      return <StartingSoon stage="CREATE" />;
    }

    if (this.state.showWarningLateSubmissionModal) {
      return this.renderWarningLateSumbission();
    }

    if (GroupUtils.isNotInGroup(assignment, creation, studentGroup)) {
      return this.renderNoGroupWarning();
    }

    if (
      ((isCreate || statusUtil.isInCreationGracePeriod(assignment)) && !creation) ||
      this.state.isEditingCreation ||
      (creation && CreationStatusUtil.isCreationEmpty(creation))
    ) {
      return this.renderMySubmissionEdit();
    }

    // Default render Submission View if is completed
    const renderCreationMenu = () => {
      if (
        isCreate ||
        statusUtil.isInCreationGracePeriod(assignment) ||
        ActivityUtils.canSubmitLate(creation, assignment)
      ) {
        if (
          ActivityUtils.isGroupAssignment(assignment) ||
          ActivityUtils.isGroupPresentationActivity(assignment)
        ) {
          if (GroupUtils.isGroupLeader((this.props as any).myStudent, creation.group)) {
            return <EditCreationButton openEditCreation={this.openEditCreation} />;
          }
          return <OnlyGroupLeaderCanCreate />;
        }
        return <EditCreationButton openEditCreation={this.openEditCreation} />;
      }
      return null;
    };

    if (ActivityUtils.isPresentationActivity(assignment)) {
      if (assignment.fileExtensionsAllowed === null) {
        return (
          <InlineInformation>
            {localize({ message: 'Activity.Presentation.StudentView.NoSubmissionRequired' })}
          </InlineInformation>
        );
      }
    }

    return (
      <VerticalList
        space="md"
        itemList={[
          this.renderCreationWarning(),
          <StudentLateCreationInfo mySubmission={creation} assignment={assignment} />,
          creation.submissionStatus === userSubmissionStatuses.MISSED ||
          creation.submissionStatus === userSubmissionStatuses.EMPTY ? null : (
            <div className="kritik-form-container">
              <CreationView title="" submission={creation} />
            </div>
          ),
          renderCreationMenu(),
          <EvaluationsReceived creation={creation} activity={assignment} />,
        ]}
      />
    );
  }

  renderEvaluations() {
    if (
      ActivityUtils.isWithinGroupActivity((this.props as any).assignment) &&
      !(this.props as any).studentGroup
    ) {
      return this.renderNoGroupWarning();
    }
    return (
      <EvaluationTabs
        submission={(this.props as any).submission}
        assignment={(this.props as any).assignment}
        user={(this.props as any).user}
        submissions={(this.props as any).submissions}
        entities={(this.props as any).entities}
        myStudent={(this.props as any).myStudent}
        course={(this.props as any).course}
      />
    );
  }

  renderFeedback() {
    const shouldShowHelpBoard =
      !statusUtil.isGrading({ assignment: (this.props as any).assignment }) &&
      !statusUtil.isFinalized({ assignment: (this.props as any).assignment });
    if (
      ActivityUtils.isWithinGroupActivity((this.props as any).assignment) &&
      !(this.props as any).studentGroup
    ) {
      return this.renderNoGroupWarning();
    }
    return (
      <StudentEvaluationsOverview
        assignment={(this.props as any).assignment}
        creation={(this.props as any).mySubmission}
        renderHelpBoard={shouldShowHelpBoard}
      />
    );
  }

  renderFeedbackSummary() {
    return (
      <FeedbackSummary
        assignment={(this.props as any).assignment}
        submission={(this.props as any).mySubmission}
        student={(this.props as any).myStudent}
      />
    );
  }

  renderEvaluationSummary() {
    return (
      <EvaluationScoreView
        assignment={(this.props as any).assignment}
        submissionsToEvaluate={(this.props as any).submissions}
        student={(this.props as any).student}
        course={(this.props as any).course}
        submission={(this.props as any).mySubmission}
      />
    );
  }

  renderLateEnrollmentBanner() {
    return (
      <NoticeBoard type="information" title="Late enrollment">
        It appears you enrolled after this activity was completed and were not able to participate
        in it.
      </NoticeBoard>
    );
  }

  renderEvaluationsReceived() {
    // @ts-expect-error TS(2339) FIXME: Property 'assignment' does not exist on type 'Read... Remove this comment to see the full error message
    const { assignment, mySubmission, studentGroup } = this.props;

    if (
      StudentUtils.wasStudentEnrolledLate(
        (this.props as any).assignment,
        (this.props as any).myStudent
      ) &&
      !(this.props as any).mySubmission
    ) {
      return this.renderLateEnrollmentBanner();
    }

    if (GroupUtils.isNotInGroup(assignment, mySubmission, studentGroup)) {
      return null;
    }

    const myEvalsReceived = (this.props as any).mySubmission.scores;

    if (
      myEvalsReceived.length > 0 ||
      CreationStatusUtil.wasCreationMissed((this.props as any).mySubmission)
    ) {
      return <React.Fragment>{this.renderMySubmission()}</React.Fragment>;
    }
    return (
      <React.Fragment>
        {this.renderMySubmission()}
        <NoticeBoard danger title="No Evaluations">
          You did not receive any peer evaluations during this activity.
        </NoticeBoard>
      </React.Fragment>
    );
  }

  renderEvaluationsGiven() {
    if (
      StudentUtils.wasStudentEnrolledLate(
        (this.props as any).assignment,
        (this.props as any).myStudent
      ) &&
      !(this.props as any).mySubmission
    ) {
      return this.renderLateEnrollmentBanner();
    }
    const labels = (this.props as any).submissions.map((creation: any, i: any) => {
      const evaluation = EvaluationUtils.getEvaluationByStudentId(
        creation.scores,
        (this.props as any).myStudent._id
      );
      return {
        id: i,
        title: ActivityUtils.isGroupPresentationActivity((this.props as any).assignment)
          ? creation.group.name
          : ActivityUtils.isIndividualPresentationActivity((this.props as any).assignment)
            ? creation.user.profile.name
            : `Evaluation ${i + 1}`,
        status: TabMenuUtils.getEvaluationTabStatus({
          evaluation,
          activity: (this.props as any).assignment,
        }),
      };
    });
    return (this.props as any).submissions.length > 0 ? (
      <React.Fragment>
        <TabMenu
          labels={labels}
          onSelect={(e: any) => {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            return this.handleTabSelect(e, 'peerCreations');
          }}
          activeLabel={this.state.selectedCreationTab}
        />
        {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message */}
        {this.renderTabContent('peerCreations')}
      </React.Fragment>
    ) : (
      <NoticeBoard danger title="">
        You were not assigned any peer evaluations during this activity.
      </NoticeBoard>
    );
  }

  renderFOF(userScore: any) {
    if (
      ActivityUtils.isGroupAssignment((this.props as any).assignment) ||
      EvaluationUtils.isSelfEvaluation({
        evaluation: userScore,
        userId: (this.props as any).myStudent.user,
      })
    ) {
      return null;
    }

    return (
      <StudentContent
        user={(this.props as any).user.authUser}
        score={StudentUtils.getNewKS((this.props as any).myStudent)}
      >
        <Feedback
          assignment={(this.props as any).assignment}
          submission={(this.props as any).mySubmission}
          evaluation={userScore}
        />
      </StudentContent>
    );
  }

  renderPeerEvaluationTab(tabIdx: any) {
    if (tabIdx === undefined || tabIdx === false) {
      return null;
    }
    const userScore = (this.props as any).mySubmission.scores[tabIdx];
    if (!userScore) {
      return null;
    }

    return (
      <React.Fragment>
        <DisplayBox>
          <StudentContent
            user={userScore.user}
            score={StudentUtils.getNewKS((this.props as any).entities.students[userScore.student])}
          >
            <h3>Peer Evaluation</h3>
            <StarRubricMarks
              rubric={(this.props as any).assignment.rubric}
              marks={userScore.marks}
              type="secondary"
              criteriaModeScoringOverruled={null}
              tableType={STAR_RUBRIC_MARKS.DEFAULT}
            />
            <h4 style={{ margin: '20px 0' }}>Written Evaluation</h4>
            {CreationStatusUtil.isCommentRemoved(userScore) ? (
              <NoticeBoard
                type="danger"
                title="This comment has been removed by your instructor."
              />
            ) : (
              <div className="evaluation-student-comment">
                <TextDisplay>
                  <HtmlContent content={userScore.comment} />
                  <FileList files={userScore.files} />
                </TextDisplay>
              </div>
            )}
          </StudentContent>
        </DisplayBox>
        {this.renderFOF(userScore)}
      </React.Fragment>
    );
  }

  renderTabContent(secondary = false) {
    // @ts-expect-error TS(2339) FIXME: Property 'assignment' does not exist on type 'Read... Remove this comment to see the full error message
    const { assignment, mySubmission, studentGroup } = this.props;
    const isFinalized = statusUtil.isFinalized({ assignment });
    if (secondary) {
      // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      return secondary === 'peerCreations' ? (
        <PeerCreationTab tabIdx={this.state.selectedCreationTab} />
      ) : (
        this.renderPeerEvaluationTab(this.state.selectedEvaluationTab)
      );
    }
    if (ActivityUtils.isCalibrationActivity(assignment)) {
      if (isFinalized) {
        return this.renderEvaluationSummary();
      }
      return this.renderEvaluations();
    }
    if (GroupUtils.isNotInGroup(assignment, mySubmission, studentGroup)) {
      if (!(this.props as any).studentGroup) {
        return this.renderNoGroupWarning();
      }
      return null;
    }
    switch (this.state.selectedPrimaryTab) {
      case 'evaluations':
        return this.renderEvaluations();
      case 'feedback':
        return this.renderFeedback();
      case 'feedbackSummary':
        return this.renderFeedbackSummary();
      case 'creation':
        return (
          <CreationSummary
            myCreation={
              (this.props as any).assignment.userAssignment
                ? (this.props as any).assignment.userAssignment
                : (this.props as any).mySubmission
            }
            rubric={(this.props as any).assignment.rubric}
            activity={(this.props as any).assignment}
          />
        );
      case 'kritik':
        return this.renderEvaluationSummary();
      case 'submission':
      default:
        return this.renderMySubmission();
    }
  }

  renderSideBar() {
    const renderSummaryHeader = () => {
      return (
        <h3 className="summary-box__header" data-testid="grading-summary-header">
          Summary
        </h3>
      );
    };
    const items = [];
    if (
      (this.props as any).mySubmission &&
      !ActivityUtils.isCalibrationActivity((this.props as any).assignment)
    ) {
    }
    items.push(<GradingChangeSideBox />);
    return (
      <CollapsibleComponent
        testid="student-grade-summary"
        header={renderSummaryHeader()}
        secondaryTopElement={<FinalizedPanelDisplay />}
        body={<SideBar items={items} />}
      />
    );
  }

  getCreationSectionLabel() {
    if (
      ActivityUtils.isGroupAssignment((this.props as any).assignment) ||
      ActivityUtils.isGroupPresentationActivity((this.props as any).assignment)
    ) {
      return 'Group Creation';
    }
    return 'Your Creation';
  }

  getExpansionPanels = () => {
    const isFinalized = statusUtil.isFinalized({ assignment: (this.props as any).assignment });
    const isGrading = statusUtil.isGrading({ assignment: (this.props as any).assignment });
    const isGradeHistoryAvailable = GradeHistoryUtil.getStudentActivityGradeHistory(
      (this.props as any).gradeHistories,
      (this.props as any).assignment._id
    );
    const panelsContent = [];
    panelsContent.push({
      title: 'Activity Details',
      expanded: false,
      content: <AssignmentOverview showSchedule={false} />,
    });
    if (isGradeHistoryAvailable && this.state.selectedPrimaryTab === 'feedbackSummary') {
      panelsContent.push({
        title: 'Feedback Given',
        expanded: true,
        content: this.renderFeedback(),
      });
    } else if ((isFinalized || isGrading) && this.state.selectedPrimaryTab === 'kritik') {
      panelsContent.push({
        title: 'Evaluations Given',
        expanded: true,
        content: this.renderEvaluationsGiven(),
      });
    } else {
      panelsContent.push({
        title:
          this.state.selectedPrimaryTab !== 'kritik'
            ? this.getCreationSectionLabel()
            : 'Peer Creations',
        expanded: true,
        content:
          this.state.selectedPrimaryTab !== 'kritik'
            ? this.renderEvaluationsReceived()
            : this.renderEvaluationsGiven(),
      });
    }
    return panelsContent;
  };

  render() {
    if (!(this.props as any).assignment || !(this.props as any).submissions) {
      return null;
    }
    const isFinalized =
      statusUtil.isFinalized({ assignment: (this.props as any).assignment }) ||
      !!StudentUtils.getStudentActivityScoreHistory(
        (this.props as any).myStudent.scoreHistories,
        (this.props as any).assignment._id
      );

    const isGrading = statusUtil.isGrading({ assignment: (this.props as any).assignment });
    const menuOption = getMenuOptions((this.props as any).assignment);
    const evaluateOrLater = isEvaluateOrLater((this.props as any).assignment);
    const feedbackOrLater = isFeedbackOrLater({ assignment: (this.props as any).assignment });

    const labels = menuOption.map((menuOption: any) => {
      let stageAssignmentTotal: string | null;
      switch (menuOption.id) {
        case 'evaluations':
          stageAssignmentTotal = evaluateOrLater
            ? `${(this.props as any).numEvalsCompleted}/${(this.props as any).numEvalsAssigned}`
            : null;
          break;
        case 'feedback':
          stageAssignmentTotal = feedbackOrLater
            ? `${(this.props as any).completedFOF}/${(this.props as any).feedbackAssigned}`
            : null;
          break;
        default:
          stageAssignmentTotal = null;
      }

      return {
        id: menuOption.id,
        title: `${menuOption.title}`,
        pill: stageAssignmentTotal !== null ? `${stageAssignmentTotal}` : '',
      };
    });

    return isFinalized ||
      (isGrading && ActivityUtils.isPresentationActivity((this.props as any).assignment)) ? (
      <PageContainer>
        <PageHeader title={(this.props as any).assignment.title} separator />
        <div className="activity-finalized-wrapper">
          <div className="activity-finalized__content">
            <TabMenu
              labels={getFinalizedMenuOptions((this.props as any).assignment)}
              onSelect={(e) => {
                this.handleTabSelect(e);
              }}
              activeLabel={this.state.selectedPrimaryTab}
            />
            {this.renderTabContent()}
            <ExpansionPanel panels={this.getExpansionPanels()} />
          </div>
          <div className="activity__side-panel">{this.renderSideBar()}</div>
        </div>
      </PageContainer>
    ) : (
      <PageContainer>
        <div className="student-view-container">
          <PageHeader title={(this.props as any).assignment.title} separator />
          <AssignmentOverview>
            <TabMenu
              labels={labels}
              onSelect={(e) => {
                this.handleTabSelect(e);
              }}
              activeLabel={this.state.selectedPrimaryTab}
            />
            <TabMenuItem>{this.renderTabContent()}</TabMenuItem>
          </AssignmentOverview>
          {!ActivityUtils.isPresentationActivity((this.props as any).assignment) && (
            <ScoreToast creation={(this.props as any).mySubmission} />
          )}
        </div>
      </PageContainer>
    );
  }
}

function calculateCompletedFeedbackCount(props: any) {
  const { mySubmission, assignment, submissions, myStudent } = props;
  if (!mySubmission) {
    return { completedFOF: 0, toDo: 0 };
  }

  if (ActivityUtils.isPresentationActivity(assignment)) {
    return FeedbackScoreUtil.numFOFCompletedForPresentationActivity({
      creations: submissions || [],
      studentId: myStudent._id,
    });
  } else {
    return FeedbackScoreUtil.numFOFCompleted(mySubmission || {}, assignment, myStudent._id);
  }
}

const StudentView = (props: any) => {
  const evaluateOrLater = isEvaluateOrLater(props.assignment);

  // Check if props.mySubmission is available before accessing its properties
  const { data: submission, isLoading } = useGetCreation({
    id: props.mySubmission?._id, // Access _id property only if props.mySubmission is available
    canUse: evaluateOrLater,
  });

  const numEvalsAssigned =
    (!isLoading &&
      submission &&
      EvaluationUtils.getNumEvaluationsToDo(submission, props.myStudent?._id)) ||
    0;
  const numEvalsCompleted =
    (!isLoading &&
      submission &&
      EvaluationUtils.getNumEvaluationsCompleted(submission, props.myStudent?._id)) ||
    0;

  const { completedFOF, toDo } = calculateCompletedFeedbackCount(props);

  if (evaluateOrLater && isLoading) {
    return (
      <div className="scoring-creation-table__loader--active">
        <Spinner />
      </div>
    );
  }

  return (
    <StudentViewComponent
      {...props}
      submission={submission}
      numEvalsCompleted={numEvalsCompleted}
      numEvalsAssigned={numEvalsAssigned}
      feedbackAssigned={toDo}
      completedFOF={completedFOF}
    />
  );
};

export default withRouter(
  connect(mapStateToProps, {
    setAccessibilityMessage,
  })(StudentView)
);
