import axios from 'axios';
import * as _ from 'lodash-es';
import { normalize } from 'normalizr';
import { push } from 'router';
import { reset, SubmissionError } from 'redux-form';
import { clearEntities, mergeEntities } from 'actions/entities';
import { openGlobalModal } from 'actions/modals';
import { userSchema, studentSchema } from 'schemas';
import { authService, searchService, userService } from 'services';
import * as ErrorUtils from 'utils/error';
import * as types from 'types';
import { localize } from 'locales';

import errorAction from './error';

function beginLogin() {
  return { type: types.MANUAL_LOGIN_USER };
}

function loginSuccess(payload: any) {
  return {
    type: types.LOGIN_SUCCESS_USER,
    payload,
  };
}

function loginError(errors: any) {
  return {
    type: types.LOGIN_ERROR_USER,
    payload: errors,
  };
}

function beginSignUp() {
  return { type: types.SIGNUP_USER };
}

function signUpSuccess(payload: any) {
  return {
    type: types.SIGNUP_USER_SUCCESS,
    success: true,
    payload,
  };
}

function beginLogout() {
  return { type: types.LOGOUT_USER };
}

function logoutSuccess() {
  return { type: types.LOGOUT_SUCCESS_USER };
}

function logoutError(errors: any) {
  return { type: types.LOGOUT_ERROR_USER, payload: errors };
}

export function updateAuthUser(authUser = false) {
  return (dispatch: any, getState: any) => {
    const { user, entities } = getState();

    if (user.authenticated && user.authUser && user.authUser.profile) {
      if (!authUser) {
        authUser = entities.users[user.authUser.id];
      } else if (!(authUser as any)._id || (authUser as any)._id != user.authUser.id) {
        return;
      }
    }

    dispatch({
      type: types.UPDATE_AUTH_USER,
      payload: authUser,
    });
  };
}

export function showLogin(data = {}) {
  // @ts-expect-error TS(2339) FIXME: Property 'redirect' does not exist on type '{}'.
  const { redirect } = data;
  if (redirect == '/signup' || redirect == '/login') {
    return typeof location === 'object' ? push(`/login${location.search}`) : { type: 'NO_ACTION' };
  }
  return push(`/login${redirect ? `?redirect=${redirect}` : ''}`);
}

export function showSignup(data = {}) {
  // @ts-expect-error TS(2339) FIXME: Property 'redirect' does not exist on type '{}'.
  const { redirect } = data;
  if (redirect == '/signup' || redirect == '/login') {
    return typeof location === 'object' ? push(`/signup${location.search}`) : { type: 'NO_ACTION' };
  }

  return push(`/signup${redirect ? `?redirect=${redirect}` : ''}`);
}

export function toggleLoginMode() {
  return { type: types.TOGGLE_LOGIN_MODE };
}

export function oauthLogin(data: any) {
  return (dispatch: any, getState: any) => {
    dispatch(beginLogin());

    return authService()
      .oauth(data)
      .then(
        _.bind((response) => {
          dispatch(getCurrentUser());
          dispatch(
            loginSuccess({
              user: response.data,
              message: localize({ message: 'Message.SuccessfulLogin' }),
            })
          );
        }, data)
      )
      .catch((err: any) => {
        dispatch(
          loginError(
            (err && err.response && err.response.data && err.response.data.errors) || {
              general: 'Connection error, please retry',
            }
          )
        );
      });
  };
}

export function getCurrentUser() {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'GET_CURRENT_USER_REQUEST' });

    return authService()
      .user()
      .then((response: any) => {
        dispatch({ type: 'GET_CURRENT_USER_SUCCESS', payload: response.data });
      })
      .catch((err: any) => {
        dispatch({
          type: 'GET_CURRENT_USER_FAILURE',
          payload: err,
          error: true,
        });
      });
  };
}

export function forgotPassword({ email }: any, { onSuccess }: any = {}) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: types.FORGOT_PASSWORD });

    authService()
      .forgotPassword({ email })
      .then((response: any) => {
        dispatch({
          type: types.FORGOT_PASSWORD_SUCCESS,
          success: true,
          payload: response.data,
        });
        onSuccess?.(response.data?.resetUrl);
      })
      .catch((err: any) => {
        dispatch(errorAction(types.FORGOT_PASSWORD_FAILURE, err));
      });
    setTimeout(() => {
      dispatch({ type: types.RESET_ASYNC, payload: types.FORGOT_PASSWORD });
    }, 3000);
  };
}

export function validateResetToken({ token }: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'VALIDATE_RESET_TOKEN' });

    return authService()
      .validateResetToken({ token })
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({ type: 'VALIDATE_RESET_TOKEN_SUCCESS' });
          return res.data.isValid;
        }
      })
      .catch((err: any) => {
        dispatch({
          type: 'VALIDATE_RESET_TOKEN_FAILURE',
          payload: (err && err.response && err.response.data && err.response.data.errors) || {
            general: 'Error',
          },
        });
      });
  };
}

export function resetPassword(data: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: types.RESET_PASSWORD });

    return authService()
      .resetPassword(data)
      .then((response: any) => {
        dispatch({
          type: types.RESET_PASSWORD_SUCCESS,
          success: true,
          payload: response.data,
        });
      })
      .catch((err: any) => {
        dispatch({ type: types.RESET_PASSWORD_FAILURE, error: err.response.data.errors });
        throw new SubmissionError(
          (err && err.response && err.response.data && err.response.data.errors) || {}
        );
      });
  };
}

export function changePassword(data: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: types.CHANGE_PASSWORD });
    return userService({})
      .changePassword(data)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({ type: types.CHANGE_PASSWORD_SUCCESS });
          dispatch(updateAuthUser());
        }
      })
      .catch((err: any) => {
        dispatch(errorAction(types.CHANGE_PASSWORD_FAILURE, err));
        setTimeout(() => {
          dispatch({ type: types.RESET_ASYNC, payload: types.CHANGE_PASSWORD });
        }, 1000);
        throw new SubmissionError((err && err.response.data.error.message) || {});
      });
  };
}

export function manualLogin(data: any) {
  return (dispatch: any, getState: any) => {
    dispatch(beginLogin());

    return authService()
      .login(data)
      .then(() => {
        document.location.href = data.redirect || '/';
      })
      .catch((err: any) => {
        dispatch(errorAction(types.LOGIN_ERROR_USER, err));
      });
  };
}

export function signUp(data: any) {
  return (dispatch: any, getState: any) => {
    dispatch(beginSignUp());

    return authService()
      .signUp(data)
      .then((response: any) => {
        dispatch(
          signUpSuccess({
            user: response.data,
            message: localize({ message: 'Message.SuccessfulRegister' }),
          })
        );
        return response.data;
      })
      .catch((err: any) => {
        dispatch({ type: types.SIGNUP_USER_FAILURE, error: err.response.data.errors });
        throw new SubmissionError(
          (err && err.response && err.response.data && err.response.data.errors) || {}
        );
      });
  };
}

export function logOut({ destination = '/login' }: { destination?: string }) {
  return (dispatch: any, getState: any) => {
    dispatch(beginLogout());

    return authService()
      .logOut()
      .then(() => {
        dispatch(clearEntities());
        if (window.location && window.location.pathname !== destination) {
          window.location.href = destination;
        }
      })
      .catch((err: any) => {
        dispatch(logoutError(err));
      });
  };
}

export function updateUser(data: any, options = {}) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'UPDATE_USER_REQUEST', payload: data });

    return userService()
      .update({ id: data._id, data })
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, userSchema);
          dispatch(mergeEntities(normalized.entities));
          dispatch(updateAuthUser(res.data));

          if (options && (options as any).passwordChanged) {
            dispatch(reset('user_password_form'));
            dispatch(
              openGlobalModal({
                props: { size: 'small' },
                messageId: localize({ message: 'User.YourPasswordIsChanged' }),
              })
            );
          }

          return dispatch({
            type: 'UPDATE_USER_SUCCESS',
            payload: normalized.result,
            meta: { user: res.data },
          });
        }
      })
      .catch((err: any) => {
        dispatch({
          type: 'UPDATE_USER_REQUEST',
          payload: err,
          error: true,
        });

        const errors = err && err.response && err.response.data && err.response.data.errors;
        if (errors) {
          let keys: any = [];
          if (errors.links) {
            keys = _.keys(errors.links).map((key) => {
              return `links.${key}`;
            });
          }
          keys = keys.concat(_.keys(err.response.data.errors));
          throw new SubmissionError({
            ...err.response.data.errors,
            ...{ _error: keys },
          });
        } else {
          throw new SubmissionError({});
        }
      });
  };
}

export function uploadUserImage(profile: any, imageUrl: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'UPLOAD_USER_IMAGE_REQUEST', payload: profile });

    axios
      .post(`${import.meta.env.VITE_API_URL}/users/${profile._id}/image`, { imageUrl })
      .then((res) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, userSchema);
          dispatch(mergeEntities(normalized.entities));
          dispatch({
            type: 'UPLOAD_USER_IMAGE_SUCCESS',
            payload: normalized.result,
          });
          dispatch(updateAuthUser());
        } else {
          dispatch({ type: 'UPLOAD_USER_IMAGE_FAILURE' });
        }
      })
      .catch((err) => {
        dispatch({ type: 'UPLOAD_USER_IMAGE_FAILURE', payload: err });
      });
  };
}

export function searchAll({ query }: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: types.SEARCH_ALL_REQUEST, payload: query });

    return searchService()
      .all({ query })
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({ type: types.SEARCH_ALL_SUCCESS, payload: res.data });
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.SEARCH_ALL_FAILURE, payload: err });
      });
  };
}

export function getStudentInCourse(params = {}, { client, callback }: any = {}) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: types.GET_STUDENT, payload: (params as any).id });

    userService({ client })
      .getStudent(params)
      .then((res: any) => {
        const normalized = normalize(res.data, studentSchema);
        dispatch(mergeEntities(normalized.entities));
        dispatch({
          type: types.GET_STUDENT_SUCCESS,
          payload: { params, items: normalized.result },
          success: true,
        });
        if (typeof callback === 'function') {
          callback(res.data);
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.GET_STUDENT_FAILURE, payload: err, error: true });
        if (typeof callback === 'function') {
          callback([]);
        }
      });
    setTimeout(() => {
      dispatch({ type: types.RESET_ASYNC, payload: types.GET_STUDENT });
    }, 1000);
  };
}

export function updatePreferences(data: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'UPDATE_PREFERENCES_REQUEST' });

    return userService({})
      .updatePreferences(data)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({ type: 'UPDATE_PREFERENCES_SUCCESS' });
          const normalized = normalize(res.data, studentSchema);
          dispatch(mergeEntities(normalized.entities));
        }
      })
      .catch((err: any) => {
        dispatch({ type: 'UPDATE_PREFERENCES_FAILURE', payload: err });
        throw err;
      });
  };
}

export function updateAccountInformation(data: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: types.UPDATE_ACCOUNT_INFORMATION_REQUEST });

    return userService({})
      .updateAccountInformation(data)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({ type: types.UPDATE_ACCOUNT_INFORMATION_SUCCESS });
          const normalized = normalize(res.data, userSchema);
          dispatch(mergeEntities(normalized.entities));
          dispatch(updateAuthUser());
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.UPDATE_ACCOUNT_INFORMATION_FAILURE, payload: err });
        throw new SubmissionError(
          (err && err.response && err.response.data && err.response.data.errors) || {}
        );
      });
  };
}

export function getUserByEmail({ email }: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'GET_USER_FROM_HASH' });

    return userService({})
      .getUserByEmail({ email })
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, userSchema);
          dispatch(mergeEntities(normalized.entities));
          dispatch({ type: 'GET_USER_FROM_HASH_SUCCESS', payload: res.data });
          return res.data;
        }
      })
      .catch((err: any) => {
        dispatch({ type: 'GET_USER_FROM_HASH_FAILURE', payload: err });
        throw new SubmissionError(
          (err && err.response && err.response.data && err.response.data.errors) || {}
        );
      });
  };
}

export function getTeacherOrAdminByEmail({ email }: any) {
  return async () => {
    return await userService({})
      .getTeacherOrAdminByEmail({ email })
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, userSchema);
          return res.data;
        }
      })
      .catch((error: any) => {
        return { error: error.message || error };
      });
  };
}

export function activateUser(data: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'ACTIVATE_USER' });

    return userService({})
      .activateUser(data)
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, userSchema);
          dispatch(mergeEntities(normalized.entities));
          dispatch({ type: 'ACTIVATE_USER_SUCCESS', payload: res.data });
          return res.data;
        }
      })
      .catch((err: any) => {
        dispatch({ type: 'ACTIVATE_USER_FAILURE', payload: err });
        throw new SubmissionError(
          (err && err.response && err.response.data && err.response.data.errors) || {}
        );
      });
  };
}

export const setIsDifferentOffSet = (payload: any) => {
  return {
    type: types.SET_IS_DIFFERENT_OFFSET,
    payload,
  };
};
