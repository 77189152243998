import { SCORE_VIEW_STATUS } from '@kritik/utils/types';
import { Creation, CreationScore } from '@kritik/types.generated';

export function getHighlightedCriteriaFromScore(creationScore: CreationScore) {
  return creationScore.highlightedCriteria;
}

export function isScoreViewWaiting(creation: Creation) {
  return creation.scoreViewStatus === SCORE_VIEW_STATUS.WAITING;
}

export function isScoreViewReady(creation: Creation) {
  return creation.scoreViewStatus === SCORE_VIEW_STATUS.READY;
}

export function isScoreViewDismissed(creation: Creation) {
  return creation.scoreViewStatus === SCORE_VIEW_STATUS.DISMISSED;
}

export function isScoreViewCompleted(creation: Creation) {
  return creation.scoreViewStatus === SCORE_VIEW_STATUS.VIEWED;
}
