import React, { useState, createContext } from 'react';

export const CourseContext = createContext(null);

const CourseProvider = (props: any) => {
  const [course, setCourse] = useState(null);
  return (
    <CourseContext.Provider value={[course, setCourse]}>{props.children}</CourseContext.Provider>
  );
};

export default CourseProvider;
