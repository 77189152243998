import types from './types';

const initialState = {
  isActivityScoresLoaded: false,
  activityId: null,
};

function activityScoresReducer(state = initialState, action: any) {
  switch (action.type) {
    case types.GET_ACTIVITY_SCORES_SUCCESS:
      return {
        ...initialState,
        ...action.payload.data,
        isActivityScoresLoaded: true,
        activityId: action.payload.activityId,
      };
    case types.GET_AVG_GROUP_SCORES_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default activityScoresReducer;
