import * as _ from 'lodash-es';
import * as creationStatusUtil from '@kritik/utils/creation/status';
import { isEvaluateOrLater } from '@kritik/utils/stage';
import { CreationScoreUtil } from '@kritik/utils/grade';

export const sortByIssues = (itemList: any, ascending: any, activity: any) => {
  let _itemList = [...itemList];

  if (isEvaluateOrLater(activity)) {
    _itemList = sortAfterCreate(itemList);
  } else {
    _itemList = sortDuringCreate(itemList);
  }
  return ascending ? _itemList.reverse() : _itemList;
};

const getCreation = (item: any) => {
  return item.creation;
};

const sortDuringCreate = (itemList: any) => {
  return itemList;
};

const sortAfterCreate = (itemList: any) => {
  // not graded > disputed > flags > prof graded > nothing
  return _.sortBy(itemList, [
    (item) => {
      const creation = getCreation(item);
      const isNotGraded = creationStatusUtil.isCreationNotGraded(creation);
      return isNotGraded;
    },
    (item) => {
      const creation = getCreation(item);
      const isDisputed = creationStatusUtil.isDispute(creation);
      return isDisputed;
    },
    (item) => {
      const creation = getCreation(item);
      const numFlags = creationStatusUtil.getNumOfCreationFlags(creation);
      return numFlags > 0;
    },
    (item) => {
      const creation = getCreation(item);
      const isInstructorGraded =
        CreationScoreUtil.isInstructorGraded(creation) || CreationScoreUtil.isProfRevised(creation);
      return isInstructorGraded;
    },
  ]);
};
