import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import ChevronUp from 'images/icons/fa/ChevronUp';
import ChevronDown from 'images/icons/fa/ChevronDown';
import Typography from 'components/core/Typography';

const CollapsibleContent = (props: any) => {
  const [isOpen, setIsOpen] = React.useState(props.defaultExpanded);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <div onClick={handleClick} className="collapse-content__header" data-testid={props.testid}>
        <div className="collapse-content__title">{props.title}</div>
        <div className="collapse-content__icon">
          <Typography color="light" fontSize="12px">
            {isOpen ? <ChevronUp /> : <ChevronDown />}
          </Typography>
        </div>
      </div>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {props.content}
      </Collapse>
    </React.Fragment>
  );
};

CollapsibleContent.defaultProps = {
  defaultExpanded: false,
  content: null,
};

export default CollapsibleContent;
