import React from 'react';
import FormField from 'components/core/form/Field';
import FormFieldLabel from 'components/core/form/FieldLabel';
import { getPassLevelName } from '@kritik/utils/rubric';

const PassFailWarning = ({ rubric, isNumericGrading }: any) => {
  if (isNumericGrading) {
    return null;
  }

  const style = { color: '#C528B2', fontWeight: 500, textTransform: 'capitalize' };
  if (rubric.passFailCondition === 'MinimumPercentage') {
    return (
      <FormField>
        <FormFieldLabel label="Pass/Fail" />
        In order for a student to pass this activity, the student must at least obtain
        {/* @ts-expect-error TS(2322) FIXME: Type '{ color: string; fontWeight: number; textTra... Remove this comment to see the full error message */}
        <span style={style}> {`${rubric.minimumPercentage}% in Creation Score`} </span>
      </FormField>
    );
  }
  const criteria = rubric.passCriteriaRule;
  return (
    <FormField>
      <FormFieldLabel label="Pass/Fail" />
      In order for a student to pass this activity, the student must at least obtain
      {/* @ts-expect-error TS(2322) FIXME: Type '{ color: string; fontWeight: number; textTra... Remove this comment to see the full error message */}
      <span style={style}> {getPassLevelName(rubric)} </span>
      in
      {/* @ts-expect-error TS(2322) FIXME: Type '{ color: string; fontWeight: number; textTra... Remove this comment to see the full error message */}
      <span style={style}>{` ${criteria} Criteria`}</span>.
    </FormField>
  );
};

PassFailWarning.defaultProps = {
  isNumericGrading: true,
};

export default PassFailWarning;
