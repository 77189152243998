import React from 'react';
import FormFieldLabel from 'components/core/form/FieldLabel';
import FormFieldInput from 'components/core/form/FieldInput';
import FormField from 'components/core/form/Field';
import FormFieldInfo from 'components/core/form/FieldInfo';
import RadioInput from 'components/core/input/Radio';

export const Radio = (props: any) => {
  return (
    <FormField error={props.error} className={props.className}>
      <FormFieldLabel isRequired={props.isRequired} label={props.label} />
      <FormFieldInput error={props.error}>
        <RadioInput
          isDisabled={props.isDisabled}
          value={props.value}
          onChange={props.onChange}
          options={props.options}
          type={props.type}
          legendText={props.legendText}
        />
      </FormFieldInput>
      <FormFieldInfo>{props.info}</FormFieldInfo>
    </FormField>
  );
};

Radio.defaultProps = {
  isRequired: false,
  options: [],
  type: 'default',
  label: '',
  error: '',
  className: '',
  legendText: '',
  isDisabled: false,
};

export default Radio;
