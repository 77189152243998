import React, { useState } from 'react';
import { connect } from 'react-redux';
import { resolveDispute } from 'actions/activity';
import ConfirmModal from 'components/modals/ConfirmModal';
import { InlineInformation, TextDisplay } from 'components/layout';
import { Button } from 'components/buttons';
import { Card, CardActions, CardContent } from '@material-ui/core';

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

const CreationDispute = ({ creation, handleIsResolvingDispute, user, resolveDispute }: any) => {
  const [instructorComment, setInstructorComment] = useState('');
  const [error, setError] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(null);
  const [isSubmittingDisputeResolution, setIsSubmittingDisputeResolution] = useState(false);

  const resetDisputeEditor = () => {
    setShowConfirmModal(false);
    setInstructorComment('');
    setError(null);
    setIsSubmittingDisputeResolution(false);
    handleIsResolvingDispute();
  };

  const handleResolve = async () => {
    if (!isSubmittingDisputeResolution) {
      setIsSubmittingDisputeResolution(true);
      const disputeData = {
        submissionId: creation._id,
        assignmentId: creation.assignment,
        dispute: {
          status: 'Resolved',
          resolvedOn: new Date(Date.now()),
          resolvedBy: user.authUser.id,
          teacherComment: instructorComment,
        },
      };
      try {
        await resolveDispute(disputeData);
        resetDisputeEditor();
      } catch (err) {
        setError(err);
        setShowConfirmModal(false);
        setIsSubmittingDisputeResolution(false);
      }
    }
  };
  return (
    <Card>
      <ConfirmModal
        isOpen={showConfirmModal}
        onCancel={() => {
          setShowConfirmModal(false);
        }}
        onConfirm={async () => {
          await handleResolve();
        }}
        cancelButton="No"
        confirmButton="Yes"
        title="Are you sure you want to resolve this dispute?"
        description="Ensure that you have reviewed the student's score and are
            satisfied with the result. The student will be notifed of the
            dispute resolution."
        testid="confirm-resolve-dispute-modal"
      />
      <CardContent className="resolve-dispute-container">
        <h3 className="header-3">Manage Dispute</h3>
        <p>
          <b>{creation.user.profile.name}</b> has disputed their grade.{' '}
        </p>
        <p>
          {' '}
          Please edit the student's grade if necessary. Once you are satisfied, you can resolve the
          dispute.{' '}
        </p>
        <p>
          <b>Reason for dispute:</b> <TextDisplay content={creation.dispute.reason} />
        </p>
        <textarea
          id="prof-dispute-comment"
          // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'number'.
          rows="5"
          name="instructorComment"
          placeholder="I have reviewed this submission and decided that..."
          onChange={(e) => {
            setInstructorComment(e.target.value);
          }}
          value={instructorComment}
          data-testid="instructor-dispute-comment"
        />
        {error && <InlineInformation type="danger">{error}</InlineInformation>}
      </CardContent>
      <CardActions className="resolve-button-container">
        <Button
          type="primary"
          onClick={() => {
            setShowConfirmModal(true);
          }}
          testid="resolve-dispute"
        >
          Resolve Dispute
        </Button>
        <Button
          type="secondary"
          onClick={() => {
            setShowConfirmModal(false);
            handleIsResolvingDispute();
          }}
        >
          Cancel
        </Button>
      </CardActions>
    </Card>
  );
};

export default connect(mapStateToProps, {
  resolveDispute,
})(CreationDispute);
