const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
const SET_CREATION_TABLE_COLUMNS = 'SET_CREATION_TABLE_COLUMNS';
const SET_CREATION_TABLE_TYPE = 'SET_CREATION_TABLE_TYPE';
const SET_CREATION_TABLE_LIST_BY = 'SET_CREATION_TABLE_LIST_BY';
const SET_CREATION_TABLE_FILTER = 'SET_CREATION_TABLE_FILTER';
const SET_CREATION_TABLE_SEARCH = 'SET_CREATION_TABLE_SEARCH';
const SET_IS_SHOW_EVALS_COMPLETED = 'SET_IS_SHOW_EVALS_COMPLETED';
const SET_IS_SHOW_FEEDBACK_COMPLETED = 'SET_IS_SHOW_FEEDBACK_COMPLETED';
const SET_CREATION_TABLE_ACTIVITY = 'SET_CREATION_TABLE_ACTIVITY';
const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
const SET_TOTAL_ROWS_COUNT = 'SET_TOTAL_ROWS_COUNT';
const SET_PAGE_COUNT = 'SET_PAGE_COUNT';
const SET_CREATION_TABLE_LOADING = 'SET_CREATION_TABLE_LOADING';

const types = {
  SET_INITIAL_STATE,
  SET_CREATION_TABLE_COLUMNS,
  SET_CREATION_TABLE_TYPE,
  SET_CREATION_TABLE_LIST_BY,
  SET_CREATION_TABLE_FILTER,
  SET_CREATION_TABLE_SEARCH,
  SET_IS_SHOW_EVALS_COMPLETED,
  SET_IS_SHOW_FEEDBACK_COMPLETED,
  SET_CREATION_TABLE_ACTIVITY,
  SET_CURRENT_PAGE,
  SET_PAGE_SIZE,
  SET_TOTAL_ROWS_COUNT,
  SET_PAGE_COUNT,
  SET_CREATION_TABLE_LOADING,
};

export default types;
