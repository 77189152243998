import { push } from 'router';

export function navigateToHomePage() {
  return push(`/`);
}

export function navigateToActivityImport({ courseId }: any) {
  return push(`/course/${courseId}/import`);
}

export function navigateToRubricManager() {
  return push(`/rubric-manager`);
}

export function navigateToCourseScoreEdit({ courseId }: any) {
  return push(`/course/${courseId}/score/edit`);
}

export default {
  navigateToActivityImport,
  navigateToCourseScoreEdit,
  navigateToHomePage,
};
