import { localize } from 'locales';

const StudentPermissionsOption = (props: any) => {
  return (
    <div>
      <h3 className="course-details__title">
        {localize({ message: 'CourseDetails.StudentPermissionOption.Title' })}
      </h3>
      <p className="course-details__user-email">
        {localize({ message: 'CourseDetails.StudentPermissionOption.Description' })}
      </p>
      <p className="course-details__subtitle course-details__section-margin-top">
        {localize({ message: 'CourseDetails.StudentPermissionOption.Subtitle.ClassAverage' })}
      </p>
      <p>
        {props.course.courseSettings.hideAverageGrade
          ? localize({
              message: 'CourseDetails.StudentPermissionOption.Subtitle.ClassAverage.Hide',
            })
          : localize({
              message: 'CourseDetails.StudentPermissionOption.Subtitle.ClassAverage.Show',
            })}
      </p>
    </div>
  );
};

export default StudentPermissionsOption;
