import React from 'react';
import Button from 'components/buttons/Button';
import NewTabIcon from 'images/icons/fa/NewTab';

function NewTabButton(props: any) {
  return (
    <Button
      type="secondary"
      onClick={() => {
        props.onClick();
      }}
      testid={props.testid}
      title={props.title}
    >
      <div className="new-tab-btn">
        <NewTabIcon />
        <span className="new-tab-btn__label">{props.label}</span>
      </div>
    </Button>
  );
}

export default NewTabButton;
