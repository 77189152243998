import { schema } from 'normalizr';

import user from 'schemas/user';
import course from 'schemas/course';
import assignment from 'schemas/activity';

const rubric = new schema.Entity(
  'rubrics',
  {
    user,
    course,
    assignment,
  },
  { idAttribute: '_id' }
);

export default rubric;
