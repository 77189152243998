import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAssignment, getAssignmentList } from 'selectors/activity';
import { getCourse } from 'selectors/course';
import FormTitle from 'components/core/form/Title';
import FormField from 'components/core/form/Field';
import FormFieldLabel from 'components/core/form/FieldLabel';
import FormContainer from 'components/core/form/Container';
import FormSection from 'components/core/form/Section';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import { Form, Field } from 'react-final-form';
import RubricPreview from 'components/ActivityEdit/Rubric/Preview';
import { ACTIVITY_TEMPLATES } from 'components/ActivityEdit/Details/constants';
import AttachmentManager from 'components/layout/AttachmentManager';
import TitleField from 'components/ActivityEdit/Details/Fields/Title';
import { FIELD_NAME as TITLE } from 'components/ActivityEdit/Details/Fields/Title/constants';
import ObjectiveField from 'components/ActivityEdit/Details/Fields/Objective';
import { FIELD_NAME as OBJECTIVE } from 'components/ActivityEdit/Details/Fields/Objective/constants';
import InstructionsField from 'components/ActivityEdit/Details/Fields/Instructions';
import { FIELD_NAME as INSTRUCTIONS } from 'components/ActivityEdit/Details/Fields/Instructions/constants';
import {
  FIELD_NAME as RUBRIC,
  FIELD_LABEL as RUBRIC_LABEL,
} from 'components/ActivityEdit/Details/Rubric/constants';
import { Button } from 'components/buttons';
import PresentationOptions from './PresentationOptions';
import PresentationType from 'components/ActivityEdit/Presentation/PresentationType';
import {
  ACTIVITY_TYPES,
  NOTES_PLACEHOLDER,
  PRESENTATION_TEMPLATE_RUBRIC,
} from '@kritik/constants/activity';
import { getInitialPresentationActivityAdvancedOptionValues } from 'components/ActivityEdit/AdvancedOptions/utils';
import { selectRubrics } from 'selectors/rubric';
import setFieldData from 'final-form-set-field-data';
import { updateAssignment } from 'actions/activity';
import localUtil from 'components/ActivityEdit/util';
import ErrorMsg from 'components/ActivityEdit/ErrorMsg';
import { isScheduled, isDraft, isCreate, isEvaluateOrLater } from '@kritik/utils/stage';
import {
  hasEvaluatorNotes,
  getEvaluatorNotes,
  getEvaluatorNotesFiles,
  isNumericGrading,
  autoAcceptsLateSubmissions,
  acceptsLateSubmissions,
} from '@kritik/utils/activity';
import {
  HAS_EVALUATOR_NOTES,
  EVALUATOR_NOTES_FILES,
  FIELD_NAME as EVALUATOR_NOTES,
} from 'components/ActivityEdit/Details/Fields/Notes/constants';
import NotesField from 'components/ActivityEdit/Details/Fields/Notes';
import { groupService } from 'services';
import { localize } from 'locales';
import { getFormattedFormValues } from './utils';
import PresentationInfo from './PresentationInfo';
import { withRouter } from 'utils/withRouter';
import { trackEvent } from 'utils/userEvents';
import { selectAuthUser } from 'selectors/user';
import { selectRubricTemplate } from 'redux/activityEdit';
import { useIsFeatureFlagEnabled } from 'context/growthbookContext';
import { FIELD_NAME as removeStudentFromActivityAfterMissedCreation } from 'components/ActivityEdit/AdvancedOptions/Fields/MissedCreation/constants';
import { useUserRoleInCourse } from 'hooks/course';
import { LateSubmissionSettings } from 'components/DefaultActivitySettings/LateSubmissionSettings';
import {
  ACCEPT_LATE_CREATIONS,
  AUTO_ACCEPT_LATE_CREATIONS,
} from 'components/LateCreation/constants';
import * as courseUtil from '@kritik/utils/course';

const Presentation = (props: any) => {
  const { isStudentInCourse } = useUserRoleInCourse();

  useEffect(() => {
    props.changeActivityType(props.values.activityType);
  }, [props.values.activityType]);

  const onSelectRubric = (rubric: any) => {
    props.form.mutators.setValue(RUBRIC, rubric);
  };
  const canEditRubric = () => {
    if (props.values.settings.isDuplicating) {
      return true;
    }
    const { activity } = props;
    const isValidStatus =
      !isScheduled(activity) ||
      isDraft({ assignment: activity }) ||
      isCreate({ assignment: activity });

    return isValidStatus;
  };

  return (
    <FormContainer>
      <FormTitle label="Activity Details" size="xl" />
      <TitleField />
      <ObjectiveField />
      <InstructionsField />
      <Field name="files">
        {({ input }) => {
          return (
            <FormField>
              <FormFieldLabel label="Resources" />
              <AttachmentManager
                onFileChange={input.onChange}
                fileList={input.value ? input.value : []}
              />
            </FormField>
          );
        }}
      </Field>
      <FormSection>
        <FormTitle label="Evaluator Notes" size="xl" />
        <NotesField />
      </FormSection>
      <FormSection>
        <FormTitle label={RUBRIC_LABEL} size="xl" />
        <RubricPreview
          rubric={props.values[RUBRIC]}
          canEditRubric={canEditRubric()}
          isNumericGrading={props.values.isNumericGrading}
          onSelectRubric={onSelectRubric}
          canViewMode={!isStudentInCourse}
          form={props.form}
        />
      </FormSection>
    </FormContainer>
  );
};

const FormWrapper = (props: any) => {
  const [currentActivityType, setCurrentActivityType] = useState(
    ACTIVITY_TYPES.INDIVIDUAL_PRESENTATION
  );
  const [courseHasGroupSets, setCourseHasGroupSets] = useState(false);

  const getInitialActivityType = () => {
    if (props.activity.activityType === ACTIVITY_TYPES.GROUP_PRESENTATION) {
      return ACTIVITY_TYPES.GROUP_PRESENTATION;
    }
    return ACTIVITY_TYPES.INDIVIDUAL_PRESENTATION;
  };

  const getInitialFormValues = (): any => {
    let values = {};
    if (props.isEdit || props.isDuplicating) {
      values = {
        activityType: getInitialActivityType(),
        [TITLE]: props.activity.title,
        [RUBRIC]: props.activity.rubric,
        userRubrics: props.userRubrics,
        isNumericGrading: isNumericGrading(props.activity),
        [OBJECTIVE]: props.activity.objective,
        [INSTRUCTIONS]: props.activity.instructions,
        files: props.activity.files,
        [HAS_EVALUATOR_NOTES]: hasEvaluatorNotes(props.activity),
        [EVALUATOR_NOTES]: getEvaluatorNotes(props.activity),
        [EVALUATOR_NOTES_FILES]: getEvaluatorNotesFiles(props.activity),
        weight: props.activity.weight,
        requireFile: props.activity.requireFile,
        isGroupActivity: props.activity.isGroupActivity,
        [removeStudentFromActivityAfterMissedCreation]: false,
        startingScore: props.activity.startingScore,
        acceptLateSubmissions: acceptsLateSubmissions(props.activity),
        autoAccept: autoAcceptsLateSubmissions(props.activity),
      };
    } else if (currentActivityType === ACTIVITY_TYPES.GROUP_PRESENTATION) {
      const template = ACTIVITY_TEMPLATES.groupPresentation;
      values = {
        activityType: ACTIVITY_TYPES.GROUP_PRESENTATION,
        [TITLE]: '',
        [RUBRIC]: props.userRubrics.find((rubric) => rubric.name === PRESENTATION_TEMPLATE_RUBRIC),
        userRubrics: props.userRubrics,
        [OBJECTIVE]: template[OBJECTIVE],
        [INSTRUCTIONS]: template[INSTRUCTIONS],
        files: [],
        isNumericGrading: true,
        [HAS_EVALUATOR_NOTES]: false,
        [EVALUATOR_NOTES_FILES]: [],
        [EVALUATOR_NOTES]: NOTES_PLACEHOLDER,
        requireFile: false,
        isGroupActivity: true,
        [removeStudentFromActivityAfterMissedCreation]: false,
        startingScore: props.course.startingScore,
        [ACCEPT_LATE_CREATIONS]: courseUtil.acceptsLateSubmissions(props.course),
        [AUTO_ACCEPT_LATE_CREATIONS]: courseUtil.autoAcceptsLateSubmissions(props.course),
      };
    } else {
      const template = ACTIVITY_TEMPLATES.individualPresentation;
      values = {
        activityType: ACTIVITY_TYPES.INDIVIDUAL_PRESENTATION,
        [TITLE]: '',
        [RUBRIC]: props.userRubrics.find((rubric) => rubric.name === PRESENTATION_TEMPLATE_RUBRIC),
        userRubrics: props.userRubrics,
        [OBJECTIVE]: template[OBJECTIVE],
        [INSTRUCTIONS]: template[INSTRUCTIONS],
        files: [],
        isNumericGrading: true,
        [HAS_EVALUATOR_NOTES]: false,
        [EVALUATOR_NOTES_FILES]: [],
        [EVALUATOR_NOTES]: NOTES_PLACEHOLDER,
        requireFile: false,
        isGroupActivity: false,
        [removeStudentFromActivityAfterMissedCreation]: false,
        startingScore: props.course.startingScore,
        [ACCEPT_LATE_CREATIONS]: courseUtil.acceptsLateSubmissions(props.course),
        [AUTO_ACCEPT_LATE_CREATIONS]: courseUtil.autoAcceptsLateSubmissions(props.course),
      };
    }

    const advancedOptions = getInitialPresentationActivityAdvancedOptionValues(
      props.activity,
      props.course
    );
    return {
      ...advancedOptions,
      ...values,
      settings: {
        isDuplicating: props.isDuplicating,
        isEdit: props.isEdit,
      },
    };
  };

  useEffect(() => {
    const getCourseGroupSets = async () => {
      try {
        const response = await groupService().getAllGroupSets({ courseId: props.course._id });
        if (response.data.length) {
          setCourseHasGroupSets(true);
        }
      } catch (ignore) {}
    };
    void getCourseGroupSets();
  }, []);

  const handleOnSubmit = (values: any) => {
    const data = getFormattedFormValues(values, props.router.params.courseId);
    const activityId = props.router.params.assignmentId;
    const courseId = props.router.params.courseId;
    trackEvent('Presentation Activity Saved', props.authUser, {
      activityId,
      courseId,
      activityType: data.activityType,
    });

    return props.onSubmit({
      data,
      formSettings: localUtil.getFormSettings(values),
      fileList: values.files,
      redirectTo: values.action === 'save-and-edit-participation' ? 'edit-participation' : '',
    });
  };

  const [initialValues, setInitialValues] = useState(getInitialFormValues());

  const createStageHasPassed = isEvaluateOrLater(props.activity);

  useEffect(() => {
    setInitialValues(getInitialFormValues());
    if (!props.isEdit && !props.isDuplicating) {
      props.selectRubricTemplate(initialValues.rubric?._id);
    }
  }, [currentActivityType]);

  const showNextButton =
    currentActivityType === ACTIVITY_TYPES.GROUP_PRESENTATION
      ? courseHasGroupSets
      : props.course?.students?.length > 0;

  return (
    <>
      <PresentationInfo />
      <Form
        onSubmit={handleOnSubmit}
        initialValues={initialValues}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => {
              return value;
            });
          },
          setFieldData,
        }}
        render={({ handleSubmit, form, submitting, values, submitError }) => {
          const showLateSubmissionsSettings = values.requireFile;
          props.formRef.current = form;
          let formEdit = values;
          if (props.isEdit) {
            formEdit = {
              ...values,
            };
          }

          return (
            <form onSubmit={handleSubmit}>
              {!props.isEdit && !props.isDuplicating && (
                <PresentationType
                  formState={form.getState()}
                  mutators={form.mutators}
                  settings={values.settings}
                />
              )}
              <Presentation
                form={form}
                values={formEdit}
                course={props.course}
                activity={props.activity}
                changeActivityType={(type: any) => {
                  return setCurrentActivityType(type);
                }}
              />
              <PresentationOptions
                isDuplicatingActivity={props.isDuplicating}
                activity={props.activity}
                course={props.course}
              />
              {showLateSubmissionsSettings && (
                <LateSubmissionSettings
                  showNoLateSubmissionsAllowedNotice={false}
                  acceptLateCreations={values.acceptLateSubmissions}
                  onAcceptLateCreationsChange={(e) => {
                    form.change('acceptLateSubmissions', e.target.checked);
                    form.change('autoAccept', false);
                  }}
                  autoAcceptLateCreations={values.autoAccept}
                  onAutoAcceptLateCreationsChange={(e) =>
                    form.change('autoAccept', e.target.checked)
                  }
                  acceptLateEvaluations={values.acceptLateEvaluations}
                  onAcceptLateEvaluationsChange={(e) => {
                    form.change('acceptLateEvaluations', e.target.checked);
                    form.change('autoAcceptLateEvaluations', false);
                  }}
                  autoAcceptLateEvaluations={values.autoAcceptLateEvaluations}
                  onAutoAcceptLateEvaluationsChange={(e) =>
                    form.change('autoAcceptLateEvaluations', e.target.checked)
                  }
                  activity={props.activity}
                  hideLateEvaluationsSettings
                  isDuplicatingActivity={props.isDuplicating}
                />
              )}
              <FormSubmitButtons errors={<ErrorMsg />}>
                <Button
                  type="primary"
                  inputType="submit"
                  loading={submitting && values.action === 'save'}
                  disabled={submitting}
                  data-testid="save-activity"
                  onClick={() => {
                    form.change('action', 'save');
                  }}
                >
                  {localize({ message: 'Activity.Presentation.Save' })}
                </Button>
                {showNextButton && !createStageHasPassed ? (
                  <Button
                    type="primary"
                    inputType="submit"
                    loading={submitting && values.action === 'save-and-edit-participation'}
                    disabled={submitting}
                    data-testid="save-and-edit-participation"
                    onClick={() => {
                      form.change('action', 'save-and-edit-participation');
                    }}
                    label={localize({
                      message: 'Button.Label.SaveAndRedirectToStudentParticipation',
                      options: { activityType: currentActivityType },
                    })}
                  >
                    {localize({ message: 'Activity.Presentation.SaveAndNext' })}
                  </Button>
                ) : null}
                <Button type="secondary" onClick={props.onCancel}>
                  {localize({ message: 'Activity.Presentation.CancelSave' })}
                </Button>
              </FormSubmitButtons>
            </form>
          );
        }}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
    activityId: state.selected.assignmentId,
    course: getCourse(state, state.selected.courseId),
    userRubrics: selectRubrics(state),
    activities: getAssignmentList(state),
    authUser: selectAuthUser(state),
  };
};

export default withRouter(
  connect(mapStateToProps, { updateAssignment, selectRubricTemplate })(FormWrapper)
);
