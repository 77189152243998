import { Route, createRoutesFromElements } from 'react-router';

import { fetchCourse, fetchAssignment } from 'fetch-data';

import {
  ActivityImport,
  AdminActivitySettings,
  AdminImpersonate,
  AdminIndex,
  App,
  Assignment,
  CouponDashboard,
  Course,
  CourseAssignments,
  CourseData,
  CourseEnrollment,
  CourseScoreEdit,
  CourseSettings,
  CreateActivityAIPage,
  CreateAssignment,
  DemoRegister,
  DuplicateActivity,
  EditActivity,
  EditActivityParticipation,
  EditCourseDetails,
  ErrorPage,
  GenericErrorPage,
  Home,
  InstructorCreationView,
  LMSConnectionPending,
  LmsGradeSync,
  LmsIntegrations,
  LoginOrRegister,
  NoCourse,
  NoUserAccount,
  PresentationSchedule,
  RosterManager,
  Rubric,
  RubricManager,
  Schedule,
  Setting,
  Spotlight,
  UpdateUserEmail,
} from 'pages';

import { isAdmin } from 'utils/permission';
import ChangeCoursePrice from 'components/Admin/ChangeCoursePriceModal/ChangeCoursePrice';
import Users from 'pages/Admin/Users';
import PromoteAdmin from './pages/Admin/PromoteAdmin';
import DeleteUser from './pages/Admin/DeleteUser';
import DefaultCoursePrice from 'pages/Admin/Institution/DefaultCoursePrice';

import type { RouteOnEnter } from 'app-types';
import { MigratedRoute } from 'utils/MigratedRoute';
import { localize } from './locales';
import { CoursePage } from 'components/Course/CoursePage';
import LmsConnectionRequests from 'containers/Admin/LmsConnectionRequests';
import { createBrowserRouter } from 'react-router-dom';
import { ActivityDetailsCreationScoreComparisonTable } from 'routes/ActivityDetails.CreationScoreComparisonTable';
import CreationList from 'components/CreationList';
import { ActionItems } from 'routes/ActionItems/ActionItems.index';
import { CreationSimilarityReport } from 'routes/CreationSimilarityReport';
import { ResolveCreationSimilarityReport } from 'routes/ResolveCreationSimilarityReport';

/*
 * @param {Redux Store}
 * We require store as an argument here because we wish to get
 * state from the store after it has been authenticated.
 */
export default (store: any) => {
  const requireAuth: RouteOnEnter = ({ location, replace, callback }) => {
    const {
      user: { authenticated },
    } = store.getState();
    if (!authenticated) {
      replace(`/login?redirect=${location.pathname}${location.search}`);
    }
    callback();
  };

  const redirectAuth: RouteOnEnter = ({ location, replace, callback }) => {
    const {
      user: { authenticated },
    } = store.getState();
    if (authenticated) {
      const redirectUrl = new URLSearchParams(location.search).get('redirect');
      replace(redirectUrl || '/');
    }
    callback();
  };

  const instructorAuth: RouteOnEnter = ({ replace, callback }) => {
    const { user } = store.getState();
    if (!user?.authUser?.permissions.includes('canCreateCourse')) {
      replace('/');
    }
    callback();
  };

  const instructorInCourseAuth: RouteOnEnter = ({ replace, callback, course }) => {
    if (course?.userRole === 'instructor') {
      callback();
    } else {
      replace('/');
    }
  };

  const adminAuth: RouteOnEnter = ({ replace, callback }) => {
    const { user } = store.getState();

    if (!user.authenticated || !isAdmin(user.authUser)) {
      replace('/');
    }
    callback();
  };

  const instructorOrUnauthenticatedAuth: RouteOnEnter = ({ location, replace, callback }) => {
    const { user } = store.getState();
    if (user.authenticated && !user?.authUser?.permissions.includes('canCreateCourse')) {
      const redirectUrl = new URLSearchParams(location.search).get('redirect');
      replace(redirectUrl || '/');
    }
    callback();
  };

  return createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={<MigratedRoute store={store} element={App} />}
        errorElement={
          <GenericErrorPage
            subtitle={localize({ message: 'Error.500Subtitle' })}
            icon="🚒"
            title={localize({ message: 'Error.500Title' })}
            buttonText={localize({ message: 'Error.ButtonText' })}
            descriptions={[localize({ message: 'Error.500Text' })]}
            iconVariant="blue"
          />
        }
      >
        <Route
          index
          element={
            <MigratedRoute
              store={store}
              element={Home}
              onEnter={requireAuth}
              fetchData={fetchCourse.list}
            />
          }
        />
        <Route
          path="admin"
          element={<MigratedRoute store={store} element={AdminIndex} onEnter={adminAuth} />}
        />
        <Route
          path="admin/courseSettings"
          element={<MigratedRoute store={store} element={CourseSettings} onEnter={adminAuth} />}
        />
        <Route
          path="admin/coupons"
          element={<MigratedRoute store={store} element={CouponDashboard} onEnter={adminAuth} />}
        />
        <Route
          path="admin/pricing"
          element={<MigratedRoute store={store} element={ChangeCoursePrice} onEnter={adminAuth} />}
        />
        <Route
          path="admin/course-data"
          element={<MigratedRoute store={store} element={CourseData} onEnter={adminAuth} />}
        />
        <Route
          path="admin/lms-connection-requests"
          element={
            <MigratedRoute store={store} element={LmsConnectionRequests} onEnter={adminAuth} />
          }
        />
        <Route
          path="admin/users"
          element={<MigratedRoute store={store} element={Users} onEnter={adminAuth} />}
        />
        <Route
          path="admin/users/promote-admin"
          element={<MigratedRoute store={store} element={PromoteAdmin} onEnter={adminAuth} />}
        />
        <Route
          path="admin/users/delete-user"
          element={<MigratedRoute store={store} element={DeleteUser} onEnter={adminAuth} />}
        />
        <Route
          path="admin/users/update-user-email"
          element={<MigratedRoute store={store} element={UpdateUserEmail} onEnter={adminAuth} />}
        />
        <Route
          path="admin/institution/default-course-price"
          element={<MigratedRoute store={store} element={DefaultCoursePrice} onEnter={adminAuth} />}
        />
        <Route
          path="admin/impersonate"
          element={<MigratedRoute store={store} element={AdminImpersonate} onEnter={adminAuth} />}
        />
        <Route
          path="admin/activitySettings"
          element={
            <MigratedRoute store={store} element={AdminActivitySettings} onEnter={adminAuth} />
          }
        />
        <Route
          path="admin/lms-integrations"
          element={<MigratedRoute store={store} element={LmsIntegrations} onEnter={adminAuth} />}
        />
        <Route
          path="login"
          element={<MigratedRoute store={store} element={LoginOrRegister} onEnter={redirectAuth} />}
        />
        <Route
          path="signup"
          element={<MigratedRoute store={store} element={LoginOrRegister} onEnter={redirectAuth} />}
        >
          {['student', 'instructor'].map((path, index) => {
            return (
              <Route
                element={
                  <MigratedRoute store={store} element={LoginOrRegister} onEnter={redirectAuth} />
                }
                key={`${path} ${index}`}
                path={path}
              />
            );
          })}
        </Route>
        <Route path="forgot" element={<MigratedRoute store={store} element={LoginOrRegister} />} />
        <Route
          path="reset/:token"
          element={<MigratedRoute store={store} element={LoginOrRegister} />}
        />
        <Route
          path="demo"
          element={
            <MigratedRoute
              store={store}
              element={DemoRegister}
              onEnter={instructorOrUnauthenticatedAuth}
            />
          }
        />
        <Route
          path="course/:courseId"
          element={
            <MigratedRoute
              store={store}
              element={Course}
              onEnter={requireAuth}
              fetchData={fetchCourse.get}
            />
          }
        />

        <Route path="course/:courseId/*" element={<CoursePage />}>
          <Route
            path="details/edit"
            element={
              <MigratedRoute
                store={store}
                element={EditCourseDetails}
                onEnter={instructorInCourseAuth}
              />
            }
          />
          <Route
            path="score/edit"
            element={
              <MigratedRoute
                store={store}
                element={CourseScoreEdit}
                fetchData={fetchCourse.get}
                onEnter={instructorInCourseAuth}
              />
            }
          />
          <Route path="action-items" element={<ActionItems />} loader={ActionItems.loader} />

          <Route
            path="assignments"
            element={
              <MigratedRoute
                store={store}
                element={CourseAssignments}
                fetchData={fetchCourse.getCourseAndAssignmentsWithoutRefetch}
                onEnter={requireAuth}
              />
            }
          />
          <Route path="assignment/:assignmentId">
            <Route
              path="reports/similarity/:creationId"
              loader={CreationSimilarityReport.loader}
              element={<CreationSimilarityReport />}
            >
              <Route path="resolve" element={<ResolveCreationSimilarityReport />} />
            </Route>
            <Route
              element={
                <MigratedRoute
                  store={store}
                  element={Assignment}
                  fetchData={fetchCourse.getCourseAndAssignmentsWithoutRefetch}
                  onEnter={requireAuth}
                />
              }
            >
              <Route index element={<CreationList />} />
              <Route
                path="score-comparison"
                loader={ActivityDetailsCreationScoreComparisonTable.loader}
                element={<ActivityDetailsCreationScoreComparisonTable />}
              />
            </Route>
            <Route
              path="creation/:creationId"
              element={
                <MigratedRoute
                  store={store}
                  element={InstructorCreationView}
                  fetchData={fetchCourse.get}
                  onEnter={instructorInCourseAuth}
                />
              }
            />
            <Route
              path="edit-assignment"
              element={
                <MigratedRoute
                  store={store}
                  element={EditActivity}
                  fetchData={fetchCourse.getCourseAndAssignmentsWithoutRefetch}
                  onEnter={instructorInCourseAuth}
                />
              }
            />
            <Route
              path="duplicate-assignment"
              element={
                <MigratedRoute
                  store={store}
                  element={DuplicateActivity}
                  fetchData={fetchCourse.getCourseAndAssignmentsWithoutRefetch}
                  onEnter={instructorInCourseAuth}
                />
              }
            />
            <Route
              path="edit-participation"
              element={
                <MigratedRoute
                  store={store}
                  element={EditActivityParticipation}
                  onEnter={instructorInCourseAuth}
                />
              }
            />
            <Route
              path="schedule"
              element={
                <MigratedRoute
                  store={store}
                  element={Schedule}
                  fetchData={fetchCourse.get}
                  onEnter={instructorInCourseAuth}
                />
              }
            />
            <Route
              path="presentation-schedule"
              element={
                <MigratedRoute
                  element={PresentationSchedule}
                  onEnter={instructorInCourseAuth}
                  store={store}
                />
              }
            />
            <Route
              path="spotlights/create"
              element={
                <MigratedRoute
                  store={store}
                  element={Spotlight}
                  fetchData={fetchCourse.getCourseAndAssignmentsWithoutRefetch}
                  onEnter={instructorInCourseAuth}
                />
              }
            />
            <Route
              path="spotlights/:spotlightId"
              element={<MigratedRoute store={store} element={Spotlight} onEnter={requireAuth} />}
            />
            <Route
              path="spotlights/:spotlightId/edit"
              element={
                <MigratedRoute
                  store={store}
                  element={Spotlight}
                  fetchData={fetchCourse.getCourseAndAssignmentsWithoutRefetch}
                  onEnter={instructorInCourseAuth}
                />
              }
            />
            <Route
              path="rubric/:rubricId"
              element={
                <MigratedRoute
                  store={store}
                  element={Rubric}
                  fetchData={fetchAssignment.get}
                  onEnter={requireAuth}
                />
              }
            />
          </Route>
          <Route
            path="students"
            element={
              <MigratedRoute
                store={store}
                element={() => null}
                onEnter={
                  (({ replace, params }) =>
                    replace(`/course/${params.courseId}/roster`)) as RouteOnEnter
                }
              />
            }
          />
          <Route
            path="roster"
            element={
              <MigratedRoute
                store={store}
                element={RosterManager}
                fetchData={fetchCourse.get}
                onEnter={instructorInCourseAuth}
              />
            }
          >
            <Route path="invite-collaborators" element={<span>invite collabs</span>} />
          </Route>
          <Route
            path="lms-grade-sync"
            element={
              <MigratedRoute
                store={store}
                element={LmsGradeSync}
                fetchData={fetchCourse.getCourseAndAssignments}
                onEnter={instructorInCourseAuth}
              />
            }
          />

          <Route
            path="create-activity"
            element={
              <MigratedRoute
                store={store}
                element={CreateAssignment}
                fetchData={fetchCourse.getCourseAndAssignmentsWithoutRefetch}
                onEnter={instructorInCourseAuth}
              />
            }
          />

          <Route
            path="create-activity-ai"
            element={
              <MigratedRoute
                store={store}
                element={CreateActivityAIPage}
                fetchData={fetchCourse.getCourseAndAssignmentsWithoutRefetch}
                onEnter={instructorInCourseAuth}
              />
            }
          />

          <Route
            path="import"
            element={
              <MigratedRoute
                store={store}
                element={ActivityImport}
                fetchData={fetchCourse.get}
                onEnter={instructorInCourseAuth}
              />
            }
          />
        </Route>

        <Route
          path="rubric-manager"
          element={<MigratedRoute store={store} element={RubricManager} onEnter={instructorAuth} />}
        />
        <Route
          element={<MigratedRoute store={store} element={Setting} onEnter={requireAuth} />}
          path="settings/:tab"
        />
        <Route element={<CourseEnrollment />} path=":courseId/enroll/:enrollId" />
        <Route path="/no-account" element={<NoUserAccount />} />
        <Route path="/no-course" element={<NoCourse />} />
        <Route path="/lms-connection-pending" element={<LMSConnectionPending />} />
        <Route
          element={
            <GenericErrorPage
              subtitle={localize({ message: 'Error.404Subtitle' })}
              icon="🔍"
              title={localize({ message: 'Error.404Title' })}
              buttonText={localize({ message: 'Error.ButtonText' })}
              descriptions={[localize({ message: 'Error.404Text' })]}
              iconVariant="blue"
            />
          }
          path="404"
        />
        <Route
          element={
            <GenericErrorPage
              subtitle={localize({ message: 'Error.500Subtitle' })}
              icon="🚒"
              title={localize({ message: 'Error.500Title' })}
              buttonText={localize({ message: 'Error.ButtonText' })}
              descriptions={[localize({ message: 'Error.500Text' })]}
              iconVariant="blue"
            />
          }
          path="500"
        />
        <Route element={<ErrorPage />} path="error" />
        <Route
          element={
            <GenericErrorPage
              subtitle={localize({ message: 'Error.404Subtitle' })}
              icon="🔍"
              title={localize({ message: 'Error.404Title' })}
              buttonText={localize({ message: 'Error.ButtonText' })}
              descriptions={[localize({ message: 'Error.404Text' })]}
              iconVariant="blue"
            />
          }
          path="*"
        />
      </Route>
    ),
    {
      //https://reactrouter.com/en/main/routers/create-browser-router#future
      future: {
        v7_fetcherPersist: true,
        v7_normalizeFormMethod: true,
        v7_partialHydration: true,
        v7_relativeSplatPath: true,
        unstable_skipActionErrorRevalidation: true,
      },
    }
  );
};

/**
 * @deprecated This method exists for backwards compatibility with the react-router-redux library.
 * Use `useNavigate()` from `react-router-dom` instead.
 */
export function push(path: string) {
  window.router.navigate(path);
}

/**
 * @deprecated This method exists for backwards compatibility with the react-router-redux library.
 * Use `useNavigate()` from `react-router-dom` instead.
 */
export function replace(path: string) {
  window.router.navigate(path, { replace: true });
}
