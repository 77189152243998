import { connect } from 'react-redux';
import TutorialBanner from 'components/tutorial/Banner';
import Badge from 'images/icons/svg/BadgeBigBrainVariant';
import { toggleTutorials, TUTORIAL_KEYS } from 'redux/tutorial/actions';
import { selectTutorialSettingsFromUser } from 'redux/tutorial/selectors';
import localUtils from 'components/tutorial/Banner/utils';
import { useUserRoleInCourse } from 'hooks/course';
import { useAuthUserFromRedux } from 'hooks/user';
import { localize } from 'locales/index';

export const COPY = {
  key: TUTORIAL_KEYS.ACTIVITY,
  image: <Badge />,
  title: 'How do activities work?',
  studentDescription: localize({ message: 'Course.TutorialBanner.Student' }),
  instructorDescription: localize({ message: 'Course.TutorialBanner.Instructor' }),
  linkStudent: {
    target: 'https://help.kritik.io/en/articles/6387956-participating-in-a-kritik-activity',
    type: 'url',
  },
  linkInstructor: {
    target: 'https://help.kritik.io/en/collections/3546609-instructor-guide#activities',
    type: 'url',
  },
};

const Banner = (props: any) => {
  const { isInstructorInCourse } = useUserRoleInCourse();
  const authUser = useAuthUserFromRedux();

  const onClose = () => {
    props.toggleTutorials({
      id: authUser.id,
      tutorialLabels: {
        [COPY.key]: false,
      },
    });
  };

  if (!localUtils.shouldRenderTutorial(props.tutorialSettings, COPY.key)) {
    return null;
  }

  return (
    <TutorialBanner
      className={props.className}
      image={COPY.image}
      title={COPY.title}
      description={isInstructorInCourse ? COPY.instructorDescription : COPY.studentDescription}
      link={isInstructorInCourse ? COPY.linkInstructor : COPY.linkStudent}
      onClose={onClose}
    />
  );
};

const mapStateToProps = (state: any) => {
  return {
    tutorialSettings: selectTutorialSettingsFromUser(state),
  };
};

Banner.defaultProps = {
  className: '',
};

export default connect(mapStateToProps, {
  toggleTutorials,
})(Banner);
