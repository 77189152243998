import STAGE_STATUS_ICONS from 'images/status-icons';
import StageTitle from 'containers/PresentationSchedule/StageTitle';
import DateInput from 'components/schedule/DateInput';
import ArrowDown from 'containers/PresentationSchedule/ArrowDown';
import { localize } from 'locales';

type FeedbackProps = {
  disabled?: boolean;
  endDate: number;
  onEndDateChange: (date: Date) => void;
};

function Feedback(props: FeedbackProps) {
  return (
    <div className="feedback__container">
      <div className="feedback__icon-container">
        <img
          src={props.disabled ? STAGE_STATUS_ICONS.FEEDBACK_DISABLED : STAGE_STATUS_ICONS.FEEDBACK}
          aria-hidden="true"
        />
        <ArrowDown />
      </div>
      <div>
        <StageTitle title={localize({ message: 'Activity.Schedule.Feedback' })} />
        <p>{localize({ message: 'Activity.Schedule.Feedback.Note' })}</p>
        <DateInput
          label={localize({ message: 'Activity.Schedule.FeedbackDueDate' })}
          labelColor="dark"
          value={props.endDate}
          onDateChange={props.onEndDateChange}
          disabled={props.disabled}
        />
      </div>
    </div>
  );
}

export default Feedback;
