import Button from 'components/buttons/Button';
import PropagationInterceptor from 'components/Utility/PropagationInterceptor';

type Props = {
  isRowHovered: boolean;
  handleAction: () => void;
  actionText: string;
  text: string;
  id: string;
};

const ActionCell = ({ isRowHovered, handleAction, actionText, text, id }: Props) => {
  if (isRowHovered) {
    return (
      <PropagationInterceptor>
        <Button type="secondary" id={id} onClick={handleAction}>
          {actionText}
        </Button>
      </PropagationInterceptor>
    );
  }
  return <>{text}</>;
};

export default ActionCell;
