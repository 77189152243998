import classnames from 'classnames';

type Props = {
  helpText?: string;
  className?: string;
  id?: string;
};

function FieldHelpText(props: Props) {
  if (!props.helpText) {
    return null;
  }
  const classes = classnames('kritik-form-field__help-text', props.className);
  return (
    <span className={classes} id={props.id}>
      {props.helpText}
    </span>
  );
}

export default FieldHelpText;
