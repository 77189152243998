const PageContainer = (props: any) => {
  return (
    <div className="page-content-container" style={props.style}>
      <div className="page-content" id="kritik-main" tabIndex={-1}>
        {props.children}
      </div>
    </div>
  );
};

PageContainer.defaultProps = {
  style: {},
};

export default PageContainer;
