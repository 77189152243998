import { forwardRef } from 'react';
import { withForm } from 'components/core/form/utils/withForm';
import TextInput from 'components/core/input/Text';
import FormFieldLabel from '../FieldLabel';

const FormTextInput = forwardRef((props: any, ref: React.Ref<HTMLInputElement>) => {
  return (
    <>
      {props.label && (
        <FormFieldLabel
          label={props.label}
          isRequired={props.isRequired}
          id={props.id}
          className={props.className}
        />
      )}
      <TextInput
        placeholder={props.placeholder}
        autoFocus={props.autoFocus}
        value={props.value}
        name={props.name}
        aria-label={props['aria-label']}
        onChange={props.onChange}
        disabled={props.disabled}
        onBlur={props.onBlur}
        testid={props.testid}
        helpText={props.helpText}
        onEnterKey={props.onEnterKey}
        id={props.id}
        autoComplete={props.autoComplete}
        required={props.isRequired}
        ref={ref}
        inputProps={{
          ...props.inputProps,
          'aria-describedby': props.error ? props.id + '_error' : undefined,
        }}
      />
    </>
  );
});

FormTextInput.defaultProps = {
  autoFocus: false,
  placeholder: '',
};

export default withForm(FormTextInput);
