import React from 'react';
import InfoLabel from 'components/layout/InfoLabel';
import { CreationScoreUtil } from '@kritik/utils/grade';

const PassFailGrade = ({ creation }: any) => {
  const isPassed = CreationScoreUtil.isPassed(creation);
  if (isPassed) {
    return <InfoLabel type="success" label="PASSED" />;
  }

  return <InfoLabel type="danger" label="FAILED" />;
};

export default PassFailGrade;
