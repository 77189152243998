import { Component } from 'react';
import ScheduleContainer from 'containers/Schedule';
import Page from 'pages/Page';
import { localize } from 'locales/index';

class Schedule extends Component {
  getMetaData() {
    return {
      title: this.pageTitle(),
      meta: this.pageMeta(),
      link: this.pageLink(),
      script: this.pageScript(),
    };
  }

  pageTitle = () => {
    return localize({
      message: 'Activity.Schedule.PageTitle',
    });
  };

  pageMeta = () => {
    return [];
  };

  pageLink = () => {
    return [];
  };

  pageScript = () => {
    return [];
  };

  render() {
    return (
      <Page {...this.getMetaData()}>
        <ScheduleContainer {...this.props} />
      </Page>
    );
  }
}

export default Schedule;
