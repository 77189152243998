import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { setColumns, setInitialState, setTableListBy } from 'redux/creationTable/actions';
import {
  selectTableSettings,
  selectCreationTableFilter,
  selectCreationTableSearch,
  selectSortedColumn,
  selectIsCreationTableLoading,
} from 'redux/creationTable/select';
import Table from 'components/CreationList/Table';
import Row from 'components/CreationList/DisputeTable/Row';
import localConstants from 'components/CreationList/DisputeTable/constants';
import studentSelector from 'selectors/student';
import creationSelector from 'selectors/creation';
import ActivitySelectors from 'selectors/activity';
import GroupSelectors from 'selectors/group';
import submissionSelector from 'selectors/creation';
import * as activityUtils from '@kritik/utils/activity';
import tableUtil from 'components/CreationList/util';
import { TABLE_TYPE_OPTIONS, LIST_BY_TYPES } from 'components/CreationList/constant';
import Spinner from 'components/Loaders/Spinner';
import getPaginatedResults from 'actions/pagination/actions';
import { setCurrentPage } from 'redux/creationTable/actions';
import { navigateToActivityPage } from 'actions/activity';
import { usePrevious } from 'hooks/usePrevious';

function DisputeTable(props: any) {
  const prevProps = usePrevious({
    listBy: props.tableSettings.listBy,
  });

  useEffect(() => {
    const tableType = TABLE_TYPE_OPTIONS.DISPUTE;
    let columns = localConstants.STUDENT_COLUMNS.PEER;
    if (activityUtils.isGroupAssignment(props.activity)) {
      // @ts-expect-error TS(2322) FIXME: Type '({ header: string; align: string; isSortable... Remove this comment to see the full error message
      columns = tableUtil.getColumnsToRender(
        TABLE_TYPE_OPTIONS.DISPUTE,
        props.tableSettings.listBy
      );
    }
    props.setInitialState({ columns, type: tableType });
  }, []);

  useEffect(() => {
    if (activityUtils.isGroupAssignment(props.activity)) {
      if (prevProps && (prevProps as any).listBy !== props.tableSettings.listBy) {
        const columns = tableUtil.getColumnsToRender(
          TABLE_TYPE_OPTIONS.DISPUTE,
          props.tableSettings.listBy
        );
        props.setColumns(columns);
      }
    }
    if (!props.tableSettings.listBy) {
      if (activityUtils.isGroupAssignment(props.activity)) {
        props.setTableListBy(LIST_BY_TYPES.GROUP);
      } else {
        props.setTableListBy(LIST_BY_TYPES.STUDENT);
      }
    }
  }, [props.tableSettings.listBy]);

  useEffect(() => {
    props.setCurrentPage(1);
    props.navigateToActivityPage({ courseId: props.courseId, assignmentId: props.activityId });
    props.getPaginatedResults();
  }, []);

  if (props.isLoadingCreationTable) {
    return (
      <div className="scoring-creation-table__loader--active">
        <Spinner />
      </div>
    );
  }
  const filteredList = props.itemList.filter((item) => {
    return item.creation.dispute.status !== 'No dispute';
  });
  if (filteredList) {
    props.setItemCount(filteredList.length);
  }
  return (
    <Table
      id={`dispute-creation-table-${props.activity._id}`}
      style={{ minWidth: '768px' }}
      rowGenerator={Row}
      rowList={filteredList}
    />
  );
}

const mapStateToProps = (state: any) => {
  const itemList = tableUtil.generateRowListData()(
    {
      CreationSelectors: creationSelector,
      SubmissionSelectors: submissionSelector,
      GroupSelectors,
      StudentSelectors: studentSelector,
      ActivitySelectors,
    },
    state
  );
  return {
    itemList,
    filter: selectCreationTableFilter(state),
    search: selectCreationTableSearch(state),
    sortedColumn: selectSortedColumn(state),
    activity: ActivitySelectors.getAssignment(state, state.selected.assignmentId),
    tableSettings: selectTableSettings(state),
    isLoadingCreationTable: selectIsCreationTableLoading(state),
    courseId: state.selected.courseId,
    activityId: state.selected.assignmentId,
  };
};

export default connect(mapStateToProps, {
  setInitialState,
  setColumns,
  setTableListBy,
  getPaginatedResults,
  setCurrentPage,
  navigateToActivityPage,
})(DisputeTable);
