export const OPTIONS = [
  {
    title: 'Toronto, Ontario',
    lat: '43.70011',
    lng: '-79.4163',
  },
  {
    title: 'Edmonton, Alberta',
    lat: '53.55014',
    lng: '-113.46871',
  },
  {
    title: 'Winnipeg, Manitoba',
    lat: '49.8844',
    lng: '-97.14704',
  },
  {
    title: 'Montreal, Quebec',
    lat: '45.50884',
    lng: '-73.58781',
  },
  {
    title: 'Charlottetown, Prince Edward Island',
    lat: '46.23459',
    lng: '-63.1256',
  },
  {
    title: 'Fredericton, New Brunswick',
    lat: '45.94541',
    lng: '-66.66558',
  },
  {
    title: 'Victoria, British Columbia',
    lat: '48.4359',
    lng: '-123.35155',
  },
  {
    title: 'Regina, Saskatchewan',
    lat: '50.45008',
    lng: '-104.6178',
  },
  {
    title: 'Halifax, Nova Scotia',
    lat: '44.6464',
    lng: '-63.57291',
  },
  {
    title: "St. John's, Newfoundland and Labrador",
    lat: '47.56494',
    lng: '-52.70931',
  },
  {
    title: 'Iqaluit, Nunavut',
    lat: '63.74697',
    lng: '-68.51727',
  },
  {
    title: 'Whitehorse, Yukon',
    lat: '60.71611',
    lng: '-135.05375',
  },
  {
    title: 'Yellowknife, Northwest Territories',
    lat: '62.45411',
    lng: '-114.37248',
  },
];
