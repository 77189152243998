import errorAction from 'actions/error';
import ActivityService from 'services/activity';
import types from './types';

export function getActivityScores(activityId: any) {
  return async (dispatch: any) => {
    dispatch({ type: types.GET_ACTIVITY_SCORES });
    try {
      const res = await ActivityService().getActivityScores(activityId);
      dispatch({
        type: types.GET_ACTIVITY_SCORES_SUCCESS,
        payload: { data: res.data, activityId },
      });
      return { success: res.data };
    } catch (err) {
      dispatch(errorAction(types.GET_ACTIVITY_SCORES_FAILURE, err));
      return { error: err };
    }
  };
}

export function getAvgGroupScoresByCreationId({ activityId, creationId }: any) {
  return async (dispatch: any) => {
    dispatch({ type: types.GET_AVG_GROUP_SCORES });
    try {
      const res = await ActivityService().getAvgGroupScoresByCreationId({ activityId, creationId });
      dispatch({ type: types.GET_AVG_GROUP_SCORES_SUCCESS, payload: res.data });
      return res.data;
    } catch (err) {
      dispatch(errorAction(types.GET_AVG_GROUP_SCORES_FAILURE, err));
      return { error: err };
    }
  };
}

export default { getActivityScores };
