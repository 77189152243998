import React, { useContext, useState } from 'react';
import momentTZ from 'moment-timezone';
import { connect } from 'react-redux';
import FlagIcon from '@material-ui/icons/Flag';
import VerticalList from 'components/layout/VerticalList';
import CreationDisplay from 'components/Creation/Create/CreationDisplay';
import { wasCreationMissed } from '@kritik/utils/creation/status';
import { creationHasTeacherComment, isUserCreationOwner } from '@kritik/utils/creation/general';
import { isGroupMember } from '@kritik/utils/group';
import { getAssignment } from 'selectors/activity';
import { getStudentFromUser } from 'selectors/student';
import { getCreationTopic } from 'utils/creation/topic';
import { SubtitleDisplay } from 'components/AssignmentLabels/InfoLabels';
import ViewPortVisibilityNotifier from 'components/Creation/Create/RefWrapper';
import { CreationInstructorOptions } from 'components/Creation/Create/Options';
import InstructorCreationComment from 'components/CommentOnContent/CreationComment';
import { userSubmissionStatuses } from '@kritik/constants/creation';
import { useUserRoleInCourse } from 'hooks/course';
import * as ActivityUtils from '@kritik/utils/activity';
import { Activity } from 'old-common/types.generated';
import { TranslatedText } from 'components/TranslatedText';
import { Button } from 'components/buttons';
import { FlagCreationModal } from '../FlagCreationModal';
import { Creation, User, Student } from 'old-common/types.generated';
import NoticeBoard from 'components/layout/NoticeBoard';
import { useIsFeatureFlagEnabled } from 'context/growthbookContext';

const CreationViewContext = React.createContext(null);

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    assignment: getAssignment(state, state.selected.assignmentId),
    student: getStudentFromUser(state, state.selected.courseId),
  };
};

function InstructorComment() {
  const { isInstructorInCourse } = useUserRoleInCourse();
  const { user, submission, student, disableViewInstructorComment } =
    useContext(CreationViewContext);
  if (disableViewInstructorComment) {
    return null;
  }
  const isPartOfCreation = submission.group && isGroupMember(submission.group, student);
  if (!creationHasTeacherComment(submission)) {
    return null;
  }
  if (isInstructorInCourse || isUserCreationOwner(submission, user) || isPartOfCreation) {
    return (
      <ViewPortVisibilityNotifier creation={submission} student={student}>
        <InstructorCreationComment
          creation={submission}
          activityId={submission.assignment}
          creationUserId={submission.user._id}
          isInstructor={isInstructorInCourse}
        />
      </ViewPortVisibilityNotifier>
    );
  }

  return null;
}

type CreationContentProps = {
  creation: Creation;
  isPresentationActivity: boolean;
};
function CreationContent(props: CreationContentProps) {
  const { isStudentInCourse } = useUserRoleInCourse();

  if (wasCreationMissed(props.creation)) {
    return null;
  }

  return isStudentInCourse && props.isPresentationActivity && props.creation.files.length === 0 ? (
    <TranslatedText i18nKey="Activity.Presentation.StudentView.AutoSubmission" />
  ) : (
    <CreationDisplay creation={props.creation} />
  );
}

type SubmissionDisplayProps = {
  activity: Activity;
};
function SubmissionDisplay(props: SubmissionDisplayProps) {
  const { submission: creation } = useContext(CreationViewContext);
  if (!creation) {
    return null;
  }

  return (
    <div className="submission-view-container" data-testid="grading-stage-creation-container">
      <VerticalList
        space="xs"
        itemList={[
          <CreationContent
            creation={creation}
            isPresentationActivity={ActivityUtils.isPresentationActivity(props.activity)}
          />,
          <InstructorComment />,
        ]}
      />
    </div>
  );
}

function Topic() {
  const { submission } = useContext(CreationViewContext);
  const topic = getCreationTopic(submission);
  if (!topic || !topic.name) {
    return null;
  }
  return <SubtitleDisplay text={`Topic ${topic.name}`} testid="subtitle-display" />;
}

function Title() {
  const { title } = useContext(CreationViewContext);
  return title ? (
    <div className="submission-view-header" data-testid="submission-view-header">
      <h3>{title}</h3>
    </div>
  ) : null;
}

type CreationFlaggingProps = {
  creation: Creation;
};
function CreationFlagging({ creation }: CreationFlaggingProps) {
  const [flagCreationModalOpen, setFlagCreationModalOpen] = useState(false);

  return (
    <>
      {creation.flagByTheCurrentUser ? (
        <NoticeBoard
          type={creation.flagByTheCurrentUser.resolvedAt ? 'information' : 'caution'}
          title={<TranslatedText i18nKey="Creation.Flag.ExistingFlag.Title" />}
        >
          <p>
            <TranslatedText
              i18nKey="Creation.Flag.ExistingFlag.Date"
              values={{
                date: momentTZ(creation.flagByTheCurrentUser.createdAt).format(
                  'MMM DD yyyy @ hh:mm'
                ),
              }}
            />
          </p>
          <p>
            <TranslatedText
              i18nKey="Creation.Flag.ExistingFlag.Explanation"
              values={{
                explanation: creation.flagByTheCurrentUser.explanation,
              }}
            />
          </p>
          {creation.flagByTheCurrentUser.resolvedAt && (
            <>
              <br />
              <p>
                <TranslatedText
                  i18nKey="Creation.Flag.ExistingFlag.ResolvedBy"
                  values={{
                    user: creation.flagByTheCurrentUser.resolvedByUserName,
                    date: momentTZ(creation.flagByTheCurrentUser.resolvedAt).format(
                      'MMM DD yyyy @ hh:mm'
                    ),
                  }}
                />
              </p>
            </>
          )}
        </NoticeBoard>
      ) : (
        <Button
          type="secondary"
          data-testid="flag-creation"
          className="flag-creation-button"
          onClick={() => setFlagCreationModalOpen(true)}
        >
          <FlagIcon fontSize="small" /> <TranslatedText i18nKey="Creation.Flag.FlagCreation" />
        </Button>
      )}
      <FlagCreationModal
        open={flagCreationModalOpen}
        onClose={() => setFlagCreationModalOpen(false)}
        creationId={creation._id}
      />
    </>
  );
}

type DisplaySubmissionProps = {
  submission?: Creation;
  title?: string;
  user?: User;
  student?: Student;
  disableViewInstructorComment?: boolean;
  children?: React.ReactNode;
  assignment?: Activity;
  canFlagCreation?: boolean;
};
const displaySubmission = ({
  submission,
  title = '',
  user,
  student,
  disableViewInstructorComment,
  children,
  assignment,
  canFlagCreation,
}: DisplaySubmissionProps) => {
  const { isStudentInCourse, isInstructorInCourse } = useUserRoleInCourse();
  const flagCreationFeatureFlagEnabled = useIsFeatureFlagEnabled('flag_creation');
  const contextValue = {
    submission,
    user,
    title,
    student,
    disableViewInstructorComment,
  };

  if (
    (submission.submissionStatus === userSubmissionStatuses.MISSED ||
      submission.submissionStatus === userSubmissionStatuses.EMPTY) &&
    student
  ) {
    return null;
  }

  return (
    <CreationViewContext.Provider value={contextValue}>
      {children}
      <Title />
      <Topic />
      <SubmissionDisplay activity={assignment} />
      {isStudentInCourse && canFlagCreation && flagCreationFeatureFlagEnabled && (
        <CreationFlagging creation={submission} />
      )}
      {isInstructorInCourse && <CreationInstructorOptions creation={submission} />}
    </CreationViewContext.Provider>
  );
};

const CreationView = connect(mapStateToProps, {})(displaySubmission);

export { CreationView };
