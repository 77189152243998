import React from 'react';
import { InlineInformation } from 'components/layout';
import { TranslatedText } from 'components/TranslatedText';
import { activeStages, translateStageName } from '@kritik/utils/stage';
import { isGroupAssignment, isPresentationActivity } from '@kritik/utils/activity';
import { Activity } from '@kritik/types.generated';

type StageRevertingNoticeProps = {
  canRevert: boolean;
  activeStage: (typeof activeStages)[keyof typeof activeStages];
  validRevertStage: string;
  activity: Activity;
};

export const StageRevertingNotice: React.FC<StageRevertingNoticeProps> = ({
  activity,
  canRevert,
  activeStage,
  validRevertStage,
}) => {
  if (!canRevert) {
    return (
      <p>
        <InlineInformation type="information">
          <TranslatedText i18nKey="Activity.Revert.Cannot.Revert.Beta" />
        </InlineInformation>
      </p>
    );
  }

  switch (activeStage) {
    case activeStages.FINALIZED:
      return (
        <p>
          <TranslatedText i18nKey="Activity.Revert.From.Finalized" />
        </p>
      );
    case activeStages.GRADING:
      return (
        <p>
          <TranslatedText i18nKey="Activity.Revert.From.Grading" />
        </p>
      );
    case activeStages.FEEDBACK:
      return (
        <>
          <p>
            <TranslatedText
              i18nKey="Activity.Revert.From.Feedback"
              values={{
                activeStage,
                previousStage: translateStageName({ stageName: validRevertStage, activity }),
              }}
            />
          </p>
          <InlineInformation type="information">
            <TranslatedText
              i18nKey={
                isPresentationActivity(activity)
                  ? 'Activity.Revert.From.Feedback.Information.PresentationActivities'
                  : isGroupAssignment(activity)
                    ? 'Activity.Revert.From.Feedback.Group'
                    : 'Activity.Revert.From.Feedback.Information'
              }
            />
          </InlineInformation>
        </>
      );
    case activeStages.EVALUATE_GRACE_PERIOD:
      return (
        <p>
          <TranslatedText
            i18nKey="Activity.Revert.From.EvaluateGracePeriod"
            values={{
              activeStage: translateStageName({ stageName: activeStage, activity }),
              previousStage: translateStageName({ stageName: validRevertStage, activity }),
            }}
          />
        </p>
      );
    case activeStages.EVALUATE:
      if (validRevertStage === 'Draft') {
        return (
          <p>
            <TranslatedText i18nKey="Activity.Revert.From.Evaluate.To.Draft" />
          </p>
        );
      }
      return (
        <>
          <p>
            <TranslatedText i18nKey="Activity.Revert.From.Evaluate.To.Create" />
          </p>
          <InlineInformation type="warning">
            <TranslatedText i18nKey="Activity.Revert.From.Evaluate.Information" />
          </InlineInformation>
        </>
      );
    default:
      return null;
  }
};
