import { Button, ButtonContainer } from 'components/buttons';
import { PageHeader } from 'components/layout';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useParams,
  useRevalidator,
} from 'react-router';
import { NewTab } from 'images/icons/fa';
import moment from 'moment';
import React, { forwardRef, useRef, MutableRefObject } from 'react';
import { getWordCount } from '@kritik/utils/general';
import { TranslatedText } from 'components/TranslatedText';
import { creationService } from 'services';
import { formatPartialCourseBreadcrumb } from 'utils/format';
import { useUnresolveCreationSimilarityReport } from 'hooks/plagiarism';
import NoticeBoard from 'components/layout/NoticeBoard';
import { Outlet } from 'react-router';

const isElementInViewPort = (element: HTMLDivElement) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

async function loader(args: LoaderFunctionArgs) {
  const { creationId } = args.params;

  const creationResponse = await creationService().getFlaggedCreationWithMatchedSentences({
    creationId,
  });
  return creationResponse.data;
}

type PopulateArrayRefArgs = {
  arrayRef: MutableRefObject<any[]>;
  length: number;
};

function populateArrayRef({ arrayRef, length }: PopulateArrayRefArgs) {
  if (arrayRef.current.length !== length) {
    arrayRef.current = Array(length)
      .fill(null)
      .map((_, i) => arrayRef.current[i] || React.createRef<React.RefObject<HTMLDivElement>>());
  }
}

export function CreationSimilarityReport({}) {
  const reportRef: React.RefObject<HTMLDivElement> = useRef();
  const matchedSentencesDetailsRefs = useRef([]);
  const highlightedSentencesRefs = useRef([]);
  const revalidator = useRevalidator();

  const { flaggedCreation, matchedSentences, plagiarismResolutionStatus } =
    useLoaderData() as Awaited<ReturnType<typeof loader>>;

  const unresolveCreationSimilarityReport = useUnresolveCreationSimilarityReport();

  const params = useParams();
  const navigate = useNavigate();

  populateArrayRef({ arrayRef: matchedSentencesDetailsRefs, length: matchedSentences.length });
  populateArrayRef({ arrayRef: highlightedSentencesRefs, length: matchedSentences.length });

  const handleFocusMatchedSentenceDetails = ({ index }) => {
    highlightedSentencesRefs.current.find((ref, i) => {
      if (ref.current && index === i) {
        ref.current.classList.add('similarity-report__highlighted-text-focused');
      } else {
        ref.current.classList.remove('similarity-report__highlighted-text-focused');
      }
    });

    matchedSentencesDetailsRefs.current.find((ref, i) => {
      if (ref.current && index === i) {
        if (!isElementInViewPort(ref.current)) {
          ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        ref.current.classList.add('similarity-report__match-details-container-focused');
      } else {
        ref.current.classList.remove('similarity-report__match-details-container-focused');
      }
    });
  };

  const handleHighlightedSentence = ({ index }) => {
    matchedSentencesDetailsRefs.current.find((ref, i) => {
      if (ref.current && index === i) {
        ref.current.classList.add('similarity-report__match-details-container-focused');
      } else {
        ref.current.classList.remove('similarity-report__match-details-container-focused');
      }
    });
    highlightedSentencesRefs.current.find((ref, i) => {
      if (ref.current && index === i) {
        if (!isElementInViewPort(ref.current)) {
          ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        ref.current.classList.add('similarity-report__highlighted-text-focused');
      } else {
        ref.current.classList.remove('similarity-report__highlighted-text-focused');
      }
    });
  };

  return (
    <div className="page-content-container">
      <Outlet />
      <div className="page-content">
        <PageHeader title="Similarity Report">
          <ButtonContainer>
            <Button
              type="secondary"
              onClick={() => {
                if (Boolean(plagiarismResolutionStatus)) {
                  unresolveCreationSimilarityReport.mutate(flaggedCreation.id, {
                    onSuccess: () => revalidator.revalidate(),
                  });
                } else {
                  navigate('resolve');
                }
              }}
            >
              <TranslatedText
                i18nKey={
                  Boolean(plagiarismResolutionStatus)
                    ? 'CreationSimilarityReport.Actions.Unresolve'
                    : 'CreationSimilarityReport.Actions.Resolve'
                }
              />
            </Button>
            <Button
              type="secondary"
              onClick={() =>
                navigate(
                  `/course/${params.courseId}/action-items?activityId=${params.assignmentId}`
                )
              }
            >
              <TranslatedText i18nKey="CreationSimilarityReport.Actions.BackToActionItems" />
            </Button>
          </ButtonContainer>
        </PageHeader>
        <div className="similarity-report">
          <div className="similarity-report__creation" ref={reportRef}>
            <div>
              <div className="similarity-report__creation-header">
                <p className="similarity-report__creation-title">
                  <TranslatedText
                    i18nKey="CreationSimilarityReport.Content.StudentCreation"
                    values={{ studentName: flaggedCreation.studentName }}
                  />
                  <NewTab
                    className="similarity-report__match-link"
                    onClick={(e) => {
                      e.stopPropagation();
                      const newWindow = window.open(
                        `/course/${flaggedCreation.courseId}/assignment/${flaggedCreation.activityId}/creation/${flaggedCreation.id}?groupView=${flaggedCreation.isGroupActivity}`,
                        '_blank',
                        'noopener,noreferrer'
                      );
                    }}
                  />
                </p>
                <span>
                  {getWordCount(flaggedCreation.plainTextContent)}{' '}
                  <TranslatedText i18nKey="CreationSimilarityReport.Content.WordCount" />
                </span>
              </div>
              <div className="similarity-report__creation-content">
                <HighlightedText
                  text={flaggedCreation.plainTextContent}
                  highlights={matchedSentences.map(({ flaggedSentence }) => flaggedSentence)}
                  handleFocusMatchedSentenceDetails={handleFocusMatchedSentenceDetails}
                  highlightedSentencesRefs={highlightedSentencesRefs}
                />
              </div>
            </div>
          </div>
          <div className="similarity-report__output">
            {Boolean(plagiarismResolutionStatus) && (
              <NoticeBoard title="Potential Plagiarism Resolved">
                <TranslatedText
                  i18nKey="CreationSimilarityReport.Resolution.ResolvedBy"
                  values={{
                    name: plagiarismResolutionStatus.resolvedBy,
                    date: moment(plagiarismResolutionStatus.resolvedOn).format(
                      'MMM DD yyyy @ hh:mm'
                    ),
                  }}
                />

                {plagiarismResolutionStatus.note && (
                  <div style={{ marginTop: '5px' }}>
                    <strong>Internal notes</strong>
                    <div>{plagiarismResolutionStatus?.note}</div>
                  </div>
                )}
              </NoticeBoard>
            )}
            <div className="similarity-report__output-title">
              <TranslatedText i18nKey="CreationSimilarityReport.Content.ContainsSimilarities" />
            </div>
            <div>
              {matchedSentences.map((matchedSentence, index) => {
                return (
                  <MatchedSentenceOverview
                    originalCreation={matchedSentence.originalCreation}
                    key={`${matchedSentence.originalCreation.id}-${index}`}
                  />
                );
              })}
            </div>
            <hr className="similarity-report__separator" />
            <div className="similarity-report__details">
              <div className="similarity-report__details-navigation">
                {matchedSentences.length}{' '}
                <TranslatedText i18nKey="CreationSimilarityReport.Content.Matches" />
              </div>
              {matchedSentences.map((matchedSentence, index) => {
                return (
                  <MatchedSentenceDetails
                    matchedSentence={matchedSentence}
                    key={`${matchedSentence.originalCreation.id}-${index}`}
                    ref={matchedSentencesDetailsRefs.current[index]}
                    handleHighlightedSentence={() => handleHighlightedSentence({ index })}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const HighlightedText = ({
  text,
  highlights,
  handleFocusMatchedSentenceDetails,
  highlightedSentencesRefs,
}) => {
  try {
    const getHighlightedText = ({ text, highlights }) => {
      const regex = new RegExp(`(${highlights.join('|')})`, 'gi');
      const parts = text.split(regex);
      return parts.map((part, index) => {
        const highlightIndex = highlights.findIndex((highlight) => highlight.includes(part));
        if (highlightIndex > -1) {
          return (
            <span
              key={index}
              className="similarity-report__highlighted-text"
              onClick={() => handleFocusMatchedSentenceDetails({ index: highlightIndex })}
              ref={highlightedSentencesRefs.current[highlightIndex]}
            >
              {part}
            </span>
          );
        } else {
          return <span key={index}>{part}</span>;
        }
      });
    };

    return <div>{getHighlightedText({ text, highlights })}</div>;
  } catch (error) {
    return <div>{text}</div>;
  }
};

const MatchedSentenceOverview = ({ originalCreation }) => {
  return (
    <div className="similarity-report__match-container">
      <p>{originalCreation.studentName}</p>
      <p className="similarity-report__match-activity">{originalCreation.activityTitle}</p>
      <p className="similarity-report__match-course">
        {formatPartialCourseBreadcrumb(originalCreation.course)}
      </p>
      <NewTab
        className="similarity-report__match-link"
        onClick={(e) => {
          const newWindow = window.open(
            `/course/${originalCreation.course.id}/assignment/${originalCreation.activityId}/creation/${originalCreation.id}?groupView=${originalCreation.isGroupActivity}`,
            '_blank',
            'noopener,noreferrer'
          );
        }}
      />
    </div>
  );
};

const MatchedSentenceDetails = forwardRef(
  ({ matchedSentence, handleHighlightedSentence }: any, ref: React.RefObject<HTMLDivElement>) => {
    return (
      <div
        className="similarity-report__match-details-container"
        ref={ref}
        onClick={handleHighlightedSentence}
      >
        <div className="similarity-report__match-details-header">
          <p>{matchedSentence.originalCreation.studentName}</p>
          <p className="similarity-report__match-details-activity">
            {matchedSentence.originalCreation.activityTitle}
          </p>
          <p className="similarity-report__match-details-date">
            {moment(matchedSentence.originalCreation.createdAt).format('MMM DD yyyy @ hh:mm')}
          </p>
        </div>
        <div className="similarity-report__match-original-sentence">
          {matchedSentence.originalSentence}
        </div>
        <NewTab
          className="similarity-report__match-link"
          onClick={(e) => {
            e.stopPropagation();
            const newWindow = window.open(
              `/course/${matchedSentence.originalCreation.course.id}/assignment/${matchedSentence.originalCreation.activityId}/creation/${matchedSentence.originalCreation.id}?groupView=${matchedSentence.originalCreation.isGroupActivity}`,
              '_blank',
              'noopener,noreferrer'
            );
          }}
        />
      </div>
    );
  }
);

CreationSimilarityReport.loader = loader;
