import React from 'react';
import classNames from 'classnames';
import { ChevronDown, ChevronUp, ChevronLeft, ChevronRight } from 'images/icons/fa';

const TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  WARNING: 'warning',
  HIDDEN: 'hidden',
};

type ButtonProps = {
  className?: string;
  type?: '' | 'primary' | 'secondary' | 'warning' | 'hidden';
  loading?: boolean;
  error?: boolean;
  info?: boolean;
  success?: boolean;
  unavailable?: boolean;
  children?: React.ReactNode;
  inputType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  label?: string;
  isActive?: boolean;
  testid?: string;
  chevron?: string;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'>;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      type,
      loading,
      error,
      info,
      success,
      unavailable,
      children,
      inputType,
      label,
      isActive,
      testid,
      chevron,
      ...props
    },
    ref
  ) => {
    const btnClasses = classNames(
      'btn',
      {
        'btn-primary': type === TYPES.PRIMARY,
        'btn-secondary': type === TYPES.SECONDARY,
        'btn-warning': type === TYPES.WARNING,
        'btn--hidden': type === TYPES.HIDDEN,
        'kritik-button--active': isActive,
        status: loading || success,
        loading,
        error,
        success,
        info,
        unavailable,
      },
      className
    );

    const iconClasses = classNames('button-select__icon', {
      'button-select--primary': type === TYPES.PRIMARY,
      'button-select--secondary': type === TYPES.SECONDARY,
    });

    const chevronIcon = (chevronType: string) => {
      switch (chevronType) {
        case 'right':
          return <ChevronRight className={iconClasses} />;
        case 'up':
          return <ChevronUp className={iconClasses} />;
        case 'left':
          return <ChevronLeft className={iconClasses} />;
        case 'down':
        default:
          return <ChevronDown className={iconClasses} />;
      }
    };

    return (
      <button
        ref={ref}
        className={btnClasses}
        aria-label={label}
        type={inputType}
        data-testid={testid}
        {...props}
      >
        {isActive && <i className="check icon kritik-button__icon--active" />}
        {info && <i className="fa fa-info-circle InfoIcon" />}
        {children}
        {loading && <div className="ButtonLoader" />}
        {success && <i className="check icon SuccessIcon" />}
        {chevron && chevronIcon(chevron)}
      </button>
    );
  }
);

Button.defaultProps = {
  inputType: 'button',
};

export default Button;
