import LEVEL_0 from './0.png';
import LEVEL_1 from './1.png';
import LEVEL_2 from './2.png';
import LEVEL_3 from './3.png';
import LEVEL_4 from './4.png';
import LEVEL_5 from './5.png';
import LEVEL_6 from './6.png';

export default {
  LEVEL_0,
  LEVEL_1,
  LEVEL_2,
  LEVEL_3,
  LEVEL_4,
  LEVEL_5,
  LEVEL_6,
};
