const shouldRenderTutorial = (tutorialSettings: any, tutorialKey: any) => {
  if (!tutorialSettings.hasOwnProperty(tutorialKey)) {
    return false;
  }
  return tutorialSettings[tutorialKey];
};

export default {
  shouldRenderTutorial,
};
