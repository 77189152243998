import { useState, useEffect } from 'react';
import { verifyInstitution, getInstitutions } from 'actions/admin';
import { connect } from 'react-redux';
import FormTextField from 'components/core/form/TextField';
import { FormSelection } from 'components/Form/FormComponents';
import CloseButton from '../CloseButton';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/core/form/SubmitButtons';
import { adminService } from 'services';
import { InlineInformation } from 'components/layout';

const ChangeInstitutionModal = (props: any) => {
  const [filterString, setFilterString] = useState();
  const [selectedInstitution, setSelectedInstitution] = useState();
  const [userEmail, setUserEmail] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();

  let options: any = [];

  useEffect(() => {
    props.getInstitutions();
  }, []);

  if (!props.institutions) {
    return null;
  }

  for (const [key, value] of Object.entries(props.institutions)) {
    options.push({ label: (value as any).name, value: (value as any)._id });
  }

  const getFilteredOptions = () => {
    if (!filterString) {
      return options.slice(0, 100);
    }
    return options.filter((option) => {
      return option.label.toLowerCase().includes((filterString as any).toLowerCase());
    });
  };

  const handleTypedInputChange = (e: any) => {
    setFilterString(e);
  };

  const handleInstitutionSelectionChange = (e: any) => {
    setSelectedInstitution(e);
  };

  const handleUserEmailChange = (e: any) => {
    setUserEmail(e.target.value);
  };

  const handleSubmit = () => {
    setSuccessMessage(null);
    setErrorMessage(null);

    if (!userEmail) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setErrorMessage('User email is missing');
      return;
    }

    if (!selectedInstitution) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setErrorMessage('Please select an institution');
      return;
    }

    adminService()
      .changeUserInstitutions({
        email: userEmail,
        institutionId: (selectedInstitution as any).value,
      })
      .then(() => {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        setSuccessMessage('Success!');
      })
      .catch((err: any) => {
        setErrorMessage(err?.response?.data?.error?.message);
      });
  };

  return (
    <div className="admin-panel-modal">
      <section className="admin-panel-modal__main admin-panel-modal__scrollable">
        <h1>Change a Institution</h1>
        <FormTextField label="Enter User Email" onChange={handleUserEmailChange}></FormTextField>
        <FormSelection
          label="Select an Institution"
          options={getFilteredOptions()}
          onInputChange={handleTypedInputChange}
          onChange={handleInstitutionSelectionChange}
        ></FormSelection>
        <ButtonContainer>
          <Button type="primary" onClick={handleSubmit}>
            Submit
          </Button>
          <Button type="secondary" onClick={props.handleClose}>
            Close
          </Button>
        </ButtonContainer>
        {errorMessage && <InlineInformation type="danger">{errorMessage}</InlineInformation>}
        {successMessage && <InlineInformation type="success">{successMessage}</InlineInformation>}
      </section>
      <CloseButton onClose={props.handleClose} />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    institutionExists: state.admin.institutionExists,
    institutions: state.entities.institutions,
  };
};

export default connect(mapStateToProps, { verifyInstitution, getInstitutions })(
  ChangeInstitutionModal
);
