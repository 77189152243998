import React from 'react';
import { InlineInformation } from 'components/layout';

export const InviteCallout = () => {
  return (
    <InlineInformation type="attention" title="Kritik enrollment is by invite only">
      Students can only join your course by clicking on the link they receive via email invite
    </InlineInformation>
  );
};

export default {
  InviteCallout,
};
