import React from 'react';
import StatusLabel from 'components/Creation/StatusLabel';
import * as CreationStatusUtil from '@kritik/utils/creation/status';
import * as ActivityStatusUtil from '@kritik/utils/stage';

export const MissedLabel = (props: any) => {
  if (ActivityStatusUtil.isEvaluateOrLater(props.activity)) {
    if (CreationStatusUtil.wasCreationMissed(props.creation)) {
      return <StatusLabel status="danger" label="Missed" />;
    }
    if (CreationStatusUtil.isCreationEmpty(props.creation)) {
      return <StatusLabel status="danger" label="Overdue" />;
    }
  }
  return null;
};

export default MissedLabel;
