import * as _ from 'lodash-es';
import moment from 'moment';
import { isGroupAssignment } from '@kritik/utils/activity';
import { getEvaluationStage, isEvaluateOrLater } from '@kritik/utils/stage';
import type { Activity } from '@kritik/types.generated';

export const getLastGroupActivityTransitionToEval = (activities: any) => {
  const filterdActivities = activities.filter((activity: any) => {
    return isGroupAssignment(activity) && isEvaluateOrLater(activity);
  });
  const lastActivity = _.maxBy(filterdActivities, (activity: Activity) => {
    return new Date(getEvaluationStage(activity).startDate);
  });
  return lastActivity;
};
/**
 * check if groups in group set are modified by prof since
 * last activity transition to eval stage
 */
export const isGroupSetEdited = (groupSet: any, activities: any) => {
  if (!groupSet) {
    return false;
  }
  const lastActivity = getLastGroupActivityTransitionToEval(activities) as Activity;
  if (!lastActivity || !groupSet.groupsLastEditedAt) {
    return false;
  }
  return moment(groupSet.groupsLastEditedAt).isAfter(
    moment(getEvaluationStage(lastActivity).startDate)
  );
};
