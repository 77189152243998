import { normalize } from 'normalizr';
import { push } from 'router';
import { reset, SubmissionError } from 'redux-form';
import { mergeEntities, updateEntities, replaceEntities } from 'actions/entities';
import { courseSchema, groupSchema, studentSchema } from 'schemas';
import { courseService } from 'services';
import * as types from 'types';
import * as modalTypes from 'types/modal';
import * as ErrorUtils from 'utils/error';

import { validateSheetName } from '@kritik/utils/format';

import { getAssignment, getPastAssignments } from 'selectors/activity';
import courseSelectors from 'selectors/course';
import { selectGradeHistories } from 'selectors/grade';
import { trackEvent } from 'utils/userEvents';
import { EvaluationScoreUtil, OverallScoreUtil, GradeHistoryUtil } from '@kritik/utils/grade';
import { openErrorModal, closeInfoModal } from './modals';
import { selectCourse } from './select';

import * as XLSX from 'xlsx';
import { isPresentationActivity } from '@kritik/utils/activity';

export const navigateToCourseActivitiesPage = ({ courseId }: any) => {
  return push(`/course/${courseId}/assignments`);
};

export const navigateToCoursePage = ({ courseId }: any) => {
  return push(`/course/${courseId}`);
};

export const navigateToCourseDetailsEditPage = (courseId: any, queryParameters = '') => {
  return push(`/course/${courseId}/details/edit${queryParameters}`);
};

export const navigateToCourseStudentsPage = (courseId: any) => {
  return push(`/course/${courseId}/roster`);
};

export function createCourse(data: courses.POST.Request) {
  return async (dispatch: any, getState: any) => {
    dispatch({ type: 'CREATE_COURSE_REQUEST' });
    try {
      const res = await courseService().create(data);
      if (res.status === 200) {
        dispatch(reset('course'));
        const normalized = normalize(res.data, courseSchema);
        dispatch(mergeEntities(normalized.entities));
        dispatch({ type: 'CREATE_COURSE_SUCCESS', payload: res.data });
        const course = res.data;
        trackEvent('Course Created', getState().user.authUser, {
          courseName: course.title,
          courseId: course._id,
          instructorEmail: getState().user.authUser.email,
          createdAt: course.createdAt.toString(),
        });
        return res.data;
      }
    } catch (err) {
      dispatch({ type: 'CREATE_COURSE_FAILURE', payload: err });
      throw new SubmissionError(
        (err &&
          (err as any).response &&
          (err as any).response.data &&
          (err as any).response.data.errors) ||
          {}
      );
    }
  };
}

export function updateCourseInReduxStore(dispatch: any, data: any) {
  const normalized = normalize(data, courseSchema);
  dispatch(mergeEntities(normalized.entities));
  dispatch({ type: 'GET_COURSE_SUCCESS', payload: normalized.result });
}
export function getCourse(params = {}, { client, callback, reject }: any = {}) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'GET_COURSE_REQUEST', payload: (params as any).id });
    return courseService({ client })
      .get(params)
      .then((res: any) => {
        updateCourseInReduxStore(dispatch, res.data);
        if (typeof callback === 'function') {
          callback(res.data);
        }
      })
      .catch((err: any) => {
        dispatch({ type: 'GET_COURSE_FAILURE', payload: err, error: true });
        reject(err);
      });
  };
}

export function getFromEnrollLink(params = {}, { client, callback, reject }: any = {}) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'GET_COURSE_REQUEST', payload: (params as any).enrollId });
    return courseService({ client })
      .getFromEnrollLink(params)
      .then((res: any) => {
        const normalized = normalize(res.data, courseSchema);
        dispatch(mergeEntities(normalized.entities));
        dispatch(selectCourse(res.data._id));
        dispatch({ type: 'GET_COURSE_SUCCESS', payload: normalized.result });
        if (typeof callback === 'function') {
          callback(res.data);
        }
      })
      .catch((err: any) => {
        dispatch({ type: 'GET_COURSE_FAILURE', payload: err, error: true });
        reject(err);
      });
  };
}

export function getCourses(params = {}, { callback, client }: any = {}) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: types.GET_COURSES_REQUEST, payload: { params } });
    return courseService({ client })
      .list(params)
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, [courseSchema]);
          if (!normalized.entities.courses) {
            normalized.entities.courses = {};
          }
          dispatch(replaceEntities(normalized.entities));
          dispatch({
            type: 'GET_COURSES_SUCCESS',
            payload: { params, items: normalized.result },
          });
          if (typeof callback === 'function') {
            callback(res.data);
          }
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.GET_COURSES_FAILURE, payload: err });
        if (typeof callback === 'function') {
          callback([]);
        }
      });
  };
}

export function updateCourse(data: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'UPDATE_COURSE_REQUEST' });

    return courseService()
      .update({ id: data._id, data })
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, courseSchema);
          dispatch(updateEntities(normalized.entities));
          dispatch({ type: 'UPDATE_COURSE_SUCCESS', payload: res.data });
        }
      })
      .catch((err: any) => {
        throw new SubmissionError(
          (err && err.response && err.response.data && err.response.data.errors) || {}
        );
      });
  };
}

export function archiveCourse({ courseId }: any) {
  return (dispatch: any, getState: any) => {
    return courseService()
      .archive({ courseId })
      .then((res: any) => {
        if (res.status === 200) {
          return dispatch(push('/'));
        }
      })
      .catch((err: any) => {
        throw new SubmissionError(
          (err && err.response && err.response.data && err.response.data.errors) || {}
        );
      });
  };
}

export function unArchiveCourse({ courseId }: any) {
  return (dispatch: any, getState: any) => {
    return courseService()
      .unArchive({ courseId })
      .then((res: any) => {
        if (res.status === 200) {
          return dispatch(push('/'));
        }
      })
      .catch((err: any) => {
        throw new SubmissionError(
          (err && err.response && err.response.data && err.response.data.errors) || {}
        );
      });
  };
}

export function deleteCourse({ id = null }) {
  return (dispatch: any, getState: any) => {
    return courseService()
      .delete({ id })
      .then((res: any) => {
        if (res.status === 200) {
          return dispatch(push('/'));
        }
      })
      .catch((err: any) => {
        throw new SubmissionError(
          (err && err.response && err.response.data && err.response.data.errors) || {}
        );
      });
  };
}

export function removeStudent({ courseId, user }: any) {
  return async (dispatch: any, getState: any) => {
    try {
      const res = await courseService().removeStudent({ courseId, user });
      if (res.status === 200) {
        const { course, groups, removedStudent } = res.data;
        const normalizedCourse = normalize(course, courseSchema);
        const normalizedGroups = normalize(groups, groupSchema);
        const normalizedStudent = normalize(removedStudent, studentSchema);
        dispatch(updateEntities(normalizedCourse.entities));
        dispatch(updateEntities(normalizedGroups.entities));
        dispatch(updateEntities(normalizedStudent.entities));
      }
    } catch (err) {
      // axios client will throw an error if response status 400
      if ((err as any).response.status === 400) {
        // Here we handle 400 bad request for error res:
        // Cannot remove a student while an activity is in Evaluate stage,
        // in backend 400, bad request is throw with NotValidError
        dispatch(
          openErrorModal({
            title: 'Error',
            content: ErrorUtils.getErrorMessageFromResponse(err),
            handleClose: () => {
              return dispatch({ type: modalTypes.CLOSE_ERROR_MODAL });
            },
          })
        );
        throw new SubmissionError(
          (err &&
            (err as any).response &&
            (err as any).response.data &&
            (err as any).response.data.errors) ||
            {}
        );
      }
    }
  };
}

export function reEnrollStudentInCourse({
  studentId,
  courseId,
}: {
  studentId: string;
  courseId: string;
}) {
  return async (dispatch: any) => {
    const response = await courseService().reEnrollStudentInCourse({ studentId, courseId });
    const normalizedCourse = normalize(response.data, courseSchema);
    dispatch(updateEntities(normalizedCourse.entities));
  };
}

export function addCollaborator({ courseId }: any) {
  return (dispatch: any, getState: any) => {
    return courseService()
      .addCollaborator({ courseId })
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, courseSchema);
          dispatch(mergeEntities(normalized.entities));
        }
      })
      .catch((err: any) => {
        throw new SubmissionError(
          (err && err.response && err.response.data && err.response.data.errors) || {}
        );
      });
  };
}

export function removeCollaborator({ courseId, collaboratorEmail }: any) {
  return (dispatch: any, getState: any) => {
    return courseService()
      .removeCollaborator({ courseId, collaboratorEmail })
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, courseSchema);
          dispatch(updateEntities(normalized.entities));
        }
      })
      .catch((err: any) => {
        throw new SubmissionError(
          (err && err.response && err.response.data && err.response.data.errors) || {}
        );
      });
  };
}

export function getGroups({ courseId }: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'GET_GROUPS_REQUEST' });

    return courseService()
      .getGroups({ courseId })
      .then((res: any) => {
        if (res.status === 200) {
          const { groups } = res.data;
          const normalized = normalize(groups, [groupSchema]);
          dispatch(replaceEntities(normalized.entities));
          dispatch({ type: 'GET_GROUPS_SUCCESS', payload: res.data });
        }
      })
      .catch((err: any) => {
        dispatch({ type: 'GET_GROUPS_FAILURE', payload: err });
        throw new SubmissionError(
          (err && err.response && err.response.data && err.response.data.errors) || {}
        );
      });
  };
}

export function exportCourseCreations(course: any, { onSuccess }: any) {
  return async (dispatch: any) => {
    dispatch({
      type: types.EXPORT_COURSE_CREATIONS_REQUEST,
    });
    try {
      const wb = XLSX.utils.book_new();
      wb.Props = {
        Title: course.title,
        // @ts-expect-error TS(2322) FIXME: Type '{ Title: any; Department: any; Author: strin... Remove this comment to see the full error message
        Department: course.department,
        Author: 'Kritik',
        CreatedDate: new Date(Date.now()),
      };
      const sheetNames = {};
      const res = await courseService().exportCourseCreations(course._id);
      for (const assignmentName in res.data) {
        const assignmentCreations = res.data[assignmentName];
        const sheetName = validateSheetName(assignmentName);
        if (sheetNames[sheetName]) {
          wb.SheetNames.push(`(${sheetNames[sheetName]})${sheetName}`);
          sheetNames[sheetName]++;
        } else {
          wb.SheetNames.push(sheetName);
          sheetNames[sheetName] = 1;
        }
        const ws = XLSX.utils.json_to_sheet(assignmentCreations);
        wb.Sheets[sheetName] = ws;
      }
      XLSX.writeFile(wb, `${course.title}-creations.xlsx`, { bookType: 'xlsx' });
      dispatch({
        type: types.EXPORT_COURSE_CREATIONS_SUCCESS,
      });
      onSuccess?.();
    } catch (error) {
      dispatch({
        type: types.EXPORT_COURSE_CREATIONS_FAILURE,
      });
    } finally {
      dispatch(closeInfoModal());
    }
  };
}

export function cancelExportCourseCreations() {
  return (dispatch: any) => {
    dispatch(closeInfoModal());
    dispatch({
      type: types.EXPORT_COURSE_CREATIONS_INIT,
    });
  };
}

export function setDashboardScores(courseId: any) {
  return (dispatch: any, getState: any) => {
    const state = getState();

    // We need to filter out presentation activities from the dashboard
    const pastAssignments = getPastAssignments(state, courseId).filter(
      (activity) => !isPresentationActivity(activity)
    );
    const gradeHistories = selectGradeHistories(state).filter((gradeHistory) => {
      const activity = getAssignment(state, gradeHistory.assignment);
      return !isPresentationActivity(activity);
    });

    const avgGrades = GradeHistoryUtil.calculateAveragGrades(gradeHistories, pastAssignments);

    dispatch(setAvgCreationScore(avgGrades.creation));
    dispatch(setAvgEvalScore(avgGrades.grading, avgGrades.writtenEvaluation));
    dispatch(setAvgFeedbackScore(avgGrades.feedback));
    dispatch(setAvgOverallScore(avgGrades));
  };
}

export function setAvgOverallScore(avgGrades: any) {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const course = courseSelectors.getCourse(state);
    const avgOverallGrade = OverallScoreUtil.calculateOverallScore(avgGrades, course);
    dispatch({
      type: types.SET_AVG_OVERALL_SCORE,
      payload: { avgOverallGrade },
    });
  };
}

export function setAvgCreationScore(grade: any) {
  return (dispatch: any) => {
    dispatch({
      type: types.SET_AVG_CREATION_SCORE,
      payload: { avgCreationGrade: grade },
    });
  };
}

export function setAvgEvalScore(grading: any, writtenEvaluation: any) {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const course = courseSelectors.getCourse(state);
    const avgEvalGrade = EvaluationScoreUtil.evaluatorOverallScoreSingleActivity(
      grading,
      writtenEvaluation,
      course
    );

    dispatch({
      type: types.SET_AVG_EVAL_SCORE,
      payload: { avgEvalGrade },
    });
  };
}

export function setAvgFeedbackScore(grade: any) {
  return (dispatch: any) => {
    dispatch({
      type: types.SET_AVG_FEEDBACK_SCORE,
      payload: { avgFeedbackGrade: grade },
    });
  };
}

export function duplicateCourse({ courseId, ...data }: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'CREATE_DUPLICATE_COURSE_REQUEST' });
    return courseService()
      .createDuplicate({ courseId, data })
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, courseSchema);
          dispatch(mergeEntities(normalized.entities));
          dispatch({ type: 'CREATE_DUPLICATE_COURSE_SUCCESS' });
          trackEvent('Course Duplicated', getState().user.authUser, {
            instructorEmail: data.user.email,
            activitiesDuplicated: data.includeActivities,
          });
        }
      })
      .catch((error: any) => {
        const message = error?.response?.data?.error?.message ?? 'Duplicate course failed';
        dispatch({ type: 'CREATE_DUPLICATE_COURSE_FAILURE', payload: message });
      });
  };
}

export function clearDuplicateError() {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'CLEAR_DUPLICATE_COURSE_FAILURE' });
  };
}
