import React from 'react';

const OverviewSection = ({ title, children, testid }: any) => {
  return (
    <div className="activity-overview-body__section">
      <div className="activity-overview-body__section-title" data-testid={testid}>
        {title}
      </div>
      {children}
    </div>
  );
};

export default OverviewSection;
