import React, { useState, useContext } from 'react';
import { FormMainError } from 'components/Form/FormComponents';
import FormNumberInput from 'components/core/form/NumberField';
import FormRichTextEditor from 'components/core/form/RichTextEditor';
import { connect } from 'react-redux';
import Button from 'components/buttons/Button';
import { createMissedFeedback } from 'actions/admin';
import { EvaluationContext } from '../../context/EvaluationContext';
import { getAssignment } from 'selectors/activity';
import { getWordCountFromHTML } from '@kritik/utils/general';
import { MINIMUM_WORD_COUNT } from '@kritik/constants/course';
import { getErrorMessageFromResponse } from 'utils/error';
import FormCheckbox from 'components/core/input/Checkbox';
import FormField from 'components/core/form/Field';

const mapStateToProps = (state: any) => {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
    student: state.admin.student,
    instructor: state.admin.prof,
  };
};

const MIN_SCORE = 1;
const MAX_SCORE = 4;

const AddNewFeedback = (props: any) => {
  const { createMissedFeedback, student, instructor } = props;
  const [notifications, setNotications] = useState({
    student: false,
    instructor: false,
  });

  const [evaluation] = useContext(EvaluationContext);

  const [uploadLoading, setUploadLoading] = useState(false);
  const [error, setError] = useState(null);
  const [feedback, setFeedback] = useState(
    evaluation.feedbackOnFeedback.critical === 0
      ? {
          comment: '',
          motivational: 1,
          critical: 1,
        }
      : evaluation.feedbackOnFeedback
  );

  const handleInputChange = (type: any, value: any) => {
    const valueReceived = value.charAt(value.length - 1);
    const valueToStore =
      Number(valueReceived) < MIN_SCORE
        ? MIN_SCORE
        : Number(valueReceived) > MAX_SCORE
          ? MAX_SCORE
          : Number(valueReceived);
    setError(null);
    setFeedback({ ...feedback, [type]: valueToStore });
  };
  const handleSubmit = async () => {
    setUploadLoading(true);
    const _comment = getWordCountFromHTML(feedback.comment);
    const MIN_COMMENT_LENGTH = MINIMUM_WORD_COUNT;

    if (feedback.motivational === -1 || feedback.critical === -1) {
      setUploadLoading(false);
      return setError('Please select a point for each category before submitting');
    }

    if (_comment < MIN_COMMENT_LENGTH) {
      setUploadLoading(false);
      return setError(
        `Please leave a longer comment, you must write at least ${MINIMUM_WORD_COUNT} words`
      );
    }

    try {
      await createMissedFeedback({
        evaluationId: evaluation._id,
        newFof: feedback,
        notifications,
      });
      if (!error) {
        window.location.reload();
      }
    } catch (e) {
      setUploadLoading(false);
      setError(getErrorMessageFromResponse(e));
    }
  };

  const getFeedbackComment = () => {
    if (feedback.comment) {
      return feedback.comment;
    }
    return evaluation.feedbackOnFeedback ? evaluation.feedbackOnFeedback.comment : '';
  };

  return (
    <React.Fragment>
      <h3>Add a new feedback:</h3>
      <FormRichTextEditor
        label="Comment"
        value={getFeedbackComment()}
        onChange={(comment: any) => {
          setError(null);
          return setFeedback({ ...feedback, comment });
        }}
      />
      <p>Word count: {getWordCountFromHTML(feedback.comment)}</p>
      <FormNumberInput
        name="motivational"
        label="Motivational"
        step={1}
        value={feedback.motivational}
        onChange={(e: any) => {
          return handleInputChange(e.target.name, e.target.value);
        }}
      />
      <FormNumberInput
        name="critical"
        label="Critical"
        step={1}
        value={feedback.critical}
        onChange={(e: any) => {
          return handleInputChange(e.target.name, e.target.value);
        }}
      />
      <FormField>
        <FormCheckbox
          label={`Notify the student (${student.email})`}
          isChecked={notifications.student}
          onChange={() => {
            return setNotications((notifications) => ({
              ...notifications,
              student: !notifications.student,
            }));
          }}
        />
      </FormField>
      <FormField>
        <FormCheckbox
          label={`Notify the instructor (${instructor.email})`}
          isChecked={notifications.instructor}
          onChange={() => {
            return setNotications((notifications) => ({
              ...notifications,
              instructor: !notifications.instructor,
            }));
          }}
        />
      </FormField>
      <div style={{ margin: '50px 0 20px' }}>
        <Button
          loading={uploadLoading}
          unavailable={uploadLoading}
          disabled={uploadLoading}
          type="primary"
          onClick={handleSubmit}
        >
          Upload
        </Button>
      </div>
      {error && (
        <React.Fragment>
          <FormMainError message={error} isOpen={error} />
          <Button
            style={{ marginTop: '30px' }}
            type="primary"
            onClick={() => {
              return window.location.reload();
            }}
          >
            Ok, Got it
          </Button>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default connect(mapStateToProps, {
  createMissedFeedback,
})(AddNewFeedback);
