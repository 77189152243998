import React from 'react';
import createSvgIcon from '../util/generateIconSVG';

export default createSvgIcon(
  <React.Fragment>
    <circle cx="31" cy="31.2799" r="29" fill="#A4F4DC" stroke="#302D6C" strokeWidth="4" />
    <mask
      id="path-2-outside-1"
      maskUnits="userSpaceOnUse"
      x="10.1921"
      y="12.3751"
      width="42"
      height="33"
      fill="black"
    >
      <rect fill="white" x="10.1921" y="12.3751" width="42" height="33" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.3531 40.5939C47.4153 38.3816 48.0103 35.9024 48.0103 33.2842C48.0103 23.9456 40.4399 16.3751 31.1012 16.3751C21.7626 16.3751 14.1921 23.9456 14.1921 33.2842C14.1921 35.9024 14.7872 38.3816 15.8494 40.5939H46.3531Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.3531 40.5939C47.4153 38.3816 48.0103 35.9024 48.0103 33.2842C48.0103 23.9456 40.4399 16.3751 31.1012 16.3751C21.7626 16.3751 14.1921 23.9456 14.1921 33.2842C14.1921 35.9024 14.7872 38.3816 15.8494 40.5939H46.3531Z"
      fill="#DCDBF0"
    />
    <path
      d="M46.3531 40.5939V44.5939H48.8697L49.959 42.3252L46.3531 40.5939ZM15.8494 40.5939L12.2435 42.3252L13.3328 44.5939H15.8494V40.5939ZM44.0103 33.2842C44.0103 35.2903 43.5555 37.1789 42.7472 38.8626L49.959 42.3252C51.275 39.5842 52.0103 36.5144 52.0103 33.2842H44.0103ZM31.1012 20.3751C38.2307 20.3751 44.0103 26.1547 44.0103 33.2842H52.0103C52.0103 21.7365 42.649 12.3751 31.1012 12.3751V20.3751ZM18.1921 33.2842C18.1921 26.1547 23.9717 20.3751 31.1012 20.3751V12.3751C19.5535 12.3751 10.1921 21.7365 10.1921 33.2842H18.1921ZM19.4553 38.8626C18.6469 37.1789 18.1921 35.2903 18.1921 33.2842H10.1921C10.1921 36.5144 10.9274 39.5842 12.2435 42.3252L19.4553 38.8626ZM15.8494 44.5939H46.3531V36.5939H15.8494V44.5939Z"
      fill="#302D6C"
      mask="url(#path-2-outside-1)"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="14"
      y="16"
      width="35"
      height="34"
    >
      <ellipse cx="31.1012" cy="33.0493" rx="16.9091" ry="16.9091" fill="#2F80ED" />
    </mask>
    <g mask="url(#mask0)">
      <path d="M51.4624 21.059L54.4567 40.7202H26.2324L51.4624 21.059Z" fill="#DE87D3" />
    </g>
    <path
      d="M50.1221 22.6961C51.6854 20.8249 49.1762 18.3156 47.305 19.879L27.8002 36.1746C26.8984 36.928 26.8374 38.2927 27.6683 39.1236L30.8774 42.3328C31.7083 43.1637 33.0731 43.1026 33.8265 42.2009L50.1221 22.6961Z"
      fill="#C528B2"
      stroke="#302D6C"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M37.2897 39.6195C37.2897 43.4005 34.2246 46.4656 30.4436 46.4656C26.6625 46.4656 23.5974 43.4005 23.5974 39.6195C23.5974 35.8384 26.6625 32.7733 30.4436 32.7733C34.2246 32.7733 37.2897 35.8384 37.2897 39.6195Z"
      fill="#DCDBF0"
      stroke="#302D6C"
      strokeWidth="2"
    />
    <line
      x1="30.7393"
      y1="16.4066"
      x2="30.7393"
      y2="20.4833"
      stroke="#302D6C"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="16.953"
      y1="22.4224"
      x2="19.8357"
      y2="25.3051"
      stroke="#302D6C"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="13.6064"
      y1="35.8198"
      x2="17.4712"
      y2="35.8198"
      stroke="#302D6C"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="44.0457"
      y1="35.8198"
      x2="47.9104"
      y2="35.8198"
      stroke="#302D6C"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </React.Fragment>,
  '0 0 62 63'
);
