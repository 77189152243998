import { Component } from 'react';
import { connect } from 'react-redux';
import { commentAssignmentSubmissionEvaluation } from 'actions/activity';
import Button from 'components/buttons/Button';
import { Form } from 'components/Form';
import InlineInformation from 'components/layout/InlineInformation';
import FormFieldInfo from 'components/core/form/FieldInfo';
import FormFieldTitle from 'components/core/form/Title';
import FormContainer from 'components/core/form/Container';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import FormRichTextEditor from 'components/core/form/RichTextEditor';
import AttachmentManager from 'components/layout/AttachmentManager';
import { selectAuthUser } from 'selectors/user';
import * as CreationUtils from '@kritik/utils/creation/general';
import { getUserObjectByStringId } from 'redux/utils';
import { localize } from 'locales/index';

type OwnState = any;

type State = OwnState & typeof EditProfComment.defaultProps;

class EditProfComment extends Component<{}, State> {
  static defaultProps = {
    instructorComment: null,
    isCreationComment: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      fileList: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    if ((this.props as any).instructorComment) {
      this.setState({
        comment: (this.props as any).instructorComment.text,
        fileList: (this.props as any).instructorComment.files,
      });
    }
    this.setState({ isLoading: false });
  }

  handleFileChange(files: any) {
    this.setState({ fileList: files });
  }

  onSubmit() {
    const data = {};
    (data as any).userId = (this.props as any).user._id;
    (data as any).comment = this.state.comment;
    (data as any).files = this.state.fileList;
    (this.props as any)
      .onSubmit(data)
      .then(() => {
        (this.props as any).onClose();
      })
      .catch((err: any) => {
        this.setState({ error: err.message });
      });
  }

  onCommentChange(comment: any) {
    this.setState({ comment });
  }

  getErrors() {
    if (!this.state.error) {
      return null;
    }
    return <InlineInformation type="danger">{this.state.error}</InlineInformation>;
  }

  getLabel() {
    const isCommentingOnCreation = (this.props as any).isCreationComment;
    let label = 'Commenting on Written Evaluation';
    if (isCommentingOnCreation) {
      label = 'Commenting on Creation';
    }
    return label;
  }

  getPrivateTo() {
    // @ts-expect-error TS(2339) FIXME: Property 'creation' does not exist on type 'Readon... Remove this comment to see the full error message
    const { creation } = this.props;
    if (CreationUtils.isGroupCreation(creation) && (this.props as any).isCreationComment) {
      return creation.group.name;
    }
    return (this.props as any).contentOwner?.profile?.name || '';
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }
    return (
      <FormContainer>
        <Form>
          <FormFieldTitle size="lg" label={this.getLabel()} />
          <FormFieldInfo>
            <b>Private to: </b>
            {this.getPrivateTo()}
          </FormFieldInfo>
          <FormRichTextEditor
            value={this.state.comment}
            onChange={(ev: any) => {
              return this.onCommentChange(ev);
            }}
            testid="comment-on-evaluation-editor"
          />
          <AttachmentManager
            onFileChange={(newList: any) => {
              return this.handleFileChange(newList);
            }}
            fileList={this.state.fileList}
          />
          <FormSubmitButtons errors={this.getErrors()}>
            <Button
              className="submit-comment"
              type="primary"
              onClick={() => {
                return this.onSubmit();
              }}
              data-testid="save-content"
              inputType="submit"
              label={localize({ message: 'Button.Label.SaveInstructorComment' })}
            >
              Save
            </Button>
            <Button
              className="cancel-btn"
              type="secondary"
              onClick={() => {
                return (this.props as any).onClose();
              }}
            >
              Cancel
            </Button>
          </FormSubmitButtons>
        </Form>
      </FormContainer>
    );
  }
}

function mapStateToProps(state: any, ownProps: any) {
  const contentOwner = getUserObjectByStringId(ownProps.contentOwner, state.entities);
  return {
    entities: state.entities,
    user: selectAuthUser(state),
    contentOwner,
  };
}

export default connect(mapStateToProps, { commentAssignmentSubmissionEvaluation })(EditProfComment);
