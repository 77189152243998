import React from 'react';
import ColumnSelector from 'components/LmsGradeSync/ColumnSelector';
import Tooltip from '@material-ui/core/Tooltip';

function LmsColumnCell(props: any) {
  if (props.selectedColumn.isNewColumn === false) {
    return <span>{props.selectedColumn.label}</span>;
  }
  return (
    <Tooltip title={props.selectedColumn.label || ''} placement="top">
      <div>
        <ColumnSelector
          lmsColumns={props.lmsColumns}
          selectedColumn={props.selectedColumn}
          setSelectedColumn={props.setSelectedColumn}
          activityId={props.activityId}
        />
      </div>
    </Tooltip>
  );
}

export default LmsColumnCell;
