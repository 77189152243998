import { connect } from 'react-redux';
import { navigateToEditActivityParticipation } from 'actions/activity';
import { navigateToCourseStudentsPage } from 'actions/courses';
import { localize } from 'locales';
import ActionCell from '../ActionCell';
import ScheduleCell from '../Schedule';

type Props = {
  course: any;
  activity: any;
  isRowHovered: boolean;
  navigateToCourseStudentsPage: (id: string) => void;
  navigateToEditActivityParticipation: (params: { courseId: string; activityId: string }) => void;
  courseHasGroupSets: boolean;
};

const NextActionCell = ({
  course,
  activity,
  navigateToCourseStudentsPage,
  navigateToEditActivityParticipation,
  isRowHovered,
  courseHasGroupSets,
}: Props) => {
  if (course.students.length === 0) {
    return (
      <ActionCell
        handleAction={() => navigateToCourseStudentsPage(activity.course)}
        isRowHovered={isRowHovered}
        id={`invite-students-${activity.course}`}
        actionText={localize({ message: 'Course.InviteStudents' })}
        text={localize({ message: 'Course.NoStudents' })}
      />
    );
  }

  if (!courseHasGroupSets) {
    return (
      <ActionCell
        handleAction={() => navigateToCourseStudentsPage(activity.course)}
        isRowHovered={isRowHovered}
        id={`create-groupSets-${activity.course}`}
        actionText={localize({ message: 'Course.CreateGroupSets' })}
        text={localize({ message: 'Course.NoGroupSets' })}
      />
    );
  }

  if (!activity.participationSettings?.creatorGroups?.length) {
    return (
      <ActionCell
        handleAction={() =>
          navigateToEditActivityParticipation({
            courseId: activity.course,
            activityId: activity._id,
          })
        }
        isRowHovered={isRowHovered}
        id={`edit-participation-${activity._id}`}
        actionText={localize({ message: 'Activity.EditStudentParticipation' })}
        text={localize({ message: 'Activity.NoPresenters' })}
      />
    );
  }

  return <ScheduleCell activity={activity} isRowHovered={isRowHovered} />;
};

NextActionCell.defaultProps = {
  isRowHovered: false,
};

export default connect(null, {
  navigateToCourseStudentsPage,
  navigateToEditActivityParticipation,
})(NextActionCell);
