import PEER from './peer_icon.svg';
import GROUP from './group_icon.svg';
import CALIBRATION from './calibration_icon.svg';
import WITHIN_GROUP from 'images/activity-types/within_group_icon.svg';
import PRESENTATION from './presentation_icon.svg';

export default {
  PEER,
  GROUP,
  CALIBRATION,
  WITHIN_GROUP,
  PRESENTATION,
};
