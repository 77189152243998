import { useEffect, useState } from 'react';
import Table from 'components/layout/SimpleTable';
import TableRow from 'components/layout/SimpleTable/Row';
import TableCell from 'components/layout/SimpleTable/Cell';
import TableHead from 'components/layout/SimpleTable/Head';
import TableBody from 'components/layout/SimpleTable/Body';

import { EdlinkIntegration } from '@kritik/types.generated';
import PageContainer from 'components/layout/PageContainer';
import PageHeader from 'components/layout/PageHeader';
import { InlineInformation } from 'components/layout';
import Button from 'components/buttons/Button';
import InstitutionSelect from 'components/Admin/InstitutionSelect';
import Spinner from 'components/Loaders/Spinner';
import { adminService } from 'services';
import * as ErrorUtils from 'utils/error';
import { exactDate } from '@kritik/utils/format';
import IntegrationForm from './IntegrationForm';

const LmsIntegrations = () => {
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [selectedIntegration, setSelectedIntegration] = useState<EdlinkIntegration>(null);
  const [refetchInstitutions, setRefetchInstitutions] = useState(0);
  const [integrations, setIntegrations] = useState<EdlinkIntegration[]>([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchInstitutionIntegrations = async () => {
      setErrorMessage(null);
      setIsLoading(true);
      try {
        const response = await adminService().fetchInstitutionIntegrations({
          institutionId: selectedInstitution.value,
        });
        setIsLoading(false);
        if (response.data) {
          setIntegrations(response.data);
        }
      } catch (err) {
        setIsLoading(false);
        setErrorMessage(ErrorUtils.getErrorMessageFromResponse(err));
      }
    };

    setShowCreateForm(false);
    if (selectedInstitution) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchInstitutionIntegrations();
    }
  }, [selectedInstitution, refetchInstitutions]);

  const handleDeleteIntegration = async ({ integrationId }) => {
    setErrorMessage(null);

    try {
      const response = await adminService().deleteIntegration({
        integrationId,
      });
      if (response?.data?.success) {
        setRefetchInstitutions((d) => d + 1);
      }
    } catch (err) {
      setErrorMessage(ErrorUtils.getErrorMessageFromResponse(err));
    }
  };

  return (
    <PageContainer>
      <PageHeader title="Manage LMS Integrations" />
      <InstitutionSelect
        setSelectedInstitution={setSelectedInstitution}
        setErrorMessage={setErrorMessage}
      />
      {isLoading ? (
        <div className="spinner-container">
          <Spinner />
        </div>
      ) : (
        selectedInstitution && (
          <div>
            {integrations?.length > 0 ? (
              <Table>
                <TableHead>
                  <TableCell>LMS</TableCell>
                  <TableCell>Integration ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Creation Date</TableCell>
                  <TableCell></TableCell>
                </TableHead>
                <TableBody>
                  {integrations.map((integration) => {
                    return (
                      <TableRow key={integration.edlinkIntegrationId}>
                        <TableCell>{integration.lms}</TableCell>
                        <TableCell>{integration.edlinkIntegrationId}</TableCell>
                        <TableCell>{integration.name}</TableCell>
                        <TableCell>{exactDate(integration.createdAt, null)}</TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              setSelectedIntegration(integration);
                            }}
                          >
                            Update
                          </Button>
                          <Button
                            type="warning"
                            onClick={() => {
                              if (confirm('Are you sure you want to delete this integration?')) {
                                handleDeleteIntegration({
                                  integrationId: integration._id,
                                });
                              }
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <>
                <InlineInformation
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                  type="warning"
                >
                  No LMS integration found for this institution.
                </InlineInformation>
              </>
            )}
            <Button
              type="primary"
              style={{ marginTop: '10px' }}
              onClick={() => setShowCreateForm((showCreateForm) => !showCreateForm)}
            >
              Add Integration
            </Button>
          </div>
        )
      )}
      {showCreateForm && (
        <IntegrationForm
          hideForm={() => setShowCreateForm(false)}
          onError={setErrorMessage}
          onSuccess={() => {
            setRefetchInstitutions((d) => d + 1);
            setSelectedIntegration(null);
          }}
          institutionId={selectedInstitution.value}
        />
      )}
      {selectedIntegration && (
        <IntegrationForm
          initialValues={selectedIntegration}
          hideForm={() => setShowCreateForm(false)}
          onError={setErrorMessage}
          onSuccess={() => {
            setRefetchInstitutions((d) => d + 1);
            setSelectedIntegration(null);
          }}
          institutionId={selectedInstitution.value}
        />
      )}
      {errorMessage && (
        <InlineInformation style={{ marginTop: '10px' }} type="danger">
          {errorMessage}
        </InlineInformation>
      )}
    </PageContainer>
  );
};

export default LmsIntegrations;
