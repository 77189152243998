import { createRestApiClient } from 'utils/createRestApiClient';
const apiEndpoint = import.meta.env.VITE_API_URL;

export default class BaseService {
  client: any;
  source: any;
  constructor(options = {}) {
    const client =
      (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint });
    this.client = client;
    this.source = this.client.CancelToken.source();
  }
}
