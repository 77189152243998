import types from './types';

const selectGradbookAsyncState = (state: any) => {
  return state.gradebook.async;
};

const selectDownloadGradebookState = (state: any) => {
  return selectGradbookAsyncState(state)[types.DOWNLOAD_GRADEBOOK];
};

export default {
  selectDownloadGradebookState,
  selectGradbookAsyncState,
};
