import { useCallback, useEffect } from 'react';
import { BlockerFunction, useBeforeUnload, useBlocker, useNavigate } from 'react-router-dom';
import Confirm from 'components/modals/ConfirmModal';

type OnLeaveModalProps = {
  formRef?: any;
  noRef?: boolean;
  saveAction?: () => void;
  customButtonCopy?: string;
};
//based on https://github.com/remix-run/react-router/blob/main/examples/navigation-blocking/src/app.tsx
const OnLeaveModal: React.FunctionComponent<OnLeaveModalProps> = (props) => {
  const navigate = useNavigate();

  // Prompt the user before leaving the page if the form is dirty
  useBeforeUnload((ev) => {
    const isFormDirty = props.formRef?.current?.getState()?.dirty || props.noRef;
    if (!isFormDirty) {
      return;
    }
    ev.preventDefault();
  });
  // Block the navigation if the form is dirty and the user is changing routes
  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      const isChangingRoute = currentLocation.pathname !== nextLocation.pathname;
      const isFormDirty = props.formRef?.current?.getState()?.dirty || props.noRef;
      return isChangingRoute && isFormDirty;
    },
    [props.formRef?.current?.getState()?.dirty, props.noRef]
  );
  const blocker = useBlocker(shouldBlock);

  // Reset the blocker if the user cleans the form
  useEffect(() => {
    const isFormDirty = props.formRef?.current?.getState()?.dirty || props.noRef;
    if (blocker.state === 'blocked' && !isFormDirty) {
      blocker.reset();
    }
  }, [blocker, props.formRef?.current?.getState()?.dirty]);

  return (
    <Confirm
      isOpen={blocker.state === 'blocked'}
      onCancel={() => blocker.reset()}
      onConfirm={() => {
        if (props.saveAction) {
          props.saveAction();
          navigate('/create-activity');
        } else {
          blocker.proceed();
        }
      }}
      cancelButton="Stay on Page"
      confirmButton={`${props.customButtonCopy ? props.customButtonCopy : 'Discard Changes'}`}
      title="You have unsaved changes"
      description="Are you sure you want to navigate away? You will lose your unsaved changes."
    />
  );
};

export default OnLeaveModal;
