import { localize } from 'locales';

export const GOAL_TYPES = {
  QUESTION: 'question',
  ESSAY: 'essay',
  PEER_CONTENT: 'peerContent',
  MULTIMEDIA: 'multimedia ',
  WITHIN_GROUP: 'groupAssessment',
  GROUP_PRESENTATION: 'groupPresentation',
  INDIVIDUAL_PRESENTATION: 'individualPresentation',
};

export const ACTIVITY_GOALS = [
  { label: 'Create a Question', value: GOAL_TYPES.QUESTION },
  { label: 'Create an Essay', value: GOAL_TYPES.ESSAY },
  { label: 'Create Content to Teach Peers', value: GOAL_TYPES.PEER_CONTENT },
  { label: 'Creative Communication', value: GOAL_TYPES.MULTIMEDIA },
  { label: 'Evaluate Group Members', value: GOAL_TYPES.WITHIN_GROUP },
];

export const NOTES_PLACEHOLDER = `Enter notes for Evaluators`;

const passLevel = '0';
const passCriteriaRule = 'any';
const levels = ['Novice', 'Intermediate', 'Proficient', 'Advanced'];

export const ACTIVITY_TEMPLATES = {
  [GOAL_TYPES.QUESTION]: {
    type: 'question',
    objective:
      '<p>INSTRUCTOR TO INSERT APPROPRIATE OVERALL LEARNING OBJECTIVE. WE SUGGEST USING MEASURABLE VERBS FROM <a href="https://www.kritik.io/resources/blooms-taxonomy" target="_blank">BLOOM’S TAXONOMY</a>.</p>',
    instructions:
      '<p>This activity has three stages to complete. Check the schedule to the right of your screen to note the deadlines to submit for each stage. You will also receive email reminders when the stages open and when the deadlines are approaching. If you provide your phone number in Account Settings, you can opt in to receive text message reminders, too.</p>' +
      '<br/>' +
      '<p>INSTRUCTOR TO ADD SPECIFIC INSTRUCTIONS FOR WHAT STUDENTS SHOULD INCLUDE IN THE ASSIGNMENT.</p>' +
      '<br/>' +
      '<i>If you have any questions about using Kritik, please contact the Support team by accessing the live chat in the bottom right corner. They’re happy to help!</i>',
    rubric: {
      levels,
      criteria: [
        {
          name: 'Rich Information and Context',
          weight: 1,
        },
        {
          name: 'Complexity',
          weight: 1,
        },
        {
          name: 'Scope',
          weight: 1,
        },
        {
          name: 'Relevance',
          weight: 1,
        },
        {
          name: 'Creativity',
          weight: 1,
        },
      ],
      grid: [
        [
          'The question demonstrates no comprehension of the subject',
          'The question is based on weak understanding of the subject',
          'The question is based on the comprehension of most parts of the subject',
          'The question demonstrates rich and deep comprehension of the context',
        ],
        [
          'Basic and simple question with incorrect structure',
          'The question is general ',
          'The question is complicated but vaguely supported',
          'The question is complex but well supported ',
        ],
        [
          'Question is general and simple',
          'Question does not allow for exploration of the subject',
          'Question allows for exploration of some parts of the subject',
          'Question manipulates exploration of the subject in more depth',
        ],
        [
          'Not related to the subject',
          'Question is not explicitly relevant to the topic',
          'Question is relevant to a specific part of the topic',
          'The question is explicitly relevant to the topic',
        ],
        [
          'Question lacks creativity and innovation',
          'Question is general with minimum creativity',
          'Question manipulates some new perspective',
          'Unique and original question that manipulates a new perspective',
        ],
      ],
      passLevel,
      passCriteriaRule,
    },
  },
  [GOAL_TYPES.ESSAY]: {
    type: 'essay',
    objective: '<p>Example Objective: To develop writing and analytical skills.</p>',
    instructions:
      '<p>Example Instruction: Read section 1.1 through 1.3 of your textbook. Think of a controversial topic related to the content covered in these sections and pick a position. Write a short persuasive essay(200- 250 words), and build a case using facts and logic, as well as examples, expert opinion, and sound reasoning, why a certain position is correct. The rubric below will be used to assess your score.</p>',
    rubric: {
      levels,
      criteria: [
        {
          name: 'Clarity of Thoughts',
          weight: 1,
        },
        {
          name: 'Accuracy',
          weight: 1,
        },
        {
          name: 'Creative and Critical Thinking',
          weight: 1,
        },
        {
          name: 'Source and evidence',
          weight: 1,
        },
      ],
      grid: [
        [
          'Central idea and clarity of purpose absent or incompletely maintained',
          'Central idea expressed vaguely. Minimal sense of purpose present',
          'Central idea and clarity of purpose generally evident',
          'Well developed central idea. Clarity of purpose demonstrated throughout the essay',
        ],
        [
          'Incorrect and unsupported information',
          'Important information left out. Disreputable opinions ',
          'Few inaccurate facts. Information based on evidence',
          'All the information and facts are accurate and based on evidence',
        ],
        [
          'Position was simplistic and obvious',
          'Displayed a solid understanding of the topic. Failed to address challenging thoughts',
          'Sharpened the focus, sorted ideas for relevance, focused on key points',
          'Original concepts synthesized. Honoured multiple perspectives and made thoughtful connections',
        ],
        [
          'Lacks citation. Incorrect and disreputable evidence',
          'Some sources are missing',
          'Sources are cited correctly and some are used critically',
          'Sources are cited consistent stye and are used critically',
        ],
      ],
      passLevel,
      passCriteriaRule,
    },
  },
  [GOAL_TYPES.PEER_CONTENT]: {
    type: 'peerContent',
    objective:
      '<p>Example Objective: To enhance comprehension and memorization of the course materials through teaching peers.</p>',
    instructions:
      '<p>Example Instruction: Read section 1.1 through 1.3 of your textbook or the slides used by your instructor to teach them in the class. Think of a better way you can teach the same concept(s) to your peers. Feel free to use visual aids and refer to any known pop culture, characters or external examples known among your peers to convey the message or make a point clear or make it stick to peers&apos;s memory for longer. The rubric will be used to assess your score.</p>',
    rubric: {
      levels,
      criteria: [
        {
          name: 'Organization',
          weight: 1,
        },
        {
          name: 'Relevance',
          weight: 1,
        },
        {
          name: 'Clarity',
          weight: 1,
        },
        {
          name: 'Knowledge of Content',
          weight: 1,
        },
      ],
      grid: [
        [
          'No clear sequence',
          'Material explained/presented in a confusing manner',
          'Material explained in a logical manner',
          'Material explained in a logical, sophisticated, and engaging manner',
        ],
        [
          'Material is not related to the subject',
          'Material is not directly relevant to the subject',
          'Material only explains select parts of the subject',
          'Composed material is explicitly relevant to the subject',
        ],
        [
          'Material is confusing and not understandable',
          'Material composition is confusing at most parts',
          'Material composed in a clear way with few distracting parts.',
          'Material composed very clearly and easy to understand',
        ],
        [
          'Displays little/no understanding of the concept',
          'Displays some understanding of the concepts. Leaves important information out',
          'Displays sufficient understanding of the concept',
          'Displays thorough understanding of content. Extra research is evident',
        ],
      ],
      passLevel,
      passCriteriaRule,
    },
  },
  [GOAL_TYPES.MULTIMEDIA]: {
    type: 'multimedia',
    objective:
      '<p>Example Objective: To develop creativity and presentation skills through making or adopting multimedia and visual modules.</p>',
    instructions:
      '<p>Example instruction: How can the attached text be presented more creatively? Augment the text with an illustration, infographic item, summary table, short video, animation, or anything that will help convey the message faster or make it more engaging than the plain text. You can either use your creative skills to build one, or find an open-source content online. Make sure to cite if you used others&apos; creation. The rubric will be used to assess your activity.</p>',
    rubric: {
      levels,
      criteria: [
        {
          name: 'Organization',
          weight: 1,
        },
        {
          name: 'Knowledge',
          weight: 1,
        },
        {
          name: 'Text and Readability',
          weight: 1,
        },
        {
          name: 'Creativity',
          weight: 1,
        },
        {
          name: 'Visual Aid',
          weight: 1,
        },
      ],
      grid: [
        [
          'No clear sequence of presentation',
          'The sequence of presentation is difficult for the audience to follow',
          'Presentation follows a logical sequence',
          'Logical, engaging and sophisticated sequence of presentation',
        ],
        [
          'No understanding of information. Incorrect and unsupported information',
          'Inaccurate information presented. Important information left out',
          'Accurate information presented comfortably',
          'Thorough and deep understanding of the topic. Information presented accurately',
        ],
        [
          'Poor readability and incorrect use of fonts',
          'Difficult to read. Inappropriate use of fonts',
          'Good readability with some distraction due to font selection',
          'Carefully planned font format which enhances readability',
        ],
        [
          'Repetitive delivery with no originality in presentation techniques',
          'Material presented with little originality or interpretation',
          'Some original material and interpretation',
          'Excepionally original material and interpretation',
        ],
        [
          'Does not connect to speech. Disorganized and basic.',
          'Basic and somewhat organized. Minimal connection to speech',
          'Connects to speech. Mostly organized, creative, and aesthetically pleasing',
          'Complements the speech well. Organized, creative and aesthetically pleasing',
        ],
      ],
      passLevel,
      passCriteriaRule,
    },
  },
  [GOAL_TYPES.WITHIN_GROUP]: {
    type: 'question',
    objective:
      '<p>Students working in small groups often learn more and demonstrate better retention than students taught in other instructional formats.</p><p> The objective of this activity is to give students the opportunity to evaluate the effectiveness of their group and to evaluate themselves.</p>',
    instructions:
      "<p>1. For the Create stage, submit a self-evaluation of your contributions to the team, answering the following questions in your response:</p><ul><li>How did the group interact together?</li><li>What have you learned and how have you learned it?</li><li>What were your contributions to the group?</li><li>What was done well? What could be improved?</li></ul><p>2. Check the activity schedule for when the Evaluate stage is due. In this stage, assess each team member's contributions to the group using the rubric provided. You will also be anonymously evaluated by your team members.&nbsp; In your written evaluation, reflect on the following questions in your response:</p><ul><li>How did the group interact together?</li><li>What have you learned from this team member and how have you learned it?</li><li>What were their contributions to the group?</li><li>What was done well by this individual? What could be improved?</li></ul><p>3. Check the activity schedule for when the Feedback stage is due. During the Feedback stage, you will have the opportunity to rate how critical and how motivational each team member's written evaluations were for you to receive.</p>",
    rubric: {
      levels,
      criteria: [
        {
          name: 'Contributions & Attitude',
          weight: 1,
        },
        {
          name: 'Cooperation with Others',
          weight: 1,
        },
        {
          name: 'Focus, Commitments',
          weight: 1,
        },
        {
          name: 'Team Role Fulfillment',
          weight: 1,
        },
        {
          name: 'Ability to Communicate',
          weight: 1,
        },
        {
          name: 'Accuracy',
          weight: 1,
        },
      ],
      grid: [
        [
          'Seldom cooperative. Rarely offers useful ideas. Is disruptive.',
          'Sometimes cooperative. Sometimes offers useful ideas. Rarely displays positive attitude.',
          'Usually cooperative. Usually offers useful ideas. Generally displays positive attitude.',
          'Always cooperative. Routinely offers useful ideas. Always displays positive attitude.',
        ],
        [
          'Did not do any work. Does not contribute. Does not work well with others.',
          'Could have shared more of the workload. Has difficulty. Requires structure, directions, and leadership.',
          'Did own part of workload. Cooperative. Works well with others.',
          'Did more than others. Highly productive. Works extremely well with others.',
        ],
        [
          'Often is not a good team member. Does not focus on the task. Lets others do the work.',
          'Sometimes focuses on the task. Not always a good team member. Must be prodded and reminded to keep on task.',
          'Does not cause problems in the group. Focuses on the task most of the time. Can count on this person.',
          'Tries to keep people working together. Almost always focused on the task. Is very self-directed.',
        ],
        [
          'Participates in few or no group meetings. Provides no leadership. Does little or no work assigned by the group.',
          'Participates in some group meetings. Provides some leadership. Does some of the work assigned by the group.',
          'Participates in most group meetings. Provides leadership when asked. Does most of the work assigned by the group.',
          'Participates in all group meetings. Assumes leadership role. Does the work that is assigned by the group.',
        ],
        [
          'Rarely listens to, shares with, or supports the efforts of others. Is always talking and never listens to others. Provides no feedback. Does not relay any information to teammates.',
          'Often listens to, shares with, and supports the efforts of others. Usually does most of the talking. Rarely listens to others. Provides little feedback. Relays very little information that relates to the topic.',
          'Usually listens to, shares with, and supports the efforts of others. Sometimes talks too much. Provides some effective feedback. Relays some basic information that relates to the topic.',
          'Always listens to, shares with, and supports the efforts of others. Provides effective feedback. Relays a lot of relevant information.',
        ],
        [
          'Work is generally sloppy and incomplete, contains excessive errors, and is mostly late.',
          'Work tends to be disorderly, incomplete, inaccurate, and is usually late.',
          'Work is generally complete, meets the requirements of the task, and is mostly done on time. ',
          'Work is complete, well-organized, error-free, and done on time or early.',
        ],
      ],
      passLevel,
      passCriteriaRule,
    },
  },
  [GOAL_TYPES.GROUP_PRESENTATION]: {
    type: 'question',
    objective: `<p>${localize({ message: 'Rubric.Presentation.Group.Objective' })}</p>`,
    instructions: `<p>${localize({ message: 'Rubric.Presentation.Group.Instructions' })}</p>`,
    rubric: {
      levels,
      criteria: [
        {
          name: localize({ message: 'Rubric.Presentation.Group.Crit1' }),
          weight: 1,
        },
        {
          name: localize({ message: 'Rubric.Presentation.Group.Crit2' }),
          weight: 1,
        },
        {
          name: localize({ message: 'Rubric.Presentation.Group.Crit3' }),
          weight: 1,
        },
      ],
      grid: [
        [
          localize({ message: 'Rubric.Presentation.Group.Crit1.L1' }),
          localize({ message: 'Rubric.Presentation.Group.Crit1.L2' }),
          localize({ message: 'Rubric.Presentation.Group.Crit1.L3' }),
          localize({ message: 'Rubric.Presentation.Group.Crit1.L4' }),
        ],
        [
          localize({ message: 'Rubric.Presentation.Group.Crit2.L1' }),
          localize({ message: 'Rubric.Presentation.Group.Crit2.L2' }),
          localize({ message: 'Rubric.Presentation.Group.Crit2.L3' }),
          localize({ message: 'Rubric.Presentation.Group.Crit2.L4' }),
        ],
        [
          localize({ message: 'Rubric.Presentation.Group.Crit3.L1' }),
          localize({ message: 'Rubric.Presentation.Group.Crit3.L2' }),
          localize({ message: 'Rubric.Presentation.Group.Crit3.L3' }),
          localize({ message: 'Rubric.Presentation.Group.Crit3.L4' }),
        ],
      ],
      passLevel,
      passCriteriaRule,
    },
  },
  [GOAL_TYPES.INDIVIDUAL_PRESENTATION]: {
    type: 'question',
    objective: `<p>${localize({ message: 'Rubric.Presentation.Individual.Objective' })}</p>`,
    instructions: `<p>${localize({ message: 'Rubric.Presentation.Individual.Instructions' })}</p>`,
    rubric: {
      levels,
      criteria: [
        {
          name: localize({ message: 'Rubric.Presentation.Individual.Crit1' }),
          weight: 1,
        },
        {
          name: localize({ message: 'Rubric.Presentation.Individual.Crit2' }),
          weight: 1,
        },
        {
          name: localize({ message: 'Rubric.Presentation.Individual.Crit3' }),
          weight: 1,
        },
      ],
      grid: [
        [
          localize({ message: 'Rubric.Presentation.Individual.Crit1.L1' }),
          localize({ message: 'Rubric.Presentation.Individual.Crit1.L2' }),
          localize({ message: 'Rubric.Presentation.Individual.Crit1.L3' }),
          localize({ message: 'Rubric.Presentation.Individual.Crit1.L4' }),
        ],
        [
          localize({ message: 'Rubric.Presentation.Individual.Crit2.L1' }),
          localize({ message: 'Rubric.Presentation.Individual.Crit2.L2' }),
          localize({ message: 'Rubric.Presentation.Individual.Crit2.L3' }),
          localize({ message: 'Rubric.Presentation.Individual.Crit2.L4' }),
        ],
        [
          localize({ message: 'Rubric.Presentation.Individual.Crit3.L1' }),
          localize({ message: 'Rubric.Presentation.Individual.Crit3.L2' }),
          localize({ message: 'Rubric.Presentation.Individual.Crit3.L3' }),
          localize({ message: 'Rubric.Presentation.Individual.Crit3.L4' }),
        ],
      ],
      passLevel,
      passCriteriaRule,
    },
  },
};
