import React, { useState } from 'react';
import { Button } from 'components/buttons';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { PlusCircle, MinusCircle } from 'images/icons/fa';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const StyledMenuItem = (props: any) => {
  return (
    <MenuItem onClick={props.onClick} className="rubric-cell-menu-item">
      <div className="rubric-cell-menu-item__icon" data-testid={props.testid}>
        {props.icon}
      </div>
      {props.label}
    </MenuItem>
  );
};

const ModifyRubricButton = (props: any) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const openPopper = (e: any) => {
    return setAnchorEl(e.currentTarget);
  };

  const isOpen = () => {
    return !!anchorEl;
  };

  return (
    <div>
      <Button onClick={openPopper} type="hidden">
        <PlusCircle className="rubric-cell-menu__btn" />
      </Button>
      <Popper
        anchorEl={anchorEl}
        open={isOpen()}
        placement={props.isModifyCriteria ? 'bottom' : 'right'}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => {
          return (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={handleClickAway}
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                >
                  <MenuList autoFocusItem={isOpen()} id="menu-list-grow">
                    <StyledMenuItem
                      onClick={props.handleAdd}
                      icon={<PlusCircle />}
                      label={`Add ${props.isModifyCriteria ? 'Criteria' : 'new higher level'}`}
                      testid={props.isModifyCriteria ? 'add-criteria' : 'add-higher-level'}
                    />
                    <StyledMenuItem
                      onClick={props.handleRemove}
                      icon={<MinusCircle />}
                      label={`Remove ${props.isModifyCriteria ? 'Criteria' : 'the highest level'}`}
                      testid={props.isModifyCriteria ? 'remove-criteria' : 'remove-highest-level'}
                    />
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          );
        }}
      </Popper>
    </div>
  );
};

ModifyRubricButton.defaultProps = {
  isModifyCriteria: false,
};

export default ModifyRubricButton;
