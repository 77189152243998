import React from 'react';
import createSvgIcon from '../util/generateIconSVG';

export default function GridIcon(props: any) {
  const Comp = createSvgIcon(
    <path
      d="M12 16H16V12H12V16ZM12 10H16V6H12V10ZM6 4H10V0H6V4ZM12 4H16V0H12V4ZM6 10H10V6H6V10ZM0 10H4V6H0V10ZM0 16H4V12H0V16ZM6 16H10V12H6V16ZM0 4H4V0H0V4Z"
      fill="#DCDBF0"
    />,
    '0 0 16 16'
  );
  return <Comp {...props} />;
}
