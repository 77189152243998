import { forwardRef } from 'react';
import maxBy from 'lodash/maxBy';
import GroupCard from 'components/GroupManager/GroupCard';
import { PanelDisplay } from 'components/layout';
import { searchGroupList } from '@kritik/utils/filter';
import AddGroupCard from './AddGroupCard';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeGrid as Grid } from 'react-window';

const ADD_GROUP_CARD = 'ADD_GROUP_CARD';

export function Groups({ isGroupSetInUse, searchParam, selectedGroups, readOnly, gridRef }) {
  const filterGroups = () => {
    if (!searchParam) {
      return selectedGroups;
    }

    return searchGroupList(selectedGroups, searchParam);
  };

  const getRowHeight = () => {
    const GROUP_MEMBER_HEIGHT = 68;
    const PADDING_HEIGHT = 120;
    const groupWithMaxMember = maxBy(filteredGroups, (group) => {
      return (group as any).members.length;
    });
    let maxNumber = groupWithMaxMember ? (groupWithMaxMember as any).members.length : 1;
    maxNumber = Math.max(maxNumber, 1);
    return maxNumber * GROUP_MEMBER_HEIGHT + PADDING_HEIGHT;
  };

  const filteredGroups = filterGroups();

  return (
    <div className="group-set-wrapper">
      <AutoSizer disableHeight>
        {({ width }: any) => {
          // ADD_GROUP_CARD value is a placeholder for rendering add group card
          const itemsToRender = searchParam ? filteredGroups : [...filteredGroups, ADD_GROUP_CARD];

          let columnCount = 3;
          if (width < 290 * 2) {
            columnCount = 1;
          } else if (width < 290 * 3) {
            columnCount = 2;
          }

          const rowCount = Math.ceil(itemsToRender.length / columnCount);

          const itemData = {
            selectedGroups: itemsToRender,
            isGroupSetInUse,
            columnCount,
            readOnly,
            groups: selectedGroups,
          };

          return (
            <Grid
              ref={gridRef}
              height={window.innerHeight * 0.5}
              width={width}
              columnCount={columnCount}
              columnWidth={() => {
                return width / columnCount;
              }}
              rowCount={rowCount}
              rowHeight={getRowHeight}
              itemData={itemData}
              innerElementType={innerElementType}
            >
              {Cell}
            </Grid>
          );
        }}
      </AutoSizer>
    </div>
  );
}

const Cell = ({ columnIndex, rowIndex, style, data }: any) => {
  const { isGroupSetInUse, columnCount, selectedGroups, readOnly, groups } = data;
  const arrIdx = rowIndex * columnCount + columnIndex;
  const group = selectedGroups[arrIdx];
  const _style = {
    ...style,
    left: style.left,
    top: style.top,
    width: style.width,
    height: style.height,
  };
  if (group === ADD_GROUP_CARD) {
    return (
      <GroupCardWrapper style={style} key={arrIdx}>
        {!readOnly && <AddGroupCard />}
      </GroupCardWrapper>
    );
  }
  if (!group) {
    return null;
  }

  return (
    <GroupCardWrapper style={_style} key={group._id}>
      <PanelDisplay>
        <GroupCard
          group={group}
          groups={groups}
          isGroupSetInUse={isGroupSetInUse}
          testid={`group-${arrIdx}`}
          readOnly={readOnly}
        />
      </PanelDisplay>
    </GroupCardWrapper>
  );
};

// @ts-expect-error TS(2339) FIXME: Property 'style' does not exist on type '{}'.
const innerElementType = forwardRef(({ style, ...rest }, ref) => {
  return (
    <div
      // @ts-expect-error TS(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
      ref={ref}
      style={{
        ...style,
      }}
      {...rest}
    />
  );
});

function GroupCardWrapper({ children, style }: any) {
  return (
    <div className="group-manager__item" style={style}>
      {children}
    </div>
  );
}
