import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment-timezone';

const timeZoneOptionsToBeFilteredOut = ['etc/'];
const TIME_ZONE_OPTIONS = moment.tz.names().filter((zone) => {
  return !timeZoneOptionsToBeFilteredOut.some((option) => zone.toLowerCase().includes(option));
});

type SearchTimeZoneProps = {
  timeZone: any;
  setTimeZone: any;
  disabled?: boolean;
};

function SearchTimeZone({ timeZone, setTimeZone, disabled }: SearchTimeZoneProps) {
  return (
    <React.Fragment>
      <div style={{ marginTop: '20px' }} />
      <Autocomplete
        value={timeZone ? (timeZone.value ? timeZone.value : timeZone.suggestion) : null}
        options={TIME_ZONE_OPTIONS}
        renderInput={(params) => {
          return <TextField {...params} label="Time Zone" variant="outlined" />;
        }}
        onChange={setTimeZone}
        disabled={disabled}
        disableClearable
      />
    </React.Fragment>
  );
}

export { SearchTimeZone };
