export const RANK_NAMES = {
  0: 'Beginner',
  1: 'Beginner 2',
  2: 'Accomplished',
  3: 'Accomplished 2',
  4: 'Scholar',
  5: 'Virtuoso',
};

export const RANK_DESCRIPTIONS = {
  0: "Very Low Impact: How much impact this student's scoring will have on a peers final grade.",
  1: "Low Impact: How much impact this student's scoring will have on a peers final grade.",
  2: "Medium Impact: How much impact this student's scoring will have on a peers final grade.",
  3: "High Impact:  How much impact this student's scoring will have on a peers final grade.",
  4: "Very High Impact: How much impact this student's scoring will have on a peers final grade.",
  5: "Highest Impact: How much impact this student's scoring will have on a peers final grade.",
};
