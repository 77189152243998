import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import PlagiarismService from 'services/plagiarism';

export function useFetchPlagiarismProbability(creationId: string) {
  return useQuery(
    ['getPlagarism', creationId],
    async () => {
      const response = await PlagiarismService().getCreationPlagiarism({ creationId });
      return response.data;
    },
    {
      enabled: Boolean(creationId),
    }
  );
}

export function useResolveCreationSimilarityReport() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { creationId: string; note: string }) =>
      PlagiarismService().resolveCreationSimilarityReport(data),
    onSuccess: () => queryClient.invalidateQueries(['getCreationSimilarityReportResolutionStatus']),
  });
}

export function useUnresolveCreationSimilarityReport() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (creationId: string) =>
      PlagiarismService().unresolveCreationSimilarityReport(creationId),
    onSuccess: () => queryClient.invalidateQueries(['getCreationSimilarityReportResolutionStatus']),
  });
}
