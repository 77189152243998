import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import { Link } from 'react-router-dom';
import { manualLogin } from 'actions/users';
import Button from 'components/buttons/Button';
import {
  SignupContainer,
  SignupMainTitle,
  SignupContent,
  SignupText,
  SignupSecondaryTitle,
} from 'components/AuthWrapper';
import { Form, FormButtons, FormMainError } from 'components/Form';
import FormPassword from 'components/core/form/PasswordField';
import { MANUAL_LOGIN_USER, RESET_PASSWORD } from 'types';
import RegionPicker from 'components/General/RegionPicker';
import FormFieldLabel from 'components/core/form/FieldLabel';
import FormField from 'components/core/form/Field';
import * as RegionUtils from 'utils/region';
import SSOActivation from 'components/auth/SSOActivation';
import SignUpOrSignInStep1 from 'components/auth/SignUpOrSignInStep1';
import SelectField from 'components/core/form/SelectField';
import { TranslatedText } from 'components/TranslatedText';

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    loginBusy: state.async[MANUAL_LOGIN_USER].busy,
    loginSuccess: state.async[MANUAL_LOGIN_USER].success,
    loginError: state.async[MANUAL_LOGIN_USER].error,
    isUpdatePasswordSuccess: state.async[RESET_PASSWORD].success,
  };
};

type OwnLoginState = any;

type LoginState = OwnLoginState & typeof Login.defaultProps;

type LoginProps = {
  prefillEmail?: string;
  loginBusy?: boolean;
  loginError?: boolean;
  isUpdatePasswordSuccess?: boolean;
  manualLogin?: ({ email, password }: { email: string; password: string }) => void;
};

class Login extends Component<LoginProps, LoginState> {
  static defaultProps = {
    prefillEmail: null,
    loginBusy: false,
    loginError: false,
    isUpdatePasswordSuccess: false,
  };

  constructor(props: {}) {
    super(props);
    this.state = {
      password: '',
      email: '',
      errorMessage: null,
      isLMSUser: null,
      isEmailEditable: true,
      isLtiAdvantageUser: false,
    };
  }

  componentDidMount() {
    if (this.props.prefillEmail) {
      this.setState({ email: this.props.prefillEmail });
    }
  }

  componentDidUpdate(prevProps: LoginProps) {
    if (prevProps.loginError !== this.props.loginError) {
      this.setState({ errorMessage: this.props.loginError });
    }
    if (prevProps.prefillEmail !== this.props.prefillEmail) {
      this.setState({ email: this.props.prefillEmail });
    }
  }

  handleNextStep(isSSOUser: any, userEmail: any, isLtiAdvantageUser: any) {
    if (isSSOUser) {
      this.setState({ isLMSUser: true, email: userEmail, isLtiAdvantageUser });
    } else {
      this.setState({ isLMSUser: false, email: userEmail, isEmailEditable: false });
    }
  }

  onSubmit() {
    const region = RegionUtils.getRegion();
    if (!region) {
      return this.setState({
        errorMessage: 'Please select a region.',
      });
    }
    this.props.manualLogin({
      email: this.state.email,
      password: this.state.password,
    });
  }

  handleInputChange = (e: any) => {
    const { target } = e;
    const { value } = target;
    const { name } = target;

    if (this.state.errorMessage) {
      this.setState({ errorMessage: null });
    }

    this.setState({
      [name]: value,
    });
  };

  renderSignupMainTitle = () => {
    if (this.props.isUpdatePasswordSuccess) {
      return <SignupMainTitle>Your password has been updated.</SignupMainTitle>;
    }
    return <SignupMainTitle>Welcome back, we’ve missed you.</SignupMainTitle>;
  };

  redirectToRegion = (e: any) => {
    const region = e.target.value;
    this.setState({ region });
    RegionUtils.redirectToRegion({ region, path: '/login' });
  };

  handleEmailChange = () => {
    this.setState({ isLMSUser: null });
  };

  renderLogin() {
    const options = [
      { value: 'email', label: this.state.email },
      { value: 'change-email', label: 'Change email' },
    ];
    return (
      <SignupContent>
        {this.renderSignupMainTitle()}
        <SignupSecondaryTitle size="xl"> Sign-in </SignupSecondaryTitle>
        <FormField>
          <FormFieldLabel label="Region" color="light" id="region-select-field" />
          <RegionPicker onChange={this.redirectToRegion} value={RegionUtils.getRegion()} />
        </FormField>
        <Form>
          <SelectField
            options={options}
            value="email"
            onChange={this.handleEmailChange}
            menuLabel={<TranslatedText i18nKey="Login.SelectEmail" />}
          />
          <FormFieldLabel
            label={
              <div>
                Password (
                <Link
                  className="underlined-link"
                  to={`/forgot?email=${encodeURIComponent(this.state.email)}`}
                >
                  forgot password?
                </Link>
                )
              </div>
            }
          />
          <FormPassword
            aria-label="Password"
            testid="password"
            name="password"
            value={this.state.password}
            onChange={this.handleInputChange}
            autoFocus
            className="login__password-field"
          />
          <FormButtons>
            <Button
              data-testid="signin"
              type="primary"
              onClick={() => {
                return this.onSubmit();
              }}
              loading={this.props.loginBusy}
              disabled={this.props.loginBusy}
              inputType="submit"
            >
              Sign-in
            </Button>
          </FormButtons>
          <FormMainError message={this.state.errorMessage} isOpen={this.state.errorMessage} />
        </Form>
        <SignupText color="dark">
          Don't have an account?{' '}
          <Link className="underlined-link" to="/signup">
            Sign-up
          </Link>
        </SignupText>
      </SignupContent>
    );
  }

  render() {
    if (this.state.isLMSUser === true) {
      return (
        <SSOActivation
          isSignIn
          emailToSignIn={this.state.email}
          isLtiAdvantageUser={this.state.isLtiAdvantageUser}
        />
      );
    }
    if (this.state.isLMSUser === false) {
      return <SignupContainer>{this.renderLogin()}</SignupContainer>;
    }
    return (
      <SignUpOrSignInStep1
        isSignIn
        onNextStep={(isLMSUser: any, email: any, isLtiAdvantageUser: any) => {
          this.handleNextStep(isLMSUser, email, isLtiAdvantageUser);
        }}
        email={this.state.email}
      />
    );
  }
}

export default withRouter(connect(mapStateToProps, { manualLogin })(Login));
