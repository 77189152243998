import FormTextInput from 'components/core/form/TextField';
import { Field } from 'react-final-form';
import validators from 'components/ActivityEdit/Details/Fields/validators';
import { COURSE_FIELD_NAMES } from 'components/Course/Edit/constants';
import { localize } from 'locales/index';

const CourseCodeField = () => {
  return (
    <Field name={COURSE_FIELD_NAMES.CODE} type="text">
      {({ input, meta }) => {
        return (
          <div className="course-details__code-field">
            <FormTextInput
              label="Course Code"
              value={input.value}
              placeholder={localize({ message: 'Course.Create.CourseCodeField.Placeholder' })}
              onChange={input.onChange}
              onBlur={input.onBlur}
              error={meta.error && meta.touched && meta.error}
              testid="course-code"
              id="course-code"
            />
          </div>
        );
      }}
    </Field>
  );
};
export default CourseCodeField;
