import React, { useContext } from 'react';
import FileList from 'components/layout/AttachmentManager/FileList';
import InlineInformation from 'components/layout/InlineInformation';
import HtmlContent from 'components/General/HtmlContent';
import Button from 'components/buttons/Button';
import { CreationContext } from '../../../context/CreationContext';

const ShowInfoMessage = ({ files, changeShouldAddNewEval }: any) => {
  const { creation, setCreation } = useContext(CreationContext);

  const noContent = creation.content === '<p>&nbsp;</p>';
  const noFiles = files.length === 0;

  return (
    <React.Fragment>
      <div style={{ margin: '20px' }} />
      <InlineInformation
        title={
          noContent
            ? "This creation doesn't have any content"
            : 'This creation has the following content'
        }
      />
      <div style={{ margin: '5px 0 30px 0' }}>
        {!noContent && <HtmlContent content={creation.content.substr(0, 500)} />}
      </div>
      <InlineInformation
        title={
          noFiles ? "This creation doesn't have any files" : 'This creation has the following files'
        }
      />
      <div style={{ margin: '5px 0 30px 0' }}>{!noFiles && <FileList files={files} />}</div>
      <Button
        type="secondary"
        onClick={() => {
          return changeShouldAddNewEval(true);
        }}
      >
        Confirm Creation
      </Button>
    </React.Fragment>
  );
};

export default ShowInfoMessage;
