import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { TranslatedText } from 'components/TranslatedText';
import { Button } from 'components/buttons';

export const GracePeriodWarning = ({ isOpen, onConfirm, onCancel, timeLeft }) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <TranslatedText i18nKey="Evaluation.GracePeriod.Warning.Title" />
      </DialogTitle>
      <DialogContent>
        <TranslatedText i18nKey="Evaluation.GracePeriod.Warning.Content1" />
      </DialogContent>
      <DialogContent id="alert-dialog-description" style={{ color: 'red' }}>
        <TranslatedText i18nKey="Evaluation.GracePeriod.Warning.Content2" values={{ timeLeft }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} type="primary" autoFocus>
          <TranslatedText i18nKey="Evaluation.GracePeriod.Warning.EditEvaluation" />
        </Button>
        <Button onClick={onCancel} type="secondary" autoFocus>
          <TranslatedText i18nKey="Cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
