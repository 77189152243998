import React from 'react';
import { Dialog } from '@material-ui/core';

const Modal = (props: any) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={props.maxWidth}
      fullWidth={props.isFullWidth}
      onClick={props.onClick}
    >
      {props.children}
    </Dialog>
  );
};

Modal.defaultProps = {
  maxWidth: null,
  isFullWidth: false,
};

export default Modal;
