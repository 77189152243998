import NoCourseContainer from 'containers/NoCourse';
import Page from 'pages/Page';

function NoCourse() {
  return (
    <Page title="No Course" meta={[]} link={[]} script={[]}>
      <NoCourseContainer />
    </Page>
  );
}

export default NoCourse;
