import InfoCircle from 'images/icons/fa/InfoCircle';

type FormFieldErrorProps = {
  error?: string;
  id?: string;
};

const FormFieldError = ({ error, id }: FormFieldErrorProps) => {
  if (!error) {
    return null;
  }
  return (
    <span className="kritik-form-field__error" role="alert" aria-live="assertive" id={id}>
      <InfoCircle />
      <span className="kritik-form-field__error-msg">{error}</span>
    </span>
  );
};

FormFieldError.defaultProps = {
  error: null,
};

export default FormFieldError;
