const GET_ASSIGNMENT = 'GET_ASSIGNMENT';
const GET_ASSIGNMENT_SUCCESS = 'GET_ASSIGNMENT_SUCCESS';
const GET_ASSIGNMENT_FAILURE = 'GET_ASSIGNMENT_FAILURE';

const FINALIZE_ASSIGNMENT_REQUEST = 'FINALIZE_ASSIGNMENT_REQUEST';
const FINALIZE_ASSIGNMENT_SUCCESS = 'FINALIZE_ASSIGNMENT_SUCCESS';
const FINALIZE_ASSIGNMENT_FAILURE = 'FINALIZE_ASSIGNMENT_FAILURE';

export default {
  GET_ASSIGNMENT,
  GET_ASSIGNMENT_SUCCESS,
  GET_ASSIGNMENT_FAILURE,
  FINALIZE_ASSIGNMENT_REQUEST,
  FINALIZE_ASSIGNMENT_SUCCESS,
  FINALIZE_ASSIGNMENT_FAILURE,
};
