function GridContainer(props: any) {
  if (!props.nodes || props.nodes.length === 0) {
    return null;
  }
  const renderNodes = () => {
    return props.nodes.map((node: any) => {
      return node;
    });
  };
  const classname = props.hasMinimumHeight
    ? `grid-container row-height-control ${props.className}`
    : `grid-container ${props.className}`;
  return <div className={classname}>{renderNodes()}</div>;
}

export default GridContainer;
