import { connect } from 'react-redux';

const NoInstitutionWarning = (props: any) => {
  if (
    !props.user.authenticated ||
    props.user?.authUser?.institution ||
    !props.user?.authUser?.permissions.includes('canCreateCourse')
  ) {
    return null;
  }

  return (
    <div className="no-institution-warning-container">
      <i className="fa fa-info-circle" />
      <div className="no-institution-warning">
        <h6 className="no-institution-warning__title">There's an issue with your account</h6>
        You haven't specified which institution you belong to. Some functions in the system will not
        work properly. Please contact Kritik Support right away via Live Chat at the bottom right
        corner of your screen to update your account information.
      </div>
    </div>
  );
};

export default connect((state: any) => ({
  user: state.user,
}))(NoInstitutionWarning);
