import { useState } from 'react';
import { connect } from 'react-redux';
import WeightMenu from 'components/Assignment/List/WeightEditor';
import { selectAuthUser } from 'selectors/user';
import PencilIcon from '@material-ui/icons/Create';
import Button from 'components/buttons/Button';
import PropagationInterceptor from 'components/Utility/PropagationInterceptor';
import { localize } from 'locales/index';
import { useUserRoleInCourse } from 'hooks/course';

const WeightCell = (props: any) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { isInstructorInCourse } = useUserRoleInCourse();

  const setIsEditingWeight = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const unsetIsEditingWeight = () => {
    setAnchorEl(null);
  };

  const renderWeight = () => {
    const { weight } = props.activity;
    if (!isInstructorInCourse) {
      return (
        <span style={{ marginRight: '18px' }} tabIndex={0}>
          {weight} {weight === 1 ? 'pt' : 'pts'}
        </span>
      );
    }
    return (
      <PropagationInterceptor>
        <Button
          type="hidden"
          className="activity-list__weight-btn"
          onClick={setIsEditingWeight}
          aria-label={localize({ message: 'Button.Label.EditWeight' })}
        >
          <span>
            {weight} {weight === 1 ? 'pt' : 'pts'}
          </span>
          <PencilIcon className="activity-list__weight-btn-icon" />
        </Button>
        <WeightMenu
          activity={props.activity}
          anchorEl={anchorEl}
          isOpen={anchorEl}
          onCancel={() => {
            return unsetIsEditingWeight();
          }}
        />
      </PropagationInterceptor>
    );
  };

  return renderWeight();
};

export default WeightCell;
