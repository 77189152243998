import React from 'react';
import classNames from 'classnames';

const HorizontalItem = ({ children }: any) => {
  return <div className="horizontal-list__item">{children}</div>;
};

const HorizontalList = (props: any) => {
  const classList = classNames('horizontal-list', { left: props.align === 'left' });
  return (
    <div className="horizontal-list-wrapper">
      <div className={classList}>
        {props.itemList.map((item: any, index: number) => {
          return <HorizontalItem key={`${item}-${index}`}>{item}</HorizontalItem>;
        })}
      </div>
    </div>
  );
};

HorizontalList.defaultProps = {
  itemList: [],
  align: '',
};

export default HorizontalList;
