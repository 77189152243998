import React, { useEffect, useState } from 'react';
import { withRouter } from 'utils/withRouter';
import { connect } from 'react-redux';
import { getAssignment, getAssignmentList } from 'selectors/activity';
import FormTitle from 'components/core/form/Title';
import FormField from 'components/core/form/Field';
import FormFieldLabel from 'components/core/form/FieldLabel';
import FormContainer from 'components/core/form/Container';
import FormSection from 'components/core/form/Section';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import { Form, Field, FormSpy } from 'react-final-form';
import RubricPreview from 'components/ActivityEdit/Rubric/Preview';
import { ACTIVITY_GOALS, ACTIVITY_TEMPLATES } from 'components/ActivityEdit/Details/constants';
import AttachmentManager from 'components/layout/AttachmentManager';
import TitleField from 'components/ActivityEdit/Details/Fields/Title';
import { FIELD_NAME as TITLE } from 'components/ActivityEdit/Details/Fields/Title/constants';
import ObjectiveField from 'components/ActivityEdit/Details/Fields/Objective';
import { FIELD_NAME as OBJECTIVE } from 'components/ActivityEdit/Details/Fields/Objective/constants';
import InstructionsField from 'components/ActivityEdit/Details/Fields/Instructions';
import { FIELD_NAME as INSTRUCTIONS } from 'components/ActivityEdit/Details/Fields/Instructions/constants';
import {
  FIELD_NAME as RUBRIC,
  FIELD_LABEL as RUBRIC_LABEL,
} from 'components/ActivityEdit/Details/Rubric/constants';
import { Button } from 'components/buttons';
import NoticeBoard from 'components/layout/NoticeBoard';
import AdvancedOptions from 'components/ActivityEdit/AdvancedOptions';
import GroupOptions from 'components/ActivityEdit/GroupOptions';
import {
  ACTIVITY_TYPES,
  DEFAULT_WEIGHT_WITHIN_GROUP,
  NOTES_PLACEHOLDER,
} from '@kritik/constants/activity';
import { getAllGroupSets } from 'actions/groups';
import { getAllGroupSetList, getGroupSetList } from 'selectors/group';
import { getInitialAdvOptionValues } from 'components/ActivityEdit/AdvancedOptions/utils';
import { selectRubrics } from 'selectors/rubric';
import setFieldData from 'final-form-set-field-data';
import { isGroupSetEdited } from 'utils/groupSet';
import * as courseUtil from '@kritik/utils/course';
import { updateAssignment } from 'actions/activity';
import localUtil from 'components/ActivityEdit/util';
import ErrorMsg from 'components/ActivityEdit/ErrorMsg';
import { isScheduled, isDraft, isCreate } from '@kritik/utils/stage';
import {
  hasEvaluatorNotes,
  getEvaluatorNotes,
  getEvaluatorNotesFiles,
  isNumericGrading,
  acceptsLateSubmissions,
  autoAcceptsLateSubmissions,
} from '@kritik/utils/activity';
import {
  HAS_EVALUATOR_NOTES,
  EVALUATOR_NOTES_FILES,
  FIELD_NAME as EVALUATOR_NOTES,
} from 'components/ActivityEdit/Details/Fields/Notes/constants';
import NotesField from 'components/ActivityEdit/Details/Fields/Notes';
import {
  ACCEPT_LATE_CREATIONS,
  AUTO_ACCEPT_LATE_CREATIONS,
  ACCEPT_LATE_EVALUATIONS,
  AUTO_ACCEPT_LATE_EVALUATIONS,
} from 'components/LateCreation/constants';
import { getFormattedFormValues, getActivityGroupSet } from './utils';
import { useFetchCourse, useUserRoleInCourse } from 'hooks/course';
import { localize } from 'locales';
import { FIELD_NAME as removeStudentFromActivityAfterMissedCreation } from '../AdvancedOptions/Fields/MissedCreation/constants';

const Group = (props: any) => {
  const { isStudentInCourse } = useUserRoleInCourse();

  useEffect(() => {
    props.changeActivityType(props.values.activityType);
  }, [props.values.activityType]);

  const onSelectRubric = (rubric: any) => {
    props.form.mutators.setValue(RUBRIC, rubric);
  };
  const canEditRubric = () => {
    if (props.values.settings.isDuplicating) {
      return true;
    }
    const { activity } = props;
    const isValidStatus =
      !isScheduled(activity) ||
      isDraft({ assignment: activity }) ||
      isCreate({ assignment: activity });

    return isValidStatus;
  };
  const renderWithinGroupInfo = () => {
    if (props.values.activityType === ACTIVITY_TYPES.WITHIN_GROUP) {
      return (
        <NoticeBoard
          title="How a Within Group Activity Works"
          data-testid="how-within-group-activity-work-notice-board"
        >
          Each group member submits a Creation explaining their contribution to the group and group
          activities they completed together. During the Evaluate Stage, group members evaluate each
          other using the rubric.
        </NoticeBoard>
      );
    }
  };

  return (
    <FormContainer>
      <FormTitle label="Activity Details" size="xl" />
      {renderWithinGroupInfo()}
      <TitleField />
      <ObjectiveField />
      <InstructionsField />
      <Field name="files">
        {({ input }) => {
          return (
            <FormField>
              <FormFieldLabel label="Resources" />
              <AttachmentManager
                onFileChange={input.onChange}
                fileList={input.value ? input.value : []}
              />
            </FormField>
          );
        }}
      </Field>
      <FormSection>
        <FormTitle label="Evaluator Notes" size="xl" />
        <NotesField />
      </FormSection>
      <FormSection>
        <FormTitle label={RUBRIC_LABEL} size="xl" />
        <RubricPreview
          rubric={props.values[RUBRIC]}
          canEditRubric={canEditRubric()}
          isNumericGrading={props.values.isNumericGrading}
          onSelectRubric={onSelectRubric}
          canViewMode={!isStudentInCourse}
          form={props.form}
        />
      </FormSection>
    </FormContainer>
  );
};

const FormWrapper = (props: any) => {
  const [currentActivityType, setCurrentActivityType] = useState(null);
  const [loading, setLoading] = useState(true);
  const { data: course } = useFetchCourse(props.router.params.courseId);

  useEffect(() => {
    const getGroupSets = async () => {
      await props.getAllGroupSets({ courseId: props.router.params.courseId });
      setLoading(false);
    };
    void getGroupSets();
  }, []);

  const WarningEngine = ({ mutators: { setFieldData } }: any) => {
    return (
      <FormSpy
        subscription={{ values: true }}
        onChange={({ values }) => {
          // Warning if group set has been changed from last activity for within group
          setFieldData('selectedGroupSet', {
            warning:
              props.groupSetList.length > 0 &&
              values.activityType === ACTIVITY_TYPES.WITHIN_GROUP &&
              isGroupSetEdited(
                props.groupSetList.find((groupSet) => groupSet._id === values.selectedGroupSet),
                props.activities
              )
                ? true
                : undefined,
          });
        }}
      />
    );
  };

  const getInitialActivityType = () => {
    if (props.activity.activityType === ACTIVITY_TYPES.WITHIN_GROUP) {
      return ACTIVITY_TYPES.WITHIN_GROUP;
    }
    return ACTIVITY_TYPES.GROUP;
  };

  const getInitialFormValues = () => {
    let values = {};
    if (props.isEdit || props.isDuplicating) {
      values = {
        activityType: getInitialActivityType(),
        [TITLE]: props.activity.title,
        [RUBRIC]: props.activity.rubric,
        userRubrics: props.userRubrics,
        isNumericGrading: isNumericGrading(props.activity),
        [OBJECTIVE]: props.activityObjective || props.activity.objective,
        [INSTRUCTIONS]: props.activityInstructions || props.activity.instructions,
        files: props.activity.files,
        selectedGroupSet: getActivityGroupSet(props.groupSetList, props.activity),
        selfEvaluate: props.activity.selfEvaluate,
        [HAS_EVALUATOR_NOTES]: hasEvaluatorNotes(props.activity),
        [EVALUATOR_NOTES]: getEvaluatorNotes(props.activity),
        [EVALUATOR_NOTES_FILES]: getEvaluatorNotesFiles(props.activity),
        [ACCEPT_LATE_CREATIONS]: acceptsLateSubmissions(props.activity),
        [AUTO_ACCEPT_LATE_CREATIONS]: autoAcceptsLateSubmissions(props.activity),
        [ACCEPT_LATE_EVALUATIONS]: Boolean(props.activity.acceptLateEvaluations),
        [AUTO_ACCEPT_LATE_EVALUATIONS]: Boolean(props.activity.autoAcceptLateEvaluations),
        [removeStudentFromActivityAfterMissedCreation]:
          props.activity.removeStudentFromActivityAfterMissedCreation,
      };
    } else if (currentActivityType === ACTIVITY_TYPES.WITHIN_GROUP) {
      const template = ACTIVITY_TEMPLATES.groupAssessment;
      values = {
        activityType: ACTIVITY_TYPES.WITHIN_GROUP,
        [TITLE]: '',
        [RUBRIC]: template[RUBRIC],
        userRubrics: props.userRubrics,
        [OBJECTIVE]: props.activityObjective || template[OBJECTIVE],
        [INSTRUCTIONS]: props.activityInsturctions || template[INSTRUCTIONS],
        files: [],
        isNumericGrading: true,
        selectedGroupSet: null,
        weight: DEFAULT_WEIGHT_WITHIN_GROUP,
        selfEvaluate: true,
        [HAS_EVALUATOR_NOTES]: false,
        [EVALUATOR_NOTES_FILES]: [],
        [EVALUATOR_NOTES]: NOTES_PLACEHOLDER,
        [ACCEPT_LATE_CREATIONS]: courseUtil.acceptsLateSubmissions(course),
        [AUTO_ACCEPT_LATE_CREATIONS]: courseUtil.autoAcceptsLateSubmissions(course),
        [ACCEPT_LATE_EVALUATIONS]: Boolean(course.acceptLateEvaluations),
        [AUTO_ACCEPT_LATE_EVALUATIONS]: Boolean(course.autoAcceptLateEvaluations),
      };
    } else {
      const activityGoal = ACTIVITY_GOALS[0].value;
      const template = ACTIVITY_TEMPLATES[activityGoal];
      values = {
        activityType: ACTIVITY_TYPES.GROUP,
        [TITLE]: '',
        [RUBRIC]: null,
        userRubrics: props.userRubrics,
        [OBJECTIVE]: props.activityObjective || template[OBJECTIVE],
        [INSTRUCTIONS]: props.activityInstructions || template[INSTRUCTIONS],
        files: [],
        isNumericGrading: true,
        selectedGroupSet: null,
        [HAS_EVALUATOR_NOTES]: false,
        [EVALUATOR_NOTES_FILES]: [],
        [EVALUATOR_NOTES]: NOTES_PLACEHOLDER,
        [ACCEPT_LATE_CREATIONS]: courseUtil.acceptsLateSubmissions(course),
        [AUTO_ACCEPT_LATE_CREATIONS]: courseUtil.autoAcceptsLateSubmissions(course),
        [ACCEPT_LATE_EVALUATIONS]: Boolean(course.acceptLateEvaluations),
        [AUTO_ACCEPT_LATE_EVALUATIONS]: Boolean(course.autoAcceptLateEvaluations),
        [removeStudentFromActivityAfterMissedCreation]: false,
      };
    }
    const advancedOptions = getInitialAdvOptionValues(props.activity, course);
    return {
      ...advancedOptions,
      ...values,
      settings: {
        isDuplicating: props.isDuplicating,
        isEdit: props.isEdit,
        minimumWordCountForEvaluations: props.activity
          ? props.activity.settings?.minimumWordCountForEvaluations
          : course.courseSettings?.minimumWordCountForEvaluations,
      },
    };
  };

  const handleOnSubmit = (values: any) => {
    const data = getFormattedFormValues({ values, courseId: props.router.params.courseId });
    return props.onSubmit({
      data,
      formSettings: localUtil.getFormSettings(values),
      fileList: values.files,
    });
  };

  const [initialValues, setInitialValues] = useState(getInitialFormValues());

  useEffect(() => {
    setInitialValues(getInitialFormValues());
  }, [currentActivityType]);

  if (loading) {
    return null;
  }

  return (
    <Form
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      mutators={{
        // expect (field, value) args from the mutator
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => {
            return value;
          });
        },
        setFieldData,
      }}
      render={({ handleSubmit, form, submitting, values, submitError }) => {
        props.formRef.current = form;
        return (
          <form onSubmit={handleSubmit}>
            <GroupOptions
              form={form}
              values={values}
              isEdit={props.isEdit}
              isDuplicating={props.isDuplicating}
            />
            <React.Fragment>
              <Group
                form={form}
                values={values}
                course={props.course}
                activity={props.activity}
                changeActivityType={(type: any) => {
                  return setCurrentActivityType(type);
                }}
              />

              <AdvancedOptions
                course={course}
                form={form}
                values={values}
                activity={props.activity}
              />
            </React.Fragment>
            <FormSubmitButtons errors={<ErrorMsg />}>
              <Button
                type="primary"
                inputType="submit"
                loading={submitting}
                disabled={submitting}
                data-testid="save-activity"
              >
                Save Activity
              </Button>
              <Button type="secondary" onClick={props.onCancel}>
                Cancel
              </Button>
            </FormSubmitButtons>
            <WarningEngine mutators={form.mutators} />
          </form>
        );
      }}
    />
  );
};

const mapStateToProps = (state: any) => {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
    activityId: state.selected.assignmentId,
    groupSetList: getGroupSetList(state),
    allGroupSetList: getAllGroupSetList(state),
    userRubrics: selectRubrics(state),
    activities: getAssignmentList(state),
  };
};

export default withRouter(
  connect(mapStateToProps, { getAllGroupSets, updateAssignment })(FormWrapper)
);
