import { loadingBarMiddleware } from 'react-redux-loading-bar';

import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { responsiveStoreEnhancer } from 'redux-responsive';
import thunk from 'redux-thunk';

import createRootReducer from '../reducers';

/*
 * @param {Object} initial state to bootstrap our stores with for server-side rendering
 * @param {History Object} a history object. We use `createMemoryHistory` for server-side rendering,
 *                          while using browserHistory for client-side
 *                          rendering.
 */
export default function configureStore(initialState: any, history: any) {
  const rootReducer = createRootReducer();

  // Installs hooks that always keep react-router and redux store in sync
  const middleware = [
    thunk,
    (store: any) =>
      (next: any) =>
      (action: any, ...params) => {
        /**
         * this middleware ignores the action if it's undefined.
         * reason: we're not gonna write any more actions, so what's working will keep working.
         * However, when migrating some react-router-redux actions to react-router, we started to dispatch
         * `push` and `replace`, but they're not redux actions, they're just functions that call `history.push` and `history.replace`,
         * so they can't be processed by a middleware.
         */
        if (action === undefined) {
          return;
        }
        next(action, ...params);
      },
    loadingBarMiddleware({
      promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE'],
    }),
  ];
  let store;

  // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
  if (App.config.get('appEnv') === 'development') {
    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
    if (App.config.get('shouldShowDevReduxLogs')) {
      middleware.push(createLogger());
    }

    store = createStore(
      rootReducer,
      initialState,
      composeEnhancers(compose(responsiveStoreEnhancer, applyMiddleware(...middleware)))
    );
  } else {
    store = createStore(
      rootReducer,
      initialState,
      compose(responsiveStoreEnhancer, applyMiddleware(...middleware), (f: any) => {
        return f;
      })
    );
  }

  (window as any).store = store;
  return store;
}
