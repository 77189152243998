import React from 'react';

const EmptyPlaceholder = ({ image, title, children, ...props }: any) => {
  return (
    <div className="empty-placeholder" {...props}>
      <div className="empty-placeholder__image">
        <img aria-hidden="true" src={image} />
      </div>
      <div className="empty-placeholder__description">
        <h3> {title} </h3>
        {children}
      </div>
    </div>
  );
};

export default EmptyPlaceholder;
