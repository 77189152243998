import React from 'react';
import { connect } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import AvatarDisplay from 'components/layout/AvatarDisplay';
import { isAdmin } from 'utils/permission';
import CourseSelectors from 'selectors/course';
import StudentSelectors from 'selectors/student';
import { push } from 'router';
import { logOut } from 'actions/users';
import LogoutIcon from 'images/icons/fa/Logout';
import UserIcon from 'images/icons/fa/User';
import ChalkboardTeacherIcon from 'images/icons/fa/ChalkboardTeacher';
import WrenchIcon from 'images/icons/fa/Wrench';
import * as StudentUtils from 'utils/student';
import { useNavigate } from 'react-router';
import { localize } from 'locales';
import MenuTab from 'components/Navigation/MenuTab';
import { ConfirmModal } from 'components/modals';
import { usePresenterMode } from 'hooks/viewAs';
import { useUserRoleInCourse } from 'hooks/course';

function ProfileTab(props: any) {
  const { isStudentInCourse } = useUserRoleInCourse();
  const { isPresenterMode, startPresenterMode } = usePresenterMode(props?.course?._id);
  const [showPresenterModeConfirmationModal, setShowPresenterModeConfirmationModal] =
    React.useState(false);

  const push = useNavigate();

  if (!props.user.authenticated) {
    return null;
  }

  const getKritikScore = () => {
    return props.student ? StudentUtils.getNewKS(props.student) : 0;
  };

  const isInCourse = () => {
    return Boolean(props.course);
  };

  const canShowPresenterModeMenuItem = isInCourse() && !isStudentInCourse && !isPresenterMode;

  return (
    <li role="listitem" className="bottom-menu-item">
      <div id="account-menu">
        <MenuTab
          testid={`settings-menu-${props.position}`}
          position={props.position}
          label="Account Profile"
          aria-haspopup="menu"
          activeRegex={[/^\/settings\/(account|about)$/]}
          icon={
            <AvatarDisplay
              user={props.user.authUser}
              kritikScore={getKritikScore()}
              size="50px"
              hoverable={false}
              showBorder={isInCourse() && isStudentInCourse}
              renderInsideDiv
            />
          }
          content={[
            <MenuItem
              key={1}
              onClick={() => {
                push(`/settings/about`);
              }}
              data-testid="about-settings"
            >
              <div className="profile-pic-menu__item">
                <AvatarDisplay
                  user={props.user.authUser}
                  kritikScore={getKritikScore()}
                  size="50px"
                  style={{ marginLeft: '-7px' }}
                  hoverable={false}
                  showBorder={isInCourse()}
                />
                <div className="description">
                  <h4>{props.user.authUser.profile.name}</h4>
                </div>
              </div>
            </MenuItem>,
            <Divider key={2} />,
            canShowPresenterModeMenuItem && (
              <MenuItem
                key={3}
                className="profile-pic-menu__item"
                onClick={() => {
                  setShowPresenterModeConfirmationModal(true);
                }}
                data-testid="view-in-presenter-mode"
              >
                <ChalkboardTeacherIcon className="profile-pic-menu__icon" />
                {localize({ message: 'Navigation.PictureProfileMenu.ViewInPresenterMode' })}
              </MenuItem>
            ),
            <MenuItem
              key={3}
              className="profile-pic-menu__item"
              onClick={() => {
                push(`/settings/account`);
              }}
              data-testid="account-settings"
            >
              <UserIcon className="profile-pic-menu__icon" />
              {localize({ message: 'Navigation.PictureProfileMenu.AccountSettings' })}
            </MenuItem>,
            isAdmin(props.user.authUser) && (
              <MenuItem
                key={4}
                className="profile-pic-menu__item"
                onClick={() => {
                  push(`/admin`);
                }}
              >
                <WrenchIcon className="profile-pic-menu__icon" />
                {localize({ message: 'Navigation.PictureProfileMenu.Admin' })}
              </MenuItem>
            ),
            <MenuItem
              className="profile-pic-menu__item"
              onClick={() => {
                window.Intercom?.('shutdown');
                props.logOut({});
              }}
              key={5}
            >
              <LogoutIcon className="profile-pic-menu__icon" />
              {localize({ message: 'Navigation.PictureProfileMenu.Logout' })}
            </MenuItem>,
          ].filter(Boolean)}
        />
      </div>
      <ConfirmModal
        maxWidth="md"
        isOpen={showPresenterModeConfirmationModal}
        title={localize({ message: 'Navigation.ViewInPresenterModeModal.Title' })}
        description={
          <div>
            <p>
              {localize({
                message: 'Navigation.ViewInPresenterModeModal.Description.Paragraph1',
              })}
            </p>
            <br />
            <p>
              {localize({
                message: 'Navigation.ViewInPresenterModeModal.Description.Paragraph2',
              })}
            </p>
          </div>
        }
        confirmButton={localize({ message: 'Navigation.ViewInPresenterModeModal.Confirm' })}
        cancelButton={localize({ message: 'Cancel' })}
        onCancel={() => setShowPresenterModeConfirmationModal(false)}
        onConfirm={startPresenterMode}
        closeConfirmModal={() => setShowPresenterModeConfirmationModal(false)}
      />
    </li>
  );
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    course: CourseSelectors.getCourse(state),
    student: StudentSelectors.getStudentFromUser(state, state.selected.courseId),
  };
};

export default connect(mapStateToProps, { push, logOut })(ProfileTab);
