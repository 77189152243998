export const ROW_HEIGHT = 100;

export const LIST_BY_TYPES = {
  STUDENT: 'Student',
  GROUP: 'Group',
};

export const TABLE_TYPE_OPTIONS = {
  PROGRESS: 'Progress',
  SCORING: 'Scoring',
  DISPUTE: 'Dispute',
  SCORE_COMPARISON: 'Score Comparison',
} as const;

export const FILTERS = {
  SHOW_ALL: 'SHOW_ALL',
  MISSED_CREATIONS: 'MISSED_CREATIONS',
  LATE_CREATIONS: 'LATE_CREATIONS',
  OVERDUE_CREATIONS: 'OVERDUE_CREATIONS',
  NOT_GRADED: 'NOT_GRADED',
  DISPUTES: 'DISPUTES',
  FLAGS: 'FLAGS',
};

export const TABLE_HEADERS = {
  NAME: 'Name',
  CREATE: 'Create',
  EVALUATE: 'Evaluate',
  FEEDBACK: 'Feedback',
  ISSUES: 'Issues',
  OVERALL: 'Overall',
  EVALUATIONS_RECEIVED: 'Evaluations Received',
  EVALUATIONS_GIVEN: 'Evaluations Given',
  FEEDBACK_RECEIVED: 'Feedback Received',
  RESOLVED_DATE: 'Resolved Date',
  DISPUTED_DATE: 'Disputed Date',
  STATUS: 'Status',
};

export const EVALUATE_HEADER_LABEL = {
  GIVEN: 'Given',
  RECEIVED: 'Received',
};

export const SORT_OPTIONS = TABLE_HEADERS;

export default {
  ROW_HEIGHT,
  TABLE_TYPE_OPTIONS,
  SORT_OPTIONS,
  EVALUATE_HEADER_LABEL,
};
