import React, { ReactElement } from 'react';

type Props = {
  content: ReactElement;
  toggleNavigationBar: () => void;
};

function TopBar({ content, toggleNavigationBar }: Props) {
  return (
    <div className="TopBar">
      <button
        className="TopBar__toggle"
        onClick={toggleNavigationBar}
        aria-label="Navigation toggle button"
      >
        <i className="fas fa-bars"></i>
      </button>
      <ul className="TopBar__content">{content}</ul>
    </div>
  );
}

export default TopBar;
