import { useEffect } from 'react';

// taken from:
// https://github.com/Wootric/spa-examples/blob/master/react-example/src/Wootric.js
// and changed to functional component

function Wootric(props: any) {
  useEffect(() => {
    const { email, id, createdAt } = props.user;
    const timeStamp = Math.round(new Date(createdAt).getTime() / 1000);
    const setupScript = document.createElement('script');
    setupScript.type = 'text/javascript';
    setupScript.id = 'wootric-settings';
    setupScript.async = true;
    setupScript.innerHTML = `
      window.wootricSettings = {
        email: '${email}',
        created_at: ${timeStamp},
        external_id: '${id}',
        account_token: '${
          // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
          App.config.get('wootricAccountToken')
        }',
      };
    `;
    if (document.getElementById('wootric-settings') == null) {
      document.body.appendChild(setupScript);
    }

    // Beacon
    const beacon = document.createElement('script');
    beacon.type = 'text/javascript';
    beacon.id = 'wootric-beacon';
    beacon.async = true;

    beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';
    beacon.onload = function () {
      (window as any).wootric('run');
    };
    if (document.getElementById('wootric-beacon') == null) {
      document.body.appendChild(beacon);
    }
  }, []);

  return <div />;
}

export default Wootric;
