import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from 'components/buttons/Button';
import { localize } from 'locales/index';

function WarningModalLateSubmission({ handleOpen, handleClose, handleEdit, hoursLeft }: any) {
  return (
    <Dialog
      open={handleOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to edit your creation?
      </DialogTitle>
      <DialogContent id="alert-dialog-description">
        The creation stage is over. If you edit your creation now you will have limited time to
        resubmit.
      </DialogContent>
      <DialogContent id="alert-dialog-description" style={{ color: 'red' }}>
        You will have {hoursLeft} to resubmit and it will be marked as late.
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            return handleEdit();
          }}
          type="primary"
          autoFocus
          testid="confirm-edit-creation"
        >
          Edit Creation
        </Button>
        <Button
          onClick={() => {
            return handleClose();
          }}
          type="secondary"
          autoFocus
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WarningModalLateSubmission;
