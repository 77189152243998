import React from 'react';
import RadioInput from 'components/core/input/Radio';
import FormTitle from 'components/core/form/Title';
import FormFieldLabel from 'components/core/form/FieldLabel';
import { Field } from 'react-final-form';
import * as statusUtil from '@kritik/utils/stage';
import validators from 'components/ActivityEdit/Details/Fields/validators';
import { localize } from 'locales/index';

const SelfEvaluation = (props: any) => {
  const getSelfEvaluateOptions = () => {
    return [
      {
        value: true,
        label: 'Yes, Self Evaluate',
        testid: 'self-evaluate-btn',
      },
      {
        value: false,
        label: 'No, Group Members Only',
        testid: 'group-member-only-btn',
      },
    ];
  };

  const isDisabled = () => {
    return (
      props.settings.activity &&
      !props.settings.isCloning &&
      !statusUtil.isDraft({ assignment: props.settings.activity })
    );
  };

  const setSelfEvaluate = (e: any, onChangeCallBack: any) => {
    if (isDisabled()) {
      return;
    }
    onChangeCallBack(e.target.value === 'true');
  };

  return (
    <React.Fragment>
      <FormTitle label="Self Evaluation" />
      <FormFieldLabel label="Do you want students to be able to evaluate themselves" />
      <Field name="selfEvaluate" validate={validators.isRequired}>
        {({ input, meta }) => {
          return (
            <RadioInput
              value={input.value}
              title="Self Evaluation"
              options={getSelfEvaluateOptions()}
              onChange={(e: any) => {
                return setSelfEvaluate(e, input.onChange);
              }}
              isDisabled={isDisabled()}
              type="button"
              legendText="Legend.Text.SelfEvaluationOption"
            />
          );
        }}
      </Field>
    </React.Fragment>
  );
};

export default SelfEvaluation;
