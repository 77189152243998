import { getTotalWeightOfActivityList, isCalibrationActivity } from '@kritik/utils/activity';
import { min0Max100 } from '@kritik/utils/format';
import { Activity, Creation } from '@kritik/types.generated';

export const getActivitiesParticipatedIn = (
  gradeHistories: GradeHistory[],
  activityList?: Activity[]
) => {
  const activityMap = activityList.reduce((acc, activity) => {
    if (!isCalibrationActivity(activity)) {
      acc[activity._id] = activity;
    }
    return acc;
  }, {});
  const activitiesParticipatedIn = [];
  gradeHistories.forEach((history) => {
    const activity = activityMap[history.assignment.toString()];
    if (activity) {
      activitiesParticipatedIn.push(activity);
    }
  });
  return activitiesParticipatedIn;
};

const applyActivityWeightToScore = (score: number, activity: { weight: number }) => {
  return score * activity.weight;
};

export const calculateSumOfGradeHistories = (
  gradeHistories: GradeHistory[],
  activityList: Creation[]
) => {
  const activityMap = activityList.reduce((acc, activity) => {
    acc[activity._id] = activity;
    return acc;
  }, {});

  const sumOfGradeHistories = gradeHistories.reduce(
    (sumOfGrades, gradeHistory) => {
      const activity = activityMap[gradeHistory.assignment];
      if (!activity) {
        return sumOfGrades;
      }
      sumOfGrades.creation += applyActivityWeightToScore(
        GradeHistoryUtil.getCreationScoreFromGradeHistory(gradeHistory),
        activity
      );
      sumOfGrades.grading += applyActivityWeightToScore(gradeHistory.grading, activity);
      sumOfGrades.writtenEvaluation += applyActivityWeightToScore(
        gradeHistory.writtenEvaluation,
        activity
      );
      sumOfGrades.feedback += applyActivityWeightToScore(gradeHistory.feedback, activity);
      return sumOfGrades;
    },
    {
      creation: 0,
      grading: 0,
      writtenEvaluation: 0,
      feedback: 0,
    }
  );

  return sumOfGradeHistories;
};

export class GradeHistoryUtil {
  /**
   * Calculate avg scores in a course with activity weights
   */
  static calculateAveragGrades(gradeHistories: GradeHistory[], activityList: Activity[]) {
    const activitiesParticipatedIn = getActivitiesParticipatedIn(gradeHistories, activityList);
    if (!activitiesParticipatedIn.length) {
      return {
        creation: 0,
        grading: 0,
        writtenEvaluation: 0,
        feedback: 0,
      };
    }

    const sumOfGradeHistories = calculateSumOfGradeHistories(
      gradeHistories,
      activitiesParticipatedIn
    );
    const totalWeight = getTotalWeightOfActivityList(activitiesParticipatedIn);

    if (!totalWeight) {
      return {
        creation: 0,
        grading: 0,
        writtenEvaluation: 0,
        feedback: 0,
      };
    }
    //to avoid having entries below 0 or above 100, we use the min0Max100 function
    //it happens due to a rounding issue, so values are set to -0.00000000XXXXX or
    //100.00000000000XXXXX instead of 0 or 100.
    const avgGrades = {
      creation: min0Max100(sumOfGradeHistories.creation / totalWeight),
      grading: min0Max100(sumOfGradeHistories.grading / totalWeight),
      writtenEvaluation: min0Max100(sumOfGradeHistories.writtenEvaluation / totalWeight),
      feedback: min0Max100(sumOfGradeHistories.feedback / totalWeight),
    };
    return avgGrades;
  }

  static getCreationScoreFromGradeHistory(gradeHistory: GradeHistory) {
    if (!gradeHistory) {
      return 0;
    }
    if (this.isFromNumericGradingActivity(gradeHistory)) {
      return gradeHistory.creation;
    }
    return gradeHistory.passed ? 100 : 0;
  }

  /*
    gradeHistory did not use to have a numericGrading property, this is why we check
    We assume that all past activities were numericGrading since pass/fail was broken anyway
  */
  static isFromNumericGradingActivity(gradeHistory: GradeHistory) {
    if (typeof gradeHistory.numericGrading === 'undefined' || gradeHistory.numericGrading) {
      return true;
    }
    return false;
  }
}
