import React, { useState, useContext, useEffect } from 'react';
import Button from 'components/buttons/Button';
import { connect } from 'react-redux';
import { findCreationForMissedEvaluation } from 'actions/admin';
import FormNumberInput from 'components/core/form/NumberField';
import { CourseContext } from '../context/CourseContext';
import { CreationContext } from '../context/CreationContext';
import { AssignmentContext } from '../context/AssignmentContext';
import FindAssignmentContainer from '../Find/FindAssignmentContainer';

const mapStateToProps = (state: any) => {
  return {
    evaluator: state.admin.student,
    creation: state.admin.creation,
  };
};

const FindCreation = (props: any) => {
  const [course, setCourse] = useContext(CourseContext);

  const { creation, setCreation } = useContext(CreationContext);

  const [assignment, setAssignment] = useContext(AssignmentContext);
  const [creationNum, setCreationNum] = useState('');

  const changeCourse = (course: any) => {
    return setCourse(course);
  };
  const changeAssignment = (newAssignment: any) => {
    setCreationNum('');
    setAssignment(newAssignment);
  };
  const changeCreationNum = (e: any) => {
    return setCreationNum(e.target.value);
  };

  const findCreation = async () => {
    const { evaluator, findCreationForMissedEvaluation } = props;
    if (assignment) {
      const data = await findCreationForMissedEvaluation({
        assignment: assignment.value._id,
        student: evaluator.id,
        creationNumber: creationNum,
      });
      setCreation(data);
    }
  };

  return (
    <React.Fragment>
      <FindAssignmentContainer
        course={course}
        changeCourse={changeCourse}
        assignment={assignment}
        changeAssignment={changeAssignment}
      />

      <FormNumberInput
        name="creationNumber"
        label="Creation Number"
        value={creationNum}
        onChange={changeCreationNum}
      />

      <Button type="secondary" onClick={findCreation}>
        Find Creation
      </Button>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, {
  findCreationForMissedEvaluation,
})(FindCreation);
