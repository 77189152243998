import { SpotlightTag } from 'utils/spotlightTag';

export function Spotlight(spotlight: any) {
  if (!spotlight) {
    return null;
  }
  return {
    ...spotlight,
    getId: () => {
      return spotlight._id;
    },
    getActivityId: () => {
      return spotlight.activity._id || spotlight.activity;
    },
    getReference: () => {
      if (spotlight.reference._id) {
        return spotlight.reference;
      }
      return null;
    },
    getReferenceId: () => {
      return spotlight.reference._id || spotlight.reference;
    },
    getComment: () => {
      return spotlight.comment;
    },
    getType: () => {
      return spotlight.type;
    },
    getTagId: () => {
      return spotlight.tag._id || spotlight.tag;
    },
    getTag: () => {
      return SpotlightTag(spotlight.tag);
    },
    isEvaluationType: () => {
      return spotlight.type === 'Evaluation';
    },
    isCreationType: () => {
      return spotlight.type === 'Creation';
    },
    serialize: () => {
      return {
        ...spotlight,
        tag: spotlight.tag._id || spotlight.tag,
        activity: spotlight.activity._id || spotlight.activity,
        course: spotlight.course._id || spotlight.course,
      };
    },
  };
}
