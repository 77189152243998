import * as modalTypes from 'types/modal';

const openFinalizedConfirmModal = ({ children, onConfirm }: any) => {
  return {
    type: modalTypes.OPEN_CONFIRM_MODAL,
    payload: {
      title: 'There are still outstanding issues with the activity',
      description:
        'We recommend you resolve outstanding issues before you mark the activity as complete.',
      children,
      confirmButton: 'Mark as complete',
      cancelButton: 'Cancel',
      onConfirm,
    },
  };
};

export default {
  openFinalizedConfirmModal,
};
