export const REGION_OPTIONS = {
  CANADA: 'Canada',
  AMERICA: 'America',
  EUROPE: 'Europe',
  OTHER: 'Other',
};

export function getRegionPrefix(region: string) {
  switch (region) {
    case REGION_OPTIONS.AMERICA:
      return 'us';
    case REGION_OPTIONS.CANADA:
    case REGION_OPTIONS.EUROPE:
    case REGION_OPTIONS.OTHER:
    default:
      return 'ca';
  }
}

/**
 * @deprecated This method should not be used, use baseUrl instead
 */
export function getHostname({ region, appEnv }: { region: string; appEnv: string }) {
  const regionPrefix = getRegionPrefix(region);
  switch (appEnv) {
    case 'production':
      return `${regionPrefix}.kritik.io`;
    case 'staging':
      return 'staging.kritik.io';
    case 'development':
    default:
      return `localhost:3000`;
  }
}
