import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import RubricManagerComponent from 'components/RubricManager';
import { listRubrics } from 'actions/rubrics';
import { navigateToActivityList } from 'actions/activity';
import { navigateToHomePage } from 'redux/redirect';

const RubricViewContainer = (props: any) => {
  useEffect(() => {
    props.listRubrics();
  }, []);
  const goBackAction = () => {
    if (props.selectedCourseId) {
      props.navigateToActivityList({ courseId: props.selectedCourseId });
    } else {
      props.navigateToHomePage();
    }
  };
  return <RubricManagerComponent goBackAction={goBackAction} />;
};

const mapStateToProps = (state: any) => {
  return {
    selectedCourseId: state.selected.courseId,
  };
};

export default connect(mapStateToProps, {
  listRubrics,
  navigateToActivityList,
  navigateToHomePage,
})(RubricViewContainer);
