export const ACTIVITY_TYPES = {
  PEER: 'Peer',
  CALIBRATION: 'Calibration',
  GROUP: 'Group',
  WITHIN_GROUP: 'Group Assessment',
  INDIVIDUAL: 'Individual',
  INDIVIDUAL_PRESENTATION: 'Individual Presentation',
  GROUP_PRESENTATION: 'Group Presentation',
};

export const fileRestrictionStatus = {
  ALL: 'restrictedAllFiles',
  SOMEFILES: 'restrictedSomeFiles',
  UNRESTRICTED: 'unrestrictedFiles',
};

export const FILE_UPLOAD_MAX_SIZE_IN_MB = 200;
export const FILE_UPLOAD_MAX_SIZE_IN_BYTES = FILE_UPLOAD_MAX_SIZE_IN_MB * 1024 * 1024;
export const PROFILE_IMAGE_MAX_SIZE_IN_MB = 10;
export const PROFILE_IMAGE_MAX_SIZE_IN_BYTES = PROFILE_IMAGE_MAX_SIZE_IN_MB * 1024 * 1024;
export const DEFAULT_WEIGHT = 1;
export const DEFAULT_WEIGHT_CALIBRATION = 0;
export const DEFAULT_WEIGHT_WITHIN_GROUP = 0;
export const MIN_WEIGHT = 0;
export const MAX_WEIGHT = 9999;

export const DEFAULT_NUM_EVALS_TO_ASSIGN = 5;

export default {
  ACTIVITY_TYPES,
  DEFAULT_WEIGHT,
  MIN_WEIGHT,
  MAX_WEIGHT,
};

export const NOTES_PLACEHOLDER = 'Enter notes for Evaluators';

export const REVERT_ACTIVITY_DUE_DATE = 5;

export const ALL_STUDENTS: string = 'all';
export const EXCLUDE_CREATORS: string = 'excludeCreators';
export const SPECIFIC_STUDENTS: string = 'specificStudents';
export const SPECIFIC_GROUPS: string = 'specificGroups';

export const PRESENTATION_TEMPLATE_RUBRIC = 'Presentation Lab - MASTER';
