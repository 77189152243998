import React from 'react';
import { connect } from 'react-redux';
import { DisplayBox } from 'components/layout';
import { Button, ButtonContainer } from 'components/buttons';
import { FeedbackScoreUtil } from '@kritik/utils/grade';
import InlineInformation from 'components/layout/InlineInformation';
import ScoreItem from 'components/DisplayBox/ScoreItem';
import DisplayBoxItem from 'components/DisplayBox/Item';
import * as StudentUtils from 'utils/student';
import * as ActivityUtils from '@kritik/utils/activity';
import NoticeBoard from 'components/layout/NoticeBoard';
import { FeedbackScoreGroupScoreNotice } from 'components/Assignment/NoticeBoards';
import { selectAvgGroupFeedbackScore } from 'redux/activityScores/select';
import StudentSelectors from 'selectors/student';
import { getCreationsFromEntities } from 'selectors/creation';

const FeedbackScore = (props: any) => {
  const getCurrentFeedbackScore = () => {
    if (props.groupView) {
      return props.avgGroupFeedbackScore;
    }
    return FeedbackScoreUtil.calcScore({
      creation: props.creation,
      activity: props.activity,
      studentId: props.student._id,
      creations: props.creations,
    });
  };

  const renderBoxHeader = () => {
    return (
      <ScoreItem
        title="Feedback Score"
        subtitle={props.groupView ? ' (group average)' : ''}
        description="Participation in completed tasks"
        value={getCurrentFeedbackScore()}
        size="lg"
      />
    );
  };

  const renderProfGraded = () => {
    if (!FeedbackScoreUtil.isFeedbackScoredByTeacher(props.creation)) {
      return null;
    }
    return (
      <DisplayBoxItem>
        <InlineInformation type="prof-graded">Instructor graded</InlineInformation>
      </DisplayBoxItem>
    );
  };

  const renderEditBtn = () => {
    if (
      ActivityUtils.isGroupAssignment(props.activity) ||
      ActivityUtils.isGroupPresentationActivity(props.activity)
    ) {
      return null;
    }
    const scoreHistory = StudentUtils.getStudentActivityScoreHistory(
      props.scoreHistories,
      props.activity._id
    );

    const renderNotice = () => {
      if (ActivityUtils.isGroupAssignment(props.activity)) {
        if (props.groupView) {
          return <FeedbackScoreGroupScoreNotice />;
        }
        return null;
      }
      return (
        <NoticeBoard type="information" title="Feedback Score Change unavailable">
          The student’s Feedback Score will be editable once you mark the activity as completed.
        </NoticeBoard>
      );
    };
    if (scoreHistory) {
      return (
        <ButtonContainer>
          <Button type="secondary" onClick={props.onEdit}>
            Edit Score
          </Button>
        </ButtonContainer>
      );
    }
    return renderNotice();
  };

  return (
    <DisplayBox>
      {renderBoxHeader()}
      {renderProfGraded()}
      {renderEditBtn()}
    </DisplayBox>
  );
};

FeedbackScore.defaultProps = {
  avgGroupFeedbackScore: 0,
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    avgGroupFeedbackScore: selectAvgGroupFeedbackScore(state, ownProps.creation._id),
    scoreHistories: StudentSelectors.selectStudentScoreHistories(
      state,
      ownProps.creation.student._id || ownProps.creation.student
    ),
    student: StudentSelectors.selectCurrentStudent(state),
    creations: getCreationsFromEntities(state, state.selected.assignmentId),
  };
};

export default connect(mapStateToProps, {})(FeedbackScore);
