import React from 'react';
import classNames from 'classnames';
import FieldError from 'components/core/form/FieldError';

const SIZE = {
  DEFAULT: '',
  LARGE: 'lg',
};

type FormFieldProps = {
  error?: string;
  size?: '' | 'lg';
  id?: string;
  className?: string;
  children: React.ReactNode;
};
const FormField = (props: FormFieldProps) => {
  const classes = classNames(
    'kritik-form-field',
    {
      'kritik-form-field--lg': props.size === SIZE.LARGE,
    },
    props.className
  );
  return (
    <div className={classes}>
      {props.children}
      <FieldError error={props.error} id={props.id ? props.id + '_error' : undefined} />
    </div>
  );
};

FormField.defaultProps = {
  error: null,
  size: SIZE.DEFAULT,
  className: '',
};

export default FormField;
