import { getCourse } from 'selectors/course';
import { navigateToCourseDetailsEditPage } from 'actions/courses';
import { connect } from 'react-redux';
import Button from 'components/buttons/Button';

const NoLmsCourseConnected = ({ course, navigateToCourseDetailsEditPage }: any) => {
  function navigateToCourseSettings() {
    navigateToCourseDetailsEditPage(course._id, '?scroll-to-lms=true');
  }
  return (
    <div className="sync-roster-modal__no-course">
      <div className="sync-roster-modal__title">No LMS Course Connected</div>
      <div className="sync-roster-modal__description">
        You can connect this Kritik course under the Learning Management System settings in Course
        Details
      </div>
      <Button type="secondary" onClick={() => navigateToCourseSettings()}>
        LMS Settings
      </Button>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    course: getCourse(state),
  };
};
export default connect(mapStateToProps, { navigateToCourseDetailsEditPage })(NoLmsCourseConnected);
