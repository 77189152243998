import HyperDX from '@hyperdx/browser';

export default function hyperDxInit(state: any) {
  // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
  if (App.config.get('hyperDxEnabled')) {
    HyperDX.init({
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
      apiKey: App.config.get('hyperDxApiKey'),
      service: 'kritik-app',
      tracePropagationTargets: [/\*.kritik.io/i],
      consoleCapture: true,
      advancedNetworkCapture: false,
      instrumentations: {
        interactions: false,
      },
    });

    if (state.user.authenticated) {
      const { authUser } = state.user;
      HyperDX.setGlobalAttributes({
        userId: authUser.id,
        userName: authUser.profile.name,
        userEmail: authUser.email,
      });
    } else {
      // logout/reset user attributes
      HyperDX.setGlobalAttributes({
        userId: null,
        userName: null,
        userEmail: null,
      });
    }
  }
}
