import { TABLE_TYPE_OPTIONS } from 'components/CreationList/constant';

export const selectCreationTableFilter = (state: any) => {
  return state.creationTable.filter;
};

export const selectCreationTableListBy = (state: any) => {
  return state.creationTable.listBy;
};

export const selectCreationTableColumns = (state: any) => {
  return state.creationTable.columns;
};

export const selectCreationTableViewType = (state: any) => {
  return state.creationTable.type || TABLE_TYPE_OPTIONS.PROGRESS;
};

export const selectCreationTableSearch = (state: any) => {
  return state.creationTable.search;
};

export const selectSortedColumn = (state: any) => {
  if (!state.creationTable.columns) {
    return null;
  }
  return state.creationTable.columns.find((col: any) => {
    if (col.sorting) {
      return col.sorting.isSorted;
    }
    return null;
  });
};

export const selectTableSettings = (state: any) => {
  return state.creationTable;
};

export const selectIsShowEvalsCompleted = (state: any) => {
  return state.creationTable.isShowEvalsCompleted;
};

export const selectIsShowFeedbackCompleted = (state: any) => {
  return state.creationTable.isShowFeedbackCompleted;
};

export const selectCurrentPage = (state: any) => {
  return state.creationTable.currentPage;
};

export const selectPageSize = (state: any) => {
  return state.creationTable.pageSize;
};

export const selectTotalRowsCount = (state: any) => {
  return state.creationTable.totalRowsCount;
};

export const selectPageCount = (state: any) => {
  return state.creationTable.pageCount;
};

export const selectIsCreationTableLoading = (state: any) => {
  return state.creationTable.isLoading;
};

export default {
  selectCreationTableFilter,
  selectCreationTableListBy,
  selectCreationTableColumns,
  selectCreationTableViewType,
  selectCreationTableSearch,
  selectSortedColumn,
  selectTableSettings,
  selectIsShowEvalsCompleted,
  selectIsShowFeedbackCompleted,
  selectCurrentPage,
  selectPageSize,
  selectPageCount,
  selectIsCreationTableLoading,
};
