import { mimeTypes } from 'components/Assignment/constants';
import { ACTIVITY_TYPES, fileRestrictionStatus } from '@kritik/constants/activity';

export const getFormSettings = (formFields: any) => {
  return formFields.settings;
};

const getFormActivity = (formFields: any) => {
  const settings = getFormSettings(formFields);
  return settings.activity;
};

export const isEditingCalibration = (formFields: any) => {
  return formFields.activityType === ACTIVITY_TYPES.CALIBRATION;
};

export const getFileExtentionsAllowed = (values: any) => {
  if (values.restrictFileTypes === fileRestrictionStatus.SOMEFILES) {
    const fileTypes = [
      'allowAudio',
      'allowExcel',
      'allowImages',
      'allowPDF',
      'allowPowerpoint',
      'allowText',
      'allowVideo',
      'allowWord',
      'allowZip',
    ];

    let fileExtensionsAllowed: any = [];
    fileTypes.forEach((type) => {
      if (values[type] === true) {
        fileExtensionsAllowed = fileExtensionsAllowed.concat(mimeTypes[type]);
      }
    });
    return fileExtensionsAllowed;
  }
  if (values.restrictFileTypes === fileRestrictionStatus.UNRESTRICTED) {
    return [];
  }
  return null;
};

export const getChangedFileList = (fileList: any) => {
  const changedFiles: any = [];
  const unChangedFiles: any = [];

  fileList.forEach((file: any) => {
    if (!file._id) {
      changedFiles.push(file);
    } else {
      unChangedFiles.push(file);
    }
  });

  return {
    unChangedFiles,
    changedFiles,
  };
};

export default {
  getFormSettings,
  getFormActivity,
  getFileExtentionsAllowed,
  getChangedFileList,
  isEditingCalibration,
};
