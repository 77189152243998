import { sortItemList as sortItemListByProgress } from 'components/CreationList/ProgressTable/utils';
import { sortItemList as sortItemListByScore } from 'components/CreationList/ScoringTable/utils';
import { sortItemList as sortItemListByDispute } from 'components/CreationList/DisputeTable/utils';
import { TABLE_TYPE_OPTIONS } from 'components/CreationList/constant';

export const sortItemList = ({
  itemList,
  sortedColumn,
  activity,
  viewType,
  course,
  tableSettings,
  feedbackReceivedMap,
}: any) => {
  if (!sortedColumn) {
    return itemList;
  }

  switch (viewType) {
    case TABLE_TYPE_OPTIONS.PROGRESS:
      return sortItemListByProgress(
        itemList,
        sortedColumn,
        activity,
        tableSettings,
        feedbackReceivedMap
      );
    case TABLE_TYPE_OPTIONS.SCORING:
      return sortItemListByScore(itemList, sortedColumn, activity, course);
    case TABLE_TYPE_OPTIONS.DISPUTE:
      return sortItemListByDispute(itemList, sortedColumn);
    default:
      return itemList;
  }
};

export function getStudentIdsFromTableItemList(itemList: any[] = []): string[] {
  return Array.from(
    itemList.reduce((list, item) => {
      if (item.group) {
        item.group.members.forEach((member) => {
          list.add(member._id);
        });
      } else {
        list.add(item.student._id);
      }
      return list;
    }, new Set())
  );
}

export function injectLateEvaluationsToTableItemList({
  itemList,
  lateEvaluationsMap,
}: {
  itemList: any[];
  lateEvaluationsMap: Record<string, any[]>;
}): any[] {
  return itemList.map((item) => {
    if (item.group) {
      return {
        ...item,
        lateEvaluations: item.group.members.reduce((all, member) => {
          all[member._id] = lateEvaluationsMap[member._id] || [];
          return all;
        }, {}),
      };
    } else {
      return {
        ...item,
        lateEvaluations: lateEvaluationsMap[item.student._id] || [],
      };
    }
  });
}
