import Button from 'components/buttons/Button';
import { Avatar, Card, CardContent, Typography, CardActions } from '@material-ui/core';
import './_ErrorPageTemplate.scss';
import classNames from 'classnames';
import { trackEvent } from 'utils/userEvents';
import { AuthUser } from 'app-types';

// if no status then it would be considered as error boundary
type ErrorPageTemplateProps = {
  icon: string;
  iconVariant: 'blue' | 'red';
  title: string;
  buttonText: string;
  subtitle?: string;
  descriptions: string[];
  authUser?: AuthUser;
};

const ErrorPageTemplate = ({
  subtitle = null,
  icon,
  iconVariant = null,
  title,
  buttonText,
  descriptions,
  authUser,
}: ErrorPageTemplateProps) => {
  trackEvent('Generic Error Page Shown', authUser, {
    path: window.location.pathname,
  });

  return (
    <>
      <div className="icon-container">
        <Avatar
          className={classNames('avatar-icon', {
            'red-icon': iconVariant === 'red',
            'blue-icon': iconVariant === 'blue',
          })}
        >
          {icon}
        </Avatar>
      </div>
      <Card className="error-container">
        <CardContent>
          <Typography variant="h4" component="h4">
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="subtitle2" component="p" className="error-status">
              {subtitle}
            </Typography>
          )}
          <div className="error-section-margin">
            {descriptions.map((description) => (
              <Typography variant="body1" component="p" className="error-text-margin">
                {description}
              </Typography>
            ))}
          </div>
        </CardContent>
        <CardActions className="error-buttons-container">
          <Button
            type="primary"
            onClick={() => {
              // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
              window.location.replace(App.config.get('baseUrl'));
            }}
          >
            {buttonText}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};
export default ErrorPageTemplate;
