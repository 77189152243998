import React from 'react';
import { connect } from 'react-redux';
import * as creationStatus from '@kritik/utils/creation/status';
import ResolveMenu from 'components/Creation/Flag/InstructorMenu/ResolveMenu';

const TeacherMenu = (props: any) => {
  if (
    creationStatus.isCommentFlagged(props.evaluation) ||
    creationStatus.isCommentRemoved(props.evaluation) ||
    creationStatus.isCommentFlagDismissed(props.evaluation)
  ) {
    return <ResolveMenu evaluation={props.evaluation} creation={props.creation} />;
  }
  return null;
};

const mapStateToProps = (state: any) => {
  return {};
};

export default connect(mapStateToProps, {})(TeacherMenu);
