import React from 'react';
import classNames from 'classnames';

const ProgressBar = ({ className, progress }: any) => {
  let styleProgress = progress.toString();
  if (!styleProgress.includes('%')) {
    styleProgress += '%';
  }

  const progressBarInnerStyle = 'progress-bar-inner';
  const panelClasses = classNames(
    'progress-bar-container',
    {
      'progress-bar-container--completed': styleProgress === '100%',
    },
    className
  );

  return (
    <div className={panelClasses}>
      <div className="progress-bar-outer">
        <div className="progress-bar-inner" style={{ width: styleProgress }} />
      </div>
    </div>
  );
};

export default ProgressBar;
