import { GradingScoreUtil } from '@kritik/utils/grade';

export function getGradingScore({ scoreHistories, activity }: any) {
  if (scoreHistories.length === 0) {
    return GradingScoreUtil.DEFAULT_GRADING_SCORE;
  }

  const scoreHistory = scoreHistories.find((scoreHistory: any) => {
    return scoreHistory.assignment === activity._id;
  });
  // if no score history, means that student enroll late and not have any scoreHistories before finalizing this actvivity
  if (!scoreHistory) {
    return GradingScoreUtil.DEFAULT_GRADING_SCORE;
  }

  return scoreHistory.gradingScore;
}

export default {
  getGradingScore,
};
