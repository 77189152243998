import { useState } from 'react';
import { getEnrollLink } from '@kritik/utils/general';
import FindStudentWithEmail from '../Find/FindStudentWithEmail';
import CloseButton from '../CloseButton';
import FindCourseFromOptions from '../Find/FindCourseFromOptions';

const GenerateJoinLinkModal = (props: any) => {
  const [email, setEmail] = useState('');
  const [course, setCourse] = useState(null);
  const [courseOptions, setCourseOptions] = useState([]);
  const [link, setLink] = useState('');

  const changeEmail = (e: any) => {
    return setEmail(e.target.value.trim());
  };

  const getLink = async () => {
    const enrollLink = await getEnrollLink({
      courseId: course.value,
      email,
    });
    setLink(`${window.location.origin}${enrollLink}`);
  };

  return (
    <div className="admin-panel-modal">
      <section className="admin-panel-modal__main admin-panel-modal__scrollable">
        <h1>Generate an Invitation Link</h1>
        <FindStudentWithEmail
          email={email}
          changeEmail={changeEmail}
          changeCourse={setCourse}
          changeCourseOptions={setCourseOptions}
        />
        <FindCourseFromOptions
          buttonName="Generate an Invitation Link"
          buttonDesign="primary"
          email={email}
          course={course}
          changeCourse={setCourse}
          courseOptions={courseOptions}
          handleVerifyCourse={getLink}
        />
        {link && <p>{link}</p>}
      </section>
      <CloseButton onClose={props.handleClose} />
    </div>
  );
};

export default GenerateJoinLinkModal;
