import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Modal from 'components/modals/Components/Modal';
import ModalTitle from 'components/modals/Components/Header';
import ModalAction from 'components/modals/Components/Action';
import ModalContent from 'components/modals/Components/Content';
import { getNumEligibleEvaluatorsByActivityId } from 'redux/lateCreation';
import CreationSelector from 'selectors/creation';
import * as LateSubmissionUtil from '@kritik/utils/creation/lateCreation';

const ResolutionModal = (props: any) => {
  const [numEligibleEvaluators, setNumEligibleEvaluators] = useState(0);
  const findNumEligibleEvaluators = () => {
    props
      .getNumEligibleEvaluatorsByActivityId({
        activityId: props.activityId,
        creationId: props.creationId,
      })
      .then(({ success }: any) => {
        if (success) {
          setNumEligibleEvaluators(success);
        }
      });
  };

  const isManualGradingOnly = LateSubmissionUtil.isLateSubmissionRejected(props.creation);

  const getActions = () => {
    const actions = [];
    if (!isManualGradingOnly) {
      actions.push({
        label: 'Assign Evaluators',
        testid: 'assign-evaluators',
        type: 'primary',
        isDisabled: numEligibleEvaluators === 0,
        isUnavailable: numEligibleEvaluators === 0,
        onClick: () => {
          return props.onResolveLateCreation();
        },
      });
    }

    actions.push({
      label: 'Score Manually',
      type: 'secondary',
      onClick: () => {
        return props.onScoreManually();
      },
    });

    actions.push({
      label: 'Cancel',
      type: 'secondary',
      onClick: () => {
        return props.onCancel();
      },
    });

    actions[0].type = 'primary';

    return actions;
  };
  useEffect(() => {
    if (props.isOpen && props.activityId) {
      findNumEligibleEvaluators();
    }
  }, [props.isOpen]);

  const text = {
    title: 'How would you like the Creation scored?',
    text_manual_grading: 'Grade the creation yourself.',
    text_evaluator_or_manual_grading: 'Choose to assign evaluators or grade the creation yourself.',
  };

  return (
    <Modal isOpen={props.isOpen}>
      <ModalTitle title={text.title} />
      <ModalContent>
        <div className="late-submission-modal__text">
          {!isManualGradingOnly ? text.text_evaluator_or_manual_grading : text.text_manual_grading}
        </div>
        {!isManualGradingOnly && (
          <div className="late-submission-modal__evaluators">
            {`${numEligibleEvaluators} Eligible Evaluators are available`}
          </div>
        )}
      </ModalContent>
      <ModalAction actions={getActions()} />
    </Modal>
  );
};

ResolutionModal.defaultProps = {
  isOpen: false,
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    creation: CreationSelector.getSubmission(state, ownProps.creationId),
  };
};

export default connect(mapStateToProps, { getNumEligibleEvaluatorsByActivityId })(ResolutionModal);
