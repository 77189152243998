import React from 'react';

const TabMenuItem = (props: any) => {
  return (
    <div className="tab-menu-item" tabIndex={-1}>
      {props.children}
    </div>
  );
};

export default TabMenuItem;
