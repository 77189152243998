import { EvaluationScoreUtil, OverallScoreUtil, GradeHistoryUtil } from '@kritik/utils/grade';

export const calculateAvgGradeChanges = (
  gradeHistories: any,
  course: any,
  finalizedActivities: any
) => {
  const avgGradeChanges = {
    overall: null,
    creation: null,
    evaluation: null,
    grading: null,
    writtenEvaluation: null,
    feedback: null,
  };

  const lastTwoGradeHistories = getLastTwoAvgGrades(gradeHistories, finalizedActivities);
  if (lastTwoGradeHistories.length <= 1) {
    return avgGradeChanges;
  }

  lastTwoGradeHistories[0].evaluation = EvaluationScoreUtil.evaluatorOverallScoreSingleActivity(
    lastTwoGradeHistories[0].grading,
    lastTwoGradeHistories[0].writtenEvaluation,
    course
  );

  lastTwoGradeHistories[1].evaluation = EvaluationScoreUtil.evaluatorOverallScoreSingleActivity(
    lastTwoGradeHistories[1].grading,
    lastTwoGradeHistories[1].writtenEvaluation,
    course
  );

  lastTwoGradeHistories[0].overall = OverallScoreUtil.calculateOverallScore(
    lastTwoGradeHistories[0],
    course
  );
  lastTwoGradeHistories[1].overall = OverallScoreUtil.calculateOverallScore(
    lastTwoGradeHistories[1],
    course
  );

  for (const key in avgGradeChanges) {
    const scoreChangeInPercent = lastTwoGradeHistories[1][key] - lastTwoGradeHistories[0][key];
    avgGradeChanges[key] = scoreChangeInPercent;
  }
  return avgGradeChanges;
};

const sortGradeHistories = (gradeHistories: any) => {
  return gradeHistories.slice().sort((a: any, b: any) => {
    return a.createdAt - b.createdAt;
  });
};

export const getLastTwoAvgGrades = (gradeHistories: any, activityList: any) => {
  if (gradeHistories.length <= 1) {
    return gradeHistories;
  }
  const sortedGradeHistories = sortGradeHistories(gradeHistories);
  const prevAvgGrades = GradeHistoryUtil.calculateAveragGrades(
    sortedGradeHistories.slice(0, gradeHistories.length - 1),
    activityList
  );
  const currentAvgGrades = GradeHistoryUtil.calculateAveragGrades(
    sortedGradeHistories,
    activityList
  );
  return [prevAvgGrades, currentAvgGrades];
};

export default {
  calculateAvgGradeChanges,
};
