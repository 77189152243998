import React from 'react';
import Banner from 'components/core/Banner';
import NewTab from 'components/buttons/NewTab';
import Button from 'components/buttons/Button';
import VideoIcon from 'images/icons/fa/Video';
import Typography from 'components/core/Typography';
import { localize } from 'locales/index';

const LINK_TYPES = {
  URL: 'url',
  VIDEO: 'video',
};

const TutorialBanner = (props: any) => {
  const onClickButton = () => {
    window.open(props.link.target);
  };

  const getVideoLength = () => {
    if (!props.link.length) {
      return null;
    }
    return `(${props.link.length})`;
  };

  const renderRedirect = () => {
    if (!props.link) {
      return null;
    }
    if (props.link.type === LINK_TYPES.URL) {
      return <NewTab onClick={onClickButton} label="Learn more" title={props.title} />;
    }
    if (props.link.type === LINK_TYPES.VIDEO) {
      return (
        <a href={props.link.target} target="_blank" rel="noreferrer">
          <Typography color="primary">
            <VideoIcon /> Watch a video {getVideoLength()}
          </Typography>
        </a>
      );
    }
    return null;
  };

  return (
    <Banner
      className={props.className}
      image={props.image}
      title={props.title}
      description={props.description}
      onClose={props.onClose}
    >
      {renderRedirect()}
    </Banner>
  );
};

TutorialBanner.defaultProps = {
  link: null,
  onClose: null,
  className: '',
};

export default TutorialBanner;
