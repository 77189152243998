import React from 'react';
import AppContainer from 'containers/App';
import Page from 'pages/Page';
import { link, meta, title } from 'pages/assets';
import { StylesProvider } from '@material-ui/core/styles';
import * as LocalStorageUtils from 'utils/localStorage';

const setRegion = () => {
  const params = new URLSearchParams(window.location.search);
  const region = params.get('region');
  if (region) {
    LocalStorageUtils.setText('region', region);
  }
};

const AppPage = (props: any) => {
  setRegion();

  // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
  const intercomId = App.config.get('intercomId');
  // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
  const intercomUserHash = App.config.get('intercomUserHash');

  React.useEffect(() => {
    // https://material-ui.com/guides/server-rendering/#the-client-side
    const jssStyles = document.querySelector('#mui-css-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <StylesProvider injectFirst>
      <Page title={title} meta={meta} link={link}>
        <AppContainer {...props} intercomId={intercomId} intercomUserHash={intercomUserHash} />
      </Page>
    </StylesProvider>
  );
};

export default AppPage;
