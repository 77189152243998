import { connect } from 'react-redux';
import { push } from 'router';
import { resetAllTutorials } from 'redux/tutorial/actions';
import MenuItem from '@material-ui/core/MenuItem';
import QuestionCircle from 'images/icons/fa/QuestionCircle';
import Divider from '@material-ui/core/Divider';
import { localize } from 'locales';
import MenuTab from 'components/Navigation/MenuTab';
import { AuthUser } from 'app-types';
import { User } from 'old-common/types.generated';

type HelpTabProps = {
  user: User & { authUser: AuthUser };
  resetAllTutorials: (userId: string) => void;
  position: any;
};

function HelpTab({ user, resetAllTutorials, position }: HelpTabProps) {
  const resetTutorials = () => {
    resetAllTutorials(user.authUser.id);
  };

  const openNewTab = (url: any) => {
    window.open(url, '_blank');
  };

  return (
    <li role="listitem" className="bottom-menu-item">
      <MenuTab
        icon={<QuestionCircle className="navigation-help__icon" />}
        label={localize({ message: 'Navigation.Tabs.Support.Label' })}
        position={position}
        content={[
          <MenuItem
            key={2}
            onClick={() => {
              openNewTab(
                user.authUser.permissions.includes('canCreateCourse')
                  ? 'https://help.kritik.io/en/articles/6386864-course-grading-scheme'
                  : 'https://help.kritik.io/en/articles/6388500-how-scoring-works'
              );
            }}
          >
            {localize({ message: 'Navigation.HelpMenu.HowScoringWorks' })}
          </MenuItem>,
          <MenuItem
            key={3}
            onClick={() => {
              openNewTab(
                user.authUser.permissions.includes('canCreateCourse')
                  ? 'https://help.kritik.io/en/articles/6386864-course-grading-scheme#h_04542bf962'
                  : 'https://help.kritik.io/en/articles/6388500-how-scoring-works#h_853eac162e'
              );
            }}
          >
            {localize({ message: 'Navigation.HelpMenu.EvaluatorTips' })}
          </MenuItem>,
          <Divider key={4} />,
          <MenuItem onClick={resetTutorials} key={5}>
            {localize({ message: 'Navigation.HelpMenu.ResetTutorials' })}
          </MenuItem>,
          <MenuItem
            key={6}
            onClick={() => {
              openNewTab('https://help.kritik.io');
            }}
          >
            {localize({ message: 'Navigation.HelpMenu.HelpCenter' })}
          </MenuItem>,
        ]}
      />
    </li>
  );
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, { resetAllTutorials, push })(HelpTab);
