import errorAction from 'actions/error';
import ActivityService from 'services/activity';

const GET_ELIGIBLE_EVALUATORS = 'GET_ELIGIBLE_EVALUATORS';
const GET_ELIGIBLE_EVALUATORS_SUCCESS = 'GET_ELIGIBLE_EVALUATORS_SUCCESS';
const GET_ELIGIBLE_EVALUATORS_FAILURE = 'GET_ELIGIBLE_EVALUATORS_FAILURE';

export function getNumEligibleEvaluatorsByActivityId({ activityId, creationId }: any) {
  return async (dispatch: any) => {
    dispatch({ type: GET_ELIGIBLE_EVALUATORS });
    try {
      const res = await ActivityService().getNumEligibleEvaluatorsByActivityId({
        activityId,
        creationId,
      });
      dispatch({ type: GET_ELIGIBLE_EVALUATORS_SUCCESS });
      return { success: res.data.length };
    } catch (err) {
      dispatch(errorAction(GET_ELIGIBLE_EVALUATORS_FAILURE, err));
      return { error: err };
    }
  };
}

export default {
  getNumEligibleEvaluatorsByActivityId,
};
