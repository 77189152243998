import React, { useState } from 'react';
import { FormMainError } from 'components/Form/FormComponents';
import FormTextInput from 'components/core/form/TextField';
import InlineInformation from 'components/layout/InlineInformation';
import { Button } from 'components/buttons';
import CloseButton from '../CloseButton';

import * as XLSX from 'xlsx';

const CouponBookstoreModal = (props: any) => {
  const [bookstoreName, setBookstoreName] = useState('');
  const [courseName, setCourseName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const changeBookstoreName = (e: any) => {
    if (error) {
      setError('');
    }
    setBookstoreName(e.target.value);
  };

  const changeCourseName = (e: any) => {
    if (error) {
      setError('');
    }
    setCourseName(e.target.value);
  };

  const generateExcelFile = (selectedCoupons: any) => {
    setLoading(true);
    try {
      const wb = XLSX.utils.book_new();
      wb.Props = {
        Title: `Coupons For ${bookstoreName}`,
        Subject: 'Coupons',
        Author: 'Kritik',
        CreatedDate: new Date(Date.now()),
      };
      wb.SheetNames.push('Coupons');
      const ws_data = selectedCoupons.map((coupon: any) => {
        return { ...coupon, courseName };
      });
      const ws = XLSX.utils.json_to_sheet(ws_data);
      wb.Sheets.Coupons = ws;
      XLSX.writeFile(wb, `${wb.Props.Title}.xls`);
      setError('');
    } catch (error) {
      console.log(error);
      setError('Unable to generate excel file');
    }
    setLoading(false);
  };

  const checkAllFields = () => {
    return bookstoreName !== '' && courseName !== '';
  };

  return (
    <div className="admin-panel-modal">
      <section className="admin-panel-modal__main admin-panel-modal__scrollable">
        <h1>Bookstore Coupons</h1>
        <FormTextInput
          name="bookstoreName"
          label="Bookstore Name"
          value={bookstoreName}
          onChange={changeBookstoreName}
        />
        <FormTextInput
          name="courseName"
          label="Course Name"
          value={courseName}
          onChange={changeCourseName}
        />
        <Button
          unavailable={!checkAllFields()}
          disabled={!checkAllFields()}
          loading={loading}
          className="admin-panel-submit-button"
          type="primary"
          onClick={() => {
            return generateExcelFile(props.selectedCoupons);
          }}
        >
          Generate Excel
        </Button>
        <FormMainError message={error} isOpen={error} />
        <InlineInformation
          style={{ marginTop: '20px' }}
          title="To create PDFs to send to the bookstore follow the guide here "
        >
          <a target="_blank" href="https://youtu.be/ZHXhTl15dqo">
            (YouTube)
          </a>
        </InlineInformation>
      </section>
      <CloseButton onClose={props.handleClose} />
    </div>
  );
};

export default CouponBookstoreModal;
