import React from 'react';

const CreationHeader = ({ children }: any) => (
  <>
    <h3 className="submission-overview__creation-header-label">Creation</h3>
    <div className="submission-overview__creation-header-timestamp">{children}</div>
  </>
);

export default CreationHeader;
