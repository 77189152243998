import * as _ from 'lodash-es';
import { combineReducers } from 'redux';

import * as types from 'types';

const courses = (
  state = {
    items: [],
  },
  action: any
) => {
  switch (action.type) {
    case types.GET_COURSES_REQUEST:
      if (action.payload) {
        return { items: [] };
      }
      return state;

      break;
    case types.GET_COURSES_SUCCESS:
      if (action.payload) {
        return { items: action.payload.items };
      }
      return state;

    case types.CREATE_COURSE_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          items: [action.payload._id, ...state.items],
        };
      }
      return state;

    default:
      return state;
  }
};

const homeReducer = combineReducers({
  courses,
});

export default homeReducer;
