import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonContainer } from 'components/buttons';
import { logOut } from 'actions/users';
import LtiLaunchDuplicateDialog from './LtiLaunchDuplicateDialog';
import { getCourseList } from 'selectors/course';
import { localize } from 'locales';
import { createCourseMutation, duplicateCourseMutation } from 'hooks/course';
import { withRouter } from 'utils/withRouter';
import { TranslatedText } from 'components/TranslatedText';
import { AuthUser } from 'app-types';

type LtiDuplicateFormParams = {
  title: string;
  courseId: string;
  includeActivities: boolean;
  section: string;
  code: string;
};

type EdlinkClassInfo = {
  id: string;
  name: string;
  edlinkIntegrationId: string;
  lmsClassId?: string;
} | null;

const LtiLaunchBar = ({
  authUser,
  edlinkClassInfo,
  canDuplicate,
  coursesList,
  showSuccessMessage,
}: {
  authUser: AuthUser;
  edlinkClassInfo: EdlinkClassInfo;
  canDuplicate: boolean;
  coursesList: any[];
  showSuccessMessage: (courseName: string) => void;
}) => {
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
  const shouldShow = edlinkClassInfo ? true : false;
  const [showBanner, setShowBanner] = useState(shouldShow);
  const createCourse = createCourseMutation(authUser);
  const duplicateCourse = duplicateCourseMutation(authUser);

  useEffect(() => {
    if (createCourse.isSuccess) {
      setShowBanner(false);
      showSuccessMessage(createCourse.data.title);
    }

    if (duplicateCourse.isSuccess) {
      setShowBanner(false);
      toggleDuplicateDialog();
      showSuccessMessage(duplicateCourse.data.title);
    }
  }, [createCourse.isSuccess, duplicateCourse.isSuccess]);

  const createConnectedCourse = ({ edlinkClassInfo }) => {
    const data = {
      title: edlinkClassInfo.name,
      lms: {
        connectedCourseId: edlinkClassInfo.id,
        connectedCourseName: edlinkClassInfo.name,
        edlinkIntegrationId: edlinkClassInfo.edlinkIntegrationId,
        lmsClassId: edlinkClassInfo.lmsClassId,
      },
    };

    createCourse.mutate(data);
  };

  const duplicateLtiLaunchConnectedCourse = (data: LtiDuplicateFormParams) => {
    duplicateCourse.mutate({
      ...data,
      user: authUser.id,
      lms: {
        connectedCourseId: edlinkClassInfo.id,
        connectedCourseName: edlinkClassInfo.name,
        edlinkIntegrationId: edlinkClassInfo.edlinkIntegrationId,
        lmsClassId: edlinkClassInfo.lmsClassId,
      },
    });
  };

  const hideBanner = () => {
    setShowBanner(false);
  };

  const toggleDuplicateDialog = () => {
    setShowDuplicateDialog(!showDuplicateDialog);
  };

  if (!showBanner || !edlinkClassInfo) {
    return null;
  }

  return (
    <div className="lti-launch-bar" data-testid="lti-launch-banner">
      <h3 className="lti-launch-bar__title">
        {localize({ message: 'LtiLaunchBar.Title', options: { name: authUser.profile.name } })}
      </h3>
      <p className="lti-launch-bar__content">
        <TranslatedText
          i18nKey="LtiLaunchBar.Content"
          values={{ courseName: edlinkClassInfo.name }}
        />
      </p>

      <ButtonContainer className="lti-launch-bar-btn-container">
        <Button
          type="primary"
          testid="lti-create-new-course-btn"
          onClick={() => createConnectedCourse({ edlinkClassInfo })}
        >
          <i className="fa fa-plus" style={{ paddingRight: 10 }} />
          {localize({ message: 'LtiLaunchBar.CreateCourseBtn' })}
        </Button>
        {canDuplicate && (
          <Button
            type="primary"
            testid="lti-duplicate-course-btn"
            onClick={() => toggleDuplicateDialog()}
          >
            <i className="fa fa-copy" style={{ paddingRight: 10 }} />
            {localize({ message: 'LtiLaunchBar.DuplicateCourseBtn' })}
          </Button>
        )}
        <Button
          type="secondary"
          testid="lti-setup-later-btn"
          className="pull-right"
          onClick={hideBanner}
        >
          {localize({ message: 'LtiLaunchBar.SetupLaterBtn' })}
        </Button>
      </ButtonContainer>
      {showDuplicateDialog && (
        <LtiLaunchDuplicateDialog
          open={showDuplicateDialog}
          onClose={toggleDuplicateDialog}
          coursesList={coursesList}
          onSubmit={duplicateLtiLaunchConnectedCourse}
          ltiCourseName={edlinkClassInfo.name}
        />
      )}
    </div>
  );
};

export default withRouter(
  connect(
    (state: any) => ({
      authUser: state.user.authUser,
      coursesList: getCourseList(state),
    }),
    { logOut }
  )(LtiLaunchBar)
);
