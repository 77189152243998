const DEPARTMENTS = [
  'Agriculture',
  'Anthropology',
  'Architecture',
  'Biology',
  'Business Administration',
  'Chemistry',
  'Communications',
  'Computer Science',
  'Criminal Justice',
  'Economics',
  'Education',
  'Electrical Engineering',
  'English',
  'Environmental Science',
  'Film and Media Studies',
  'Fine Arts (Visual Arts, Performing Arts)',
  'Foreign Languages (e.g., Spanish, French, etc.)',
  'Geology/Earth Sciences',
  'Health Sciences',
  'History',
  'Hospitality Management',
  'Mathematics',
  'Mechanical Engineering',
  'Nursing',
  'Nutrition and Dietetics',
  'Philosophy',
  'Physics',
  'Political Science',
  'Psychology',
  'Public Health',
  'Social Work',
  'Sociology',
];

export default {
  DEPARTMENTS,
};

export const MINIMUM_WORD_COUNT = 20;
