import { combineReducers } from 'redux';
import { generateAsyncReducer } from 'redux/utils';
import types from './types';

const asyncActions = {
  call: [types.DOWNLOAD_GRADEBOOK],
  success: [types.DOWNLOAD_GRADEBOOK_SUCCESS],
  error: [types.DOWNLOAD_GRADEBOOK_FAILURE],
};

export default combineReducers({
  async: generateAsyncReducer(asyncActions),
});
