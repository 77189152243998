import React from 'react';
import classNames from 'classnames';

const EnvironmentBar = ({ currentEnv, branch }: any) => {
  const environment = currentEnv.charAt(0).toUpperCase() + currentEnv.slice(1); // uppercase first letter
  const classes = classNames('environment-bar', {
    'dev-bg': currentEnv === 'development',
    'tst-bg': currentEnv === 'test',
    'stg-bg': currentEnv === 'staging',
  });

  return (
    <div className={classes}>
      <p className="environment-bar__title">
        <span>{environment}</span>{' '}
        {branch && <span className="environment-bar__branch">({branch})</span>}{' '}
        <span>
          This is <strong>Kritik's STAGING</strong> environment, and is only intended for use by
          Kritik employees. If you are an instructor or a student, please go to{' '}
          <a className="environment-bar__login-link" href="https://app.kritik.io">
            https://app.kritik.io
          </a>{' '}
          to login.
        </span>
      </p>
    </div>
  );
};

export default React.memo(EnvironmentBar);
