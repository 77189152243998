import React from 'react';
import classNames from 'classnames';

const LABELS = {
  SUCCESS: 'success',
  CAUTION: 'caution',
  DANGER: 'danger',
  ATTENTION: 'attention',
  INFORMATION: 'information',
  LIGHT_BLUE: 'light-blue',
  PENDING: 'pending',
  TRANSPARENT: 'no-bg',
};

const InfoLabel = ({ className, label, type, testid }: any) => {
  const displayClasses = classNames(
    'information-label',
    {
      'information-label__success': LABELS.SUCCESS === type,
      'information-label__caution': LABELS.CAUTION === type,
      'information-label__danger': LABELS.DANGER === type,
      'information-label__attention': LABELS.ATTENTION === type,
      'information-label__information': LABELS.INFORMATION === type,
      'information-label__light-blue': LABELS.LIGHT_BLUE === type,
      'information-label__transparent': LABELS.TRANSPARENT === type,
      'information-label__pending': LABELS.PENDING === type,
    },
    className
  );

  return (
    <div className={displayClasses} data-testid={testid}>
      {label}
    </div>
  );
};

export default InfoLabel;
