import { assignmentStatuses as STATUS_LABELS } from '@kritik/constants/stage';
import * as assignmentStatusUtil from '@kritik/utils/stage';

export const isNodeActive = (status: any, activity: any) => {
  if (assignmentStatusUtil.isActiveStatus(status)) {
    return true;
  }
  const isCreateStatus = status.name === STATUS_LABELS.CREATE;
  const isInGracePeriod = assignmentStatusUtil.isInCreationGracePeriod(activity);
  const isEvaluateStatus = status.name === STATUS_LABELS.EVALUATE;
  const isInEvaluateGracePeriod = assignmentStatusUtil.isInEvaluationGracePeriod(activity);
  if (isCreateStatus && isInGracePeriod) {
    return true;
  }
  if (isEvaluateStatus && isInEvaluateGracePeriod) {
    return true;
  }

  return false;
};

export const isNodeStartingNext = (status: any, activity: any) => {
  if (assignmentStatusUtil.isInCreationGracePeriod(activity)) {
    return false;
  }
  if (assignmentStatusUtil.isInEvaluationGracePeriod(activity)) {
    return false;
  }
  return assignmentStatusUtil.isStatusStartingNext(status, activity);
};

type StageStatusType = 'starting' | 'active' | 'complete' | null;

const getStageStatus = (status: any, assignment: any): StageStatusType => {
  const isStageActive = isNodeActive(status, assignment);
  const isStageComplete = assignmentStatusUtil.isPastStatus(status, assignment);
  const isActivityStarting = isNodeStartingNext(status, assignment);

  if (isActivityStarting) {
    return 'starting';
  }
  if (isStageActive) {
    return 'active';
  }
  if (isStageComplete) {
    return 'complete';
  }
  return null;
};

export default {
  isNodeActive,
  isNodeStartingNext,
  getStageStatus,
};
