import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getMarkingSchemeBreakDowns } from '@kritik/utils/course';
import { getCourse } from 'selectors/course';
import { selectGradeHistories, selectDashboardGrades } from 'selectors/grade';
import { getPastAssignments } from 'selectors/activity';
import { setDashboardScores } from 'actions/courses';
import { calculateAvgGradeChanges } from './calcGrades';
import ScoreCard from './ScoreCard';
import { useUserRoleInCourse } from 'hooks/course';
import { TranslatedText } from 'components/TranslatedText';

const mapStateToProps = (state: any) => {
  return {
    course: getCourse(state),
    avgGrades: selectDashboardGrades(state),
    gradeHistories: selectGradeHistories(state),
    finalizedActivities: getPastAssignments(state, state.selected.courseId),
  };
};

const ActivityListDashboard = ({
  gradeHistories,
  course,
  avgGrades,
  setDashboardScores,
  finalizedActivities,
}: any) => {
  const [avgGradeChanges, setAvgGradeChanges] = useState({});
  const { isInstructorInCourse } = useUserRoleInCourse();

  useEffect(() => {
    // 1. when score is not calculated - check if all scores are null in store
    // 2. when course changed - select course action chain
    // 3. When finalized an assignment - finalize assignments action chain

    // Add gradeHistories check for fixing case that we need to wait
    // wait for getCourse action to get an individual course with gradeHistories field
    if (course.gradeHistories && finalizedActivities.length) {
      setDashboardScores(course._id);
      setAvgGradeChanges(calculateAvgGradeChanges(gradeHistories, course, finalizedActivities));
    }
  }, [course._id, gradeHistories.length, finalizedActivities.length]);

  const instructorDescriptionTextEvalScore = {
    overallScoreDescription: 'Average of all scores of your students based on their grading weight',
    overallScoreHeader: 'Overall',
    creationScoreDescription: 'Average score of creations in your course',
    creationScoreHeader: 'Creation',
    evalScoreDescription: 'Average score of evaluations in your course',
    evalScoreHeader: 'Evaluation',
    feedbackScoreDescription: 'Participation in feedback',
    feedbackScoreHeader: 'Feedback',
  };

  const studentDescriptionTextEvalScore = {
    overallScoreHeader: 'Overall',
    overallScoreDescription:
      'The Overall Score is an average of all your scores, based on their grading weight.',
    creationScoreDescription:
      'Your creation will be scored by your peers based on the rubric provided by your instructor.',
    creationScoreHeader: 'Creation',
    evalScoreDescription:
      "Your evaluation score is based on how well you grade your peer's creations.",
    evalScoreHeader: 'Evaluation',
    feedbackScoreDescription:
      'The Feedback Score is based on your participation in the feedback stage.',
    feedbackScoreHeader: 'Feedback',
  };

  const getScoreBoxDescripton = () => {
    let scoreBoxDescription = {};
    if (isInstructorInCourse) {
      scoreBoxDescription = instructorDescriptionTextEvalScore;
    } else {
      scoreBoxDescription = studentDescriptionTextEvalScore;
    }
    return scoreBoxDescription;
  };

  const description = getScoreBoxDescripton();
  const markingSchemeBreakdowns = getMarkingSchemeBreakDowns(course);

  return (
    <>
      <h2 className="new-header-2">
        <TranslatedText i18nKey="Activity.Dashboard.KritikScore" />
      </h2>
      <div className="activityList-boxes-wrapper">
        <ScoreCard
          score={avgGrades.avgOverallGrade}
          scoreTrend={(avgGradeChanges as any).overall}
          scoreName={(description as any).overallScoreHeader}
          description={(description as any).overallScoreDescription}
          weight={null}
          ariaLabel="overall score description"
        />
        <ScoreCard
          score={avgGrades.avgCreationGrade}
          scoreTrend={(avgGradeChanges as any).creation}
          scoreName={(description as any).creationScoreHeader}
          description={(description as any).creationScoreDescription}
          weight={markingSchemeBreakdowns.creation}
          ariaLabel="creation grade description"
        />
        <ScoreCard
          score={avgGrades.avgEvalGrade}
          scoreTrend={(avgGradeChanges as any).evaluation}
          scoreName={(description as any).evalScoreHeader}
          description={(description as any).evalScoreDescription}
          weight={markingSchemeBreakdowns.evaluation}
          ariaLabel="evaluation grade description"
        />
        <ScoreCard
          score={avgGrades.avgFeedbackGrade}
          scoreTrend={(avgGradeChanges as any).feedback}
          scoreName={(description as any).feedbackScoreHeader}
          description={(description as any).feedbackScoreDescription}
          weight={markingSchemeBreakdowns.feedback}
          ariaLabel="feedback score description"
        />
      </div>
    </>
  );
};

export default connect(mapStateToProps, {
  setDashboardScores,
})(ActivityListDashboard);
