import React, { useState } from 'react';
import { connect } from 'react-redux';
import GradebookActions from 'redux/gradebook/actions';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/buttons/ButtonContainer';
import DownloadGradebookModal from 'components/DownloadGradebookModal';
import DownloadCreationsModal from 'components/DownloadCreationsModal';
import { selectCurrentCourse } from 'selectors/course';

const mapStateToProps = (state: any) => {
  return {
    course: selectCurrentCourse(state),
  };
};

function GradesDownloadActions({ course }: any) {
  const [isDownloadGradebookOpen, setIsDownloadGradebookOpen] = useState(false);
  const [isDownloadCreationsOpen, setIsDownloadCreationsOpen] = useState(false);
  const onClickDownloadGradebook = () => {
    setIsDownloadGradebookOpen(true);
  };
  const onClickDownloadCreations = () => {
    setIsDownloadCreationsOpen(true);
  };
  return (
    <div>
      <h3>Grades</h3>
      <p>Please select what data you want to download</p>
      <ButtonContainer>
        <Button onClick={onClickDownloadGradebook} type="primary">
          Download Gradebook
        </Button>
        <Button onClick={onClickDownloadCreations} type="secondary">
          Download Creations
        </Button>
      </ButtonContainer>
      <DownloadGradebookModal
        course={course}
        isOpen={isDownloadGradebookOpen}
        setIsOpen={setIsDownloadGradebookOpen}
      />
      <DownloadCreationsModal
        isOpen={isDownloadCreationsOpen}
        setIsOpen={setIsDownloadCreationsOpen}
        course={course}
      />
    </div>
  );
}

export default connect(mapStateToProps, {
  downloadGradebook: GradebookActions.downloadGradebook,
})(GradesDownloadActions);
