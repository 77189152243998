import classNames from 'classnames';
import { Button } from 'components/buttons';
import ArrowLeft from 'images/icons/fa/ArrowLeft';

const PageHeader = ({ className, action, title, separator, children, testid }: any) => {
  const headerClasses = classNames(
    'kritik-page-header',
    {
      'kritik-page-header--separator': separator,
    },
    className
  );

  const renderAction = () => {
    if (action) {
      return (
        <div className="kritik-page-header__action">
          <Button
            type="secondary"
            onClick={() => {
              return action();
            }}
            style={{ minWidth: '60px' }}
          >
            <ArrowLeft />
          </Button>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={headerClasses}>
      {renderAction()}
      <h1 className="ui page-title" data-testid={testid}>
        {title}
      </h1>
      <div className="kritik-page-header__options no-print">{children}</div>
    </div>
  );
};

PageHeader.defaultProps = {
  separator: false,
  action: null,
};

export default PageHeader;
