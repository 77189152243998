import React, { useEffect, useState, useRef } from 'react';
import PopperMenu from '../PopperMenu';

const OverlayMenu = (props: any) => {
  const ref = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setAnchorEl(ref.current);
  }, [props.rightCoordinate, props.rowHeight, props.topCoordinate]);

  const handleAction = (callBack: any) => {
    props.onClose();
    if (typeof callBack === 'function') {
      callBack();
    }
  };

  return (
    <>
      <div
        ref={ref}
        className="rubric-select-overlay"
        style={{
          left: props.xCoordinate,
          top: props.yCoordinate,
        }}
      />
      <PopperMenu
        anchorEl={anchorEl}
        placement="right-start"
        options={[
          {
            label: 'Add Criterion above',
            action: (e: any) => handleAction(props.handleAddCriteria(e, props.clickedRowIndex)),
          },
          {
            label: 'Add Criterion below',
            action: (e: any) => handleAction(props.handleAddCriteria(e, props.clickedRowIndex + 1)),
          },
          {
            label: 'Add Level to Criterion',
            action: (e: any) => handleAction(props.handleAddLevel(e, [props.clickedRowIndex])),
          },
          {
            label: 'Delete Criterion',
            action: (e: any) =>
              handleAction(props.handleRemoveCriteria(e, [props.clickedRowIndex])),
          },
        ]}
      />
    </>
  );
};

OverlayMenu.defaultProps = {
  rightCoordinate: '',
  rowHeight: '',
  topCoordinate: '',
};

export default OverlayMenu;
