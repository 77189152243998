import React from 'react';
import RadioInput from 'components/core/input/Radio';
import { GRADE_SYNC_TYPE } from 'components/LmsGradeSync/constants';
import { localize } from 'locales/index';

const options = [
  {
    value: 'activity',
    onClick: null,
    label: 'By Activity',
    testid: 'set-by-activity',
  },
  { value: 'overall', onClick: null, label: 'No Breakdown' },
];

function ScoreSyncBreakdownSwitch(props: any) {
  if (!props.isNewIntegration) {
    return null;
  }

  const renderLabel = () => {
    if (props.value === GRADE_SYNC_TYPE.ACTIVITY) {
      return <p>One score per activity a student completed</p>;
    }
    return <p>One overall score per student</p>;
  };
  return (
    <div className="scoretypesync__switch">
      <h4>Score syncing breakdown</h4>
      <p>How would you like students scores synced to your LMS</p>
      <RadioInput
        value={props.value}
        options={options}
        onChange={props.onChange}
        type="button"
        legendText="Legend.Text.LMS.ScoreSyncBreakdown"
      />
      {renderLabel()}
    </div>
  );
}

export default ScoreSyncBreakdownSwitch;
