import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

const ProgressBar = (props: any) => {
  let styleProgress = props.progress.toString();
  if (!styleProgress.includes('%')) {
    styleProgress += '%';
  }
  return (
    <div className="loader-progress-bar">
      <div className="loader-progress-bar__outer">
        <LinearProgress className="loader-progress-bar__inner" style={{ width: styleProgress }} />
      </div>
      <div className="loader-progress-bar__label"> {props.progressLabel} </div>
    </div>
  );
};

export default ProgressBar;
