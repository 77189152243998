import React, { useEffect, useState } from 'react';
import { Chip } from '@material-ui/core';
import PageContainer from 'components/layout/PageContainer';
import { Button } from 'components/buttons';
import SyllabusFileUpload from 'components/SyllabusFileUpload';
import { useFetchGenerateActivityIdeas, createAiActivityMutation } from 'hooks/ai';
import { useFetchCourse } from 'hooks/course';
import { localize } from 'locales/index';
import { TranslatedText } from 'components/TranslatedText';
import InformativeLoadingScreen from 'components/Loaders/InformativeLoadingScreen';
import { RouterProp } from 'utils/withRouter';
import { trackEvent } from 'utils/userEvents';
import { InlineInformation } from 'components/layout';
import Spinner from 'components/Loaders/Spinner';
import { getSyllabusFile } from './utils';
import { useIsFeatureFlagEnabled } from 'context/growthbookContext';
import { AiGeneratedActivity, AuthUser } from 'app-types';
import InfoPopup from 'components/core/InfoPopup';

type Props = {
  router?: RouterProp;
  authUser: AuthUser;
};

// Duplicated from client/components/layout/PageHeader.tsx with custom CSS to allow for BETA tag to
// fit beside the title
function PageHeader({ title, children }: any) {
  return (
    <div className="kritik-page-header2">
      <h1 className="ui page-title">{title}</h1>
      <div className="spacer"></div>
      <div className="kritik-page-header__options no-print">{children}</div>
    </div>
  );
}

type UiActivityTypes = 'Peer' | 'Group' | 'GroupAssessment' | 'IndividualPresentation';
type MoreLessLikeThis = ai.generate_activity_ideas.POST.Request['moreLessLikeThis'];

export default function CreateActivityAI(props: Props) {
  const courseId = props.router.params.courseId;
  const authUser = props.authUser;
  const { data: course } = useFetchCourse(courseId);
  const [activityIdeas, setAiActivityIdeas] = useState([]);
  const [syllabusFileId, setSyllabusFileId] = useState<string | null>(
    getSyllabusFile(course?.files)?._id?.toString() || null
  );

  const [activeActivityType, setActiveActivityType] = useState<UiActivityTypes>('Peer');
  const [moreLessLikeThis, setMoreLessLikeThis] = useState<MoreLessLikeThis>();
  const isAiActivityCreationEnabled = useIsFeatureFlagEnabled('create_activity_ai');

  const generateActivityIdeas = useFetchGenerateActivityIdeas({
    courseCode: course.code,
    courseName: course.title,
    syllabusFileId,
    activityType: activeActivityType,
    numActivities: 5,
    moreLessLikeThis,
    onSuccess: (data: AiGeneratedActivity[]) => {
      setAiActivityIdeas([...activityIdeas, ...data]);
    },
  });
  const createActivity = createAiActivityMutation({ authUser, courseId });

  useEffect(() => {
    if (createActivity.isSuccess) {
      props.router.push(`/course/${courseId}/assignment/${createActivity.data.activityId}`);
    }
  }, [createActivity.isSuccess]);

  if (!isAiActivityCreationEnabled) {
    props.router.push(`/`);
  }

  async function handleLoadMore() {
    await generateActivityIdeas.refetch();
    trackEvent('AI Activity More Examples', authUser);
  }

  function handleCreateFromAiIdea(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    const { dataset } = e.target as HTMLButtonElement;
    const idea = activityIdeas[dataset.id];
    const text = idea.heading + ': ' + idea.description;
    createActivity.mutate({
      idea: text,
      isProblemSet: idea.isProblemSet,
      isTeachAPeer: idea.isTeachAPeer,
      courseId,
      syllabusFileId,
      activityType: activeActivityType,
    });
  }

  function handleCreateActivityManually() {
    props.router.push(`/course/${courseId}/create-activity`);
  }

  function handleNaviateToActivities() {
    props.router.push(`/course/${courseId}/assignments`);
  }

  function syllabusUploaded(file: any) {
    setSyllabusFileId(file.fileId);
    setAiActivityIdeas([]);
    setMoreLessLikeThis(undefined);

    trackEvent('Syllabus Uploaded', authUser, {
      courseId: courseId,
      courseName: course.title,
    });
  }

  function syllabusRemoved() {
    setSyllabusFileId(null);
    setAiActivityIdeas([]);
    setMoreLessLikeThis(undefined);
  }

  function handleGenerateActivityIdeas() {
    setAiActivityIdeas([]);
    setMoreLessLikeThis(undefined);
    generateActivityIdeas.refetch();
  }

  function handleMoreLessLikeThis(type: MoreLessLikeThis) {
    if (moreLessLikeThis === type) {
      return;
    }
    switch (type) {
      case 'more-problem-set':
        trackEvent('More like this AI Suggestion', authUser, {
          courseId,
          activityType: 'Problem Set',
        });
        break;
      case 'more-teach-a-peer':
        trackEvent('More like this AI Suggestion', authUser, {
          courseId,
          activityType: 'Teach a Peer',
        });
        break;
      case 'less-problem-set':
        trackEvent('Disliked AI Suggestion', authUser, {
          courseId,
          activityType: 'Problem Set',
        });
        break;
      case 'less-teach-a-peer':
        trackEvent('Disliked AI Suggestion', authUser, {
          courseId,
          activityType: 'Teach a Peer',
        });
        break;
    }
    setAiActivityIdeas([]);
    setMoreLessLikeThis(type);
  }

  function handleGenerateWithinGroupActivity() {
    setAiActivityIdeas([]);
    setActiveActivityType('GroupAssessment');
  }

  function handleGeneratePresentationActivity() {
    setAiActivityIdeas([]);
    setActiveActivityType('IndividualPresentation');
  }

  function handleGenerateIndividualActivity() {
    if (activeActivityType === 'Peer') {
      return;
    }
    setAiActivityIdeas([]);
    setMoreLessLikeThis(undefined);
    setActiveActivityType('Peer');
  }

  function handleGenerateBetweenGroupActivity() {
    if (activeActivityType === 'Group') {
      return;
    }
    setAiActivityIdeas([]);
    setMoreLessLikeThis(undefined);
    setActiveActivityType('Group');
  }

  return (
    <PageContainer>
      <PageHeader title={localize({ message: 'Activity.CreateAI.PageTitle' })}>
        <Button type="secondary" onClick={handleNaviateToActivities} role="link">
          <TranslatedText i18nKey="Activity.CreateAI.BackToActivities" />
        </Button>
        <Button
          type="secondary"
          onClick={handleCreateActivityManually}
          style={{ marginLeft: '1em' }}
          role="link"
        >
          <TranslatedText i18nKey="Activity.CreateAI.CreateManually" />
          &nbsp;&nbsp;
          <i className="fa fa-arrow-right spacing" />
        </Button>
      </PageHeader>

      <div className="generate-activity-ai">
        {createActivity.isError && (
          <InlineInformation style={{ margin: '1em' }} type="danger">
            <TranslatedText i18nKey="Activity.CreateAI.Create.Error" />
          </InlineInformation>
        )}

        {!createActivity.isLoading && (
          <>
            <div className="generate-instructions">
              <TranslatedText i18nKey="Activity.CreateAI.Instructions" />
            </div>
            <div className="upload-syllabus">
              <div style={{ paddingRight: '1em' }}>
                <div className="upload-syllabus-header">
                  <TranslatedText i18nKey="Activity.CreateAI.UploadSyllabus.Header" />
                </div>
                <TranslatedText i18nKey="Activity.CreateAI.UploadSyllabus.Instructions" />
              </div>

              <div>
                <SyllabusFileUpload
                  courseId={courseId}
                  existingSyllabus={getSyllabusFile(course.files)}
                  onUploadFile={syllabusUploaded}
                  onRemoveFile={syllabusRemoved}
                />
              </div>
            </div>

            <Button
              type="primary"
              inputType="submit"
              onClick={handleGenerateActivityIdeas}
              style={{ marginTop: '1em' }}
            >
              <i aria-hidden="true" className="fa fa-magic" />{' '}
              <TranslatedText i18nKey="Activity.CreateAI.GenerateActivities.Button" />
            </Button>

            <hr />
            <h2 className="ui page-title">
              <TranslatedText i18nKey="Activity.CreateAI.ActivityIdeas.Title" />
            </h2>
            <p>
              <TranslatedText i18nKey="Activity.CreateAI.ActivityIdeas.Instructions" />
            </p>

            <div className="generate-buttons-container">
              <div className="generate-button-title">
                <TranslatedText i18nKey="Activity.CreateAI.Generate.Header" />
                <InfoPopup
                  title={localize({ message: 'Activity.CreateAI.InfoPopup.Title' })}
                  description={<TranslatedText i18nKey="Activity.CreateAI.InfoPopup.Description" />}
                />
              </div>
              <div className="generate-buttons">
                <fieldset className="generate-buttons__fieldset">
                  <legend className="visually-hidden">
                    <TranslatedText i18nKey="Activity.CreateAI.Generate.FieldSet" />
                  </legend>
                  <Button
                    type="secondary"
                    onClick={handleGenerateIndividualActivity}
                    isActive={activeActivityType === 'Peer'}
                    aria-pressed={activeActivityType === 'Peer'}
                  >
                    {activeActivityType === 'Peer' && <i className="fa fa-check" />}{' '}
                    <TranslatedText i18nKey="Activity.CreateAI.Generate.IndividualButton" />
                  </Button>

                  <Button
                    type="secondary"
                    onClick={handleGenerateBetweenGroupActivity}
                    isActive={activeActivityType === 'Group'}
                    aria-pressed={activeActivityType === 'Group'}
                  >
                    {activeActivityType === 'Group' && <i className="fa fa-check" />}{' '}
                    <TranslatedText i18nKey="Activity.CreateAI.Generate.BetweenGroupButton" />
                  </Button>
                  <Button
                    type="secondary"
                    onClick={handleGenerateWithinGroupActivity}
                    isActive={activeActivityType === 'GroupAssessment'}
                    aria-pressed={activeActivityType === 'GroupAssessment'}
                  >
                    {activeActivityType === 'GroupAssessment' && <i className="fa fa-check" />}{' '}
                    <TranslatedText i18nKey="Activity.CreateAI.Generate.WithinGroupButton" />
                  </Button>
                  <Button
                    type="secondary"
                    onClick={handleGeneratePresentationActivity}
                    isActive={activeActivityType === 'IndividualPresentation'}
                    aria-pressed={activeActivityType === 'IndividualPresentation'}
                  >
                    {activeActivityType === 'IndividualPresentation' && (
                      <i className="fa fa-check" />
                    )}{' '}
                    <TranslatedText i18nKey="Activity.CreateAI.Generate.PresentationButton" />
                  </Button>
                </fieldset>
              </div>

              <div className="helptip">
                <TranslatedText i18nKey="Activity.CreateAI.Generate.SubText" />
              </div>
            </div>

            <table className="activity-ideas__table" role="table" aria-labelledby="tableCaption">
              <caption id="tableCaption" className="visually-hidden">
                <TranslatedText i18nKey="Activity.CreateAI.ActivityIdeas.Title" />
              </caption>
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="activity-ideas__grid-item"
                    aria-label={localize({
                      message: 'Activity.CreateAI.ActivityIdeas.Header.Title',
                    })}
                  >
                    <strong>
                      <TranslatedText i18nKey="Activity.CreateAI.ActivityIdeas.Header.Title" />
                    </strong>
                  </th>
                  <th
                    scope="col"
                    className="activity-ideas__grid-item"
                    aria-label={localize({
                      message: 'Activity.CreateAI.ActivityIdeas.Header.Summary',
                    })}
                  >
                    <strong>
                      <TranslatedText i18nKey="Activity.CreateAI.ActivityIdeas.Header.Summary" />
                    </strong>
                  </th>
                  <th
                    scope="col"
                    className="activity-ideas__grid-item"
                    aria-label={localize({
                      message: 'Activity.CreateAI.ActivityIdeas.Header.Action',
                    })}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {activityIdeas.map(
                  (
                    idea: {
                      heading: string;
                      description: string;
                      isProblemSet: boolean;
                      isTeachAPeer: boolean;
                    },
                    id: number
                  ) => {
                    const isProblemSetOrTeachAPeer = idea.isProblemSet || idea.isTeachAPeer;
                    return (
                      <tr key={id}>
                        <td className="activity-ideas__grid-summary activity-ideas__grid-item activity-ideas__grid-title">
                          <strong>{idea.heading}</strong>
                          <Chip
                            classes={{
                              root: 'grid-title__chip',
                              label: 'grid-title__label',
                            }}
                            label={
                              idea.isProblemSet ? (
                                <TranslatedText i18nKey="Activity.CreateAI.ActivityIdeas.Type.ProblemSet" />
                              ) : idea.isTeachAPeer ? (
                                <TranslatedText i18nKey="Activity.CreateAI.ActivityIdeas.Type.TeachAPeer" />
                              ) : activeActivityType === 'Group' ? (
                                <TranslatedText i18nKey="Activity.CreateAI.Generate.BetweenGroupButton" />
                              ) : activeActivityType === 'GroupAssessment' ? (
                                <TranslatedText i18nKey="Activity.CreateAI.Generate.WithinGroupButton" />
                              ) : activeActivityType === 'IndividualPresentation' ? (
                                <TranslatedText i18nKey="Activity.CreateAI.Generate.PresentationButton" />
                              ) : (
                                <TranslatedText i18nKey="Activity.CreateAI.Generate.IndividualButton" />
                              )
                            }
                            color="default"
                            size="small"
                          />
                          {isProblemSetOrTeachAPeer && (
                            <div className="activity-ideas__grid-title__more-or-less-like-this">
                              <Button
                                type="hidden"
                                title={localize({
                                  message: 'Activity.CreateAI.ActivityIdeas.MoreLikeThis',
                                })}
                                className="activity-ideas__grid-title__more-or-less-like-this--button"
                                onClick={() =>
                                  handleMoreLessLikeThis(
                                    idea.isProblemSet ? 'more-problem-set' : 'more-teach-a-peer'
                                  )
                                }
                              >
                                <i className="fa fa-magic activity-ideas__grid-title__more-or-less-like-this--icon"></i>
                              </Button>
                              <Button
                                type="hidden"
                                title={localize({
                                  message: 'Activity.CreateAI.ActivityIdeas.LessLikeThis',
                                })}
                                className="activity-ideas__grid-title__more-or-less-like-this--button"
                                onClick={() =>
                                  handleMoreLessLikeThis(
                                    idea.isProblemSet ? 'less-problem-set' : 'less-teach-a-peer'
                                  )
                                }
                              >
                                <i className="fa fa-thumbs-down activity-ideas__grid-title__more-or-less-like-this--icon"></i>
                              </Button>
                            </div>
                          )}
                        </td>
                        <td className="activity-ideas__grid-summary activity-ideas__grid-item">
                          {idea.description}
                        </td>
                        <td className="activity-ideas__grid-item">
                          <Button
                            type="secondary"
                            onClick={handleCreateFromAiIdea}
                            data-id={id}
                            label={localize({
                              message: 'Activity.CreateAI.ActivityIdeas.Button.AriaLabel',
                              options: { title: idea.heading },
                            })}
                          >
                            <TranslatedText i18nKey="Activity.CreateAI.ActivityIdeas.CreateButton" />
                          </Button>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>

            {generateActivityIdeas.isError && (
              <InlineInformation style={{ margin: '1em' }} type="danger">
                <TranslatedText i18nKey="Activity.CreateAI.ActivityIdeas.Error" />
              </InlineInformation>
            )}

            {generateActivityIdeas.isFetching && (
              <div className="loading-ideas">
                <Spinner />
                <p>
                  <TranslatedText i18nKey="Activity.CreateAI.ActivityIdeas.Loading" />
                </p>
              </div>
            )}

            {activityIdeas.length > 0 && (
              <Button
                type="secondary"
                style={{ marginTop: '1em' }}
                onClick={async () => {
                  await handleLoadMore();
                }}
              >
                <i aria-hidden="true" className="fa fa-rotate-right" />{' '}
                <TranslatedText i18nKey="Activity.CreateAI.ActivityIdeas.LoadMoreButton" />
              </Button>
            )}
          </>
        )}

        {createActivity.isLoading && (
          <InformativeLoadingScreen
            loadingText="Generating Learning Objective, Instructions, and Rubric for your activity, this may take a while"
            left="student_quotes"
            right="student_improvements"
          />
        )}
      </div>
    </PageContainer>
  );
}
