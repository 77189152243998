export function SpotlightTag(tag: any) {
  if (!tag) {
    return null;
  }
  return {
    ...tag,
    getId: () => {
      return tag._id;
    },
    getName: () => {
      return tag.name;
    },
    getType: () => {
      return tag.type;
    },
    getDefinition: () => {
      return tag.definition;
    },
  };
}
