import React from 'react';
import {
  DisputeLabel,
  DisputeResolvedLabel,
  RaisedFlagLabel,
  ResolvedFlagLabel,
} from 'components/AssignmentLabels/StatusLabels';
import Access from 'components/Access';
import HorizontalList from 'components/layout/HorizontalList';
import * as CreationStatusUtils from '@kritik/utils/creation/status';
import * as EvaluationUtils from '@kritik/utils/creation/evaluation';

const StatusCell = (props: any) => {
  const getInstructorItemList = () => {
    const itemList = [];
    if (props.activity.numDisputes > 0) {
      itemList.push(
        <div className="activity-list__status">
          <DisputeLabel disputes={props.activity.numDisputes} />
        </div>
      );
    }

    if (props.activity.numDisputesResolved > 0) {
      itemList.push(
        <div className="activity-list__status">
          <DisputeResolvedLabel disputes={props.activity.numDisputesResolved} />
        </div>
      );
    }

    if (props.activity.numFlags > 0) {
      itemList.push(
        <div className="activity-list__status">
          <RaisedFlagLabel flags={props.activity.numFlags} />
        </div>
      );
    }
    if (props.activity.numFlagsResolved > 0) {
      itemList.push(
        <div className="activity-list__status">
          <ResolvedFlagLabel flags={props.activity.numFlagsResolved} />
        </div>
      );
    }
    return itemList;
  };

  const getStudentItemList = () => {
    const itemList: any = [];
    if (!props.creation) {
      return itemList;
    }
    if (CreationStatusUtils.isDispute(props.creation)) {
      itemList.push(
        <div className="activity-list__status">
          <DisputeLabel />
        </div>
      );
    }
    if (CreationStatusUtils.isDisputeResolved(props.creation)) {
      itemList.push(
        <div className="activity-list__status">
          <DisputeResolvedLabel />
        </div>
      );
    }

    if (EvaluationUtils.isAnyEvaluationFlaggedByStudent(props.creation.scores, props.student._id)) {
      itemList.push(
        <div className="activity-list__status">
          <RaisedFlagLabel />
        </div>
      );
    }
    if (EvaluationUtils.isAllEvaluationsFlagsResolved(props.creation.scores, props.student._id)) {
      itemList.push(
        <div className="activity-list__status">
          <ResolvedFlagLabel />
        </div>
      );
    }
    return itemList;
  };

  return (
    <Access
      instructor={<HorizontalList align="left" itemList={getInstructorItemList()} />}
      student={<HorizontalList align="left" itemList={getStudentItemList()} />}
    />
  );
};

export default StatusCell;
