import React from 'react';

const SideBar = (props: any) => {
  const renderSidebarItems = () => {
    return props.items.map((item: any, index: any) => {
      return (
        <div key={`${index}-element`} className="separator">
          {item}
        </div>
      );
    });
  };

  return (
    <div className="activity-overview-sidebar">
      <div className="activity-overview-sidebar--sticky">{renderSidebarItems()}</div>
    </div>
  );
};

export default SideBar;
