import { useState } from 'react';
import { connect } from 'react-redux';
import { countryCodesOptions } from 'components/Setting/countrycodes';
import Button from 'components/buttons/Button';
import * as GeneralUtils from '@kritik/utils/general';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import InlineInformation from 'components/layout/InlineInformation';
import { updateAccountInformation } from 'actions/users';
import UpdateEmailNotice from 'components/Setting/UpdateEmailNotice';
import { getErrorMessageFromResponse } from 'utils/error';
import { Card, CardContent, CardActions } from '@material-ui/core';
import AutoCompleteField from 'components/core/form/AutoCompleteField';
import TextField from 'components/core/form/TextField';
import { localize } from 'locales';

const AccountSetting = (props: any) => {
  const [success, setSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(props.authUser?.phoneNumber || '');
  const [countryCode, setCountryCode] = useState('');
  const [email, setEmail] = useState(props.authUser.email);

  function updatePhoneNumber(countryCode: any, phoneNumber: any) {
    setPhoneNumber(phoneNumber);
    setCountryCode(countryCode);
    setError(false);
    setSuccess(false);
  }

  function updateEmail(email: any) {
    setEmail(email);
    setError(false);
    setSuccess(false);
  }

  function phoneNumberChanged() {
    const newNumber = countryCode + phoneNumber;
    return newNumber !== props.authUser?.phoneNumber && !success;
  }

  function emailChanged() {
    return email !== props.authUser?.email && !success;
  }

  function hasUpdate() {
    return emailChanged() || phoneNumberChanged();
  }

  function handleSave() {
    const dataToSubmit = { userId: props.authUser.id };
    if (phoneNumberChanged()) {
      const _countryCode = GeneralUtils.sanitizeCountryCode(countryCode);
      if (
        phoneNumber.length > 0 &&
        !GeneralUtils.isValidE164PhoneNumber(_countryCode + phoneNumber)
      ) {
        setError(localize({ message: 'WrongPhoneNumberFormat' }));
        return;
      } else {
        (dataToSubmit as any).phoneNumber = phoneNumber === '' ? '' : _countryCode + phoneNumber;
      }
    }
    if (emailChanged()) {
      if (GeneralUtils.isEmail(email)) {
        (dataToSubmit as any).email = email;
      } else {
        setError('Please enter a valid email');
        return;
      }
    }
    setSubmitting(true);
    props
      .updateAccountInformation(dataToSubmit)
      .then(() => {
        setSubmitting(false);
        setSuccess(true);
        props.onSuccess();
      })
      .catch((err: any) => {
        setSubmitting(false);
        setSuccess(false);
        setError(
          getErrorMessageFromResponse(err?.errors?.message) ||
            'Something went wrong. Please try again later.'
        );
      });
  }

  function resetFields() {
    setSuccess(false);
    setError(null);
    setPhoneNumber(props.authUser?.phoneNumber || '');
    setCountryCode('');
    setEmail(props.authUser.email);
  }

  return (
    <div className="account-settings__wrapper">
      <Card>
        <CardContent>
          <div className="account-settings__login">Login</div>
          <UpdateEmailNotice />
          <div className="email-input">
            <TextField
              id="email"
              label="Email"
              value={email}
              onChange={(e) => updateEmail(e.target.value)}
              style={{ width: '100%' }}
              disabled
              data-testid="update-email"
            />
          </div>
          <div className="account-settings__header">Mobile Phone Number</div>
          <div className="phone-number-input">
            <AutoCompleteField
              label="Country Code"
              id="countryCode"
              options={countryCodesOptions}
              onChange={(v) => setCountryCode(v.value)}
              value={countryCode}
              placeholder="Select country code"
              testid="country-code-dropdown"
            />
            <TextField
              id="phoneNumber"
              label="Phone Number"
              testid="phone-number-input"
              placeholder="Your phone number"
              value={phoneNumber}
              onChange={(e) => updatePhoneNumber(countryCode, e.target.value)}
              classNames="phone-number-input__input"
              style={{ width: '100%' }}
            />
          </div>
        </CardContent>
        <CardActions>
          <FormSubmitButtons
            className="account-settings__buttons"
            errors={error && <InlineInformation type="danger">{error}</InlineInformation>}
          >
            <Button
              type="primary"
              onClick={handleSave}
              disabled={!hasUpdate()}
              loading={submitting}
              testid="update-account-settings"
              inputType="submit"
            >
              Update
            </Button>
            <Button
              type="secondary"
              onClick={resetFields}
              disabled={!hasUpdate() || submitting}
              inputType="reset"
            >
              Cancel
            </Button>
          </FormSubmitButtons>
        </CardActions>
      </Card>
    </div>
  );
};

export default connect(null, { updateAccountInformation })(AccountSetting);
