import * as _ from 'lodash-es';
import moment from 'moment-timezone';

import SchedulingService from 'services/scheduling';
import * as ActivityStatusUtils from '@kritik/utils/stage';
import * as ActivityUtils from '@kritik/utils/activity';
import SchedulingUtils from 'containers/Schedule/utils';
import { selectCurrentActivity } from 'selectors/activity';
import { selectCurrentCourse } from 'selectors/course';
import { selectTimeZone } from 'selectors/user';
import ScheduleSelectors from './selectors';
import types from './types';

export function updateSchedule(newSchedule: any) {
  return (dispatch: any) => {
    dispatch({ type: types.UPDATE_SCHEDULE, payload: newSchedule });
  };
}

export function setSchedule(newSchedule: any) {
  return (dispatch: any) => {
    dispatch({ type: types.SET_SCHEDULE, payload: newSchedule });
  };
}

export function resetSchedule() {
  return (dispatch: any) => {
    dispatch({ type: types.RESET_SCHEDULE });
  };
}

export function setErrorPastDate(error: any) {
  return (dispatch: any) => {
    dispatch({
      type: types.SET_ERROR_PAST_DATE,
      payload: error,
    });
  };
}

function updateScheduling(template?: any) {
  return (dispatch: any, getState: any) => {
    const state = getState();

    const activity = selectCurrentActivity(state);

    if (ActivityStatusUtils.isScheduled(activity)) {
      const schedule = SchedulingUtils.generateScheduleFromStatuses(activity);
      const intervals = SchedulingUtils.generateIntervalsFromSchedule(schedule);
      const newSchedule = {
        ...schedule,
        ...intervals,
      };

      if (template) {
        // @ts-expect-error TS(2339) FIXME: Property 'customTime' does not exist on type '{ ev... Remove this comment to see the full error message
        newSchedule.customTime = template.customTime;
      }

      return dispatch(updateSchedule(newSchedule));
    }

    const schedule = SchedulingUtils.initSchedule();

    if (!template) {
      return dispatch(updateSchedule(schedule));
    }

    const {
      creationInterval,
      gracePeriodInterval,
      evaluationInterval,
      evaluationGracePeriodInterval,
      feedbackInterval,
      customTime,
    } = template;
    const intervals = {};

    if (ActivityUtils.isCalibrationActivity(activity)) {
      (intervals as any).evaluationInterval = evaluationInterval;
    } else {
      (intervals as any).creationInterval = creationInterval;
      (intervals as any).gracePeriodInterval = gracePeriodInterval;
      (intervals as any).evaluationInterval = evaluationInterval;
      (intervals as any).evaluationGracePeriodInterval = evaluationGracePeriodInterval || 120 * 60;
      (intervals as any).feedbackInterval = feedbackInterval;
    }

    const newScheduling = SchedulingUtils.generateScheduleFromIntervals({
      ...schedule,
      ...intervals,
      customTime,
    });
    return dispatch(updateSchedule(newScheduling));
  };
}

export function getSchedulingTemplate(courseId: any) {
  return async (dispatch: any, getState: any) => {
    dispatch({
      type: types.GET_SCHEDULING_TEMPLATE,
    });
    try {
      const state = getState();
      const schedulingService = new SchedulingService();
      const res = await schedulingService.getTemplate(courseId);
      const template = res.data;

      const timeZone = selectTimeZone(state);
      if (ActivityStatusUtils.getIsDifferentTimeZone(timeZone)) {
        /**
         * When user client is in a different time zone, we force schedule page to use institution time zone
         * to handle all dates, then we don't have to modify the offsets manually
         */
        moment.tz.setDefault(timeZone);
      }

      dispatch(updateScheduling(template));

      dispatch({
        type: types.GET_SCHEDULING_TEMPLATE_SUCCESS,
        success: template,
      });
    } catch (error) {
      dispatch(updateScheduling());
      dispatch({
        type: types.GET_SCHEDULING_TEMPLATE_FAILURE,
        error,
      });
    }
  };
}

export function createSchedulingTemplate(course: any, data: any) {
  return async (dispatch: any) => {
    dispatch({
      type: types.CREATE_SCHEDULING_TEMPLATE,
    });
    try {
      const schedulingService = new SchedulingService();
      const res = await schedulingService.createTemplate(course._id, data);
      const template = res.data;
      dispatch({
        type: types.CREATE_SCHEDULING_TEMPLATE_SUCCESS,
        success: template,
      });
    } catch (error) {
      dispatch({
        type: types.CREATE_SCHEDULING_TEMPLATE_FAILURE,
        error,
      });
    }
  };
}

export function updateSchedulingTemplate(course: any, data: any) {
  return async (dispatch: any) => {
    dispatch({
      type: types.UPDATE_SCHEDULING_TEMPLATE,
    });
    try {
      const schedulingService = new SchedulingService();
      const res = await schedulingService.updateTemplate(course._id, data);
      const template = res.data;
      dispatch({
        type: types.UPDATE_SCHEDULING_TEMPLATE_SUCCESS,
        success: template,
      });
    } catch (error) {
      dispatch({
        type: types.UPDATE_SCHEDULING_TEMPLATE_FAILURE,
        error,
      });
    }
  };
}

function sanitizeData(templateData: any, activity: any) {
  if (ActivityUtils.isCalibrationActivity(activity)) {
    return _.pick(templateData, ['evaluationInterval', 'customTime', 'activityType', '_id']);
  }
  return _.pick(templateData, [
    '_id',
    'creationInterval',
    'gracePeriodInterval',
    'evaluationInterval',
    'feedbackInterval',
    'customTime',
    'activityType',
  ]);
}

export function saveSchedulingTemplate() {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const course = selectCurrentCourse(state);
    const activity = selectCurrentActivity(state);
    const getTemplateAsyncState = ScheduleSelectors.selectGetSchedulingTemplateState(state);
    const template = getTemplateAsyncState.success;
    const schedule = ScheduleSelectors.selectSchedule(state);

    if (template && template._id) {
      dispatch(
        updateSchedulingTemplate(
          course,
          sanitizeData(
            {
              ...schedule,
              _id: template._id,
              activityType: activity.activityType,
            },
            activity
          )
        )
      );
    } else {
      dispatch(
        createSchedulingTemplate(
          course,
          sanitizeData(
            {
              ...schedule,
              activityType: activity.activityType,
            },
            activity
          )
        )
      );
    }
  };
}

export default {
  updateSchedule,
  setSchedule,
};
