import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getAssignmentsForLateSubmission } from 'actions/admin';
import { FormSelection, FormMainError } from 'components/Form/FormComponents';
import Button from 'components/buttons/Button';

const mapStateToProps = (state: any) => {
  return {
    assignments: state.admin.assignments,
    isLoading: state.admin.isLoadingCourses,
  };
};

const DEFAULT_BUTTTON_NAME = 'Verify Course';
const DEFAULT_BUTTTON_DESIGN = 'secondary';
const DEFAULT_ERROR_MESSAGE = 'Please choose a course from the options.';

const FindCourseFromOptions = (props: any) => {
  const [showMessage, setShowMessage] = useState({ isOpen: false, message: DEFAULT_ERROR_MESSAGE });

  const {
    email,
    course,
    courseOptions,
    changeCourse,
    changeAssignmentOptions,
    changeAssignment,
    handleVerifyCourse,
    buttonName = DEFAULT_BUTTTON_NAME,
    buttonDesign = DEFAULT_BUTTTON_DESIGN,
    children,
    loading = false,
  } = props;

  const verifyCourse = async () => {
    if (course) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
      setShowMessage(false);
      if (handleVerifyCourse) {
        const errorMessageReceived = await handleVerifyCourse();
        if (errorMessageReceived) {
          return setShowMessage({ isOpen: true, message: errorMessageReceived });
        }
        return;
      }
      const data = await props.getAssignmentsForLateSubmission({
        courseID: course.value,
        email,
      });
      const assignmentOptions = data.map((assignment: any) => {
        return {
          value: assignment,
          label: assignment.title,
        };
      });
      changeAssignmentOptions(assignmentOptions);
      // if admin suddenly change course, erase all the previous records
      changeAssignment(null);
    } else {
      setShowMessage({ isOpen: true, message: DEFAULT_ERROR_MESSAGE });
    }
  };

  return (
    <React.Fragment>
      <FormSelection
        label="Course"
        isLoading={props.isLoading}
        value={course}
        onChange={changeCourse}
        options={courseOptions}
      />

      {children}

      <Button loading={loading} type={buttonDesign} onClick={verifyCourse}>
        {buttonName}
      </Button>

      <FormMainError message={showMessage.message} isOpen={showMessage.isOpen} />
    </React.Fragment>
  );
};

export default connect(mapStateToProps, {
  getAssignmentsForLateSubmission,
})(FindCourseFromOptions);
