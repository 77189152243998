import React from 'react';
import SYNC_ICONS from 'images/lms-grade-sync';
import Tooltip from '@material-ui/core/Tooltip';

function getLabelandIcon(status: any) {
  let image = SYNC_ICONS.SYNC_SUCCESS;
  let label = 'Not going to sync';
  switch (status) {
    case 'ready-to-sync':
      image = SYNC_ICONS.SYNC_READY;
      label = 'Ready to sync';
      break;
    case 'not-synced':
      image = SYNC_ICONS.SYNC_SUCCESS;
      label = 'Not going to sync';
      break;
    case 'synced':
      image = SYNC_ICONS.SYNC_SUCCESS;
      label = 'Not going to sync';
      break;
    case 'error':
      image = SYNC_ICONS.SYNC_ERROR;
      label = 'Sync error';
      break;
    default:
      image = SYNC_ICONS.SYNC_SUCCESS;
      label = 'Not going to sync';
  }
  return { image, label };
}

function SyncStatusIcon(props: any) {
  const { image, label } = getLabelandIcon(props.status);

  return (
    <div
      className="activity-table__sync-status-icon"
      onClick={() => {
        props.handleSyncStatusChange(props.status, props.activityId);
      }}
    >
      <Tooltip title={label} placement="top">
        <img src={image} aria-hidden="true" />
      </Tooltip>
    </div>
  );
}

export default SyncStatusIcon;
