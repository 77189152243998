import { Field, useFormState } from 'react-final-form';
import FormFieldSelector from 'components/core/form/SelectField';
import CourseFormConstants from '@kritik/constants/course';
import { COURSE_FIELD_NAMES } from 'components/Course/Edit/constants';
import { createTestIdFromLabel } from '@kritik/utils/general';

const CourseDepartmentField = () => {
  const state = useFormState();
  const { department } = state.values;
  const options = CourseFormConstants.DEPARTMENTS.map((department) => {
    return {
      label: department,
      value: department,
      testid: createTestIdFromLabel(department),
    };
  });
  if (Boolean(department) && !CourseFormConstants.DEPARTMENTS.includes(department)) {
    options.push({
      label: department,
      value: department,
      testid: createTestIdFromLabel(department),
    });
  }
  return (
    <Field name={COURSE_FIELD_NAMES.DEPARTMENT} type="text">
      {({ input, meta }) => {
        return (
          <FormFieldSelector
            label="Department (Subject)"
            options={options}
            value={input.value}
            onChange={input.onChange}
            onBlur={input.onBlur}
            error={meta.error && meta.touched && meta.error}
            placeholder="Select a department"
            id="select-department"
            testid="select-department"
          />
        );
      }}
    </Field>
  );
};
export default CourseDepartmentField;
