import { connect } from 'react-redux';
import userSelector from 'selectors/user';
import * as evaluationUtil from '@kritik/utils/creation/evaluation';
import * as creationStatus from '@kritik/utils/creation/status';
import NotFlaggedMenu from 'components/Creation/Flag/StudentMenu/NotFlagged';
import FlaggedMenu from 'components/Creation/Flag/StudentMenu/Flagged';
import { useUserRoleInCourse } from 'hooks/course';

const StudentMenu = (props: any) => {
  const { isStudentInCourse } = useUserRoleInCourse();
  if (
    evaluationUtil.isUserEvaluationOwner(props.user, props.evaluation) ||
    creationStatus.isCommentRemoved(props.evaluation) ||
    creationStatus.isCommentFlagDismissed(props.evaluation)
  ) {
    return null;
  }
  if (creationStatus.isCommentNotFlagged(props.evaluation)) {
    return <NotFlaggedMenu evaluation={props.evaluation} creation={props.creation} />;
  }
  if (creationStatus.isCommentFlagged(props.evaluation)) {
    if (isStudentInCourse) {
      return <FlaggedMenu evaluation={props.evaluation} creation={props.creation} />;
    }
  }
  return null;
};

const mapStateToProps = (state: any) => {
  return {
    user: userSelector.selectAuthUser(state),
  };
};

export default connect(mapStateToProps, {})(StudentMenu);
