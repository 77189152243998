import { useState } from 'react';
import { Form, Field } from 'react-final-form';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  makeStyles,
  FormHelperText,
  FormGroup,
} from '@material-ui/core';
import Button from 'components/buttons/Button';
import CodeSectionFieldLayout from 'components/Course/Edit/CourseDetails/Layout';
import CourseCodeField from 'components/Course/Edit/CourseDetails/CourseCodeField';
import CourseSectionField from 'components/Course/Edit/CourseDetails/CourseSectionField';
import CourseNameField from 'components/Course/Edit/CourseDetails/CourseNameField';
import Checkbox from 'components/core/input/Checkbox';
import { localize } from 'locales/index';
import { CourseTermField } from '../Edit/CourseDetails/CourseTermField';

const DUPLICATE_FIELD_NAMES = {
  COURSE_ID: 'courseId',
  CODE: 'code',
  SECTION: 'section',
  COURSE_NAME: 'title',
  INCLUDE_ACTIVITIES: 'includeActivities',
};

const defaultValues = {
  [DUPLICATE_FIELD_NAMES.COURSE_ID]: null,
  [DUPLICATE_FIELD_NAMES.COURSE_NAME]: '',
  [DUPLICATE_FIELD_NAMES.CODE]: '',
  [DUPLICATE_FIELD_NAMES.SECTION]: '',
  [DUPLICATE_FIELD_NAMES.INCLUDE_ACTIVITIES]: true,
};

const getInitialValuesFromCourse = (course: any) => {
  return {
    [DUPLICATE_FIELD_NAMES.COURSE_ID]: course._id,
    [DUPLICATE_FIELD_NAMES.COURSE_NAME]: `${course.title} - Copy`,
    [DUPLICATE_FIELD_NAMES.CODE]: course.code ?? '',
    [DUPLICATE_FIELD_NAMES.SECTION]: course.section ?? '',
    [DUPLICATE_FIELD_NAMES.INCLUDE_ACTIVITIES]: true,
  };
};

const useStyles = makeStyles({
  root: {
    justifyContent: 'flex-start',
    paddingLeft: '0',
    paddingRight: '0',
    marginTop: '1.5rem',
  },

  activityIncludeSubTitle: {
    marginLeft: '1.9rem',
  },
});

const DuplicateDialog = (props: any) => {
  const [initialValues, setInitialValues] = useState(
    props.course ? getInitialValuesFromCourse(props.course) : defaultValues
  );
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        return props.onClose();
      }}
      aria-labelledby="duplicate-dialog-title"
      aria-describedby="duplicate-dialog-description"
    >
      <DialogTitle id="duplicate-dialog-title">Duplicate Course</DialogTitle>
      <DialogContent>
        <DialogContentText id="duplicate-dialog-description">
          All course settings and activity defaults will be copied to your new course.
        </DialogContentText>

        <Form
          onSubmit={props.onSubmit}
          initialValues={initialValues}
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => {
                return value;
              });
            },
          }}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <CourseNameField label={localize({ message: 'Course.Create.CourseNameField' })} />
                <CodeSectionFieldLayout className="course-details__code-section-field-layout">
                  <CourseCodeField />
                  <CourseSectionField />
                </CodeSectionFieldLayout>
                <CourseTermField />
                <FormGroup>
                  <Field name={DUPLICATE_FIELD_NAMES.INCLUDE_ACTIVITIES} type="checkbox">
                    {({ input }) => {
                      return (
                        <Checkbox
                          label="Include activities"
                          isChecked={input.checked}
                          onChange={input.onChange}
                          type="primary"
                          testid="duplicate-course-include-activities-check"
                        />
                      );
                    }}
                  </Field>
                  <FormHelperText
                    classes={{
                      root: classes.activityIncludeSubTitle,
                    }}
                  >
                    When checked all activities in the course will be duplicated
                  </FormHelperText>
                </FormGroup>

                <DialogActions
                  classes={{
                    root: classes.root,
                  }}
                >
                  <Button type="primary" inputType="submit" testid="duplicate-course-button">
                    Duplicate Course
                  </Button>
                  <Button
                    onClick={() => {
                      return props.onClose();
                    }}
                    type="secondary"
                    autoFocus
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default DuplicateDialog;
