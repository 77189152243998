import React from 'react';
import Icon from './Icon';

export default function createSvgIcon(path: any, viewBox: any) {
  const Component = (props: any, ref: any) => (
    <Icon ref={ref} {...props} viewBox={viewBox}>
      {path}
    </Icon>
  );

  return React.memo(React.forwardRef(Component));
}
