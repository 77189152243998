import CRITICALBG from 'images/feedback/pattern-critical.png';
import CRITICALBADGE from 'images/feedback/badge-critical.svg';
import MOTIVATIONALBG from 'images/feedback/pattern-motivational.png';
import MOTIVATIONALBADGE from 'images/feedback/badge-motivational.svg';

export const TEMPLATES = {
  critical: {
    title: 'Critical',
    description: 'How helpful was the critique you received in the written evaluation?',
    background: CRITICALBG,
    badge: CRITICALBADGE,
    marks: [
      {
        value: 1,
        label: 'Ineffective',
      },
      {
        value: 2,
        label: 'Unhelpful',
      },
      {
        value: 3,
        label: 'Good Critique',
      },
      {
        value: 4,
        label: 'Great Critique',
      },
    ],
  },
  motivational: {
    title: 'Motivational',
    description: 'How did you find the tone of the written evaluation?',
    background: MOTIVATIONALBG,
    badge: MOTIVATIONALBADGE,
    marks: [
      {
        value: 1,
        label: 'Discouraging',
      },
      {
        value: 2,
        label: 'Generic',
      },
      {
        value: 3,
        label: 'Motivational',
      },
      {
        value: 4,
        label: 'Very Motivational',
      },
    ],
  },
};

export const SUBMITTING_DESCRIPTIONS = {
  critical: [
    'I feel that the evaluator gave me comments that were not correct.',
    'I feel that the evaluator gave me comments that were generic and not actionable.',
    'I feel that the evaluator gave me helpful comments that aided in my learning and were actionable.',
    'I feel that the evaluator gave me extremely helpful comments that aided in my learning while also being descriptive, specific, and actionable.',
  ],
  motivational: [
    'I feel the evaluator was not empathetic and did not use some positive reinforcement for my efforts or the areas I did well.',
    `I feel the evaluator didn't put enough effort in writing a truly encouraging comment.`,
    'I feel the evaluator put in effort to write an encouraging comment.',
    'I feel the evaluator gave me comments that positively affected my confidence, and inspired me to learn and improve.',
  ],
};

export const VIEWING_DESCRIPTIONS = {
  critical: [
    'The student submitting feedback felt the evaluator gave them comments that were not correct',
    'The student submitting feedback felt the evaluator gave them comments that were generic and not actionable.',
    'The student submitting feedback felt the evaluator gave them helpful comments that aided in their learning and were actionable.',
    'The student submitting feedback felt the evaluator gave them extremely helpful comments that aided in their learning while also being descriptive, specific, and actionable.',
  ],
  motivational: [
    'The student submitting feedback felt the evaluation was not empathetic and did not use some positive reinforcement for their efforts or the areas they did well.',
    'I feel the evaluator was not empathetic and did not use some positive reinforcement for my efforts or the areas I did well.',
    'The student submitting feedback felt the evaluator put in effort to write an encouraging comment.',
    'The student submitting feedback felt the evaluator gave them comments that positively affected their confidence, and inspired them to learn and improve.',
  ],
};

export const TYPES = {
  CRITICAL: 'critical',
  MOTIVATIONAL: 'motivational',
};

export default {
  TEMPLATES,
  TYPES,
  SUBMITTING_DESCRIPTIONS,
  VIEWING_DESCRIPTIONS,
};
