import React from 'react';
import AttachmentType from 'components/ActivityEdit/AdvancedOptions/Fields/AttachmentType';
import NumEvalsToAssign from 'components/ActivityEdit/AdvancedOptions/Fields/NumEvalsToAssign';
import SelfEvaluation from 'components/ActivityEdit/AdvancedOptions/Fields/SelfEvaluation';
import WeightField from 'components/ActivityEdit/AdvancedOptions/Fields/Weight';
import StartingScoreField from 'components/ActivityEdit/AdvancedOptions/Fields/StartingScore';
import TopicsField from 'components/ActivityEdit/AdvancedOptions/Fields/Topics';
import { ACTIVITY_TYPES } from '@kritik/constants/activity';
import { AdvancedOptionField } from 'components/ActivityEdit/AdvancedOptions/Field';
import MinimumWordCount from 'components/Course/Edit/CourseDetails/MinimumWordCount';
import { localize } from 'locales/index';
import { Activity, Course } from '@kritik/types.generated';
import MissedCreationOption from './Fields/MissedCreation';
import { LateSubmissionSettings } from 'components/DefaultActivitySettings/LateSubmissionSettings';
import { useFetchActivity } from 'hooks/activity';
import { useParams } from 'react-router';

type Props = {
  form?: any;
  values: any;
  course: Course;
  activity: Activity;
};

const AdvancedOptions = (props: Props) => {
  const { assignmentId } = useParams();
  const { data: activity } = useFetchActivity(assignmentId);

  const renderAttachmentType = () => {
    return (
      <AdvancedOptionField>
        <AttachmentType />
      </AdvancedOptionField>
    );
  };

  const renderNumEvalsToAssign = () => {
    return (
      <AdvancedOptionField>
        <NumEvalsToAssign activity={activity} values={props.values} />
      </AdvancedOptionField>
    );
  };

  const renderSelfEvaluation = () => {
    if (props.values.activityType !== ACTIVITY_TYPES.WITHIN_GROUP) {
      return;
    }
    return (
      <AdvancedOptionField>
        <SelfEvaluation settings={props.values.settings} />
      </AdvancedOptionField>
    );
  };

  const renderTopics = () => {
    if (!props.course || props.values.activityType !== ACTIVITY_TYPES.PEER) {
      return null;
    }
    return <TopicsField activity={props.activity} />;
  };

  return (
    <React.Fragment>
      <h3>Options</h3>
      {renderTopics()}
      {renderAttachmentType()}
      <h3>{localize({ message: 'ActivitySettings.Default' })}</h3>
      <MinimumWordCount
        testid="word-count-activity"
        fieldName="settings.minimumWordCountForEvaluations"
        wordCount={props.course.courseSettings?.minimumWordCountForEvaluations}
      />
      {renderNumEvalsToAssign()}
      {renderSelfEvaluation()}
      <WeightField />
      {props.course.markingScheme.isPercentage && (
        <StartingScoreField isDuplicatingActivity={props.values?.settings?.isDuplicating} />
      )}
      <MissedCreationOption values={props.values} />
      <LateSubmissionSettings
        showNoLateSubmissionsAllowedNotice={
          props.values.removeStudentFromActivityAfterMissedCreation === true
        }
        acceptLateCreations={props.values.acceptLateSubmissions}
        onAcceptLateCreationsChange={(e) => {
          props.form.change('acceptLateSubmissions', e.target.checked);
          props.form.change('autoAccept', false);
        }}
        autoAcceptLateCreations={props.values.autoAccept}
        onAutoAcceptLateCreationsChange={(e) => props.form.change('autoAccept', e.target.checked)}
        acceptLateEvaluations={props.values.acceptLateEvaluations}
        onAcceptLateEvaluationsChange={(e) => {
          props.form.change('acceptLateEvaluations', e.target.checked);
          props.form.change('autoAcceptLateEvaluations', false);
        }}
        autoAcceptLateEvaluations={props.values.autoAcceptLateEvaluations}
        onAutoAcceptLateEvaluationsChange={(e) =>
          props.form.change('autoAcceptLateEvaluations', e.target.checked)
        }
        activity={activity}
        isDuplicatingActivity={props.values?.settings?.isDuplicating}
      />
    </React.Fragment>
  );
};

export default AdvancedOptions;
