import { normalize } from 'normalizr';
import { mergeEntities } from 'actions/entities';
import { rubricService } from 'services';
import { rubricSchema } from 'schemas';
import types from './types';

export function setSelectedRubricId(rubricId: any) {
  return (dispatch: any) => {
    dispatch({ type: types.SET_SELECTED_RUBRIC, payload: rubricId });
  };
}

export function setIsEditingTemplate(isEditing: any) {
  return (dispatch: any) => {
    dispatch({ type: types.SET_IS_TEMPLATE_EDIT, payload: isEditing });
  };
}

export function createNewBasicTemplate() {
  return async (dispatch: any) => {
    dispatch({ type: types.CREATE_NEW_BASIC_TEMPLATE });
    try {
      const res = await rubricService().createNewBasicTemplate();
      const rubric = res.data;
      dispatch(mergeEntities(normalize(rubric, rubricSchema).entities));
      dispatch(setSelectedRubricId(rubric._id));
      dispatch({
        type: types.CREATE_NEW_BASIC_TEMPLATE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: types.CREATE_NEW_BASIC_TEMPLATE_FAILURE,
        payload: {
          message: error,
        },
      });
    }
  };
}

export default {
  setSelectedRubricId,
  setIsEditingTemplate,
  createNewBasicTemplate,
};
