import React from 'react';

const TermsOfService = () => {
  return (
    <div style={{ marginBottom: '15px', fontSize: '14px' }}>
      By signing up, you agree to our{' '}
      <a
        rel="noreferrer"
        href="https://www.kritik.io/terms-of-service"
        target="_blank"
        className="underlined-link"
      >
        terms of service.
      </a>
    </div>
  );
};

export default TermsOfService;
