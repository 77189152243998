import React from 'react';
import PanelDisplay from 'components/layout/PanelDisplay';

const LmsIntegration = ({ lmsCourseName }: any) => {
  return (
    <>
      <h3 className="panel-display__title">LMS Setup</h3>
      <div className="panel-display__subtitle course-details__margin-bottom-md">
        This Kritik course is connected to the following course in your LMS
      </div>
      <div className="scoring-options__settings">
        <div className="scoring-options__text">{`Connected - ${lmsCourseName}`}</div>
      </div>
    </>
  );
};

export default LmsIntegration;
