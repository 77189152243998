import { useUserRoleInCourse } from 'hooks/course';

function Access(props: any) {
  const { isInstructorInCourse } = useUserRoleInCourse();
  const { student, instructor } = props;
  if (!student && !instructor) {
    return null;
  }
  if (isInstructorInCourse) {
    return instructor;
  }
  return student;
}

Access.defaultProps = {
  student: null,
  instructor: null,
};

export default Access;
