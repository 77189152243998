import { combineReducers } from 'redux';
import { FileService } from 'services';
import * as ErrorUtils from 'utils/error';
import { RESET_ASYNC } from 'types';
import { generateAsyncReducer } from './utils';
import { extractFileNameFromS3Path } from 'common/utils/file';

const UPLOAD_FILES_REQUEST = 'UPLOAD_FILES';
const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
const UPLOAD_FILES_FAILURE = 'UPLOAD_FILES_FAILURE';

const asyncActions = {
  call: [UPLOAD_FILES_REQUEST],
  success: [UPLOAD_FILES_SUCCESS],
  error: [UPLOAD_FILES_FAILURE],
};

const addUploadedFiles = (
  uploadedFiles: {
    size: number;
    Location: string;
    Key: string;
    mimetype: string;
  }[]
) => {
  const files = [];
  for (let i = 0; i < uploadedFiles.length; i++) {
    const file = uploadedFiles[i];
    files.push({
      name: extractFileNameFromS3Path(file.Key),
      url: file.Location,
      size: file.size,
      mimetype: file.mimetype,
    });
  }
  return files;
};

export const uploadFiles = ({ formData, key, callback }: any) => {
  return async (dispatch: any, getState: any) => {
    dispatch({
      type: UPLOAD_FILES_REQUEST,
      key,
    });
    try {
      const response = await FileService().uploadFiles({
        formData,
        allowedExtensions: formData.allowedExtensions,
      });
      dispatch({
        type: UPLOAD_FILES_SUCCESS,
        success: true,
        key,
      });
      if (typeof callback === 'function') {
        callback(addUploadedFiles(response.data));
      }
      return response.data;
    } catch (error) {
      dispatch({
        type: UPLOAD_FILES_FAILURE,
        error: ErrorUtils.getErrorMessageFromResponse(error),
        key,
      });
      return {
        error: ErrorUtils.getErrorMessageFromResponse(error),
      };
    }
  };
};

export const selectUploadStatus = (state: any, key: any) => {
  const uploadStatus = state.upload.status[UPLOAD_FILES_REQUEST];
  if (key && uploadStatus.key !== key) {
    return {};
  }
  return uploadStatus;
};

export const resetUploadStatus = () => {
  return (dispatch: any) => {
    dispatch({ type: RESET_ASYNC, payload: UPLOAD_FILES_REQUEST });
  };
};

export default combineReducers({
  status: generateAsyncReducer(asyncActions),
});
