import { TABLE_HEADERS } from '../constant';

export const STUDENT_COLUMNS = {
  PEER: [
    { header: TABLE_HEADERS.NAME, align: 'left', isSortable: true, width: 'lg' },
    {
      header: TABLE_HEADERS.STATUS,
      align: 'center',
      isSortable: true,
      sorting: {
        isSorted: true,
        isAscending: true,
      },
    },
    { header: TABLE_HEADERS.DISPUTED_DATE, align: 'center', isSortable: true },
    { header: TABLE_HEADERS.RESOLVED_DATE, align: 'center', isSortable: true },
  ],
  GROUP: [
    { header: TABLE_HEADERS.NAME, align: 'left', isSortable: true, width: 'lg' },
    {
      header: TABLE_HEADERS.STATUS,
      align: 'center',
      isSortable: true,
      sorting: {
        isSorted: true,
        isAscending: true,
      },
    },
    { header: TABLE_HEADERS.DISPUTED_DATE, align: 'center', isSortable: true },
    { header: TABLE_HEADERS.RESOLVED_DATE, align: 'center', isSortable: true },
  ],
};

export const GROUP_COLUMNS = {
  GROUP: [
    {
      header: TABLE_HEADERS.NAME,
      align: 'left',
      isSortable: true,
      width: 'lg',
      testid: 'group-header-name',
    },
    {
      header: TABLE_HEADERS.STATUS,
      align: 'center',
      isSortable: true,
      sorting: {
        isSorted: true,
        isAscending: true,
      },
      testid: 'group-header-status',
    },
    {
      header: TABLE_HEADERS.DISPUTED_DATE,
      align: 'center',
      isSortable: true,
      info: '',
      testid: 'group-header-date',
    },
    {
      header: TABLE_HEADERS.RESOLVED_DATE,
      align: 'center',
      isSortable: true,
      info: '',
      testid: 'group-header-resolve-date',
    },
    { header: ' ', align: 'right', isSortable: false, width: 'sm' },
  ],
};

export default {
  STUDENT_COLUMNS,
  GROUP_COLUMNS,
};
