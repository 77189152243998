import { useState } from 'react';
import PageContainer from 'components/layout/PageContainer';
import PageHeader from 'components/layout/PageHeader';
import { InlineInformation } from 'components/layout';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/core/form/SubmitButtons';
import InstitutionSelect from 'components/Admin/InstitutionSelect';
import { adminService } from 'services';
import * as ErrorUtils from 'utils/error';
import { priceToCents } from 'utils/format';

function priceInCentsOrNull(price: string) {
  if (price === '') {
    return null;
  }

  return priceToCents(price);
}

function DefaultCoursePrice() {
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [coursePrice, setCoursePrice] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCoursePrice(e.target.value);
  };

  const onSave = async (e: any) => {
    e.preventDefault();
    setSuccessMessage('');

    if (!selectedInstitution) {
      return setErrorMessage('You need to select institution first!');
    }
    if (Number.isNaN(priceInCentsOrNull(coursePrice))) {
      return setErrorMessage(`"${coursePrice}" is not a number`);
    }

    try {
      setIsLoading(true);
      await adminService().changeDefaultCoursePrice({
        coursePrice: priceInCentsOrNull(coursePrice),
        institutionId: (selectedInstitution as any).value,
      });
      setSuccessMessage('Default price successfuly updated!');
      setErrorMessage('');
    } catch (err) {
      setErrorMessage(ErrorUtils.getErrorMessageFromResponse(err));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageContainer>
      <PageHeader title="Change default course price for Institution" />
      <InlineInformation type="warning">
        Please note that default price is only applicable for newly created courses. Existing
        courses will not be affected! To reset the price, clear the price field.
      </InlineInformation>
      <form onSubmit={onSave}>
        <InstitutionSelect
          setSelectedInstitution={setSelectedInstitution}
          setErrorMessage={setErrorMessage}
        />

        <div className="kritik-form-field">
          <div className="kritik-form-field__input">
            <label className="kritik-form-field__label">
              <span>Enter course price:</span>
            </label>
            <div className="kritik-form-field__text-input-wrapper">
              $
              <input
                type="text"
                className="kritik-form-field__text-input"
                value={coursePrice}
                onChange={handlePriceChange}
                style={{ marginLeft: '0.5em' }}
              />
            </div>
          </div>
        </div>

        <ButtonContainer>
          <Button type="primary" inputType="submit" loading={isLoading}>
            Save
          </Button>
        </ButtonContainer>
        {errorMessage && (
          <InlineInformation style={{ marginTop: '10px' }} type="danger">
            {errorMessage}
          </InlineInformation>
        )}
        {successMessage && (
          <InlineInformation style={{ marginTop: '10px' }} type="success">
            {successMessage}
          </InlineInformation>
        )}
      </form>
    </PageContainer>
  );
}

export default DefaultCoursePrice;
