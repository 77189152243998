import { connect } from 'react-redux';
import Button from 'components/buttons/Button';
import { Card } from '@material-ui/core';
import InfoLabel from 'components/layout/InfoLabel';
import SpotlightBadge from 'components/Spotlight/Badge';
import { getBackgroundUrl } from 'utils/tags';
import * as FormatUtils from '@kritik/utils/format';
import { navigateToSpotlight } from 'redux/spotlight';
import ShortenText from 'components/General/ShortenText';
import { useUserRoleInCourse } from 'hooks/course';
import { Spotlight, Activity, SpotlightTag } from '@kritik/types.generated';

type SubmissionSpotlightProps = {
  spotlight: Spotlight & { activity: Activity } & { tag: SpotlightTag };
  courseId: string;
  navigateToSpotlight: any;
};

const SubmissionSpotlight = (props: SubmissionSpotlightProps) => {
  const { isInstructorInCourse } = useUserRoleInCourse();
  const renderAnonymized = () => {
    if (isInstructorInCourse) {
      return (
        <div style={{ display: 'flex' }}>
          {props.spotlight.status === 'draft' && (
            <InfoLabel
              label="Draft"
              type="information"
              className="spotlight-card-ltr__disclaimer"
            />
          )}
          <InfoLabel
            className="spotlight-card-ltr__disclaimer"
            label="Evaluator Anonymized"
            type="information"
          />
        </div>
      );
    }
  };

  const renderButton = () => {
    return (
      <div className="spotlight-card-ltr__button">
        <Button
          type="secondary"
          onClick={() => {
            props.navigateToSpotlight({
              courseId: props.courseId,
              activityId: props.spotlight.activity._id,
              spotlightId: props.spotlight._id,
            });
          }}
          data-testid="view-spotlight"
        >
          View Spotlight
        </Button>
      </div>
    );
  };

  const getCardContent = () => {
    return FormatUtils.stripHtmlTags(props.spotlight.comment);
  };
  const isSpotlightTag = props.spotlight.tag.name === 'Spotlight';
  return (
    <Card className="spotlight-card-ltr">
      <div
        className="spotlight-card-ltr__header"
        style={{ background: getBackgroundUrl(props.spotlight.tag.name) }}
      >
        <div className="spotlight-card-ltr__badge">
          <SpotlightBadge tag={props.spotlight.tag} />
        </div>
      </div>
      <div className="spotlight-card-ltr__content">
        <span className="spotlight-card-ltr__content-header">
          {isSpotlightTag ? 'Spotlighted' : `Spotlighted: ${props.spotlight.tag.name}`}
        </span>
        <div className="spotlight-card-ltr__content-comment">
          <ShortenText label={getCardContent()} maxLength={300} />
        </div>
        {renderAnonymized()}
        {renderButton()}
      </div>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  return {
    courseId: state.selected.courseId,
  };
};

export default connect(mapStateToProps, { navigateToSpotlight })(SubmissionSpotlight);
