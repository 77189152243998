import React from 'react';
import { selectConfirmModalProps } from 'selectors/modal';
import { closeConfirmModal } from 'actions/modals';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogProps,
} from '@material-ui/core';
import { Button, ButtonContainer } from 'components/buttons';
import { InlineInformation } from 'components/layout';

const mergeProps = (stateProps: any, dispatchProps: any, ownProps: any) => {
  if (ownProps.title || ownProps.description) {
    return ownProps;
  }
  // overide component own props from parent, for compatibility that some confirm modals are not
  // integrate with redux
  return { ...stateProps, ...dispatchProps };
};

const mapStateToProps = (state: any) => {
  const confirmModalProps = selectConfirmModalProps(state);
  return {
    ...confirmModalProps,
  };
};

type Props = {
  isOpen: boolean;
  title: string;
  description: string;
  onCancel: () => void;
  cancelButton: string;
  onConfirm: () => void;
  confirmButton: string;
  children: React.ReactNode;
  closeConfirmModal: () => void;
  isFullWidth: boolean;
  maxWidth: DialogProps['maxWidth'];
  isDangerous: boolean;
  importantInfo: string;
  error?: string;
  isConfirmDisabled: boolean;
  testid: string;
};

const ConfirmModal = ({
  isOpen,
  title,
  description,
  onCancel,
  cancelButton,
  onConfirm,
  confirmButton,
  children,
  closeConfirmModal,
  isFullWidth,
  maxWidth,
  isDangerous,
  importantInfo,
  error,
  ...props
}: Props) => {
  const handleClickConfirm = () => {
    onConfirm && onConfirm();
    typeof closeConfirmModal === 'function' && closeConfirmModal();
  };
  const handleClickCancel = () => {
    onCancel && onCancel();
    typeof closeConfirmModal === 'function' && closeConfirmModal();
  };
  return (
    <Dialog
      open={Boolean(isOpen)}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      fullWidth={isFullWidth}
      data-testid={props.testid}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className="kritik-confirm__description">{description}</DialogContentText>
        <DialogContentText className="kritik-confirm__important-info ">
          {importantInfo}
        </DialogContentText>
        {children}
      </DialogContent>
      {error && (
        <DialogContent>
          <InlineInformation type="danger">{error}</InlineInformation>
        </DialogContent>
      )}
      <DialogActions className="kritik-confirm__buttons">
        <ButtonContainer>
          <Button
            onClick={handleClickConfirm}
            type={isDangerous ? 'warning' : 'primary'}
            disabled={props.isConfirmDisabled}
            unavailable={props.isConfirmDisabled}
            data-testid="modal_confirm"
          >
            {confirmButton}
          </Button>
          <Button onClick={handleClickCancel} type="secondary" autoFocus data-testid="modal_cancel">
            {cancelButton}
          </Button>
        </ButtonContainer>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.defaultProps = {
  maxWidth: null,
  isFullWidth: false,
  isConfirmDisabled: false,
};

export default connect(mapStateToProps, { closeConfirmModal }, mergeProps)(ConfirmModal);
