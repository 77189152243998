import React from 'react';
import { connect } from 'react-redux';
import StatusResult from 'components/ActivityCard/Schedule/StatusNode/CompletionDiplay';
import { assignmentStatuses as STATUS_LABELS, PRESENT_STATUS } from '@kritik/constants/stage';
import * as ActivityUtils from '@kritik/utils/activity';
import * as creationStatus from '@kritik/utils/creation/status';
import * as LateSubmissionUtils from '@kritik/utils/creation/lateCreation';
import * as evaluationUtil from '@kritik/utils/creation/evaluation';
import * as activityStatus from '@kritik/utils/stage';
import { getCreationsFromEntities, getMySubmission } from 'selectors/creation';
import { getStudentFromUser } from 'selectors/student';
import { selectAuthUser } from 'selectors/user';
import { FeedbackScoreUtil } from '@kritik/utils/grade';
import { useUserRoleInCourse } from 'hooks/course';

export const CreationStageResult = ({ activity, creation, isPastStatus }: any) => {
  if (
    creationStatus.isCompleted(creation) &&
    ActivityUtils.isPresentationActivity(activity) &&
    !activity.requireFile
  ) {
    return <StatusResult ariaLabel="Completed" status="success" />;
  }

  if (creationStatus.isCreationOverdue(activity, creation)) {
    return <StatusResult ariaLabel="Overdue" status="fail" symbol="warning" />;
  }
  if (
    (creation && creationStatus.wasCreationOnTime(creation)) ||
    ActivityUtils.isCalibrationActivity(activity)
  ) {
    return <StatusResult ariaLabel="Completed" status="success" />;
  }
  if (creation && creationStatus.wasCreationSubmittedInGracePeriod(creation)) {
    return (
      <StatusResult ariaLabel="Submitted during grace period" status="warning" symbol="success" />
    );
  }
  if (isPastStatus) {
    if (creationStatus.wasCreationMissed(creation)) {
      return <StatusResult ariaLabel="Missed" status="fail" />;
    }
    if (!creation || creationStatus.isCreationEmpty(creation)) {
      return <StatusResult ariaLabel="Empty" status="fail" symbol="warning" />;
    }
    if (LateSubmissionUtils.isLateSubmissionPending(creation)) {
      return <StatusResult ariaLabel="Late Creation Pending" status="warning" symbol="success" />;
    }
    if (LateSubmissionUtils.isLateSubmissionAccepted(creation)) {
      return <StatusResult ariaLabel="Late Creation Accepted" status="success" symbol="success" />;
    }
    if (LateSubmissionUtils.isLateSubmissionRejected(creation)) {
      return <StatusResult ariaLabel="Late Creation Rejected" status="fail" symbol="fail" />;
    }
  }
  return null;
};

export const EvaluateStageResult = ({ creation, student, isPastStatus, activity }: any) => {
  if (!creation || !student) {
    return null;
  }
  if (evaluationUtil.isCompleted(creation, student._id)) {
    if (activity.hasGraceEvaluations) {
      return (
        <StatusResult ariaLabel="Submitted during grace period" status="warning" symbol="success" />
      );
    }
    return <StatusResult ariaLabel="Completed" status="success" />;
  }
  if (isPastStatus) {
    const remainingEvaluations = evaluationUtil.getNumEvaluationRemaining(creation, student._id);
    return (
      <StatusResult ariaLabel="Missed" status="fail" label={remainingEvaluations.toString()} />
    );
  }
  return null;
};

export const FeedbackStageResult = ({
  activity,
  creation,
  isPastStatus,
  student,
  creations,
}: any) => {
  if (!creation || ActivityUtils.isCalibrationActivity(activity) || !student) {
    return null;
  }
  const missedIndividualCreation = !creation && !activity.isGroupActivity;
  if (
    (missedIndividualCreation || creationStatus.wasCreationMissed(creation)) &&
    !ActivityUtils.isPresentationActivity(activity)
  ) {
    return <StatusResult ariaLabel="Missed" status="fail" />;
  }

  let completedFOF = 0;
  let toDo = 0;

  if (ActivityUtils.isPresentationActivity(activity)) {
    const result = FeedbackScoreUtil.numFOFCompletedForPresentationActivity({
      creations,
      studentId: student._id,
    });
    completedFOF = result.completedFOF;
    toDo = result.toDo;
  } else {
    const result = FeedbackScoreUtil.numFOFCompleted(creation, activity, student._id);
    completedFOF = result.completedFOF;
    toDo = result.toDo;
  }

  const missedFOF = toDo - completedFOF;
  if (missedFOF === 0) {
    return <StatusResult ariaLabel="Completed" status="success" />;
  }
  if (isPastStatus) {
    if (missedFOF > 0) {
      return <StatusResult ariaLabel="Missed" status="fail" label={missedFOF.toString()} />;
    }
  }
  return null;
};

const StageResult = (props: any) => {
  const shouldRenderStatus = () => {
    return !activityStatus.isFutureStatus(props.status, props.activity);
  };

  const renderCreationStatus = (creation: any) => {
    return (
      <CreationStageResult
        activity={props.activity}
        creation={creation}
        isPastStatus={activityStatus.isPastStatus(props.status, props.activity)}
      />
    );
  };

  const renderEvaluateStatus = (creation: any) => {
    if (!creation) {
      return;
    }
    return (
      <EvaluateStageResult
        creation={creation}
        student={props.student}
        isPastStatus={activityStatus.isPastStatus(props.status, props.activity)}
        activity={props.activity}
      />
    );
  };

  const renderFeedbackStatus = (creation: any) => {
    return (
      <FeedbackStageResult
        activity={props.activity}
        creation={creation}
        isPastStatus={activityStatus.isPastStatus(props.status, props.activity)}
        student={props.student}
        creations={props.creations}
      />
    );
  };

  const getStatusResult = () => {
    const { isInstructorInCourse } = useUserRoleInCourse();
    if (isInstructorInCourse && activityStatus.isPastStatus(props.status, props.activity)) {
      return <StatusResult ariaLabel="Completed" status="success" />;
    }
    if (!shouldRenderStatus()) {
      return null;
    }
    const _creation = props.creation ? props.creation : props.activity.userAssignment;
    switch (props.status.name) {
      case STATUS_LABELS.CREATE:
        return renderCreationStatus(_creation);
      case STATUS_LABELS.EVALUATE:
        return renderEvaluateStatus(_creation);
      case STATUS_LABELS.FEEDBACK:
        return renderFeedbackStatus(_creation);
      case PRESENT_STATUS:
        if (activityStatus.isFeedbackOrLater({ assignment: props.activity })) {
          return <StatusResult ariaLabel="Completed" status="success" />;
        }
      default:
        return null;
    }
  };
  return getStatusResult();
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    creation: getMySubmission(state, ownProps.activity._id),
    student: getStudentFromUser(state, state.selected.courseId),
    user: selectAuthUser(state),
    creations: getCreationsFromEntities(state, ownProps.activity._id),
  };
};

export default connect(mapStateToProps)(StageResult);
