import { isCalibrationActivity, isPresentationActivity } from '@kritik/utils/activity';
import * as CreationStatusUtils from '@kritik/utils/creation/status';

export function canSpotlightCreation({ spotlight, creation, activity }) {
  if (spotlight) {
    // spotlight exists
    return false;
  }

  if (!activity || isCalibrationActivity(activity)) {
    // no spotlighting in calibration activities
    return false;
  }

  if (isPresentationActivity(activity)) {
    if (creation.files.length > 0) {
      // allow spotlighting a presentation activity when a file is attached
      return true;
    } else {
      return false;
    }
  }

  if (
    CreationStatusUtils.wasCreationMissed(creation) ||
    CreationStatusUtils.isCreationEmpty(creation)
  ) {
    // nothing to spotlight
    return false;
  }

  return true;
}
