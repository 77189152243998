import React, { Component } from 'react';
import { connect } from 'react-redux';
import RosterManagerContainer from 'containers/RosterManager';
import Page from 'pages/Page';
import { withRouter } from 'utils/withRouter';
import { getCourse } from 'selectors/course';
import { localize } from 'locales/index';

class RosterManager extends Component {
  getMetaData() {
    return {
      title: this.pageTitle(),
      meta: this.pageMeta(),
      link: this.pageLink(),
      script: this.pageScript(),
    };
  }

  pageTitle = () => {
    return localize({
      message: 'Course.Roster.PageTitle',
    });
  };

  pageMeta = () => {
    return [];
  };

  pageLink = () => {
    return [];
  };

  pageScript = () => {
    return [];
  };

  render() {
    return (
      <Page {...this.getMetaData()}>
        <RosterManagerContainer {...this.props} />
      </Page>
    );
  }
}

export default RosterManager;
