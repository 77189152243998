import React from 'react';
import { connect } from 'react-redux';
import Typography from 'components/core/Typography';
import { createNewBasicTemplate } from 'redux/rubricManager/actions';
import Button from 'components/buttons/Button';

const CreateNewTemplateButton = (props: any) => {
  return (
    <Button
      type="hidden"
      onClick={props.createNewBasicTemplate}
      data-testid="create-rubric-template"
    >
      <Typography color="btnprimary" weight="500">
        Create Template
      </Typography>
    </Button>
  );
};
const mapStateToProps = (state: any) => {
  return {};
};

export default connect(mapStateToProps, { createNewBasicTemplate })(CreateNewTemplateButton);
