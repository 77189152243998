import React from 'react';
import { Divider } from '@material-ui/core';
import * as LateSubmissionUtil from '@kritik/utils/creation/lateCreation';
import * as AssignmentStatusUtil from '@kritik/utils/stage';
import * as CreationStatusUtil from '@kritik/utils/creation/status';
import * as ActivityUtil from '@kritik/utils/activity';
import NoticeBoard from 'components/layout/NoticeBoard';
import { InstructorEvaluationsOverview } from 'components/Creation';

const CreationEvaluations = ({ creation, assignment }: any) => {
  const isPending = LateSubmissionUtil.isLateSubmissionPending(creation);
  const isRejected = LateSubmissionUtil.isLateSubmissionRejected(creation);
  if (isPending && AssignmentStatusUtil.isEvaluate({ assignment })) {
    return (
      <>
        <Divider /> No evaluators have been assigned yet.
      </>
    );
  }
  if (isPending || isRejected) {
    return (
      <>
        <Divider /> No evaluators were assigned
      </>
    );
  }
  if (CreationStatusUtil.wasCreationMissed(creation)) {
    if (
      ActivityUtil.acceptsLateSubmissions(assignment) &&
      ActivityUtil.autoAcceptsLateSubmissions(assignment) &&
      AssignmentStatusUtil.isEvaluate({ assignment })
    ) {
      return (
        <>
          <Divider />
          <NoticeBoard type="information" title="Evaluators will be auto-assigned">
            Late Creations will automatically be assigned, eligible evaluators if the activity is
            still in the Evaluation stage. An eligible evaluator is a student who has not started
            any evaluations yet. If no eligible evaluators can be found the instructor will have to
            score the creation themself.
          </NoticeBoard>
        </>
      );
    }
    return (
      <>
        <NoticeBoard type="danger" title="Evaluators not assigned">
          Evaluators were not assigned because the student didn't submit their creation before the
          due date.
        </NoticeBoard>
        {!assignment.isGroupActivity && !ActivityUtil.isPresentationActivity(assignment) && (
          <NoticeBoard type="danger" title="Student cannot participate in the feedback stage">
            The student will not be able to participate in the feedback stage because there are no
            evaluations to provide feedback on.
          </NoticeBoard>
        )}
      </>
    );
  }
  return <InstructorEvaluationsOverview activity={assignment} creation={creation} />;
};

export default CreationEvaluations;
