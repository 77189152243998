const getState = (state: any) => {
  return state.activityScores;
};

const selectActivityScoresMap = (state: any) => {
  return state.activityScores;
};

const isActivityScoresLoaded = (state: any) => {
  const loadedActivityId = getState(state).activityId;
  const selectedActivityId = state.selected.assignmentId;
  return state.activityScores.isActivityScoresLoaded && loadedActivityId === selectedActivityId;
};

export const selectAvgGroupEvalScores = (state: any, creationId: any) => {
  return getState(state)[creationId];
};

export const selectAvgGroupFeedbackScore = (state: any, creationId: any) => {
  const avgGroupFeedbackScore = 0;
  const creationScores = getState(state)[creationId];
  if (creationScores) {
    return creationScores.avgGroupFeedbackScore;
  }
  return avgGroupFeedbackScore;
};

export default {
  selectAvgGroupEvalScores,
  selectAvgGroupFeedbackScore,
  selectActivityScoresMap,
  isActivityScoresLoaded,
};
