import React from 'react';

function MarginVertical({ size }: any) {
  const map = {
    sm: 8,
    md: 16,
    default: 24,
    lg: 32,
    xl: 40,
  };
  return (
    <div
      style={{
        marginBottom: map[size],
      }}
    />
  );
}

MarginVertical.defaultProps = {
  size: 'default',
};

export default MarginVertical;
