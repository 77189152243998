import { isGroupPresentationActivity } from '@kritik/utils/activity';
import NextActionCellForGroupPresentation from './GroupPresentation';
import NextActionCellForIndividualPresentation from './IndividualPresentation';

type Props = {
  course: any;
  activity: any;
  isRowHovered: boolean;
  courseHasGroupSets: boolean;
};

const NextActionCell = (props: Props) => {
  if (isGroupPresentationActivity(props.activity)) {
    return <NextActionCellForGroupPresentation {...props} />;
  }
  return <NextActionCellForIndividualPresentation {...props} />;
};

export default NextActionCell;
