import * as _ from 'lodash-es';

const getResolvedDate = (item: any) => {
  const creation = item.creation;
  return creation.dispute.resolvedOn;
};

export const sortByResolvedDate = (itemList: any, ascending: any) => {
  const _itemList = itemList.filter((item) => {
    const itemCreation = item.creation;
    return itemCreation.dispute.status !== 'No dispute';
  });
  return _.orderBy(
    _itemList,
    [
      function (item) {
        return getResolvedDate(item);
      },
    ],
    [ascending ? 'asc' : 'desc']
  );
};
