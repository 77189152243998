import React from 'react';
import Icon from './Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function createIcon(icon: any) {
  const Component = (props: any, ref: any) => (
    <Icon component="span" ref={ref} {...props} width="auto" height="auto">
      <FontAwesomeIcon icon={icon} />
    </Icon>
  );

  return React.memo(React.forwardRef(Component));
}
