import React from 'react';
import { connect } from 'react-redux';
import { DisplayBox } from 'components/layout';
import StarRubricMarks from 'components/General/StarRubricMarks';
import WrittenEvaluationPanel from 'components/Assignment/FinalizedPanels/WrittenEvaluation';
import { MissedWork } from 'components/Assignment/NoticeBoards';
import StudentContent from 'components/layout/StudentContent';
import { CreationView } from 'components/Creation/Create/View';
import {
  isCalibrationActivity,
  isWithinGroupActivity,
  isGroupPresentationActivity,
  isIndividualPresentationActivity,
  isPresentationActivity,
} from '@kritik/utils/activity';
import { getNewKS } from 'utils/student';
import { isRevealingEvaluators } from '@kritik/utils/course';
import { selectCurrentCourse } from 'selectors/course';
import { selectCurrentActivity } from 'selectors/activity';
import { getMySubmission, getMySubmissionsToEvaluate } from 'selectors/creation';
import { getStudentFromUser } from 'selectors/student';
import EvalInstructorCommentView from 'components/CommentOnContent/EvaluationComment';
import { CreationScoreUtil, GradingScoreUtil } from '@kritik/utils/grade';
import ScoreItem from 'components/DisplayBox/ScoreItem';
import InfoPopup from 'components/core/InfoPopup';
import { STAR_RUBRIC_MARKS } from 'components/General/constant';
import { localize } from 'locales/index';
import { LateEvaluationBoard } from 'components/Creation/CreationOverview/EvaluationTab/LateEvaluation';
import { isFinalized } from '@kritik/utils/stage';
import HtmlContent from 'components/General/HtmlContent';

const getIndividualGradingSkill = ({ submissions, myStudent, tabIdx }: any) => {
  const myEval = submissions[tabIdx].scores.filter((score: any) => {
    return score.student._id == myStudent._id;
  })[0];

  let gradingSkillsChange = 0;
  if (myEval) {
    gradingSkillsChange = myEval.gradingSkillsChange.reduce((acc: any, el: any) => {
      return acc + el;
    }, 0);
  }
  return gradingSkillsChange;
};

// during finalized stage, render creations that student evaluate
function PeerCreationTab({ tabIdx, submissions, activity, myStudent, course, mySubmission }: any) {
  if (tabIdx === undefined || tabIdx === false) {
    return null;
  }
  const peerCreation = submissions[tabIdx];
  const myEvaluation = peerCreation.scores.filter((score: any) => {
    return score.student._id === myStudent._id;
  })[0];
  const comparisonMarks = CreationScoreUtil.getRawCreationScore(peerCreation, activity.rubric);

  const maxGradingScoreChange = GradingScoreUtil.getMaxScoreChangePerAssignment(
    isCalibrationActivity(activity)
  );
  const maxGradingScorePerEval = maxGradingScoreChange / submissions.length;
  const gradingSkillsChangePerEval = getIndividualGradingSkill({
    submissions,
    myStudent,
    tabIdx,
  });
  const gradingScorePerEval = GradingScoreUtil.calcScorePerEval(
    gradingSkillsChangePerEval,
    maxGradingScorePerEval
  );
  const isActivityFinalized = isFinalized({ assignment: activity });

  const getPopoverDescription = () => {
    return (
      <>
        <p>{localize({ message: 'GradingScoreTable.Popover.Description1' })}</p>
        <p>
          {localize({ message: 'GradingScoreTable.Popover.Description2' })}
          <a
            target="_blank"
            href={'https://help.kritik.io/en/articles/6845495-grading-power-and-scoring-in-kritik'}
          >
            {localize({ message: 'GradingScoreTable.Popover.LinkName' })}
          </a>
        </p>
      </>
    );
  };

  const renderMyEvaluation = () => {
    if (myEvaluation) {
      return (
        <React.Fragment>
          <LateEvaluationBoard
            evaluation={myEvaluation}
            activity={activity}
            evaluatedCreation={peerCreation}
          />
          <DisplayBox
            header={
              <ScoreItem
                size="lg"
                title={localize({ message: 'GradingScoreTable.Title' })}
                description={localize({ message: 'GradingScoreTable.Subtitle' })}
                value={gradingScorePerEval}
                className="section-padding-bottom"
                subtitle={
                  <InfoPopup
                    btnClassName="subtitle-as-icon"
                    title={localize({ message: 'GradingScoreTable.Popover.Title' })}
                    description={getPopoverDescription()}
                  />
                }
              />
            }
            testid="grading-score-container"
          >
            <EvalInstructorCommentView
              activityId={activity._id}
              creationUserId={peerCreation.user._id}
              evaluation={myEvaluation}
              isInstructor={false}
            />
            <StarRubricMarks
              tableType={STAR_RUBRIC_MARKS.GRADING_SCORE}
              rubric={activity.rubric}
              marks={myEvaluation.marks}
              type="primary"
              maxGradingScorePerEval={maxGradingScorePerEval}
              scoreDiffs={myEvaluation.gradingSkillsChange}
              comparisonMarks={[{ type: 'final-score', marks: comparisonMarks }]}
            />
            {isActivityFinalized && peerCreation.instructorCreationScoreExplanation && (
              <div
                className="creation-score-explanation"
                data-testid="instructor-creation-score-explanation"
              >
                <h4 className="creation-score-explanation__title">Evaluation Notes</h4>
                <div>
                  <HtmlContent content={peerCreation.instructorCreationScoreExplanation} />
                </div>
              </div>
            )}
          </DisplayBox>

          {!isCalibrationActivity(activity) && (
            <WrittenEvaluationPanel
              peersCreation={peerCreation}
              submission={mySubmission}
              studentsEval={myEvaluation}
              assignment={activity}
              submissions={submissions}
              student={myStudent}
            />
          )}
        </React.Fragment>
      );
    }
    return <MissedWork field="evaluation" />;
  };

  const anonymous =
    !isRevealingEvaluators(course) &&
    !isWithinGroupActivity(activity) &&
    !isPresentationActivity(activity);

  const creationTitle = isGroupPresentationActivity(activity)
    ? `${peerCreation.group.name}'s creation`
    : isIndividualPresentationActivity(activity)
      ? `${peerCreation.user.profile.name}'s creation`
      : anonymous === false
        ? peerCreation.user.profile.name
        : `Anonymous Peer Creation ${tabIdx + 1}`;

  return (
    <div style={{ marginTop: '10px' }}>
      <StudentContent
        user={peerCreation.user}
        anonymous={anonymous}
        score={getNewKS(peerCreation.student)}
        label={!isPresentationActivity(activity) ? 'Creator' : null}
        group={isGroupPresentationActivity(activity) ? peerCreation.group : null}
      >
        <h3>{creationTitle}</h3>
        <CreationView submission={peerCreation} />
      </StudentContent>
      {renderMyEvaluation()}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    course: selectCurrentCourse(state),
    activity: selectCurrentActivity(state),
    mySubmission: getMySubmission(state, state.selected.assignmentId),
    myStudent: getStudentFromUser(state, state.selected.courseId),
    submissions: getMySubmissionsToEvaluate(state, state.selected.assignmentId),
  };
};

PeerCreationTab.defaultProps = {
  submissions: [],
};

export default connect(mapStateToProps)(PeerCreationTab);
