import { createRestApiClient } from 'utils/createRestApiClient';

const apiEndpoint = import.meta.env.VITE_API_URL;

export default (options = {}) => {
  const client =
    (options as any).client || createRestApiClient().withConfig({ baseURL: apiEndpoint });
  return {
    listRubrics: (params: any) => {
      return client.request({
        method: 'GET',
        url: `/rubrics`,
        params,
      });
    },
    getRubric: ({ id }: any) => {
      return client.request({
        method: 'GET',
        url: `/rubrics/${id}`,
      });
    },
    updateRubric: (rubric: any) => {
      return client.request({
        method: 'PUT',
        url: `/rubrics/${rubric._id}`,
        data: rubric,
      });
    },
    createNewBasicTemplate: () => {
      return client.request({
        method: 'POST',
        url: `/rubrics/new-base-template`,
      });
    },
    delete: (rubric: any) => {
      return client.request({
        method: 'DELETE',
        url: `/rubrics/${rubric._id}`,
      });
    },
    copyRubricTemplate: ({ rubric, user }: any) => {
      return client.request({
        method: 'POST',
        url: `/rubrics/${rubric._id}`,
        data: {
          userId: user._id,
        },
      });
    },
    createTemplate: ({ rubric, userId }: any) => {
      return client.request({
        method: 'POST',
        url: `/rubrics/templates`,
        data: {
          userId,
          rubricData: rubric,
        },
      });
    },
  };
};
