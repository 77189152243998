import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import NoticeBoard from 'components/layout/NoticeBoard';
import { DisplayBox } from 'components/layout';
import { FeedbackScoreUtil } from '@kritik/utils/grade';
import ScoreItem from 'components/DisplayBox/ScoreItem';
import * as ActivityUtil from '@kritik/utils/activity';
import InlineInformation from 'components/layout/InlineInformation';
import { getSubmissions } from 'selectors/creation';

const FeedbackSummary = ({ assignment, submission, student, creations }: any) => {
  const isGroupAssignment =
    ActivityUtil.isGroupAssignment(assignment) ||
    ActivityUtil.isGroupPresentationActivity(assignment);

  const getFeedbackScore = () => {
    if (isGroupAssignment || ActivityUtil.isIndividualPresentationActivity(assignment)) {
      return FeedbackScoreUtil.calcScore({
        creation: submission,
        activity: assignment,
        studentId: student._id,
        creations,
      });
    }
    return FeedbackScoreUtil.calcScore({
      creation: submission,
      activity: assignment,
      studentId: submission.student._id,
    });
  };

  const renderInlineScoreNotice = () => {
    if (isGroupAssignment) {
      return (
        <InlineInformation>
          Your Feedback Score is individual and not shared with the group.
        </InlineInformation>
      );
    }
    return null;
  };
  return (
    <DisplayBox>
      <ScoreItem
        title="Feedback Score"
        description="Your feedback score is your participation in the feedback stage"
        value={getFeedbackScore()}
        size="lg"
      />
      {FeedbackScoreUtil.isFeedbackScoredByTeacher(submission) && (
        <NoticeBoard type="success" title="The instructor has scored your feedback">
          This feedback score might not match directly with your participation because it was
          modified by the instructor.
        </NoticeBoard>
      )}
      {renderInlineScoreNotice()}
    </DisplayBox>
  );
};

const mapStateToProps = (state: any) => {
  return {
    creations: getSubmissions(state, state.selected.assignmentId),
  };
};

export default withRouter(connect(mapStateToProps)(FeedbackSummary));
