import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  CalibrationGradingNotice,
  EvaluationScoreGroupScoreNotice,
  EvaluationScoreGroupEvalsNotice,
  KSChangeUnavailableNotice,
  EvaluationScoreCalibrationActivityNotice,
} from 'components/Assignment/NoticeBoards';
import Button from 'components/buttons/Button';
import { DisplayBox, DisplayBoxDivider } from 'components/layout';
import { EvaluationScoreEdit } from 'components/Creation/GradeView';
import { simplePercent } from '@kritik/utils/format';
import NoticeBoard from 'components/layout/NoticeBoard';
import InlineInformation from 'components/layout/InlineInformation';
import { EvaluationScoreUtil } from '@kritik/utils/grade';
import * as GradeHistoryUtil from '@kritik/utils/gradeHistory';
import * as StudentUtils from 'utils/student';
import ScoreItem from 'components/DisplayBox/ScoreItem';
import * as ActivityUtils from '@kritik/utils/activity';
import { selectAvgGroupEvalScores } from 'redux/activityScores/select';
import StudentSelectors from 'selectors/student';
import GradeViewUtils from './utils';
import { useUserRoleInCourse } from 'hooks/course';

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    avgGroupEvalScores: selectAvgGroupEvalScores(state, ownProps.submission._id),
    scoreHistories: StudentSelectors.selectStudentScoreHistories(state, ownProps.student._id),
  };
};

type EvaluationScoreViewProps = {
  assignment: any;
  course: any;
  submissionsToEvaluate: any;
  submission: any;
  student: any;
  groupView: boolean;
  avgGroupEvalScores: any;
  scoreHistories: any;
};

const EvaluationScoreView = (props: EvaluationScoreViewProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const {
    assignment,
    course,
    submissionsToEvaluate,
    submission,
    student,
    groupView,
    scoreHistories,
  } = props;
  const { isInstructorInCourse, isStudentInCourse } = useUserRoleInCourse();
  const gradeHistory = GradeHistoryUtil.getStudentActivityGradeHistory(
    student.gradeHistories,
    assignment._id
  );

  const { grading, writtenEvaluation, evaluation } = GradeViewUtils.getEvaluationScores({
    gradeHistory,
    creationsToEvaluate: submissionsToEvaluate,
    student,
    activity: assignment,
    course,
    creation: submission,
  });
  const scoreHistory = StudentUtils.getStudentActivityScoreHistory(scoreHistories, assignment._id);
  if (!scoreHistory && !ActivityUtils.isCalibrationActivity(assignment)) {
    return <KSChangeUnavailableNotice esEnabled={course.courseSettings.useEvaluationScore} />;
  }

  if (!gradeHistory && ActivityUtils.isCalibrationActivity(assignment)) {
    return <EvaluationScoreCalibrationActivityNotice />;
  }

  const instructorDescription = {
    evaluation_score: `Evaluation Score is a combination of how accurately your 
      student graded their peer's creations and the quality of their written 
      evaluation.`,
    grading_score: `How well they graded their peers.`,
    written_evaluation_score: `The quality of their written feedback.`,
  };
  const studentDescription = {
    evaluation_score: `Evaluation Score is a combination of how accurately you
      graded your peer's creations and the quality of your written evaluation.`,
    grading_score: `How well you graded your peers.`,
    written_evaluation_score: `The quality of your written feedback.`,
  };

  let descriptions = studentDescription;
  if (isInstructorInCourse) {
    descriptions = instructorDescription;
  }

  const getSectionLabel = (label: string, weight?: number) => {
    const _label = {
      title: '',
      subtitle: '',
    };
    const getGradeWeight = (weight: any) => {
      const totalWeight =
        course.markingScheme.kritikScore + course.markingScheme.feedbackCommunicationEffectiveness;
      return (weight / totalWeight) * 100;
    };

    const _weight = getGradeWeight(weight);
    if (ActivityUtils.isCalibrationActivity(assignment)) {
      _label.title = `${label}`;
      return _label;
    }
    _label.title = `${label}`;
    if (_weight) {
      _label.subtitle = groupView
        ? ` (group average) (${simplePercent(_weight)} weight)`
        : ` (${simplePercent(_weight)} weight)`;
    } else {
      _label.subtitle = groupView ? ` (group average)` : '';
    }
    return _label;
  };

  const renderProfGraded = () => {
    if (!isInstructorInCourse) {
      return null;
    }
    if (!EvaluationScoreUtil.isEvaluationScoredByTeacher(submission)) {
      return null;
    }
    return <InlineInformation type="prof-graded">Instructor graded</InlineInformation>;
  };

  const renderGradingScore = () => {
    const { title, subtitle } = getSectionLabel('Grading Score', course.markingScheme.kritikScore);
    return (
      <ScoreItem
        value={groupView ? props.avgGroupEvalScores.avgGradingScore : grading}
        title={title}
        subtitle={subtitle}
        description={descriptions.grading_score}
      />
    );
  };

  const renderWrittenEvaluationScore = () => {
    if (ActivityUtils.isCalibrationActivity(assignment)) {
      return <CalibrationGradingNotice isInstructor={isInstructorInCourse} />;
    }
    const { title, subtitle } = getSectionLabel(
      'Written Evaluation Score',
      course.markingScheme.feedbackCommunicationEffectiveness
    );

    return (
      <ScoreItem
        title={title}
        subtitle={subtitle}
        description={descriptions.written_evaluation_score}
        value={groupView ? props.avgGroupEvalScores.avgWrittenEvalScore : writtenEvaluation}
      />
    );
  };

  const renderTeacherReviewedLabel = () => {
    if (!isStudentInCourse || !EvaluationScoreUtil.isEvaluationScoredByTeacher(submission)) {
      return null;
    }
    return (
      <NoticeBoard type="success" title="The instructor has scored your evaluations">
        This evaluation score might not match directly with your peers' feedback because it was
        modified by the instructor.
      </NoticeBoard>
    );
  };

  const renderEditButton = () => {
    if (
      ActivityUtils.isGroupAssignment(assignment) ||
      ActivityUtils.isGroupPresentationActivity(assignment) ||
      ActivityUtils.isCalibrationActivity(assignment)
    ) {
      return null;
    }
    return (
      <React.Fragment>
        <DisplayBoxDivider />
        <Button
          type="secondary"
          onClick={() => {
            return setIsEditing(true);
          }}
        >
          Edit Score
        </Button>
      </React.Fragment>
    );
  };

  if (isEditing) {
    const labels = {
      gradingScore: getSectionLabel('Grading Score', course.markingScheme.kritikScore).title,
      writtenEvalScore: getSectionLabel(
        'Written Evaluation Score',
        course.markingScheme.feedbackCommunicationEffectiveness
      ).title,
    };
    return (
      <EvaluationScoreEdit
        labels={labels}
        markingScheme={course.markingScheme}
        prevScores={{
          evaluationScore: evaluation,
          gradingScore: grading,
          writtenEvalScore: writtenEvaluation,
        }}
        returnToScoreView={() => {
          return setIsEditing(false);
        }}
        activity={assignment}
      />
    );
  }

  const renderGroupEvalScoreNotice = () => {
    if (
      isStudentInCourse &&
      (ActivityUtils.isGroupAssignment(assignment) ||
        ActivityUtils.isGroupPresentationActivity(assignment))
    ) {
      return (
        <React.Fragment>
          <DisplayBoxDivider />
          <InlineInformation>
            Evaluation Score is individual and not shared with the group.
          </InlineInformation>
        </React.Fragment>
      );
    }
    if (!groupView) {
      return null;
    }
    return (
      <React.Fragment>
        <DisplayBoxDivider />
        <EvaluationScoreGroupScoreNotice />
      </React.Fragment>
    );
  };

  const renderPanelHeader = () => {
    const { title, subtitle } = getSectionLabel('Evaluation score');
    return (
      <ScoreItem
        title={title}
        subtitle={subtitle}
        description={descriptions.evaluation_score}
        value={groupView ? props.avgGroupEvalScores.avgEvalScore : evaluation}
        size="lg"
        testid={'evaluation-score'}
      />
    );
  };

  return (
    <div>
      <DisplayBox>
        {renderPanelHeader()}
        {renderProfGraded()}
        <DisplayBoxDivider />
        {renderGradingScore()}
        <DisplayBoxDivider />
        {renderWrittenEvaluationScore()}
        {renderGroupEvalScoreNotice()}
        {renderTeacherReviewedLabel()}
        {isInstructorInCourse && renderEditButton()}
      </DisplayBox>
      {groupView ? <EvaluationScoreGroupEvalsNotice /> : null}
    </div>
  );
};

EvaluationScoreView.defaultProps = {
  groupView: false,
  avgGroupEvalScores: {
    avgGradingScore: 0,
    avgWrittenEvalScore: 0,
    avgEvalScore: 0,
  },
};

export default connect(mapStateToProps, {})(EvaluationScoreView);
