import { combineReducers } from 'redux';
import { normalize } from 'normalizr';

import { mergeEntities, updateEntities } from 'actions/entities';
import { groupService } from 'services';
import { assignedGroupSchema, groupSchema } from 'schemas';
import { getGroupSet } from 'selectors/group';
import { selectGroupSet } from 'actions/select';
import { openLoadingModal, closeLoadingModal } from 'actions/modals';
import * as ErrorUtils from 'utils/error';

const ADD_GROUP_REQUEST = 'ADD_GROUP_REQUEST';
const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
const ADD_GROUP_FAILURE = 'ADD_GROUP_FAILURE';
const SHOW_UPLOAD_GROUPSET_VIEW = 'SHOW_UPLOAD_GROUPSET_VIEW';
const CLOSE_UPLOAD_GROUPSET_VIEW = 'CLOSE_UPLOAD_GROUPSET_VIEW';
const START_CSVFILE_UPLOAD = 'START_CSVFILE_UPLOAD';
const SET_STUDENTS_NOT_FOUND = 'SET_STUDENTS_NOT_ADDED';
const GENERATE_FROM_CSV_SUCCESS = 'GENERATE_FROM_CSV_SUCCESS';
const GENERATE_FROM_CSV_FAILURE = 'GENERATE_FROM_CSV_FAILURE';

const initialState = {
  status: '',
  message: '',
  isShowUploadView: false,
  isUploadingCsvFile: false,
  studentsNotFound: [],
};

const getMsgFromPayload = (payload: any) => {
  return payload.message;
};

function groupReducer(state = initialState, action: any) {
  switch (action.type) {
    case ADD_GROUP_REQUEST:
      return {
        ...state,
        status: 'pending',
      };
    case ADD_GROUP_SUCCESS:
      return {
        ...state,
        status: 'success',
      };
    case ADD_GROUP_FAILURE:
      return {
        ...state,
        status: 'failure',
        message: getMsgFromPayload(action.payload),
      };
    case SHOW_UPLOAD_GROUPSET_VIEW:
      return {
        ...state,
        isShowUploadView: true,
      };
    case CLOSE_UPLOAD_GROUPSET_VIEW:
      return {
        ...state,
        isShowUploadView: false,
      };
    case START_CSVFILE_UPLOAD:
      return {
        ...state,
        isUploadingCsvFile: action.payload,
      };
    case SET_STUDENTS_NOT_FOUND:
      return {
        ...state,
        studentsNotFound: [...action.payload],
      };
    default:
      return state;
  }
}

const getResponseData = (res: any) => {
  return res.data;
};

const getResponseMsg = (res: any) => {
  return res.data.message;
};

export const generateFromCSV = ({ formData }: any) => {
  return async (dispatch: any) => {
    dispatch(startCsvFileUpload(true));
    dispatch(
      openLoadingModal({
        title: 'Creating Groups',
        content: 'Creating groups based on your csv file.',
      })
    );
    try {
      const response = await groupService().generateFromCSV({
        formData,
      });
      if (response.status === 200) {
        const { groupSet, groupList, studentsNotFound } = response.data;
        const normalizedSet = normalize(groupSet, assignedGroupSchema);
        const normalizedGroups = normalize(groupList, [groupSchema]);
        const normalizedResult = {
          ...normalizedSet.entities,
          ...normalizedGroups.entities,
        };
        dispatch(mergeEntities(normalizedResult));
        dispatch(selectGroupSet(groupSet._id));
        dispatch(startCsvFileUpload(false));
        dispatch(closeLoadingModal());
        if (studentsNotFound.length > 0) {
          dispatch(setNotAddedStudents(studentsNotFound));
        } else {
          dispatch(closeUploadView());
        }
        dispatch({ type: GENERATE_FROM_CSV_SUCCESS });
      }
    } catch (error) {
      dispatch({
        type: GENERATE_FROM_CSV_FAILURE,
        error: ErrorUtils.getErrorMessageFromResponse(error),
      });
      dispatch(closeLoadingModal());
      return {
        error: ErrorUtils.getErrorMessageFromResponse(error),
      };
    }
  };
};

export const showUploadView = () => {
  return (dispatch: any) => {
    dispatch({ type: SHOW_UPLOAD_GROUPSET_VIEW });
  };
};

export const closeUploadView = () => {
  return (dispatch: any) => {
    dispatch({ type: CLOSE_UPLOAD_GROUPSET_VIEW });
  };
};

export const startCsvFileUpload = (payload: any) => {
  return (dispatch: any) => {
    dispatch({ type: START_CSVFILE_UPLOAD, payload });
  };
};

export const setNotAddedStudents = (payload: any) => {
  return (dispatch: any) => {
    dispatch({ type: SET_STUDENTS_NOT_FOUND, payload });
  };
};

export const addGroup = (groupSetId: any) => {
  return async (dispatch: any, getState: any) => {
    dispatch({
      type: ADD_GROUP_REQUEST,
    });
    try {
      const res = await groupService().addGroup({ groupSetId });
      if (res.status === 200) {
        const newGroup = getResponseData(res);
        const normalizedGroup = normalize(newGroup, groupSchema);
        dispatch(updateEntities(normalizedGroup.entities));

        const state = getState();
        const groupSet = getGroupSet(groupSetId, state.entities);
        groupSet.groups.push(newGroup._id);
        const normalizedGroupSet = normalize(groupSet, assignedGroupSchema);
        dispatch(mergeEntities(normalizedGroupSet.entities));
        return dispatch({
          type: ADD_GROUP_SUCCESS,
        });
      }
      return dispatch({
        type: ADD_GROUP_FAILURE,
        payload: {
          message: getResponseMsg(res),
        },
      });
    } catch (error) {
      dispatch({
        type: ADD_GROUP_FAILURE,
        payload: {
          message: 'Somthing wrong when try adding a new group',
        },
      });
    }
  };
};

export const selectCurrentGroupSet = (state: any) => {
  return getGroupSet(state.selected.groupSetId, state.entities);
};

export const selectIsShowUploadView = (state: any) => {
  return state.groupManager.group.isShowUploadView;
};

export const selectIsUploadingCsvFile = (state: any) => {
  return state.groupManager.group.isUploadingCsvFile;
};

export const selectNotFoundStudents = (state: any) => {
  return state.groupManager.group.studentsNotFound;
};

export default combineReducers({
  group: groupReducer,
});
