import React from 'react';
import classNames from 'classnames';

type markingSchemeSeparatorProps = {
  children: React.ReactNode;
  hasNext: boolean;
};
const Node = (props: markingSchemeSeparatorProps) => {
  const lineClasses = classNames('marking-scheme__split-lines', {
    'marking-scheme__split-lines--middle': props.hasNext,
  });
  return (
    <div className="marking-scheme__split">
      <div className={lineClasses} />
      {props.children}
    </div>
  );
};

Node.defaultProps = {
  onChange: null,
  isEdit: false,
  weight: null,
};

export default Node;
