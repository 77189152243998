import { assignmentService, courseService } from 'services';
import { isCalibrationActivity } from '@kritik/utils/activity';
import { selectActivityIdsToImport, selectActivityOptions } from 'redux/activityImport/select';
import types from 'redux/activityImport/types';

const getFilteredCourseList = (state: any, courses: any) => {
  const currentlySelectedCourse = state.selected.courseId;
  return courses.filter((course: any) => {
    return course._id !== currentlySelectedCourse && course.userRole === 'instructor';
  });
};

export const getActivityImportCourses = (params = {}, { client }: any = {}) => {
  return (dispatch: any, getState: any) => {
    dispatch({ type: types.GET_ACTIVITY_IMPORT_COURSES_REQUEST, payload: { params } });
    return courseService({ client })
      .list({ activity: true })
      .then((res: any) => {
        if (res.status === 200) {
          const state = getState();
          const courses = getFilteredCourseList(state, res.data);
          dispatch({
            type: types.GET_ACTIVITY_IMPORT_COURSES_SUCCESS,
            payload: courses,
          });
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.GET_ACTIVITY_IMPORT_COURSES_FAILURE, payload: err });
      });
  };
};

export const getActivitiesToImportFromCourse = (params = {}, { client }: any = {}) => {
  return (dispatch: any) => {
    dispatch({ type: types.GET_ACTIVITIES_TO_IMPORT_REQUEST, payload: { params } });
    return assignmentService({ client })
      .list(params)
      .then((res: any) => {
        if (res.status === 200) {
          const activities = res.data;
          activities.sort((a: any, b: any) => {
            if (a.title.toLowerCase() > b.title.toLowerCase()) {
              return 1;
            }
            return -1;
          });
          dispatch({
            type: types.GET_ACTIVITIES_TO_IMPORT_SUCCESS,
            payload: activities,
          });
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.GET_ACTIVITIES_TO_IMPORT_FAILURE, payload: err });
      });
  };
};

const importSingleActivity = (activityId: any, courseId: any) => {
  return assignmentService()
    .importActivity({ activityId, courseId })
    .then((res: any) => {
      if (res.status === 200) {
        return activityId;
      }
      return false;
    })
    .catch((err: any) => {
      console.log(err);
    });
};

export const importSelectedActivities = () => {
  return async (dispatch: any, getState: any) => {
    dispatch({ type: types.ACTIVITY_IMPORT_STREAM_REQUEST });
    let state = getState();
    const activitiesToImport = selectActivityIdsToImport(state);
    const selectedCourseId = state.selected.courseId;
    for (const activityId of activitiesToImport) {
      state = getState();
      if (state.activityImport.abortImport) {
        break;
      }
      const result = await importSingleActivity(activityId, selectedCourseId);
      if (result) {
        dispatch({ type: types.IMPORT_ACTIVITY_SUCCESS, payload: result });
      } else {
        dispatch({ type: types.IMPORT_ACTIVITY_FAILURE });
      }
    }
    dispatch({ type: types.ACTIVITY_IMPORT_STREAM_SUCCESS });
  };
};

export const toggleSelectedActivity = (activityId: any) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    let selectedActivities = selectActivityIdsToImport(state);
    const activityIdx = selectedActivities.indexOf(activityId);
    if (activityIdx >= 0) {
      selectedActivities = selectedActivities.filter((id: any) => {
        if (id.toString() !== activityId.toString()) {
          return true;
        }
        return false;
      });
    } else {
      selectedActivities.push(activityId.toString());
    }
    dispatch({ type: types.SET_ACTIVITIES_TO_IMPORT, payload: selectedActivities });
  };
};

export const selectAllActivities = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const filteredActivities = selectActivityOptions(state).reduce((acc: any, activity: any) => {
      if (!isCalibrationActivity(activity)) {
        acc.push(activity._id);
      }
      return acc;
    }, []);
    dispatch({ type: types.SET_ACTIVITIES_TO_IMPORT, payload: filteredActivities });
  };
};

export const resetSelectedActivities = () => {
  return (dispatch: any) => {
    dispatch({ type: types.SET_ACTIVITIES_TO_IMPORT, payload: [] });
  };
};

export const resetActivityOptions = () => {
  return (dispatch: any) => {
    dispatch({ type: types.RESET_ACTIVITIES_TO_IMPORT });
  };
};

export const abortImportAction = () => {
  return (dispatch: any) => {
    dispatch({ type: types.STOP_IMPORT });
  };
};

export const resetStore = () => {
  return (dispatch: any) => {
    dispatch({ type: types.RESET_ACTIVITY_IMPORT });
  };
};

export default {
  getActivityImportCourses,
  getActivitiesToImportFromCourse,
  resetActivityOptions,
  importSelectedActivities,
  toggleSelectedActivity,
  selectAllActivities,
  resetSelectedActivities,
  abortImportAction,
  resetStore,
};
