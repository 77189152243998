import { RESET_ASYNC } from 'types';

export const resetAsync = (action: any) => {
  return {
    type: RESET_ASYNC,
    payload: action,
  };
};

export default {
  resetAsync,
};
