import { useContext } from 'react';
import AddNewFeedback from './AddNewFeedback';
import { EvaluationContext } from '../../context/EvaluationContext';

const AddNewFeedbackContainer = () => {
  const [evaluation] = useContext(EvaluationContext);
  const addNewFeedback = evaluation && evaluation.confirmed ? <AddNewFeedback /> : null;

  return addNewFeedback;
};

export default AddNewFeedbackContainer;
