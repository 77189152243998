export const TYPE_OPTIONS = {
  PEER: 'peer',
  CALIBRATION: 'calibration',
  GROUP: 'group',
  PRESENTATION: 'presentation',
};

export default {
  TYPE_OPTIONS,
};
