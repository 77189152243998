import ACTIVE from './assignment-icon-active.svg';
import DISABLED from './assignment-icon-disabled.svg';
import DONE from './assignment-icon-done.svg';
import PENDING from './assignment-icon-pending.svg';
import HORIZONTAL_ARROW from './horizontal-arrow.svg';

export default {
  ACTIVE,
  DISABLED,
  DONE,
  PENDING,
  HORIZONTAL_ARROW,
};
